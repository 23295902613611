import React from 'react';
import {SystemMaintenance} from '@front-libs/ui';

type SystemMaintenanceProviderProps = {
  children: React.ReactNode;
};

export const SystemMaintenanceProvider = ({children}: SystemMaintenanceProviderProps) => {
  const isSystemMaintenance = import.meta.env.VITE_IS_ASSET_SYSTEM_MAINTENANCE === 'true';
  return <>{isSystemMaintenance ? <SystemMaintenance /> : children}</>;
};
