import React, {useState, useMemo} from 'react';
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions as MDialogActions,
  Button,
} from '@material-ui/core';
import {styled} from '@material-ui/styles';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import {Option, Selector} from '@molecules/Selector';
// import {productStatusOptions} from '@modules/hospital_products/constants';
import {HospitalRoom} from '@modules/hospital_places/types';
import {HospitalRoomFormatter} from '@modules/hospital_wards/helpers';
import {OptionStringList} from '../../ProductsRegistrationDialog/Step2/EditableColumnModal/EditableColumnModal';
import {FilterOption} from '@components/molecules/Drawers/FilterDrawer/types';
import {columns} from './ColumnData';
import {Column} from './types';

export type EditableColumnModalProps = {
  selected: string[];
  hospitalRooms: HospitalRoom[];
  hospitalDealerOptionsList: OptionStringList;
  hospitalDepartmentOptions: OptionStringList;
  notesDataList: FilterOption[];
} & DialogProps;

/**
 * 選択された機器情報を編集するためのモーダルダイアログ。
 *
 * @param {EditableColumnModalProps} props - コンポーネントへのprops
 * @param {boolean} props.open - ダイアログが開いているかどうか
 * @param {string[]} props.selected - 選択された機器のIDリスト
 * @param {HospitalRoom[]} props.hospitalRooms - 病院の部屋のリスト
 * @param {FilterOption[]} props.notesDataList - 汎用項目のデータ
 * @param {DialogProps} props.actions - ダイアログのアクションハンドラ
 * @returns {React.FC<EditableColumnModalProps>} 機器情報を一括編集するためのモーダルダイアログのReact関数型コンポーネント
 */
export const EditableColumnModal: React.FC<EditableColumnModalProps> = ({
  open,
  selected,
  hospitalRooms,
  hospitalDealerOptionsList,
  hospitalDepartmentOptions,
  notesDataList,
  actions,
}) => {
  const [selectColumn, setColumn] = useState<string>('');
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const [selectValue, setValue] = useState<{[key: string]: any} | null>(null);

  const handleSave = async () => {
    // 一括編集の場合は 更新する値(下段テキスト) に何も入力されない場合は [キャンセル] 扱い(selectValue === null)
    // 院内耐用年数の場合 は 文字列に変換して返す (API側で 値削除 と 更新対象外を見分けるために "" 空値を使用のため)
    if (selectValue?.legalDurableYear || selectValue?.legalDurableYear === 0) {
      // ※ゼロ値の場合も 0 年 として更新する ( 0 値は false と見なされるため 代入値を直接指定)
      selectValue.legalDurableYear = selectValue.legalDurableYear ? selectValue.legalDurableYear.toString() : '0';
    }
    if (selectValue?.isBaseUnit === 'notBaseUnit') {
      selectValue.isBaseUnit = null;
    }
    await actions.resolve(selectValue);
  };

  const handleCancel = () => {
    actions.reject();
  };

  const hospitalRoomOptions = useMemo(
    () => hospitalRooms.map((item) => ({label: HospitalRoomFormatter.getFullRoom(item), value: item.hashId})),
    [hospitalRooms]
  );

  const columnList = useMemo(
    () => columns(hospitalRoomOptions, hospitalDealerOptionsList, hospitalDepartmentOptions, notesDataList),
    [hospitalDealerOptionsList, hospitalDepartmentOptions, hospitalRoomOptions, notesDataList]
  );

  /**
   * セレクトボックスのオプション表示用リスト
   */
  const targetColumn = useMemo<Column | undefined>(() => {
    return columnList.find((col) => col.name === selectColumn);
  }, [columnList, selectColumn]);

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const handleChange = (type: Column['type'], name: string) => (e: any) => {
    setValue(null);

    switch (type) {
      case 'text':
      case 'textarea':
        setValue({[name]: e.target.value});
        break;
      case 'number':
        setValue({[name]: Number(e.target.value)});
        break;
      case 'date':
        // Memo: DateFieldはdateのstringが渡される。
        setValue({[name]: e});
        break;
      case 'select':
        // Memo: Selectorは{value: any, label: any}が渡される。
        setValue({[name]: e.value});
        break;
    }
  };

  const handleChangeColumn = (option: Option) => {
    //Memo: 更新する情報を変更した場合は入っている値をNullにする。
    setValue(null);
    setColumn(option.value);
  };

  const canSubmit = useMemo(() => {
    return !!selectColumn;
  }, [selectColumn]);

  return (
    <Dialog open={open} fullWidth maxWidth={'md'} PaperProps={{style: {overflowY: 'visible', maxWidth: '500px'}}}>
      <DialogTitle id="scroll-dialog-title">
        <Title>{selected.length}件の機器情報を一括情報編集</Title>
      </DialogTitle>
      <DialogContent style={{overflowY: 'visible'}}>
        <Box>
          <div>更新する情報</div>
          <Selector
            value={selectColumn}
            options={columnList.map((column) => {
              return {
                label: column.label,
                value: column.name,
              };
            })}
            onChange={handleChangeColumn}
            menuPosition={'fixed'}
          />
        </Box>
        {targetColumn && (
          <Box my={3}>
            <div>{targetColumn.label}</div>
            {React.createElement(targetColumn.InputComponent, {
              name: targetColumn.name,
              onChange: handleChange(targetColumn.type, targetColumn.name),
              onBlur: targetColumn.formatter
                ? () => {
                    if (selectValue && selectValue[targetColumn.name]) {
                      // biome-ignore lint/style/noNonNullAssertion: 非nullアサーション演算子(!)
                      setValue({[targetColumn.name]: targetColumn.formatter!(selectValue[targetColumn.name])});
                    }
                  }
                : undefined,
              value: selectValue ? selectValue[targetColumn.name] : null,
              ...targetColumn.props,
            })}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Box p={2} width={'100%'} display={'flex'} justifyContent={'flex-end'}>
          <Box mr={2}>
            <Button disabled={!canSubmit} color="primary" variant="contained" onClick={handleSave}>
              保存
            </Button>
          </Box>
          <Box>
            <Button color="primary" variant="outlined" onClick={handleCancel}>
              キャンセル
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const DialogActions = styled(MDialogActions)({
  background: '#FFF',
  padding: 'initial',
});

const Title = styled(Typography)({
  fontWeight: 'bold',
});
