import React from 'react';
import {Button} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {HospitalProductNoteSettingsOptionsValue} from '@modules/hospital_products/types';
import {SelectTypeOptionEditList} from './SelectTypeOptionEditList';

type Props = {
  options: HospitalProductNoteSettingsOptionsValue[];
  onNew: () => HospitalProductNoteSettingsOptionsValue;
  onChange: (newOptions: HospitalProductNoteSettingsOptionsValue[]) => void;
  onRemove: (option: HospitalProductNoteSettingsOptionsValue) => Promise<boolean>;
};

export const SelectTypeOptionEditForm = ({options, onNew, onChange, onRemove}: Props) => {
  const handleChangeOption = (index: number, newOption: HospitalProductNoteSettingsOptionsValue) => {
    onChange(options.map((option, i) => (i === index ? newOption : option)));
  };

  const onDragEnd = (srcIdx: number, destIdx: number) => {
    if (srcIdx === destIdx) return;

    const opts = Array.from(options);
    const [removed] = opts.splice(srcIdx, 1);
    opts.splice(destIdx, 0, removed);
    onChange(opts);
  };

  const handleAddOption = () => {
    onChange([...options, onNew()]);
  };

  const handleRemoveOption = async (index: number) => {
    const result = await onRemove(options[index]);
    if (!result) return;

    onChange(options.filter((_, i) => i !== index));
  };

  return (
    <div>
      <SelectTypeOptionEditList
        options={options}
        onChange={handleChangeOption}
        onDragEnd={onDragEnd}
        onRemove={handleRemoveOption}
      />
      <Button variant="text" sx={{paddingLeft: 0, color: '#0052CC'}} onClick={handleAddOption}>
        <AddIcon />
        選択項目の追加
      </Button>
    </div>
  );
};
