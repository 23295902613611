import React from 'react';
import {Typography, Grid, styled} from '@material-ui/core';

type RentalStatusBadgeProps = {
  isReturned: boolean;
};

export const RentalStatusBadge: React.FC<RentalStatusBadgeProps> = (props) => {
  const {isReturned} = props;

  return (
    <StatusBadge>
      <Text isReturned={isReturned} variant="inherit">
        {isReturned ? '返却済み' : '貸出中'}
      </Text>
    </StatusBadge>
  );
};

const StatusBadge = styled(Grid)({
  minWidth: '100px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const Text = styled(Typography)(({isReturned}: {isReturned: boolean}) => ({
  flex: '0 0 65px',
  borderRadius: '100px',
  padding: '2px 0px',
  fontSize: '12px',
  fontWeight: 'bold',
  textAlign: 'center',
  borderWidth: '2px',
  borderStyle: 'solid',

  background: isReturned ? '#EAF5EA' : '#DEEBFF',
  color: isReturned ? '#3C9E58' : '#2A96E8',
  borderColor: isReturned ? '#3C9E58' : '#2A96E8',
}));
