import React from 'react';
import {ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, SxProps, Theme} from '@mui/material';

const settingMenuItems = [
  {
    label: '端末設定',
    value: 'terminalSetting',
  },
  {
    label: 'エリアボタンの並び替え',
    value: 'customize',
  },
  {
    label: 'ログアウト',
    value: 'logout',
  },
] as const;

const popperStyle: SxProps<Theme> = {
  zIndex: 1000,
};

type Props = {
  anchorEl: HTMLDivElement | null;
  open: boolean;
  onClose: () => void;
  onClickSettingMenu: (value: (typeof settingMenuItems)[number]['value']) => void;
};

export const PopperMenu = ({open, onClose, onClickSettingMenu, anchorEl}: Props) => {
  const handleMenuItemClick = (value: (typeof settingMenuItems)[number]['value']) => {
    onClickSettingMenu(value);
    onClose();
  };

  return (
    <Popper sx={popperStyle} open={!!open} anchorEl={anchorEl} transition placement="bottom">
      {({TransitionProps}) => (
        <Grow {...TransitionProps} style={{transformOrigin: 'placement'}}>
          <Paper>
            <ClickAwayListener onClickAway={onClose}>
              <MenuList>
                {settingMenuItems.map((item, index) => (
                  <MenuItem
                    key={`MenuItem_${index}`}
                    onClick={() => handleMenuItemClick(item.value)}
                    data-testid={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};
