import React, {useCallback} from 'react';
import {styled, Box, Switch, TextField, Theme, Select, MenuItem, SxProps, FormControl} from '@mui/material';
import {Draggable} from 'react-beautiful-dnd';
import {NoteFieldDataTypes} from '@modules/hospital_products/types';
import {NoteFieldDataTypeLabels} from './constants';
import {NoteField} from './types';
import {SelectTypeField} from './SelectTypeField';

type NameChangeHandler = (id: string, name: string) => void;
type VisibleChangeHandler = (id: string, visible: boolean) => void;
type TypeChangeHandler = (id: string, type: NoteFieldDataTypes) => void;
type NoteFieldProp = {
  noteField: NoteField;
  index: number;
  textFieldValue: string;
  disable: boolean;
  nameChangeHandler: NameChangeHandler;
  nameBlueHandler: NameChangeHandler;
  visibleChangeHandler: VisibleChangeHandler;
  typeChangeHandler: TypeChangeHandler;
  onSelectOptionEdit: (id: string) => void;
};

const StyledItemContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '10px',
  height: '38px',
});

const StyledLabelContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  width: '46px',
  fontSize: '14px',
});

const StyledSelectorContainer = styled(Box)({
  display: 'flex',

  '& > * ': {
    marginRight: '24px',
  },
});
const StyledTextField = styled(TextField)({
  width: '220px',
  fontSize: '14px',
  '& input': {
    fontSize: '14px',
  },
});

const selectBoxStyles: (disable?: string) => SxProps<Theme> = (disable) => (theme: Theme) => ({
  color: theme.palette.primary.main,
  fontWeight: 'bold',
  fontSize: '14px',

  '& .MuiSelect-select:before': {
    borderBottom: 'none',
  },
  '&::before': {
    borderBottom: 'none', // 通常時の下線を非表示
  },
  '&::after': {
    borderBottom: 'none', // 通常時のフォーカス後の下線も非表示
  },
  '&.Mui-disabled::before': {
    borderBottom: 'none', // disabled状態のときに下線を非表示
  },
  '&.Mui-disabled::after': {
    borderBottom: 'none', // disabled状態のときのフォーカス後の下線も非表示
  },
  '& .MuiSelect-icon': {
    color: disable === 'true' ? '' : theme.palette.primary.main,
  },
});

const formControlStyles: SxProps<Theme> = {mt: 0.5, ml: 3, mr: 3, width: '160px'};
const menuItemStyles: SxProps<Theme> = {fontSize: '14px'};

/**
 * ドラッグアンドドロップ可能なリストアイテムを表示するコンポーネント
 * 編集可能なテキストフィールド、セレクタ、およびスイッチが含まれている
 *
 * @param {NoteField} noteField - ノートフィールドのオブジェクト
 * @param {number} index - リスト内のアイテムのインデックス
 * @param {string} textFieldValue - テキストフィールドの値
 * @param {boolean} disable - 編集不可か 名称のみ変更可能
 * @param {NameChangeHandler} nameChangeHandler - 名前変更時のハンドラ
 * @param {NameChangeHandler} nameBlueHandler - テキストフィールドからフォーカスが外れた時のハンドラ
 * @param {VisibleChangeHandler} visibleChangeHandler - 可視性の変更時のハンドラ
 * @param {TypeChangeHandler} typeChangeHandler - タイプ変更時のハンドラ
 *
 * @example
 * ```tsx
 * <DraggableItem
 *   noteField={{ id: '1', visible: true, name: '備考', type: 'text' }}
 *   index={0}
 *   textFieldValue="テキストフィールドの初期値"
 *   disable={false}
 *   nameChangeHandler={(id, name) => console.log(`名前変更: ${id}, ${name}`)}
 *   nameBlueHandler={(id, name) => console.log(`名前変更確定: ${id}, ${name}`)}
 *   visibleChangeHandler={(id, visible) => console.log(`可視性変更: ${id}, ${visible}`)}
 *   typeChangeHandler={(id, type) => console.log(`タイプ変更: ${id}, ${type}`)}
 * />
 * ```
 */
export const DraggableItem = ({
  noteField,
  index,
  textFieldValue,
  disable,
  nameChangeHandler,
  nameBlueHandler,
  typeChangeHandler,
  visibleChangeHandler,
  onSelectOptionEdit,
}: NoteFieldProp) => {
  const handleSelectOptionEdit = () => {
    onSelectOptionEdit(noteField.id);
  };

  const renderDataTypeField = (noteField: NoteField) => {
    switch (noteField.type) {
      case 'text':
        return null;
      case 'date':
        return null;
      case 'select':
        return <SelectTypeField onSelectOptionEdit={handleSelectOptionEdit} />;
      default:
        return null;
    }
  };

  /** トグルスイッチ変更時 */
  const onChangeSwitch = useCallback(() => {
    visibleChangeHandler(noteField.id, !noteField.visible);
  }, [visibleChangeHandler, noteField.id, noteField.visible]);

  /** セレクトボックス変更時 */
  const onChangeSelector = useCallback(
    (value: string) => {
      typeChangeHandler(noteField.id, value as NoteFieldDataTypes);
    },
    [typeChangeHandler, noteField.id]
  );

  /** テキストフィールドのフォーカスが外れた時に文言が変更されていたら */
  const onChangeTextBlur = useCallback(
    (value: string) => {
      if (noteField.name !== value) {
        nameBlueHandler(noteField.id, value);
      }
    },
    [nameBlueHandler, noteField.id, noteField.name]
  );

  /** テキストフィールドの入力 */
  const onChangeText = useCallback(
    (value: string) => {
      nameChangeHandler(noteField.id, value);
    },
    [nameChangeHandler, noteField.id]
  );

  return (
    // 初回のスコープでは並べ替え機能がないためisDragDisabledをtrueに設定
    <Draggable draggableId={noteField.id} index={index} isDragDisabled={true}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <StyledItemContainer>
            <StyledSelectorContainer>
              <StyledLabelContainer>
                {/* 並び替え実装時に使用するドラッグアイコンをコメントアウト */}
                {/* <DragIndicatorIcon /> */}
                {`備考${index + 1}`}
              </StyledLabelContainer>
              <Switch
                checked={noteField.visible}
                onChange={onChangeSwitch}
                disabled={disable}
                color={disable ? 'default' : 'primary'}
                name="propertyName"
                inputProps={{'aria-label': 'primary checkbox'}}
              />
              <StyledTextField
                variant="outlined"
                size="small"
                value={textFieldValue}
                onChange={(e) => {
                  onChangeText(e.target.value);
                }}
                onBlur={(e) => {
                  onChangeTextBlur(String(e.target.value));
                }}
              />
              <FormControl variant="standard" sx={formControlStyles}>
                <Select
                  name={`select${noteField.id}`}
                  value={noteField.type}
                  placeholder="データタイプ"
                  disabled={disable}
                  color="primary"
                  sx={selectBoxStyles(disable.toString())}
                  onChange={(e) => {
                    if (e.target.value) onChangeSelector(String(e.target.value));
                  }}>
                  <MenuItem sx={menuItemStyles} value="text">
                    {NoteFieldDataTypeLabels.text}
                  </MenuItem>
                  <MenuItem sx={menuItemStyles} value="date">
                    {NoteFieldDataTypeLabels.date}
                  </MenuItem>
                  <MenuItem sx={menuItemStyles} value="select">
                    {NoteFieldDataTypeLabels.select}
                  </MenuItem>
                </Select>
              </FormControl>
              {renderDataTypeField(noteField)}
            </StyledSelectorContainer>
          </StyledItemContainer>
        </div>
      )}
    </Draggable>
  );
};
