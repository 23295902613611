import React, {useMemo, useState, CSSProperties} from 'react';
import {Grid, Button, Paper, MenuList, Popper, MenuItem, ClickAwayListener} from '@mui/material';
import {NavLink, useLocation} from 'react-router-dom';
import {KeyboardArrowDown} from '@mui/icons-material';
import {NavLinkType} from '@components/organisms/Header/pc';
import Grow from '@mui/material/Grow';
import {CSSObject} from '@mui/system';

const containerStyle: CSSObject = {
  paddingBottom: '16px',
};

const linkItemStyle: CSSObject = {
  height: 'inherit',
};

const linkContentStyle: CSSProperties = {
  textDecoration: 'none',
  color: '#8B95A6',
};

const linkedButtonStyle = (activeNavLink?: NavLinkType, item?: NavLinkType): CSSObject => ({
  height: '100%',
  borderRadius: 0,
  paddingLeft: '12px',
  paddingRight: '12px',
  minWidth: 0,
  '&:hover': {
    backgroundColor: 'inherit',
  },
  ...(activeNavLink?.to === item?.to && {
    fontWeight: 'bolder',
    color: 'primary.dark',
    paddingBottom: '2px',
    borderBottom: '2px solid',
    borderColor: 'primary.dark',
  }),
});

const popperStyle: CSSObject = {
  zIndex: 100000,
  maxHeight: 360,
  overflow: 'auto',
  boxShadow:
    '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
};

const menuListStyle: CSSObject = {
  display: 'block',
};

const menuItemStyle: CSSObject = {
  fontSize: '14px',
  '&:hover': {
    backgroundColor: 'secondary.light',
  },
};

const navLinkItems: NavLinkType[] = [
  {
    to: '/inspection_v2/tables',
    label: '点検表',
  },
  {
    to: '/inspection_v2/whole_product_plans',
    label: '機種別設定',
  },
  {
    to: '/inspection_v2/hospital_product_plans',
    label: '定期点検計画',
  },
];
const NavLinkItem: React.FC<{item: NavLinkType; activeNavLink?: NavLinkType}> = ({item, activeNavLink}) => {
  const [openedChild, setOpenedChild] = useState<string>('');

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if ((item.childLinks ?? []).length === 0) return;
    event.preventDefault();
    setOpenedChild(item.label);
  };

  const handleClose = () => {
    setOpenedChild('');
  };

  return (
    <Grid item sx={linkItemStyle}>
      <NavLink to={item.to} style={linkContentStyle} data-testid={`global-nav-${item.to}`}>
        <Button color="inherit" onClick={handleClick} sx={linkedButtonStyle(activeNavLink, item)}>
          {item.label}
          {item.childLinks && <KeyboardArrowDown sx={{color: 'grey.700'}} />}
        </Button>
        <ClickAwayListener onClickAway={handleClose}>
          <Popper open={item.label === openedChild} transition placement="bottom-start" sx={popperStyle}>
            {({TransitionProps, placement}) => (
              <Grow {...TransitionProps} style={{transformOrigin: placement}}>
                <Paper>
                  <MenuList sx={menuListStyle}>
                    {navLinkItems.map((i, childIndex) => (
                      <NavLink key={`childMenu-${childIndex}`} to={i.to} style={linkContentStyle}>
                        <MenuItem sx={menuItemStyle} onClick={handleClose}>
                          {i.label}
                        </MenuItem>
                      </NavLink>
                    ))}
                  </MenuList>
                </Paper>
              </Grow>
            )}
          </Popper>
        </ClickAwayListener>
      </NavLink>
    </Grid>
  );
};

/**
 * 点検のページ切り替え用タブ
 * 点検表・機種計画・機器計画でページ切り替えられる
 * 汎用的にする場合はnavLinkItemsを外に出す
 *
 */
export const InspectionMenu: React.FC = () => {
  const location = useLocation();

  const activeNavLink = useMemo(() => {
    const isNavLinkActive = (item: NavLinkType): boolean => {
      if (item.to !== '' && location.pathname.startsWith(item.to)) {
        return true;
      }

      return item.childLinks?.some((child) => location.pathname.startsWith(child.to)) ?? false;
    };

    return navLinkItems.find(isNavLinkActive);
  }, [location]);

  return (
    <Grid container sx={containerStyle}>
      {navLinkItems.map((item, index) => (
        <NavLinkItem key={`nav-${index}`} item={item} activeNavLink={activeNavLink} />
      ))}
    </Grid>
  );
};
