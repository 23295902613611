import React, {useCallback, useMemo, useState} from 'react';
import {
  Grid,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  makeStyles,
  IconButton,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import {PapersType} from '../types';
import {DashboardLayoutSetting} from '@modules/dashboard/types';
import {Form, Formik, useFormikContext} from 'formik';
import CloseIcon from '@material-ui/icons/Close';
import {papers, paperTypes} from '../consts';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import {ONE_HEIGHT_BOARDS} from '..';
import {camelCase} from 'lodash';

const useStyles = makeStyles((theme) => ({
  title: {
    padding: '24px 32px 0px',
    '& h2': {
      color: '#172B4D',
      fontSize: '20px',
      fontWeight: 'bold',
    },
  },
  closeButton: {
    padding: '0px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '40px',
    padding: '0px 32px 0px',
    '& > div:not(:first-child)': {
      marginTop: '24px',
    },
  },
  formHelperText: {
    fontSize: 14,
    color: theme.palette.grey[600],
    marginLeft: '36px',
  },
  actions: {
    marginTop: '24px',
    padding: '0px 32px 32px',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

type SetBoardVisibilityDialogFormProps = {
  open: boolean;
  onClose: React.MouseEventHandler;
  currentLayout: DashboardLayoutSetting;
};

export const SetBoardVisibilityDialogForm: React.FC<SetBoardVisibilityDialogFormProps> = (props) => {
  const {open, onClose, currentLayout} = props;
  const classes = useStyles();
  const {setValues, submitForm} = useFormikContext();
  const [checkboxOptions, setCheckboxOptions] = useState<DashboardLayoutSetting>(currentLayout);

  const boardsWithVisibility = useMemo(() => {
    return paperTypes.map((paperType) => ({
      ...papers[camelCase(paperType)],
      visibility: checkboxOptions.some((layout) => layout.boardId === papers[camelCase(paperType)].value),
      currentLayout: checkboxOptions.filter((layout) => layout.boardId === papers[camelCase(paperType)].value),
    }));
  }, [checkboxOptions]);

  const handleSubmit = useCallback(() => {
    submitForm();
  }, [submitForm]);

  const handleChangeLayoutCheckbox = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const {checked, value} = event.target;

      if (!checked) {
        // Remove the layout if the checkbox is unchecked
        setCheckboxOptions((prevCheckedOptions) => prevCheckedOptions.filter((layout) => layout.boardId !== value));
        setValues((prevValues: DashboardLayoutSetting) =>
          prevValues.filter((prevValue) => prevValue.boardId !== value)
        );
      } else {
        // Add a default value if the checkbox is checked
        setCheckboxOptions((prevCheckedOptions) => [
          ...prevCheckedOptions,
          {
            boardId: value as PapersType,
            layout: {
              width: 6,
              height: ONE_HEIGHT_BOARDS.includes(value) ? 1 : 2,
              x: 0,
              y: 30, // 一番下に配置されるように設定している
            },
          },
        ]);
        setValues((prevValues: DashboardLayoutSetting) => [
          ...prevValues,
          {
            boardId: value as PapersType,
            layout: {
              width: 6,
              height: ONE_HEIGHT_BOARDS.includes(value) ? 1 : 2,
              x: 0,
              y: 30, // 一番下に配置されるように設定している
            },
          },
        ]);
      }
    },
    [setCheckboxOptions, setValues]
  );

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="xs" aria-labelledby="form-dialog-title">
      <DialogTitle className={classes.title} disableTypography>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant="h2">{`表示するダッシュボードを選択`}</Typography>
          <IconButton onClick={onClose} className={classes.closeButton}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid item>
          <div>
            {boardsWithVisibility.map((board) => {
              return (
                <Grid key={board.value}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        style={{padding: 9}}
                        onChange={handleChangeLayoutCheckbox}
                        checked={board.visibility}
                        value={board.value}
                        name={board.value}
                      />
                    }
                    label={board.label}
                  />
                </Grid>
              );
            })}
          </div>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          確定
        </Button>
        <Button onClick={onClose} color="primary">
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export type SetBoardVisibilityDialogProps = DialogProps & {
  currentLayout: DashboardLayoutSetting;
};

export const SetBoardVisibilityDialog: React.FC<SetBoardVisibilityDialogProps> = (props) => {
  const {open, currentLayout} = props;
  const handleSubmit = useCallback(
    async (res) => {
      await props.actions.resolve(res as DashboardLayoutSetting);
    },
    [props.actions]
  );

  const handleClose = useCallback(
    (_e: React.MouseEvent) => {
      props.actions.reject();
    },
    [props.actions]
  );

  return (
    <Formik initialValues={currentLayout} onSubmit={handleSubmit}>
      <Form>
        <SetBoardVisibilityDialogForm open={open ?? false} onClose={handleClose} currentLayout={currentLayout} />
      </Form>
    </Formik>
  );
};
