import React, {useState, useMemo} from 'react';
import {Clear, Fullscreen, FullscreenExit, Minimize} from '@material-ui/icons';
import {Grid, IconButton, Button, styled, Theme, Avatar} from '@material-ui/core';

export type CommentDialogProps = {
  thumbnailUrl: string;
  actions: {
    resolve: (value: string) => void;
    reject: () => void;
  };
};

type WindowMode = 'full' | 'normal' | 'mini';

type StyleProps = {
  theme: Theme;
  mode: WindowMode;
};

const fullWindowMixin = {
  width: '92vw !important',
  height: '92vh',
};

const normalWindowMixin = {
  top: 146,
  right: 40,
  width: '535px !important',
  height: 428,
};

const miniWindowMixin = {
  bottom: 0,
  right: 40,
  width: '535px !important',
  height: 50,
};

const StyledRoot = styled(Grid)(({theme, mode}: StyleProps) => ({
  position: 'fixed',
  margin: 0,
  border: 'none',
  boxShadow: '0px 0px 10px rgba(0,0,0,0.2)',
  overflow: 'hidden',
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  transitionDuration: '.2s',
  zIndex: theme.zIndex.drawer + 10,
  borderRadius: '14px',
  // 各モードに合わせてCSSを読み込む
  ...(mode === 'full' && fullWindowMixin),
  ...(mode === 'normal' && normalWindowMixin),
  ...(mode === 'mini' && miniWindowMixin),
}));

const StyledBackdrop = styled('div')(({theme, mode}: StyleProps) => ({
  backgroundColor: mode === 'full' ? '#0000007f' : '',
  zIndex: theme.zIndex.appBar + 1000,
  alignItems: 'center',
  justifyContent: 'center',
  position: 'fixed',
  right: 0,
  bottom: 0,
  top: 0,
  left: 0,
  display: 'flex',
}));

const StyledHeader = styled(Grid)({
  alignItems: 'center',
  justifyContent: 'flex-end',
  color: '#172B4D',
  fontSize: 18,
  height: 56,
  flex: '0 0 46px',
  padding: '0px 6px',
});

const StyledButtons = styled(Grid)({
  flex: '0 0 auto',
  width: 'auto',
});

const StyledIconButton = styled(IconButton)({
  '&&': {
    color: '#172B4D',
    padding: '4px 8px',
  },
});

const StyledBody = styled(Grid)({
  flex: '1 1 0',
  padding: '16px 24px',
  display: 'flex',
});

const StyledInputComment = styled('textarea')({
  width: '100%',
  height: '100%',
  borderStyle: 'none',
  resize: 'none',
  fontSize: 16,
  marginLeft: '8px',
  '&:focus-visible': {
    borderStyle: 'none',
    outline: 'none',
  },
});

const StyledFooter = styled(Grid)({
  height: 72,
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'flex-end',
});

const StyledFooterBtnContainer = styled(Grid)({
  padding: '16px',
});
const StyledFooterButton = styled(Button)({
  '&&': {borderRadius: '24px'},
});

type HeaderButtonProps = {
  mode: WindowMode;
  onClickMinimize: () => void;
  onClickFullScreen: () => void;
  onClickClose: () => void;
};

const HeaderButtons = ({mode, onClickMinimize, onClickFullScreen, onClickClose}: HeaderButtonProps) => {
  return (
    <StyledButtons>
      <StyledIconButton onClick={onClickMinimize}>
        <Minimize style={{transform: mode === 'mini' ? 'rotate(180deg)' : undefined}} />
      </StyledIconButton>
      <StyledIconButton onClick={onClickFullScreen}>
        {mode === 'full' ? <FullscreenExit /> : <Fullscreen />}
      </StyledIconButton>

      <StyledIconButton onClick={onClickClose}>
        <Clear />
      </StyledIconButton>
    </StyledButtons>
  );
};

/**
 * 不具合報告用のコメント入力ダイアログ
 * @param thumbnailUrl Avatar表示用の画像URL
 * @returns
 */
export const CommentDialog: React.FC<CommentDialogProps> = ({thumbnailUrl, actions}) => {
  const [mode, setMode] = useState<WindowMode>('normal');
  const [value, setValue] = useState('');
  const disabled = useMemo(() => value === '', [value]);

  return (
    <StyledBackdrop mode={mode}>
      <StyledRoot container mode={mode}>
        <StyledHeader container>
          <HeaderButtons
            mode={mode}
            onClickFullScreen={() => (mode === 'full' ? setMode('normal') : setMode('full'))}
            onClickMinimize={() => (mode === 'mini' ? setMode('normal') : setMode('mini'))}
            onClickClose={() => actions.resolve('')}
          />
        </StyledHeader>
        <StyledBody>
          <Avatar src={thumbnailUrl} variant="circular" />
          <StyledInputComment
            value={value}
            onChange={(e) => setValue(e.target.value)}
            tabIndex={mode === 'mini' ? -1 : 1}
            placeholder={'コメントを入力'}
          />
        </StyledBody>
        <StyledFooter>
          <StyledFooterBtnContainer>
            <StyledFooterButton
              disabled={disabled}
              onClick={() => actions.resolve(value)}
              color={'primary'}
              variant={'contained'}
              disableElevation>
              コメントする
            </StyledFooterButton>
          </StyledFooterBtnContainer>
        </StyledFooter>
      </StyledRoot>
    </StyledBackdrop>
  );
};
