import {useCallback, useState} from 'react';
import {useQueries, useQuery} from 'react-query';
import {
  getCategories,
  getDescendantCategories,
  getHospitalCategories,
  useDescendantCategoryQuery,
  useFetchRootCategoriesQuery,
} from '../api';
import {CategoryIndex} from '../types';

export type CategoryTrees = {
  rootCategory: CategoryIndex;
  narrowCategories: CategoryIndex[];
}[];

/** TODO:参照元が未参照なので消したい */
export const useCategoryTreesQuery = (isMedicalDevice?: boolean) => {
  const rootQuery = useQuery(['category', 'categoryTree', isMedicalDevice], () =>
    getCategories({depth: 0, isMedicalDevice: isMedicalDevice})
  );
  const data = rootQuery.data?.data ?? [];

  const descendantQueries = useQueries(
    data.map((rootCategory, index) => ({
      queryKey: ['categoryTree', rootCategory.hashId, index],
      queryFn: () => getDescendantCategories(rootCategory.hashId),
    }))
  );
  return {
    ...rootQuery,
    data: data.map((rootCategory, index) => ({
      rootCategory: rootCategory,
      narrowCategories: descendantQueries[index].data?.data.filter((item) => item.depth === 1) ?? [],
    })),
  };
};

// 病院のカテゴリーを取得
export const useHospitalCategoryTreesQuery = (hospitalHashId: string, isMedicalDevice?: boolean) => {
  const rootQuery = useQuery([hospitalHashId, 'category', 'categoryTree', isMedicalDevice], () =>
    getHospitalCategories(hospitalHashId, {depth: 0, isMedicalDevice: isMedicalDevice})
  );
  const data = rootQuery.data?.data ?? [];

  const descendantQueries = useQueries(
    data.map((rootCategory: CategoryIndex, index: number) => ({
      queryKey: [hospitalHashId, 'categoryTree', rootCategory.hashId, index],
      queryFn: () => getDescendantCategories(rootCategory.hashId),
    }))
  );
  return {
    ...rootQuery,
    data: data.map((rootCategory: CategoryIndex, index: number) => ({
      rootCategory: rootCategory,
      narrowCategories: descendantQueries[index].data?.data.filter((item: CategoryIndex) => item.depth === 1) ?? [],
    })),
  };
};

export const useCategoryQuery = () => {
  const [selectedRootCategory, setSelectedRootCategory] = useState<string>();
  const rootCategoryQuery = useFetchRootCategoriesQuery({depth: 0});
  const subCategoryQuery = useFetchRootCategoriesQuery({depth: 1});

  const descendantCategoriesQueries = useDescendantCategoryQuery(selectedRootCategory);

  const selectRootCategory = useCallback((categoryHashId: string | undefined) => {
    setSelectedRootCategory(categoryHashId);
  }, []);

  return {rootCategoryQuery, subCategoryQuery, descendantCategoriesQueries, selectRootCategory};
};
