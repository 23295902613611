import * as Yup from 'yup';
import dayjs from 'dayjs';
import {ObjectShape} from 'yup/lib/object';
import {
  EMPTY_INSPECTION_ITEM_VALUES_ERROR,
  EMPTY_REQUIRED_INSPECTION_ITEM_VALUE_ERROR,
  INSPECTION_SELECT_ITEM_VALIDATION_ERROR,
  INVALID_INSPECTION_DATE_FORMAT_ERROR,
  INVALID_INSPECTION_SELECTED_ITEM_ERROR,
  INVALID_INSPECTION_TIME_FORMAT_ERROR,
  INSPECTION_NUMERIC_ITEM_VALIDATION_ERROR,
  EmptyAnswer,
} from './types';
import {yup} from '@front-libs/core';
import {FormikErrors, getIn} from 'formik';
import {FormValue} from './types';
import _ from 'lodash';
import {isNullish} from '@front-libs/helpers';
import {
  DateInspectionItem,
  InspectionItem,
  MultiSelectInspectionItem,
  MultilineTextInspectionItem,
  NumericInspectionItem,
  SelectInspectionItem,
  TextInspectionItem,
  TimeInspectionItem,
} from '@modules/inspections/api';

const getSelectItemSchema = (item: SelectInspectionItem, isDraft: boolean) => {
  const options = item.options.map(({value}) => value);
  return Yup.object().shape({
    id: Yup.string().required(),
    value: yup
      .string()
      .nullable()
      .test('select-validation', function (value?: string | null) {
        if (!value) {
          return !item.required || isDraft
            ? true
            : this.createError({
                path: this.path,
                message: EMPTY_REQUIRED_INSPECTION_ITEM_VALUE_ERROR,
              });
        }

        if (!options.includes(value)) {
          return this.createError({
            path: this.path,
            message: INVALID_INSPECTION_SELECTED_ITEM_ERROR,
          });
        }

        if (!item.validator) {
          return true;
        }

        // バリデーションに含まれていない場合OK
        return !item.validator.values.includes(value)
          ? true
          : this.createError({
              path: this.path,
              message: INSPECTION_SELECT_ITEM_VALIDATION_ERROR,
            });
      }),
  });
};

// biome-ignore lint/suspicious/noExportsInTest: <explanation>
export const getMultiSelectItemSchema = (item: MultiSelectInspectionItem, isDraft: boolean) => {
  const valuesSchema: ObjectShape = {};
  item.options.forEach((option) => {
    valuesSchema[option.value] = Yup.boolean();
  });

  const optionValues = item.options.map((o) => o.value);

  return Yup.object().shape({
    id: Yup.string().required(),
    values: Yup.object()
      .shape(valuesSchema)
      .test('multi-select-validation', function (value: {[key: string]: boolean} | undefined) {
        const emptyError = this.createError({
          path: this.path,
          message: EMPTY_REQUIRED_INSPECTION_ITEM_VALUE_ERROR,
        });

        if (!value) {
          return item.required && !isDraft ? emptyError : true;
        }

        let numWrongKey = 0; // キーが選択肢に無いもの
        let numChecked = 0; // チェックされた数
        let numInvalidChecked = 0; // キーがバリデーションに含まれるもの

        Object.entries(value).forEach(([k, v]) => {
          if (!optionValues.includes(k)) {
            numWrongKey++;

            return;
          }

          if (v) {
            numChecked++;

            if (item.validator?.values && item.validator.values.includes(k)) {
              numInvalidChecked++;
            }
          }
        });

        if (numWrongKey > 0) {
          return this.createError({
            path: this.path,
            message: INVALID_INSPECTION_SELECTED_ITEM_ERROR,
          });
        }

        if (numChecked === 0 && item.required && !isDraft) {
          return emptyError;
        }

        if (numInvalidChecked > 0) {
          return this.createError({
            path: this.path,
            message: INSPECTION_SELECT_ITEM_VALIDATION_ERROR,
          });
        }

        return true;
      }),
  });
};

// 結果用, 結果の場合arrayとなって入る
// biome-ignore lint/suspicious/noExportsInTest: <explanation>
export const getResultMultiSelectItemSchema = (item: MultiSelectInspectionItem, isDraft: boolean) => {
  const optionValues = item.options.map((o: {value: string}) => o.value);

  return Yup.object().shape({
    id: Yup.string().required(),
    values: Yup.array()
      .of(Yup.string())
      .nullable()
      .test('multi-select-validation', function (values?: (string | undefined)[] | null) {
        const emptyError = this.createError({
          path: this.path,
          message: EMPTY_REQUIRED_INSPECTION_ITEM_VALUE_ERROR,
        });

        if (!values || values.length === 0) {
          return item.required && !isDraft ? emptyError : true;
        }

        let numWrongKey = 0; // キーが選択肢に無いもの
        let numInvalidChecked = 0; // キーがバリデーションに含まれるもの

        values.forEach((key) => {
          if (!key) {
            return;
          }

          if (!optionValues.includes(key)) {
            numWrongKey++;

            return;
          }

          if (item.validator?.values && item.validator.values.includes(key)) {
            numInvalidChecked++;
          }
        });

        if (numWrongKey > 0) {
          return this.createError({
            path: this.path,
            message: INVALID_INSPECTION_SELECTED_ITEM_ERROR,
          });
        }

        if (numInvalidChecked > 0) {
          return this.createError({
            path: this.path,
            message: INSPECTION_SELECT_ITEM_VALIDATION_ERROR,
          });
        }

        return true;
      }),
  });
};

// biome-ignore lint/suspicious/noExportsInTest: <explanation>
export const getTextItemSchema = (item: TextInspectionItem | MultilineTextInspectionItem, isDraft: boolean) => {
  return Yup.object().shape({
    id: Yup.string().required(),
    value:
      item.required && !isDraft
        ? Yup.string().required(EMPTY_REQUIRED_INSPECTION_ITEM_VALUE_ERROR)
        : Yup.string().nullable(),
  });
};

const getNumericItemSchema = (item: NumericInspectionItem, isDraft: boolean) => {
  return Yup.object().shape({
    id: Yup.string().required(),
    value: Yup.mixed()
      .test('validate-numeric', function (value?: number | '') {
        if (isNullish(value) || value === '') {
          return item.required && !isDraft
            ? this.createError({
                path: this.path,
                message: EMPTY_REQUIRED_INSPECTION_ITEM_VALUE_ERROR,
              })
            : true;
        }

        if (!item.validator || (item.validator.op !== 'and' && item.validator.op !== 'or')) {
          return true;
        }

        // orで挟む
        for (const expr of item.validator.exprs) {
          // XXX: Ignore for now
          if (expr.op === 'and' || expr.op === 'or' || expr.op === 'not') {
            continue;
          }

          const validationError = this.createError({
            path: this.path,
            message: JSON.stringify({
              error: INSPECTION_NUMERIC_ITEM_VALIDATION_ERROR,
              // biome-ignore lint/suspicious/noExplicitAny: <explanation>
              customError: (expr as any).errorText || null,
            }),
          });

          // 外れる場合エラー
          switch (expr.op) {
            case 'gt':
              if (value > expr.value) {
                return validationError;
              }
              break;
            case 'gte':
              if (value >= expr.value) {
                return validationError;
              }
              break;
            case 'lt':
              if (value < expr.value) {
                return validationError;
              }
              break;
            case 'lte':
              if (value <= expr.value) {
                return validationError;
              }
              break;
            case 'eq':
              if (Number(value) === Number(expr.value)) {
                return validationError;
              }
              break;
            case 'neq':
              if (Number(value) !== expr.value) {
                return validationError;
              }
              break;
            case 'between':
              if (value >= expr.lower && value <= expr.upper) {
                return validationError;
              }
              break;
            case 'not-between':
              if (value < expr.lower || value > expr.upper) {
                return validationError;
              }
              break;
          }
        }

        return true;
      }),
  });
};

// biome-ignore lint/suspicious/noExportsInTest: <explanation>
export const getDateItemSchema = (item: DateInspectionItem, isDraft: boolean) => {
  return Yup.object().shape({
    id: Yup.string().required(),
    value:
      item.required && !isDraft
        ? Yup.date()
            .required(EMPTY_REQUIRED_INSPECTION_ITEM_VALUE_ERROR)
            .typeError(INVALID_INSPECTION_DATE_FORMAT_ERROR)
        : Yup.date().nullable().typeError(INVALID_INSPECTION_DATE_FORMAT_ERROR),
  });
};

// biome-ignore lint/suspicious/noExportsInTest: <explanation>
export const getTimeItemSchema = (item: TimeInspectionItem, isDraft: boolean) => {
  return Yup.object().shape({
    id: Yup.string().required(),
    value: Yup.string()
      .nullable()
      .test('validate-time', function (value?: string | null) {
        if (!value) {
          return item.required && !isDraft
            ? this.createError({
                path: this.path,
                message: EMPTY_REQUIRED_INSPECTION_ITEM_VALUE_ERROR,
              })
            : true;
        }

        return dayjs(value, 'HH:MM').isValid()
          ? true
          : this.createError({
              path: this.path,
              message: INVALID_INSPECTION_TIME_FORMAT_ERROR,
            });
      }),
  });
};

// biome-ignore lint/suspicious/noExportsInTest: <explanation>
export const getItemSchema = (item: InspectionItem & {id: string}, isDraft: boolean) => {
  switch (item.type) {
    case 'select':
      return getSelectItemSchema(item, isDraft);
    case 'multi-select':
      return getMultiSelectItemSchema(item, isDraft);
    case 'text':
    case 'multiline-text':
      return getTextItemSchema(item, isDraft);
    case 'numeric':
      return getNumericItemSchema(item, isDraft);
    case 'date':
      return getDateItemSchema(item, isDraft);
    case 'time':
      return getTimeItemSchema(item, isDraft);
  }

  return yup.object();
};

const getItemsSchema = (items: InspectionItem[], isDraft: boolean) => {
  const itemsSchema: ObjectShape = {};
  const sections = items;

  for (const section of sections) {
    if (section.type !== 'section') continue;

    for (const item of section.items) {
      if (item.id) {
        itemsSchema[item.id] = getItemSchema(item as InspectionItem & {id: string}, isDraft);
      }
    }
  }
  return itemsSchema;
};

// biome-ignore lint/suspicious/noExportsInTest: <explanation>
export const getFormSchema = (items: InspectionItem[]) => {
  return Yup.lazy((values: FormValue) =>
    Yup.object().shape({
      items: Yup.object().shape(getItemsSchema(items, values.isDraft)).required(EMPTY_INSPECTION_ITEM_VALUES_ERROR),
    })
  );
};

// biome-ignore lint/suspicious/noExportsInTest: <explanation>
export const getEmptyAnswers = (errors: FormikErrors<FormValue>, sections: InspectionItem[]): EmptyAnswer[] => {
  const itemMap: Record<string, EmptyAnswer> = {};

  sections.forEach((section, sectionIndex) => {
    // XXX: Ignore for now
    if (section.type !== 'section') {
      return;
    }

    section.items.forEach((item, itemIndex) => {
      if (!item.id) {
        return;
      }

      itemMap[item.id] = {
        sectionIndex: sectionIndex,
        sectionName: section.name,
        fieldIndex: itemIndex,
        fieldName: item.name,
      };
    });
  });

  const emptyAnswers: EmptyAnswer[] = [];

  if (errors.items && _.isPlainObject(errors.items)) {
    Object.entries(errors.items).forEach(([id, item]) => {
      if (!item || !itemMap[id]) {
        return;
      }

      {
        const valueError = getIn(item, 'value');
        if (valueError === EMPTY_REQUIRED_INSPECTION_ITEM_VALUE_ERROR) {
          emptyAnswers.push(itemMap[id]);
        }
      }

      {
        const valuesError = getIn(item, 'values');
        if (valuesError === EMPTY_REQUIRED_INSPECTION_ITEM_VALUE_ERROR) {
          emptyAnswers.push(itemMap[id]);
        }
      }
    });
  }

  return emptyAnswers;
};
