import React from 'react';
import {Button} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {SelectTypeOptionCreateList} from './SelectTypeOptionCreateList';

type Props = {
  options: string[];
  onChange: (newOptions: string[]) => void;
};

export const SelectTypeOptionCreateForm = ({options, onChange}: Props) => {
  const handleChangeOption = (index: number, newOption: string) => {
    onChange(options.map((option, i) => (i === index ? newOption : option)));
  };

  const handleAddOption = () => {
    onChange([...options, '']);
  };

  const handleRemoveOption = (index: number) => {
    onChange(options.filter((_, i) => i !== index));
  };

  return (
    <div>
      <SelectTypeOptionCreateList options={options} onChange={handleChangeOption} onRemove={handleRemoveOption} />
      <Button variant="text" sx={{paddingLeft: 0, color: '#0052CC'}} onClick={handleAddOption}>
        <AddIcon />
        選択項目の追加
      </Button>
    </div>
  );
};
