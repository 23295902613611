import React from 'react';
import {Selector} from '@molecules/Selector';

type Props = {
  selectValue: string;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  onChange: (value: {label: string; value: string; [key: string]: any}) => void;
  columnOptions: {
    label: string;
    value: string;
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    [key: string]: any;
  }[];
};

export const PropertySelectorColumn: React.FC<Props> = ({selectValue, columnOptions, onChange}) => {
  return (
    <Selector
      value={selectValue}
      options={columnOptions}
      onChange={onChange}
      formControlProps={{style: {minWidth: 200, width: 200}}}
    />
  );
};
