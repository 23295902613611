import React from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {createStyles, makeStyles, Avatar, Typography} from '@material-ui/core';
import {MenuItemType, PopperMenuButton} from '@molecules/Buttons/PopperMenuButton';
import {useLogout} from '@modules/auth/hooks/useLogout';
import {useNavigate} from 'react-router-dom';

const CACHE_KEY_PREFIX = '@@auth0spajs@@';

const useStyles = makeStyles((theme) =>
  createStyles({
    linkedButtonIcon: {
      padding: '0px 0px 0px 8px',
      minWidth: 0,
      [theme.breakpoints.up(1025)]: {
        border: '1px solid #919BAB',
        padding: '3px 16px',
      },
    },
  })
);

type Prop = {
  smallViewport: boolean;
};

export const ProfileMenu: React.FC<Prop> = ({smallViewport}) => {
  const {user} = useAuth0();
  const logout = useLogout();
  const navigate = useNavigate();
  const classes = useStyles();

  const profileMenuItems = [
    {
      label: 'ユーザー設定',
      value: 'profile',
    },
    {
      label: 'ログアウト',
      value: 'logout',
    },
  ];

  const handleActionMenuClick = (item: MenuItemType) => {
    switch (item.value) {
      case 'profile':
        navigate('/account/profile');
        break;

      case 'logout': {
        const userKeys = Object.keys(localStorage).filter((key) => key.startsWith(CACHE_KEY_PREFIX));
        for (const key of userKeys) {
          localStorage.removeItem(key);
        }
        logout();
        break;
      }
    }
  };

  return (
    <PopperMenuButton
      hiddenArrow={true}
      buttonProps={{color: 'inherit', className: classes.linkedButtonIcon}}
      menuItemList={profileMenuItems}
      onMenuClick={handleActionMenuClick}>
      <Avatar src={user?.picture} />
      {!smallViewport && (
        <Typography variant="body1" style={{marginLeft: '16px', color: '#172B4D', fontWeight: 'bold'}}>
          {user?.nickname}
        </Typography>
      )}
    </PopperMenuButton>
  );
};
