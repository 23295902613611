import {Box, makeStyles} from '@material-ui/core';
import React, {useMemo} from 'react';
import {ResultDetailHeader} from './ResultDetailHeader';
import {ResultDetailContent} from './ResultDetailContent';
import {InspectionResultIndex} from '@modules/inspection_results/types';
import {InspectionIndex, InspectionTableIndex} from '@modules/inspections/types';
import {Form, Formik} from 'formik';
import {recordToFormValue} from '@Apps/InspectionResult/common/mapper';
import {InnerLoading} from '@components/molecules/Loading';
import {getFormSchema} from '@Apps/InspectionResult/common/validator';

export const ResultDetail: React.FC<ResultDetailProps> = (props) => {
  const {inspectionResult, inspection, inspectionTable} = props;

  const classes = useStyles();

  const initialValues = useMemo(() => {
    return recordToFormValue(inspectionTable, inspectionResult, inspection.status === 'draft');
  }, [inspection, inspectionTable, inspectionResult]);

  const validationSchema = useMemo(() => {
    if (!inspectionTable) return null;

    return getFormSchema(inspectionTable?.items ?? []);
  }, [inspectionTable]);

  if (!initialValues || validationSchema === null) {
    return <InnerLoading />;
  }
  return (
    <Box className={classes.root}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={() => {}}>
        <Form>
          <ResultDetailHeader />
          <ResultDetailContent
            inspection={inspection}
            inspectionResult={inspectionResult}
            inspectionTable={inspectionTable}
          />
        </Form>
      </Formik>
    </Box>
  );
};

type ResultDetailProps = {
  inspectionResult: InspectionResultIndex;
  inspection: InspectionIndex;
  inspectionTable: InspectionTableIndex;
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    margin: '16px',
  },
}));
