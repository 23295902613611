import React from 'react';
import {ListItem, TextField, Button} from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import {Draggable} from 'react-beautiful-dnd';
import {HospitalProductNoteSettingsOptionsValue} from '@modules/hospital_products/types';

type Props = {
  index: number;
  option: HospitalProductNoteSettingsOptionsValue;
  removeFieldState: 'visible' | 'disabled';
  onChange: (index: number, newOption: HospitalProductNoteSettingsOptionsValue) => void;
  onRemove: (index: number) => void;
};

export const SelectTypeOptionEditListItem = ({index, option, removeFieldState, onChange, onRemove}: Props) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(index, {...option, name: e.target.value});
  };

  const handleRemove = () => {
    onRemove(index);
  };

  return (
    <Draggable draggableId={option.value.toString()} index={index}>
      {(provided) => (
        <ListItem ref={provided.innerRef} {...provided.draggableProps} sx={{padding: 0}}>
          <TextField
            {...provided.dragHandleProps}
            variant="outlined"
            size="small"
            sx={{width: '430px'}}
            inputProps={{sx: {padding: '16px', height: '16px', fontSize: '14px', color: '#172B4D'}}}
            InputProps={{startAdornment: <DragIndicatorIcon sx={{color: '#0000008A', cursor: 'grab'}} />}}
            tabIndex={index + 1}
            value={option.name}
            onChange={handleChange}
          />
          <Button
            variant="text"
            sx={{
              marginLeft: '24px',
              padding: '8px',
              height: '32px',
              fontSize: '14px',
              color: '#C7243A',
            }}
            tabIndex={-1}
            disabled={removeFieldState === 'disabled'}
            onClick={handleRemove}>
            項目を削除
          </Button>
        </ListItem>
      )}
    </Draggable>
  );
};
