import React, {useMemo, Suspense, useCallback} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Typography,
  IconButton,
} from '@material-ui/core';
import {Close} from '@material-ui/icons';
import {Form, Formik, useFormikContext} from 'formik';
import {TextField} from '@molecules/Formik/fields';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import * as yup from 'yup';
import {UserFormatter} from '@modules/hospital_users/helpers';
import {useHospitalUsers} from '@modules/hospital_users/hooks/useHospitalUsers';
import Selector from '@molecules/Formik/fields/Selector';
import {RequiredLabel} from '@molecules/FormRequiredLabel';

const validationSchema = yup.object({
  skipDate: yup.string().required(),
  operator: yup.string().required(),
  reason: yup.string(),
});

type SkipInspectionFormProps = {
  open: boolean;
  defaultUserHashId: string;
  onClose: React.MouseEventHandler;
};

type SkipInspectionFormValue = yup.InferType<typeof validationSchema>;

const SkipInspectionDialogForm: React.FC<SkipInspectionFormProps> = (props) => {
  const {open, defaultUserHashId, onClose} = props;
  const classes = useStyles();
  const {submitForm, isValid} = useFormikContext<SkipInspectionFormValue>();
  const {hospitalUsers} = useHospitalUsers();

  const handleSubmit = useCallback(() => {
    submitForm();
  }, [submitForm]);

  const userOptions = useMemo(
    () => UserFormatter.getOptions(hospitalUsers, {withAlias: true, withSubLabel: true}),
    [hospitalUsers]
  );

  const defaultUser = useMemo(
    () => userOptions.find((o) => o.value === defaultUserHashId),
    [defaultUserHashId, userOptions]
  );

  if (userOptions.length === 0) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="sm">
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Typography className={classes.title} style={{fontSize: '20px', fontWeight: 'bold'}}>
          点検をスキップ
        </Typography>
        <IconButton aria-label="close" onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container className={classes.fields} direction="column">
          <Grid>
            <RequiredLabel>スキップをした日時</RequiredLabel>
            <TextField type="date" name="skipDate" size="small" fullWidth />
          </Grid>
          <Grid>
            <RequiredLabel>スキップ実施者</RequiredLabel>
            <Selector
              name="operator"
              size="small"
              placeholder="点検者"
              options={userOptions}
              defaultValue={defaultUser}
            />
          </Grid>
          <Grid>
            <label>スキップ理由</label>
            <TextField type="text" name="reason" size="small" fullWidth />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button variant="contained" color="primary" onClick={handleSubmit} disabled={!isValid}>
          スキップ
        </Button>
        <Button variant="text" onClick={onClose}>
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export type SkipInspectionDialogProps = DialogProps & {
  defaultOperatorHashId: string;
  defaultSkipDate: string;
};

export type SkipInspectionDialogResult = {
  skipDate: Date;
  operator: string;
  reason?: string;
};

export const SkipInspectionDialog: React.FC<SkipInspectionDialogProps> = (props) => {
  const {open, defaultOperatorHashId, defaultSkipDate} = props;

  const handleSubmit = useCallback(
    async (data: SkipInspectionFormValue) => {
      const {skipDate, operator, reason} = data;
      props.actions.resolve({
        skipDate: new Date(skipDate),
        operator: operator,
        reason: reason !== '' ? reason : undefined,
      });
    },
    [props.actions]
  );

  const handleClose = useCallback(
    (e: React.MouseEvent) => {
      props.actions.reject();
    },
    [props.actions]
  );

  return (
    <Suspense fallback={null}>
      <Formik
        initialValues={{skipDate: defaultSkipDate, operator: defaultOperatorHashId, reason: ''}}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        isInitialValid={true}>
        <Form>
          <SkipInspectionDialogForm open={open} onClose={handleClose} defaultUserHashId={defaultOperatorHashId} />
        </Form>
      </Formik>
    </Suspense>
  );
};

const useStyles = makeStyles(() => ({
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '24px 32px 40px',
  },
  title: {
    color: '#172B4D',
  },
  content: {
    padding: '0px 32px',
  },
  fields: {
    '& > :not(:first-child)': {
      marginTop: '24px',
    },
  },
  inputDate: {
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  actions: {
    padding: '24px 32px 32px',
  },
}));
