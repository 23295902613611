import React, {useState, useMemo, Suspense} from 'react';
import {Box, Typography, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button} from '@material-ui/core';
import {styled} from '@material-ui/styles';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import {Option, Selector} from '@molecules/Selector';
import {productStatusOptions} from '@modules/hospital_products/constants';
import {DateField} from '@molecules/DateField';
import {
  formatAssetRegisterNumber,
  formatLotNumber,
  formatManagementId,
  formatSerialNumber,
} from '@modules/hospital_products/utls';
import {HospitalRoomFormatter} from '@modules/hospital_wards/helpers';
import {HospitalRoom} from '@modules/hospital_places/types';

export type OptionString = {
  label: string;
  value: string;
};

export type OptionStringList = OptionString[];

type EditableColumnModalProps = {
  selected: string[];
  hospitalRooms: HospitalRoom[];
  hospitalDealerOptionsList: OptionStringList;
  hospitalDepartmentOptions: OptionStringList;
} & DialogProps;

type ColumnSelectorProps = {
  options: Option[];
  value: string;
  onChange: (option: Option) => void;
};
type EditableColumnContentProps = {
  column: Column;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  value: any;
  onChange: (type: string, name: string) => void;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  setValue: React.Dispatch<any>;
};

/**
 * 列の選択
 * @param {ColumnSelectorProps} {options, value, onChange}
 */
const ColumnSelector = ({options, value, onChange}: ColumnSelectorProps) => (
  <Box>
    <div>更新する情報</div>
    <Selector value={value} options={options} onChange={onChange} menuPosition={'fixed'} />
  </Box>
);

/**
 * 編集可能な列
 * @param {EditableColumnContentProps} {column, value, onChange,setValue}
 */
const EditableColumnContent = ({column, value, onChange, setValue}: EditableColumnContentProps) => (
  <Box my={3}>
    <div>{column.label}</div>
    {React.createElement(column.InputComponent, {
      name: column.name,
      onChange: onChange(column.type, column.name),
      onBlur: column.formatter
        ? () => {
            if (value && value[column.name]) {
              setValue({[column.name]: column.formatter?.(`${value[column.name]}`)});
            }
          }
        : undefined,
      value: value ? value[column.name] : null,
      ...column.props,
    })}
  </Box>
);

/**
 * 選択された機器情報を編集するためのモーダルダイアログ
 * @param {EditableColumnModalProps} {open, selected, hospitalRooms}
 * @returns
 */
export const EditableColumnModal = ({
  open,
  selected,
  hospitalRooms,
  hospitalDealerOptionsList,
  hospitalDepartmentOptions,
  actions,
}: EditableColumnModalProps) => {
  const [selectColumn, setColumn] = useState<string>('');
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const [selectValue, setValue] = useState<any>(null);

  const handleSave = async () => {
    await actions.resolve(selectValue);
  };

  const handleCancel = () => {
    actions.reject();
  };

  const hospitalRoomOptions = useMemo(
    () => hospitalRooms.map((item) => ({label: HospitalRoomFormatter.getFullRoom(item), value: item.hashId})),
    [hospitalRooms]
  );

  const targetColumn = useMemo<Column | undefined>(() => {
    return columns(hospitalRoomOptions, hospitalDealerOptionsList, hospitalDepartmentOptions).find(
      (col) => col.name === selectColumn
    );
  }, [hospitalDealerOptionsList, hospitalDepartmentOptions, hospitalRoomOptions, selectColumn]);

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const handleChange = (type: string, name: string) => (e: any) => {
    setValue(null);

    switch (type) {
      case 'text':
        setValue({[name]: e.target.value});
        break;
      case 'number':
        setValue({[name]: e.target.value});
        break;
      case 'date':
        // Memo: DateFieldはdateのstringが渡される。
        setValue({[name]: e});
        break;
      case 'select':
        // Memo: Selectorは{value: any, label: any}が渡される。
        setValue({[name]: e.value});
        break;
    }
  };

  const handleChangeColumn = (option: Option) => {
    //Memo: 更新する情報を変更した場合は入っている値をNullにする。
    setValue(null);
    setColumn(option.value);
  };

  const columnOptions = useMemo(
    () =>
      columns(hospitalRoomOptions, hospitalDealerOptionsList, hospitalDepartmentOptions).map((column) => ({
        label: column.label,
        value: column.name,
      })),
    [hospitalDealerOptionsList, hospitalDepartmentOptions, hospitalRoomOptions]
  );

  const canSubmit = useMemo(() => {
    return !!selectColumn;
  }, [selectColumn]);

  return (
    <Suspense fallback={null}>
      <Dialog open={open} fullWidth maxWidth={'md'} PaperProps={{style: {overflowY: 'visible', maxWidth: '500px'}}}>
        <DialogTitle id="scroll-dialog-title">
          <Title variant="h6">{selected.length}件の機器情報を一括情報編集</Title>
        </DialogTitle>
        <DialogContent style={{overflowY: 'visible'}}>
          <ColumnSelector options={columnOptions} value={selectColumn} onChange={handleChangeColumn} />
          {targetColumn && (
            <EditableColumnContent
              column={targetColumn}
              value={selectValue}
              onChange={handleChange}
              setValue={setValue}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Box p={2} width={'100%'} display={'flex'} justifyContent={'flex-end'}>
            <Box mr={2}>
              <Button disabled={!canSubmit} color="primary" variant="contained" onClick={handleSave}>
                保存
              </Button>
            </Box>
            <Box>
              <Button color="primary" variant="outlined" onClick={handleCancel}>
                キャンセル
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </Suspense>
  );
};

type Column = {
  type: string;
  label: string;
  name: string;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  InputComponent: any;
  formatter?: (raw: string) => string;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  props?: any;
};

const columns: (
  hospitalRoomOptions: OptionStringList,
  hospitalDealerOptionsList: OptionStringList,
  hospitalDepartmentOptions: OptionStringList
) => Column[] = (hospitalRoomOptions, hospitalDealerOptionsList, hospitalDepartmentOptions) => [
  {
    type: 'text',
    label: '管理番号',
    name: 'managementId',
    InputComponent: TextField,
    formatter: formatManagementId,
    props: {
      variant: 'outlined',
      style: {width: '100%'},
    },
  },
  {
    type: 'text',
    label: 'シリアル番号',
    name: 'serialNumber',
    InputComponent: TextField,
    formatter: formatSerialNumber,
    props: {
      variant: 'outlined',
      style: {width: '100%'},
    },
  },
  {
    type: 'text',
    label: 'ロット番号',
    name: 'lotNumber',
    InputComponent: TextField,
    formatter: formatLotNumber,
    props: {
      variant: 'outlined',
      style: {width: '100%'},
    },
  },
  {
    type: 'select',
    label: '貸出区分',
    name: 'permanentlyAssigned',
    InputComponent: Selector,
    props: {
      options: [
        {label: '貸出不可', value: true},
        {label: '貸出可', value: false},
      ],
    },
  },
  {
    type: 'select',
    label: '稼働状況',
    name: 'status',
    InputComponent: Selector,
    props: {
      options: productStatusOptions,
    },
  },
  {
    type: 'select',
    label: '管理部署',
    // NOTE:ACC-316の要件にて管理部署と所属部署のデータを統合。データ統合に伴い、keyをhospitalDepartmentHashIdに変更している。
    name: 'hospitalDepartmentHashId',
    InputComponent: Selector,
    props: {
      options: hospitalDepartmentOptions,
    },
  },
  {
    type: 'select',
    label: '機器管理場所',
    name: 'hospitalRoomHashId',
    InputComponent: Selector,
    props: {
      options: hospitalRoomOptions,
    },
  },
  {
    type: 'date',
    label: '購入日',
    name: 'dateOfPurchase',
    InputComponent: DateField,
    props: {
      style: {width: '100%'},
    },
  },
  {
    type: 'select',
    label: '親機・子機',
    name: 'isBaseUnit',
    InputComponent: Selector,
    props: {
      options: [
        {label: '親機', value: true},
        {label: '子機', value: false},
      ],
    },
  },
  {
    type: 'select',
    label: '担当代理店',
    name: 'hospitalDealerHashId',
    InputComponent: Selector,
    props: {
      options: hospitalDealerOptionsList,
    },
  },
  {
    type: 'select',
    label: '購入区分',
    name: 'waysOfPurchase',
    InputComponent: Selector,
    props: {
      options: [
        {label: '購入', value: 'purchase'},
        {label: 'リース', value: 'lease'},
        {label: 'レンタル', value: 'rental'},
        {label: '代替品', value: 'alternative'},
        {label: 'デモ機', value: 'demo'},
        {label: '寄贈', value: 'gift'},
        {label: '移管', value: 'transfer_control'},
      ],
    },
  },
  {
    type: 'number',
    label: '院内耐用年数（年）',
    name: 'legalDurableYear',
    InputComponent: TextField,
    props: {
      variant: 'outlined',
      style: {width: '100%'},
      type: 'number',
    },
  },
  {
    type: 'select',
    label: '保守契約',
    name: 'isMaintenanceContract',
    InputComponent: Selector,
    props: {
      options: [
        {label: '保守契約', value: true},
        {label: '保守契約外', value: false},
      ],
    },
  },
  {
    type: 'text',
    label: '資産番号',
    name: 'assetRegisterNumber',
    InputComponent: TextField,
    formatter: formatAssetRegisterNumber,
    props: {
      variant: 'outlined',
      style: {width: '100%'},
    },
  },
  // {
  //   type: 'number',
  //   label: '減価償却費（円）',
  //   name: 'depreciationAmount',
  //   InputComponent: TextField,
  //   props: {
  //     variant: 'outlined',
  //     style: {width: '100%'},
  //     type: 'number',
  //   },
  // },
  // {
  //   type: 'number',
  //   label: '帳簿価格（円）',
  //   name: 'bookValue',
  //   InputComponent: TextField,
  //   props: {
  //     variant: 'outlined',
  //     style: {width: '100%'},
  //     type: 'number',
  //   },
  // },
  {
    type: 'date',
    label: '廃棄日',
    name: 'dateOfDisposal',
    InputComponent: DateField,
    props: {
      style: {width: '100%'},
    },
  },
  {
    type: 'textarea',
    label: '廃棄理由',
    name: 'reasonOfDisposal',
    InputComponent: TextField,
    props: {
      variant: 'outlined',
      style: {width: '100%'},
    },
  },
  {
    type: 'date',
    label: '点検開始日',
    name: 'periodicInspectionStartDate',
    InputComponent: DateField,
    props: {
      style: {width: '100%'},
    },
  },
  // NOTE:備考の汎用化により次回スコープ対応までコメントアウト
  // {
  //   type: 'textarea',
  //   label: '備考1',
  //   name: 'notes',
  //   InputComponent: TextField,
  //   props: {
  //     variant: 'outlined',
  //     multiline: true,
  //     style: {width: '100%'},
  //   },
  // },
  // {
  //   type: 'textarea',
  //   label: '備考2',
  //   name: 'notes2',
  //   InputComponent: TextField,
  //   props: {
  //     variant: 'outlined',
  //     multiline: true,
  //     style: {width: '100%'},
  //   },
  // },
  // {
  //   type: 'textarea',
  //   label: '備考3',
  //   name: 'notes3',
  //   InputComponent: TextField,
  //   props: {
  //     variant: 'outlined',
  //     multiline: true,
  //     style: {width: '100%'},
  //   },
  // },
];

const Title = styled(Typography)({
  fontWeight: 'bold',
});
