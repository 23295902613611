import React from 'react';
import {styled, Box, Button, Dialog, DialogActions, DialogTitle, IconButton, Typography} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import CloseIcon from '@mui/icons-material/Close';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';

type AlertDialogProps = DialogProps & {
  title: string;
  content: React.ReactNode;
};

const Message = styled(Box)({
  marginTop: '24px',
  '& .MuiTypography-root': {
    fontSize: 'inherit',
  },
});

const Actions = styled(DialogActions)({
  marginTop: '40px',
  '& > button:nth-of-type(n+2)': {
    marginLeft: '16px',
  },
});

export const AlertDialog = ({open, actions, title, content}: AlertDialogProps) => {
  const handleClose = () => {
    actions.reject();
  };

  const handleCancel = () => {
    actions.reject();
  };

  const handleSubmit = () => {
    actions.resolve(true);
  };

  return (
    <Dialog
      maxWidth="lg"
      open={!!open}
      PaperProps={{
        sx: {position: 'relative', minWidth: '634px', padding: '32px', fontSize: '14px', color: ' #172B4D'},
      }}>
      <DialogTitle sx={{display: 'flex', alignItems: 'center', padding: 0, fontWeight: 700}}>
        <ErrorIcon sx={{marginRight: '8px', color: '#C7243A'}} />
        {title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          fontSize: '24px',
          right: '16px',
          top: '24px',
          color: 'inherit',
        }}>
        <CloseIcon />
      </IconButton>
      <Message>{content}</Message>
      <Actions>
        <Button
          variant="contained"
          sx={{backgroundColor: '#C7243A', '&:hover': {backgroundColor: '#C7243A'}}}
          onClick={handleSubmit}>
          実行
        </Button>
        <Button variant="text" sx={{color: '#172B4D'}} onClick={handleCancel}>
          キャンセル
        </Button>
      </Actions>
    </Dialog>
  );
};
