import React from 'react';
import {useBackPrevious} from '@front-libs/core';
import {Button, styled} from '@material-ui/core';
import {ChevronLeft} from '@material-ui/icons';

const NavigationButton = styled(Button)({
  fontWeight: 400,
  fontSize: '14px',
});
const NavigationArea = styled('div')({
  width: '100%',
  height: '44px',
  display: 'flex',
  justifyContent: 'space-between',
});

const NavigationButtonContainer = styled('div')({
  height: '100%',
  display: 'flex',
  margin: '0px 20px',
});

export const Header = () => {
  const backPrevious = useBackPrevious('/repairs/fault_receptions');
  const handleClickProductsLink = () => {
    backPrevious();
  };
  return (
    <NavigationArea>
      <NavigationButtonContainer>
        <NavigationButton onClick={handleClickProductsLink}>
          <ChevronLeft />
          不具合受付一覧に戻る
        </NavigationButton>
      </NavigationButtonContainer>
    </NavigationArea>
  );
};
