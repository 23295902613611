import {Box, SxProps} from '@mui/material';
import React from 'react';
import {Droppable} from 'react-beautiful-dnd';
import {LayoutSequenceType} from '..';
import {COLUMN_SIZE, ROW_SIZE, SECTION_SIZE} from '../consts';
import {RentalDnDCard} from '../RentalDnDCard';

type SectionProps = {
  section: LayoutSequenceType[];
  sectionIndex: number;
  isDnDEnabled: boolean;
};

export const RentalDnDSection = ({section, sectionIndex, isDnDEnabled}: SectionProps) => {
  return (
    <Box key={`section-${sectionIndex}`} sx={sectionContainerStyle}>
      <Box sx={sectionTitleStyle}>{sectionIndex + 1}ページ目</Box>
      {section.map((row, rowIndex) => (
        <Droppable droppableId={`row-${sectionIndex * COLUMN_SIZE + rowIndex}`} key={rowIndex} direction="horizontal">
          {(provided) => (
            <Box ref={provided.innerRef} {...provided.droppableProps} sx={cardsContainerStyle}>
              {row.map((item, index) => (
                <RentalDnDCard
                  key={item.name}
                  title={item.name}
                  index={sectionIndex * SECTION_SIZE + rowIndex * ROW_SIZE + index}
                  isDnDEnabled={isDnDEnabled}
                />
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      ))}
    </Box>
  );
};

// スタイル定義
const sectionContainerStyle = {
  width: 'fit-content',
  marginBottom: '40px',
};

const cardsContainerStyle: SxProps = {
  display: 'grid',
  gridTemplateColumns: 'repeat(6, 1fr)',
  gap: '16px',
};

const sectionTitleStyle: SxProps = {
  color: '#172B4D',
  fontSize: '14px',
  fontWeight: 700,
  borderBottom: '1px solid #172B4D',
  margin: '8px',
  padding: '8px',
};
