import {RepairStatusState, RepairStatusAction, RepairStatusActionType} from './types';

export const repairStatusInitialState: RepairStatusState = {
  map: new Map(),
};

export const repairStatusReducer = (state: RepairStatusState, action: RepairStatusAction): RepairStatusState => {
  switch (action.type) {
    case RepairStatusActionType.REPAIR_STATUS_SET_MAP: {
      return {
        ...state,
        map: action.payload.map,
      };
    }
    default:
      return state;
  }
};
