import React, {useState, useMemo, useCallback, Suspense} from 'react';
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions as MDialogActions,
  Button,
  styled,
} from '@mui/material';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import {Option, Selector} from '@molecules/Selector';
import {EditableColumnList, MultipleEditableColumnNames} from './ColumnList';
import {useGetCategoriesOptions} from '@modules/hospital_whole_products_bulk_update/hooks';
import {MakerSelect} from './MakerSelect';

type ColumnType = 'text' | 'textarea' | 'number' | 'date' | 'select';

type ValueType = {
  [key: string]: string | number | boolean;
};

export type Column = {
  type: ColumnType;
  label: string;
  name: string;
  InputComponent: React.ElementType;
  props?: Record<string, unknown>;
};

export type EditableColumnModalProps = {
  selected: string[];
} & DialogProps;

type RenderInputComponentProps = {
  targetColumn: Column;
  selectValue: ValueType | undefined;
  handleChange: (
    type: Column['type'],
    name: string
  ) => (e: React.ChangeEvent<HTMLInputElement> | string | ValueType | undefined) => void;
};

type ClassificationElementsProps = {
  onChange: (option: Option<string> | undefined) => void;
};

/**
 * 大分類・小分類用コンポーネント
 */
const ClassificationElements = ({onChange}: ClassificationElementsProps) => {
  const [broadCategoryHashId, setBroadCategoryHashId] = useState<string>();
  const [narrowCategoryHashId, setNarrowCategoryHashId] = useState<string | null>(null);
  const {rootCategoryOptions, narrowCategoryOptions} = useGetCategoriesOptions(broadCategoryHashId);

  return (
    <FlexDiv>
      <div>大分類</div>
      <Selector
        onChange={(option: Option<string>) => {
          setNarrowCategoryHashId(null);
          setBroadCategoryHashId(option.value);
          onChange(undefined);
        }}
        value={broadCategoryHashId ?? ''}
        options={rootCategoryOptions}
      />
      <MarginDiv>小分類</MarginDiv>
      <Selector
        onChange={(option: Option<string>) => {
          setNarrowCategoryHashId(option.value);
          onChange(option);
        }}
        value={narrowCategoryHashId}
        options={narrowCategoryOptions ?? []}
      />
    </FlexDiv>
  );
};

const RenderInputComponent = ({targetColumn, selectValue, handleChange}: RenderInputComponentProps) => {
  if (targetColumn.name === 'narrowCategoryHashId')
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <ClassificationElements
          onChange={(options) => {
            const func = handleChange(targetColumn.type, targetColumn.name);
            func(options);
          }}
        />
      </Suspense>
    );

  if (targetColumn.name === 'makerHashId')
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <MakerSelect
          onChangeMaker={(requestName, value) => {
            const func = handleChange(targetColumn.type, requestName);
            func(value);
          }}
        />
      </Suspense>
    );
  return (
    <>
      <div>{targetColumn.label}</div>
      {React.createElement(targetColumn.InputComponent, {
        name: targetColumn.name,
        onChange: handleChange(targetColumn.type, targetColumn.name),
        value: selectValue ? selectValue[targetColumn.name] : null,
        ...targetColumn.props,
      })}
    </>
  );
};

/**
 * 選択された院内マスター機器情報を編集するためのモーダルダイアログ。
 *
 * @param {EditableColumnModalProps} props - コンポーネントへのprops
 * @param {boolean} props.open - ダイアログが開いているかどうか
 * @param {string[]} props.selected - 選択された機器のIDリスト
 * @param {DialogProps} props.actions - ダイアログのアクションハンドラ
 * @returns {React.FC<EditableColumnModalProps>} 院内マスター機器情報を一括編集するためのモーダルダイアログのReact関数型コンポーネント
 */
export const EditableColumnModal: React.FC<EditableColumnModalProps> = ({open, selected, actions}) => {
  const [selectColumn, setColumn] = useState<string>('');
  const [selectValue, setValue] = useState<ValueType | undefined>(undefined);

  // セレクトボックスの項目リスト 複数選択と単一選択で編集項目が変わる
  const editableColumnList = useMemo(
    () =>
      selected.length === 1
        ? EditableColumnList
        : EditableColumnList.filter((v) => MultipleEditableColumnNames.includes(v.name)),
    []
  );

  const handleSave = useCallback(async () => {
    await actions.resolve(selectValue);
  }, [actions, selectValue]);

  const handleCancel = useCallback(() => {
    // キャンセル時はundefinedを渡す
    actions.resolve(undefined);
  }, [actions]);

  /**
   * セレクトボックスのオプション表示用リスト
   */
  const targetColumn = useMemo<Column | undefined>(() => {
    return editableColumnList.find((col) => col.name === selectColumn);
  }, [editableColumnList, selectColumn]);

  const handleChange = useCallback(
    (type: Column['type'], name: string) =>
      (e: React.ChangeEvent<HTMLInputElement> | string | ValueType | undefined) => {
        setValue(undefined);

        if (e === undefined) return;
        if (typeof e === 'string') {
          setValue({[name]: e});
        } else if ((e as ValueType).label) {
          setValue({[name]: (e as ValueType).value});
        } else {
          const value = (e as React.ChangeEvent<HTMLInputElement>).target.value;
          setValue({[name]: type === 'number' ? Number(value) : value});
        }
      },
    [selectValue]
  );

  const handleChangeColumn = (option: Option) => {
    //NOTE: 更新する情報を変更した場合は入っている値をundefinedにする。
    setValue(undefined);
    setColumn(option.value);
  };

  return (
    <Dialog open={!!open} fullWidth maxWidth={'md'} PaperProps={{style: {overflowY: 'visible', maxWidth: '500px'}}}>
      <DialogTitle id="scroll-dialog-title">
        <Title>{selected.length}件の機器情報を一括情報編集</Title>
      </DialogTitle>
      <DialogContent style={{overflowY: 'visible'}}>
        <Box>
          <div>編集する情報</div>
          <Selector
            value={selectColumn}
            options={editableColumnList.map((column) => {
              return {
                label: column.label,
                value: column.name,
              };
            })}
            onChange={handleChangeColumn}
            menuPosition={'fixed'}
          />
        </Box>
        {targetColumn && (
          <Box my={3}>
            <RenderInputComponent targetColumn={targetColumn} selectValue={selectValue} handleChange={handleChange} />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Box p={2} width={'100%'} display={'flex'} justifyContent={'flex-end'}>
          <Box mr={2}>
            <Button disabled={!selectValue} color="primary" variant="contained" onClick={handleSave}>
              保存
            </Button>
          </Box>
          <Box>
            <Button color="primary" variant="outlined" onClick={handleCancel}>
              キャンセル
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const DialogActions = styled(MDialogActions)({
  background: '#FFF',
  padding: 'initial',
});

const Title = styled(Typography)({
  fontWeight: 'bold',
});
const FlexDiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});
const MarginDiv = styled('div')({
  marginTop: 8,
});
