import React from 'react';
import {Theme, Typography} from '@material-ui/core';
import {styled} from '@material-ui/core/styles';
import {FaultReceptionElement} from '../FaultReceptionList/FaultReceptionTable';

type Props = {
  rowData: FaultReceptionElement;
};

export const ReceptionIdColumn = ({rowData}: Props) => {
  return <StyledText>{rowData.receptionId}</StyledText>;
};

const StyledText = styled(Typography)(({theme}: {theme: Theme}) => ({
  padding: '0px',
  display: 'inline',
  color: theme.palette.info.dark,
  fontSize: '14px',
  fontWeight: 'bold',
}));
