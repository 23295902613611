import {Grid, SxProps} from '@mui/material';
import {RentalSelectionCard} from './RentalSelectionCard';
import {SelectionSwiper} from './SelectionSwiper';
import React from 'react';
import {Box} from '@material-ui/core';

const rootStyle: SxProps = {
  padding: '0px 56px',
  margin: 'auto',
  width: 'calc(100% - 112px)',
};

const getGridStyles = (dataLength: number): SxProps => ({
  ...rootStyle,
  justifyContent: dataLength > 4 ? 'flex-start' : 'center',
  flexWrap: dataLength > 4 ? 'wrap' : 'nowrap',
});

const getFlexBasis = (dataLength: number) => {
  return dataLength > 8 ? '16.66%' : '25%';
};
const SelectionGrid = <T,>({data, onSelect, getLabel}: Props<T>) => {
  const dataLength = data.length === 0 ? 0 : data[0].length;
  if (dataLength === 0) return null;
  return (
    <Grid container justifyContent={'center'} alignItems="center" sx={getGridStyles(dataLength)} spacing={3}>
      {data[0].map((value, index) => (
        <Grid item key={index} sx={{flexBasis: getFlexBasis(dataLength)}}>
          <RentalSelectionCard<T> label={getLabel(value)} itemsCount={dataLength} value={value} onChange={onSelect} />
        </Grid>
      ))}
    </Grid>
  );
};

type Props<T> = {
  data: T[][];
  onSelect: (value: T) => void;
  getLabel: (value: T) => string;
};

/**
 * 貸出メニュー等の大エリア、小エリア、階数などの選択画面を表示するコンポーネント
 * データが12以下の場合はグリッド形式、それ以上はスワイパーで表示
 *
 * @template T データの型。
 * @param {Props<T>} props - コンポーネントに渡されるプロパティ。
 * @param {T[][]} props.data - 表示するデータの配列。
 * @param {(value: T) => void} props.onSelect - データが選択されたときに呼び出される関数。
 * @param {(value: T) => string} props.getLabel - データのラベルを取得するための関数。
 */
export const SelectionDisplay = <T,>(props: Props<T>) => {
  const {data, onSelect, getLabel} = props;

  if (data.length === 0) return null;
  return (
    <>
      {data[0] && data[0].length <= 12 ? (
        <SelectionGrid {...props} />
      ) : (
        <Box sx={rootStyle}>
          <SelectionSwiper<T> data={data} getLabel={(item) => getLabel(item)} onSelect={(item) => onSelect(item)} />
        </Box>
      )}
    </>
  );
};
