import React, {useMemo} from 'react';
import {SameHospitalProductDialog} from '@components/organisms/FaultRepairs/dialogs/SameHospitalProductDialog';
import {yup} from '@front-libs/core';
import {convertDateToSimpleDateTime} from '@front-libs/helpers';

import {getHospitalProductFaultRepairHistories} from '@modules/hospital_products/api';
import {SymptomCategoryType, SymptomDetailCategoryType} from '@modules/repairs/types';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import dayjs from 'dayjs';
import {Formik} from 'formik';
import {RepairForm} from './RepairForm';
import {useRegistrationRepairData} from './hooks';

export type RegistrationRepairFormProps = {
  hospitalProductHashId: string;
  symptomCategory?: SymptomCategoryType;
  symptomDetailCategory?: SymptomDetailCategoryType;
  causeOfIssueDetail?: string;
  requestUserHashId?: string;
  requests?: string;
  requestForRepairAt?: string;
  substitutionHospitalProductHashId?: string;
  statusHashId?: string;
};

type RegistrationRepairProp = {
  onSubmit: (res: Partial<RegistrationRepairFormProps>) => void;
  onClose: VoidFunction;
};
/**
 * 修理フォームのコンテナ
 * components/organisms/FaultRepairs/dialogs/RegistrationRepairDialog.tsxとほぼ同じ
 * @param props
 * @returns
 */
export const RegistrationRepair: React.FC<RegistrationRepairProp> = ({onSubmit, onClose}) => {
  const {formFields, repairStatuses, myInfo} = useRegistrationRepairData();
  const validationSchema = useMemo(
    () =>
      yup.object({
        ...formFields.reduce((obj: Record<string, yup.StringSchema>, item) => {
          obj[item.value] = item.isRequired ? yup.string().required() : yup.string();
          return obj;
        }, {}),
        hospitalProductHashId: yup.string().required(),
      }),
    [formFields]
  );

  const handleSubmit = async (res: Partial<RegistrationRepairFormProps>) => {
    const {
      data: {data: repairHistories},
    } = await getHospitalProductFaultRepairHistories(myInfo.hospitalHashId, res.hospitalProductHashId ?? '', {
      order: '-created_at',
    });

    const excludeStatuses = repairStatuses
      .filter((item) => ['complete', 'archive'].includes(item.statusType))
      .map((item) => item.hashId);

    // repairHistories の件数が何件かあって、完了 or 廃棄ではない場合に重複ダイアログを上げる
    if (repairHistories.length > 0 && !excludeStatuses.includes(repairHistories[0].statusHashId)) {
      const status = repairStatuses.find((item) => item.hashId === repairHistories[0].statusHashId);
      try {
        await dialogHandler.open(SameHospitalProductDialog, {
          managementId: repairHistories[0].faultHospitalProduct.managementId,
          statusName: status?.statusName ?? '不明なステータス',
        });
      } catch (_e) {
        onClose();
        return;
      }
    }

    onSubmit({
      ...res,
      // symptomDetailCategory は現状 internal_failure の場合のみ設定する。それ以外は undefined にする
      symptomDetailCategory: res.symptomCategory === 'internal_failure' ? res.symptomDetailCategory : undefined,
    });
  };
  const initialValues: Partial<RegistrationRepairFormProps> = useMemo(
    () => ({
      requestForRepairAt: convertDateToSimpleDateTime(dayjs().toDate()),
      // NOTE:空文字を送るとエラーになるので初期値のWarningエラーは折を見て修正
      // requestUserName: '',
      // hospitalProductHashId: '',
      // causeOfIssueDetail: '',
      // requestUserHashId: '',
      // requests: '',
      // substitutionHospitalProductHashId: '',
      // statusHashId: '',
    }),
    []
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnMount={true}>
      <RepairForm formFields={formFields} hospitalHashId={myInfo.hospitalHashId} onClose={onClose} />
    </Formik>
  );
};
