import {WardAssetMenuTemplate} from '@components/templates/WardAssetMenuTemplate';
import {type HospitalRoom} from '@modules/hospital_places/types';
import {useAtom, useAtomValue} from 'jotai';
import {chunk} from 'lodash';
import React, {useEffect, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {useFetchHospitalRooms} from '@modules/hospital_places/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {selectedReturnWaitingAreaInfoAtom, selectedReturnWaitingReceptionAtom} from '../states';
import {SelectionDisplay} from '../SelectionDisplay';
import {MAX_SELECTION_PER_PAGE, ReceptionSelectionMap} from '../consts';
import {getFloorDescription} from '../FloorSelection';

/**
 * 返却待ち受付 - 階数選択画面
 */
export const ReturnWaitingReceptionFloorSelection = () => {
  const navigate = useNavigate();
  const {myInfo} = useMyInfo();
  const [areaInfoAtom, setAreaInfoAtom] = useAtom(selectedReturnWaitingAreaInfoAtom);
  const selectItem = useAtomValue(selectedReturnWaitingReceptionAtom);

  useEffect(() => {
    if (!selectItem.narrowCategoryName || !areaInfoAtom.ward) navigate(ReceptionSelectionMap.rootPath);
  }, []);

  const {data: roomData} = useFetchHospitalRooms(myInfo.hospitalHashId, {
    page: 0,
    perPage: 100,
    hospitalWardHashId: areaInfoAtom.ward?.hashId,
    showRentalPlace: true,
    order: 'floor_number',
  });
  const filteredRooms = useMemo(() => {
    const roomMap: Map<string, HospitalRoom> = new Map();
    roomData.forEach((room) => {
      const key = `${room.floorNumber}:${room.isGroundFloor}`;
      if (!roomMap.has(key)) {
        roomMap.set(key, room);
      }
    });
    return chunk([...roomMap.values()], MAX_SELECTION_PER_PAGE);
  }, [roomData]);

  const onSelect = (value: HospitalRoom) => {
    setAreaInfoAtom({...areaInfoAtom, floor: {floorNumber: value.floorNumber, isGroundFloor: value.isGroundFloor}});
    navigate(ReceptionSelectionMap.path.roomSelection);
  };

  return (
    <WardAssetMenuTemplate
      reservationDeviceName={selectItem?.narrowCategoryName}
      breadcrumbs={[areaInfoAtom.ward?.name || '']}
      headerProps={{title: ReceptionSelectionMap.title}}
      footerProps={{
        text: ReceptionSelectionMap.footerTitle.selection,
        backButtonHandler: () => navigate(ReceptionSelectionMap.path.wardSelection),
      }}>
      <SelectionDisplay<HospitalRoom>
        data={filteredRooms}
        getLabel={(item) => getFloorDescription(item)}
        onSelect={(item) => onSelect(item)}
      />
    </WardAssetMenuTemplate>
  );
};
