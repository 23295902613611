import {InspectionResultState, InspectionResultAction, InspectionResultActionType} from './types';

export const inspectionResultInitialState: InspectionResultState = {
  results: [],
};

export const inspectionResultReducer = (
  state: InspectionResultState,
  action: InspectionResultAction
): InspectionResultState => {
  switch (action.type) {
    case InspectionResultActionType.INSPECTION_RESULT_SET_LIST: {
      return {
        ...state,
        results: action.payload.results,
      };
    }
    default:
      return state;
  }
};
