import {axios} from '@front-libs/core';
import {returnsReservationRequest, returnsReservationRequestParam, ReturnWaitingReceptionsResponse} from './type';
import {ReturnsReservationRequest} from './type';

/**
 * 返却待ち受付保存API
 * @param hospitalHashId
 * @param params returnsReservationRequest
 */
export const postReturnsReservation = async (hospitalHashId: string, params: ReturnsReservationRequest) => {
  params;
  const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/return_waiting_receptions`;
  await axios.post(baseUrl, params);
};

/**
 * 返却待ち受付 取得API
 * @param hospitalHashId
 */
export const getReturnWaitingReceptions = async (hospitalHashId: string, params: returnsReservationRequestParam) => {
  const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/return_waiting_receptions`;
  return await axios.get<ReturnWaitingReceptionsResponse[]>(baseUrl, {params});
};
