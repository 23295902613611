import React, {useEffect} from 'react';
import {styled, Table, TableHead, TableBody, TableRow, TableCell, Radio} from '@mui/material';
import dayjs from 'dayjs';
import {RepairIndex} from '@modules/repairs/types';
import {getSymptomCategoryLabel} from '@modules/repairs/constants';
import {useFaultRepairMenuStore} from '../../../hooks';

type Props = {
  repairs: RepairIndex[];
  selectedRepairHashId: string | null;
  onSelect: (hashId: string) => void;
};

const Th = styled(TableCell)({
  border: 0,
  padding: '8px',
  color: 'inherit',
});
const Td = styled(TableCell)({
  border: 0,
  padding: '8px',
  color: 'inherit',
});

export const FaultRepairSelector = ({repairs, selectedRepairHashId, onSelect}: Props) => {
  const {listRepairStatuses, findRepairStatusByStatusHashId} = useFaultRepairMenuStore();
  useEffect(() => {
    listRepairStatuses();
  }, []);

  const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSelect(event.target.value);
  };

  return (
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          <Th />
          <Th align="left">修理番号</Th>
          <Th align="left">修理受付日時</Th>
          <Th align="left">事象区分</Th>
          <Th align="left">ステータス</Th>
        </TableRow>
      </TableHead>
      <TableBody>
        {repairs.map((repair) => (
          <TableRow key={repair.hashId}>
            <Td align="center">
              <Radio
                sx={{padding: 0}}
                name="repair-radio-buttons"
                value={repair.hashId}
                checked={repair.hashId === selectedRepairHashId}
                onChange={handleSelect}
              />
            </Td>
            <Td align="left">{repair.repairRegistrationNumber}</Td>
            <Td align="left">
              {repair.requestForRepairAt && dayjs(repair.requestForRepairAt).format('YYYY/MM/DD HH:mm')}
            </Td>
            <Td align="left">{getSymptomCategoryLabel(repair.symptomCategory ?? undefined)}</Td>
            <Td align="left">{findRepairStatusByStatusHashId(repair.statusHashId)?.statusName}</Td>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
