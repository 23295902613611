import {DependencyList, useCallback, useEffect, useState} from 'react';
import _ from 'lodash';

export const useDebounceMemo = <T>(factory: () => T, deps: DependencyList | undefined, debounce: number): T => {
  const [state, setState] = useState(factory());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetState = useCallback(_.debounce(setState, debounce), []);
  useEffect(() => {
    debouncedSetState(factory());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
  return state;
};
