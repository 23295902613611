import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import {SnackbarProvider, useSnackbar, OptionsObject, VariantType, SnackbarKey} from 'notistack';
import {StylesProvider} from '@material-ui/styles';
import {IconButton} from '@material-ui/core';
import {Close, ErrorOutline, CheckCircleOutline} from '@material-ui/icons';
// render snackbar DOM into div element in index.html
const mountPoint = document.getElementById('snackbar');

const defaultEnqueueOptions = {
  autoHideDuration: 5000,
};

/**
 * スナックバーを表示する
 * 新規作成時はMuiV5に対応したSnackBarを使用してください(import {openSnackBar} from '@front-libs/ui';)
 * @param msg - スナックバーに表示するメッセージ
 * @param variant - スナックバーのタイプ。大体successとかをよく使うはずなのでデフォルトはsuccess
 * @param horizontal - スナックバーの水平表示位置。大体左下のはずなのでleftがデフォルト
 * @param vertical - スナックバーの垂直表示位置。大体左下のはずなのでbottomがデフォルト
 * @param options - スナックバーのオプション。 OptionsObject を参照(variantは除く)
 */
export const openSnackBar = (
  msg: string,
  horizontal: 'left' | 'center' | 'right' = 'left',
  vertical: 'top' | 'bottom' = 'bottom',
  variant: VariantType = 'success',
  options: OptionsObject = {}
) => {
  const ShowSnackbar: React.FC<{message: string; opts?: OptionsObject}> = ({message, ...opts}) => {
    const {enqueueSnackbar} = useSnackbar();
    useEffect(() => {
      enqueueSnackbar(message, {variant, ...defaultEnqueueOptions, ...opts});
    }, [message, opts, enqueueSnackbar]);
    return null;
  };

  ReactDOM.render(
    <StylesProvider injectFirst>
      <SnackbarProvider
        action={(key) => <SnackbarCloseButton id={key} />}
        iconVariant={{
          success: <CheckCircleOutline style={{marginRight: '8px'}} />,
          error: <ErrorOutline style={{marginRight: '8px'}} />,
        }}
        maxSnack={3}
        anchorOrigin={{horizontal, vertical}}>
        <ShowSnackbar message={msg} {...options} />
      </SnackbarProvider>
    </StylesProvider>,
    mountPoint
  );
};
const SnackbarCloseButton = ({id}: {id: SnackbarKey}) => {
  const {closeSnackbar} = useSnackbar();

  return (
    <IconButton
      onClick={() => {
        closeSnackbar(id);
      }}>
      <Close style={{color: 'white'}} />
    </IconButton>
  );
};
