import {Box, CSSObject} from '@mui/material';
import React from 'react';
import {RecommendWholeProductResult} from '@modules/whole_products/type';

type Props = {
  searchResult: RecommendWholeProductResult;
};

export const ResultDisplay = ({searchResult}: Props) => {
  const MAX_TITLE_LENGTH = 20;
  const MAX_FIELD_LENGTH = 12;
  return (
    <Box sx={{display: 'flex'}}>
      <Box
        sx={{
          p: 2,
          border: '1px solid #D1D5DB',
          borderRadius: 2,
          width: '268px',
          height: '156px',
          m: 3,
          fontSize: 14,
          lineHeight: '20px',
        }}>
        <Box sx={{fontWeight: 600, color: '#172B4D', mb: 2}}>
          {changeText(searchResult.recommendDisplayName, MAX_TITLE_LENGTH)}
        </Box>
        <LabelAndValue label="大分類" value={searchResult.recommendRootCategory.name} />
        <LabelAndValue label="小分類" value={searchResult.recommendNarrowCategory.name} />
        <LabelAndValue label="販売名" value={changeText(searchResult.recommendDisplayName, MAX_FIELD_LENGTH)} />
        <LabelAndValue label="メーカー名" value={changeText(searchResult.recommendMakerName, MAX_FIELD_LENGTH)} />
        <LabelAndValue label="JANコード" value={searchResult.recommendJanCode} />
        <LabelAndValue label="承認番号" value={searchResult.recommendApprovalNumber} />
      </Box>
    </Box>
  );
};

const changeText = (text: string | undefined, maxLength: number) => {
  if (!text) return '';
  return text.length > maxLength ? text.substring(0, maxLength - 1) + '…' : text;
};

type LabelAndValueProps = {
  label: string;
  value: string | undefined;
};

const LabelAndValue = ({label, value}: LabelAndValueProps) => {
  return (
    <Box>
      <Box sx={{width: 80, display: 'inline-block'}}>{label}</Box>：{value ?? ''}
    </Box>
  );
};
