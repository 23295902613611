import {TrainingPlan} from '@modules/training_plans/types';
import {UserIndex} from '@modules/hospital_users/types';
import {WholeProduct} from '@organisms/HospitalProductImporter';

export type ScheduleStatus = 'done' | 'incomplete';

export type TrainingSchedule = {
  hashId: string;
  currentTimes: number;
  trainingPlan: TrainingPlan;
  status: ScheduleStatus;
  startAt: Date;
  finishAt: Date;
  place?: string;
  numberOfTrainees?: number;
  createdBy: UserIndex;
  updatedBy: UserIndex;
  wholeProducts: WholeProduct[];
};

export type TrainingReport = {
  hashId: string;
  description: string;
  numberOfAttendants: number;
  trainingSchedule: TrainingSchedule;
  reportedHospitalUser: UserIndex;
  startedAt: Date;
  finishedAt: Date;
  createdBy: UserIndex;
  updatedBy: UserIndex;
};

export enum TrainingScheduleActionType {
  TRAINING_SCHEDULE_SET_LIST = 'TRAINING_SCHEDULE_SET_LIST',
}

export type TrainingScheduleSetListAction = {
  type: TrainingScheduleActionType.TRAINING_SCHEDULE_SET_LIST;
  payload: {
    schedules: TrainingSchedule[];
  };
};

export type TrainingScheduleAction = TrainingScheduleSetListAction;

export type TrainingScheduleState = {
  schedules: TrainingSchedule[];
};
