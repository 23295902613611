import {useSearchParams} from '@front-libs/core';
import qs from 'qs';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {queryParamTargetKeys} from '../common/const';
import {QueryParams} from '../common/type';

/**
 * フロントに付与されたクエリパラメータを取得する。
 */
export const useQueryParams = (): QueryParams => {
  const searchParams = useSearchParams();

  // 対象のキーだけを抽出する
  const filteredParams: QueryParams = {};
  queryParamTargetKeys.forEach((key) => {
    const value = searchParams[key];
    if (value !== undefined) {
      filteredParams[key] = Array.isArray(value) ? value[0] || null : value;
    }
  });

  return filteredParams;
};

export const useQueryUpdateParams = (initialQueryParams: QueryParams) => {
  const [queryParams, setQueryParams] = useState<QueryParams>(initialQueryParams);
  const navigate = useNavigate();

  // クエリパラメータを更新する関数
  const updateQueryParams = (newParams: QueryParams) => {
    const updatedParams = {...queryParams, ...newParams};
    for (const key in updatedParams) {
      if (updatedParams[key] === '') {
        delete updatedParams[key];
      }
    }
    setQueryParams(updatedParams);
    const search = qs.stringify(updatedParams, {addQueryPrefix: true});
    navigate({search}, {replace: true});
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {name, value} = e.target;
    updateQueryParams({[name]: value});
  };

  return {queryParams, updateQueryParams, handleChange};
};
