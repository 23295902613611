import React, {useCallback, useMemo, useState} from 'react';
import {useNotificationMutation, useNotificationQuery} from '@modules/notifications/hooks';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {CheckedMap, DrawerAction} from './DrawerAction';
import {NotificationList} from './NotificationList';
import dayjs from 'dayjs';
import {NotificationCategory, UserNotification, NotificationOptionFilter} from '@modules/notifications/types';
import {useNavigate} from 'react-router-dom';
import {NoNotification} from './NoNotification';
import {dialogHandler} from '@components/molecules/Dialogs/DialogHandler';
import {getPmdaRecall} from '@modules/notifications/api';
import {RecallDialog} from '@components/molecules/Dialogs/RecallDialog';
import {fetchFileUrlByPath} from '@modules/files/api';
import {isNullish} from '@front-libs/helpers';

type Props = {
  open: boolean;
  onClose: () => void;
  onAllClear?: () => void;
  masterCategories: NotificationCategory[]; //ユーザ通知、PMDA通知のマスターカテゴリー
  optionFilters: NotificationOptionFilter[]; // ユーザ通知、PMDA通知のフィルター用カテゴリー
};

const dateCondition = {
  todayFrom: dayjs().startOf('day').toDate(),
  yesterdayTo: dayjs().add(-1, 'd').endOf('day').toDate(),
  yesterdayFrom: dayjs().add(-1, 'd').startOf('day').toDate(),
  thisMonthTo: dayjs().add(-2, 'day').endOf('day').toDate(),
  thisMonthFrom: dayjs().startOf('month').toDate(),
  thisYearTo: dayjs().endOf('month').add(-1, 'month').toDate(),
  thisYearFrom: dayjs().startOf('year').toDate(),
  lastYearTo: dayjs().endOf('year').add(-1, 'year').toDate(),
};

export const NotificationContainer: React.FC<Props> = ({
  open,
  onAllClear,
  onClose,
  children,
  masterCategories,
  optionFilters,
}) => {
  const {myInfo} = useMyInfo();
  const navigate = useNavigate();
  const [categories, setCategories] = useState<NotificationCategory[]>(masterCategories);
  const todayNotification = useNotificationQuery('todayNotification', myInfo.hospitalHashId, myInfo.hashId, {
    categories,
    createdAtFrom: dateCondition.todayFrom,
  });
  const yesterdayNotification = useNotificationQuery('yesterdayNotification', myInfo.hospitalHashId, myInfo.hashId, {
    categories,
    createdAtFrom: dateCondition.yesterdayFrom,
    createdAtTo: dateCondition.yesterdayTo,
  });
  const thisMonthNotification = useNotificationQuery('thisMonthNotification', myInfo.hospitalHashId, myInfo.hashId, {
    categories,
    createdAtFrom: dateCondition.thisMonthFrom,
    createdAtTo: dateCondition.thisMonthTo,
  });
  const thisYearNotification = useNotificationQuery('thisYearNotification', myInfo.hospitalHashId, myInfo.hashId, {
    categories,
    createdAtFrom: dateCondition.thisYearFrom,
    createdAtTo: dateCondition.thisYearTo,
  });
  const allNotification = useNotificationQuery('allNotification', myInfo.hospitalHashId, myInfo.hashId, {
    categories,
    createdAtTo: dateCondition.lastYearTo,
  });
  const unreadNotification = useNotificationQuery(
    'unreadNotification',
    myInfo.hospitalHashId,
    myInfo.hashId,
    {
      categories,
      statuses: ['unread'],
    },
    0,
    1
  );

  const noNotification = useMemo(
    () =>
      allNotification.data?.data.length === 0 &&
      todayNotification.data?.data.length === 0 &&
      yesterdayNotification.data?.data.length === 0 &&
      thisMonthNotification.data?.data.length === 0 &&
      thisYearNotification.data?.data.length === 0,
    [
      allNotification.data?.data.length,
      thisMonthNotification.data?.data.length,
      thisYearNotification.data?.data.length,
      todayNotification.data?.data.length,
      yesterdayNotification.data?.data.length,
    ]
  );

  const {bulkUpdateNotificationsMutation} = useNotificationMutation();

  const refetchAll = useCallback(() => {
    todayNotification.refetch();
    yesterdayNotification.refetch();
    thisMonthNotification.refetch();
    thisYearNotification.refetch();
    allNotification.refetch();
    unreadNotification.refetch();
  }, [
    todayNotification,
    yesterdayNotification,
    thisMonthNotification,
    thisYearNotification,
    allNotification,
    unreadNotification,
  ]);

  const hasUnread = useMemo(() => (unreadNotification.data?.totalCount ?? 0) > 0, [unreadNotification]);

  const handleAllRead = useCallback(() => {
    bulkUpdateNotificationsMutation.mutate(
      {
        hospitalHashId: myInfo.hospitalHashId,
        hospitalUserHashId: myInfo.hashId,
        data: {
          status: 'read',
        },
      },
      {
        onSuccess: () => {
          if (onAllClear) onAllClear();
          refetchAll();
        },
      }
    );
  }, [bulkUpdateNotificationsMutation, myInfo.hashId, myInfo.hospitalHashId, onAllClear, refetchAll]);

  const handleChangeCategories = useCallback(
    (checkedValues: CheckedMap) => {
      // 選択済みのIDを取得する
      const selectedCategoryIds = Object.entries(checkedValues).flatMap(([key, checked]) => {
        if (checked) {
          return key;
        }
        return [];
      });
      // チェックがない場合、すべてのカテゴリーを表示する
      if (selectedCategoryIds.length === 0) {
        setCategories(masterCategories);
      } else {
        // 選択済みIDからカテゴリー一覧を取得する
        const matchingValues = optionFilters.flatMap((option) =>
          selectedCategoryIds.includes(option.id) ? option.values : []
        );

        setCategories(matchingValues);
      }
    },
    [masterCategories, optionFilters]
  );

  // リコールダイアログを表示
  const openPmdaRecallDialog = useCallback(async (data: UserNotification) => {
    try {
      const url = data.link as string;
      const pmdaRecallData = await getPmdaRecall(url);

      return await dialogHandler.open(RecallDialog, {
        title: pmdaRecallData.subject,
        content: pmdaRecallData.body,
      });
    } catch (_e) {
      return;
    }
  }, []);

  const handleClickNotificationItem = useCallback(
    async (notification: UserNotification) => {
      bulkUpdateNotificationsMutation.mutate(
        {
          hospitalHashId: myInfo.hospitalHashId,
          hospitalUserHashId: myInfo.hashId,
          data: {
            status: 'read',
            notificationHashIds: [notification.hashId],
          },
        },
        {onSuccess: () => unreadNotification.refetch()}
      );

      // ダウンロードパスが含まれる場合は署名済みURLを取得してダウンロードする
      if (notification.s3DownloadPath) {
        const {redirectUrl} = await fetchFileUrlByPath(notification.s3DownloadPath);
        if (isNullish(redirectUrl)) return;

        window.open(redirectUrl, '_blank');
        return;
      }

      if (notification.link) {
        if (notification.link.includes('/pmda/recalls/')) {
          // リコール通知のダイアログを開く
          openPmdaRecallDialog(notification);
          unreadNotification.refetch();
        } else if (notification.link.includes('https://') || notification.link.includes('http://')) {
          // HTTPから始まる場合は外部サイトなので別タブで開く
          window.open(notification.link, '_blank');
          return;
        } else {
          navigate(notification.link);
        }
      }
    },
    [
      bulkUpdateNotificationsMutation,
      myInfo.hashId,
      myInfo.hospitalHashId,
      navigate,
      openPmdaRecallDialog,
      unreadNotification,
    ]
  );

  return (
    <>
      {children}
      <DrawerAction
        onClose={onClose}
        open={open}
        hasUnread={hasUnread}
        onClickAllRead={handleAllRead}
        onCategoryFilterChange={handleChangeCategories}
        optionFilters={optionFilters}
      />
      {noNotification ? (
        <NoNotification />
      ) : (
        <>
          {(todayNotification.data?.totalCount ?? 0) > 0 && (
            <NotificationList
              listTitle={'今日'}
              listItem={todayNotification.data?.data ?? []}
              onClickNotificationItem={handleClickNotificationItem}
            />
          )}
          {(yesterdayNotification.data?.totalCount ?? 0) > 0 && (
            <NotificationList
              listTitle={'昨日'}
              listItem={yesterdayNotification.data?.data ?? []}
              onClickNotificationItem={handleClickNotificationItem}
            />
          )}
          {(thisMonthNotification.data?.totalCount ?? 0) > 0 && (
            <NotificationList
              listTitle={'今月'}
              listItem={thisMonthNotification.data?.data ?? []}
              onClickNotificationItem={handleClickNotificationItem}
            />
          )}
          {(thisYearNotification.data?.totalCount ?? 0) > 0 && (
            <NotificationList
              listTitle={'今年'}
              listItem={thisYearNotification.data?.data ?? []}
              onClickNotificationItem={handleClickNotificationItem}
            />
          )}
          {(allNotification.data?.totalCount ?? 0) > 0 && (
            <NotificationList
              listTitle={'すべて'}
              listItem={allNotification.data?.data ?? []}
              onClickNotificationItem={handleClickNotificationItem}
            />
          )}
        </>
      )}
    </>
  );
};
