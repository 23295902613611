import React from 'react';
import {Box, Button, Dialog, DialogActions, IconButton, styled, Typography} from '@mui/material';
import {DialogProps as DialogHandlerProps} from '@molecules/Dialogs/DialogHandler';
import CloseIcon from '@mui/icons-material/Close';

type MergeDialogProps = {
  open: boolean;
} & DialogHandlerProps;

const StyledCloseIcon = styled(CloseIcon)({
  fontSize: '24px',
  fontWeight: 'bold',
  color: '#172B4D',
});

export const UnifyWholeProductConfirmModal: React.FC<MergeDialogProps> = ({open, actions}) => {
  const onClose = () => {
    actions.reject();
  };
  return (
    <Dialog maxWidth="lg" open={!!open}>
      <Box sx={{margin: '32px'}}>
        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography sx={{fontWeight: 'bold', fontSize: '20px'}}>選択した２機種を統合しますか？</Typography>
          <IconButton onClick={onClose}>
            <StyledCloseIcon />
          </IconButton>
        </Box>
        <Typography sx={{marginTop: '32px', fontSize: '14px', color: '#172B4D'}}>
          機種を統合すると元に戻せません。
          <br />
          統合後、移行元となる機種に紐づいていた機器の、定期点検計画の再設定をお願いします。
        </Typography>
      </Box>
      <DialogActions sx={{margin: '24px 32px 24px 0px'}}>
        <Button onClick={actions.resolve} sx={{backgroundColor: '#0052CC'}} variant="contained">
          機種を統合する
        </Button>
        <Button sx={{color: '#172B4D'}} onClick={actions.reject}>
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
};
