import {contentFixedStyle} from '@components/templates/RentalTemplate';
import {isNullish} from '@front-libs/helpers';
import {useFetchHospitalRooms} from '@modules/hospital_places/api';
import {HospitalRoom} from '@modules/hospital_places/types';
import {useHospitalRoomLayoutSettingQuery} from '@modules/hospital_room_layout_setting/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {HospitalWard} from '@modules/hospital_wards/types';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {Box, CSSObject, Grid, SxProps, Theme} from '@mui/material';
import {useAtomValue, useSetAtom} from 'jotai';
import _ from 'lodash';
import React, {useCallback, useEffect, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {Footer} from '../Footer';
import {Header} from '../Header';
import {RentalSelectionCard} from '../RentalSelectionCard';
import {SelectionSwiper} from '../SelectionSwiper';
import {
  allowRentalRoomUndefinedDataAtom,
  defaultBarcodeScannerTypeAtom,
  rentFloorAtom,
  rentRoomAtom,
  rentStepperAtom,
  rentWardAtom,
} from '../states';

const MAX_ROOMS_PER_PAGE = 18;

/**
 * 小エリア選択画面
 */
export const RoomSelection = () => {
  const navigate = useNavigate();
  const {myInfo} = useMyInfo();
  const setRentalRoom = useSetAtom(rentRoomAtom);
  const setRentalStepper = useSetAtom(rentStepperAtom);
  const ward = useAtomValue(rentWardAtom);
  const floor = useAtomValue(rentFloorAtom);
  const allowRentalRoomUndefinedData = useAtomValue(allowRentalRoomUndefinedDataAtom);
  const defaultBarcodeScannerType = useAtomValue(defaultBarcodeScannerTypeAtom);
  const {data: roomsData} = useFetchHospitalRooms(myInfo.hospitalHashId, {
    page: 0,
    perPage: 100,
    hospitalWardHashId: ward?.hashId,
    showRentalPlace: true,
    floorNumber: floor?.floorNumber,
    isGroundFloor: floor?.isGroundFloor,
    order: 'name',
  });

  const {data: roomLayoutSettingsData} = useHospitalRoomLayoutSettingQuery(
    myInfo.hospitalHashId,
    myInfo.hashId,
    ward?.hashId ?? '',
    {
      page: 0,
      perPage: 100,
    }
  );

  const roomOptions = useMemo(() => {
    if (myInfo.rentalLayoutPreference === 'customize' && !isNullish(roomLayoutSettingsData[0])) {
      // myInfo.rentalLayoutPreferenceが'customize'の場合、
      // customSequenceに基づいて並び順を変更
      const customSequenceIds = roomLayoutSettingsData[0].customSequence.map((item) => item.id) || [];

      // customSequenceIdsに存在するIDのみを抽出して並び順を変更
      const sortedFiltered = customSequenceIds
        .map((id) => roomsData.find((room) => room.hashId === id))
        .filter((room) => room !== undefined) as HospitalRoom[];

      return {data: _.chunk(sortedFiltered, MAX_ROOMS_PER_PAGE), length: sortedFiltered.length};
    }
    return {data: _.chunk(roomsData, MAX_ROOMS_PER_PAGE), length: roomsData.length};
  }, [myInfo.rentalLayoutPreference, roomLayoutSettingsData, roomsData]);

  const handleClickRoom = useCallback(
    (value: HospitalWard | HospitalRoom) => {
      if (!('numberOfRooms' in value)) {
        setRentalRoom(value);
        setRentalStepper(2);
        defaultBarcodeScannerType === 'barcode_reader'
          ? navigate(`/shared/rental/product/barcode_reader`)
          : navigate(`/shared/rental/product/camera`);
      }
    },
    [defaultBarcodeScannerType, navigate, setRentalRoom, setRentalStepper]
  );

  const handleClickNextButton = useCallback(() => {
    setRentalRoom(null);
    defaultBarcodeScannerType === 'barcode_reader'
      ? navigate(`/shared/rental/product/barcode_reader`)
      : navigate(`/shared/rental/product/camera`);
  }, [defaultBarcodeScannerType, navigate, setRentalRoom]);

  useEffect(() => {
    setRentalStepper(1);
  }, [setRentalStepper]);

  const GridComponent = () => {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={[gridRootStyle, roomOptions.length > 4 && gridRootSecondLineStyle]}
        spacing={3}>
        {roomOptions.data[0] &&
          roomOptions.data[0].map((room, index) => (
            <Grid item key={index} sx={roomOptions.length > 8 ? grid6ItemsStyle : grid4ItemsStyle}>
              <RentalSelectionCard
                label={room.name}
                itemsCount={roomOptions.length}
                value={room}
                onChange={handleClickRoom}
              />
            </Grid>
          ))}
      </Grid>
    );
  };

  return (
    <>
      <Header title={'貸出'} />
      <Box sx={wardFloorContainerStyle}>
        {`${ward?.name}`}
        {floor?.floorNumber ? (
          <>
            <KeyboardArrowRightIcon sx={{fontSize: '30px', color: '#172B4D', margin: '0px 8px'}} />
            {`${floor?.isGroundFloor ? '' : '地下'}${floor?.floorNumber}階`}
          </>
        ) : (
          ''
        )}
      </Box>
      <Box sx={contentFixedStyle}>
        {roomOptions.length <= 12 ? (
          <GridComponent />
        ) : (
          <SelectionSwiper<HospitalRoom>
            data={roomOptions.data}
            getLabel={(item) => item.name}
            onSelect={(item) => handleClickRoom(item)}
          />
        )}
      </Box>
      <Footer
        text={'貸出先を選択してください'}
        nextButtonLabel={allowRentalRoomUndefinedData ? '貸出先を選択せず次へ' : undefined}
        onClickNextButton={handleClickNextButton}
      />
    </>
  );
};

const wardFloorContainerStyle: SxProps<Theme> = (theme) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  top: 0,
  left: '32px',
  fontSize: '24px',
  fontWeight: 'bold',
  zIndex: 0,
  color: theme.palette.rent.main,
});

const gridRootStyle: CSSObject = {
  margin: 'auto',
  flexWrap: 'nowrap',
  padding: '0px 80px',
};

const gridRootSecondLineStyle: CSSObject = {
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
};

const grid4ItemsStyle: SxProps<Theme> = {
  flexBasis: '25%',
};

const grid6ItemsStyle: SxProps<Theme> = {
  flexBasis: '16.66%',
};
