import {useReducer, useEffect} from 'react';
import {codeReaderReducer, codeReaderInitialState} from '../reducers';
import {CodeReaderType, CodeReaderState, CodeReaderActionType} from '../types';
import {CodeReaderTypes, LocalStorageKeys} from '../constants';

type CodeReaderStoreType = {
  codeReaderState: CodeReaderState;
  updateCodeReaderType: (codeReaderType: CodeReaderType) => void;
};

export const useCodeReaderStore = (): CodeReaderStoreType => {
  const [state, dispatch] = useReducer(codeReaderReducer, codeReaderInitialState);

  const isCodeReaderType = (codeReaderType: string): codeReaderType is CodeReaderType => {
    return (CodeReaderTypes as readonly string[]).includes(codeReaderType);
  };

  const loadCodeReaderType = () => {
    const codeReaderType = localStorage.getItem(LocalStorageKeys.CodeReaderType);
    if (!codeReaderType) {
      return;
    }
    if (!isCodeReaderType(codeReaderType)) {
      return;
    }

    dispatch({
      type: CodeReaderActionType.CODE_READER_PUT_ITEM,
      payload: {codeReaderType},
    });
  };

  useEffect(() => {
    loadCodeReaderType();
  }, []);

  const updateCodeReaderType = (codeReaderType: CodeReaderType) => {
    dispatch({
      type: CodeReaderActionType.CODE_READER_PUT_ITEM,
      payload: {codeReaderType},
    });
    localStorage.setItem(LocalStorageKeys.CodeReaderType, codeReaderType);
  };

  return {
    codeReaderState: state,
    updateCodeReaderType,
  };
};
