import {HITOTSULogo} from '@atoms/HITOTSULogo';
import {useSignOut} from '@modules/auth/hooks';
import {dialogHandler} from '@molecules/Dialogs/DialogHandlerV5';
import {Box} from '@mui/material';
import {useAtom} from 'jotai';
import React, {useCallback, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {LongPressEvent, useLongPress} from 'use-long-press';
import {rentOrReturnSettingAtom} from '../states';
import {PopperMenu} from './PopperMenu';
import {TerminalSettingDialog, TerminalSettingDialogProps, optionValue} from './TerminalSettingDialog';

export const HeaderHitotsuLogo = () => {
  const [rentOrReturnSetting, setRentOrReturnSetting] = useAtom(rentOrReturnSettingAtom);
  const [isOpeningMenu, setIsOpeningMenu] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const anchorRef = useRef<HTMLDivElement | null>(null);
  const logout = useSignOut();
  const logoutOnLongPressed = useCallback(() => {
    logout();
  }, [logout]);

  const handleOpenMenu = useCallback((_e: LongPressEvent<Element>) => {
    setIsOpeningMenu(true);
    setOpen(true);
  }, []);

  const handleLongPress = useLongPress(handleOpenMenu, {
    threshold: 2000, // 2秒にしてるとどうも3秒くらいで発火するっぽい
  });

  const handleClickMenu = async (value: 'logout' | 'terminalSetting' | 'customize') => {
    switch (value) {
      case 'logout':
        logoutOnLongPressed();
        break;
      case 'terminalSetting':
        handleTerminalSetting();
        break;
      case 'customize':
        navigate('/shared/cards_position_setting');
        break;
      default:
        break;
    }
  };

  const handleTerminalSetting = async () => {
    const settingValue = await dialogHandler.open<TerminalSettingDialogProps, Record<string, optionValue>>(
      TerminalSettingDialog,
      {
        defaultValue: {
          rentalMenuSetting: rentOrReturnSetting,
        },
      }
    );
    setRentOrReturnSetting(settingValue.rentalMenuSetting);
  };

  const handleCloseMenu = useCallback(() => {
    // NOTE: 長押し時にクリックイベントが発火してしまうので、メニューを閉じようとしてしまう。
    // そのため、メニューを開いたままにするために、メニューを開いた直後のイベントでは閉じないようにする。
    if (isOpeningMenu) {
      setIsOpeningMenu(false);
      return;
    }
    setOpen(false);
  }, [isOpeningMenu]);
  return (
    <Box
      data-testid="rentalMenu"
      onContextMenu={(e) => {
        e.preventDefault();
        e.stopPropagation();
        return false;
      }}
      {...handleLongPress()}
      sx={{width: 'fit-content'}}
      ref={anchorRef}>
      <HITOTSULogo />
      <PopperMenu
        anchorEl={anchorRef.current}
        open={open}
        onClose={handleCloseMenu}
        onClickSettingMenu={handleClickMenu}
      />
    </Box>
  );
};
