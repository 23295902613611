import React from 'react';
import {styled, Grid, List, ListItem, ListItemText} from '@mui/material';
import {
  HospitalProductRequiredProperty,
  HospitalProductRequiredPropertyItem,
} from '@modules/hospital_product_required_properties/types';
import {ProductRequiredPropertiesSettingFormInputItem} from './ProductRequiredPropertiesSettingFormInputItem';

type Props = {
  propertyItems: HospitalProductRequiredPropertyItem[];
  requiredProperties: HospitalProductRequiredProperty[];
  onRequiredPropertyAdd: (property: string) => void;
  onRequiredPropertyDelete: (property: string) => void;
};

const InputList = styled(List)({
  maxWidth: 400,
});

const AlignLeftHeaderText = styled(ListItemText)({
  textAlign: 'left',
});

const AlignCenterHeaderText = styled(ListItemText)({
  textAlign: 'center',
});

export const ProductRequiredPropertiesSettingFormInput = (props: Props) => {
  return (
    <InputList>
      <ListItem>
        <Grid container>
          <Grid item xs={10}>
            <AlignLeftHeaderText>項目</AlignLeftHeaderText>
          </Grid>
          <Grid item xs={2}>
            <AlignCenterHeaderText>必須</AlignCenterHeaderText>
          </Grid>
        </Grid>
      </ListItem>
      {props.propertyItems.map((item: HospitalProductRequiredPropertyItem) => (
        <ProductRequiredPropertiesSettingFormInputItem
          key={item.value}
          label={item.name}
          value={item.value}
          required={props.requiredProperties.some((p) => p.property === item.value)}
          onRequiredPropertyAdd={props.onRequiredPropertyAdd}
          onRequiredPropertyDelete={props.onRequiredPropertyDelete}
        />
      ))}
    </InputList>
  );
};
