import React from 'react';
import {Grid, Paper} from '@material-ui/core';
import {useStyles} from './styles';
import {useSelectView, useSelectedRootCategoryHashId} from './states';
import {Toolbar} from './Toolbar';
import {useOnlyOnce} from '@front-libs/core';
import {Chart} from './Chart';
import {useRootCategories} from '../components/hooks';

export const FaultRatio: React.FC = () => {
  const classes = useStyles();

  const [selectView, setSelectView] = useSelectView();
  const [rootCategoryHashId, setRootCategoryHashId] = useSelectedRootCategoryHashId();
  const {query} = useRootCategories();

  useOnlyOnce(
    () => {
      setRootCategoryHashId(query.data[0].hashId);
    },
    query.isSuccess && query.data.length > 0
  );

  return (
    <Paper className={classes.paper}>
      <div className={classes.root}>
        <div className={classes.contentTitleContainer}>
          <Toolbar
            selectView={selectView}
            rootCategoryHashId={rootCategoryHashId}
            onChangeSelectView={setSelectView}
            onChangeRootCategoryHashId={setRootCategoryHashId}
          />
        </div>
        <Grid className={classes.highChartContainer}>
          <Chart />
        </Grid>
      </div>
    </Paper>
  );
};
