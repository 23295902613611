import React from 'react';
import {Box, Button, Drawer, SxProps, Theme, Typography} from '@mui/material';
import {patchProduct} from '@modules/products/api';
import {useFormContext} from 'react-hook-form';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {WholeProductFormValue} from './hooks';
import {WholeProductIndex} from '@modules/products/types';
import {convertFormFieldToPatchWholeProductAPI} from './helper';
import {openSnackBar} from '@molecules/SnackBar';

const paperStyle: SxProps<Theme> = {
  backgroundColor: (theme) => theme.palette.secondary.light,
  borderTop: (theme) => `1px solid ${theme.palette.grey[200]}`,
};

const contentContainerStyle: SxProps = {
  padding: '16px 52px',
  display: 'flex',
};

const propertyStyle: SxProps = {
  display: 'flex',
  flexWrap: 'wrap',
  alignContent: 'center',
};

const cancelButtonStyle: SxProps = {
  marginLeft: '12px',
  marginRight: '32px',
};

type SubmitDrawerProps = {
  wholeProduct: WholeProductIndex;
  refetch: () => void;
};

export const SubmitDrawer = ({wholeProduct, refetch}: SubmitDrawerProps) => {
  const methods = useFormContext();
  const {myInfo} = useMyInfo();
  const [isConnected, setIsConnected] = React.useState(false);
  const {
    getValues,
    reset,
    formState: {dirtyFields, isSubmitted, isValid},
  } = methods;

  const count = Object.keys(dirtyFields).length;

  const handleSave = async () => {
    if (count === 0) return;
    setIsConnected(true);
    // Form更新されたKey一覧
    const updateFieldKeys = Object.keys(dirtyFields) as (keyof WholeProductFormValue)[];
    const formValues = getValues() as WholeProductFormValue;

    const payload = convertFormFieldToPatchWholeProductAPI(formValues, updateFieldKeys);

    try {
      await patchProduct(myInfo.hospitalHashId, wholeProduct.hashId, payload);
      await refetch();
      reset(formValues);
      openSnackBar('機種情報を更新しました', 'left', 'bottom');
    } catch (e) {
      console.error(e);
      openSnackBar('機器情報の更新に失敗しました', 'left', 'bottom', 'error');
    } finally {
      setIsConnected(false);
    }
  };

  const handleCancel = () => {
    // ReactHookFormのreset関数を呼び出し、フォームの値を初期値に戻す
    reset();
  };

  return (
    <Drawer anchor={'bottom'} open={count > 0} variant="persistent" PaperProps={{sx: paperStyle, elevation: 4}}>
      <Box sx={contentContainerStyle}>
        <Box>
          <Button
            variant={'contained'}
            color={'primary'}
            size={'large'}
            disabled={isConnected || isSubmitted || !isValid}
            onClick={handleSave}>
            保存
          </Button>
          <Button
            variant={'outlined'}
            color={'primary'}
            size={'large'}
            disabled={isConnected}
            sx={cancelButtonStyle}
            onClick={handleCancel}>
            キャンセル
          </Button>
        </Box>
        <Box sx={propertyStyle}>
          <Typography>{count}件のプロパティーを保存しますか？</Typography>
        </Box>
      </Box>
    </Drawer>
  );
};
