import React, {useCallback, useMemo, useState} from 'react';
import {Button, createStyles, Grid, makeStyles, Theme, TextField, useMediaQuery} from '@material-ui/core';
import {Search, Tune} from '@material-ui/icons';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {TableLayoutDialog, TableLayoutDialogProps} from '@organisms/Table/TableLayoutDialog';
import {UserFormatter} from '@modules/hospital_users/helpers';
import {TableLayoutResult} from '@modules/table_layout/hooks/useTableLayout';
import {useHospitalUsers} from '@modules/hospital_users/hooks/useHospitalUsers';
import {IconButton} from '@material-ui/core';
import {PopperSelectBoxButton, SelectOptionProps} from '@molecules/Buttons/PopperSelectBoxButton';
import {CategoryFormatter} from '@modules/categories/helpers';
import {useGetHospitalCategories, useGetHospitalDescendantCategories} from '@modules/categories/api';
import {
  useInspectionResultStatus,
  useRootCategory,
  useNarrowCategory,
  useInspector,
  useIsDetailFilterActive,
} from './states/states';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {MenuItemType, PopperMenuButton} from '@molecules/Buttons/PopperMenuButton';
import {onlineManager} from 'react-query';
import {useSearchParams} from 'react-router-dom';
import dayjs from 'dayjs';
import {CreateDatePickerDialog} from '@components/molecules/Dialogs/DatePickerDialog';
import {openSnackBar} from '@components/molecules/SnackBar';
import {requestExportInspectionResultCSVsTask} from '@modules/inspection_results/api';
import {RequestExportInspectionResultCSVsParam} from '@modules/inspection_results/types';
import {InspectionTypesToLabel, InspectionType} from '@modules/inspections/enum';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flex: {
      flexGrow: 1,
    },
    searchNameContainer: {
      minWidth: '270px',
    },
    searchName: {
      backgroundColor: theme.palette.common.white,
      fontSize: '14px',
    },
    actionMenu: {
      fontWeight: 'bold',
      color: theme.palette.primary.dark,
      marginLeft: '8px',
    },
    tableContainer: {
      margin: '24px 0px',
    },
    rentalHistoryMenu: {
      width: '204px',
      height: '32px',
      backgroundColor: '#FAFBFC',
      marginLeft: '40px',
    },
    dateSelector: {
      display: 'flex',
      alignItems: 'center',
    },
    date: {
      margin: '0px 8px',
    },
    dateMenu: {
      fontWeight: 'bold',
      color: theme.palette.primary.dark,
    },
    dateDivider: {
      margin: '0px 8px',
    },
    filterBtnActive: {
      backgroundColor: theme.palette.primary.light,
      '&&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    actionBtn: {
      width: '100%',
      background: 'rgba(9, 30, 66, 0.04)',
      border: '1px solid #C6CBD4',
    },
  })
);

const actionMenus4Tablet = [
  {
    label: 'エクスポート',
    value: 'export',
  },
  {
    label: '表示項目を変更',
    value: 'changeTableLayout',
  },
];

const actionMenus4TabletWithoutExport = [
  {
    label: '表示項目を変更',
    value: 'changeTableLayout',
  },
];

type ToolBarProp = {
  searchName: string;
  tableLayout: TableLayoutResult | undefined;
  onChangeSearchName: React.ChangeEventHandler<HTMLInputElement>;
  onChangeTableLayout: (layout: TableLayoutResult) => void;
  onClickDrawer: () => void;
};

export const ToolBar: React.FC<ToolBarProp> = (props) => {
  const {searchName, tableLayout, onChangeSearchName, onChangeTableLayout, onClickDrawer} = props;
  const classes = useStyles();
  const matches = useMediaQuery('(max-width:1024px)');
  const isOnline = onlineManager.isOnline();

  const {myInfo} = useMyInfo();
  const {hospitalUsers} = useHospitalUsers();

  const [status] = useInspectionResultStatus();
  const [, setInspectorHashId] = useInspector();
  const [rootCategory, setRootCategory] = useRootCategory();
  const [narrowCategory, setNarrowCategory] = useNarrowCategory();
  const [resetNarrowCategoryValue, setResetNarrowCategoryValue] = useState<boolean>(false);

  const [broadCategoryHashId, setBroadCategoryHashId] = useState<null | string>(null);
  const {data: descendantCategories} = useGetHospitalDescendantCategories(
    myInfo.hospitalHashId,
    broadCategoryHashId ?? undefined,
    true
  );

  const [searchParams] = useSearchParams();
  const type = searchParams.get('type') ?? 'all';

  const isDetailFilterActive = useIsDetailFilterActive();

  const handleChangeRootCategory = useCallback(
    (row: SelectOptionProps | undefined) => {
      setRootCategory(row?.value ?? null);
      setBroadCategoryHashId(row?.value ?? null);
      // FixMe: 大分類・小分類共に選択されている状態で、小分類に紐づく大分類を選択した場合、小分類の選択が解除される。
      // FixMe: 小分類だけ選択されている状態で、小分類に紐づく大分類を選択した場合、小分類の選択が解除される。
      setNarrowCategory(null);
      setResetNarrowCategoryValue(true);
    },
    [setNarrowCategory, setRootCategory]
  );

  const handleChangeNarrowCategory = useCallback(
    (row: SelectOptionProps | undefined) => {
      setNarrowCategory(row?.value ?? null);
      setResetNarrowCategoryValue(false);
    },
    [setNarrowCategory]
  );

  const handleClickChangeTableLayout = useCallback(async () => {
    try {
      if (!tableLayout) return;
      const currentLayout = await dialogHandler.open<TableLayoutDialogProps>(TableLayoutDialog, {
        tableColumns: tableLayout.tableLayout ?? [],
        defaultOptions: tableLayout.currentLayout,
        forceValue: tableLayout.forceValue ?? {},
      });
      const newTableLayout = {
        tableLayout: tableLayout.tableLayout,
        currentLayout: currentLayout,
      };
      onChangeTableLayout(newTableLayout);
    } catch (_e) {
      // empty function
    }
  }, [onChangeTableLayout, tableLayout]);

  const inspectionType = type as InspectionType;
  const inspectionTypeLabel = InspectionTypesToLabel[inspectionType] ?? '';

  // ハンドラ 点検履歴 CSVエクスポート 起動
  const handleExport = useCallback(async () => {
    try {
      const res = await dialogHandler.open(CreateDatePickerDialog, {
        title: `${inspectionTypeLabel}のエクスポート範囲を選択`,
        description: `点検予定日の範囲を選択してください。(点検ステータスが完了の場合は、点検完了日も抽出範囲に含めます。)`,
        initialStartDate: dayjs().subtract(1, 'year').format('YYYY-MM-DD'),
        initialEndDate: dayjs().format('YYYY-MM-DD'),
        startDateLabel: '開始日',
        endDateLabel: '終了日',
      });

      if (!res) return null;

      const data: RequestExportInspectionResultCSVsParam = {
        scheduledAtFrom: res.startDate,
        scheduledAtTo: res.endDate,
        statuses: status,
        types: type,
      };

      const result = await requestExportInspectionResultCSVsTask(myInfo.hospitalHashId, data);
      if (result.status === 201) {
        openSnackBar('ファイルのエクスポートを受け付けました。\n処理完了後、通知をご確認ください。', 'center', 'top');
      }
    } catch (_e) {
      openSnackBar('ファイルのエクスポートに失敗しました。', 'center', 'top', 'error');
    }
  }, [myInfo.hospitalHashId, type, inspectionTypeLabel, status]);

  const {data: allRootCategory} = useGetHospitalCategories(myInfo.hospitalHashId, {depth: 0, isMedicalDevice: true});
  const {data: allNarrowCategory} = useGetHospitalCategories(myInfo.hospitalHashId, {depth: 1, isMedicalDevice: true});

  const handleSelectUser = useCallback(
    (user: SelectOptionProps | undefined) => {
      // eslint-disable-next-line no-shadow
      const userHashId = user?.value ?? null;
      setInspectorHashId(userHashId);
    },
    [setInspectorHashId]
  );

  const handleMenuClick = useCallback(
    (item: MenuItemType) => {
      switch (item.value) {
        case 'filterDetailCondition':
          onClickDrawer();
          break;

        case 'changeTableLayout':
          handleClickChangeTableLayout();
          break;

        case 'export':
          handleExport();
          break;
      }
    },
    [handleClickChangeTableLayout, handleExport, onClickDrawer]
  );

  const actionMenuItems = useMemo(() => {
    // TODO: Refactor Me (分岐をまとめる)
    // オフラインの場合 または 予定月超過の場合は エクスポートメニューを出さない
    const menu = status === 'overdue' || !isOnline ? actionMenus4TabletWithoutExport : actionMenus4Tablet;
    if (matches && isOnline) {
      // オンラインの場合(サーバー処理が可能)
      return [
        ...menu,
        {
          label: '詳細で絞り込む',
          value: 'filterDetailCondition',
        },
      ];
    } else if (matches) {
      // オフラインの場合(サーバー処理ができない)
      return [...menu];
    } else {
      return menu;
    }
  }, [matches, isOnline, status]);

  return (
    <Grid container alignItems="center" style={{marginTop: '8px'}}>
      <Grid item sm={4} md={3} className={classes.searchNameContainer}>
        <TextField
          className={classes.searchName}
          label={'機種名・型式・管理番号で検索'}
          variant={'outlined'}
          fullWidth
          size={'small'}
          InputProps={{
            endAdornment: <Search />,
          }}
          InputLabelProps={{
            style: {
              fontSize: 14,
            },
          }}
          defaultValue={searchName}
          onChange={onChangeSearchName}
        />
      </Grid>
      {isOnline && (
        <>
          <Grid item>
            <PopperSelectBoxButton
              buttonLabel="大分類"
              options={CategoryFormatter.getOptions(allRootCategory)}
              isMulti={false}
              onChange={handleChangeRootCategory}
              searchable={true}
              initialOption={CategoryFormatter.getOptions(allRootCategory).find((item) => item.value === rootCategory)}
            />
          </Grid>
          <Grid item>
            <PopperSelectBoxButton
              buttonLabel="小分類"
              options={CategoryFormatter.getOptions(descendantCategories ?? allNarrowCategory)}
              isMulti={false}
              onChange={handleChangeNarrowCategory}
              searchable={true}
              resetValue={resetNarrowCategoryValue}
              initialOption={CategoryFormatter.getOptions(allNarrowCategory).find(
                (item) => item.value === narrowCategory
              )}
            />
          </Grid>
          {status === 'completed' && !matches && (
            <Grid item>
              <PopperSelectBoxButton
                buttonLabel={'すべての点検者'}
                options={UserFormatter.getOptions(hospitalUsers, {withAlias: true, withSubLabel: true})}
                isMulti={false}
                onChange={handleSelectUser}
                searchable={true}
              />
            </Grid>
          )}
        </>
      )}

      {matches && (
        <>
          <Grid className={classes.flex} />
          <Grid item>
            <PopperMenuButton
              menuItemList={actionMenuItems}
              hiddenArrow={true}
              buttonProps={{variant: 'text'}}
              onMenuClick={handleMenuClick}>
              <Tune />
            </PopperMenuButton>
          </Grid>
        </>
      )}
      {!matches && (
        <>
          {isOnline && (
            <Grid item>
              <Button
                color="inherit"
                onClick={onClickDrawer}
                style={{marginLeft: 8}}
                className={isDetailFilterActive ? classes.filterBtnActive : undefined}>
                <Tune />
                <span className={classes.actionMenu}>詳細で絞り込む</span>
              </Button>
            </Grid>
          )}
          <Grid className={classes.flex} />
          <Grid item>
            <PopperMenuButton
              buttonProps={{variant: 'contained', disableElevation: true, className: classes.actionBtn}}
              menuItemList={actionMenuItems}
              onMenuClick={handleMenuClick}>
              アクション
            </PopperMenuButton>
          </Grid>
        </>
      )}

      {/* FIXME: チェックボックスのスタイルを適用するために必要 */}
      <IconButton style={{display: 'none'}} />
    </Grid>
  );
};
