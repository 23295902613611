import React from 'react';
import {Button} from '@mui/material';

type Props = {
  onSelectOptionEdit: () => void;
};

export const SelectTypeField = ({onSelectOptionEdit}: Props) => {
  const handleSelectOptionEdit = () => {
    onSelectOptionEdit();
  };

  return (
    <Button variant="outlined" sx={{borderColor: '#0052CC', color: '#0052CC'}} onClick={handleSelectOptionEdit}>
      選択項目を編集
    </Button>
  );
};
