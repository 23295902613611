import {CSSObject, TableCell, TableHead, TableRow} from '@mui/material';
import React from 'react';

const TableCellStyle: CSSObject = {
  borderBottom: 'none',
};

export const EditWholeProductHeader = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{...TableCellStyle, minWidth: '290px'}}>点検名</TableCell>
        <TableCell sx={{...TableCellStyle, minWidth: '176px'}}>点検タイプ</TableCell>
        <TableCell sx={{...TableCellStyle, minWidth: '200px'}}>点検間隔</TableCell>
        <TableCell sx={{...TableCellStyle, minWidth: '360px'}}>使用する点検表</TableCell>
        <TableCell sx={{...TableCellStyle, minWidth: '185px'}} />
      </TableRow>
    </TableHead>
  );
};
