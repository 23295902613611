/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Yup from 'yup';

Yup.setLocale({
  mixed: {
    required: '必須入力項目です',
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    oneOf: ({values}: any) => `次の値のいずれかでなければなりません: ${values}`,
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    notOneOf: ({values}: any) => `次の値のいずれかであってはなりません: ${values}`,
    notType: '入力内容の形式が異なります',
  },
  string: {
    length: ({length}: {length: number}) => `${length}文字入力して下さい`,
    min: ({min}: {min: number}) => `${min}文字以上入力してください`,
    max: ({max}: {max: number}) => `${max}文字以内で入力して下さい`,
    matches: '入力内容の形式が異なります',
    email: '入力内容の形式が異なります',
    url: '入力内容の形式が異なります',
    trim: '前後にスペースを入れてはいけません',
    lowercase: '小文字でなければなりません',
    uppercase: '大文字でなければなりません',
  },
  number: {
    min: ({min}: {min: number}) => `${min}以上の値を入力して下さい`,
    max: ({max}: {max: number}) => `${max}以下の値を入力して下さい`,
    lessThan: ({less}: {less: number}) => `${less}より小さい値を入力して下さい`,
    moreThan: ({more}: {more: number}) => `${more}より大きい値を入力して下さい`,
    positive: '正の数を入力して下さい',
    negative: '負の数を入力して下さい',
    integer: '整数を入力して下さい',
  },
  date: {
    min: ({min}) => `${min}以上の日付を入力して下さい`,
    max: ({max}) => `${max}以下の日付を入力して下さい`,
  },
  object: {
    noUnknown: '有効なキーを持ったデータを入力して下さい',
  },
  array: {
    min: ({min}: {min: number}) => `${min}個以上の値を入力して下さい`,
    max: ({max}: {max: number}) => `${max}個以下の値を入力して下さい`,
  },
});

export default Yup;
