import {axios} from '@front-libs/core';
import {isNullish} from '@front-libs/helpers';
import dayjs from 'dayjs';
import {CreateFaultRepairParams, FetchFaultRepairsParams, FetchFaultRepairsResult, RepairIndex} from '../types';

type FetchApiParams = Partial<{
  name: string;
  personInChargeHashIds: string;
  symptomCategories: string;
  symptomDetailCategories: string;
  rootCategories: string;
  dealtCategories: string;
  requestForRepairAtFrom: string;
  requestForRepairAtTo: string;
  updatedAtFrom: string;
  statusHashId: string;
  page: number;
  perPage: number;
  order?: string;
  faultReceptionHashId: string;
}>;

const serializeFetchParam = (params: FetchFaultRepairsParams): FetchApiParams => {
  return {
    name: params.name,
    personInChargeHashIds: params.personInChargeHashIds?.join(','),
    symptomCategories: params.symptomCategories?.join(','),
    symptomDetailCategories: params.symptomDetailCategories?.join(','),
    rootCategories: params.rootCategories?.join(','),
    dealtCategories: params.dealtCategories?.join(','),
    requestForRepairAtTo: params.requestForRepairAtTo
      ? dayjs(params.requestForRepairAtTo).format('YYYY-MM-DDTHH:mm:ssZ')
      : undefined,
    requestForRepairAtFrom: params.requestForRepairAtFrom
      ? dayjs(params.requestForRepairAtFrom).format('YYYY-MM-DDTHH:mm:ssZ')
      : undefined,
    updatedAtFrom: !isNullish(params.updatedAtFrom)
      ? dayjs(params.updatedAtFrom).format('YYYY-MM-DDTHH:mm:ssZ')
      : undefined,
    statusHashId: params.statusHashId,
    perPage: params.perPage,
    page: params.page,
    order: params.order,
    faultReceptionHashId: params.faultReceptionHashId,
  };
};

export const getFaultRepair = async (hospitalHashId: string, faultRepairHashId: string) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/fault_repair/${faultRepairHashId}`;
  const {data} = await axios.get<RepairIndex>(baseUrl, {});
  return data;
};

export const getFaultRepairs = async (hospitalHashId: string, params: FetchFaultRepairsParams) => {
  const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/fault_repair`;

  const {data} = await axios.get<FetchFaultRepairsResult>(baseUrl, {params: serializeFetchParam(params)});
  return data;
};

export const updateFaultRepair = async (
  hospitalHashId: string,
  hospitalProductHashId: string,
  faultRepairHashId: string,
  data: Partial<Omit<RepairIndex, 'hashId' | 'hospitalHashId' | 'faultHospitalProduct'>>
) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/products/${hospitalProductHashId}/fault_repair/${faultRepairHashId}`;
  const {data: d} = await axios.put<RepairIndex>(baseUrl, {
    ...data,
    requestForRepairAt: isNullish(data.requestForRepairAt)
      ? data.requestForRepairAt
      : dayjs(data.requestForRepairAt).format('YYYY-MM-DDTHH:mm:ssZ'),
    makerContactAt: isNullish(data.makerContactAt)
      ? data.makerContactAt
      : dayjs(data.makerContactAt).format('YYYY-MM-DDTHH:mm:ssZ'),
    appliedAtToSuppliesDepartment: isNullish(data.appliedAtToSuppliesDepartment)
      ? data.appliedAtToSuppliesDepartment
      : dayjs(data.appliedAtToSuppliesDepartment).format('YYYY-MM-DDTHH:mm:ssZ'),
    fixedAt: isNullish(data.fixedAt) ? data.fixedAt : dayjs(data.fixedAt).format('YYYY-MM-DDTHH:mm:ssZ'),
  });
  return d;
};

export const createFaultRepair = async (
  hospitalHashId: string,
  hospitalProductHashId: string,
  data: Partial<CreateFaultRepairParams>
) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/products/${hospitalProductHashId}/fault_repair`;
  const {data: d} = await axios.post<RepairIndex>(baseUrl, {
    ...data,
    requestForRepairAt: isNullish(data.requestForRepairAt)
      ? data.requestForRepairAt
      : dayjs(data.requestForRepairAt).format('YYYY-MM-DDTHH:mm:ssZ'),
    makerContactAt: isNullish(data.makerContactAt)
      ? data.makerContactAt
      : dayjs(data.makerContactAt).format('YYYY-MM-DDTHH:mm:ssZ'),
    appliedAtToSuppliesDepartment: isNullish(data.appliedAtToSuppliesDepartment)
      ? data.appliedAtToSuppliesDepartment
      : dayjs(data.appliedAtToSuppliesDepartment).format('YYYY-MM-DDTHH:mm:ssZ'),
    fixedAt: isNullish(data.fixedAt) ? data.fixedAt : dayjs(data.fixedAt).format('YYYY-MM-DDTHH:mm:ssZ'),
    // NOTE:数値入力後数値を消した場合、空文字が発生する恐れあり。そのため、空文字はundefinedに変換する対応を追加
    costOfFix: isNullish(data.costOfFix) || (data.costOfFix as unknown) === '' ? undefined : data.costOfFix,
    // NOTE:数値入力後数値を消した場合、空文字が発生する恐れあり。そのため、空文字はundefinedに変換する対応を追加
    estimatedCostOfFix:
      isNullish(data.estimatedCostOfFix) || (data.estimatedCostOfFix as unknown) === ''
        ? undefined
        : data.estimatedCostOfFix,
  });
  return d;
};

export const deleteFaultRepair = async (hospitalHashId: string, faultRepairHashId: string) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/fault_repair/${faultRepairHashId}`;
  const {data: d} = await axios.delete<RepairIndex>(baseUrl);
  return d;
};
