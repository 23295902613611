import {zodResolver} from '@hookform/resolvers/zod';
import {useForm} from 'react-hook-form';
import {WholeProductIndex} from '@modules/products/types';
import {z} from 'zod';
import {EIGHT_DIGIT_NUMBER_ERROR_MESSAGE} from '@Apps/ProductImport/hooks';

// クラス分類の設定で空文字を許容するため、enumとliteralを組み合わせる
export const classNameSchema = z.union([z.enum(['one', 'two', 'three', 'four']), z.literal('')]);
export const specificMaintainSchema = z.enum(['apply', 'not_apply']);
export const CategoryIndexSchema = z.object({
  hashId: z.string().min(1),
  name: z.string().min(1),
  depth: z.number().min(0),
});

export const MakerIndexSchema = z.object({
  hashId: z.string().optional(), // 新規登録を許容するため
  name: z.string(),
});
export type MakerIndex = z.infer<typeof MakerIndexSchema>;

const wholeProductFormValueSchema = z
  .object({
    rootCategory: CategoryIndexSchema,
    narrowCategory: CategoryIndexSchema.nullable().optional(), // rootCategoryの再設定時にnullでリセットするためnullable追加
    maker: MakerIndexSchema.optional(),
    displayName: z.string().min(1),
    name: z.string().min(1),
    //  Zodのregexは空文字がエラーになるためrefineを使用
    jmdnCode: z
      .string()
      .refine((value) => value === '' || /^(?:[1-9][0-9]{7})$/.test(value), {message: EIGHT_DIGIT_NUMBER_ERROR_MESSAGE})
      .optional(),
    janCode: z
      .string()
      .refine((value) => value === '' || /^[a-zA-Z0-9]+$/.test(value), {
        message: 'JANコードには、半角英数字を入力してください。',
      })
      .optional(),
    approvalNumber: z
      .string()
      .refine((value) => value === '' || /^[A-Z0-9]+$/.test(value), {
        message: '承認番号には、半角英大文字・数字 を入力してください。',
      })
      .optional(),
    newJanCode: z.optional(z.string()),
    className: classNameSchema.optional(),
    catalogPrice: z.optional(z.number()),
    specificMaintain: specificMaintainSchema.optional(),
  })
  .superRefine((data, ctx) => {
    // 大分類が指定されている場合、小分類は必須
    if (data.rootCategory && !data.narrowCategory) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['narrowCategory'], // narrowCategory にエラーを関連付け
        message: '大分類が指定されている場合、小分類は必須です。',
      });
    }
  });

export type WholeProductFormValue = z.infer<typeof wholeProductFormValueSchema>;

export const useWholeProductForm = (wholeProduct: WholeProductIndex) => {
  const initialWholeProductFormValue: WholeProductFormValue = {
    rootCategory: wholeProduct.categories[0],
    narrowCategory: wholeProduct.categories[1],
    maker: {
      hashId: wholeProduct.maker.hashId,
      name: wholeProduct.maker.name,
    },
    name: wholeProduct.name ?? '',
    displayName: wholeProduct.displayName ?? '',
    approvalNumber: wholeProduct.approvalNumber ?? '',
    jmdnCode: wholeProduct.jmdnCode?.toString() ?? '',
    janCode: wholeProduct.janCode ?? '',
    newJanCode: wholeProduct.newJanCode ?? '',
    className: wholeProduct.className as typeof classNameSchema._type | undefined,
    catalogPrice: wholeProduct.catalogPrice ?? undefined,
    specificMaintain:
      wholeProduct.isSpecificMaintain === undefined
        ? undefined
        : wholeProduct.isSpecificMaintain
          ? 'apply'
          : 'not_apply',
  };

  const methods = useForm<WholeProductFormValue>({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: zodResolver(wholeProductFormValueSchema),
    defaultValues: initialWholeProductFormValue,
  });
  return methods;
};
