import {Box, Button, Typography} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import React from 'react';
import {CSSObject} from '@emotion/react';

const informationStyle: CSSObject = {
  fontSize: '12px',
};

const titleStyle: CSSObject = {
  padding: '8px 16px',
  fontSize: '14px',
};

const subtitleStyle: CSSObject = {
  padding: '8px 16px',
  fontSize: '20px',
  fontWeight: 600,
};

const headerContainerStyle: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  minHeight: '88px',
  padding: '16px',
};

const infoBoxStyle: CSSObject = {
  backgroundColor: '#F2F6FC',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  borderRadius: '6px',
};

const infoContentStyle: CSSObject = {
  justifyContent: 'center',
  display: 'flex',
  alignItems: 'center',
};

const iconStyle: CSSObject = {
  padding: '16px',
  color: '#0052CC',
};

const textBoxStyle: CSSObject = {
  padding: '8px',
};

const buttonStyle: CSSObject = {
  backgroundColor: 'white',
  fontWeight: 600,
  margin: '8px',
  padding: '4px 16px',
  border: '1px solid #0052CC',
  whiteSpace: 'nowrap',
  minWidth: '104px',
  height: '32px',
  overflow: 'hidden',
  textAlign: 'center',
};

/**
 * パンくずリストとタイトル
 * @returns
 */
const TitleAndTopicPath = () => {
  return (
    <Box flex={1}>
      <Box sx={titleStyle}>機器を新規登録 ＞ 院内で未登録の機種から登録</Box>
      <Box sx={subtitleStyle}>院内未登録の機種を検索し、登録してください</Box>
    </Box>
  );
};

/**
 * 右側のインフォメーションと登録ボタン
 * @param param0
 * @returns
 */
const Information = ({onClick}: {onClick: VoidFunction}) => {
  return (
    <Box flex={1} sx={infoBoxStyle}>
      <Box sx={infoContentStyle}>
        <InfoOutlinedIcon sx={iconStyle} />
        <Box sx={textBoxStyle}>
          <Typography variant="body1" sx={informationStyle}>
            登録したい機種が検索結果に表示されない場合、あるいは医療機器でない場合は、右のボタンから機種情報をご登録ください。
          </Typography>
        </Box>
      </Box>
      <Button variant="outlined" sx={buttonStyle} onClick={onClick}>
        手入力で登録
      </Button>
    </Box>
  );
};

type Props = {
  onClickNewWholeProductRegister: () => void;
};

/***
 * 院内の未登録機種を検索し登録する画面のヘッダー部分
 ***/
export const Header = ({onClickNewWholeProductRegister}: Props) => {
  return (
    <Box sx={headerContainerStyle}>
      <TitleAndTopicPath />
      <Information onClick={onClickNewWholeProductRegister} />
    </Box>
  );
};
