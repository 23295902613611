import React from 'react';
import {CSSObject, Typography} from '@mui/material';
import {PageHeaderAlert} from '@molecules/PageHeaderAlert';

const FontSizeCss: CSSObject = {
  fontSize: '14px',
};
const HeadTextCss: CSSObject = {
  ...FontSizeCss,
  fontWeight: 700,
};

type Props = {onClose: VoidFunction};

/**
 *
 * 定期点検計画ページにて検索結果が適用されている際に表示される注意喚起ヘッダー
 *
 * @param props.onClose 閉じる処理
 * @returns
 */
export const ProductPlanCationHeader = ({onClose}: Props) => {
  return (
    <PageHeaderAlert onClose={onClose} variant="info">
      <Typography component={'h1'} sx={HeadTextCss}>
        定期点検計画を作成する対象機器を選択し、「点検開始月」と「月ごとの点検日」を設定してください。
      </Typography>
      <Typography component={'p'} sx={FontSizeCss}>
        ※この画面を離れると検索結果がクリアされます。再度、同機種の定期点検計画を作成したい場合は、「機種名・型式」検索フィルタをご活用ください。
      </Typography>
    </PageHeaderAlert>
  );
};
