import {HospitalProductIndex} from '@modules/hospital_products/types';
import {Box} from '@mui/material';
import React from 'react';
import {
  tableTitleStyle,
  BaseSharedListTable,
  BaseSharedListTableColumn,
} from '@Apps/BaseSharedMenu/BaseSharedListTable';
import {CancelButton} from '@Apps/BaseSharedMenu/CancelButton';

type RowData = {
  managementId: string;
  cancelButton: JSX.Element | null;
};

const columns: BaseSharedListTableColumn<RowData>[] = [
  {id: 'managementId', label: '管理番号', minWidth: '140px'},
  {id: 'cancelButton', label: '', minWidth: 'fit-content', sx: {width: 'fix-content'}},
];

export type TableProps = {
  rentingHospitalProducts: HospitalProductIndex[];
  onClickCancel: (rowIndex: number) => void;
};

export const ScannedProductTable = ({rentingHospitalProducts, onClickCancel}: TableProps) => {
  const tableRows = rentingHospitalProducts.map((item, index) => ({
    managementId: item.managementId ?? '',
    cancelButton: item.managementId ? (
      <CancelButton
        onClickCancel={() => {
          onClickCancel(index);
        }}
      />
    ) : null,
  }));

  return (
    <>
      <Box sx={tableTitleStyle}>読み取った機器リスト</Box>
      <BaseSharedListTable<RowData> tableRows={tableRows} columns={columns} />
    </>
  );
};
