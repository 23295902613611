import React, {useState} from 'react';
import {Collapse, Grid, List, ListItem, ListItemIcon, ListItemText, makeStyles, Theme} from '@material-ui/core';
import {ExpandMore, ChevronRight} from '@material-ui/icons';
import {FastField, useFormikContext} from 'formik';
import {RepairIndex} from '@modules/repairs/types';
import {formatRFC3339Date, isNullish} from '@front-libs/helpers';

type Props = {
  sectionName: string;
  fields: {
    type: string;
    label: string;
    name: keyof RepairIndex | DisposalField;
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    InputComponent: React.ComponentType<any>;
    hidden?: (repair: RepairIndex) => boolean | boolean;
    readOnly?: (repair: RepairIndex) => boolean | boolean;
    options?: {
      label: string;
      value: string | number | boolean | (string | number | boolean)[];
    }[];
    multiline?: boolean;
    clearable?: boolean;
  }[];
};

type DisposalField = 'dateOfDisposal' | 'reasonOfDisposal';

export const FormFieldSection: React.FC<Props> = ({sectionName, fields}) => {
  const classes = useStyles();
  const context = useFormikContext<RepairIndex>();
  const [open, setOpen] = useState(true);

  return (
    <>
      <ListItem
        button
        className={classes.collapseControl}
        onClick={() => {
          setOpen(!open);
        }}>
        <ListItemIcon className={classes.listIcon}>{open ? <ExpandMore /> : <ChevronRight />}</ListItemIcon>
        <ListItemText
          classes={{
            primary: classes.listText,
          }}
          primary={sectionName}
        />
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {fields
            .filter((field) => {
              if (isNullish(field.hidden)) return true;
              const hidden = typeof field.hidden === 'function' ? field.hidden(context.values) : field.hidden;
              return !hidden;
            })
            .map((field, index) => {
              const name =
                field.name === 'dateOfDisposal' || field.name === 'reasonOfDisposal'
                  ? `faultHospitalProduct.${field.name}`
                  : field.name;
              let value =
                field.name === 'dateOfDisposal' || field.name === 'reasonOfDisposal'
                  ? field.name in context.values.faultHospitalProduct
                    ? context.values.faultHospitalProduct[field.name]
                    : ''
                  : field.name in context.values
                    ? context.values[field.name]
                    : '';

              // field.typeがdatetime-localの場合はフォーマットしないと表示できない
              if (field.type === 'datetime-local' && ((value as string)?.length ?? 0) > 'YYYY-MM-DDTHH:mm'.length) {
                value = formatRFC3339Date(value as string, 'YYYY-MM-DDTHH:mm') ?? '';
              }
              const readOnly = typeof field.readOnly === 'function' ? field.readOnly(context.values) : field.readOnly;

              return (
                <Grid container className={classes.fieldContainer} key={field.name + index}>
                  <FastField
                    type={field.type}
                    label={field.label}
                    fullWidth
                    name={name}
                    value={value}
                    component={field.InputComponent}
                    disabled={readOnly}
                    options={field.options}
                    multiline={field.multiline}
                    clearable={field.clearable}
                  />
                </Grid>
              );
            })}
        </List>
      </Collapse>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  fieldContainer: {
    padding: '8px 16px',
  },
  collapseControl: {
    paddingTop: 24,
    paddingBottom: 24,
    paddingLeft: 16,
    borderTop: '2px solid #F5F8FA',
  },
  listIcon: {
    minWidth: 0,
    paddingRight: '8px',
  },
  listText: {
    fontSize: theme.typography.fontSize,
    fontWeight: 'bold',
  },
}));
