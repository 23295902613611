import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Box, createStyles, Grid, makeStyles, Paper, styled, Theme, Tooltip, Typography} from '@material-ui/core';
import {useNavigate} from 'react-router-dom';
import {Pagination} from '@material-ui/lab';
import {useTableLayout} from '@modules/table_layout/hooks/useTableLayout';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {Column} from '@molecules/Table/props';
import {Table} from '@molecules/Table';
import {DashboardMonthlyInspection, DashboardMonthlyInspectionParam} from '@modules/dashboard/types';
import {pickFirstQuery} from '@front-libs/helpers';
import {useSearchParams} from '@front-libs/core';
import {useDashboardMonthlyInspectionQuery} from '@modules/dashboard/api';
import {InspectionResultStatus} from '@modules/inspection_results/enum';
import {InspectionTypesToLabel} from '@modules/inspections/enum';
import {useUpdateDashboardSubject, UpdatePaperType} from '../hooks';
import {
  StartInspectionDialog,
  StartInspectionDialogProps,
  StartInspectionDialogResult,
} from '@components/organisms/StartInspectionDialog';
import {dialogHandler} from '@components/molecules/Dialogs/DialogHandler';
import {NextUpdateInspectionStatusParam, updateInspectionResult} from '@modules/inspection_results/api';
import {openSnackBar} from '@components/molecules/SnackBar';
import {InspectionResultStatusBadge} from '@components/atoms/InspectionResultStatusBadge';
import dayjs from 'dayjs';
import Assignment from '@material-ui/icons/Assignment';
import {getHospitalProduct} from '@modules/hospital_products/api';
import {PaperHeader} from '../PaperHeader';
import {papers} from '../consts';
import {TableLayoutDialog, TableLayoutDialogProps} from '@organisms/Table/TableLayoutDialog';
import {MenuItemType, PopperMenuButton} from '@molecules/Buttons/PopperMenuButton';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {useMyRole} from '@modules/hospital_users/hooks/useMyRole';
import {productStatusTypeMap} from '@modules/hospital_products/constants';
import {ProductStatusConst} from '@modules/hospital_products/types';

const pageSizeMenuItems = [
  {
    label: '20件',
    value: 20,
  },
  {
    label: '50件',
    value: 50,
  },
  {
    label: '100件',
    value: 100,
  },
];

export const MonthlyInspectionsTable: React.FC = () => {
  const paper = papers.inspectionList;
  const [tableLayout, setTableLayout] = useTableLayout('dashboardMonthlyList');

  const classes = useStyles();
  const {myInfo} = useMyInfo();
  const {isReadOnly} = useMyRole();
  const queryParams = useSearchParams();
  const {subscribe, unsubscribe} = useUpdateDashboardSubject();
  const navigate = useNavigate();

  const [pageSize, setPageSize] = useState<number>(20);
  const [orderKey, setOrderKey] = useState<string | null>(pickFirstQuery(queryParams.order) ?? '-scheduledTime');
  const [page, setPage] = useState<number>(1);

  const handleChangePage = useCallback((event: React.ChangeEvent<unknown>, p: number) => {
    setPage(p);
  }, []);

  const params = useMemo(() => {
    const _p: DashboardMonthlyInspectionParam = {
      page: page - 1,
      per_page: pageSize,
    };
    if (orderKey) {
      _p.order = orderKey;
    }
    return _p;
  }, [page, orderKey, pageSize]);

  const query = useDashboardMonthlyInspectionQuery(myInfo.hospitalHashId, params);

  const totalPage = useMemo(() => {
    return Math.ceil(query.totalCount / pageSize);
  }, [pageSize, query.totalCount]);

  const startDisplayPosition = useMemo(() => {
    return query.totalCount === 0 ? 0 : Math.ceil((page - 1) * pageSize + 1);
  }, [query.totalCount, page, pageSize]);

  const endDisplayPosition = useMemo(() => {
    const endPosition = Math.ceil(page * pageSize);
    return endPosition > query.totalCount ? query.totalCount : endPosition;
  }, [page, pageSize, query.totalCount]);

  /**
   * 点検を開始ダイアログ表示
   * @param {DashboardMonthlyInspection} data - DashboardMonthlyInspection
   */
  const openStartInspectionDialog = useCallback(
    async (data: DashboardMonthlyInspection) => {
      try {
        const hospitalProduct = await getHospitalProduct(myInfo.hospitalHashId, data.hospitalProductHashId);
        if (!hospitalProduct) {
          throw new Error('hospital product not found');
        }

        return await dialogHandler.open<StartInspectionDialogProps, StartInspectionDialogResult>(
          StartInspectionDialog,
          {
            hospitalHashId: myInfo.hospitalHashId,
            wholeProductHashId: hospitalProduct.wholeProductHashId,
            hospitalProductHashId: hospitalProduct.hashId,
            inspectionResultHashId: data.hashId,
            defaultInspectorHashId: myInfo.hashId,
            defaultInspectionHashId: data.inspectionHashId,
            showsSelectInspection: data.status !== 'uncompleted',
            inspectionType: data.type,
          }
        );
      } catch (_e) {
        return;
      }
    },
    [myInfo.hashId, myInfo.hospitalHashId]
  );

  /**
   * 点検開始ボタン押下
   */
  const handleClickInspectionStart = useCallback(
    async (data: DashboardMonthlyInspection) => {
      let inspectionResultHashId = data.hashId;
      let inspectionHashId = data.inspectionHashId;
      let inspectorHashId = data.inspector?.hashId;

      // NOTE:実施途中以外はダイアログを表示
      const res = await openStartInspectionDialog(data);
      if (!res) return;

      inspectionResultHashId = res.inspectionResult;
      inspectionHashId = res.inspection;
      inspectorHashId = res.inspector;

      try {
        await updateInspectionResult(myInfo.hospitalHashId, inspectionHashId, inspectionResultHashId, {
          status: 'uncompleted',
          inspectionHashId: inspectionHashId,
          inspectorHashId: inspectorHashId,
        });
        // NOTE:_InspectionResultでuseLocation経由で受け取るためのParam
        const nextUpdateInspectionStatusParam: NextUpdateInspectionStatusParam = {
          status: 'uncompleted',
          inspectorHashId: inspectorHashId,
        };
        navigate(`/inspections/${inspectionHashId}/result/${inspectionResultHashId}`, {
          state: nextUpdateInspectionStatusParam,
        });
      } catch (e) {
        console.error(e);
        openSnackBar('点検実績の更新に失敗しました', 'left', 'bottom', 'error');
      }
    },
    [myInfo.hospitalHashId, navigate, openStartInspectionDialog]
  );

  const serializedTableColumn = useMemo(() => {
    const tableColumn: Column<DashboardMonthlyInspection>[] = isReadOnly
      ? tableLayout.currentLayout
      : [
          {
            title: '',
            field: 'hashId',
            render: (data: DashboardMonthlyInspection) => {
              return (
                <Tooltip className={classes.tooltip} title="点検開始" onClick={() => handleClickInspectionStart(data)}>
                  <Assignment color="secondary" />
                </Tooltip>
              );
            },
          },
          ...tableLayout.currentLayout,
        ];

    return tableColumn.map<Column<DashboardMonthlyInspection>>((item) => {
      switch (item.field) {
        case 'managementId':
          item.render = ({hospitalProduct}: DashboardMonthlyInspection) => {
            return (
              <Typography
                className={classes.managementId}
                onClick={() => {
                  navigate(`/products/${hospitalProduct.hashId}`);
                }}>
                {hospitalProduct.managementId}
              </Typography>
            );
          };
          break;
        case 'type':
          item.render = (inspection: DashboardMonthlyInspection) => {
            return (
              <>
                {InspectionTypesToLabel[inspection.type]}
                {inspection.isAdhocDate && <div>(臨時)</div>}
              </>
            );
          };
          break;
        case 'status':
          item.render = ({status}: {status: InspectionResultStatus}) => InspectionResultStatusBadge({status}) ?? '';
          break;
        case 'rootCategory':
          item.render = ({hospitalProduct}: DashboardMonthlyInspection) =>
            hospitalProduct.wholeProduct?.categories.find((cate) => cate.depth === 0)?.name ?? '';
          break;
        case 'narrowCategory':
          item.render = ({hospitalProduct}: DashboardMonthlyInspection) =>
            hospitalProduct.wholeProduct?.categories.find((cate) => cate.depth === 1)?.name ?? '';
          break;
        case 'name':
          item.render = ({hospitalProduct}: DashboardMonthlyInspection) => hospitalProduct.wholeProduct?.name ?? '';
          break;
        case 'displayName':
          item.render = ({hospitalProduct}: DashboardMonthlyInspection) =>
            hospitalProduct.wholeProduct?.displayName ?? '';
          break;
        case 'makerName':
          item.render = ({hospitalProduct}: DashboardMonthlyInspection) =>
            hospitalProduct.wholeProduct?.maker.name ?? '';
          break;
        case 'scheduledTime':
          item.render = ({scheduledTime}: DashboardMonthlyInspection) =>
            scheduledTime ? dayjs(scheduledTime).format('YYYY-MM-DD') : '';
          break;
        case 'suspendedAt':
          item.render = ({suspendedAt}: DashboardMonthlyInspection) =>
            suspendedAt ? dayjs(suspendedAt).format('YYYY-MM-DD') : '';
          break;
        case 'inspector':
          item.render = ({inspector}: DashboardMonthlyInspection) =>
            inspector ? `${inspector.lastName} ${inspector.firstName}` : '';
          break;
        case 'hospitalProductStatus':
          item.render = ({hospitalProduct}: DashboardMonthlyInspection) =>
            hospitalProduct ? <HospitalStatus status={hospitalProduct.status} /> : '';
          break;
        default:
          break;
      }
      item.noBodyWrap = true;
      return item;
    });
  }, [classes.managementId, classes.tooltip, handleClickInspectionStart, navigate, tableLayout]);

  const handleOrderChange = useCallback(
    (columnIndex: number, orderDirection: 'asc' | 'desc') => {
      if (columnIndex === -1) {
        setOrderKey(null);
      } else {
        setOrderKey(`${orderDirection === 'desc' ? '-' : ''}${serializedTableColumn[columnIndex].field}`);
      }
    },
    [serializedTableColumn]
  );

  const onUpdateDashboardSubject = useCallback(
    (paperType: UpdatePaperType) => {
      if (paperType !== 'all' && paperType !== 'inspection_list') return;
      query.refetch();
    },
    [query]
  );

  const handleChangeLayout = useCallback(async () => {
    const currentLayout = await dialogHandler.open<TableLayoutDialogProps>(TableLayoutDialog, {
      tableColumns: tableLayout?.tableLayout ?? [],
      defaultOptions: tableLayout?.currentLayout ?? [],
      forceValue: {managementId: true},
    });
    const newTableLayout = {
      tableLayout: tableLayout?.tableLayout ?? [],
      currentLayout: currentLayout,
    };

    setTableLayout(newTableLayout);
  }, [setTableLayout, tableLayout]);

  const handleChangeRowsPerPage = useCallback((item: MenuItemType) => {
    setPageSize(item.value);
    setPage(1);
  }, []);

  useEffect(() => {
    subscribe(onUpdateDashboardSubject);
    return () => unsubscribe(onUpdateDashboardSubject);
  }, []);

  return (
    <Paper className={classes.paper}>
      <PaperHeader
        title={paper.label}
        onClickSettingMenu={handleChangeLayout}
        settingMenuItems={paper.settingMenuItems}
      />
      <Grid container className={classes.root}>
        <Grid container item>
          <Table<DashboardMonthlyInspection>
            stickyHeader={true}
            columns={serializedTableColumn}
            isLoading={query.isLoading}
            data={query.data}
            showSelection={false}
            onOrderChange={handleOrderChange}
            paperProps={{
              className: classes.tableContainer,
            }}
          />
        </Grid>
        <Grid container justifyContent={'space-between'}>
          <Grid item className={classes.pageDescription}>
            {query.totalCount}件のうち{startDisplayPosition}件目-{endDisplayPosition}件目までを表示しています
          </Grid>
          <Grid item className={classes.paginationContainer}>
            <Pagination page={page} count={totalPage} shape="rounded" onChange={handleChangePage} />
            1ページあたり
            <PopperMenuButton
              placement="bottom"
              hiddenArrow={true}
              buttonProps={{variant: 'text'}}
              menuItemList={pageSizeMenuItems}
              onMenuClick={handleChangeRowsPerPage}>
              <span className={classes.pageSize}>{pageSize}件</span>
              <ArrowDropDownIcon />
            </PopperMenuButton>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

/**
 * 稼働状況の表示
 * 丸とステータスに対応した日本語名を表示し、Statusにあった色で表示する
 * @returns
 */
const HospitalStatus = ({status}: {status: ProductStatusConst}) => {
  const productStatus = productStatusTypeMap[status];
  if (!productStatus) return null;
  return (
    <StatusBox color={productStatus.color}>
      <CircleIcon backgroundColor={productStatus.color} />
      {productStatus.label}
    </StatusBox>
  );
};

const CircleIcon = styled('div')(({backgroundColor}: {backgroundColor: string}) => ({
  width: '10px',
  height: '10px',
  borderRadius: '50%',
  backgroundColor: backgroundColor,
}));
const StatusBox = styled(Box)(({color}: {color: string}) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  color: color,
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: '0px 16px 16px',
      borderTop: `4px solid ${theme.palette.primary.dark}`,
      height: 'calc(100% - 20px)',
    },
    root: {
      padding: '32px 24px',
    },
    flex: {
      flexGrow: 1,
    },
    tableContainer: {
      marginBottom: '24px',
      maxHeight: '360px',
      overflowY: 'scroll',
    },
    pagination: {
      marginTop: '24px',
      justifyContent: 'right',
    },
    managementId: {
      display: 'inline',
      color: theme.palette.info.dark,
      fontWeight: 'bold',
      cursor: 'pointer',
    },
    tooltip: {
      cursor: 'pointer',
      color: '#2A96EB',
    },
    pageDescription: {
      margin: 'auto 0px',
    },
    paginationContainer: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '0.875rem',
    },
    pageSize: {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
  })
);
