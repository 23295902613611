import {CSSObject, TableCell, TableHead, TableRow} from '@mui/material';
import React from 'react';

const TableCellStyle: CSSObject = {
  borderBottom: 'none',
};

export const CreateWholeProductHeader = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell sx={TableCellStyle}>点検名</TableCell>
        <TableCell sx={TableCellStyle}>点検タイプ</TableCell>
        <TableCell sx={{...TableCellStyle, minWidth: '200px'}}>点検間隔</TableCell>
        <TableCell sx={TableCellStyle}>使用する点検表</TableCell>
        <TableCell sx={{...TableCellStyle, minWidth: '80px'}} />
      </TableRow>
    </TableHead>
  );
};
