import {atom} from 'jotai';
import {Order} from '@molecules/Table/props';
import {FaultReceptionParams} from '@modules/fault_receptions/types';
import {atomWithReset, atomWithStorage} from 'jotai/utils';

const LOCAL_STORAGE_KEY_FAULT_RECEPTION_LIST = 'hitotsu/fault_reception_list';
export const orderKeyAtom = atom<Order | null>(null);
export const pageAtom = atomWithReset<number>(1);
export const pageSizeAtom = atomWithStorage<number>(LOCAL_STORAGE_KEY_FAULT_RECEPTION_LIST, 20);

export const faultReceptionsVariables = atom<FaultReceptionParams>((get) => {
  const order = get(orderKeyAtom);
  const _p: FaultReceptionParams = {
    page: get(pageAtom) - 1 || 0,
    perPage: get(pageSizeAtom),
    order: order ? `${order.direction === 'desc' ? '-' : ''}${order.fieldId}` : '-receptionId',
  };
  return _p;
});
