import React from 'react';
import {Droppable} from 'react-beautiful-dnd';
import {makeStyles, Paper, Theme} from '@material-ui/core';
import {DndDraggable} from '@atoms/dnd';
import {RepairCard} from './RepairCard';
import {RepairIndex} from '@modules/repairs/types';
import {TableLayout} from '@modules/table_layout/hooks/useTableLayout';

type RepairStatusStageProps = {
  hashId: string;
  title: string;
  count: number;
  sequence: number;
  isFirst?: boolean;
  isLast?: boolean;
  faultRepairs?: RepairIndex[];
  currentCardLayout: TableLayout[] | undefined;
};

export const RepairStatusStage: React.FC<RepairStatusStageProps> = ({isFirst = false, isLast = false, ...props}) => {
  const classes = useStyles();
  return (
    <Paper variant="outlined" className={classes.root} square>
      <div className={classes.statusTitleContainer}>
        <div>{props.title}</div>
        <div>{props.count}</div>
      </div>
      <Droppable droppableId={props.hashId}>
        {(provided) => {
          return (
            <div ref={provided.innerRef} {...provided.droppableProps} className={classes.scrollContainer}>
              {props.faultRepairs?.map((item, index) => (
                <DndDraggable isDragDisabled={false} key={index} index={index} draggableId={item.hashId}>
                  <div className={classes.repairCardContainer}>
                    <RepairCard currentCardLayout={props.currentCardLayout} repairData={item} />
                  </div>
                </DndDraggable>
              ))}
              {provided.placeholder}
            </div>
          );
        }}
      </Droppable>
    </Paper>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    overflowY: 'hidden',
    height: '100%',
    minHeight: '100%',
    display: 'flex',
    flex: 2,
    flexDirection: 'column',
    boxSizing: 'border-box',
    maxWidth: '296px',
    minWidth: '296px',
    '& + $root': {
      marginLeft: -1,
    },
  },
  statusTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#172B4D',
    padding: '16px 8px',
    borderBottom: '1px solid #E0E3EA',
  },
  repairCardContainer: {
    marginBottom: '8px',
  },
  productName: {
    color: theme.palette.secondary.dark,
    fontWeight: 'bold',
  },
  textContent: {
    marginBottom: '12px',
  },
  iconContent: {
    marginBottom: '12px',
    marginTop: '12px',
  },
  scrollContainer: {
    flexGrow: 1,
    backgroundColor: '#F6F8FA',
    padding: '8px',
    overflowY: 'scroll',
  },
}));
