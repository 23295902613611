import jsQR from 'jsqr';
import {ImageWrapper} from '@ericblade/quagga2';

// NOTE: `ericblade/quagga2-reader-qr` を参考に定義した。
// SEE: https://github.com/ericblade/quagga2-reader-qr/blob/87774e6b507ad0a34fc4b08d2425e8dd4580bf2e/src/index.ts
class QrCodeReader {
  decodeImage(inputImageWrapper: ImageWrapper) {
    const data = inputImageWrapper.getAsRGBA();
    const result = jsQR(data, inputImageWrapper.size.x, inputImageWrapper.size.y);
    if (result === null) {
      return null;
    }

    return {
      codeResult: {
        code: result.data,
      },
      ...result,
    };
  }

  decodePattern(_pattern: number[]) {
    return null;
  }
}

export default QrCodeReader;
