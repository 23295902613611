import React from 'react';
import {styled, FormControl, FormControlLabel, RadioGroup, Radio} from '@mui/material';
import {RequiredLabel} from '@molecules/FormRequiredLabel';
import {InspectionTypeOptions} from '@components/organisms/Header/pc/GeneralMenu/HospitalProductMenu/constants';
import {InspectionType} from '@components/organisms/Header/pc/GeneralMenu/HospitalProductMenu/types';

type Props = {
  selectedInspectionType: InspectionType | null;
  onSelect: (value: string) => void;
};

const RadioGroupItem = styled(FormControlLabel)({
  marginTop: '8px',
  '& .MuiFormControlLabel-label': {
    fontSize: '14px',
    lineHeight: '24px',
  },
});

const RadioGroupItemControl = styled(Radio)({
  paddingTop: 0,
  paddingBottom: 0,
});

export const InspectionTypeSelector = ({selectedInspectionType, onSelect}: Props) => {
  const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSelect(event.target.value);
  };

  return (
    <FormControl>
      <RequiredLabel>点検タイプ</RequiredLabel>
      <RadioGroup value={selectedInspectionType} onChange={handleSelect}>
        {InspectionTypeOptions.map((opt) => (
          <RadioGroupItem key={opt.value} control={<RadioGroupItemControl />} label={opt.label} value={opt.value} />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
