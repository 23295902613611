import React, {useCallback, useMemo} from 'react';
import {NoData, Table} from '@molecules/Table';
import {Column} from '@molecules/Table/props';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {TableLayout, useTableLayout} from '@modules/table_layout/hooks/useTableLayout';
import {useNavigate} from 'react-router-dom';
import {convertDateToJapaneseDateTime} from '@front-libs/helpers';
import {ReceptionIdColumn} from '../common/ReceptionIdColumn';
import {ChangeTextColumn} from '../common/ChangeTextColumn';
import {isNullish} from '@front-libs/helpers';
import {useFaultReceptionQuery} from '../hooks';
import {useAtom} from 'jotai';
import {orderKeyAtom} from '../jotai';
import {LocationColumn} from '../common/LocationColumn';
import {FaultReceptionLocationType} from '@modules/fault_receptions/types';

export type FaultReceptionElement = {
  hashId: string;
  /** 受付番号 */
  receptionId: string;
  /** 受付日時 */
  createdAt: string;
  /** どんな機器 */
  productFeature: string;
  /** どんな不具合 */
  faultDetail: string;
  /** 使用していた場所 */
  rentalRoomName: string;
  /** 誰に聞いたら良いか */
  reporterName: string;
  /** どこで回収できるか */
  location: FaultReceptionLocationType;
  /** 回収場所 */
  locationName: string;
  /** 代替機 */
  isNeedAlternativeDevice: string;
  /** 至急の対応 */
  isNeedUrgentResponse: string;
};

/** 受付番号カラムのレンダリングロジック */
const renderReceptionIdColumn = (rowData: FaultReceptionElement) => {
  return <ReceptionIdColumn rowData={rowData} />;
};

/** 機器情報・不具合・使用場所のレンダリングロジック */
const renderChangeTextColumn = (text: string) => {
  return <ChangeTextColumn text={text} />;
};

/** 回収場所カラムのレンダリングロジック */
const renderLocationColumn = (location: FaultReceptionLocationType, locationName?: string) => {
  return <LocationColumn location={location} locationName={locationName} />;
};

/**
 * 不具合受付履歴テーブル
 * @returns
 */
export const FaultReceptionTable: React.FC = () => {
  const navigate = useNavigate();
  const {myInfo} = useMyInfo();
  const [order, setOrder] = useAtom(orderKeyAtom);
  const [tableLayout] = useTableLayout('faultReceptionList');

  const query = useFaultReceptionQuery(myInfo.hospitalHashId);

  const handleClickRow = (_event?: React.MouseEvent, rowData?: FaultReceptionElement) => {
    if (isNullish(rowData)) return;
    navigate(`/shared/fault_receptions/${rowData.hashId}`);
  };

  const handleOrderChange = useCallback(
    (columnIndex: number, orderDirection: 'asc' | 'desc') => {
      if (columnIndex === -1) {
        setOrder(null);
      } else {
        setOrder({
          fieldId: String(tableLayout?.currentLayout[columnIndex].field),
          direction: orderDirection,
        });
      }
    },
    [setOrder, tableLayout?.currentLayout]
  );

  const listData = query.data.map((data) => {
    return {
      receptionId: data.receptionId,
      createdAt: convertDateToJapaneseDateTime(data.createdAt) ?? '',
      productFeature: data.productFeature,
      faultDetail: data.faultDetail,
      rentalRoomName: data.rentalRoomName,
      reporterName: data.reporterName,
      location: data.location,
      locationName: data.locationName || '',
      isNeedAlternativeDevice: data.isNeedAlternativeDevice ? '必要' : '不要',
      isNeedUrgentResponse: data.isNeedUrgentResponse ? '必要' : '不要',
      hashId: data.hashId,
    };
  });

  const serializedTableColumn = useMemo(() => {
    const tableColumn = Object.assign<Column<FaultReceptionElement>[], TableLayout[]>([], tableLayout.currentLayout);
    return tableColumn.map<Column<FaultReceptionElement>>((item) => {
      switch (item.field) {
        case 'receptionId':
          item.render = renderReceptionIdColumn;
          break;
        case 'productFeature':
          item.render = (data) => renderChangeTextColumn(data.productFeature);
          break;
        case 'faultDetail':
          item.render = (data) => renderChangeTextColumn(data.faultDetail);
          break;
        case 'rentalRoomName':
          item.render = (data) => renderChangeTextColumn(data.rentalRoomName);
          break;
        case 'reporterName':
          item.render = (data) => renderChangeTextColumn(data.reporterName);
          break;
        case 'location':
          item.render = ({location, locationName}: FaultReceptionElement) =>
            renderLocationColumn(location, locationName);
          break;
        default:
          break;
      }
      item.noBodyWrap = true;
      return item;
    });
  }, [tableLayout.currentLayout]);

  return (
    //TODO:新テーブルに置き換える
    <Table<FaultReceptionElement>
      stickyHeader={true}
      columns={serializedTableColumn}
      isLoading={query.isLoading}
      data={listData}
      showSelection={false}
      onOrderChange={handleOrderChange}
      onRowClick={handleClickRow}
      tableSize="small"
      noDataComponent={
        <NoData title={'不具合受付の履歴はありません。'} message={'ここで不具合受付の履歴を確認できます。'} />
      }
      defaultOrder={
        order ?? {
          fieldId: 'receptionId',
          direction: 'desc',
        }
      }
    />
  );
};
