import {useMemo} from 'react';
import {useQuery} from 'react-query';
import {getInspectionSettingKey} from '@constants/api';
import {getInspectionProductSettings} from './api';
import {FetchInspectionSettingsParam} from './types';

export const useFetchInspectionSettingsQuery = (hospitalHashId: string, params: FetchInspectionSettingsParam) => {
  const query = useQuery(
    [getInspectionSettingKey, hospitalHashId, params],
    () => getInspectionProductSettings(hospitalHashId, params),
    {
      enabled: Object.keys(params).length > 0,
    }
  );

  return useMemo(
    () => ({
      ...query,
      data: query.data?.data ?? [],
      totalCount: query.data?.totalCount ?? 0,
    }),
    [query]
  );
};
