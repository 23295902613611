import Image from '@assets/image.png';
import {Sidebar} from '@components/organisms/Sidebar';
import {Button, Grid, Theme, Typography, createStyles, makeStyles} from '@material-ui/core';
import {useSettingsContentTemplate} from '@templates/ContentLayout/InnerSidebarContentLayout';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageTitle: {
      fontSize: 28,
    },
    formControl: {
      marginTop: 24,
    },
    formHelperText: {
      fontSize: 14,
      color: theme.palette.grey[600],
    },
    circleContainer: {
      position: 'relative',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    avatarIcon: {
      width: 56,
      height: 56,
    },
    avatarIconOverlay: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1,
      backgroundColor: 'rgba(70, 72, 75, 0.8)',
      borderRadius: 100,
      transition: 'opacity 0.25s',
      opacity: 0,
      '&:hover': {
        opacity: 1,
      },
    },
    editIcon: {
      color: theme.palette.common.white,
      display: 'block',
    },
    popperMenuButtonBtn: {
      padding: 0,
      '&:hover': {
        backgroundColor: theme.palette.common.white,
      },
    },
    textFieldsContainer: {
      marginBottom: 45,
    },
    fontBold: {
      fontWeight: 700,
    },
    text: {
      color: '#172B4D',
      fontSize: '20px',
      marginBottom: '24px',
    },
    image: {
      maxHeight: '380px',
    },
  })
);

const ProfileForm = () => {
  const classes = useStyles();
  const templateClasses = useSettingsContentTemplate();

  return (
    <Grid container className={templateClasses.grid}>
      <Grid item className={templateClasses.sideBar}>
        <Sidebar />
      </Grid>
      <Grid item className={templateClasses.content}>
        <Grid container style={{marginBottom: '32px'}}>
          <Grid item>
            <Typography variant={'h1'} className={classes.pageTitle}>
              ユーザー情報
            </Typography>
          </Grid>
        </Grid>
        <Grid className={classes.text}>
          氏名、ユーザーID、パスワード、メールアドレスはHITOTSU Hospitalで変更できます。
        </Grid>
        <Grid>
          <a href={import.meta.env.VITE_ACCOUNT_SERVICE_URL} target="_blank" rel="noreferrer">
            <Button variant="contained" color="primary">
              HITOTSU Hospitalへ移動
            </Button>
          </a>
        </Grid>
        <div>
          <div className={classes.text} style={{marginTop: '24px', marginBottom: 0}}>
            変更手順:
          </div>
          <Grid container style={{gap: '24px'}}>
            <Grid item>
              <img src={Image} alt="操作方法" width={750} />
            </Grid>
            <Grid item style={{marginTop: 24}}>
              <Typography>①HITOTSU Hospitalにアクセスし、右上に表示されるユーザー名をクリック</Typography>
              <Typography>②プロフィールの右上に出てくる編集ボタンをクリック</Typography>
              <Typography>③変更したい項目を選び、変更する</Typography>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

export const AccountProfile: React.FC = () => {
  const templateClasses = useSettingsContentTemplate();

  return (
    <Grid container className={templateClasses.grid}>
      <ProfileForm />
    </Grid>
  );
};
