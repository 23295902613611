import React, {memo, useMemo} from 'react';
import {
  BaseField,
  RadioField,
  PullDownField,
  CheckboxField,
  NumericField,
  SingleLineTextField,
  MultiLineTextField,
  DateField,
  TimeField,
} from '@components/molecules/InspectionTableFormItems';
import {InspectionItem} from '@modules/inspections/api';

type InnerItemProps = {
  item: InspectionItem;
};

export const InnerItem = memo((props: InnerItemProps) => {
  const {item} = props;

  const typedItem = useMemo(() => {
    if (!item || item.type === null) return null;

    switch (item.type) {
      case 'select':
        if (item.view === 'radio') {
          return <RadioField options={item.options} isEditable={false} value="" />;
        } else if (item.view === 'pull-down') {
          return <PullDownField options={item.options} isEditable={false} value="" />;
        }
        return null;
      case 'multi-select':
        return <CheckboxField options={item.options} isEditable={false} values={{}} />;
      case 'numeric':
        return <NumericField unit={item.unit} isEditable={false} value="" validator={item.validator} />;
      case 'text':
        return <SingleLineTextField placeholder="" isEditable={false} showsError={false} value="" />;
      case 'multiline-text':
        return <MultiLineTextField placeholder="" isEditable={false} showsError={false} value="" />;
      case 'date':
        return <DateField isEditable={false} showsError={false} value="" />;
      case 'time':
        return <TimeField isEditable={false} showsError={false} value="" />;
      default:
        return null;
    }
  }, [item]);

  // item.typeがnull、もしくはsectionの場合は表示しない
  // また、現状bool型の点検項目も存在しないため、typeがboolの場合も表示しない
  if (!item || item.type === null || item.type === 'section' || item.type === 'bool') return null;

  return <BaseField item={item}>{typedItem}</BaseField>;
});
