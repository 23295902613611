import React from 'react';
import {Box, SxProps} from '@mui/material';
import {wholeProductDetailFormFields} from './const';
import {FieldFactory} from './FieldFactory';

const felidWrapStyle: SxProps = {
  display: 'grid',
  flexWrap: 'wrap',
  gap: '16px',
  marginTop: '64px',
  marginBottom: '16px',
  gridTemplateColumns: 'repeat(auto-fit, minmax(212px, 1fr))',
  // 一つのFormが212px が4つ並んだとき 右ペイン(タイムライン等)の幅600pxにPadding32pxを追加した数値
  // 212 * 4 + 600 + 32 * 2 = 1588
  '@media (min-width: 1588px)': {
    gridTemplateColumns: 'repeat(4, 1fr)', // 最大4列に制限
  },
};

const FormStyle: SxProps = {
  padding: '8px 16px',
  minWidth: '180px',
};

export const WholeProductForm = () => {
  return (
    <form>
      <Box sx={felidWrapStyle}>
        {wholeProductDetailFormFields.map((field, index) => (
          <Box sx={FormStyle} key={index}>
            {FieldFactory(field)}
          </Box>
        ))}
      </Box>
    </form>
  );
};
