import React, {useImperativeHandle, useState} from 'react';
import {SearchAndFilter} from '@organisms/SearchAndFilter';
import {useAtom} from 'jotai';
import {PaginationAndPerPage} from '@organisms/PaginationAndPerPage/PaginationAndPerPage';
import {ProductsPartsTablePageSizeAtom} from './state';
import {Box, SxProps} from '@mui/material';
import {ProductsPartsTable} from './ProductPartsTable';
import {useFetchWholeProductPartsQuery} from '@modules/whole_product_parts/hooks';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {FetchWholeProductPartsParam} from '@modules/whole_product_parts/types';
import {useDebounceMemo} from '@front-libs/core';
import {useMyRole} from '@modules/hospital_users/hooks/useMyRole';
const rootStyle: SxProps = {
  padding: '16px 24px',
  width: 'stretch',
};

// ** APIのパラメータの生成 */
const generateFetchWholeProductPartsParams = (
  page: number,
  pageSize: number,
  searchName: string | null
): FetchWholeProductPartsParam => {
  const params: FetchWholeProductPartsParam = {
    page: page - 1,
    perPage: pageSize,
  };

  if (searchName) {
    params.name = searchName;
  }
  return params;
};

export type ProductsPartsBodyRef = {refetchData: () => void};

export const ProductsPartsBody = React.forwardRef<ProductsPartsBodyRef>((_, ref) => {
  const {myInfo} = useMyInfo();
  const {isReadOnly, isAdmin} = useMyRole();
  const [searchName, setSearchName] = useState('');
  const [pageSize, setPageSize] = useAtom(ProductsPartsTablePageSizeAtom);
  const [page, setPage] = useState<number>(1);

  const params = useDebounceMemo<FetchWholeProductPartsParam>(
    () => {
      return generateFetchWholeProductPartsParams(page, pageSize, searchName);
    },
    [page, pageSize, searchName],
    300
  );

  const {data, isLoading, totalCount, refetch} = useFetchWholeProductPartsQuery(myInfo.hospitalHashId, params);

  useImperativeHandle<ProductsPartsBodyRef, ProductsPartsBodyRef>(ref, () => ({
    refetchData: refetch,
  }));

  const handleChangeSearchName = (newSearchName: string) => {
    if (page !== 1) setPage(1);
    setSearchName(newSearchName);
  };
  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
    setPage(1);
  };

  return (
    <Box sx={rootStyle}>
      <SearchAndFilter
        searchNameValue={searchName ?? ''}
        onChangeSearchName={handleChangeSearchName}
        searchLabel="部品・交換品名/品目で検索"
      />
      <ProductsPartsTable
        data={data}
        isLoading={isLoading}
        isReadOnly={isReadOnly}
        isAdmin={isAdmin}
        hospitalHashId={myInfo.hospitalHashId}
        refetch={refetch}
      />
      <PaginationAndPerPage
        totalCount={totalCount ?? 0}
        currentPage={page}
        pageSize={pageSize}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
      />
    </Box>
  );
});
