import React, {useCallback, useEffect, useState} from 'react';
import {useAtom, useAtomValue} from 'jotai';
import {DragDropContext} from 'react-beautiful-dnd';
import {useDnDLogic} from './hooks';
import {RentalDnDSection} from './RentalDnDSection';
import {Header} from './Header';
import {customSequence} from '@modules/hospital_ward_layout_setting/types';
import {useFormikContext} from 'formik';
import {Box, Typography, SxProps} from '@mui/material';
import {DEFAULT_ORDER} from './Header/consts';
import {layoutSettingSelectedFloorStatus, layoutSettingSelectedWardStatus, radioAtom, tabAtom} from './states';
import {MenuItemType, PopperMenuButtonV5} from '@components/molecules/Buttons/PopperMenuButton/PopperMenuButtonV5';

type Option = {
  label: string;
  value: string;
};

type FloorOption = {
  floorNumber: string;
  isGroundFloor: boolean;
} & Option;

type PositionSettingProps = {
  data: customSequence[];
  wardOptions?: MenuItemType<string>[];
  floorOptions?: FloorOption[];
  handleClickCancel: () => Promise<void>;
  resetFlag: boolean;
};

/**
 * エリアボタンの並び順設定画面
 */
export const PositionSetting = ({
  data,
  wardOptions,
  floorOptions,
  handleClickCancel,
  resetFlag,
}: PositionSettingProps): JSX.Element => {
  const {setFieldValue} = useFormikContext<{Setting: customSequence[]}>();
  const {sections, handleOnDragEnd} = useDnDLogic(data || [], resetFlag);
  const radioButton = useAtomValue(radioAtom);
  const tab = useAtomValue(tabAtom);
  const [isDnDEnabled, setIsDnDEnabled] = useState(true);
  const [selectedWard, setSelectedWard] = useAtom(layoutSettingSelectedWardStatus);
  const [selectedFloor, setSelectedFloor] = useAtom(layoutSettingSelectedFloorStatus);

  useEffect(() => {
    setIsDnDEnabled(radioButton !== DEFAULT_ORDER);

    // 配列の各要素を平坦化
    const flattenNestedArrays = (arrays: unknown[]): unknown[] =>
      arrays.reduce<unknown[]>((acc, item) => {
        if (Array.isArray(item)) return acc.concat(flattenNestedArrays(item));
        return acc.concat(item);
      }, []);
    const flattenedArray = flattenNestedArrays(sections);
    setFieldValue('Setting', flattenedArray);
  }, [radioButton, setFieldValue, data, sections]);

  const handleSelectWard = useCallback(
    (item: MenuItemType<string>) => {
      setSelectedWard({
        label: item.label as string,
        value: item.value,
      });
    },
    [setSelectedWard]
  );

  const handleSelectFloor = useCallback(
    (item: MenuItemType<string>) => {
      setSelectedFloor({
        label: item.label + '',
        value: item.value,
        floorNumber: (item as FloorOption).floorNumber,
        isGroundFloor: (item as FloorOption).isGroundFloor,
      });
    },
    [setSelectedFloor]
  );

  return (
    <>
      <Header handleClickCancel={handleClickCancel} />
      <Box sx={rootStyle}>
        {wardOptions !== undefined && tab === 'room' && (
          <Box sx={menuContainerStyle}>
            <PopperMenuButtonComp
              menuItemList={wardOptions}
              handleSelectWard={handleSelectWard}
              label={selectedWard.label}
            />
            {/* 階数がある場合は追加で表示する */}
            {floorOptions !== undefined && selectedFloor !== null && (
              <>
                <Typography sx={{marginLeft: '8px'}}>・</Typography>
                <PopperMenuButtonComp
                  menuItemList={floorOptions}
                  handleSelectWard={handleSelectFloor}
                  label={selectedFloor.label}
                />
              </>
            )}
            <Typography sx={textItemStyle}>
              {radioButton === DEFAULT_ORDER ? 'の小エリアボタン昇順' : 'の小エリアボタンを並び替える'}
            </Typography>
          </Box>
        )}

        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Box sx={containerStyle}>
            {sections.map((section, sectionIndex) => (
              <RentalDnDSection
                key={`section_${sectionIndex}`}
                section={section}
                sectionIndex={sectionIndex}
                isDnDEnabled={isDnDEnabled}
              />
            ))}
          </Box>
        </DragDropContext>
      </Box>
    </>
  );
};

const rootStyle: SxProps = {
  padding: '24px 32px 0',
  width: '100%',
};

const containerStyle: SxProps = {
  width: '100%',
  display: 'flex',
  alignItems: 'start',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
};

const menuContainerStyle: SxProps = {
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
};

const textItemStyle: SxProps = {
  marginLeft: '8px',
};

const buildingMenuStyle: SxProps = {
  width: '180px',
  height: '32px',
  backgroundColor: '#FFFFFF',
  border: '2px solid #DFE1E5',
  textOverflow: 'ellipsis',
  color: '#172B4D',
  '&:hover': {
    backgroundColor: 'inherit',
  },
};

type PopperMenuButtonCompType = {
  menuItemList: MenuItemType<string>[];
  handleSelectWard: (item: MenuItemType<string>) => void;
  label: string;
};
/**
 * PopperMenuButtonをラップしたコンポーネント
 */
function PopperMenuButtonComp({menuItemList, handleSelectWard, label}: PopperMenuButtonCompType) {
  // キーボード操作のためにtooltipOptionを設定
  menuItemList.forEach((v) => {
    if (!v.tooltipOption) {
      v.tooltipOption = {enableTooltip: false, title: v.label + ''};
    }
  });

  return (
    <Box>
      <PopperMenuButtonV5<string>
        placement="bottom-start"
        buttonProps={{
          variant: 'contained',
          sx: buildingMenuStyle,
          disableElevation: true,
        }}
        itemProps={{sx: {minWidth: '400px', fontSize: '14px'}}}
        menuItemList={menuItemList}
        onMenuClick={handleSelectWard}>
        {label}
      </PopperMenuButtonV5>
    </Box>
  );
}
