import React, {useCallback, useState} from 'react';
import {Typography} from '@mui/material';
import {openSnackBar} from '@molecules/SnackBar';
import {BarcodeReader} from './BarcodeReader';
import {Camera} from './Camera';
import {ErrorMessage} from './ErrorMessage';
import {Menu} from './Menu';
import {useHospitalProductMenuStore, useCodeReaderStore} from '../hooks';
import {HospitalProductReadMessages} from '../constants';
import {CodeReaderType} from '../types';

export const DialogContent = () => {
  const [managementId, setManagementId] = useState<string>('');
  const {hospitalProductState, resetHospitalProductState, listHospitalProductsByManagementId} =
    useHospitalProductMenuStore();
  const {codeReaderState, updateCodeReaderType} = useCodeReaderStore();

  const handleReScan = () => {
    setManagementId('');
    resetHospitalProductState();
  };

  // NOTE: CameraコンポーネントのonReadをuseCallbackで利用しているため、こちらのhandleReadにuseCallbackを利用している
  const handleRead = useCallback(async (managementId: string) => {
    try {
      await listHospitalProductsByManagementId(managementId);
    } catch (error) {
      openSnackBar(HospitalProductReadMessages.failed, 'left', 'bottom', 'error');
      console.error('Failed to fetch hospital products', error);
      return;
    }

    setManagementId(managementId);
  }, []);

  const handleChangeCodeReaderType = (codeReaderType: CodeReaderType) => {
    updateCodeReaderType(codeReaderType);
  };

  const renderCodeReader = () => {
    switch (codeReaderState.codeReaderType) {
      case 'camera':
        return <Camera onRead={handleRead} onChangeCodeReaderType={handleChangeCodeReaderType} />;
      case 'barcodeReader':
        return <BarcodeReader onRead={handleRead} onChangeCodeReaderType={handleChangeCodeReaderType} />;
      default:
        return <BarcodeReader onRead={handleRead} onChangeCodeReaderType={handleChangeCodeReaderType} />;
    }
  };

  // コードを読み取っていない場合はコードリーダーを表示
  if (!managementId) {
    return renderCodeReader();
  }
  // コードに紐づく機器が存在しない場合はエラーメッセージを表示
  if (hospitalProductState.hospitalProducts.length === 0) {
    return (
      <ErrorMessage
        title="読み取りエラー"
        content={
          <>
            <Typography>こちらのバーコードまたはQRコードには、機器情報が登録されてません。</Typography>
            <Typography>
              ※削除済みあるいは廃棄済みの機器に紐づく、バーコードまたはQRコードは読み取ることができません。
            </Typography>
          </>
        }
        buttonText="読み取り画面に戻る"
        onBack={handleReScan}
      />
    );
  }
  // コードに紐づく機器が複数存在する場合はエラーメッセージを表示
  if (hospitalProductState.hospitalProducts.length > 1) {
    return (
      <ErrorMessage
        title="読み取りエラー"
        content={
          <>
            <Typography>こちらのバーコードまたはQRコードには、複数の機器が紐づいています。</Typography>
            <Typography>単一の機器が紐づいているバーコードまたはQRコードを読み取ってください。</Typography>
          </>
        }
        buttonText="読み取り画面に戻る"
        onBack={handleReScan}
      />
    );
  }

  return <Menu hospitalProduct={hospitalProductState.hospitalProducts[0]} onBack={handleReScan} />;
};
