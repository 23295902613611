import {axios} from '@front-libs/core';
import {StandardListApiResponse} from '../common/type';
import {HospitalProductInspectionPlan} from './type';

export type FetchHospitalProductInspectionPlansParam = Partial<{
  page: number;
  perPage: number;
  order: string;
  name: string; // 機種名・型式・管理番号
  wholeProductHashId: string; // 機種ID
  status: string | null; // 点検状況
  categoryHashIds: string; // 大分類・小分類
  inspectionPeriodFrom: number; // 点検間隔
  inspectionPeriodTo: number; // 点検間隔
  dateOfPurchaseFrom: number; // 購入日
  dateOfPurchaseTo: number; // 購入日
  hospitalWardHashId: string; // 機器管理場所
  hospitalRoomHashId: string; // 機器管理場所
}>;

export const getHospitalProductInspectionPlans = async (
  hospitalHashId: string,
  params: FetchHospitalProductInspectionPlansParam
) => {
  const baseURL = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/inspection-plans`;
  const {data} = await axios.get<StandardListApiResponse<HospitalProductInspectionPlan>>(baseURL, {params});
  return data;
};

export type UpdateHospitalProductInspectionPlansData = {
  inspectionPlanHashId: string;
  inspectionSettingHashId: string;
  hospitalProductHashId: string;
  periodicInspectionStartMonth: string;
  periodicInspectionDay: number;
};

export const bulkUpdateHospitalProductInspectionPlans = async (
  hospitalHashId: string,
  inspectionPlans: UpdateHospitalProductInspectionPlansData[]
) => {
  const baseURL = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/inspection-plans/bulk_upsert`;
  return await axios.post(baseURL, {inspectionPlans});
};

export const bulkDeleteHospitalProductInspectionPlans = async (
  hospitalHashId: string,
  inspectionPlanHashIds: string[]
) => {
  const baseURL = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/inspection-plans/bulk_delete`;
  return await axios.post(baseURL, {inspectionPlanHashIds});
};
