import {
  Box,
  CSSObject,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TableCell,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React from 'react';
import {PopperBtnInspectionTable} from '@organisms/PopperBtnInspectionTable';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import {InspectionType} from '@modules/inspections/enum';
import {InspectionIndex} from '@modules/inspections/types';
import {CreateWholeProductPlan} from '../type';

const TableCellStyle: CSSObject = {
  borderBottom: 'none',
  padding: '0px 16px',
};

type SettingNameCellProps = {
  settingName: string;
  onInspectionSettingNameChange: (inspectionSettingName: string) => void;
};

/**
 * 点検名のセル
 */
export const SettingNameCell = ({settingName, onInspectionSettingNameChange}: SettingNameCellProps) => {
  return (
    <TableCell sx={TableCellStyle}>
      <TextField
        fullWidth
        value={settingName}
        onChange={(e) => onInspectionSettingNameChange(e.target.value)}
        variant="outlined"
        size="small"
        placeholder={'点検名を入力'}
        sx={{minWidth: '312px'}}
      />
    </TableCell>
  );
};

/**
 * 点検タイプのセル
 */
type InspectionTypeCellProps = {
  row: CreateWholeProductPlan;
  onChangeInspectionType: (inspectionType: InspectionType) => void;
  displayInspectionTypeOptions: {value: InspectionType; label: string}[];
};

export const InspectionTypeCell = ({
  row,
  onChangeInspectionType,
  displayInspectionTypeOptions,
}: InspectionTypeCellProps) => {
  return (
    <TableCell sx={TableCellStyle}>
      <FormControl fullWidth size="small" sx={{minWidth: '200px'}}>
        <InputLabel id="demo-simple-select-label" />
        <Select
          labelId="demo-simple-select-label"
          value={row.inspectionType || ''}
          displayEmpty
          sx={row.inspectionType ? null : {color: '#a6a6a6'}}
          onChange={(e) => {
            onChangeInspectionType(e.target.value as InspectionType);
          }}>
          <MenuItem value="" disabled sx={{display: 'none'}}>
            点検タイプを選択
          </MenuItem>
          {displayInspectionTypeOptions.map((option: {value: string; label: string}) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </TableCell>
  );
};

type InspectionPeriodCellProps = {
  isPeriodic: boolean;
  row: CreateWholeProductPlan;
  onChangeInspectionPeriod: (inspectionPeriod: number) => void;
};

/**
 * 点検間隔のセル
 */
export const InspectionPeriodTypeCell = ({row, isPeriodic, onChangeInspectionPeriod}: InspectionPeriodCellProps) => {
  return (
    <TableCell sx={TableCellStyle}>
      {isPeriodic ? (
        <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <TextField
            type="number"
            inputProps={{min: 1, max: 120}}
            value={row.inspectionPeriod || ''}
            onChange={(e) => onChangeInspectionPeriod(Number(e.target.value))}
            fullWidth
            margin="normal"
            size="small"
            placeholder={'数値を入力'}
            sx={{margin: 0}}
          />
          <Typography style={{width: '100px', paddingLeft: '8px'}}>ヶ月</Typography>
        </Box>
      ) : (
        <TextField
          type="number"
          fullWidth
          margin="normal"
          size="small"
          disabled
          placeholder="対象外"
          style={{backgroundColor: '#F3F4F6', margin: 0}}
        />
      )}
    </TableCell>
  );
};

type InspectionCellProps = {
  inspectionType: InspectionType | null;
  inspectionName: string;
  onChangeInspection: (inspectionHashId: string, inspectionName: string) => void;
};

/**
 * 点検表のセル
 */
export const InspectionCell = ({inspectionType, inspectionName, onChangeInspection}: InspectionCellProps) => {
  return (
    <TableCell sx={TableCellStyle}>
      <PopperBtnInspectionTable
        inspectionName={inspectionName}
        onChangeInspection={(inspection: InspectionIndex) => {
          onChangeInspection(inspection.hashId, inspection.name);
        }}
        type={inspectionType}
      />
    </TableCell>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-types
type ActionButtonCellProps = {
  onDelete: () => void;
};

/**
 * アクションボタンのセル
 */
export const ActionButtonCell = ({onDelete}: ActionButtonCellProps) => {
  return (
    <TableCell sx={TableCellStyle}>
      <Tooltip title="削除">
        <IconButton onClick={() => onDelete()} size="small">
          <DeleteOutlineIcon />
          <Typography>削除</Typography>
        </IconButton>
      </Tooltip>
    </TableCell>
  );
};
