import {z} from 'zod';

/**
 * 返却待ち受付APIのリクエストのバリデーションスキーマ
 */
export type returnsReservationRequest = {
  narrowCategoryHashId: string;
  roomHashId: string;
  reservationCount: number;
  contactInformation: string;
  transitionMessage: string;
};

export type returnsReservationRequestParam = {
  /** 1ページあたりのアイテム数 */
  perPage: number;
  /** ページ番号 */
  page: number;
  /** 開始日時 (ISO8601形式) */
  receptionAtFrom?: string;
  /** 終了日時 (ISO8601形式) */
  receptionAtTo?: string;
  /** 絞り込み用カテゴリIDリスト */
  narrowCategoryHashIds?: string[];
  /** 絞り込み用部屋IDリスト */
  roomHashIds?: string[];
};

/** 完了 連絡済み 準備中 確認前 キャンセル*/
export type WaitingReceptionsStatusType = 'completed' | 'contacted' | 'preparation' | 'before_confirmation' | 'cancel';

export type ReturnWaitingReceptionsResponse = {
  hashId: string;
  /** ステータス */
  status: WaitingReceptionsStatusType;
  /** 受付日時 (ISO8601形式) */
  receptionAt: string;
  /** 部屋のハッシュID */
  roomHashId: string;
  /** 部屋の名前 */
  roomName: string;
  /** 小エリアハッシュID */
  narrowCategoryHashId: string;
  /** 小エリア名 */
  narrowCategoryName: string;
  /** 予約数 */
  reservationCount: number;
  /** 連絡先 */
  contactInformation: string;
  /** 伝達事項 */
  transitionMessage: string;
  /** 貸出先の連絡者 */
  rentalContactPerson: string;
  /** コメント */
  comment: string;
};
export const returnsReservationRequestSchema = z.object({
  narrowCategoryHashId: z.string().min(1),
  roomHashId: z.string().min(1),
  reservationCount: z
    .number()
    .min(1) // 1以上の数値
    .max(20),
  contactInformation: z.string().optional(),
  transitionMessage: z.string().optional(),
});

export type ReturnsReservationRequest = z.infer<typeof returnsReservationRequestSchema>;
