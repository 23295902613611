export const ApplicationIndicator = {
  gtinIndicator: '(01)',
  expirationDateIndicator: '(17)',
  lotNumberIndicator: '(10)',
  serialNumberIndicator: '(21)',
} as const;

export type ApplicationIndicatorValueType = (typeof ApplicationIndicator)[keyof typeof ApplicationIndicator];

export const extractCodeFromGS1Barcode = (
  gs1Barcode: string,
  targetAI: ApplicationIndicatorValueType
): string | null => {
  // 対象のAIがない場合は返却
  const firstIndicator = gs1Barcode.indexOf(targetAI);
  if (firstIndicator === -1) {
    return null;
  }

  // 次のAIがある場合は ( までを取得。そうじゃない場合は末尾まで取得する
  let nextIndicator: number | undefined = gs1Barcode.indexOf('(', firstIndicator + targetAI.length);
  nextIndicator = nextIndicator !== -1 ? nextIndicator : undefined;

  return gs1Barcode.slice(firstIndicator + targetAI.length, nextIndicator);
};

export const getMedian = (arr: number[]): number => {
  const sortedArr = [...arr].sort((a, b) => a - b);
  const half = Math.floor(sortedArr.length / 2);
  if (sortedArr.length % 2 === 1) {
    return sortedArr[half];
  }
  return (sortedArr[half - 1] + sortedArr[half]) / 2;
};

export const getMedianOfCodeErrors = (decodedCodes: {error?: number}[]): number => {
  const errors = decodedCodes.filter((x) => x.error !== undefined).map((x) => x.error as number);
  const medianOfErrors = getMedian(errors);
  return medianOfErrors;
};
