import React from 'react';
import {Button, styled} from '@mui/material';

type Props = {
  onClick: () => void;
  children: React.ReactNode;
};

export const DialogButton = ({onClick, children}: Props) => {
  return (
    <StyledButton onClick={onClick} data-testid={'rental-error-dialog-button'}>
      {children}
    </StyledButton>
  );
};

const StyledButton = styled(Button)(({theme}) => ({
  cursor: 'pointer',
  borderRadius: '20px',
  boxShadow: `0px 8px 0px 0px ${theme.palette.rent.dark}`,
  backgroundColor: theme.palette.rent.main,
  height: 84,
  width: 256,
  margin: '0 auto',
  fontSize: 21,
  fontWeight: 'bold',
  color: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.rent.dark,
  },
}));
