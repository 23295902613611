import {Button, CSSObject, Divider, Paper, Typography, styled} from '@mui/material';
import {withSuspense} from '@front-libs/core';
import React from 'react';
import {ChevronLeft} from '@mui/icons-material';
import {useWholeProductEditPlanList} from './hooks';
import {EditWholeProductList} from './EditWholeProductList';

const BasePaper = styled(Paper)({
  height: '100vh',
  position: 'absolute', // 位置を絶対位置指定
  top: 0, // 上端
  left: 0, // 左端
  width: '100%', // 横幅
});

const Header = styled('div')({
  margin: '16px 32px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
});

const ProductInfo = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  paddingTop: '16px',
  '& > div': {
    marginRight: '24px',
  },
});

const BodyContainer = styled('div')({
  margin: '16px 24px',
  display: 'flex',
  flexDirection: 'column',
});

const NavigationButton = styled(Button)({
  fontWeight: 400,
  fontSize: '14px',
  justifyContent: 'start',
  padding: '0px',
});

const TableColorStyle: CSSObject = {
  color: '#172B4D',
};

const _EditWholeProductPlanList = () => {
  const {product, handleBack, editRows, dispatch} = useWholeProductEditPlanList();

  return (
    <BasePaper>
      <Header>
        <NavigationButton sx={{padding: '0px'}} onClick={handleBack}>
          <ChevronLeft sx={TableColorStyle} />
          <Typography sx={TableColorStyle}>機種計画一覧に戻る</Typography>
        </NavigationButton>
        <ProductInfo>
          <div>機種名：{product?.displayName}</div>
          <div>型式：{product?.name}</div>
          <div>メーカー：{product?.maker.name}</div>
        </ProductInfo>
      </Header>
      <Divider />
      <BodyContainer>
        <EditWholeProductList inspectionSettings={editRows} dispatch={dispatch} />
      </BodyContainer>
    </BasePaper>
  );
};

export const EditWholeProductPlanList = withSuspense(_EditWholeProductPlanList, null);
