import {useMemo, useReducer} from 'react';
import {useQuery} from 'react-query';
import {getTrainingReportsKey, getTrainingScheduleKey} from '@constants/api';
import {
  FetchTrainingReportsParam,
  getTrainingReports,
  getTrainingSchedule,
  FetchTrainingSchedulesParam,
  FetchTrainingSchedulesResult,
  getTrainingSchedules,
} from '@modules/training_schedules/api';
import {trainingScheduleReducer, trainingScheduleInitialState} from '@modules/training_schedules/reducers/schedules';
import {TrainingScheduleState, TrainingScheduleActionType} from '@modules/training_schedules/types';

export const useFetchTrainingScheduleQuery = (hospitalHashId: string, trainingScheduleHashId: string) => {
  return useQuery([getTrainingScheduleKey, hospitalHashId, trainingScheduleHashId], () =>
    getTrainingSchedule(hospitalHashId, trainingScheduleHashId)
  );
};

export const useFetchTrainingReportsQuery = (hospitalHashId: string, params: FetchTrainingReportsParam) => {
  const query = useQuery([getTrainingReportsKey, hospitalHashId, params], () =>
    getTrainingReports(hospitalHashId, params)
  );
  return useMemo(
    () => ({
      ...query,
      data: query.data?.data ?? [],
      totalCount: query.data?.totalCount ?? 0,
    }),
    [query]
  );
};

type TrainingScheduleStoreType = {
  state: TrainingScheduleState;
  dispatchListTrainingSchedules: (
    hospitalHashId: string,
    params: FetchTrainingSchedulesParam
  ) => Promise<FetchTrainingSchedulesResult>;
};

export const useTrainingScheduleStore = (): TrainingScheduleStoreType => {
  const [state, dispatch] = useReducer(trainingScheduleReducer, trainingScheduleInitialState);

  const dispatchListTrainingSchedules = async (hospitalHashId: string, params: FetchTrainingSchedulesParam) => {
    const res = await getTrainingSchedules(hospitalHashId, params);
    dispatch({
      type: TrainingScheduleActionType.TRAINING_SCHEDULE_SET_LIST,
      payload: {schedules: res.data},
    });
    return res;
  };

  return {
    state,
    dispatchListTrainingSchedules,
  };
};
