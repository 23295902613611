export const HospitalUserPermission = {
  TrainingManagement: 'hospital:use:training_management',
  Inspection: 'hospital:use:inspection',
  ChannelManagement: 'hospital:use:channel_management',
  Repair: 'hospital:use:repair',
  Rental: 'hospital:use:rental',
  Dashboard: 'hospital:use:dashboard',
  GrantManagementDashboard: 'hospital:grant:management_dashboard',
  ReadManagementDashboard: 'hospital:read:management_dashboard',
  ReadCostManagementDashboard: 'hospital:read:cost_management_dashboard',
  ReadRentalRatioManagementDashboard: 'hospital:read:rental_ratio_management_dashboard',
  ReadRepairRatioManagementDashboard: 'hospital:read:repair_ratio_management_dashboard',
  GeneralFileList: 'hospital:use:general_file_list',
};
