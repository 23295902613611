import {Button, Grid, InputAdornment, makeStyles, TextField} from '@material-ui/core';
import React, {useCallback} from 'react';
import {MakerInspectionResultIndex} from '@modules/maker_inspection_results/types';
import {ChevronLeft} from '@material-ui/icons';
import {useNavigate} from 'react-router-dom';
import {UserFormatter} from '@modules/hospital_users/helpers';
import {convertDateToJapaneseDateTime, isNullish} from '@front-libs/helpers';

type Props = {
  makerInspectionResult: MakerInspectionResultIndex;
  inspectionFee: number | null;
  comment: string;
  isReadOnly: boolean;

  onChangeInspectionFee: (inspectionFee: number) => void;
  onChangeComment: (comment: string) => void;
  onSubmit: () => void;
};

export const SideNav: React.FC<Props> = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const handleClickInspectionLink = useCallback(() => {
    navigate('/inspection_v2/results');
  }, [navigate]);

  const handleClickProductLink = useCallback(() => {
    navigate(`/products/${props.makerInspectionResult.hospitalProduct.hashId}`);
  }, [navigate, props.makerInspectionResult.hospitalProduct.hashId]);

  return (
    <Grid container style={{padding: 16, background: '#FFF', height: '100%'}} direction="column">
      <Grid item style={{marginBottom: 16}}>
        <Button color="inherit" className={classes.actionMenu} onClick={handleClickInspectionLink}>
          <ChevronLeft />
          <span>点検一覧</span>
        </Button>
      </Grid>
      <Grid item>
        <div
          style={{
            fontWeight: 700,
            fontSize: 16,
            marginBottom: 24,
          }}>
          {props.makerInspectionResult.hospitalProduct.displayName || props.makerInspectionResult.hospitalProduct.name}
        </div>
        <div
          style={{
            fontSize: 14,
            marginBottom: 8,
          }}>
          点検タイプ： <span style={{fontWeight: 700}}>メーカー保守点検</span>
        </div>
        <div
          style={{
            fontSize: 14,
            marginBottom: 8,
          }}>
          管理番号：{' '}
          <Button style={{fontWeight: 700, paddingLeft: 0}} color="primary" onClick={handleClickProductLink}>
            {props.makerInspectionResult.hospitalProduct.managementId}
          </Button>
        </div>
        <div
          style={{
            fontSize: 14,
            marginBottom: 8,
          }}>
          点検者：
          <span style={{fontWeight: 700}}>
            {!isNullish(props.makerInspectionResult.inspector)
              ? UserFormatter.getFullName(props.makerInspectionResult.inspector)
              : ''}
          </span>
        </div>
        <div
          style={{
            fontSize: 14,
            marginBottom: 24,
          }}>
          点検完了日時：
          <span style={{fontWeight: 700}}>
            {!isNullish(props.makerInspectionResult.completedAt)
              ? convertDateToJapaneseDateTime(props.makerInspectionResult.completedAt)
              : ''}
          </span>
        </div>
      </Grid>
      <Grid item className={classes.fieldContainer}>
        <TextField
          style={{
            fontSize: 14,
          }}
          InputLabelProps={{
            classes: {
              root: classes.inputText,
              shrink: classes.shrink,
            },
          }}
          InputProps={{
            classes: {
              root: classes.inputRoot,
              input: classes.inputText,
              underline: classes.underline,
            },
            inputProps: {min: 0},
            endAdornment: <InputAdornment position="end">円</InputAdornment>,
          }}
          value={props.inspectionFee}
          type={'number'}
          label={'保守契約料'}
          fullWidth
          onChange={(e) => props.onChangeInspectionFee(Number(e.target.value))}
        />
      </Grid>
      <Grid item className={classes.fieldContainer}>
        <TextField
          style={{
            fontSize: 14,
          }}
          InputLabelProps={{
            classes: {
              root: classes.inputText,
              shrink: classes.shrink,
            },
          }}
          InputProps={{
            disabled: props.isReadOnly,
            classes: {
              root: classes.inputRoot,
              input: classes.inputText,
              underline: classes.underline,
            },
          }}
          value={props.comment}
          type={'text'}
          label={'コメント'}
          fullWidth
          multiline
          onChange={(e) => props.onChangeComment(e.target.value)}
        />
      </Grid>
      <Grid item>
        {!props.isReadOnly && (
          <Button color="primary" variant="contained" size="small" onClick={props.onSubmit}>
            保存
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  fieldContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 14,
  },
  inputRoot: {
    '&:hover': {
      borderBottom: `1px solid ${theme.palette.primary.dark}`,
    },
  },
  inputText: {
    fontSize: theme.typography.fontSize,
  },
  shrink: {
    transform: 'translate(0, 1.5px) scale(0.9)',
  },
  underline: {
    '&&&:before': {
      borderBottom: '0px solid',
    },
    '&&:after': {
      borderBottom: `1px solid ${theme.palette.primary.dark}`,
    },
  },
  actionMenu: {
    color: theme.palette.secondary.dark,
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: 'inherit',
    },
    paddingLeft: 0,
  },
}));
