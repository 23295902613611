import React from 'react';
import {makeStyles, Theme} from '@material-ui/core';
import Logo from '../../../assets/logo_small.png';
import {useNavigate} from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 56,
    objectFit: 'contain',
    cursor: 'pointer',
    paddingRight: '8px',
  },
}));

export const HITOTSULogoSmall = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleClickLogo = () => {
    navigate('/');
  };

  return <img className={classes.root} src={Logo} alt="HITOTSU logo" onClick={handleClickLogo} />;
};
