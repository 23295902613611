import {useCallback} from 'react';
import {useAuthz} from './useAuthz';

export const useUserPermissions = () => {
  const {permissions} = useAuthz();

  const isPermitted = useCallback(
    (targetPermissions: string[]) => {
      if (import.meta.env.VITE_APP_ENV !== 'production') return true;
      if (!permissions) return false;

      return targetPermissions.every((permission) => permissions?.includes(permission));
    },
    [permissions]
  );

  const isPermittedRepair = useCallback((_hospitalHashId: string) => true, []);
  const isPermittedRental = useCallback((_hospitalHashId: string) => true, []);
  return {isPermitted, isPermittedRepair, isPermittedRental};
};
