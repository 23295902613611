import {useMyInfo} from './useMyInfo';
import {useFetchAllHospitalUsers} from '../api';

export const useSelectableHospitalUsers = (selectableUserHashID?: string) => {
  const {myInfo} = useMyInfo();
  const allHospitalUsersQuery = useFetchAllHospitalUsers(myInfo?.hospitalHashId);
  const allHospitalUsers = allHospitalUsersQuery?.data ?? [];
  const currentSelectableUsers = allHospitalUsers.filter((user) => !user.deletedAt);

  if (selectableUserHashID) {
    const isSelectable = currentSelectableUsers.some((user) => user.hashId === selectableUserHashID);
    const selectableUser = allHospitalUsers.find((user) => user.hashId === selectableUserHashID);

    if (!isSelectable && selectableUser) {
      currentSelectableUsers.push(selectableUser);
    }
  }

  return {
    isLoading: allHospitalUsersQuery.isLoading,
    allHospitalUsers,
    selectableHospitalUsers: currentSelectableUsers,
  };
};
