import React from 'react';
import {Footer} from '../FaultReceptionHistory/Footer';
import {useParams} from 'react-router-dom';
import {Paper, styled} from '@material-ui/core';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useFaultReceptionDetailQuery} from '@modules/fault_receptions/hooks';
import {FaultReceptionDetailSection} from '@Apps/FaultReceptionDetail/Content/FaultReceptionDetailSection';
import {theme} from '@components/atoms/theme';
import {InnerLoading} from '@molecules/Loading';
import {FaultReceptionHeader} from '@components/molecules/FaultReceptionHeader';

const DetailContainer = styled(Paper)({
  backgroundColor: 'white',
  width: 'calc(100% - 64px - 32px)', // 左右のPadding(32x2) と Margin (16x2)を引いた値
  height: 'calc(100vh - 64px - 128px - 68px)', // FooterとMargin(32x2)+Padding(32x2) とヘッダーの高さを引いた値
  margin: '16px 32px',
  padding: '16px',
  borderRadius: '10px',
});

/**
 * 不具合受付履歴詳細画面を表示コンポーネント
 * URLのハッシュIDから履歴を取得する
 *
 */
export const FaultReceptionHistoryDetail = () => {
  const {hashId} = useParams();
  const {myInfo} = useMyInfo();
  const {data, isLoading} = useFaultReceptionDetailQuery(myInfo.hospitalHashId, hashId || '');

  return (
    <>
      <FaultReceptionHeader title="不具合受付の履歴" />
      <DetailContainer elevation={0}>
        {isLoading ? (
          <InnerLoading />
        ) : (
          data && <FaultReceptionDetailSection param={data} titleColor={theme.palette.secondary.dark} />
        )}
      </DetailContainer>

      <Footer />
    </>
  );
};
