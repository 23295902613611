import React from 'react';
import {styled, Theme, Box, Grid, Typography, FormHelperText} from '@mui/material';
import {StyledSettingsTempPageSubTitle1} from '@templates/ContentLayout/InnerSidebarContentLayoutV5';

const TitleContainer = styled(Box)({
  marginTop: 24,
});

const Description = styled(FormHelperText)(({theme}: {theme: Theme}) => ({
  fontSize: 14,
  color: theme.palette.grey[600],
  margin: '8px 0px 8px',
}));

export const ProductRequiredPropertiesSettingFormSubTitle = () => {
  return (
    <Grid container>
      <Grid item>
        <StyledSettingsTempPageSubTitle1 variant={'h6'}>セットアップ</StyledSettingsTempPageSubTitle1>
        <TitleContainer>
          <Typography>機器登録時の必須・任意項目設定</Typography>
          <Description>機器登録の項目ごとに必須・任意を設定することができます。</Description>
        </TitleContainer>
      </Grid>
    </Grid>
  );
};
