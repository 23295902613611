import {InspectionResultEvaluation, InspectionResultStatus} from '@modules/inspection_results/enum';
import {InspectionType} from '@modules/inspections/enum';
import {ViewInspectionTypes} from './consts';

export type ViewInspectionType = (typeof ViewInspectionTypes)[number];

export const ViewInspectionResultStatuses = ['unplanned', 'overdue', 'uncompleted', 'completed', 'skipped'] as const;

export type ViewInspectionResultStatus = (typeof ViewInspectionResultStatuses)[number];

export type InspectionResultListElement = {
  hashId: string;
  inspectionHashId: string;
  inspectorHashId: string | null;
  hospitalHashId: string;
  hospitalProductHashId: string;
  type?: InspectionType;
  inspectionType?: string;
  inspectionSettingName: string;
  inspectionName: string;
  inspectionPeriod: string;
  status: InspectionResultStatus;
  result: InspectionResultEvaluation;
  completedAt: string;
  suspendedAt: string;
  scheduledTime: Date | null;
  inspector: string;
  managementId: string;
  serialNumber: string;
  rootCategory: string;
  narrowCategory: string;
  makerName: string;
  displayName: string;
  name: string;
  purchasedDate: string;
  isOutsideOfHospital: string;
  depreciationAmount: number;
  isAdhocDate: string;
  skipReason: string;
  skippedTime: string;
  hospitalRoomName: string;
  rentHospitalRoomName: string;
  indexOfTheDay: number | null;
};
