import React from 'react';
import {List} from '@mui/material';
import {DragDropContext, Droppable, DropResult} from 'react-beautiful-dnd';
import {HospitalProductNoteSettingsOptionsValue} from '@modules/hospital_products/types';
import {SelectTypeOptionEditListItem} from './SelectTypeOptionEditListItem';

type Props = {
  options: HospitalProductNoteSettingsOptionsValue[];
  onChange: (index: number, newOption: HospitalProductNoteSettingsOptionsValue) => void;
  onRemove: (index: number) => void;
  onDragEnd: (srcIdx: number, destIdx: number) => void;
};

export const SelectTypeOptionEditList = ({options, onChange, onRemove, onDragEnd}: Props) => {
  const removeFieldState = options.length > 1 ? 'visible' : 'disabled';

  const handleChange = (index: number, newOption: HospitalProductNoteSettingsOptionsValue) => {
    onChange(index, newOption);
  };

  const handleRemove = (index: number) => {
    onRemove(index);
  };

  const handleDragEnd = ({source, destination}: DropResult) => {
    if (!destination) return;

    onDragEnd(source.index, destination.index);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="select-type-option-edit-list">
        {(provided) => (
          <List
            ref={provided.innerRef}
            {...provided.droppableProps}
            sx={{'& > li:nth-of-type(n+2)': {marginTop: '16px'}}}>
            {options.map((option, i) => (
              <SelectTypeOptionEditListItem
                key={`select-type-option-${option.value}`}
                index={i}
                option={option}
                removeFieldState={removeFieldState}
                onChange={handleChange}
                onRemove={handleRemove}
              />
            ))}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  );
};
