import React, {useEffect, useState} from 'react';
import {
  ClickAwayListener,
  Button,
  ButtonProps,
  Paper,
  Popper,
  PopperPlacementType,
  MenuList,
  MenuItem,
  MenuItemProps,
  makeStyles,
  Tooltip,
  TooltipProps,
} from '@material-ui/core';
import Grow from '@material-ui/core/Grow';
import {ArrowDropDown} from '@material-ui/icons';
import clsx from 'clsx';

export type MenuItemType = {
  label: React.ReactNode;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  value: any;
  disableMenuItem?: boolean;
  selectedMenuItem?: boolean;
  tooltipOption?: Omit<TooltipProps, 'children'> & {enableTooltip: boolean};
};

export type Props = {
  menuItemList: MenuItemType[];
  hiddenArrow?: boolean;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  MenuItemComponent?: any;
  buttonProps?: ButtonProps;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  containerProps?: any;
  itemsDirection?: 'row' | 'column';
  itemProps?: MenuItemProps;
  placement?: PopperPlacementType;
  dataTestId?: string;
  onMenuClick?: (item: MenuItemType, e: React.MouseEvent<Document, MouseEvent>) => void;
  onOpen?: () => void;
  onClose?: () => void;
};

const useStyles = makeStyles((theme) => ({
  popper: {
    zIndex: 100000,
    maxHeight: 360,
    overflow: 'auto',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    border: `1px solid ${theme.palette.grey.A100}`,
  },
  menuListRow: {
    display: 'flex',
  },
  menuListColumn: {
    display: 'block',
  },
  menuItemHovered: {
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  menuItemDisabled: {
    color: 'lightGray',
    cursor: 'default',
  },
  menuItemSelected: {
    backgroundColor: '#EBF1FB',
  },
  tooltipTitle: {
    whiteSpace: 'pre-line',
  },
  hide: {
    display: 'none',
  },
}));

export const PopperMenuButton: React.FC<Props> = ({
  children,
  menuItemList,
  onMenuClick = () => {
    // empty function
  },
  buttonProps = {color: 'primary', variant: 'outlined'},
  MenuItemComponent = MenuItem,
  itemsDirection = 'column',
  itemProps,
  containerProps,
  dataTestId,
  onClose,
  onOpen,
  hiddenArrow = false,
  placement: muiPlacement = 'bottom',
}) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const toggleMenu = () => setOpen((_open) => !_open);
  useEffect(() => {
    if (open) onOpen && onOpen();
    else onClose && onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleClickButton = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
    toggleMenu();
  };

  const handleClose = (e: React.MouseEvent<Document, MouseEvent>): void => {
    e.stopPropagation();
    if (!open) return;
    setOpen(false);
  };

  const handleMenuClick = (item: MenuItemType) => (e: React.MouseEvent<Document, MouseEvent>) => {
    !item.disableMenuItem && onMenuClick(item, e);
    handleClose(e);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div {...containerProps} className={classes.container}>
        <Button {...buttonProps} onClick={handleClickButton} data-testid={`popper-menu-button-${dataTestId}`}>
          {children}
          {!hiddenArrow && <ArrowDropDown fontSize="small" />}
        </Button>
        <Popper open={open} transition placement={muiPlacement} anchorEl={anchorEl} className={classes.popper}>
          {({TransitionProps, placement}) => (
            <Grow {...TransitionProps} style={{transformOrigin: placement}}>
              <Paper id="menu-list-grow" elevation={2} className={classes.paper}>
                <MenuList className={itemsDirection === 'column' ? classes.menuListColumn : classes.menuListRow}>
                  {menuItemList.map((item, index) => {
                    return (
                      <Tooltip
                        key={`${item.value}${index}`}
                        title={<span className={classes.tooltipTitle}>{item.tooltipOption?.title ?? ''}</span>}
                        arrow={item.tooltipOption?.arrow}
                        disableHoverListener={!item.tooltipOption?.enableTooltip}
                        classes={item.tooltipOption?.enableTooltip ? {} : {popper: classes.hide}}
                        placement={'top'}>
                        <MenuItemComponent
                          data-testid={`popper-menu-button-${item.value}`}
                          key={index}
                          {...itemProps}
                          item={item}
                          index={index}
                          className={clsx(
                            classes.menuItemHovered,
                            {[classes.menuItemDisabled]: item.disableMenuItem},
                            {[classes.menuItemSelected]: item.selectedMenuItem}
                          )}
                          onClick={handleMenuClick(item)}>
                          {item.label}
                        </MenuItemComponent>
                      </Tooltip>
                    );
                  })}
                </MenuList>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  );
};
