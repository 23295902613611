import React from 'react';

import {Button, SxProps} from '@mui/material';

type Props = {
  label: string;
  onChange: () => void;
};

export const ConfirmCard = ({label, onChange}: Props) => {
  return (
    <Button sx={ButtonStyle} onClick={onChange} data-testid={'menu-card'}>
      {label}
    </Button>
  );
};
const ButtonStyle: SxProps = {
  borderRadius: 'clamp(4px,2vw,24px)',
  boxShadow: '0px 8px 0px 0px rgba(209, 216, 245, 1)',
  '&:active': {
    color: '#2A96E8', //theme.palette.rent.light,
  },
  '&:hover': {
    backgroundColor: 'white',
  },
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: 'bold',
  fontSize: 'clamp(10px, 2vmin, 24px)', // 24 / 1440 * 100 = 1.66666
  color: '#0052CC', //theme.palette.rent.main,
  backgroundColor: 'white',
  lineHeight: '1.3em',
};
