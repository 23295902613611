import dayjs from 'dayjs';
import {useEffect, useState} from 'react';

/**
 * 現在の日付・時刻を指定フォーマット (YYYY年M月D日 (ddd) HH:mm) でリアルタイム更新するフック
 * @param updateIntervalMs 更新間隔(ミリ秒)。デフォルトは1秒
 * @returns フォーマットされた現在の日付・時刻
 */
export const useCurrentDateTime = (updateIntervalMs = 1000) => {
  const format = 'YYYY年M月D日 (ddd) HH:mm';
  const [now, setNow] = useState(dayjs().format(format));

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(dayjs().format(format));
    }, updateIntervalMs);

    return () => clearInterval(interval);
  }, [updateIntervalMs]);

  return now;
};
