import React, {useCallback, useEffect} from 'react';
import {Box, Button, Grid, Typography, SxProps, Theme} from '@mui/material';
import {Header} from '../Header';
import {CheckCircle} from '@mui/icons-material';
import {useNavigate} from 'react-router-dom';
import {useAtomValue} from 'jotai';
import {rentOrReturnAtom} from '../states';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

export const Complete = () => {
  const navigate = useNavigate();
  const timeSpanReturnHome = 5 * 1000;
  const rentOrReturn = useAtomValue(rentOrReturnAtom);
  const type: '貸出' | '返却' = rentOrReturn === 'rent' ? '貸出' : '返却';

  const handleClick = useCallback(() => {
    navigate('/shared');
  }, [navigate]);

  useEffect(() => {
    const timer = setTimeout(() => {
      handleClick();
    }, timeSpanReturnHome);
    return () => clearTimeout(timer);
  }, [handleClick, timeSpanReturnHome]);

  return (
    <>
      <Header />
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '100vw',
          gridTemplateRows: 'calc(100vh - 104px)',
        }}>
        <Box sx={containerStyle}>
          <Grid container alignItems="center" justifyContent="center">
            <Grid item>
              <CheckCircle sx={checkCircleStyle} />
            </Grid>
            <Grid item sx={textContainerStyle}>
              <Typography variant="h1" sx={titleStyle}>
                {type}登録が完了しました
              </Typography>
              <Typography sx={textStyle}>
                正常に{type}情報が登録されました。
                <br />
                しばらく時間が経つと、最初の画面に戻ります。
              </Typography>
            </Grid>
          </Grid>
          <Box sx={btnContainerStyle}>
            <Button sx={buttonStyle} onClick={handleClick} data-testid="return-top-button">
              <ChevronLeftIcon sx={{paddingRight: 2}} />
              はじめに戻る
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const containerStyle: SxProps<Theme> = {
  justifySelf: 'center',
  alignSelf: 'center',
};

const checkCircleStyle: SxProps<Theme> = {
  width: '256px',
  height: '256px',
  color: '#3C9E58',
};

const textContainerStyle: SxProps<Theme> = {
  paddingLeft: '32px',
};

const titleStyle: SxProps<Theme> = {
  fontSize: '42px',
  fontWeight: 'bold',
};

const textStyle: SxProps<Theme> = {
  marginTop: '24px',
  fontSize: '20px',
};

const btnContainerStyle: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'center',
};

const buttonStyle: SxProps<Theme> = (theme) => ({
  backgroundColor: theme.palette.common.white,
  width: '256px',
  height: '84px',
  borderRadius: '20px',
  boxShadow: '0px 4px 0px 0px rgba(209, 216, 245, 1)',
  fontSize: '24px',
  fontWeight: 'bold',
  color: theme.palette.rent.main,
});
