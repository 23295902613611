import {useQuery} from 'react-query';
import {getInspectionKey} from '@constants/api';
import {getInspection} from './api';
import {isNullish} from '@front-libs/helpers';

export const useInspection = (hospitalHashId?: string, inspectionHashId?: string, showDeleted?: boolean) => {
  const query = useQuery(
    [getInspectionKey, hospitalHashId, inspectionHashId],
    () => getInspection(hospitalHashId ?? '', inspectionHashId ?? '', showDeleted ?? false),
    {enabled: !isNullish(hospitalHashId) && !isNullish(inspectionHashId)}
  );
  return query;
};
