import {DialogContent, DialogTitle, IconButton} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import {styled} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import React, {useMemo} from 'react';
import {PDFDisplayForTablet} from '../../Utils/PDFDisplay/PDFDisplayForTablet';
import {fetchFileUrlByHashId} from '@modules/files/api';
import {useAsyncMemo} from '@front-libs/core';

export type FilePreviewDialogProps = {
  fileName: string;
  fileType: string;
  fileHashId: string;
  title: string;
} & DialogProps;

const StyledImg = styled('img')({
  objectFit: 'contain',
  width: '100%',
  height: '100%',
});
const StyledDiv = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: 'bold',
});

const StyledDialogTitle = styled(DialogTitle)({
  padding: '6px',
  display: 'flex',
  justifyContent: 'center',
  '& .MuiTypography-root ': {
    fontWeight: 'bold',
    textAlign: 'center',
    width: '82%',
    whiteSpace: 'nowrap',
    overflowX: 'auto',
  },
  '& .MuiIconButton-root ': {
    color: '#172B4D', // FIXME:後ほどThemeから呼び出す
    position: 'absolute',
    top: '-2px',
    left: '-2px',
  },
});

const FullScreenDialog = styled(Dialog)({
  height: 'auto',
  '& .MuiDialog-paper': {
    width: '100%',
    minHeight: '100%',
    margin: 0,
    borderRadius: 0,
  },
});
const StyledScrollDiv = styled('div')({
  overflow: 'scroll',
  height: '100%',
  width: '100%',
});

const StyledImageContainer = styled('div')({
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  height: '100%',
  padding: 0,
  overflow: 'hidden',
});
const StyledDialogContent = styled(DialogContent)({
  padding: 0,
  height: '100vh',
});

/**
 *
 * スマートフォン用 画像とPDFのファイルプレビュー
 *
 * @see front-app-pc/src/components/molecules/Dialogs/FilePreviewDialog.tsx
 * @example
 * ```tsx
 * const data: FileIndex;
 * await dialogHandler.open(FilePreviewDialog, {
 *         fileName: data.fileName,
 *         fileType: data.fileType,
 *         title: data.fileName,
 *         hashId: data.hashId,
 *       });
 * ```
 * @param props
 * @returns
 */
export const FilePreviewDialog = (props: FilePreviewDialogProps) => {
  const {
    open,
    actions: {reject},
    fileName,
    fileType,
    fileHashId,
    title,
    actions,
  } = props;

  const {redirectUrl, error} = useAsyncMemo(
    () => {
      return fetchFileUrlByHashId(fileHashId);
    },
    {redirectUrl: null, error: 'Loading...'},
    [fileHashId]
  );

  const preview = useMemo(() => {
    if (error) {
      return <StyledDiv>{error}</StyledDiv>;
    }

    if (!redirectUrl || (!fileType.match('application/pdf') && !fileType.match('image/*'))) {
      return <StyledDiv>この形式のファイルはプレビューできません。</StyledDiv>;
    }

    if (fileType.match('application/pdf')) {
      return (
        <StyledScrollDiv>
          <PDFDisplayForTablet src={redirectUrl} scale={1} />
        </StyledScrollDiv>
      );
    }

    if (fileType.match('image/*')) {
      return (
        <StyledImageContainer>
          <StyledImg src={redirectUrl} alt={fileName} />
        </StyledImageContainer>
      );
    }

    return <StyledDiv>この形式のファイルはプレビューできません。</StyledDiv>;
  }, [fileName, fileType, redirectUrl]);

  return (
    <FullScreenDialog open={open} onClose={reject}>
      <StyledDialogTitle>
        <IconButton aria-label="close" onClick={actions.resolve}>
          <CloseIcon />
        </IconButton>
        {title}
      </StyledDialogTitle>
      <StyledDialogContent>{preview}</StyledDialogContent>
    </FullScreenDialog>
  );
};
