import React, {memo} from 'react';
import {Typography, Grid, Paper, Avatar, styled, Fade} from '@material-ui/core';
import {useAuth0} from '@auth0/auth0-react';
import {MenuItemType, PopperMenuButton} from '@components/molecules/Buttons/PopperMenuButton';
import {profileMenuItems} from './constants';
import {useLogout} from '@modules/auth/hooks';
import {SupportPageButton} from '@components/molecules/Buttons/SupportPageButton';

const StyledPaper = styled(Paper)({
  padding: '16px',
  textAlign: 'center',
  color: 'text.secondary',
  borderRadius: 0,
  boxShadow: 'none',
});

const StyledGridContainer = styled(Grid)({
  justifyContent: 'space-between',
});

const StyledGridItem = styled(Grid)({
  alignItems: 'center',
});

const StyledTitle = styled(Typography)({
  fontWeight: 'bold',
  fontSize: '24px',
  color: '#172B4D',
});

const StyledAvatar = styled(Avatar)({
  width: '30px',
  height: '30px',
});

const ButtonCss = {
  borderRadius: 0,
  minWidth: '24px',
  width: '24px',
  height: '24px',
};

type MobileHeaderProps = {
  title: string;
  titleFade?: boolean;
};

export const Header = memo(({title, titleFade}: MobileHeaderProps) => {
  const {user} = useAuth0();
  const logout = useLogout();

  //FIXME ログアウト時のリダイレクト先を修正必要
  const handleActionMenuClick = (item: MenuItemType) => {
    switch (item.value) {
      case 'logout':
        logout();
        break;
    }
  };

  return (
    <Grid item xs={12}>
      <StyledPaper>
        <StyledGridContainer container>
          <Grid item>
            <Fade in={titleFade === undefined ? true : titleFade} timeout={titleFade === undefined ? 0 : 500}>
              <StyledTitle>{title}</StyledTitle>
            </Fade>
          </Grid>
          <Grid item>
            <StyledGridItem container>
              <Grid item style={{paddingRight: '16px'}}>
                <SupportPageButton />
              </Grid>
              <Grid item>
                <PopperMenuButton
                  hiddenArrow={true}
                  buttonProps={{color: 'inherit', style: ButtonCss}}
                  menuItemList={profileMenuItems}
                  onMenuClick={handleActionMenuClick}>
                  <StyledAvatar src={user?.picture} />
                </PopperMenuButton>
              </Grid>
            </StyledGridItem>
          </Grid>
        </StyledGridContainer>
      </StyledPaper>
    </Grid>
  );
});
