import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Grid, styled, Fade} from '@material-ui/core';
import {Header} from '@components/organisms/Header/sp';
import {Footer} from '@components/organisms/Footer/sp';
import {ToolBar} from './ToolBar';
import {InspectionTypeBar} from './InspectionTypeBar';
import {InspectionStatusBar} from './InspectionStatusBar';
import {FetchInspectionResultsParam, useFetchInfiniteInspectionResultsQuery} from '@modules/inspection_results/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {isNullish} from '@front-libs/helpers';
import {MenuItemType} from '@components/molecules/Buttons/PopperMenuButton';
import InfiniteScroll from 'react-infinite-scroller';
import {InspectionResultIndex} from '@modules/inspection_results/types';
import {InspectionResult} from './InspectionResultRow';
import {InspectionResultStatus} from '@modules/inspection_results/enum';
import {InspectionTypeOptionsValues} from '@modules/inspections/enum';
import {InspectionStatusSelectBox} from './InspectionStatusSelectBox';
import {InspectionTypeSelectBox} from './InspectionTypeSelectBox';
import {InnerLoading} from '@components/molecules/Loading';

const HEADER_HEIGHT = 56;
const FOOTER_HEIGHT = 58;
const FADE_IN_PIXEL = 72;

const StyledFixedDiv = styled('div')({
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 100,
  width: '100%',
});

const StyledFlexDiv = styled('div')({
  position: 'fixed',
  top: '4px',
  zIndex: 101,
  display: 'flex',
  padding: '4px',
  '& div': {
    padding: '2px 8px',
  },
});
const StyledRootDiv = styled('div')({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  backgroundColor: 'white',
});

const StyledStickyToolbar = styled('div')({
  position: 'sticky',
  top: '4px',
  zIndex: 50,
  height: '58px',
  backgroundColor: 'white',
});

const StyledLoading = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100vh',
});
const StyledMainContent = styled('div')({
  height: `calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px)`,
  backgroundColor: 'white',
  width: '100%',
  marginTop: `${HEADER_HEIGHT}px`,
  overflowX: 'hidden',
  overflowY: 'auto',
});

/**
 * 点検結果の一覧を表示
 */
export const InspectionResultList = () => {
  const [inspectionType, setInspectionType] = useState<InspectionTypeOptionsValues>('pre_use');
  const [inspectionSearchName, setInspectionSearchName] = useState<string | null>(null);
  const [inspectionOrder, setInspectionOrder] = useState('');
  const [inspectionStatus, setInspectionStatus] = useState<InspectionResultStatus>('unplanned');
  const scrollRef = useRef<HTMLDivElement>(null);
  const [isFadeIn, setFadeIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const {myInfo} = useMyInfo();

  useEffect(() => {
    // HIT-3061:点検履歴画面（完了タブ）のソートの初期値を完了日の降順にしてほしい（スマホ版対応）
    if (inspectionStatus === 'completed') {
      setInspectionOrder('-completed_at');
    } else {
      // デフォルト点検予定日の昇順（近い予定が上にくる）
      setInspectionOrder('scheduled_time');
    }
  }, [inspectionStatus, inspectionType]);

  useEffect(() => {
    if (!scrollRef.current) return;
    const divElement = scrollRef.current;

    const scrollTop = () => {
      setFadeIn(divElement.scrollTop > FADE_IN_PIXEL);
    };
    divElement.addEventListener('scroll', scrollTop);
    return () => {
      divElement.removeEventListener('scroll', scrollTop);
    };
  }, [scrollRef]);

  const params = useMemo(() => {
    const _p: FetchInspectionResultsParam = {
      page: 0,
      perPage: 20,
      order: inspectionOrder || 'management_id',
      types: inspectionType,
      statuses: inspectionStatus,
    };
    if (!isNullish(inspectionSearchName) && inspectionSearchName) {
      _p.name = inspectionSearchName;
    }
    return _p;
  }, [inspectionOrder, inspectionSearchName, inspectionStatus, inspectionType]);

  const inspectionHashId = 'dummyHashId';
  // NOTE: render回数が多いのでいずれ調整したい
  const {data, hasNextPage, fetchNextPage, refetch} = useFetchInfiniteInspectionResultsQuery(
    myInfo.hospitalHashId,
    inspectionHashId,
    params
  );

  const handleChangeType = useCallback(
    async (value: InspectionTypeOptionsValues) => {
      setInspectionType(value);
      setLoading(true);
      await fetchNextPage({pageParam: 0});
      setLoading(false);
    },
    [fetchNextPage, setInspectionType]
  );

  const handleChangeStatus = useCallback(
    async (value: InspectionResultStatus) => {
      setInspectionStatus(value);
      setLoading(true);
      await fetchNextPage({pageParam: 0});
      setLoading(false);
    },
    [fetchNextPage, setInspectionStatus]
  );

  const handleNameChange = useCallback(
    async (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      await fetchNextPage({pageParam: 0});
      if (e.target.value === '') {
        setInspectionSearchName(null);
      }
      setInspectionSearchName(e.target.value);
    },
    [fetchNextPage, setInspectionSearchName]
  );

  const handleOrderChange = useCallback(
    (item: MenuItemType) => {
      setInspectionOrder(item.value);
    },
    [setInspectionOrder]
  );
  return (
    <StyledRootDiv>
      <StyledFixedDiv>
        <Header title="点検" titleFade={!isFadeIn} />
      </StyledFixedDiv>

      <Fade in={isFadeIn}>
        <StyledFlexDiv>
          <InspectionTypeSelectBox type={inspectionType} onChangeType={handleChangeType} />
          <InspectionStatusSelectBox status={inspectionStatus} onChangeStatus={handleChangeStatus} />
        </StyledFlexDiv>
      </Fade>
      <StyledMainContent ref={scrollRef}>
        {/* ヘッダーに隠れるバー部分 */}
        <InspectionTypeBar type={inspectionType} onChangeType={handleChangeType} />
        <InspectionStatusBar status={inspectionStatus} onChangeStatus={handleChangeStatus} />
        {/* Stickyで残る検索バー */}
        <StyledStickyToolbar>
          <ToolBar
            searchName={inspectionSearchName}
            onChangeSearchName={handleNameChange}
            onChangeOrderChange={handleOrderChange}
            selectedValue={inspectionOrder}
          />
        </StyledStickyToolbar>
        <InfiniteScroll loadMore={(_page) => fetchNextPage({pageParam: _page})} hasMore={hasNextPage}>
          <Grid container direction="column">
            {loading || !data ? (
              <StyledLoading>
                <InnerLoading />
              </StyledLoading>
            ) : (
              data?.pages.map((_page, i) =>
                _page.data.map((inspectionResult: InspectionResultIndex, index: number) => (
                  <InspectionResult
                    inspectionResult={inspectionResult}
                    refetch={refetch}
                    key={`InspectionResult_${i}_${index}`}
                  />
                ))
              )
            )}
          </Grid>
        </InfiniteScroll>
      </StyledMainContent>
      <Footer />
    </StyledRootDiv>
  );
};
