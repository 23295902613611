export const viewInspectionType = (type: string | null) => {
  switch (type) {
    case 'pre_use':
      return '使用前点検';
    case 'in_use':
      return '使用中点検';
    case 'post_use':
      return '使用後点検';
    case 'periodic':
      return '定期点検';
    case null:
      return '';
    default:
      throw new Error('未知の点検タイプです');
  }
};
