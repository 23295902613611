import {useState} from 'react';
import {useHospitalProductRequiredPropertyStore} from '@modules/hospital_product_required_properties/hooks';
import {HospitalProductRequiredPropertyState} from '@modules/hospital_product_required_properties/types';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';

type ProductRequiredPropertiesSettingFormType = {
  hospitalProductRequiredPropertyState: HospitalProductRequiredPropertyState;
  isInitLoading: boolean;
  initProductRequiredPropertiesSettingForm: () => Promise<void>;
  addProductRequiredProperty: (property: string) => Promise<void>;
  deleteProductRequiredProperty: (property: string) => Promise<void>;
};

export const useProductRequiredPropertiesSettingForm = (): ProductRequiredPropertiesSettingFormType => {
  const {myInfo} = useMyInfo();
  const {
    state: hospitalProductRequiredPropertyState,
    dispatchListHospitalProductRequiredProperties,
    dispatchUpdateHospitalProductRequiredProperties,
    dispatchListHospitalProductRequiredPropertyItems,
  } = useHospitalProductRequiredPropertyStore();
  const [isInitLoading, setIsInitLoading] = useState(false);

  const initProductRequiredPropertiesSettingForm = async () => {
    setIsInitLoading(true);
    try {
      await Promise.all([
        dispatchListHospitalProductRequiredProperties(myInfo.hospitalHashId),
        dispatchListHospitalProductRequiredPropertyItems(myInfo.hospitalHashId),
      ]);
    } finally {
      setIsInitLoading(false);
    }
  };

  const addProductRequiredProperty = async (property: string) => {
    const properties = hospitalProductRequiredPropertyState.hospitalProductRequiredProperties.map(
      (item) => item.property
    );
    dispatchUpdateHospitalProductRequiredProperties(myInfo.hospitalHashId, {properties: [...properties, property]});
  };

  const deleteProductRequiredProperty = async (property: string) => {
    const properties = hospitalProductRequiredPropertyState.hospitalProductRequiredProperties.map(
      (item) => item.property
    );
    dispatchUpdateHospitalProductRequiredProperties(myInfo.hospitalHashId, {
      properties: properties.filter((v) => v !== property),
    });
  };

  return {
    hospitalProductRequiredPropertyState,
    isInitLoading,
    initProductRequiredPropertiesSettingForm,
    addProductRequiredProperty,
    deleteProductRequiredProperty,
  };
};
