import React, {useMemo} from 'react';
import {TableLayout, TableLayoutResult} from '@modules/table_layout/hooks/useTableLayout';
import {Table} from '@molecules/Table';
import {HospitalProductPlanListElement} from '../../types';
import {Column} from '@molecules/Table/props';
import {StatusColumn} from './StatusColumn';
import {StartDateColumn} from './StartDayColumn';
import {DateRange, Delete} from '@material-ui/icons';
import {InspectionPeriodColumn} from './InspectionPeriodColumn';
import {StartMonthColumn} from './StartMonthColumn';

/**
 * テーブルレイアウトに基づいてテーブルの列を生成する
 * @param {(TableLayoutResult)} tableLayout テーブルのレイアウト設定
 * @return Column<HospitalProductPlanListElement>[] | undefined
 */
const useTableColumns = (tableLayout: TableLayoutResult | undefined) => {
  return useMemo(() => {
    if (!tableLayout) return;
    const tableColumn = Object.assign<Column<HospitalProductPlanListElement>[], TableLayout[]>(
      [],
      tableLayout.currentLayout
    );
    return tableColumn.map<Column<HospitalProductPlanListElement>>((item) => {
      if (item.field == 'status') {
        item.render = (rowData: HospitalProductPlanListElement) => {
          return <StatusColumn rowData={rowData} />;
        };
      }
      if (item.field == 'startDate') {
        item.render = (rowData: HospitalProductPlanListElement) => {
          return <StartDateColumn rowData={rowData} />;
        };
      }
      if (item.field == 'inspectionPeriod') {
        item.render = (rowData: HospitalProductPlanListElement) => {
          return <InspectionPeriodColumn rowData={rowData} />;
        };
      }
      if (item.field == 'startMonth') {
        item.render = (rowData: HospitalProductPlanListElement) => {
          return <StartMonthColumn rowData={rowData} />;
        };
      }
      item.noBodyWrap = true;
      return item;
    });
  }, [tableLayout]);
};

type HospitalProductPlanListTable = {
  tableData: HospitalProductPlanListElement[];
  isLoading: boolean;
  canEditPlan: boolean;
  canDeletePlan: boolean;
  defaultOrder?: {fieldId: string; direction: 'asc' | 'desc'};
  onClickChangeInspectionDate: (selectedData: HospitalProductPlanListElement[]) => void;
  onClickDeletePlan: (selectedData: HospitalProductPlanListElement[]) => void;
  tableLayout: TableLayoutResult;
  handleOrderChange: (columnIndex: number, orderDirection: 'asc' | 'desc') => void;
};

export const HospitalProductPlanListTable = (props: HospitalProductPlanListTable) => {
  const {
    tableData,
    isLoading,
    canEditPlan,
    canDeletePlan,
    defaultOrder,
    onClickChangeInspectionDate,
    onClickDeletePlan,
    tableLayout,
    handleOrderChange,
  } = props;
  const serializedTableColumn = useTableColumns(tableLayout);

  const selectionButtons = useMemo(() => {
    return [
      ...(canEditPlan
        ? [
            {
              label: '点検開始月・月ごとの点検日を設定',
              IconComponent: DateRange,
              onClick: (_e: React.MouseEvent, selectedData: HospitalProductPlanListElement[]) =>
                onClickChangeInspectionDate(selectedData),
            },
          ]
        : []),
      ...(canDeletePlan
        ? [
            {
              label: '点検開始月・月ごとの点検日を削除',
              IconComponent: Delete,
              onClick: (_e: React.MouseEvent, selectedData: HospitalProductPlanListElement[]) =>
                onClickDeletePlan(selectedData),
            },
          ]
        : []),
    ];
  }, [onClickChangeInspectionDate, onClickDeletePlan, canEditPlan, canDeletePlan]);

  if (!serializedTableColumn) return null;
  return (
    <Table<HospitalProductPlanListElement>
      data={tableData}
      isLoading={isLoading}
      defaultOrder={defaultOrder}
      columns={serializedTableColumn}
      showSelection={canEditPlan || canDeletePlan}
      selectionButtons={selectionButtons}
      onOrderChange={handleOrderChange}
      noDataComponent={'データがありません'}
      stickyHeader={true}
      tableSize="small"
    />
  );
};
