import {v4 as uuidv4} from 'uuid';
import {ModeType, ADD_MODE, WAIT_MODE, VIEW_MODE, EDIT_MODE} from '../type';
import {isNullish} from '@front-libs/helpers';
import {InspectionType, InspectionTypeOptionsValues} from '@modules/inspections/enum';

/**
 * 点検計画の編集データ
 * 編集前のデータを保持しているのは、編集キャンセル時に元のデータに戻すため。
 */
export type EditWholeProductPlan = {
  uuid: string;
  inspectionSettingHashId: string | null;
  inspectionSettingName: string; // 点検名
  inspectionName: string; // 点検表名
  inspectionHashId: string; // 点検表のHashId
  inspectionPeriod: number; // 点検間隔
  inspectionPeriodUnit: string; // 点検間隔の単位
  inspectionType: InspectionType | null;
  mode: ModeType;
  beforeInspectionSettingName: string;
  beforeInspectionHashId: string;
  beforeInspectionName: string;
};

export const ADD_ACTION = 'ADD';
export const DELETE_ACTION = 'DELETE';
export const SAVE_ACTION = 'SAVE'; // 更新時の保存
export const SAVE_ADD_ACTION = 'SAVE_ADD'; // 新規計画の初回保存
export const SET_INITIAL_DATA_ACTION = 'SET_INITIAL_DATA';
export const EDIT_ACTION = 'EDIT';
export const CANCEL_ACTION = 'CANCEL';
export const CHANGE_INSPECTION_ACTION = 'CHANGE_INSPECTION';
export const CHANGE_INSPECTION_SETTING_NAME_ACTION = 'CHANGE_INSPECTION_SETTING_NAME';
export const CHANGE_INSPECTION_PERIOD_ACTION = 'CHANGE_INSPECTION_PERIOD';
export const CHANGE_INSPECTION_TYPE_ACTION = 'CHANGE_INSPECTION_TYPE';

export type EditWholeProductPlanAction =
  | {type: typeof ADD_ACTION}
  | {type: typeof DELETE_ACTION; uuid: string}
  | {type: typeof SAVE_ACTION; uuid: string}
  | {type: typeof SAVE_ADD_ACTION; uuid: string; inspectionSettingHashId: string}
  | {type: typeof SET_INITIAL_DATA_ACTION; payload: EditWholeProductPlan[]}
  | {type: typeof EDIT_ACTION; uuid: string}
  | {type: typeof CANCEL_ACTION; uuid: string}
  | {type: typeof CHANGE_INSPECTION_ACTION; uuid: string; inspectionHashId: string; inspectionName: string}
  | {type: typeof CHANGE_INSPECTION_SETTING_NAME_ACTION; uuid: string; inspectionSettingName: string}
  | {type: typeof CHANGE_INSPECTION_PERIOD_ACTION; uuid: string; inspectionPeriod: number}
  | {type: typeof CHANGE_INSPECTION_TYPE_ACTION; uuid: string; inspectionType: string};

// FIXME 後で初期値修正
const initialState: EditWholeProductPlan = {
  uuid: '',
  inspectionSettingHashId: null,
  inspectionSettingName: '',
  inspectionName: '',
  inspectionHashId: '',
  inspectionType: null,
  inspectionPeriod: 0,
  inspectionPeriodUnit: 'month',
  mode: ADD_MODE,
  beforeInspectionHashId: '',
  beforeInspectionSettingName: '',
  beforeInspectionName: '',
};

// reducer関数を定義
export const reducerFunc = (
  state: EditWholeProductPlan[],
  action: EditWholeProductPlanAction
): EditWholeProductPlan[] => {
  switch (action.type) {
    case ADD_ACTION:
      return addProductPlan(state);
    case DELETE_ACTION:
      return deleteProductPlan(state, action.uuid);
    case SAVE_ACTION:
      return saveProductPlan(state, action.uuid);
    case SAVE_ADD_ACTION:
      return saveAddProductPlan(state, action.uuid, action.inspectionSettingHashId);
    case SET_INITIAL_DATA_ACTION:
      return setInitialProductPlans(action.payload);
    case EDIT_ACTION:
      return editProductPlan(state, action.uuid);
    case CANCEL_ACTION:
      return cancelEdit(state, action.uuid);
    case CHANGE_INSPECTION_ACTION:
      return changeInspection(state, action.uuid, action.inspectionHashId, action.inspectionName);
    case CHANGE_INSPECTION_SETTING_NAME_ACTION:
      return changeInspectionSettingName(state, action.uuid, action.inspectionSettingName);
    case CHANGE_INSPECTION_PERIOD_ACTION:
      return changeInspectionPeriod(state, action.uuid, action.inspectionPeriod);
    case CHANGE_INSPECTION_TYPE_ACTION:
      return changeInspectionType(state, action.uuid, action.inspectionType);
    default:
      return state;
  }
};

/**
 * 新規計画を追加ボタンを押した時の処理
 * 対象のデータを追加する。なお、新規計画はADDモード、それ以外はVIEWモードで表示する。
 */
const addProductPlan = (plans: EditWholeProductPlan[]): EditWholeProductPlan[] => {
  const newPlans: EditWholeProductPlan[] = plans.map((item) => ({...item, mode: WAIT_MODE}));
  const createPlan = {...initialState, uuid: uuidv4()};
  newPlans.push({...createPlan});
  return newPlans;
};

/**
 * 削除ボタンを押した時の処理
 * 対象のデータを削除する。
 */
const deleteProductPlan = (state: EditWholeProductPlan[], uuid: string): EditWholeProductPlan[] => {
  return state.filter((item) => item.uuid !== uuid);
};

/**
 * 保存ボタンを押した時の処理
 * 編集したデータをサーバに保存する。
 * 新規計画の保存時は、全件Viewモードに変更する。
 */
const saveProductPlan = (state: EditWholeProductPlan[], uuid: string): EditWholeProductPlan[] => {
  const savePlan = state.find((item) => item.uuid === uuid);
  if (isNullish(savePlan)) {
    throw new Error('savePlan is nullish');
  }

  // 編集前モードの値をデータを更新する
  return state.map((item) =>
    item.uuid === uuid
      ? {
          ...item,
          mode: VIEW_MODE,
          beforeInspectionHashId: item.inspectionHashId,
          beforeInspectionName: item.inspectionName,
          beforeInspectionSettingName: item.inspectionSettingName,
        }
      : item
  );
};

/**
 * 新規計画の保存ボタンを押した時の処理
 * 編集したデータをサーバに保存する。
 * 新規計画の保存時は、全件Viewモードに変更する。
 */
const saveAddProductPlan = (
  state: EditWholeProductPlan[],
  uuid: string,
  inspectionSettingHashId: string
): EditWholeProductPlan[] => {
  const savePlan = state.find((item) => item.uuid === uuid);
  if (isNullish(savePlan)) {
    throw new Error('savePlan is nullish');
  }

  return state.map((item) =>
    item.uuid === uuid
      ? {
          ...item,
          inspectionSettingHashId: inspectionSettingHashId,
          mode: VIEW_MODE,
          beforeInspectionHashId: item.inspectionHashId,
          beforeInspectionName: item.inspectionName,
          beforeInspectionSettingName: item.inspectionSettingName,
        }
      : {
          ...item,
          mode: VIEW_MODE,
        }
  );
};

/**
 * 初期データをセットする
 */
const setInitialProductPlans = (initialPlans: EditWholeProductPlan[]): EditWholeProductPlan[] => {
  return initialPlans;
};

/**
 * 編集ボタンを押した時の処理
 * 編集モードに変更する。
 */
const editProductPlan = (state: EditWholeProductPlan[], uuid: string): EditWholeProductPlan[] => {
  return state.map((item) => (item.uuid === uuid ? {...item, mode: EDIT_MODE} : item));
};

/**
 * キャンセルボタンを押した時の処理
 * 編集モードを閲覧モードに変更する。
 * 新規計画のキャンセル時は、対象のレコードを削除し、全件Viewモードに変更する。
 */
const cancelEdit = (state: EditWholeProductPlan[], uuid: string): EditWholeProductPlan[] => {
  const cancelPlan = state.find((item) => item.uuid === uuid);
  if (isNullish(cancelPlan)) {
    throw new Error('cancelPlan is nullish');
  }

  // 新規計画の保存時は、全件Viewモードに変更する。
  if (cancelPlan.mode === ADD_MODE) {
    const newState = state.filter((item) => item.uuid !== uuid);
    // 残った要素のモードをVIEW_MODEに切り替える
    return newState.map((item) => ({...item, mode: VIEW_MODE}));
  }

  // 編集前モードの値を元に、データを復元する
  return state.map((item) =>
    item.uuid === uuid
      ? {
          ...item,
          mode: VIEW_MODE,
          inspectionHashId: item.beforeInspectionHashId,
          inspectionName: item.beforeInspectionName,
          inspectionSettingName: item.beforeInspectionSettingName,
        }
      : item
  );
};

/**
 * 点検表変更時の処理
 */
const changeInspection = (
  state: EditWholeProductPlan[],
  uuid: string,
  inspectionHashId: string,
  inspectionName: string
): EditWholeProductPlan[] => {
  return state.map((item) => (item.uuid === uuid ? {...item, inspectionHashId, inspectionName} : item));
};

/**
 * 点検計画名変更時の処理
 */
const changeInspectionSettingName = (
  state: EditWholeProductPlan[],
  uuid: string,
  inspectionSettingName: string
): EditWholeProductPlan[] => {
  return state.map((item) => (item.uuid === uuid ? {...item, inspectionSettingName} : item));
};

/**
 * 点検間隔変更時の処理
 */
const changeInspectionPeriod = (
  state: EditWholeProductPlan[],
  uuid: string,
  inspectionPeriod: number
): EditWholeProductPlan[] => {
  return state.map((item) => (item.uuid === uuid ? {...item, inspectionPeriod} : item));
};

/**
 * 点検間隔変更時の処理
 */
const changeInspectionType = (
  state: EditWholeProductPlan[],
  uuid: string,
  inspectionType: string
): EditWholeProductPlan[] => {
  return state.map((item) =>
    item.uuid === uuid
      ? {
          ...item,
          inspectionType: inspectionType as InspectionTypeOptionsValues,
          inspectionPeriod: 0,
          inspectionHashId: '',
          inspectionName: '',
        }
      : item
  );
};
