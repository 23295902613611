import {HospitalProductState, HospitalProductAction, HospitalProductActionType} from '../types';

export const initialState: HospitalProductState = {
  hospitalProducts: [],
};

export const reducer = (state: HospitalProductState, action: HospitalProductAction): HospitalProductState => {
  switch (action.type) {
    case HospitalProductActionType.HOSPITAL_PRODUCT_SET_LIST: {
      return {
        ...state,
        hospitalProducts: action.payload.hospitalProducts,
      };
    }
    default:
      return state;
  }
};
