import React, {useEffect, useState} from 'react';
import {MobileProductFormField} from '@molecules/MobileProductFormField';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useFetchHospitalProductQuery} from '@modules/hospital_products/api';
import {HospitalProductDetail} from '@modules/hospital_products/types';
import {convertDateToJapaneseDate, convertRFC3339ToDate, isNullish} from '@front-libs/helpers';
import {InnerLoading} from '@molecules/Loading';
import {waysOfPurchaseOptions} from '@Apps/ProductsList/pc/constants';
import dayjs from 'dayjs';
import {
  MAX_NOTE_NUM,
  useHospitalProductNoteSettings,
} from '@modules/hospital_products/hooks/useHospitalProductNoteSettings';
import {styled} from '@material-ui/styles';

// NOTE:SPでtypeは未使用
const fields = [
  {
    type: 'text',
    label: '購入区分',
    name: 'waysOfPurchase',
    getValue: (product: HospitalProductDetail) =>
      waysOfPurchaseOptions.find((item) => item.value === product.waysOfPurchase)?.label ?? '',
  },
  // FIXME: レンタル関連はここ
  {
    type: 'select',
    label: '購入元',
    name: 'purchasedNationalExpense',
    options: [
      {label: '国費', value: true},
      {label: '院費', value: false},
    ],
    getValue: ({purchasedNationalExpense}: HospitalProductDetail) =>
      isNullish(purchasedNationalExpense) ? '' : purchasedNationalExpense ? '国費' : '院費',
  },
  {
    type: 'number',
    label: '納入価',
    name: 'deliveryPrice',
    isVisible: (values: HospitalProductDetail) => values.waysOfPurchase === 'purchase',
    getValue: ({deliveryPrice}: HospitalProductDetail) =>
      !isNullish(deliveryPrice) ? `${deliveryPrice.toLocaleString()}円` : '',
  },
  {
    type: 'select',
    label: '税込／税抜き',
    name: 'taxIncluded',
    isVisible: (values: HospitalProductDetail) => values.waysOfPurchase === 'purchase',
    getValue: ({taxIncluded}: HospitalProductDetail) => (isNullish(taxIncluded) ? '' : taxIncluded ? '税込' : '税抜き'),
  },
  {
    type: 'number',
    label: '税率',
    name: 'taxRate',
    isVisible: (values: HospitalProductDetail) => values.waysOfPurchase === 'purchase',
    getValue: ({taxRate}: HospitalProductDetail) => (isNullish(taxRate) ? '' : `${taxRate}%`),
  },
  {
    type: 'text',
    label: '資産番号',
    name: 'assetRegisterNumber',
    getValue: ({assetRegisterNumber}: HospitalProductDetail) => assetRegisterNumber ?? '',
  },
  {
    type: 'number',
    label: '院内耐用年数',
    name: 'legalDurableYear',
    getValue: ({legalDurableYear}: HospitalProductDetail) =>
      isNullish(legalDurableYear) ? '' : `${legalDurableYear}年`,
  },
  {
    type: 'number',
    label: '残存年数（年）',
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    name: 'remainingYear' as any,
    getValue: (product: HospitalProductDetail) => {
      const {legalDurableYear, dateOfPurchase} = product;
      if (!legalDurableYear || !dateOfPurchase) return '';

      const now = dayjs();
      const diff = now.diff(dateOfPurchase, 'year');
      return `${Math.max(0, legalDurableYear - diff)}年`;
    },
  },
  {
    type: 'text',
    label: '廃棄日',
    name: 'dateOfDisposal',
    getValue: ({dateOfDisposal}: HospitalProductDetail) => {
      const date = convertRFC3339ToDate(dateOfDisposal ?? '');
      if (isNullish(date)) return '';
      return convertDateToJapaneseDate(date) ?? '';
    },
  },
  {
    type: 'textarea',
    label: '廃棄理由',
    name: 'reasonOfDisposal',
    getValue: ({reasonOfDisposal}: HospitalProductDetail) => reasonOfDisposal ?? '',
  },
];

type DetailHospitalProductInfoProps = {
  hospitalProductHashId: string;
};

const StyledPaddingDiv = styled('div')({
  padding: '16px',
});
const StyledMarginDiv = styled('div')({
  marginBottom: 24,
});

type NoteField = {label: string; value: string};

/**
 * HospitalProductDetailからnotesの値を取得する
 * 型定義が複雑になるのでSwitchで分岐
 * @param numString
 * @param data
 * @returns
 */
export const getNoteValue = (numString: string, data: HospitalProductDetail) => {
  switch (numString) {
    case '':
      return data.notes || '';
    case '2':
      return data.notes2 || '';
    case '3':
      return data.notes3 || '';
    case '4':
      return data.notes4 || '';
    case '5':
      return data.notes5 || '';
    case '6':
      return data.notes6 || '';
    case '7':
      return data.notes7 || '';
    case '8':
      return data.notes8 || '';
    case '9':
      return data.notes9 || '';
    case '10':
      return data.notes10 || '';
    case '11':
      return data.notes11 || '';
    case '12':
      return data.notes12 || '';
    case '13':
      return data.notes13 || '';
    case '14':
      return data.notes14 || '';
    case '15':
      return data.notes15 || '';
    case '16':
      return data.notes16 || '';
    case '17':
      return data.notes17 || '';
    case '18':
      return data.notes18 || '';
    case '19':
      return data.notes19 || '';
    case '20':
      return data.notes20 || '';
    default:
      return '';
  }
};

/**
 * 詳細情報ページ
 * @param props
 * @returns
 */
export const DetailHospitalProductInfo = (props: DetailHospitalProductInfoProps) => {
  const {hospitalProductHashId} = props;
  const [noteFields, setNoteFields] = useState<NoteField[]>([]);
  const {myInfo} = useMyInfo();

  const {data: productData, isLoading: isLoadingHospitalProduct} = useFetchHospitalProductQuery(
    myInfo.hospitalHashId,
    hospitalProductHashId
  );

  const {data: noteData, isLoading: isLoadingNoteSettings} = useHospitalProductNoteSettings(myInfo.hospitalHashId);

  useEffect(() => {
    if (!noteData || !productData) return;
    const noteSettings = noteData.noteSettings;

    const tempNoteFields: NoteField[] = [];
    for (let i = 1; i <= MAX_NOTE_NUM; i++) {
      const numString = i === 1 ? '' : String(i);
      if (noteSettings[`notes${numString}Visible`] === false) continue;
      const value = getNoteValue(numString, productData);
      tempNoteFields.push({
        value: noteSettings[`notes${numString}Type`] === 'date' ? value.replaceAll('-', '/') ?? '' : value,
        label: noteSettings[`notes${numString}Name`],
      });
    }
    setNoteFields(tempNoteFields);
  }, [productData, noteData]);

  if (isLoadingHospitalProduct || isNullish(productData) || isLoadingNoteSettings) {
    return <InnerLoading />;
  }
  return (
    <StyledPaddingDiv>
      {fields
        .filter((item) => isNullish(item.isVisible) || item.isVisible(productData))
        .map((item) => (
          <StyledMarginDiv key={item.name}>
            <MobileProductFormField label={item.label} value={item.getValue(productData)} />
          </StyledMarginDiv>
        ))}
      {noteFields.map((note, index) => (
        <StyledMarginDiv key={`noteFields${index}`}>
          <MobileProductFormField label={note.label} value={note.value} />
        </StyledMarginDiv>
      ))}
    </StyledPaddingDiv>
  );
};
