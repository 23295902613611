import {Typography, Box, IconButton, Tooltip} from '@mui/material';
import {ArrowDropDown, DeleteOutline} from '@mui/icons-material';
import {MenuItemType, PopperMenuButtonV5} from '@components/molecules/Buttons/PopperMenuButton/PopperMenuButtonV5';
import React, {useMemo, useState} from 'react';
import {WholeProductPmdaFile} from '@modules/pmda_files/types';
import dayjs from 'dayjs';

const DAYJS_FORMAT = 'YYYY年MM月DD日';

type Props = {
  onClickDelete: () => void;
};
/**
 * 削除ボタン
 **/
export const DeleteIconButton = ({onClickDelete}: Props) => {
  return (
    <Tooltip title="削除">
      <IconButton onClick={onClickDelete} size="small">
        <DeleteOutline style={{color: '#172B4D'}} />
      </IconButton>
    </Tooltip>
  );
};

type PopperPmdaVersionButtonProps = {
  pmdaFiles: WholeProductPmdaFile[];
  onClickSettingMenu: (fileHashId: string) => void;
  onClickDelete: (hashId: string) => void;
};

/**
 * 院内機種詳細PMDAバージョン選択ボタン
 */
export const WholeProductPmdaPopperVersionButton = React.memo(
  ({pmdaFiles, onClickSettingMenu, onClickDelete}: PopperPmdaVersionButtonProps) => {
    const [selectedFile, setSelectedFile] = useState(pmdaFiles[0] || null);

    const menuItemList: MenuItemType[] = useMemo(
      () =>
        pmdaFiles.map((pmdaFile, index) => {
          const version = `${dayjs(pmdaFile.createdAt).format(DAYJS_FORMAT)}版 ${
            pmdaFile.isUserUpload ? '手動アップロード' : '自動更新'
          }`;

          return {
            label: index === 0 ? `${version} (最新)` : version,
            value: pmdaFile.fileHashId,
          };
        }),
      [pmdaFiles]
    );

    const deleteHashId = useMemo(() => selectedFile?.hashId, [selectedFile]);

    const displayLabel = useMemo(
      () => (selectedFile ? `最新のバージョン(${dayjs(selectedFile.createdAt).format(DAYJS_FORMAT)}時点)` : ''),
      [selectedFile]
    );

    const handleMenuClick = (item: MenuItemType) => {
      const file = pmdaFiles.find((pmdaFile) => pmdaFile.fileHashId === item.value);
      if (file) {
        setSelectedFile(file);
        onClickSettingMenu(file.fileHashId);
      }
    };
    return (
      <Box sx={{display: 'flex', justifyContent: 'space-between', flexGrow: 1}}>
        <PopperMenuButtonV5
          placement="bottom-start"
          hiddenArrow
          buttonProps={{variant: 'text', sx: {width: '100%', padding: 0}}}
          menuItemList={menuItemList}
          itemProps={{sx: {minWidth: '350px'}}}
          onMenuClick={handleMenuClick}>
          {pmdaFiles.length > 0 && <Typography sx={{color: '#0052CC'}}>{displayLabel}</Typography>}
          <ArrowDropDown />
        </PopperMenuButtonV5>
        {selectedFile?.isUserUpload && <DeleteIconButton onClickDelete={() => onClickDelete(deleteHashId)} />}
      </Box>
    );
  },
  (p, v) => JSON.stringify(p.pmdaFiles) === JSON.stringify(v.pmdaFiles)
);
