import {useCurrentDateTime} from '@front-libs/core';
import {Box, SxProps, Theme} from '@mui/material';
import {useAtomValue} from 'jotai';
import React from 'react';
import {useLocation} from 'react-router-dom';
import {rentStepperAtom} from '../states';
import {RentalStepper} from './Stepper';
import {HeaderHitotsuLogo} from './HeaderHitotsuLogo';

type Props = {
  title?: string;
  showLogo?: boolean;
};

// 病棟ユーザーメニューに合わせる height66px+padding32px = 98px
export const RentalHeaderHeight = '98px';

const containerStyle: SxProps<Theme> = {
  boxSizing: 'border-box',
  minHeight: '66px',
  width: '100%',
  padding: '32px',
  display: 'flex',
  justifyContent: 'space-between',
};

const titleStyle: SxProps<Theme> = {
  color: '#374659',
  fontSize: '24px',
  fontWeight: 'bold',
};

const dateStyle: SxProps = {
  textAlign: 'right',
};

/**
 * 日付表示コンポーネント
 */
const HeaderDate = React.memo(
  () => {
    const formattedDate = useCurrentDateTime();
    return <Box sx={dateStyle}>{formattedDate}</Box>;
  },
  () => true
);

export const Header = React.memo(({title, showLogo = false}: Props) => {
  const activeStep = useAtomValue(rentStepperAtom);
  // /sharedの文字列にマッチし末尾のスラッシュがありなしどちらにもマッチします
  const regex = /^\/shared\/?$/;
  const canStepper = !regex.test(useLocation().pathname);

  return (
    <Box sx={containerStyle}>
      <Box>
        {showLogo && <HeaderHitotsuLogo />}
        <Box sx={titleStyle}>{title}</Box>
      </Box>
      {canStepper && <RentalStepper activeStep={activeStep} />}
      <HeaderDate />
    </Box>
  );
});
