import {TableBody, TableRow} from '@mui/material';
import React from 'react';
import {InspectionType} from '@modules/inspections/enum';

import {CreateWholeProductPlanAction} from './utils/reducer';
import {
  ActionButtonCell,
  InspectionCell,
  InspectionPeriodTypeCell,
  InspectionTypeCell,
  SettingNameCell,
} from './ui/CreateWholeProductCell';
import {useWholeProductPlan} from './hooks';
import {CreateWholeProductPlan} from './type';

type CreateWholeProductListBodyProps = {
  inspectionSettings: CreateWholeProductPlan[];
  dispatch: React.Dispatch<CreateWholeProductPlanAction>;
};

export const CreateWholeProductListBody = ({inspectionSettings, dispatch}: CreateWholeProductListBodyProps) => {
  return (
    <TableBody sx={{border: 'none', boxShadow: 'none', borderBottom: 'none'}}>
      {inspectionSettings.map((inspectionSetting) => (
        <CreateWholeProductRow
          key={inspectionSetting.uuid}
          inspectionSettings={inspectionSettings}
          inspectionSetting={inspectionSetting}
          dispatch={dispatch}
        />
      ))}
    </TableBody>
  );
};

type CreateWholeProductRowProps = {
  inspectionSettings: CreateWholeProductPlan[];
  inspectionSetting: CreateWholeProductPlan;
  dispatch: React.Dispatch<CreateWholeProductPlanAction>;
};

export const CreateWholeProductRow = ({
  inspectionSettings,
  inspectionSetting,
  dispatch,
}: CreateWholeProductRowProps) => {
  const {
    handleInspectionSettingNameChange,
    handleInspectionPeriodChange,
    handleInspectionChange,
    handleInspectionTypeChange,
    handleDeleteRow,
    displayInspectionTypeOptions,
  } = useWholeProductPlan({inspectionSetting, dispatch, inspectionSettings});
  return (
    <TableRow key={inspectionSetting.uuid}>
      <SettingNameCell
        settingName={inspectionSetting.inspectionSettingName}
        onInspectionSettingNameChange={(settingName: string) => {
          handleInspectionSettingNameChange(settingName);
        }}
      />
      <InspectionTypeCell
        row={inspectionSetting}
        onChangeInspectionType={(inspectionType: InspectionType) => {
          handleInspectionTypeChange(inspectionType);
        }}
        displayInspectionTypeOptions={displayInspectionTypeOptions}
      />
      <InspectionPeriodTypeCell
        isPeriodic={inspectionSetting.inspectionType === 'periodic'}
        row={inspectionSetting}
        onChangeInspectionPeriod={handleInspectionPeriodChange}
      />
      <InspectionCell
        inspectionType={inspectionSetting.inspectionType}
        inspectionName={inspectionSetting.inspectionName}
        onChangeInspection={(inspectionHashId: string, inspectionName: string) => {
          handleInspectionChange(inspectionHashId, inspectionName);
        }}
      />
      {inspectionSettings.length > 1 && (
        <ActionButtonCell
          onDelete={() => {
            handleDeleteRow();
          }}
        />
      )}
    </TableRow>
  );
};
