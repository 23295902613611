import React from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core';
import {getInspectionStatusMenus} from '../consts';
import {ViewInspectionResultStatus, ViewInspectionType} from '../types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      marginTop: '8px',
      padding: '2px 8px',
      '&.Mui-selected': {
        backgroundColor: 'rgba(42, 150, 232, 0.1) !important',
      },
      '&.Mui-selected span, &.Mui-selected + .MuiListItemSecondaryAction-root span': {
        fontWeight: 'bold',
      },
    },
  })
);

type StatusSelectorProps = {
  inspectionType: ViewInspectionType;
  status: ViewInspectionResultStatus;
  counts?: Record<ViewInspectionResultStatus, number>;
  onSelectStatus: (status: ViewInspectionResultStatus | null) => void;
  isOnline: boolean;
};

export const StatusSelector: React.FC<StatusSelectorProps> = (props) => {
  const {inspectionType, status, counts = {} as {[key: string]: number}, onSelectStatus, isOnline} = props;
  const classes = useStyles();

  return (
    <List>
      {getInspectionStatusMenus(inspectionType, isOnline).map(({value, label}) => {
        const count = counts[value] ?? 0;

        return (
          <ListItem
            button
            key={value}
            onClick={() => onSelectStatus(value as ViewInspectionResultStatus)}
            selected={status === value}
            className={classes.item}>
            <ListItemText primary={label} />
            <ListItemSecondaryAction>
              <ListItemText primary={`${count}`} />
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
};
