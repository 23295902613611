import {axios} from '@front-libs/core';
import {FetchWholeProductPartsParam, FetchWholeProductPartsResponse} from './types';
import {PartsDialogFormValues} from '@Apps/ProductsParts/schema';

/**
 * 部品・交換品一覧取得
 * @param hospitalHashId - 病院のハッシュID
 * @param params - 部品・交換品の検索およびフィルタリングのためのパラメータ
 * @param params.name - 検索条件としての部品名（オプション）
 * @param params.page - ページ番号（オプション、デフォルトは1）
 * @param params.perPage - 1ページあたりの取得件数（オプション、デフォルトは10）
 * @param params.order - 並び順（オプション、例: "asc" または "desc"）
 */
export const getWholeProductParts = async (hospitalHashId: string, params: FetchWholeProductPartsParam) => {
  const baseURL = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/whole_product_parts`;
  const {data} = await axios.get<FetchWholeProductPartsResponse>(baseURL, {params});

  return data;
};

/**
 * 部品・交換品の新規登録
 *
 * @param hospitalHashId - 病院のハッシュID
 * @param params - 登録する部品・交換品の情報
 * @param params.name - 部品名（必須）
 * @param params.partCode - 部品コード（必須）
 * @param params.assignWholeProductHashIds - 紐付ける親機のハッシュID一覧（オプション）
 *
 * @returns 登録処理の結果（サーバーレスポンス）
 */
export const postWholeProductParts = async (hospitalHashId: string, params: PartsDialogFormValues) => {
  const baseURL = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/whole_product_parts`;
  return await axios.post<unknown>(baseURL, params);
};

/**
 * 部品・交換品の更新
 *
 * @param hospitalHashId - 病院のハッシュID
 * @param params - 登録する部品・交換品の情報
 * @param params.name - 部品名（必須）
 * @param params.partCode - 部品コード（必須）
 * @param params.assignWholeProductHashIds - 紐付ける親機のハッシュID一覧（オプション）
 *
 * @returns 登録処理の結果（サーバーレスポンス）
 */
export const updateWholeProductParts = async (
  hospitalHashId: string,
  partHashId: string,
  params: PartsDialogFormValues
) => {
  `/api/v1/hospitals/:hospital_hash_id/whole_product_parts/:part_hash_id`;
  const baseURL = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/whole_product_parts/${partHashId}`;
  return await axios.put<unknown>(baseURL, params);
};

export const deleteWholeProductParts = async (hospitalHashId: string, partHashId: string) => {
  const baseURL = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/whole_product_parts/${partHashId}`;
  const {data} = await axios.delete<unknown>(baseURL);

  return data;
};
