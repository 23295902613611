import {axios} from '@front-libs/core';
import {
  CreateFaultReceptionLogParam,
  FaultReceptionLog,
  FaultReceptionLogParams,
  FaultReceptionLogsResponse,
} from '../types';

/**
 * 不具合受付のログ一覧を取得
 * @param hospitalHashId
 * @param faultReceptionHashId
 * @param params
 * @returns
 */

export const getFaultReceptionLogs = async (
  hospitalHashId: string,
  faultReceptionHashId: string,
  params?: FaultReceptionLogParams
) => {
  const url = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/fault_receptions/${faultReceptionHashId}/logs`;
  const response = await axios.get<FaultReceptionLogsResponse>(url, params && {params});
  return response.data;
};

/**
 * 不具合受付ログの登録
 * @param hospitalHashId
 * @param faultReceptionHashId
 * @param params
 * @returns
 */
export const createFaultReceptionLog = async (
  hospitalHashId: string,
  faultReceptionHashId: string,
  data: CreateFaultReceptionLogParam
) => {
  const url = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/fault_receptions/${faultReceptionHashId}/logs`;
  return await axios.post<FaultReceptionLog>(url, data);
};
