import {CodeReaderTypes} from '../constants';

export type CodeReaderType = (typeof CodeReaderTypes)[number];

export enum CodeReaderActionType {
  CODE_READER_PUT_ITEM = 'CODE_READER_PUT_ITEM',
}

export type CodeReaderPutItemAction = {
  type: CodeReaderActionType.CODE_READER_PUT_ITEM;
  payload: {
    codeReaderType: CodeReaderType;
  };
};

export type CodeReaderAction = CodeReaderPutItemAction;

export type CodeReaderState = {
  codeReaderType: CodeReaderType;
};
