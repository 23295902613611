import React, {memo, useMemo} from 'react';
import {Box, styled} from '@mui/material';
import {FaultReceptionsDetail, FaultReceptionStatus} from '@modules/fault_receptions/types';
import {FaultReceptionStatusDisplay} from './FaultReceptionStatusDisplay';
import {UnderlineTitle} from '@components/molecules/UnderlineTitle';
import {LabelAndText} from '@components/molecules/LabelAndText';

const Container = styled(Box)({
  display: 'flex',
  height: '100%',
  flexDirection: 'column',
  padding: '8px',
  overflowY: 'auto',
});

type FaultReceptionDetailSectionProps = {
  titleColor?: string;
  param?: FaultReceptionsDetail;
  status?: FaultReceptionStatus | undefined;
};

/**
 * 不具合詳細の表示
 */
export const FaultReceptionDetailSection = memo(({param, status, titleColor}: FaultReceptionDetailSectionProps) => {
  const locationName = useMemo(() => (param && param.locationName) ?? '未入力', [param]);
  if (!param) return null;

  return (
    <Container>
      {param.receptionId && (
        <FaultReceptionStatusDisplay receiptNumber={param.receptionId} status={status} titleColor={titleColor} />
      )}
      <UnderlineTitle title="入力内容" />
      <LabelAndText label="どんな機器ですか？" text={param.productFeature} />
      <LabelAndText label="どんな不具合ですか？" text={param.faultDetail} />
      <LabelAndText label="機器を使用していた場所はどこですか？" text={param.rentalRoomName} />
      <LabelAndText label="不具合の詳細を知りたい場合、誰に聞いたらよいですか？" text={param.reporterName} />
      <LabelAndText
        label="この機器はどこで回収できますか？"
        text={param.location === 'other' ? locationName : '所定の場所'}
      />
      <LabelAndText
        label="代替機が必要な場合はチェック"
        text={`チェック${param.isNeedAlternativeDevice ? 'あり' : 'なし'}`}
      />
      <LabelAndText
        label="至急の対応が必要な場合はチェック"
        text={`チェック${param.isNeedUrgentResponse ? 'あり' : 'なし'}`}
      />
    </Container>
  );
});
