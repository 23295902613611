import React from 'react';
import {TableRow, TableCell as MTableCell} from '@material-ui/core';
import {Column as ColumnType, RowAction as RowActionType} from '../props';
import {useMouseClickAndDragCallback} from './hooks';
import {CheckboxCell} from './CheckboxCell';
import {RowActions} from './RowActions';
import {isNullish} from '@front-libs/helpers';
import styled from '@emotion/styled';

const CLASS_VISIBLE_ON_ROW_HOVER = 'visible-on-row-hover';

const MTableRow = styled(TableRow)({
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04);',
  },
  [`&:hover .${CLASS_VISIBLE_ON_ROW_HOVER}`]: {
    visibility: 'visible',
  },
});

const BoxDiv = styled('span')((props: {direction: string}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: props.direction,
  '& > *:not(:first-of-type)': {
    marginLeft: '16px',
  },
  // 最初のボタンの左だけ32pxマージン開けたい
  '& > button:first-of-type': {
    // marginLeft: '32px',
  },
}));

type RowProps<T> = {
  index: number;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  data: any;
  columns: ColumnType<T>[];
  rowActions: RowActionType<T>[];
  rowSize: 'medium' | 'small';
  showsCheckbox: boolean;

  onClick?: React.MouseEventHandler;
  onSelect?: React.ChangeEventHandler<HTMLInputElement>;
  rowActionIndex?: number;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const Row = <T extends {}>(props: RowProps<T>) => {
  const {index, data, columns, rowActions, rowSize, showsCheckbox, onClick, onSelect, rowActionIndex} = props;
  const mouseCallbacks = useMouseClickAndDragCallback(onClick);
  const cursor = isNullish(onClick) ? 'text' : 'pointer';
  const newColIndex = rowActionIndex || 0;

  return (
    <MTableRow {...mouseCallbacks} data-testid={`row-${index}`} style={{cursor}}>
      {showsCheckbox && <CheckboxCell checked={data.tableData?.checked ?? false} size={rowSize} onChange={onSelect} />}
      {columns.map((col, colIndex) => {
        const whiteSpace = col.noBodyWrap === true ? 'nowrap' : 'normal';
        const justifyContent = col.justifyContentBody ?? 'left';

        return (
          <MTableCell key={col.field} component="th" scope="row" style={{whiteSpace}}>
            <BoxDiv direction={`${justifyContent}`}>
              {col.render ? col.render(data, col) : <span>{data[col.field]}</span>}
              {colIndex === newColIndex && rowActions.length > 0 && (
                <RowActions data={data} actions={rowActions} buttonSize={rowSize} />
              )}
            </BoxDiv>
          </MTableCell>
        );
      })}
    </MTableRow>
  );
};
