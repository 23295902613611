import React from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, SxProps, Theme} from '@mui/material';

type Props = {
  label: string;
  name: string;
};

/**
 * 縦並び2項目のTable
 * 貸出・返却ページの貸出者・返却者の表示に使用される
 */
export const VerticalPairTable = ({label, name}: Props) => {
  return (
    <TableContainer component={Paper} sx={tableContainerStyle} elevation={0}>
      <Table aria-label="simple table" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell align="left" sx={tableHeaderStyle}>
              {label}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={tableBodyStyle}>
            <TableCell>{name}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const tableContainerStyle: SxProps<Theme> = {
  borderRadius: '10px',
  width: '100%',
};

const tableHeaderStyle: SxProps<Theme> = {
  backgroundColor: '#FFF',
};

const tableBodyStyle: SxProps<Theme> = {
  height: '56px',
};
