import React from 'react';
import ReactDOM from 'react-dom';
import {StyledEngineProvider} from '@mui/material';
import {QueryClientProvider} from 'react-query';
import {queryClient} from '@constants/api';
import {DialogProps} from '@front-libs/ui';

const mountPoint = document.getElementById('dialog');
const containerKey = 'hitotsu-dialog-container';
const dialogKey = 'hitotsu-dialog';

export const dialogHandler = {
  /**
   * ダイアログを開く
   *
   * @param Component - 表示するコンポーネント
   * @param props - コンポーネントに渡すプロパティ`DialogProps` のキーは除外される
   * @returns ダイアログが閉じると解決される Promise
   */
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  open: function <P = any, Q = any>(Component: React.FC<P>, props: Omit<P, keyof DialogProps>) {
    return new Promise<Q>((resolve, reject) => {
      const handleResolve = async (res: Q) => {
        await this.close();
        resolve(res);
      };
      const handleReject = async (res: Q) => {
        await this.close();
        reject(res);
      };
      const actions = {resolve: handleResolve, reject: handleReject};
      let dialogProps = {...props, open: false, actions} as P & DialogProps;
      ReactDOM.render(
        <ProviderContainer>
          <Component {...dialogProps} key={dialogKey} />
        </ProviderContainer>,
        mountPoint,
        () => {
          dialogProps = {...props, open: true, actions} as P & DialogProps;
          ReactDOM.render(
            <ProviderContainer>
              <Component {...dialogProps} key={dialogKey} />
            </ProviderContainer>,
            mountPoint
          );
        }
      );
    });
  },

  close: function () {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve();
        if (mountPoint) {
          // NOTE:React18で root.unmount()に変更する
          // SEE:https://ja.react.dev/reference/react-dom/unmountComponentAtNode
          ReactDOM.unmountComponentAtNode(mountPoint);
        }
      }, 300);
    });
  },
};

function ProviderContainer({children}: {children: React.ReactNode}) {
  return (
    <StyledEngineProvider injectFirst key={containerKey}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </StyledEngineProvider>
  );
}
