import React, {useCallback, useMemo, useState} from 'react';
import {makeStyles, Grid, TextField, Typography, Box} from '@material-ui/core';
import {useAtomValue, useSetAtom} from 'jotai';
import {Search} from '@material-ui/icons';
import {TableLayout, useTableLayout} from '@modules/table_layout/hooks/useTableLayout';
import {Column} from '@molecules/Table/props';
import {PopperSelectBoxButton, SelectOptionProps} from '@molecules/Buttons/PopperSelectBoxButton';
import {CategoryFormatter} from '@modules/categories/helpers';
import {useDebounceState} from '@front-libs/core';
import {isProductSelectedAtom, ProductElement, selectedProductsAtom} from './states';
import {Table} from '@molecules/Table';
import {
  useGetHospitalCategories,
  useGetHospitalDescendantCategories,
} from '@modules/categories/api/hospitalCategoryApi';
import {GetHospitalWholeProductParam} from '@modules/products/api';
import {useHospitalWholeProductsQuery} from './hooks';
import {isNullish} from '@front-libs/helpers';

type ProductNameColumnProps = {
  name?: string;
  displayName?: string;
};

const ProductNameColumn: React.FC<ProductNameColumnProps> = (props) => {
  const {name, displayName} = props;

  return (
    <Typography style={{fontSize: 14}}>
      {!isNullish(name) && (
        <>
          {name}
          <br />
        </>
      )}
      {displayName}
    </Typography>
  );
};

type SearchProductsProps = {
  hospitalHashId: string;
};

export const SearchProducts: React.FC<SearchProductsProps> = (props) => {
  const {hospitalHashId} = props;
  const classes = useStyles();

  const [searchName, setSearchName] = useDebounceState<string>('', 500);
  const [rootCategory, setRootCategory] = useDebounceState<string | null>(null, 500);
  const [narrowCategory, setNarrowCategory] = useDebounceState<string | null>(null, 500);
  const [resetNarrowCategoryValue, setResetNarrowCategoryValue] = useState<boolean>(false);
  const isProductSelected = useAtomValue(isProductSelectedAtom);

  const [broadCategoryHashId, setBroadCategoryHashId] = useState<null | string>(null);
  const {data: descendantCategories} = useGetHospitalDescendantCategories(
    hospitalHashId,
    broadCategoryHashId ?? undefined
  );
  const {data: allRootCategory} = useGetHospitalCategories(hospitalHashId, {depth: 0});
  const {data: allNarrowCategory} = useGetHospitalCategories(hospitalHashId, {depth: 1});

  const setSelectedProducts = useSetAtom(selectedProductsAtom);

  const [tableLayout] = useTableLayout('newInspectionProductsList');
  const serializedTableColumn = useMemo(() => {
    const tableColumn = Object.assign<Column<ProductElement>[], TableLayout[]>([], tableLayout?.currentLayout);
    return tableColumn.map((item) => {
      if (item.field === 'productName') {
        item.render = ProductNameColumn;
      }

      return item;
    });
  }, [tableLayout]);

  const params = useMemo(() => {
    const p: GetHospitalWholeProductParam = {
      page: 0,
      perPage: 20,
    };

    if (searchName) {
      p.name = searchName;
    }

    if (narrowCategory !== null) {
      p.categoryHashId = narrowCategory;
    } else if (rootCategory !== null) {
      p.categoryHashId = rootCategory;
    }

    return p;
  }, [searchName, rootCategory, narrowCategory]);

  const {data, isLoading} = useHospitalWholeProductsQuery(hospitalHashId, params);

  const tableElements = useMemo<ProductElement[]>(() => {
    return (data?.data ?? []).map((p) => {
      return {
        ...p,
        name: p.name,
        displayName: p.displayName,
        tableData: {
          checked: isProductSelected[p.hashId] ?? false,
        },
      };
    });
  }, [data?.data, isProductSelected]);

  const handleChangeSearchName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchName(e.target.value);
    },
    [setSearchName]
  );

  const handleChangeRootCategory = useCallback(
    (row: SelectOptionProps | undefined) => {
      setRootCategory(row?.value ?? null);
      setBroadCategoryHashId(row?.value ?? null);
      setNarrowCategory(null);
      setResetNarrowCategoryValue(true);
    },
    [setNarrowCategory, setRootCategory]
  );

  const handleChangeNarrowCategory = useCallback(
    (row: SelectOptionProps | undefined) => {
      setNarrowCategory(row?.value ?? null);
      setResetNarrowCategoryValue(false);
    },
    [setNarrowCategory]
  );

  const handleSelectionChange = useCallback(
    (element: ProductElement[]) => {
      setSelectedProducts(element);
    },
    [setSelectedProducts]
  );

  return (
    <Grid container wrap="nowrap" className={classes.container}>
      <Grid item container className={classes.selectProductsHeader}>
        <Grid item>
          <TextField
            label="機種名・型式で検索"
            size="small"
            variant="outlined"
            InputProps={{
              endAdornment: <Search />,
            }}
            defaultValue={searchName}
            onChange={handleChangeSearchName}
            style={{minWidth: '240px', fontSize: 14}}
          />
        </Grid>
        <Grid item style={{marginLeft: '40px'}}>
          <PopperSelectBoxButton
            buttonLabel="大分類"
            options={CategoryFormatter.getOptions(allRootCategory)}
            isMulti={false}
            onChange={handleChangeRootCategory}
            searchable={true}
          />
        </Grid>
        <Grid item>
          <PopperSelectBoxButton
            buttonLabel="小分類"
            options={CategoryFormatter.getOptions(descendantCategories ?? allNarrowCategory)}
            isMulti={false}
            onChange={handleChangeNarrowCategory}
            searchable={true}
            resetValue={resetNarrowCategoryValue}
          />
        </Grid>
      </Grid>
      <Grid item className={classes.tableContainer}>
        <Box className={classes.tableScrollable}>
          <Table<ProductElement>
            columns={serializedTableColumn}
            showSelection={true}
            selectionButtons={[]}
            isLoading={isLoading}
            data={tableElements}
            onSelectionChange={handleSelectionChange}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  selectProductsHeader: {
    padding: '0px 40px 0px',
    display: 'flex',
    alignItems: 'center',
    flexGrow: 0,
    flexShrink: 1,
  },
  tableContainer: {
    paddingTop: '32px',
    flex: '1 1',
    overflow: 'hidden',
  },
  tableScrollable: {
    height: '100%',
    overflowY: 'auto',
  },
}));
