import React, {ComponentType, useCallback, useMemo} from 'react';
import {FieldProps, useFormikContext} from 'formik';
import wrapField from './wrapField';
import FormControl from '@material-ui/core/FormControl';
import {ActionMeta, ValueType} from 'react-select';
import {Props as CreatableProps} from 'react-select/creatable';
import {theme} from '@atoms/theme';
import RawCreatableSelector from 'react-select/creatable';

// XXX: Creatableをinportすると型エラーが発生するので暫定的な対応
const CreatableSelectorComponent = RawCreatableSelector as unknown as React.FC<CreatableProps<Option, false>>;

type Option = {
  label: string;
  value: string | number | boolean;
};

type Props<M extends boolean> = {
  label: string;
  options: Option[];
  placeholder?: string;
  isDisabled?: boolean;
  onChange?: (value: ValueType<Option, false>, isNew: boolean) => void;
} & Omit<CreatableProps<Option, M>, 'onChange'>;

export function CreatableSelector(props: Props<false>) {
  const {field, options, onChange, isDisabled, ...rest} = props;

  const {setFieldValue} = useFormikContext();
  const value = useMemo(
    () =>
      field.value !== undefined && field.value !== null ? options.find((option) => option.value === field.value) : '',
    [field.value, options]
  );

  const handleChange = useCallback(
    (option: Option | null, actionMeta: ActionMeta<Option>) => {
      const isNew = actionMeta.action === 'create-option';

      if (onChange) onChange(option, isNew);

      setFieldValue(field.name, option?.value);
    },
    [field.name, onChange, setFieldValue]
  );

  return (
    <div>
      <FormControl variant="outlined" size={'small'} fullWidth>
        <CreatableSelectorComponent
          menuPortalTarget={document.body}
          styles={{
            menuPortal: (provided) => ({...provided, zIndex: theme.zIndex.modal + 1}),
          }}
          name={field.name}
          // biome-ignore lint/suspicious/noExplicitAny: <explanation>
          value={value as any}
          options={options}
          onChange={handleChange}
          placeholder={props.placeholder ?? '検索...'}
          formatCreateLabel={(userInput) => `"${userInput}"を追加`}
          isDisabled={isDisabled}
          {...rest}
        />
      </FormControl>
    </div>
  );
}

export default wrapField(CreatableSelector as unknown as ComponentType<FieldProps>);
