import React from 'react';
import {FaultReceptionStatus} from '@modules/fault_receptions/types';
import {Theme, styled} from '@material-ui/core';

type FaultReceptionStatusBadgeProps = {
  status: FaultReceptionStatus;
};

const RootDiv = styled('div')({
  minWidth: '100px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: ' 0px 8px',
  gap: '8px',
});

const StatusBase = styled('div')({
  flex: '0 0 80px',
  borderRadius: '40px',
  padding: '2px 0',
  fontSize: '12px',
  textAlign: 'center',
  borderWidth: '1px',
  borderStyle: 'solid',
});

const Status = styled(StatusBase)(({theme, type}: {theme?: Theme; type: FaultReceptionStatus}) => ({
  ...(type === 'before_confirmation' && {
    background: '#F5F5F5',
    color: '#616161',
    borderColor: '#616161',
  }),
  ...(type === 'completed_confirmation' && {
    background: '#EAF5EA',
    color: '#3B873E',
    borderColor: '#3B873E',
  }),
}));

const StatusText: {[key in FaultReceptionStatus]: string} = {
  before_confirmation: '確認前',
  completed_confirmation: '確認完了',
};

/**
 * 不具合報告のステータス表示
 * @param status  FaultReceptionStatusを渡す
 * @returns
 */
export const FaultReceptionStatusBadge = ({status}: FaultReceptionStatusBadgeProps) => {
  return (
    <RootDiv>
      <Status type={status}>{StatusText[status]}</Status>
    </RootDiv>
  );
};
