import React from 'react';
import {Box, Button, CSSObject, ClickAwayListener, Pagination, Paper, Popper, Typography, styled} from '@mui/material';
import {SearchBar} from '@molecules/SearchBar';
import {useWholeProductPopperButton} from '../hooks';
import {ArrowDropDown, Close} from '@mui/icons-material';

export const WholeProductPopperButton = () => {
  const {
    buttonRef,
    displayButtonLabel,
    handleClick,
    open,
    searchText,
    handleInputChange,
    handleItemClick,
    wholeProduct,
    wholeProductHashId,
    handlePageChange,
    totalPage,
    totalCount,
    startDisplayPosition,
    endDisplayPosition,
    page,
    handleClose,
    handleClear,
    isCleared,
  } = useWholeProductPopperButton();

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <Button sx={ButtonCss(wholeProductHashId)} onClick={handleClick} ref={buttonRef}>
          {displayButtonLabel}
          <ArrowDropDown fontSize="small" />
          {isCleared && (
            <Close
              titleAccess="clear"
              fontSize="small"
              onClick={(e) => {
                handleClear();
                e.stopPropagation();
              }}
            />
          )}
        </Button>
        <Popper open={open} anchorEl={buttonRef.current} style={{zIndex: 1}} sx={{width: '472px'}}>
          <BasePaper>
            <Box sx={{padding: '16px'}}>
              <SearchBar
                placeholder="機種名・型式で検索"
                searchName={searchText}
                backgroundColor="white"
                onChangeSearchName={handleInputChange}
              />
            </Box>
            <div style={{maxHeight: '300px', overflowY: 'auto'}}>
              {wholeProduct.map((data) => (
                <Typography
                  key={data.hashId}
                  sx={{
                    padding: '8px 16px',
                    cursor: 'pointer',
                    '&&:hover': {
                      backgroundColor: '#e5f5f8',
                    },
                  }}
                  onClick={() => {
                    handleItemClick(data.hashId, data.name);
                  }}>
                  {formatWholeProductItemLabel(data.displayName, data.name)}
                </Typography>
              ))}
            </div>
            <SearchBarRoot>
              {totalCount}件のうち{startDisplayPosition}件目-{endDisplayPosition}件目までを表示しています。
              <Pagination
                page={page}
                count={totalPage}
                onChange={(_, newPage) => handlePageChange(newPage)}
                shape="rounded"
              />
            </SearchBarRoot>
          </BasePaper>
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

const SearchBarRoot = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const BasePaper = styled(Paper)({
  border: '1px solid',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'white',
});

const ButtonCss = (wholeProductHashId?: string): CSSObject => {
  const backgroundColor = wholeProductHashId && '#D6E8FC';
  return {
    color: '#003FB5',
    fontWeight: '700',
    backgroundColor: backgroundColor,
    '&&:hover': {
      backgroundColor: backgroundColor,
    },
  };
};

const formatWholeProductItemLabel = (displayName: string, name: string) => {
  if (displayName && name) {
    return changeText(`${displayName}・${name}`, 25);
  } else {
    return changeText(`${displayName}${name}`, 25);
  }
};

const changeText = (text: string, maxLength: number) =>
  text.length > maxLength ? text.substring(0, maxLength - 1) + '…' : text;
