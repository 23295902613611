import React from 'react';
import {Button, styled} from '@mui/material';
import {StrUtil} from '@front-libs/helpers';

type Props = {
  label: string;
  onChange: () => void;
  maxFontSize?: number;
};

export const SquareButton = ({label, onChange, maxFontSize = 64}: Props) => {
  return (
    <StyledButton
      onClick={onChange}
      data-testid={'menu-card'}
      //clampの相対値は右記のような計算式 48px (フォントサイズ) / 1280px (対象画面サイズ) * 100
      sx={{fontSize: `clamp(${maxFontSize / 1.75}px,${(maxFontSize / 1280) * 100}vmax, ${maxFontSize}px)`}}>
      {StrUtil.nl2br(label)}
    </StyledButton>
  );
};
const StyledButton = styled(Button)(({theme}) => ({
  borderRadius: '40px',
  boxShadow: '0px 8px 0px 0px rgba(209, 216, 245, 1)',
  '&:active': {
    color: theme.palette.rent.light,
  },
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: 'bold',
  textAlign: 'center',
  color: theme.palette.rent.main,
  backgroundColor: 'white',
  lineHeight: '1.3em',
}));
