import React from 'react';
import {styled, Box, DialogTitle, DialogActions, Button} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';

type AlertDialogProps = {
  title: string;
  content: React.ReactNode;
  buttonText: string;
  onBack: () => void;
};

const Message = styled(Box)({
  marginTop: '24px',
  '& .MuiTypography-root': {
    fontSize: 'inherit',
  },
});

const Actions = styled(DialogActions)({
  marginTop: '40px',
});

export const ErrorMessage = ({title, content, buttonText, onBack}: AlertDialogProps) => {
  return (
    <>
      <DialogTitle sx={{display: 'flex', alignItems: 'center', padding: 0, minWidth: '550px', fontWeight: 700}}>
        <ErrorIcon sx={{marginRight: '8px', color: '#C7243A'}} />
        {title}
      </DialogTitle>
      <Message>{content}</Message>
      <Actions>
        <Button variant="text" sx={{color: '#0052CC'}} onClick={onBack}>
          {buttonText}
        </Button>
      </Actions>
    </>
  );
};
