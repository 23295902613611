import {styled} from '@mui/material/styles';
import MDialogActions from '@mui/material/DialogActions';

export const DialogActions = styled(MDialogActions)({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: '16px',
  padding: '32px',
  margin: 0,
});
