import {ListPageLayout} from '@components/layouts/ListPageLayout';
import {TableViewLayout} from '@components/layouts/TableViewLayout';
import {WholeProductsListContentBody} from './ContentBody';
import {WholeProductsListContentFooter} from './ContentFooter';
import {WholeProductsListContentHeader} from './ContentHeader';
import React from 'react';

/**
 * 機種一覧ページ機種テーブル
 */
export const WholeProductsListContent = () => {
  return (
    <ListPageLayout.Content>
      <TableViewLayout>
        <WholeProductsListContentHeader />
        <WholeProductsListContentBody />
        <WholeProductsListContentFooter />
      </TableViewLayout>
    </ListPageLayout.Content>
  );
};
