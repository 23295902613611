import React, {useCallback, useState} from 'react';
import clsx from 'clsx';
import {Button, Grid, makeStyles, Theme, Typography} from '@material-ui/core';
import {ChevronLeft} from '@material-ui/icons';
import {ConditionValue, DateRangeConditionValue, FilterOption, NumConditionValue, NumberConditionValue} from './types';
import {DrawerFieldSelector} from './DrawerFieldSelector';
import {isNullish} from '@front-libs/helpers';

type FilterBodyProps = {
  selectedOption: FilterOption;
  defaultCondition?: ConditionValue;
  onAddFilter: (key: string, value: ConditionValue) => void;
  onSubmit: () => void;
  onClickReturn: () => void;
};

export const EditCondition: React.FC<FilterBodyProps> = (props) => {
  const {selectedOption, defaultCondition, onAddFilter, onClickReturn, onSubmit} = props;
  const classes = useStyles();
  const [disabled, setDisabled] = useState(true);

  const handleChangeTextFilter = useCallback(
    (value: string) => {
      if (value === '') {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
      onAddFilter(selectedOption?.value, value);
    },
    [onAddFilter, selectedOption?.value]
  );

  const handleChangeSelectorFilter = useCallback(
    (values: {label: string; value: unknown}[]) => {
      if (values.length > 0) {
        onAddFilter(selectedOption?.value, values);
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    },
    [onAddFilter, selectedOption?.value]
  );

  const handleChangeDateRangeFilter = useCallback(
    (values: DateRangeConditionValue) => {
      if (values.from !== undefined || values.to !== undefined) {
        onAddFilter(selectedOption?.value, values);
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    },
    [onAddFilter, selectedOption?.value]
  );

  const handleChangeNumberFilter = useCallback(
    (values: NumberConditionValue) => {
      if (
        isNullish(values.from) ||
        isNullish(values.to) ||
        (!isNullish(values.from) && !isNullish(values.to) && values.from <= values.to)
      ) {
        onAddFilter(selectedOption?.value, values);
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    },
    [onAddFilter, selectedOption?.value]
  );

  const handleUpdateNumFilter = useCallback(
    (value: NumConditionValue) => {
      onAddFilter(selectedOption?.value, value);
    },
    [onAddFilter, selectedOption?.value]
  );

  const handleCancel = useCallback(() => {
    onClickReturn();
  }, [onClickReturn]);

  return (
    <Grid container className={classes.root}>
      <Grid container>
        <Button color="inherit" className={classes.actionMenu} onClick={handleCancel}>
          <ChevronLeft />
          <span>戻る</span>
        </Button>
      </Grid>
      <Typography style={{fontWeight: 'bold', fontSize: '18px', paddingBottom: '24px'}}>
        {selectedOption.label}
      </Typography>
      <DrawerFieldSelector
        option={selectedOption}
        defaultCondition={defaultCondition}
        onChangeTextFilter={handleChangeTextFilter}
        onChangeSelectorFilter={handleChangeSelectorFilter}
        onChangeDateRangeFilter={handleChangeDateRangeFilter}
        onChangeNumberFilter={handleChangeNumberFilter}
        onUpdateNumFilter={handleUpdateNumFilter}
        onSubmit={onSubmit}
      />
      <Grid container>
        <Button
          onClick={onSubmit}
          color="inherit"
          disabled={disabled}
          className={disabled ? clsx(classes.submitButtonDisabled, classes.submitButton) : classes.submitButton}>
          他の条件を追加
        </Button>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '400px',
    padding: '32px 32px 0',
  },
  actionMenu: {
    padding: 0,
    marginBottom: '16px',
    marginLeft: '-12px',
    color: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
  submitButton: {
    backgroundColor: '#323F4E',
    borderRadius: '2px',
    padding: '8px 22px',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: '#323F4E',
    },
  },
  submitButtonDisabled: {
    backgroundColor: '#EBF0F5 !important',
    color: '#B4C0D3 !important',
  },
}));
