import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import {styled, Box, Button, Dialog, DialogActions, DialogTitle, IconButton, Typography} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import {openSnackBar} from '@molecules/SnackBar';
import {HospitalProductNoteSettingsOptions} from '@modules/hospital_products/types';
import {AlertDialog} from './AlertDialog';
import {SelectTypeOptionCreateForm} from './SelectTypeOptionCreateForm';
import {NoteFieldDataTypeLabels} from './constants';
import {NoteField} from './types';

type SelectTypeOptionCreateDialogProps = Omit<DialogProps, 'open'> & {
  open: boolean | undefined;
  noteField: NoteField;
  onSubmit: (option: HospitalProductNoteSettingsOptions) => void;
};

const subDialogId = 'select-type-option-create-sub-dialog';
const closeButtonId = 'select-type-option-create-dialog-close-button';

const Description = styled(Box)({
  marginTop: '32px',
  '& .MuiTypography-root': {
    fontSize: 'inherit',
  },
});

const InputForm = styled(Box)({
  marginTop: '24px',
});

const Annotation = styled(Box)({
  marginTop: '24px',
  '& .MuiTypography-root': {
    fontSize: 'inherit',
  },
});

const Actions = styled(DialogActions)({
  marginTop: '40px',
  '& > button:nth-of-type(n+2)': {
    marginLeft: '16px',
  },
});

export const SelectTypeOptionCreateDialog = ({
  open,
  actions,
  noteField,
  onSubmit,
}: SelectTypeOptionCreateDialogProps) => {
  const [options, setOptions] = useState<string[]>(['']);
  const canSubmit = !options.includes('');

  const optionsToHospitalProductNoteSettingsOptions = (): HospitalProductNoteSettingsOptions => {
    return {values: options.map((option, i) => ({value: i, name: option}))};
  };

  const openDataTypeChangeAlertDialog = () => {
    const mountPoint = document.getElementById(subDialogId);
    if (!mountPoint) return;

    const closeButton = document.getElementById(closeButtonId);
    if (closeButton) closeButton.style.visibility = 'hidden';

    return new Promise<boolean>((resolve, reject) => {
      const handleResolve = async (res: boolean) => {
        await closeDataTypeChangeAlertDialog();
        resolve(res);
      };
      const handleReject = async () => {
        await closeDataTypeChangeAlertDialog();
        reject();
      };
      const actions = {resolve: handleResolve, reject: handleReject};
      ReactDOM.render(
        <AlertDialog
          open={true}
          actions={actions}
          title="データタイプの変更"
          content={
            <>
              <Typography>この操作を実行すると、すべての機器詳細情報から以下の情報が削除されます。</Typography>
              <br />
              <Typography>
                ・「{noteField.name}」の{NoteFieldDataTypeLabels[noteField.type]}された情報
              </Typography>
              <br />
              <Typography>データタイプの変更を実行しますか？</Typography>
              <Typography>この操作は元に戻せません。</Typography>
            </>
          }
        />,
        mountPoint
      );
    });
  };
  const closeDataTypeChangeAlertDialog = () => {
    const mountPoint = document.getElementById(subDialogId);
    if (!mountPoint) return;

    const closeButton = document.getElementById(closeButtonId);
    if (closeButton) closeButton.style.visibility = 'unset';

    ReactDOM.unmountComponentAtNode(mountPoint);
  };

  const handleChangeOptions = (newOptions: string[]) => {
    setOptions(newOptions);
  };

  const handleClose = () => {
    actions.reject();
  };

  const handleCancel = () => {
    actions.reject();
  };

  const handleSubmit = async () => {
    // 重複チェック
    const optionsSet = new Set(options);
    if (optionsSet.size !== options.length) {
      openSnackBar('複数の選択項目に同じ内容が記入されています', 'left', 'bottom', 'error');
      return;
    }

    // データタイプ変更アラート表示
    try {
      await openDataTypeChangeAlertDialog();
    } catch (_e) {
      return;
    }

    onSubmit(optionsToHospitalProductNoteSettingsOptions());
    actions.resolve(true);
  };

  return (
    <Dialog
      maxWidth="lg"
      open={!!open}
      PaperProps={{
        sx: {position: 'relative', minWidth: '586px', padding: '32px', fontSize: '14px', color: ' #172B4D'},
      }}>
      <DialogTitle sx={{padding: 0, fontWeight: 700}}>単一選択の設定</DialogTitle>
      <IconButton
        id={closeButtonId}
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          fontSize: '24px',
          right: '16px',
          top: '24px',
          color: 'inherit',
        }}>
        <CloseIcon />
      </IconButton>
      <Description>
        <Typography>単一選択をデータタイプに設定すると、事前に登録した選択項目から</Typography>
        <Typography>機器ごとに１つ選択し、詳細情報として表示することができます。</Typography>
        <br />
        <Typography>「{noteField.name}」の選択項目を登録して下さい。</Typography>
      </Description>
      <InputForm>
        <SelectTypeOptionCreateForm options={options} onChange={handleChangeOptions} />
      </InputForm>
      <Annotation>
        <Typography>※空欄の選択項目がある場合、登録できません。</Typography>
      </Annotation>
      <Actions>
        <Button
          variant="contained"
          sx={{backgroundColor: '#0052CC', '&.Mui-disabled': {color: '#FFFFFF'}}}
          disabled={!canSubmit}
          onClick={handleSubmit}>
          登録
        </Button>
        <Button variant="text" sx={{color: '#0052CC'}} onClick={handleCancel}>
          キャンセル
        </Button>
      </Actions>
      <div id={subDialogId} />
    </Dialog>
  );
};
