import React, {useCallback, useMemo} from 'react';
import {styled} from '@material-ui/core/styles';
import {Header} from '@components/organisms/Header/sp';
import {Footer} from '@components/organisms/Footer/sp';
import {ProductHeader} from './ProductHeader';
import {FetchHospitalProductsParams, useFetchInfiniteHospitalProductsQuery} from '@modules/hospital_products/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {isNullish} from '@front-libs/helpers';
import {MenuItemType} from '@components/molecules/Buttons/PopperMenuButton';
import InfiniteScroll from 'react-infinite-scroller';
import Grid from '@material-ui/core/Grid';
import {HospitalProductIndex} from '@modules/hospital_products/types';
import {Product} from './Products';
import {useAtom, useAtomValue} from 'jotai';
import {searchRootCategoriesAtom, searchStatusesAtom, searchSubCategoriesAtom} from '../pc/jotai';
import {orderAtom, searchNameAtom} from './jotai';

const StyledFixedDiv = styled('div')({
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 100,
  backgroundColor: 'white',
  width: '100%',
});

const StyledRootDiv = styled('div')({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  overflow: 'hidden',
  backgroundColor: 'white',
});

const StyledMainContent = styled('div')({
  height: 'calc(100vh - 58px - 134px)',
  backgroundColor: 'white',
  width: '100%',
  paddingTop: '134px',
  paddingBottom: '58px',
  overflowX: 'hidden',
  overflowY: 'auto',
});

/**
 * スマホ用機器一覧
 * @returns
 */
export const ProductList = () => {
  const {myInfo} = useMyInfo();
  const page = 0;
  const pageSize = 100;
  const [name, setName] = useAtom(searchNameAtom);
  const [orderKey, setOrderKey] = useAtom(orderAtom);
  const statuses = useAtomValue(searchStatusesAtom);
  const rootCategories = useAtomValue(searchRootCategoriesAtom);
  const subCategories = useAtomValue(searchSubCategoriesAtom);

  const params = useMemo(() => {
    const _p: FetchHospitalProductsParams = {
      page: page,
      perPage: pageSize,
      order: orderKey,
      statuses: statuses && statuses.length > 0 ? statuses.map((item) => item.value).join(',') : undefined,
      categoryHashIds:
        subCategories.length > 0
          ? subCategories.map((item) => item.value).join(',')
          : rootCategories.length > 0
            ? rootCategories.map((item) => item.value).join(',')
            : undefined,
    };
    if (!isNullish(name) && name !== '') {
      _p.name = name;
    }
    return _p;
  }, [page, pageSize, orderKey, statuses, subCategories, rootCategories, name]);

  const {data, hasNextPage, fetchNextPage} = useFetchInfiniteHospitalProductsQuery(myInfo.hospitalHashId, params);

  const handleNameChange = useCallback(
    async (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      await fetchNextPage({pageParam: 0});
      if (e.target.value === '') {
        setName(null);
      }
      setName(e.target.value);
    },
    [fetchNextPage, setName]
  );

  const handleOrderChange = useCallback(
    (item: MenuItemType) => {
      setOrderKey(item.value);
      fetchNextPage({pageParam: 0});
    },
    [fetchNextPage, setOrderKey]
  );

  return (
    <StyledRootDiv>
      <StyledFixedDiv>
        <Header title="機器" />
        <ProductHeader handleNameChange={handleNameChange} handleOrderChange={handleOrderChange} searchName={name} />
      </StyledFixedDiv>
      <StyledMainContent>
        <InfiniteScroll loadMore={(_page) => fetchNextPage({pageParam: _page})} hasMore={hasNextPage}>
          <Grid container direction="column">
            {data?.pages.map((_page, i) =>
              _page.data.map((item: HospitalProductIndex, index: number) => (
                <Product hospitalProduct={item} key={`Product_${i}_${index}`} />
              ))
            )}
          </Grid>
        </InfiniteScroll>
      </StyledMainContent>
      <Footer />
    </StyledRootDiv>
  );
};
