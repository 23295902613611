import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import {styled, Box, Button, Dialog, DialogActions, DialogTitle, IconButton, Typography} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import {openSnackBar} from '@molecules/SnackBar';
import {
  HospitalProductNoteSettingsOptions,
  HospitalProductNoteSettingsOptionsValue,
} from '@modules/hospital_products/types';
import {AlertDialog} from './AlertDialog';
import {SelectTypeOptionEditForm} from './SelectTypeOptionEditForm';
import {NoteField} from './types';

type SelectTypeOptionEditDialogProps = Omit<DialogProps, 'open'> & {
  open: boolean | undefined;
  noteField: NoteField;
  onSubmit: (option: HospitalProductNoteSettingsOptions) => void;
};

const subDialogId = 'select-type-option-edit-sub-dialog';
const closeButtonId = 'select-type-option-edit-dialog-close-button';

const InputForm = styled(Box)({
  marginTop: '24px',
});

const Annotation = styled(Box)({
  marginTop: '24px',
  '& .MuiTypography-root': {
    fontSize: 'inherit',
  },
});

const Actions = styled(DialogActions)({
  marginTop: '40px',
  '& > button:nth-of-type(n+2)': {
    marginLeft: '16px',
  },
});

export const SelectTypeOptionEditDialog = ({open, actions, noteField, onSubmit}: SelectTypeOptionEditDialogProps) => {
  const [options, setOptions] = useState<HospitalProductNoteSettingsOptionsValue[]>(noteField.option?.values ?? []);
  const canSubmit = !options.some((v) => v.name === '');

  const newHospitalProductNoteSettingsOptionsValue = (): HospitalProductNoteSettingsOptionsValue => {
    // NOTE: 未採番のvalueは負の値を使用し、submitする際に採番する。Date.now()を使うことで他の項目との重複を防ぐ。
    return {value: -Date.now(), name: ''};
  };

  const isNewOption = (option: HospitalProductNoteSettingsOptionsValue) => {
    return option.value < 0;
  };

  const optionsToHospitalProductNoteSettingsOptions = (): HospitalProductNoteSettingsOptions => {
    // 変更前のvalueの最大値を取得
    let maxValue = -1;
    if (noteField.option) {
      maxValue = Math.max(...noteField.option.values.map((v) => v.value));
    }
    // 未採番のobjectにvalue番号を採番する
    const newOptions = options.map((v) => {
      if (isNewOption(v)) {
        maxValue++;
        v.value = maxValue;
      }
      return v;
    });
    return {values: newOptions};
  };

  const handleNewOption = (): HospitalProductNoteSettingsOptionsValue => {
    return newHospitalProductNoteSettingsOptionsValue();
  };

  const handleChangeOptions = (newOptions: HospitalProductNoteSettingsOptionsValue[]) => {
    setOptions(newOptions);
  };

  const openRemoveAlertDialog = (optionName: string) => {
    const mountPoint = document.getElementById(subDialogId);
    if (!mountPoint) return;

    const closeButton = document.getElementById(closeButtonId);
    if (closeButton) closeButton.style.visibility = 'hidden';

    return new Promise<boolean>((resolve, reject) => {
      const handleResolve = async (res: boolean) => {
        await closeRemoveAlertDialog();
        resolve(res);
      };
      const handleReject = async () => {
        await closeRemoveAlertDialog();
        reject();
      };
      const actions = {resolve: handleResolve, reject: handleReject};
      ReactDOM.render(
        <AlertDialog
          open={true}
          actions={actions}
          title="登録済みの選択項目を削除"
          content={
            <>
              <Typography>この操作を実行すると、すべての機器詳細情報から以下の情報が削除されます。</Typography>
              <br />
              <Typography>
                ・「{noteField.name}」の選択項目「{optionName}」
              </Typography>
              <br />
              <Typography>選択項目の削除を実行しますか？</Typography>
              <Typography>この操作は元に戻せません。</Typography>
            </>
          }
        />,
        mountPoint
      );
    });
  };
  const closeRemoveAlertDialog = () => {
    const mountPoint = document.getElementById(subDialogId);
    if (!mountPoint) return;

    const closeButton = document.getElementById(closeButtonId);
    if (closeButton) closeButton.style.visibility = 'unset';

    ReactDOM.unmountComponentAtNode(mountPoint);
  };

  // 指定したvalueの「編集前の選択項目」を取得する
  const findOriginalOption = (value: number): HospitalProductNoteSettingsOptionsValue | undefined => {
    const opts = noteField.option?.values ?? [];
    return opts.find((v) => v.value === value);
  };

  const handleRemoveOption = async (option: HospitalProductNoteSettingsOptionsValue) => {
    // 新規追加の項目はアラート表示不要
    if (isNewOption(option)) return true;

    const orgOpt = findOriginalOption(option.value);
    if (!orgOpt) return false;

    try {
      await openRemoveAlertDialog(orgOpt.name);
    } catch (_e) {
      return false;
    }
    return true;
  };

  const handleClose = () => {
    actions.reject();
  };

  const handleCancel = () => {
    actions.reject();
  };

  const handleSubmit = () => {
    // 重複チェック
    const optionNames = options.map((v) => v.name);
    const optionsSet = new Set(optionNames);
    if (optionsSet.size !== optionNames.length) {
      openSnackBar('複数の選択項目に同じ内容が記入されています', 'left', 'bottom', 'error');
      return;
    }

    onSubmit(optionsToHospitalProductNoteSettingsOptions());
    actions.resolve(true);
  };

  return (
    <Dialog
      maxWidth="lg"
      open={!!open}
      PaperProps={{
        sx: {position: 'relative', minWidth: '586px', padding: '32px', fontSize: '14px', color: ' #172B4D'},
      }}>
      <DialogTitle sx={{padding: 0, fontWeight: 700}}>{`「${noteField.name}」の選択項目を編集`}</DialogTitle>
      <IconButton
        id={closeButtonId}
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          fontSize: '24px',
          right: '16px',
          top: '24px',
          color: 'inherit',
        }}>
        <CloseIcon />
      </IconButton>
      <InputForm>
        <SelectTypeOptionEditForm
          options={options}
          onNew={handleNewOption}
          onChange={handleChangeOptions}
          onRemove={handleRemoveOption}
        />
      </InputForm>
      <Annotation>
        <Typography>※空欄の選択項目がある場合、保存できません。</Typography>
      </Annotation>
      <Actions>
        <Button
          variant="contained"
          sx={{backgroundColor: '#0052CC', '&.Mui-disabled': {color: '#FFFFFF'}}}
          disabled={!canSubmit}
          onClick={handleSubmit}>
          保存
        </Button>
        <Button variant="text" sx={{color: '#0052CC'}} onClick={handleCancel}>
          キャンセル
        </Button>
      </Actions>
      <div id={subDialogId} />
    </Dialog>
  );
};
