import {Box, styled} from '@mui/material';
import React, {useRef} from 'react';
import no_image from '@assets/no_image.svg';
import {resizeImage} from '@front-libs/helpers';
import {uploadFile} from '@modules/files/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {updateProduct} from '@modules/products/api';
import {openSnackBar} from '@front-libs/ui';

const StyledInput = styled('input')({
  display: 'none',
});

export const ProductThumbnail = ({src, wholeProductHashId}: {src: string; wholeProductHashId: string}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const {myInfo} = useMyInfo();

  // ボタンのクリック時にファイル選択ダイアログを開く
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // inputタグをプログラム的にクリック
    }
  };

  /**
   * ファイルアップロード
   * @returns
   */
  const fileUpload = async (file: File) => {
    try {
      const newFile = await resizeImage(file, 200);
      const fileRecord = await uploadFile({
        file: newFile,
        fileName: newFile.name,
        category: 'whole_product_thumbnail',
      });

      if (fileRecord.status !== 201) {
        console.warn('API calls successful but file may be not uploaded, data=', +JSON.stringify(fileRecord.data));
        openSnackBar('画像のアップロードに失敗しました', 'error');
        return;
      }
      return fileRecord.data.url;
    } catch (_e) {
      openSnackBar('画像のアップロードに失敗しました', 'error');
      return;
    }
  };

  /**
   * ファイルが選択された際の処理
   */
  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files || files.length < 0) return; // ファイル選択ダイアログでキヤンセルが押された場合
    const file = files[0];

    const url = await fileUpload(file);
    if (!url) return;

    try {
      const res = await updateProduct(myInfo.hospitalHashId, wholeProductHashId, {
        thumbnailUrl: url,
      });
      openSnackBar('画像を設定しました');
      src = res.thumbnailUrl ?? '';
    } catch (_e) {
      openSnackBar('画像の設定に失敗しました', 'error');
      return;
    }
  };

  return (
    <>
      <Box
        component="img"
        sx={{
          width: '76px',
          height: '76px',
          border: '1px solid #D1D5DB,',
          borderRadius: '8px',
          position: 'absolute',
          top: '12px',
          left: '32px',
          cursor: 'pointer',
          objectFit: 'contain',
          backgroundColor: 'white',
        }}
        src={src}
        object-fit="contain"
        loading="lazy"
        decoding="async"
        alt="プロダクトサムネイル"
        onError={(e) => {
          // srcが存在しない場合はno_imageを表示
          e.currentTarget.src = no_image;
        }}
        onClick={(_e) => {
          handleButtonClick();
        }}
      />
      {/* accept="image/*"とすることで画像ファイルしか選択できないように */}
      <StyledInput type="file" accept="image/*" ref={fileInputRef} onChange={handleFileChange} />
    </>
  );
};
