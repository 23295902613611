import React, {useCallback, useMemo, useState} from 'react';
import Selector from '@molecules/Formik/fields/Selector';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import {useFormikContext} from 'formik';
import CloseIcon from '@material-ui/icons/Close';
import {FetchCompaniesParam, useFetchCompaniesQuery} from '@modules/companies/api';
import {CompanyIndex} from '@modules/companies/types';
import {useCategoryQuery} from '@modules/categories/hooks/useCategories';
import {CategoryIndex} from '@modules/categories/types';
import {ValueType} from 'react-select';
import {classNameOpts} from '../../constants';
import {useDescendantCategoryQuery} from '@modules/categories/api/categoryApi';
import {RequiredLabel} from '@molecules/FormRequiredLabel';
import {useDebounceCallback} from '@front-libs/core';
import {openSnackBar} from '@components/molecules/SnackBar';
import CreatableSelector from '@components/molecules/Formik/fields/CreatableSelector';
import {formatApprovalNumber, formatJANCode} from '@modules/hospital_products/utls';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';

type NewRegistrationFormProps = {
  open: boolean;
  onClose: React.MouseEventHandler;
};

export const Step1FormContainer: React.FC<NewRegistrationFormProps> = (props) => {
  const {open, onClose} = props;
  const classes = useStyles();
  const {myInfo} = useMyInfo();

  const {isSubmitting, submitForm, isValid} = useFormikContext();
  const handleSubmit = useCallback(async () => {
    try {
      await submitForm();
      openSnackBar('機種を追加登録しました。');
    } catch (e) {
      console.error(e);
      openSnackBar('機種の追加登録に失敗しました。', 'left', 'bottom', 'error');
    }
  }, [submitForm]);

  const [fetchCompaniesQueryParam, setFetchCompaniesQueryParam] = useState<FetchCompaniesParam>({});
  const {data: companies} = useFetchCompaniesQuery(myInfo.hospitalHashId, fetchCompaniesQueryParam);
  const {rootCategoryQuery} = useCategoryQuery();

  const [selectedRootCategory, setSelectedRootCategory] = useState<string | null>(null);
  const descendantCategory = useDescendantCategoryQuery(selectedRootCategory);

  const handleSearchCompany = useDebounceCallback(
    (name: string) => {
      if (name === '') {
        setFetchCompaniesQueryParam({});
      } else {
        setFetchCompaniesQueryParam({
          ...fetchCompaniesQueryParam,
          name: name,
        });
      }
    },
    100,
    [fetchCompaniesQueryParam]
  );

  return (
    <Dialog open={open} fullWidth={true} maxWidth="md" aria-labelledby="form-dialog-title">
      <DialogTitle className={classes.title} disableTypography>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant="h2">{`機種情報の入力`}</Typography>
          <IconButton onClick={onClose} className={classes.closeButton}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Step1Form
          rootCategories={rootCategoryQuery.data ?? []}
          subCategories={descendantCategory.data ?? []}
          companies={companies?.data ?? []}
          setSelectedRootCategory={setSelectedRootCategory}
          onSearchCompany={handleSearchCompany}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={!isSubmitting && !isValid} variant={'contained'} color="primary" onClick={handleSubmit}>
          登録
        </Button>
        <Button onClick={onClose} color="primary">
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
};

type Step1FormProps = {
  companies: CompanyIndex[];
  rootCategories: CategoryIndex[];
  subCategories: CategoryIndex[];
  setSelectedRootCategory: React.Dispatch<React.SetStateAction<string | null>>;
  onSearchCompany: (name: string) => void;
};

type Step1FormValuesType = {
  name: string | null;
  displayName: string | null;
  janCode: string | null;
  approvalNumber: string | null;
  className: string | null;
  rootCategoryHashId: string | null;
};

export const Step1Form: React.FC<Step1FormProps> = (props) => {
  const classes = useStyles();
  const {companies, rootCategories, subCategories, setSelectedRootCategory, onSearchCompany} = props;

  const {values, handleChange, setFieldValue} = useFormikContext<Step1FormValuesType>();

  useMemo(() => {
    if (values.rootCategoryHashId !== '') {
      setSelectedRootCategory(values.rootCategoryHashId ?? null);
    }
  }, [values.rootCategoryHashId, setSelectedRootCategory]);

  const companyOpts = useMemo(() => {
    return companies.map((c) => ({
      label: c.name,
      value: c.hashId,
    }));
  }, [companies]);

  const rootCategoriesOpts = useMemo(() => {
    return rootCategories.map((c) => ({
      label: c.name,
      value: c.hashId,
    }));
  }, [rootCategories]);

  const subCategoriesOpts = useMemo(() => {
    return subCategories.map((c) => ({
      label: c.name,
      value: c.hashId,
    }));
  }, [subCategories]);

  return (
    <Grid container direction="column" className={classes.fieldContainer}>
      <Grid item>
        <RequiredLabel>大分類</RequiredLabel>
        <Selector
          name="rootCategoryHashId"
          size={'small'}
          options={rootCategoriesOpts}
          onChange={(val: ValueType<{label: string; value: string}, true>) => {
            setFieldValue('rootCategoryHashId', val);
            setFieldValue('subCategoryHashId', null);
          }}
        />
      </Grid>
      <Grid item>
        <RequiredLabel>小分類</RequiredLabel>
        <Selector
          name="subCategoryHashId"
          size={'small'}
          options={subCategoriesOpts}
          onChange={(val: ValueType<{label: string; value: string}, true>) => {
            setFieldValue('subCategoryHashId', val);
          }}
        />
      </Grid>
      <Grid item>
        <label>メーカー名</label>
        <CreatableSelector
          placeholder="テルモ"
          name="makerHashId"
          size={'small'}
          options={companyOpts}
          onChange={(val: ValueType<{label: string; value: string}, false>, isNew: boolean) => {
            if (isNew) {
              setFieldValue('newMakerName', val?.value);
              setFieldValue('makerHashId', null);
            } else {
              setFieldValue('newMakerName', null);
              setFieldValue('makerHashId', val);
            }
          }}
          onInputChange={onSearchCompany}
        />
      </Grid>
      <Grid item>
        <label>型式</label>
        <TextField
          fullWidth
          id="name"
          name="name"
          type="text"
          variant="outlined"
          size={'small'}
          value={values.name}
          onChange={handleChange}
        />
      </Grid>
      <Grid item>
        <label>機種名</label>
        <TextField
          fullWidth
          id="displayName"
          name="displayName"
          type="text"
          variant="outlined"
          size={'small'}
          value={values.displayName}
          onChange={handleChange}
        />
      </Grid>
      <Grid item>
        <label>JANコード</label>
        <TextField
          fullWidth
          id="janCode"
          name="janCode"
          type="text"
          variant="outlined"
          size={'small'}
          value={values.janCode}
          onChange={handleChange}
          onBlur={() => {
            if (values.janCode) {
              setFieldValue('janCode', formatJANCode(values.janCode));
            }
          }}
        />
      </Grid>
      <Grid item>
        <label>クラス分類</label>
        <Selector
          name="className"
          size={'small'}
          options={classNameOpts}
          onChange={(val: ValueType<{label: string; value: string}, true>) => {
            setFieldValue('className', val);
          }}
        />
      </Grid>
      <Grid item>
        <label>承認番号</label>
        <TextField
          fullWidth
          id="approvalNumber"
          name="approvalNumber"
          type="text"
          variant="outlined"
          size={'small'}
          value={values.approvalNumber}
          onBlur={() => {
            if (values.approvalNumber) {
              setFieldValue('approvalNumber', formatApprovalNumber(values.approvalNumber));
            }
          }}
          onChange={handleChange}
        />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    padding: '24px 32px 0px',
    '& h2': {
      color: '#172B4D',
      fontSize: '20px',
      fontWeight: 'bold',
    },
  },
  closeButton: {
    padding: '0px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0px',
    padding: '0px 32px 0px',
    '& > div:not(:first-child)': {
      marginTop: '24px',
    },
    actionButton: {
      border: '1px solid #C6CBD4',
      marginLeft: 'auto',
      backgroundColor: '#F5F6F7',
      marginRight: 8,
    },
  },
  fieldContainer: {
    width: '100%',
    marginBottom: '16px',
    '& > :not(:first-child)': {
      marginTop: '16px',
    },
  },
}));
