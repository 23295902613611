import {useQueries, useQuery} from 'react-query';
import {getFaultReceptionKey} from '@constants/api';
import {getFaultReception} from './api/faultReceptionApi';
import {FaultReception} from './types';

/**
 * 不具合受付の詳細を取得するReactQuery
 * @param hospitalHashId 病院のハッシュID
 * @param faultReceptionHashId 不具合受付のハッシュID
 * @returns 不具合受付の詳細を取得するためのReactQueryオブジェクト
 */
export const useFaultReceptionDetailQuery = (hospitalHashId: string, faultReceptionHashId: string) => {
  return useQuery(
    [getFaultReceptionKey, hospitalHashId, faultReceptionHashId],
    () => getFaultReception(hospitalHashId, faultReceptionHashId),
    {enabled: !!hospitalHashId && !!faultReceptionHashId, refetchOnWindowFocus: false}
  );
};

/**
 * 複数の不具合受付の詳細を取得するReactQuery
 * @param hospitalHashId 病院のハッシュID
 * @param faultReceptionHashIds 不具合受付のハッシュIDの配列
 * @returns 不具合受付の詳細を取得するためのオブジェクト
 */
export const useFaultReceptionDetails = (hospitalHashId: string, faultReceptionHashIds: string[]) => {
  const queries = useQueries(
    faultReceptionHashIds.map((faultReceptionHashId) => ({
      queryKey: ['faultReceptionDetails', hospitalHashId, faultReceptionHashId],
      queryFn: () => getFaultReception(hospitalHashId, faultReceptionHashId),
      enabled: !!hospitalHashId && !!faultReceptionHashId,
    }))
  );
  const data = queries.map((query) => query.data).filter((item): item is FaultReception => item !== undefined);
  return {
    data,
    isLoading: queries.some((query) => query.isLoading),
  };
};
