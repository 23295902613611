import {useCallback, useMemo} from 'react';
import {updateInspection, useFetchInspectionListQuery} from '../inspections/api';
import {InspectionIndex} from '../inspections/types';
import {getInspectionResults} from '../inspection_results/api';
import {AlertDialog} from '@components/molecules/Dialogs/AlertDialog';
import {dialogHandler} from '@components/molecules/Dialogs/DialogHandler';
import {MessageDialog} from '@components/molecules/Dialogs/MessageDialog';
import {InspectionType} from '../inspections/enum';
import {generateInspectionOptions} from './helpers';

const commonFetchInspectionsQueryParam = {
  statuses: 'available',
  perPage: 100,
};

const transformInspectionType = (inspectionType: InspectionType | undefined) => {
  if (inspectionType === 'maker_periodic') {
    throw new Error('maker_periodic is not supported');
  }
  if (inspectionType === 'daily') {
    return 'pre_use,in_use,post_use';
  }
  return inspectionType;
};

/**
 * 病院のハッシュID、検索名、製品のハッシュID、および点検タイプに基づいて点検オプションを取得するカスタムフックです。
 *
 * @param {string} hospitalHashId - 病院のハッシュID。
 * @param {string} searchName - 検索名。空文字列の場合は全ての点検を検索します。
 * @param {string | null} wholeProductHashId - 製品のハッシュID。null の場合、製品特有の点検は考慮されません。
 * @param {InspectionType} [inspectionType] - オプションの点検タイプ。指定された場合、そのタイプの点検のみを検索します。
 * @param {InspectionIndex} [defaultInspection] - デフォルトの点検インデックス。指定された場合、その点検がデフォルトとして選択されます。
 *
 * @returns {{
 *   options: ProductInspectionsOptionsType[];
 *   isLoading: boolean;
 * }} - 点検オプションの配列とデータ取得中かどうかのフラグを返します。
 */
export const useInspectionSelectorOptions = (
  hospitalHashId: string,
  searchName: string,
  wholeProductHashId: string | null,
  inspectionType?: InspectionType,
  defaultInspection?: InspectionIndex
) => {
  const transformedInspectionType = useMemo(() => transformInspectionType(inspectionType), [inspectionType]);

  const allInspectionsQuery = useFetchInspectionListQuery(
    hospitalHashId,
    useMemo(
      () => ({
        ...commonFetchInspectionsQueryParam,
        name: searchName !== '' ? searchName : undefined,
        inspectionType: transformedInspectionType,
      }),
      [inspectionType, searchName]
    )
  );
  // 臨時点検を元にデフォルトの点検表を取得する。
  const productInspectionsQuery = useFetchInspectionListQuery(
    hospitalHashId,
    useMemo(
      () => ({
        ...commonFetchInspectionsQueryParam,
        name: searchName !== '' ? searchName : undefined,
        wholeProductHashId: wholeProductHashId ?? undefined,
        inspectionType: transformedInspectionType,
      }),
      [inspectionType, searchName, wholeProductHashId]
    )
  );

  const options = useMemo(
    () =>
      generateInspectionOptions(
        allInspectionsQuery.data,
        productInspectionsQuery.data,
        wholeProductHashId,
        defaultInspection
      ),
    [allInspectionsQuery.data, productInspectionsQuery.data, wholeProductHashId]
  );

  return {
    options,
    isLoading: allInspectionsQuery.isLoading || productInspectionsQuery.isLoading,
  };
};

export const useChangeToDraft = () => {
  return useCallback(async (hospitalHashId: string, inspection: InspectionIndex) => {
    const relatedResults = await getInspectionResults(hospitalHashId, inspection.hashId, {
      inspectionHashId: inspection.hashId,
      statuses: 'uncompleted',
      perPage: 1,
    });

    if (relatedResults.totalCount > 0) {
      await dialogHandler.open(MessageDialog, {
        content: '実施途中の点検予定と紐づいている点検表は下書きに戻せません',
        positiveButtonLabel: '閉じる',
        isHiddenNegativeButton: true,
      });

      return false;
    }

    await dialogHandler.open(AlertDialog, {
      title: `点検表「${inspection.name}」を下書きに戻す`,
      content: `「${inspection.name}」の点検表を下書きに戻そうとしています`,
      positiveButtonLabel: '下書きに戻す',
      warning: false,
    });

    await updateInspection(hospitalHashId, inspection.hashId, {
      status: 'draft',
    });

    return true;
  }, []);
};
