import React, {useState, useMemo} from 'react';
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions as MDialogActions,
  Button,
  TextField,
} from '@material-ui/core';
import {styled} from '@material-ui/styles';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import {Option, Selector} from '@molecules/Selector';
import {CategoryIndex} from '@modules/categories/types';
import {CategorySelector} from './CategorySelector';
import {CategoryTrees} from '@modules/categories/hooks/useCategoryTrees';
import {isNullish} from '@front-libs/helpers';

type Props = {
  selected: string[];
  categoryTrees: CategoryTrees;
  rootCategories: CategoryIndex[];
  narrowCategories: CategoryIndex[];
} & DialogProps;

export const EditableColumnModal: React.FC<Props> = ({
  categoryTrees,
  rootCategories,
  narrowCategories,
  open,
  selected,
  actions,
}) => {
  const [selectColumn, setColumn] = useState<string>('');
  const [selectValue, setValue] = useState<Record<string, unknown> | null>(null);

  const handleSave = async () => {
    await actions.resolve(selectValue);
  };

  const handleCancel = () => {
    actions.reject();
  };

  const targetColumn = useMemo<Column>(() => {
    // biome-ignore lint/style/noNonNullAssertion: 非nullアサーション演算子(!)
    return columns(categoryTrees, rootCategories, narrowCategories).find((col) => col.name === selectColumn)!;
  }, [categoryTrees, narrowCategories, rootCategories, selectColumn]);

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const handleChange = (type: string, name: string) => (e: React.ChangeEvent<HTMLInputElement> | any) => {
    setValue(null);

    switch (type) {
      case 'text':
        setValue({[name]: e.target.value});
        break;
      case 'number':
        setValue({[name]: e.target.value});
        break;
      case 'date':
        // Memo: DateFieldはdateのstringが渡される。
        setValue({[name]: e});
        break;
      case 'select':
        // Memo: Selectorは{value: any, label: any}が渡される。
        setValue({[name]: e.value});
        break;
      case 'categorySelect':
        // Memo: CategorySelectorからは [string, string] が渡される。
        setValue({rootCategory: e[0] ?? '', narrowCategory: e[1] ?? ''});
        break;
    }
  };

  const handleChangeColumn = (option: Option) => {
    //Memo: 更新する情報を変更した場合は入っている値をNullにする。
    setValue(null);
    setColumn(option.value);
  };

  const canSubmit = useMemo(() => {
    return !!selectColumn;
  }, [selectColumn]);

  return (
    <Dialog open={open} fullWidth maxWidth={'md'} PaperProps={{style: {overflowY: 'visible', maxWidth: '500px'}}}>
      <DialogTitle id="scroll-dialog-title">
        <Title variant="h6">{selected.length}件の機種を一括編集</Title>
      </DialogTitle>
      <DialogContent style={{overflowY: 'visible'}}>
        <Box>
          <div>更新する情報</div>
          <Selector
            value={selectColumn}
            options={columns(categoryTrees, rootCategories, narrowCategories).map((column) => {
              return {
                label: column.label,
                value: column.name,
              };
            })}
            onChange={handleChangeColumn}
            menuPosition={'fixed'}
          />
        </Box>
        {targetColumn && (
          <Box my={3}>
            {!isNullish(targetColumn.showLabel) && targetColumn.showLabel && <div>{targetColumn.label}</div>}
            {React.createElement(targetColumn.InputComponent, {
              name: targetColumn.name,
              onChange: handleChange(targetColumn.type, targetColumn.name),
              value: selectValue ? selectValue[targetColumn.name] : null,
              ...targetColumn.props,
            })}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Box p={2} width={'100%'} display={'flex'} justifyContent={'flex-end'}>
          <Box mr={2}>
            <Button disabled={!canSubmit} color="primary" variant="contained" onClick={handleSave}>
              保存
            </Button>
          </Box>
          <Box>
            <Button color="primary" variant="outlined" onClick={handleCancel}>
              キャンセル
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

type Style = {
  width?: string;
  padding?: number;
};

type InputProps = {
  style?: Style;
};

type ColumnProps = {
  variant?: string;
  style?: Style;
  inputProps?: InputProps;
};

type Column = {
  type: string;
  label: string;
  name: string;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  InputComponent: any;
  props?: ColumnProps;
  showLabel?: boolean;
};

const columns: (
  categoryTrees: CategoryTrees,
  rootCategories: CategoryIndex[],
  narrowCategories: CategoryIndex[]
) => Column[] = (categoryTrees, rootCategories, narrowCategories) => [
  {
    type: 'text',
    label: 'メーカー名',
    name: 'makerName',
    InputComponent: TextField,
    props: {
      variant: 'outlined',
      style: {width: '100%'},
      inputProps: {
        style: {
          padding: 8,
        },
      },
    },
  },
  {
    type: 'categorySelect',
    label: '分類',
    name: 'category',
    InputComponent: CategorySelector,
    props: {
      categoryTrees: categoryTrees,
      rootCategories: rootCategories,
      narrowCategories: narrowCategories,
    },
    showLabel: false,
  },
];

const DialogActions = styled(MDialogActions)({
  background: '#FFF',
  padding: 'initial',
});

const Title = styled(Typography)({
  fontWeight: 'bold',
});
