import React, {useCallback} from 'react';
import {Button, Typography, styled} from '@material-ui/core';
import {AssignmentOutlined} from '@material-ui/icons';

type Props = {
  onAddRelatedContent: () => void;
};

export const InspectionStartIconButton = ({onAddRelatedContent}: Props) => {
  const handleClickBtn = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      onAddRelatedContent();
    },
    [onAddRelatedContent]
  );

  return (
    <StyledButton variant="contained" onClick={handleClickBtn}>
      <AssignmentOutlined style={{width: '16px', height: '16px', margin: '0px 4px', color: '#0052CC'}} />
      <BtnLabelTypography>{'臨時点検を開始'}</BtnLabelTypography>
    </StyledButton>
  );
};

const StyledButton = styled(Button)({
  padding: '0 4px 0 0',
  marginBottom: '16px',
  backgroundColor: 'white',
  whiteSpace: 'nowrap',
  display: 'flex',
  boxShadow: 'none',
  '&:last-child': {
    marginRight: '-4px',
  },
  '&:hover': {
    boxShadow: 'none',
  },
});

const BtnLabelTypography = styled(Typography)({
  fontSize: '14px',
  fontWeight: 400,
  height: '10px',
  lineHeight: '10px',
  margin: '9px 0',
  whiteSpace: 'nowrap',
  color: '#0052CC',
});
