import React from 'react';
import {createStyles, Grid, Button, makeStyles, Theme, Typography, Tooltip, Card} from '@material-ui/core';
import {Badge} from '@atoms/Badge';
import {Error} from '@material-ui/icons';
import {EmptyAnswer} from '../../../common/types';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    card: {
      position: 'absolute',
      padding: '32px',
      minWidth: '300px',
      maxWidth: '600px',
      right: '0px',
      zIndex: 5,
    },
    errorIcon: {
      color: '#C7243A',
    },
    errorText: {
      marginLeft: '8px',
      fontWeight: 'bold',
    },
    errors: {
      width: '100%',
      '& > ul': {
        width: '100%',
        margin: '24px 0px 0px',
        padding: '0px 0px 0px 24px',
        // li改行時の点のずれを修正
        listStyleType: 'disc',
        listStylePosition: 'outside',
      },
      '& > ul > li:not(:first-child)': {
        marginTop: '16px',
      },
    },
    error: {
      fontSize: '14px',
      overflowWrap: 'break-word',
    },
    link: {
      fontWeight: 'bold',
      color: theme.palette.info.dark,
      cursor: 'pointer',
    },
  })
);

type SubmitButtonProps = {
  disabled: boolean;
  showsBadge: boolean;
  emptyAnswers: EmptyAnswer[];
  onClick: React.MouseEventHandler;
};

export const SubmitButton: React.FC<SubmitButtonProps> = (props) => {
  const {disabled, showsBadge, emptyAnswers, onClick} = props;
  const classes = useStyle();

  const ButtonComponent = (
    <Button variant="contained" color="primary" disabled={disabled} onClick={onClick}>
      完了
    </Button>
  );

  if (!showsBadge) {
    return ButtonComponent;
  }

  return (
    <Tooltip
      title={false}
      placement="bottom"
      // eslint-disable-next-line no-shadow
      PopperComponent={(props) =>
        props.open ? (
          <div className={classes.root}>
            <Card className={classes.card}>
              <Grid container direction="column">
                <Grid item container direction="row">
                  <Error className={classes.errorIcon} />
                  <Typography className={classes.errorText}>必須項目が入力されていません</Typography>
                </Grid>
                <Grid item className={classes.errors}>
                  <ul>
                    {emptyAnswers.map((emptyAnswer, idx) => (
                      <li key={idx} className={classes.error}>
                        <Typography display="inline" variant="body2">
                          点検内容
                        </Typography>
                        <Typography display="inline" variant="body2" className={classes.link} onClick={onClick}>
                          {emptyAnswer.fieldName}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                </Grid>
              </Grid>
            </Card>
          </div>
        ) : null
      }>
      <Grid>
        <Badge badgeContent={emptyAnswers.length} color="error">
          {ButtonComponent}
        </Badge>
      </Grid>
    </Tooltip>
  );
};
