import React, {useCallback, useMemo} from 'react';
import {createStyles, Paper, Grid, Tabs, Tab, Divider, makeStyles, Theme} from '@material-ui/core';
import {useAtom} from 'jotai';
import {withFormik, WithFormikProps} from '@front-libs/core';
import {InspectionStatus, InspectionType} from '@modules/inspections/enum';
import {DEFAULT_INSPECTION_NAME, tabAtom} from '../states';
import {tabs, TabKey, FormValue} from '../types';
import {getErrorSummaries} from '../validator';
import {useNavigate} from 'react-router-dom';
import {HeaderInner} from './HeaderInner';

type HeaderProps = {
  inspectionType: InspectionType;
  inspectionStatus: InspectionStatus;
  onChangeToDraft: () => void;
  onSaveDraft: () => void;
};

export const _Header: React.FC<WithFormikProps<FormValue, FormValue, HeaderProps>> = (props) => {
  const {
    isValid,
    isSubmitting,
    inspectionType,
    inspectionStatus,
    values,
    errors,
    setFieldValue,
    setFieldTouched,
    submitForm,
    onSaveDraft,
  } = props;
  const classes = useStyles();
  const [tab, setTab] = useAtom(tabAtom);
  const navigate = useNavigate();
  const goBackPath = '/inspection_v2/tables';

  const handleClickBackToInspections = useCallback(
    (_e: React.MouseEvent) => {
      navigate(goBackPath);
    },
    [goBackPath, navigate]
  );

  const handleChangeTab = useCallback(
    (_, key: string) => {
      setTab(key as TabKey);
    },
    [setTab]
  );

  const handleChangeName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFieldValue('name', e.target.value, true);
    },
    [setFieldValue]
  );

  const handleFocusName = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      if (DEFAULT_INSPECTION_NAME === e.target.value) {
        setFieldValue('name', '');
        setFieldTouched('name', true);
      }
    },
    [setFieldTouched, setFieldValue]
  );

  const handleBlurName = useCallback(
    (_e: React.FocusEvent) => {
      setFieldTouched('name', false);
    },
    [setFieldTouched]
  );

  const handleItemClick = useCallback(() => {
    setTab('items');
  }, [setTab]);

  const onClickPublish = useCallback(() => {
    submitForm();
  }, [submitForm]);

  const errorSummaries = useMemo(() => getErrorSummaries(errors, values), [errors, values]);

  return (
    <Paper elevation={2} square className={classes.root}>
      <Grid container>
        {/* Upper Header */}
        <HeaderInner
          inspectionType={inspectionType}
          status={inspectionStatus as 'available' | 'draft'}
          inspectionName={values.name}
          errors={errors}
          errorSummaries={errorSummaries}
          isValid={isValid}
          isSubmitting={isSubmitting}
          onChangeName={handleChangeName}
          onFocusName={handleFocusName}
          onBlurName={handleBlurName}
          onSaveDraft={onSaveDraft}
          onClickPublish={onClickPublish}
          onFinishWithoutToSave={handleClickBackToInspections}
          onItemClick={handleItemClick}
        />
        <Divider variant="middle" className={classes.divider} />
        {/* Lower Header */}
        <Grid item container justifyContent="center" className={classes.tabsContainer}>
          <Tabs value={tab} onChange={handleChangeTab} indicatorColor="primary" textColor="primary">
            {tabs.map((item) => (
              <Tab key={item.value} label={item.label} value={item.value} />
            ))}
          </Tabs>
        </Grid>
      </Grid>
    </Paper>
  );
};

export const Header = withFormik<FormValue, FormValue, HeaderProps>({
  displayName: 'Header',
  getNames: () => ['name', 'type', 'sections'],
})(_Header);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.palette.common.white,
      position: 'sticky',
      top: 0,
      zIndex: theme.zIndex.appBar,
    },
    navigations: {
      padding: '16px 24px 16px',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        padding: '16px 32px',
      },
    },
    nameInputContainer: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
    },
    nameInput: {
      minWidth: '320px',
      align: 'center',
      textAlign: 'center',
      '& fieldset': {
        borderColor: 'transparent',
      },
      '& input': {
        textAlign: 'center',
        padding: '12px 14px',
      },
      '&:hover fieldset': {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: theme.palette.primary.main,
        borderRadius: '6px',
      },
      [theme.breakpoints.up('tabletH')]: {
        minWidth: '400px',
      },
    },
    rightAction: {
      minWidth: '200px',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      flexWrap: 'nowrap',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    inspectionType: {
      fontSize: '14px',
      fontWeight: 'bold',
      fontColor: '#172B4D',
      marginRight: '24px',
    },
    publishButtonContainer: {
      marginRight: 16,
    },
    divider: {
      margin: '0px 0',
      width: '100%',
    },
    tabsContainer: {
      marginTop: '8px',
    },
  })
);
