import React from 'react';
import {Box, Button, Typography, SxProps} from '@mui/material';
import {ChevronLeft} from '@mui/icons-material';
import {useNavigate} from 'react-router-dom';
import {useFormikContext} from 'formik';
import {FormValue} from '@modules/hospital_ward_layout_setting/types';
import {useAtomValue} from 'jotai';
import {radioAtom} from '../states';
import {DEFAULT_ORDER} from './consts';

type HeaderTopProps = {
  handleClickCancel: () => void;
};

export const HeaderTop = ({handleClickCancel}: HeaderTopProps) => {
  const {submitForm} = useFormikContext<FormValue>();
  const radio = useAtomValue(radioAtom);
  const navigate = useNavigate();

  const onClickPrevButton = () => {
    navigate('/shared');
  };

  const handleClickUpdate = () => {
    submitForm();
  };

  const isValid = radio === DEFAULT_ORDER;

  return (
    <Box display="flex" justifyContent="space-between" alignItems="flex-end" sx={rootStyle}>
      <Box>
        <Button
          variant="text"
          color="primary"
          sx={prevButtonStyle}
          onClick={onClickPrevButton}
          startIcon={<ChevronLeft />}>
          貸出・返却トップへ戻る
        </Button>
        <Box sx={pageTitleStyle}>エリアボタンの並び替え</Box>
      </Box>
      <Box sx={leftButtonBoxStyle}>
        <Button variant="contained" color="primary" disabled={isValid} onClick={handleClickUpdate}>
          変更を保存
        </Button>
        <Button variant="text" color="primary" disabled={isValid} onClick={handleClickCancel}>
          変更をキャンセル
        </Button>
      </Box>
    </Box>
  );
};

const rootStyle: SxProps = {
  padding: '24px 32px 16px 24px',
};

const prevButtonStyle: SxProps = {
  fontSize: '14px',
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: 'inherit',
  },
  '& .MuiButton-startIcon': {
    marginRight: 0,
  },
};

const pageTitleStyle: SxProps = {
  fontSize: '18px',
  fontWeight: 'bold',
  color: '#172B4D',
  marginLeft: '24px',
};

const leftButtonBoxStyle: SxProps = {
  display: 'inline-flex',
  gap: '16px',
};
