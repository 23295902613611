import React, {useMemo} from 'react';
import {Button, Grid} from '@material-ui/core';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import CreateIcon from '@material-ui/icons/Create';
import {useAtom} from 'jotai';
import {splitAtom} from 'jotai/utils';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {EditableColumnModal} from './EditableColumnModal';
import {Product, productsAtom} from '../../../state';
import {TableFormContents} from './TableFormContents';
import {CategoryTrees} from '@modules/categories/hooks/useCategoryTrees';
import {CategoryIndex} from '@modules/categories/types';

const rows = [
  {
    label: '型式',
    id: 'name',
  },
  {
    label: '機種名',
    id: 'displayName',
  },
  {
    label: 'メーカー',
    id: 'makerName',
  },
  {
    label: '大分類',
    id: 'rootCategory',
  },
  {
    label: '小分類',
    id: 'narrowCategory',
  },
];

const productAtomsAtom = splitAtom(productsAtom);

type Props = {
  categoryTrees: CategoryTrees;
  rootCategories: CategoryIndex[];
  subCategories: CategoryIndex[];
};

export const TableForm: React.FC<Props> = ({categoryTrees, rootCategories, subCategories}) => {
  const classes = useStyles();
  const [selected, setSelected] = React.useState<string[]>([]);
  const [products, setProducts] = useAtom(productsAtom);
  const [productAtoms] = useAtom(productAtomsAtom);

  const handleSelectAllClick = (allData: Product[]) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = allData.map((n: Product) => n.uuid + '');
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, key: string) => {
    const selectedIndex = selected.indexOf(key);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, key);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const isSelected = useMemo(
    () => (key: string) => {
      return selected.indexOf(key) !== -1;
    },
    [selected]
  );

  const handleEdit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, targets: string[]) => {
    const value = await dialogHandler.open(EditableColumnModal, {
      selected: targets,
      categoryTrees,
      rootCategories,
      narrowCategories: subCategories,
    });

    const updateProducts = products.map((product) => {
      if (targets.includes(product?.uuid + '')) {
        return {
          ...product,
          ...value,
        };
      }
      return product;
    });

    setProducts(updateProducts);
    setSelected([]);
  };

  const IconButtons = [{label: '編集', icon: <CreateIcon />, action: handleEdit}];

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table className={classes.table} aria-labelledby="tableTitle" aria-label="enhanced table">
            <EnhancedTableHead
              classes={classes}
              onSelectAllClick={handleSelectAllClick(products)}
              rowCount={productAtoms.length}
              selected={selected}
              iconButtons={IconButtons}
            />
            <TableBody>
              {productAtoms.map((productAtom, index) => {
                return (
                  <TableFormContents
                    key={index}
                    productAtom={productAtom}
                    index={index}
                    handleClick={handleClick}
                    isSelected={isSelected}
                    categoryTrees={categoryTrees}
                    rootCategories={rootCategories}
                    subCategories={subCategories}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

interface EnhancedTableHeadProps {
  classes: ReturnType<typeof useStyles>;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
  selected: string[];
  iconButtons: {
    label: string;
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    icon: any;
    action: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, selected: string[]) => void;
  }[];
}

const EnhancedTableHead: React.FC<EnhancedTableHeadProps> = (props) => {
  const {onSelectAllClick, rowCount, selected, iconButtons} = props;
  const numSelected = selected.length;
  const classes = useStyles();

  return numSelected > 0 ? (
    <TableHead style={{borderBottom: '1px solid rgba(224, 224, 224, 1)'}}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{'aria-label': 'select all desserts'}}
          />
        </TableCell>
        <TableCell style={{minWidth: 200, padding: 'initial'}}>
          <Typography>{numSelected}件が選択されました。</Typography>
        </TableCell>
        <TableCell style={{minWidth: 200, padding: 'initial'}}>
          <Grid container direction={'row'} justifyContent={'flex-start'}>
            {iconButtons.map((iconButton, index) => {
              return (
                <Grid key={`iconButtons${index}`}>
                  <Button
                    color={'primary'}
                    startIcon={iconButton.icon}
                    onClick={(e) => {
                      iconButton.action(e, selected);
                    }}>
                    {iconButton.label}
                  </Button>
                </Grid>
              );
            })}
          </Grid>
        </TableCell>
      </TableRow>
    </TableHead>
  ) : (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{'aria-label': 'select all products'}}
          />
        </TableCell>
        {rows.map((headCell) => (
          <TableCell key={headCell.id} padding={'none'} className={classes.headCell}>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    headCell: {
      paddingRight: 8,
      paddingLeft: 8,
    },
  })
);
