import {Box, Grid, makeStyles} from '@material-ui/core';
import React from 'react';
import {ProductIndex} from '@modules/products/types';

export const ProductCardFooterHeight = '140px';

const useStyles = makeStyles(() => ({
  box: {
    boxSizing: 'border-box',
    height: ProductCardFooterHeight,
    width: '100%',
    padding: '16px 0px 0px 18px',
    backgroundColor: 'white',
    display: 'flex',
  },
  container: {
    flexDirection: 'column',
    fontSize: '12px',
  },
}));

type Props = {
  product: ProductIndex;
};

export const ProductCardFooter: React.FC<Props> = (props) => {
  const {product} = props;
  const classes = useStyles();
  return (
    <Box className={classes.box}>
      <Grid container className={classes.container}>
        <Grid container item>
          <Grid item xs={4}>
            メーカー
          </Grid>
          <Grid item>{product.maker.name}</Grid>
        </Grid>
        <Grid container item>
          <Grid item xs={4}>
            型式
          </Grid>
          <Grid item>{product.name}</Grid>
        </Grid>
        <Grid container item>
          <Grid item xs={4}>
            承認番号
          </Grid>
          <Grid item>{product.approvalNumber}</Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
