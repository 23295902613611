export const actionMenuItems = [
  // {
  //   label: 'エクスポート',
  //   value: 'export',
  // },
  {
    label: '表示項目を変更',
    value: 'changeLayout',
  },
];

// '11'はゾーン配置外
export const allZoneIds = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'] as const;

export const allZoneOptions = Array.from({length: 10}, (_, i) => ({
  label: `ゾーン${i + 1}`,
  value: `${i + 1}`,
}));

export const maxBandNumber = 6;
export const minBandNumber = 1;

export type ZoneIdType = (typeof allZoneIds)[number];
