import React, {useState} from 'react';
import {ProductsPartsTableOrderAtom} from './state';
import {useAtom} from 'jotai';
import {Box} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {WholeProductParts} from '@modules/whole_product_parts/types';
import {MenuItemType, PopperMenuButtonV5} from '@components/molecules/Buttons/PopperMenuButton/PopperMenuButtonV5';
import {dialogHandler} from '@components/molecules/Dialogs/DialogHandlerV5';
import {
  AlertDialog,
  AlertDialogProps,
  EnhancedTable,
  EnhancedColumn,
  NoContentMessage,
  openSnackBar,
} from '@front-libs/ui';
import {deleteWholeProductParts} from '@modules/whole_product_parts/api';
import {PartsDialog} from './PartsDialog';
import {PartsDialogFormValues} from './schema';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {updateWholeProductParts} from '@modules/whole_product_parts/api';
import {getAxiosErrorData} from '@front-libs/core';

/** エラーコードとエラーメッセージ */
const errorMessages: Record<number, string> = {
  404: '該当の部品・交換品は存在しません。',
};

/**
 * エラーコードに対応するエラーメッセージを取得する
 * @param statusCode エラーコード
 * @returns エラーメッセージ
 */
const getErrorMessage = (statusCode: number): string => {
  return errorMessages[statusCode] || '予期しないエラーが発生しました。';
};

type Props = {
  data: WholeProductParts[];
  isLoading: boolean;
  isReadOnly: boolean;
  isAdmin: boolean;
  hospitalHashId: string;
  refetch: () => void;
};

export type WholeProductPartsAddIconButton = WholeProductParts & {iconButton?: React.ReactNode};

export const ProductsPartsTable = ({data, isLoading, isReadOnly, isAdmin, hospitalHashId, refetch}: Props) => {
  const [order, setOrder] = useAtom(ProductsPartsTableOrderAtom);
  const [open, setOpen] = useState(false);
  const [updatePartsData, setUpdatePartsData] = useState<WholeProductParts>();
  const {myInfo} = useMyInfo();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const columnsSorting = false;
  const menuButtonColumns: EnhancedColumn<WholeProductPartsAddIconButton> = {
    field: 'iconButton', // 三点リーダーの表示用
    fieldValue: (row: WholeProductParts) => (
      <MenuButton isAdmin={isAdmin} data={row} handleActionMenuClick={handleActionMenuClick} />
    ),
    sx: {width: '20px', padding: '6px 16px'},
    sorting: columnsSorting,
  };

  const columns: EnhancedColumn<WholeProductPartsAddIconButton>[] = [
    ...(isReadOnly ? [] : [menuButtonColumns]),
    {title: '部品・交換品名', field: 'name', sx: {minWidth: '280px'}, sorting: columnsSorting},
    {title: '部品・交換品目', field: 'partCode', sx: {minWidth: '140px'}, sorting: columnsSorting},
    {
      title: '対象機種',
      field: 'products',
      fieldValue: (item: WholeProductParts) =>
        item.products.map((product) => `${product.displayName} ${product.name}`).join('、'),
      sorting: columnsSorting,
    },
  ];

  const handleActionMenuClick = (action: MenuItemType, data: WholeProductParts) => {
    switch (action.value) {
      case 'edit':
        // 更新処理
        setUpdatePartsData(data);
        setOpen(true);
        break;
      case 'delete':
        // 削除処理
        handleDelete(data);
        break;
    }
  };

  async function confirmDelete(): Promise<boolean> {
    try {
      await dialogHandler.open<AlertDialogProps>(AlertDialog, {
        title: '部品・交換品の削除',
        content: '部品交換品を削除します。\nこの操作を実行しますか？実行すると元に戻せません。',
        positiveButtonLabel: '実行する',
      });

      return true;
    } catch (_e) {
      return false;
    }
  }

  const handleDelete = async (data: WholeProductParts) => {
    const isSubmit = await confirmDelete();
    if (!isSubmit) return;

    try {
      await deleteWholeProductParts(hospitalHashId, data.hashId);
      openSnackBar('部品・交換品を削除しました。');
      refetch();
    } catch (e) {
      const errorMessage = getAxiosErrorData(e);
      if (errorMessage && errorMessage?.status) {
        openSnackBar(getErrorMessage(errorMessage?.status), 'error');
      } else {
        openSnackBar('予期しないエラーが発生しました。', 'error');
      }
    }
  };

  const handleUpdate = async (values: PartsDialogFormValues) => {
    if (!updatePartsData || !updatePartsData.hashId) return;
    try {
      await updateWholeProductParts(myInfo.hospitalHashId, updatePartsData.hashId, values);
      closeUpdateDialog();
      openSnackBar('編集が完了しました。');
      refetch();
    } catch (e) {
      const errorMessage = getAxiosErrorData(e);
      if (errorMessage?.status === 409) {
        setErrorMessage('こちらの部品・交換品は登録済みです');
      } else {
        openSnackBar('予期しないエラーが発生しました。', 'error');
      }
    }
  };

  const closeUpdateDialog = () => {
    setErrorMessage(undefined);
    setUpdatePartsData(undefined);
    setOpen(false);
  };

  const handleOrderChange = (fieldId: keyof WholeProductPartsAddIconButton, direction: 'asc' | 'desc') => {
    if (!fieldId) return;
    setOrder({
      fieldId,
      direction,
    });
  };

  return (
    <>
      <Box sx={{padding: '16px 0'}}>
        <EnhancedTable<WholeProductPartsAddIconButton>
          data={data}
          columns={columns}
          isLoading={isLoading}
          order={order}
          onOrderChange={handleOrderChange}
          noDataComponent={<NoContentMessage title={'部品が登録されていません'} />}
          defaultOrder={
            order ?? {
              fieldId: 'name',
              direction: 'asc',
            }
          }
        />
      </Box>
      {open && (
        <PartsDialog
          open={open}
          title="部品・交換品情報を編集"
          positiveButtonLabel="保存"
          errorMessage={errorMessage}
          defaultValues={updatePartsData}
          onSubmit={handleUpdate}
          onClose={closeUpdateDialog}
        />
      )}
    </>
  );
};

const MenuButton = ({
  data,
  isAdmin,
  handleActionMenuClick,
}: {
  data: WholeProductParts;
  isAdmin: boolean;
  handleActionMenuClick: (action: MenuItemType, data: WholeProductParts) => void;
}) => {
  const menuItemList = [
    {label: '編集', value: 'edit'},
    ...(isAdmin ? [{label: '削除', value: 'delete'}] : []), // isAdminがtrueの場合のみ「削除」を追加
  ];

  return (
    <PopperMenuButtonV5<string>
      buttonProps={{variant: 'text', size: 'small', disableElevation: true, sx: {padding: '0px', minWidth: 'unset'}}}
      menuItemList={menuItemList}
      hiddenArrow
      onMenuClick={(action) => handleActionMenuClick(action, data)}>
      <MoreVertIcon sx={{color: 'rgba(0, 0, 0, 0.87)'}} />
    </PopperMenuButtonV5>
  );
};
