import React, {useEffect, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {WardAssetMenuTemplate} from '@components/templates/WardAssetMenuTemplate';
import {useAtomValue, useSetAtom} from 'jotai';
import {selectedReturnWaitingAreaInfoAtom, selectedReturnWaitingReceptionAtom} from '../states';
import {HospitalWard} from 'src/modules/hospital_wards/types';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useFetchHospitalWards} from '@modules/hospital_places/api';
import {chunk} from 'lodash';
import {SelectionDisplay} from '../SelectionDisplay';
import {MAX_SELECTION_PER_PAGE, ReceptionSelectionMap} from '../consts';

/**
 * 返却待ち受付 - 大エリア画面
 */
export const ReturnWaitingReceptionWardSelection = () => {
  const navigate = useNavigate();
  const setAreaInfo = useSetAtom(selectedReturnWaitingAreaInfoAtom);
  const selectItem = useAtomValue(selectedReturnWaitingReceptionAtom);
  const {myInfo} = useMyInfo();

  useEffect(() => {
    if (!selectItem.narrowCategoryName) navigate(ReceptionSelectionMap.rootPath);
  }, []);

  const {data: wardsData} = useFetchHospitalWards(myInfo.hospitalHashId, {
    page: 0,
    perPage: 100,
    onlyRentableRooms: true,
    order: 'name',
  });

  // Swiperに表示するデータを1ページあたりの最大表示数で分割
  const chunkedData = useMemo(() => {
    return chunk(wardsData, MAX_SELECTION_PER_PAGE);
  }, [wardsData]);

  const onSelect = (ward: HospitalWard) => {
    setAreaInfo({ward: ward});
    navigate(ReceptionSelectionMap.path.floorSelection);
  };

  return (
    <WardAssetMenuTemplate
      reservationDeviceName={selectItem?.narrowCategoryName}
      headerProps={{title: ReceptionSelectionMap.title}}
      footerProps={{
        text: ReceptionSelectionMap.footerTitle.selection,
        backButtonHandler: () => {
          navigate(ReceptionSelectionMap.rootPath);
        },
      }}>
      <SelectionDisplay<HospitalWard>
        data={chunkedData}
        getLabel={(item) => item.name}
        onSelect={(item) => onSelect(item)}
      />
    </WardAssetMenuTemplate>
  );
};
