import React from 'react';
import {styled, Button, Typography} from '@mui/material';
import {MenuItemValue, MenuItemSetting} from '../../types';

type Props = {
  setting: MenuItemSetting;
  selected: boolean;
  onClick: (value: MenuItemValue) => void;
};

const StyledButton = styled(Button)<{selected: boolean}>(({selected}) => ({
  flexDirection: 'column',
  width: '96px',
  height: '80px',
  padding: '8px 0px',
  backgroundColor: '#F2F6FC',
  color: '#0052CC',
  border: selected ? '2px solid #0052CC' : 'none',
  boxSizing: 'border-box',
  '& > svg': {
    fontSize: '40px',
  },
}));

export const MenuButton = ({setting, selected, onClick}: Props) => {
  const handleClick = () => {
    onClick(setting.value);
  };

  return (
    <StyledButton selected={selected} onClick={handleClick}>
      {setting.icon}
      <Typography sx={{lineHeight: '24px', fontWeight: '600', fontSize: '12px'}}>{setting.label}</Typography>
    </StyledButton>
  );
};
