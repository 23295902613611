import {useMemo} from 'react';
import dayjs from 'dayjs';
import {Build} from '@material-ui/icons';
import React from 'react';
import {Link, TimeLineTitle} from '../../ProductDetail/styled';
import {formSection} from '@Apps/RepairDetail/constants';
import {useFaultReceptionDetailQuery} from '@modules/fault_receptions/hooks';
import {useFetchFaultReceptionLogsQuery} from '@modules/fault_receptions/hooks/useFaultReceptionLog';
import {UserFormatter} from '@modules/hospital_users/helpers';
import {useHospitalUsers} from '@modules/hospital_users/hooks/useHospitalUsers';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {
  useFetchFaultRepairsQuery,
  useFetchFaultRepairStatuses,
  useFaultRepairPropertyRequirements,
} from '@modules/repairs/hooks';
import {FetchFaultRepairPropertyRequrementsParams} from '@modules/repairs/types';
import {isNullish} from '@front-libs/helpers';
import {HistoryCardProps, HistorySection} from './type';
import {useHospitalDepartmentsOptions} from '@modules/hospital_departments/hooks';

export const useFaultReceptionLogs = (hospitalHashId: string, faultReceptionHashId: string) => {
  const faultReceptionDetail = useFaultReceptionDetailQuery(hospitalHashId, faultReceptionHashId);
  const faultReceptionLog = useFetchFaultReceptionLogsQuery(hospitalHashId, faultReceptionHashId);
  const faultRepair = useFetchFaultRepairsQuery(hospitalHashId, {faultReceptionHashId});

  const isLoading = useMemo(
    () => faultReceptionDetail.isLoading || faultReceptionLog.isLoading || faultRepair.isLoading,
    [faultReceptionDetail, faultReceptionLog, faultRepair]
  );

  const hasError = useMemo(
    () => faultReceptionDetail.isError || faultReceptionLog.isError || faultRepair.isError,
    [faultReceptionDetail, faultReceptionLog, faultRepair]
  );

  const productHistory: HistoryCardProps[] = useMemo(() => {
    if (isLoading && hasError) return [];
    let historyCards: HistoryCardProps[] = [];

    const repairData = faultRepair.data?.[0] || null;
    if (!isNullish(repairData)) {
      historyCards.push({
        logHashId: repairData.hashId,
        icon: <Build />,
        cardType: 'registration',
        title: (
          <TimeLineTitle>
            <Link href={`/repairs/${repairData.hashId}`} target="_blank">
              修理情報の登録
            </Link>
          </TimeLineTitle>
        ),
        displayDate: repairData.createdAt ?? new Date(),
        repairRegistrationNumber: repairData.repairRegistrationNumber,
        contentSlot: [{label: '登録者：', value: UserFormatter.getFullName(repairData.createdBy)}],
      });
    }

    historyCards = historyCards.concat(
      faultReceptionLog.data.map<HistoryCardProps>((item) => ({
        cardType: 'fault_reception_comment',
        logHashId: item.hashId,
        faultReceptionHashId: item.faultReceptionHashId,
        pinned: item.pinned,
        user: item.createdBy,
        displayDate: item.createdAt,
        description: item.description,
        isArchived: item.isArchived,
        archivedBy: item.archivedBy,
        updatedAt: item.updatedAt,
      }))
    );

    if (!isNullish(faultReceptionDetail.data)) {
      historyCards.push({
        logHashId: faultReceptionDetail.data.hashId,
        icon: <Build />,
        cardType: 'fault_reception',
        title: '不具合受付の登録',
        displayDate: faultReceptionDetail.data?.createdAt ?? new Date(),
        contentSlot: [{label: '登録者：', value: faultReceptionDetail.data?.reporterName ?? '不明な登録者'}],
      });
    }
    return historyCards.sort((a, b) => {
      if (a.displayDate < b.displayDate) return 1;
      if (a.displayDate > b.displayDate) return -1;
      return 0;
    });
  }, [isLoading, hasError, faultRepair.data, faultReceptionLog.data, faultReceptionDetail.data]);

  const monthlyProductHistory: HistorySection[] = useMemo(() => {
    /**
     * HistoryCardPropsを元に履歴データの作成
     * @param items
     * @param isPinned
     */
    const createHistoryItems = (items: HistoryCardProps[], isPinned: boolean) => {
      return items.reduce((acc, item) => {
        const section = isPinned ? 'ピンどめしたコメント' : dayjs(item.displayDate).format('MM月 YYYY');
        const findSection = acc.find((s) => s.section === section);

        if (findSection) {
          findSection.productHistories.push(item);
        } else {
          acc.push({section, productHistories: [item]});
        }

        return acc;
      }, [] as HistorySection[]);
    };
    const pinnedItems = productHistory.filter((item) => item.pinned);
    const unpinnedItems = productHistory.filter((item) => !item.pinned);

    const pinnedHistory = createHistoryItems(pinnedItems, true);
    const unpinnedHistory = createHistoryItems(unpinnedItems, false);

    return [...pinnedHistory, ...unpinnedHistory];
  }, [productHistory]);
  return {monthlyProductHistory, faultReceptionLog, faultRepair};
};

/**
 * 修理の新規登録時に必要なデータを返す
 */
export const useRegistrationRepairData = () => {
  const {myInfo} = useMyInfo();
  const {hospitalUsers} = useHospitalUsers();
  const faultRepairStatus = useFetchFaultRepairStatuses(myInfo.hospitalHashId);
  const repairStatuses = useMemo(() => faultRepairStatus.data ?? [], [faultRepairStatus.data]);

  const startStatusHashId = useMemo(
    () => repairStatuses.find((status) => status.statusType === 'start')?.hashId,
    [repairStatuses]
  );
  const statusOptions = useMemo(
    () =>
      repairStatuses.map((item) => ({
        value: item.hashId,
        label: item.statusName,
      })),
    [repairStatuses]
  );
  const params: FetchFaultRepairPropertyRequrementsParams = {
    faultRepairStatusHashID: startStatusHashId,
    order: 'sequence',
  };
  const {data} = useFaultRepairPropertyRequirements(params);
  const {hospitalDepartmentOptions} = useHospitalDepartmentsOptions();

  const formFields = useMemo(() => {
    const fields = formSection(hospitalUsers, hospitalDepartmentOptions).flatMap((form) =>
      form.fields
        .filter((field) => data?.some((item) => item.property === field.name))
        .map((field) => {
          const matchedData = data?.find((item) => item.property === field.name);
          return {
            value: field.name,
            label: field.label,
            type: field.type,
            isRequired: matchedData?.isRequired,
            sequence: matchedData?.sequence,
            options: field.options,
          };
        })
    );

    // ステータスはformSectionに含まれていないため、ここで追加
    const _faultRepairStatus = data?.find((val) => val.property === 'statusHashId');
    if (_faultRepairStatus) {
      fields.push({
        value: _faultRepairStatus.property,
        label: 'ステータス',
        type: 'select',
        isRequired: _faultRepairStatus.isRequired,
        sequence: _faultRepairStatus.sequence,
        options: statusOptions,
      });
    }
    return fields.sort((a, b) => (a.sequence ?? 0) - (b.sequence ?? 0));
  }, [data, hospitalUsers, statusOptions]);

  return {formFields, repairStatuses, myInfo};
};
