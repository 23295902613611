import React from 'react';
import {Grid, TextField, styled} from '@mui/material';
import {NumConditionValue} from '../types';

type Props = {
  defaultValue?: number;
  optionType: 'num';
  min?: number;
  max?: number;
  onSubmit: (text: NumConditionValue) => void;
  onChange: (options: NumConditionValue) => void;
};

export const FilterDrawerNumberField: React.FC<Props> = (props) => {
  const {min, max, defaultValue, onChange} = props;

  const handleChange = (e: number) => {
    onChange(e);
  };

  return (
    <StyledContainer container>
      <StyledGrid>
        <StyledTextField
          variant="outlined"
          type="number"
          defaultValue={defaultValue}
          inputProps={{
            min,
            max,
          }}
          placeholder={''}
          onChange={(e) => handleChange(Number(e.target.value))}
        />
      </StyledGrid>
    </StyledContainer>
  );
};

const StyledContainer = styled(Grid)(() => ({
  marginBottom: '32px',
}));

const StyledGrid = styled(Grid)(() => ({
  width: '100%',
}));

const StyledTextField = styled(TextField)(() => ({
  width: '100%',
}));
