import {useAtomValue} from 'jotai';
import {faultReceptionsVariables, pageSizeAtom} from './jotai';
import {useFaultReceptionsQuery} from '@modules/fault_receptions/api/faultReceptionApi';
import {useMemo} from 'react';

export const useFaultReceptionQuery = (hospitalHashId: string) => {
  const variables = useAtomValue(faultReceptionsVariables);
  const pageSize = useAtomValue(pageSizeAtom);
  const query = useFaultReceptionsQuery(hospitalHashId, variables);

  const totalPage = useMemo(() => {
    return Math.ceil(query.totalCount / pageSize);
  }, [pageSize, query.totalCount]);

  return {...query, totalPage};
};
