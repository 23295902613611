import {useMemo, useReducer} from 'react';
import {useQuery} from 'react-query';
import dayjs from 'dayjs';
import {InspectionResultIndex, InspectionResultState, InspectionResultActionType} from './types';
import {getNextInspectionResultKey} from '@constants/api';
import {FetchInspectionResultsParam, FetchInspectionResultsResponse, getInspectionResults} from './api';
import {convertDateToRFC3339, isNullish} from '@front-libs/helpers';
import {inspectionResultReducer, inspectionResultInitialState} from './reducers';

export type GetInspectionResultsQueryParams = FetchInspectionResultsParam & {
  inspectionHashId?: string;
};

export const useGetInspectionResultsQuery = (hospitalHashId?: string, params?: GetInspectionResultsQueryParams) => {
  return useQuery(
    [hospitalHashId, params],
    async () => {
      return getInspectionResults(hospitalHashId ?? '', params?.inspectionHashId ?? '', params ?? {});
    },
    {
      enabled: !isNullish(hospitalHashId),
    }
  );
};

export const useNextInspectionResult = (inspectionResult: InspectionResultIndex) => {
  const scheduledAtFrom = useMemo(() => {
    const base = inspectionResult.scheduledTime
      ? dayjs(inspectionResult.scheduledTime).add(1, 'second').toDate()
      : new Date();

    return convertDateToRFC3339(base);
  }, [inspectionResult.scheduledTime]);

  return useQuery([getNextInspectionResultKey, inspectionResult.hospitalHashId, inspectionResult.hashId], async () => {
    // XXX: とりあえず定期点検だけ
    if (inspectionResult.type !== 'periodic') {
      return null;
    }

    return getInspectionResults(inspectionResult.hospitalHashId, inspectionResult.inspectionHashId, {
      perPage: 1,
      order: 'scheduledTime',
      hospitalProductHashId: inspectionResult.hospitalProductHashId,
      types: inspectionResult.type,
      statuses: 'unplanned,uncompleted',
      scheduledAtFrom: scheduledAtFrom,
    });
  });
};

type InspectionResultStoreType = {
  state: InspectionResultState;
  dispatchListInspectionResults: (
    hospitalHashId: string,
    inspectionHashId: string,
    params: FetchInspectionResultsParam
  ) => Promise<FetchInspectionResultsResponse>;
};

export const useInspectionResultStore = (): InspectionResultStoreType => {
  const [state, dispatch] = useReducer(inspectionResultReducer, inspectionResultInitialState);

  const dispatchListInspectionResults = async (
    hospitalHashId: string,
    inspectionHashId: string,
    params: FetchInspectionResultsParam
  ) => {
    const res = await getInspectionResults(hospitalHashId, inspectionHashId, params);
    dispatch({
      type: InspectionResultActionType.INSPECTION_RESULT_SET_LIST,
      payload: {results: res.data},
    });
    return res;
  };

  return {
    state,
    dispatchListInspectionResults,
  };
};
