import {axios, BaseListResult} from '@front-libs/core';
import {convertDateToRFC3339, convertDateToSimpleDate, isNullish} from '@front-libs/helpers';
import {FileIndex} from '../files/types';
import {MakerInspectionResultStatus} from './constants';
import {MakerInspectionResultIndex, RequestExportMakerInspectionResultCSVsParam} from './types';

export type GetMakerInspectionsParams = {
  perPage?: number;
  page?: number;
  order?: string;
  name?: string;
  status?: MakerInspectionResultStatus;
};

export type CreateMakerInspectionParams = {
  hospitalProductHashId: string;
  inspectorUserHashId?: string;
  scheduledTime?: string;
  completedAt?: string;
  inspectionFee?: number;
  skippedByUserHashId?: string;
  skippedTime?: string;
  skipReason?: string;
  comment?: string;
};

export type UpdateMakerInspectionParams = {
  makerInspectionResultHashId: string;
  status?: MakerInspectionResultStatus;
  inspectorUserHashId?: string;
  scheduledTime?: string;
  completedAt?: Date;
  inspectionFee?: number;
  skippedByUserHashId?: string;
  skippedTime?: string;
  skipReason?: string;
  comment?: string;
  nextScheduledTime?: Date;
};

export type CreateInspectionResultFilesParam = {
  fileHashIds: string[];
};

export const createMakerInspectionResult = async (hospitalHashId: string, data: CreateMakerInspectionParams) => {
  const baseURL = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/maker_inspections/results`;
  return await axios.post<MakerInspectionResultIndex>(baseURL, data);
};

export const updateMakerInspectionResult = async (hospitalHashId: string, data: UpdateMakerInspectionParams) => {
  const baseURL = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/maker_inspections/results/${
    data.makerInspectionResultHashId
  }`;
  return await axios.put<MakerInspectionResultIndex>(baseURL, {
    ...data,
    nextScheduledTime: !isNullish(data.nextScheduledTime) ? convertDateToSimpleDate(data.nextScheduledTime) : undefined,
    completedAt: !isNullish(data.completedAt) ? convertDateToRFC3339(data.completedAt) : undefined,
  });
};

export const getMakerInspectionResults = async (hospitalHashId: string, params: GetMakerInspectionsParams) => {
  const baseURL = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/maker_inspections/results`;
  return await axios.get<BaseListResult<MakerInspectionResultIndex>>(baseURL, {params});
};

export const getMakerInspectionResult = async (hospitalHashId: string, makerInspectionResultHashId: string) => {
  const baseURL = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/maker_inspections/results/${makerInspectionResultHashId}`;
  return await axios.get<MakerInspectionResultIndex>(baseURL);
};

export const deleteMakerInspectionResult = async (hospitalHashId: string, makerInspectionResultHashId: string) => {
  const baseURL = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/maker_inspections/results/${makerInspectionResultHashId}`;
  return await axios.delete(baseURL);
};

export const createMakerInspectionResultFiles = async (
  hospitalHashId: string,
  makerInspectionResultHashId: string,
  data: CreateInspectionResultFilesParam
) => {
  const baseURL = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/inspections/results/${makerInspectionResultHashId}/files`;
  return await axios.post<void>(baseURL, data);
};

export const getMakerInspectionResultFiles = async (
  hospitalHashId: string,
  makerInspectionResultHashId: string,
  order?: string
) => {
  const baseURL = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/inspections/results/${makerInspectionResultHashId}/files`;
  const {data} = await axios.get<FileIndex[]>(baseURL, {params: {order}});
  return data;
};

export const requestExportMakerInspectionResultTask = async (
  hospitalHashId: string,
  data: RequestExportMakerInspectionResultCSVsParam
) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/maker_inspections/results/tasks/export`;
  return axios.post<MakerInspectionResultIndex>(baseUrl, data);
};
