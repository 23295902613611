import {Box, Typography} from '@mui/material';
import search_not_found from '@assets/search_not_found.svg';
import React from 'react';

export const NotFound = () => {
  return (
    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
      <Box sx={{mr: 6}}>
        <Box sx={{fontWeight: 700, fontSize: 24, color: '#5D6B82', mb: 2}}>
          検索条件に一致する情報は見つかりませんでした
        </Box>
        <Typography sx={{color: '#8B95A6'}}>
          検索条件を変更して再検索するか、
          <br />
          右上の「機種情報を手入力して登録」ボタンから機種を登録してください。
        </Typography>
      </Box>
      <Box>
        <img src={search_not_found} alt="検索条件が見つかりません" />
      </Box>
    </Box>
  );
};
