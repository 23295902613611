import React from 'react';
import {TableViewLayout} from '@components/layouts/TableViewLayout';
import {Toolbar} from './Toolbar';
import {Pagination} from '@material-ui/lab';
import {FaultReceptionTable} from './FaultReceptionTable';
import {List, styled, Typography} from '@material-ui/core';
import {BEFORE_CONFIRMATION_STATUS, COMPLETED_CONFIRMATION_STATUS, SideBarWidth} from './constants';
import {FacetSearchForm} from './FacetSearchData';
import {DisplayNumberSelect} from '@molecules/DisplayNumberSelect';
import {FaultReceptionsCount} from '@Apps/RepairList/RepairPageHeader';

export const FaultReceptionList = () => {
  return (
    <FacetSearchForm>
      {({
        data,
        totalCount,
        page,
        totalPage,
        searchName,
        handleSearchName,
        status,
        handleStatus,
        handlePageChange,
        pageSize,
        handlePageSizeChange,
        handleActionMenuClick,
        handleOrderChange,
        tableLayout,
      }) => (
        <Container>
          <StyledList>
            <StyledListItem
              current={null}
              status={status}
              onClick={() => {
                handleStatus(null);
              }}>
              すべて
            </StyledListItem>
            <StyledListItem
              current={BEFORE_CONFIRMATION_STATUS}
              status={status}
              onClick={() => {
                handleStatus(BEFORE_CONFIRMATION_STATUS);
              }}>
              <Typography>確認前</Typography>
              {totalCount > 0 && <FaultReceptionsCount>{totalCount}</FaultReceptionsCount>}
            </StyledListItem>
            <StyledListItem
              current={COMPLETED_CONFIRMATION_STATUS}
              status={status}
              onClick={() => {
                handleStatus(COMPLETED_CONFIRMATION_STATUS);
              }}>
              確認完了
            </StyledListItem>
          </StyledList>
          <TableViewLayoutWrapper>
            <TableViewLayout>
              <TableViewLayout.Header>
                <Toolbar
                  searchName={searchName}
                  handleSearchName={handleSearchName}
                  handleActionMenuClick={handleActionMenuClick}
                />
              </TableViewLayout.Header>
              <TableViewLayout.Body>
                <FaultReceptionTable data={data} handleOrderChange={handleOrderChange} tableLayout={tableLayout} />
              </TableViewLayout.Body>
              <TableViewLayout.Footer container justifyContent="center">
                <Pagination page={page} count={totalPage} shape="rounded" color="primary" onChange={handlePageChange} />
                <DisplayNumberSelect
                  pageSize={pageSize}
                  update={(selectNum) => {
                    handlePageSizeChange(selectNum);
                  }}
                />
              </TableViewLayout.Footer>
            </TableViewLayout>
          </TableViewLayoutWrapper>
        </Container>
      )}
    </FacetSearchForm>
  );
};

const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  height: '100%',
}));

const StyledList = styled(List)(() => ({
  width: SideBarWidth, // 幅を200pxに固定
  marginRight: '16px',
}));

interface StyledListItemProps {
  current: string | null;
  status: string | null;
}

const StyledListItem = styled('div')(({current: currentStatus, status}: StyledListItemProps) => ({
  cursor: 'pointer',
  padding: '4px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  backgroundColor: currentStatus === status ? 'rgba(42, 150, 232, 0.1) !important' : 'transparent',
  '&:hover': {
    backgroundColor: 'rgba(42, 150, 232, 0.1) !important',
    borderRadius: '3px',
  },
}));

const TableViewLayoutWrapper = styled('div')(() => ({
  flex: 1,
  minWidth: 0,
}));
