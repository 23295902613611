import {styled} from '@mui/material';
import {Brightness1} from '@mui/icons-material';
import React from 'react';

type Props = {
  status: string;
};

const StyledBrightness1 = styled(Brightness1)(({status}: {status: string}) => ({
  width: '12px',
  position: 'absolute',
  color: status === 'planned' ? '#3C9E58' : '#C7243A',
}));

const FlexBox = styled('span')({
  display: 'flex',
  alignItems: 'center',
});

const Label = styled('span')({
  marginLeft: '16px',
});

export const HospitalProductPlanStatus: React.FC<Props> = ({status}) => {
  return (
    <FlexBox>
      <StyledBrightness1 status={status} />
      <Label>{status === 'planned' ? '計画済み' : '未計画'}</Label>
    </FlexBox>
  );
};
