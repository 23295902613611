import React, {useEffect, useState} from 'react';
import {Grid} from '@mui/material';
import {isEqual} from 'lodash';
import {putHospitalProductNoteSettings} from '@modules/hospital_products/api/hospitalProductNoteSettingsApi';
import {
  MAX_NOTE_NUM,
  useHospitalProductNoteSettings,
} from '@modules/hospital_products/hooks/useHospitalProductNoteSettings';
import {NoteFieldDataTypes, NoteSettings} from '@modules/hospital_products/types';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {InnerLoading} from '@molecules/Loading';
import {openSnackBar} from '@molecules/SnackBar';
import {Formik} from 'formik';
import {NoteField} from './types';
import {ProductManagementForm} from './ProductManagementForm';

/**
 * Post出来るようにNoteField型の配列をNoteSettings型に変換する
 * @param noteFields
 * @param noteSetting
 * @returns
 */
const convertNoteFieldToNoteSettings = (noteFields: NoteField[], noteSetting: NoteSettings) => {
  // deep copyを作成
  const noteSettings: NoteSettings = JSON.parse(JSON.stringify(noteSetting));
  for (let i = 1; i <= MAX_NOTE_NUM; i++) {
    const numString = i === 1 ? '' : String(i);
    const noteField = noteFields.find((v) => v.id === `notes${numString}`);
    if (!noteField) continue;

    noteSettings[`notes${numString}Name`] = noteField.name;
    noteSettings[`notes${numString}Visible`] = noteField.visible;
    noteSettings[`notes${numString}Type`] = noteField.type as NoteFieldDataTypes;
    noteSettings[`notes${numString}TypeOption`] = noteField.option;
  }
  return noteSettings;
};

/**
 * ２つの引数のObjectの差分を返す
 * @param obj1
 * @param obj2
 * @returns
 */
const getObjectDiff = (obj1: never, obj2: never) => {
  const diff: {[key: string]: never} = {};
  // obj2 と値が異なるプロパティを見つける
  Object.keys(obj1).forEach((key) => {
    if (!(key in obj2)) return;
    if (isEqual(obj1[key], obj2[key])) return;

    diff[key] = obj1[key];
  });
  return diff;
};

/**
 * 機器管理ページ
 * @returns
 */
export const SettingsHospitalProductManagement = () => {
  const {myInfo} = useMyInfo();
  const {
    data: noteData,
    isLoading: isLoadingNoteSettings,
    refetch,
  } = useHospitalProductNoteSettings(myInfo.hospitalHashId);

  const [noteFields, setNoteFields] = useState<NoteField[]>([]);

  useEffect(() => {
    if (!noteData) return;
    const noteSettings = noteData.noteSettings;
    const tempNoteFields: NoteField[] = [];
    for (let i = 1; i <= MAX_NOTE_NUM; i++) {
      const numString = i === 1 ? '' : String(i);
      tempNoteFields.push({
        id: `notes${numString}`,
        option: noteSettings[`notes${numString}TypeOption`],
        type: (noteSettings[`notes${numString}Type`] || 'text') as NoteFieldDataTypes,
        name: noteSettings[`notes${numString}Name`] || '',
        visible: noteSettings[`notes${numString}Visible`] || false,
      });
    }
    setNoteFields(tempNoteFields);
  }, [myInfo.hospitalHashId, noteData]);

  const handleSubmit = async (values: NoteField[]) => {
    if (!noteData) return;
    try {
      // NoteFieldをNoteSettingsに変換する
      const noteSettings = convertNoteFieldToNoteSettings(values, noteData.noteSettings);

      // 差分から変更した箇所を取得する
      const diff = getObjectDiff(noteData.noteSettings as never, noteSettings as never);
      const keys = Object.keys(diff);
      if (keys.length > 0) {
        await putHospitalProductNoteSettings(myInfo.hospitalHashId, noteSettings);
        await refetch();

        const key = keys[0];
        if (key.endsWith('Name')) {
          openSnackBar('備考の名称を変更しました');
          return;
        }
        if (key.endsWith('Visible')) {
          openSnackBar('備考の表示設定を変更しました');
          return;
        }
        // NOTE: データタイプを単一選択に変更する際に併せて選択項目を登録するように、データタイプを変更する際は別種類のkeyも併せて変更することがあるため、keys[0]以外もチェックしている。
        if (keys.some((k) => k.endsWith('Type'))) {
          openSnackBar('備考のデータタイプを変更しました');
          return;
        }
        // データタイプの変更はなく、選択項目のみ変更があった場合。
        if (key.endsWith('TypeOption')) {
          openSnackBar('選択項目の編集内容を保存しました');
          return;
        }
      }
    } catch (error) {
      openSnackBar('機器管理の設定の更新に失敗しました', 'left', 'bottom', 'error');
      console.error('Failed to update device management settings', error);
    }
  };

  if (isLoadingNoteSettings || noteFields.length === 0) {
    return <InnerLoading />;
  }

  return (
    <Grid container>
      <Formik initialValues={noteFields} onSubmit={handleSubmit} enableReinitialize={true}>
        <ProductManagementForm noteFields={noteFields} />
      </Formik>
    </Grid>
  );
};
