import {useCallback} from 'react';
import {useLogout} from './useLogout';

export const useSignOut = () => {
  const logout = useLogout();

  return useCallback(async () => {
    return logout();
  }, [logout]);
};
