import React, {useCallback} from 'react';
import {FormControlLabel, FormControl, RadioGroup, Radio} from '@material-ui/core';

type RadioFieldProps = {
  value: string;
  options: {label: string; value: string}[];
  error?: React.ReactNode;
  isEditable: boolean;
  onChange?: (value: string) => void;
};

export const RadioField: React.FC<RadioFieldProps> = (props) => {
  const {value, options, error, isEditable, onChange} = props;
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (isEditable === true && onChange) {
        onChange(e.target.value);
      }
    },
    [onChange, isEditable]
  );

  return (
    <FormControl component="fieldset">
      <RadioGroup aria-label="radio" value={value} onChange={handleChange}>
        {options.map(({label, value: val}, index) => (
          <FormControlLabel
            key={index}
            control={
              <Radio
                inputProps={{
                  readOnly: !isEditable,
                }}
              />
            }
            label={label}
            value={val}
          />
        ))}
      </RadioGroup>
      {error}
    </FormControl>
  );
};
