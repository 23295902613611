import React, {useCallback, useMemo} from 'react';
import {createStyles, Grid, makeStyles, Theme, TextField, useMediaQuery} from '@material-ui/core';
import {Search} from '@material-ui/icons';
import {MenuItemType, PopperMenuButton} from '@components/molecules/Buttons/PopperMenuButton';
import dayjs from 'dayjs';
import {CreateDatePickerDialog} from '@components/molecules/Dialogs/DatePickerDialog';
import {dialogHandler} from '@components/molecules/Dialogs/DialogHandler';
import {openSnackBar} from '@components/molecules/SnackBar';
import {useSearchParams} from 'react-router-dom';
import {RequestExportMakerInspectionResultCSVsParam} from '@modules/maker_inspection_results/types';
import {requestExportMakerInspectionResultTask} from '@modules/maker_inspection_results/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flex: {
      flexGrow: 1,
    },
    searchNameContainer: {
      minWidth: '270px',
    },
    searchName: {
      backgroundColor: theme.palette.common.white,
      fontSize: '14px',
    },
    actionMenu: {
      fontWeight: 'bold',
      color: theme.palette.primary.dark,
      marginLeft: '8px',
    },
    tableContainer: {
      margin: '24px 0px',
    },
    rentalHistoryMenu: {
      width: '204px',
      height: '32px',
      backgroundColor: '#FAFBFC',
      marginLeft: '40px',
    },
    dateSelector: {
      display: 'flex',
      alignItems: 'center',
    },
    date: {
      margin: '0px 8px',
    },
    dateMenu: {
      fontWeight: 'bold',
      color: theme.palette.primary.dark,
    },
    dateDivider: {
      margin: '0px 8px',
    },
    filterBtnActive: {
      backgroundColor: theme.palette.primary.light,
      '&&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    actionBtn: {
      width: '100%',
      background: 'rgba(9, 30, 66, 0.04)',
      border: '1px solid #C6CBD4',
    },
  })
);

const actionMenus4Tablet = [
  {
    label: 'エクスポート',
    value: 'export',
  },
];

type ToolBarProp = {
  searchName: string;
  onChangeSearchName: React.ChangeEventHandler<HTMLInputElement>;
};

export const ToolBar: React.FC<ToolBarProp> = (props) => {
  const {myInfo} = useMyInfo();
  const {searchName, onChangeSearchName} = props;
  const matches = useMediaQuery('(max-width:1024px)');
  const classes = useStyles();

  const [searchParams] = useSearchParams();
  const status = searchParams.get('status') ?? 'unplanned';

  const actionMenuItems = useMemo(() => {
    return [...actionMenus4Tablet];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matches]);

  const handleExport = useCallback(async () => {
    try {
      const res = await dialogHandler.open(CreateDatePickerDialog, {
        title: 'メーカー保守点検のエクスポート範囲を選択',
        description:
          '点検予定日の範囲を選択してください。(点検ステータスが完了の場合は、点検完了日も抽出範囲に含めます。)',
        initialStartDate: dayjs().subtract(1, 'year').format('YYYY-MM-DD'),
        initialEndDate: dayjs().format('YYYY-MM-DD'),
        startDateLabel: '開始日',
        endDateLabel: '終了日',
      });

      if (!res) return null;

      const data: RequestExportMakerInspectionResultCSVsParam = {
        scheduledTimeFrom: res.startDate,
        scheduledTimeTo: res.endDate,
        status: status,
      };

      const result = await requestExportMakerInspectionResultTask(myInfo.hospitalHashId, data);
      if (result.status === 200) {
        openSnackBar('ファイルのエクスポートを受け付けました。\n処理完了後、通知をご確認ください。', 'center', 'top');
      }
    } catch (_e) {
      openSnackBar('ファイルのエクスポートに失敗しました。', 'center', 'top', 'error');
    }
  }, [myInfo.hospitalHashId, status]);

  const handleMenuClick = useCallback(
    (item: MenuItemType) => {
      switch (item.value) {
        case 'export':
          handleExport();
          break;
      }
    },
    [handleExport]
  );

  return (
    <Grid container alignItems="center" style={{marginTop: '8px'}}>
      <Grid item sm={4} md={3} className={classes.searchNameContainer}>
        <TextField
          className={classes.searchName}
          label={'機種名・型式・管理番号で検索'}
          variant={'outlined'}
          fullWidth
          size={'small'}
          InputProps={{
            endAdornment: <Search />,
          }}
          InputLabelProps={{
            style: {
              fontSize: 14,
            },
          }}
          defaultValue={searchName}
          onChange={onChangeSearchName}
        />
      </Grid>
      <Grid className={classes.flex} />
      <Grid item>
        <PopperMenuButton
          buttonProps={{variant: 'contained', disableElevation: true, className: classes.actionBtn}}
          menuItemList={actionMenuItems}
          onMenuClick={handleMenuClick}>
          アクション
        </PopperMenuButton>
      </Grid>
    </Grid>
  );
};
