import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Location, useLocation} from 'react-router-dom';
import type {Blocker, Transition} from 'history';
import {cachedBrowserHistory} from '@front-libs/helpers';

export const useWindowPrompt = (when: boolean, message: string) => {
  const onWindowOrTabClose = useCallback(
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    (event: any) => {
      if (!when) {
        return;
      }
      if (typeof event == 'undefined') {
        event = window.event;
      }
      if (event) {
        event.returnValue = message;
      }

      return message;
    },
    [message, when]
  );

  useEffect(() => {
    window.addEventListener('beforeunload', onWindowOrTabClose);
    return () => {
      window.removeEventListener('beforeunload', onWindowOrTabClose);
    };
  }, [onWindowOrTabClose]);
};

export function useBlocker(blocker: Blocker, when = true): void {
  // react-router-dom v6.4.0 バージョンアップ で React.useContext(UNSAFE_NavigationContext).navigator を history として使用できなくなりました。
  // ゆえに あらかじめ生成した ブラウザ履歴オブジェクト を使用します。
  const history = cachedBrowserHistory;

  React.useEffect(() => {
    if (!when) return;

    const unblock = history.block((tx: Transition) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          // Automatically unblock the transition so it can play all the way
          // through before retrying it. TODO: Figure out how to re-enable
          // this block if the transition is cancelled for some reason.
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblock;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigator, blocker, when]);
}

export const useRouterPrompt = (when: boolean, message: string) => {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const retry = useRef(() => {});
  const [proceed, setProceed] = useState(false);

  const confirm = () => {
    setProceed(window.confirm(message));
  };

  useEffect(() => {
    proceed && retry.current();
  }, [proceed]);

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const handleNavigation = (nextLocation: any) => {
    confirm();
    retry.current = nextLocation.retry;
  };

  useBlocker(handleNavigation, when);
};

export const useLocationChange = (callback: (location: Location) => void) => {
  const refCallback = useRef<undefined | ((location: Location) => void)>();
  const location = useLocation();

  useEffect(() => {
    refCallback.current = callback;
  }, [callback]);

  // ロケーションに変更があったときに処理実行
  useEffect(() => {
    if (refCallback.current) {
      refCallback.current(location);
    }
  }, [location]);
};

export const usePrompt = (when: boolean, message = 'Are you sure you want to quit without saving your changes?') => {
  useWindowPrompt(when, message);
  useRouterPrompt(when, message);
};
