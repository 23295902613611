import {useMemo} from 'react';
import {useMyInfo} from './useMyInfo';
import {defaultRoleMap} from '../constants';

export const useMyRole = () => {
  const {myRole, myRoles} = useMyInfo();
  const canAccessAsset = useMemo(() => myRoles === undefined || myRoles.length > 0, [myRoles]);
  const isAdmin = useMemo(() => myRole?.role.name === defaultRoleMap.admin, [myRole?.role.name]);
  const isRentalOnly = useMemo(() => myRole?.role.name === defaultRoleMap.lendingAndReturnOnly, [myRole?.role.name]);
  const isReadOnly = useMemo(() => myRole?.role.name === defaultRoleMap.deviceUse, [myRole?.role.name]);
  const isGeneral = useMemo(() => myRole?.role.name === defaultRoleMap.general, [myRole?.role.name]);
  const isWard = useMemo(() => myRole?.role.name === defaultRoleMap.ward, [myRole?.role.name]);
  return {canAccessAsset, isAdmin, isRentalOnly, isReadOnly, isGeneral, isWard};
};
