import React from 'react';
import {dialogHandler} from '@components/molecules/Dialogs/DialogHandler';
import {openSnackBar} from '@components/molecules/SnackBar';
import {Slide, styled} from '@material-ui/core';
import {createFaultReceptionLog} from '@modules/fault_receptions/api/faultReceptionLog';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useCallback, useState, useEffect} from 'react';
import {CommentDialog, CommentDialogProps} from '../Dialog/CommentDialog';
import {NewRegistrationFormContainer} from './NewRegistrationFormContainer';
import {ToolBar} from './ToolBar';
import {useParams} from 'react-router-dom';
import {InnerFaultReceptionCommentList} from './InnerFaultReceptionCommentList';
import {RegistrationRepairDialogProps} from '@components/organisms/FaultRepairs/dialogs/RegistrationRepairDialog';
import {useFetchFaultRepairStatuses} from '@modules/repairs/hooks';
import {createFaultRepair} from '@modules/repairs/api';
import {useFaultReceptionLogs} from './hooks';
import {FaultReceptionStatus} from '@modules/fault_receptions/types';
import {useAuth0} from '@auth0/auth0-react';
import {FaultRepairConfirmDialog} from '@components/organisms/FaultRepairConfirmDialog/FaultRepairConfirmDialog';

const RootContainer = styled('div')(() => ({
  height: '100%',
  position: 'relative',
}));

const ZIndexDiv = styled('div')({
  zIndex: 10,
});

type FaultReceptionCommentListSectionProps = {
  status?: FaultReceptionStatus | undefined;
  refetch: () => void;
};

/**
 * 右カラムのコメント表示エリア
 */
export const FaultReceptionCommentList = ({status, refetch}: FaultReceptionCommentListSectionProps) => {
  const [isNewRegistration, setIsNewRegistration] = useState(false);
  const [openCommentDialog, setOpenCommentDialog] = useState(false);
  const {myInfo} = useMyInfo();
  const {user} = useAuth0();
  const {hashId} = useParams();
  const statusQuery = useFetchFaultRepairStatuses(myInfo.hospitalHashId);
  const {monthlyProductHistory, faultReceptionLog, faultRepair} = useFaultReceptionLogs(
    myInfo.hospitalHashId,
    hashId ?? ''
  );

  useEffect(() => {
    return () => {
      if (openCommentDialog) dialogHandler.close(CommentDialog, {});
    };
  }, [openCommentDialog]);

  /** コメントを追加ボタン押下 */
  const handleClickAddComment = useCallback(async () => {
    setOpenCommentDialog(true);
    const comment = await dialogHandler.open<CommentDialogProps, string>(CommentDialog, {
      thumbnailUrl: user?.picture || '',
    });
    setOpenCommentDialog(false);
    if (!comment) return;

    await createFaultReceptionLog(myInfo.hospitalHashId, hashId || '', {
      description: comment as string,
      logType: 'comment',
    });
    await faultReceptionLog.refetch();

    openSnackBar('コメントを追加しました');
  }, [faultReceptionLog, hashId, myInfo.hospitalHashId, user?.picture]);

  /** 新規登録ボタン押下時 */
  const handleClickRepair = useCallback(async () => {
    setIsNewRegistration(true);
  }, []);

  const onSubmit = useCallback(
    async (res: Partial<RegistrationRepairDialogProps>) => {
      if (!res) return;

      // ステータスが未設定の時追加する
      res.statusHashId = res.statusHashId
        ? res.statusHashId
        : statusQuery.data.find((item) => item.statusType === 'start')?.hashId ?? statusQuery.data[0].hashId;

      const {repairRegistrationNumber} = await createFaultRepair(
        myInfo.hospitalHashId,
        res.hospitalProductHashId + '',
        {
          ...res,
          faultReceptionHashId: hashId, // 不具合受付のIDと紐づける為追加
        }
      );

      refetch();
      faultRepair.refetch();
      setIsNewRegistration(false);
      await dialogHandler.open(FaultRepairConfirmDialog, {repairRegistrationNumber});
    },
    [statusQuery.data, myInfo.hospitalHashId, hashId, faultRepair, refetch]
  );

  return (
    <RootContainer>
      <Slide direction="left" in={isNewRegistration}>
        <ZIndexDiv>
          {isNewRegistration && (
            <NewRegistrationFormContainer
              onSubmit={onSubmit}
              onClose={() => {
                setIsNewRegistration(false);
              }}
            />
          )}
        </ZIndexDiv>
      </Slide>

      <ToolBar handleClickAddComment={handleClickAddComment} handleClickRepair={handleClickRepair} status={status} />
      <InnerFaultReceptionCommentList
        monthlyProductHistory={monthlyProductHistory}
        refetch={async () => {
          return await faultReceptionLog.refetch();
        }}
      />
    </RootContainer>
  );
};
