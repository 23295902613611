import React, {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {createStyles, makeStyles, Theme, Grid, Button, Typography} from '@material-ui/core';
import {ArrowDropDown} from '@material-ui/icons';
import {PageRoutingMenu} from '@organisms/PageRoutingMenu';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {
  CreateInspectionResultDialog,
  CreateInspectionResultDialogResult,
  CreateInspectionResultDialogProps,
} from '@organisms/CreateInspectionResultDialog';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {
  NextUpdateInspectionStatusParam,
  createInspectionResult,
  requestExportPeriodicInspectionScheduleTask,
} from '@modules/inspection_results/api';
import {useInspectionType} from './InternalInspection/states/states';
import {convertDateToRFC3339, convertDateToSimpleDate} from '@front-libs/helpers';
import {createMakerInspectionResult} from '@modules/maker_inspection_results/api';
import {
  CreateMakerInspectionResultDialog,
  CreateMakerInspectionResultDialogProps,
  CreateMakerInspectionResultDialogResult,
} from './MakerInspection/dialogs/CreateMakerInspectionResultDialog';
import {openSnackBar} from '@molecules/SnackBar';
import {
  ExportPeriodicInspectionResultDialogProps,
  ExportPeriodicInspectionResultDialogResult,
  ExportPeriodicInspectionResultDialog,
} from './InternalInspection/dialogs/ExportPeriodicInspectionResult';
import {onlineManager} from 'react-query';
import {useMyRole} from '@modules/hospital_users/hooks/useMyRole';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageTitle: {
      fontWeight: 'bold',
      fontSize: 20,
      color: theme.palette.primary.dark,
    },
    inspectionTypeMenu: {
      width: '204px',
      height: '32px',
      backgroundColor: '#FAFBFC',
      marginLeft: '40px',
      border: '2px solid #DFE1E5',
    },
    flex: {
      flexGrow: 1,
    },
  })
);

export const Header: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {myInfo} = useMyInfo();
  const isOnline = onlineManager.isOnline();
  const {isReadOnly} = useMyRole();

  // 点検計画出力
  const exportPeriodicInspectionSchedule = async (hospitalHashId: string): Promise<string | null> => {
    const now = new Date();
    try {
      const res = await dialogHandler.open<
        ExportPeriodicInspectionResultDialogProps,
        ExportPeriodicInspectionResultDialogResult
      >(ExportPeriodicInspectionResultDialog, {
        startYear: now.getFullYear(),
        startMonth: now.getMonth(),
      });

      const startDate = convertDateToRFC3339(new Date(res.startYear, res.startMonth, 1));

      // 1年後の日付を取得。サーバ側で最終日当日は含まない設定のため、1年間分のデータがExcelに出力される
      const endDate = convertDateToRFC3339(new Date(res.startYear + 1, res.startMonth, 1));
      await requestExportPeriodicInspectionScheduleTask(myInfo.hospitalHashId, startDate, endDate);
      openSnackBar('点検表のエクスポートを受け付けました。\n処理完了後、通知をご確認ください。', 'center', 'top');
    } catch (_e) {
      return null;
    }

    return null;
  };

  // ハンドラ: 点検計画出力
  const handleClickExportPeriodicInspectionSchedule = useCallback(async () => {
    await exportPeriodicInspectionSchedule(myInfo.hospitalHashId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myInfo?.hospitalHashId]);

  const handleClickStartInspection = useCallback(
    async (e) => {
      try {
        const {hospitalProductHashId, inspectionHashId, scheduledDate, inspector, isAdhoc} = await dialogHandler.open<
          CreateInspectionResultDialogProps,
          CreateInspectionResultDialogResult
        >(CreateInspectionResultDialog, {
          defaultInspectorHashId: myInfo.hashId,
          defaultScheduledDate: convertDateToSimpleDate(new Date()),
        });

        const result = await createInspectionResult(myInfo.hospitalHashId, inspectionHashId, {
          hospitalProductHashId,
          category: 'in_hospital',
          scheduledTime: scheduledDate,
          inspectorHashId: inspector,
          status: 'draft',
          items: {},
          isAdhoc: isAdhoc ? true : undefined,
        });
        // NOTE:_InspectionResultでuseLocation経由で受け取るためのParam
        const nextUpdateInspectionStatusParam: NextUpdateInspectionStatusParam = {
          status: 'uncompleted',
          inspectorHashId: inspector,
        };
        navigate(`/inspections/${result.data.inspectionHashId}/result/${result.data.hashId}`, {
          state: nextUpdateInspectionStatusParam,
        });
      } catch (err) {
        console.error(err);
      }
    },
    [myInfo.hashId, myInfo.hospitalHashId, navigate]
  );

  const handleClickStartMakerInspection = useCallback(async () => {
    try {
      const res = await dialogHandler.open<
        CreateMakerInspectionResultDialogProps,
        CreateMakerInspectionResultDialogResult
      >(CreateMakerInspectionResultDialog, {
        defaultInspectorHashId: myInfo.hashId,
        defaultScheduledDate: convertDateToSimpleDate(new Date()),
      });

      if (!res) return;
      const result = await createMakerInspectionResult(myInfo.hospitalHashId, {
        hospitalProductHashId: res.hospitalProductHashId,
        scheduledTime: res.scheduledDate,
        inspectorUserHashId: res.inspector,
      });
      if (result && result.data) navigate(`/maker_inspections/results/${result.data.hashId}`);
    } catch (err) {
      console.error(err);
    }
  }, [myInfo.hashId, myInfo.hospitalHashId, navigate]);

  const handleMoveClick = useCallback(() => {
    // TODO 後でv2の遷移先を変更する
    navigate(`/inspection_v2/tables`);
  }, [navigate]);

  const [type] = useInspectionType();

  return (
    <Grid container>
      <Grid item>
        <Grid container>
          <Grid item>
            <PageRoutingMenu>
              <Typography id="tutorial-inspection-list" variant={'h5'} className={classes.pageTitle}>
                点検
              </Typography>
              <ArrowDropDown />
            </PageRoutingMenu>
          </Grid>
        </Grid>
      </Grid>
      <div className={classes.flex} />
      <Grid item>
        {type === 'periodic' && isOnline && (
          <Button
            variant={'outlined'}
            color={'primary'}
            style={{background: '#FFFFFF', marginRight: '8px'}}
            onClick={handleClickExportPeriodicInspectionSchedule}>
            点検計画出力
          </Button>
        )}
      </Grid>
      <Grid item>
        {type !== 'maker_periodic' && isOnline && !isReadOnly && (
          <Button
            variant={'outlined'}
            color={'primary'}
            style={{background: '#FFFFFF', marginRight: '8px'}}
            onClick={handleMoveClick}>
            点検設定
          </Button>
        )}
      </Grid>
      {!isReadOnly && (
        <Grid item>
          {type === 'maker_periodic' ? (
            <Button variant={'contained'} color={'primary'} onClick={handleClickStartMakerInspection}>
              点検結果を記録
            </Button>
          ) : (
            <Button variant={'contained'} color={'primary'} onClick={handleClickStartInspection}>
              臨時点検を開始
            </Button>
          )}
        </Grid>
      )}
    </Grid>
  );
};
