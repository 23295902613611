import React from 'react';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import {
  Button,
  CSSObject,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  Typography,
} from '@mui/material';
import {Close} from '@material-ui/icons';

const TitleStyle: CSSObject = {
  color: '#172B4D',
  fontSize: '20px',
  fontWeight: 700,
  lineHeight: '24px',
};

const ListStyle: CSSObject = {
  listStyle: 'inside',
  paddingLeft: '7px',
  paddingBottom: 4,
};

export type InspectionWholeProductPlanUpdateAlertDialogProps = DialogProps;

/**
 * 対象の点検に関連する点検表の変更を確認するダイアログ
 *
 * @example
 * こんな感じで使ってください
 * ```tsx
 * dialogHandler.open(InspectionWholeProductPlanUpdateAlertDialog, {}).then(() => {
 *   // resolve時の処理
 * }).catch(() => {
 *   // reject時の処理
 * });
 * ```
 *
 * @param props.open ダイアログを開くかどうか
 * @param props.actions ダイアログの結果を返すためのresolve, reject関数
 */
export const InspectionWholeProductPlanUpdateAlertDialog = (
  props: InspectionWholeProductPlanUpdateAlertDialogProps
) => {
  const {open, actions} = props;

  const handleClose = () => {
    actions.reject();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
      <DialogTitle sx={TitleStyle}>
        <Grid container justifyContent="space-between" alignItems="center">
          <p>{`使用する点検表の変更を保存しますか？`}</p>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid item>
          <Typography component={'p'}>
            保存した内容は、以下に使用される点検表に反映されます。
            <List sx={ListStyle} disablePadding>
              <li>未実施の点検実施予定</li>
              <li>予定月超過の点検実施予定</li>
            </List>
          </Typography>
        </Grid>
        <Grid item>
          <Typography component={'p'}>
            以下に使用される点検表には反映されません。
            <List sx={ListStyle} disablePadding>
              <li>スキップした点検実施予定</li>
              <li>実施途中の点検</li>
              <li>完了した点検</li>
            </List>
          </Typography>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={actions.resolve}>
          保存する
        </Button>
        <Button onClick={handleClose} color="primary">
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
};
