import React, {Suspense} from 'react';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import {Form, Formik} from 'formik';
import {InferType} from 'yup';
import {WholeProductEditDialogBody} from './WholeProductEditDialogBody';
import {WholeProductEditDialogResult, isWholeProductEditDialogResult, validationSchema} from './type';
import {initialValues} from './const';
import {openSnackBar} from '@components/molecules/SnackBar';

export type WholeProductEditDialogProps = {
  recommendResult?: WholeProductEditDialogResult;
  title: string;
  content?: string | React.ReactNode;
  alertContent?: boolean;
  paperStyle?: React.CSSProperties;
} & DialogProps;

type FormValues = {
  rootCategoryHashId?: string;
  narrowCategoryHashId?: string;
  displayName?: string | null;
  name?: string | null;
  makerHashId?: string | null;
  approvalNumber?: string | null;
  // NOTE:JMDNコードはnumber型だがバリデーションをstringで行っている
  jmdnCode?: string | number | null;
  janCode?: string | null;
  isSpecificMaintain?: boolean | null;
  className?: string | null;
  catalogPrice?: number | null;
};

type ProductRegistrationFormType = InferType<typeof validationSchema>;

/**
 * 新規機種の手入力ダイアログ
 * @param param0
 * @returns
 */
export const WholeProductEditDialog = ({
  open,
  actions,
  recommendResult,
  title,
  content,
  alertContent,
  paperStyle,
}: WholeProductEditDialogProps) => {
  const handleSubmit = (res: FormValues) => {
    // JMDNコードはstringでバリデーションを行っているがAPI側はnumber型である必要があるためキャスト
    res = {...res, jmdnCode: res.jmdnCode ? Number(res.jmdnCode) : null};
    if (isWholeProductEditDialogResult(res)) {
      actions.resolve(res);
    } else {
      openSnackBar('入力したデータが不正です', 'left', 'bottom', 'error');
      actions.reject();
    }
  };

  const handleClose = () => {
    actions.reject();
  };

  const isInitialValid = Boolean(
    recommendResult &&
      recommendResult.rootCategoryHashId !== '' &&
      recommendResult.narrowCategoryHashId !== '' &&
      recommendResult.displayName !== '' &&
      recommendResult.name !== ''
  );

  return (
    <Formik<ProductRegistrationFormType>
      initialValues={recommendResult || initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      isInitialValid={isInitialValid}>
      <Form>
        <Suspense fallback={<div>Loading...</div>}>
          <WholeProductEditDialogBody
            open={!!open}
            onClose={handleClose}
            title={title}
            content={content}
            alertContent={alertContent}
            recommendMakerHashId={recommendResult?.makerHashId ?? undefined}
            recommendMakerName={recommendResult?.newMakerName ?? undefined}
            paperStyle={paperStyle}
          />
        </Suspense>
      </Form>
    </Formik>
  );
};
