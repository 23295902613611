import React from 'react';
import {TableViewLayout} from '@components/layouts/TableViewLayout';
import {DisplayNumberSelect} from '@molecules/DisplayNumberSelect';
import {Grid, Pagination, styled} from '@mui/material';
import {useWholeProductsListContentFooter} from '@modules/hospital_whole_products_bulk_update/hooks';
import {useAtomValue} from 'jotai';
import {wholeProductTotalCount} from '@modules/hospital_whole_products_bulk_update/jotai';

/**
 * 機種一覧ページ機種テーブルフッター
 */
export const WholeProductsListContentFooter = () => {
  const totalCount = useAtomValue(wholeProductTotalCount);
  const {page, setPage, pageSize, setPageSize, totalPage, startDisplayPosition, endDisplayPosition, handleChangePage} =
    useWholeProductsListContentFooter(totalCount);
  return (
    <TableViewLayout.Footer container justifyContent="space-between">
      <PageDescriptionGrid item>
        {totalCount}件のうち{startDisplayPosition}件目-{endDisplayPosition}件目までを表示しています
      </PageDescriptionGrid>
      <PaginationContainerGrid item>
        <Pagination page={page} count={totalPage} shape="rounded" onChange={handleChangePage} />
        <DisplayNumberSelect
          pageSize={pageSize}
          update={(selectNum) => {
            setPageSize(selectNum);
            setPage(1);
          }}
        />
      </PaginationContainerGrid>
      <EmptyGrid />
    </TableViewLayout.Footer>
  );
};

const PageDescriptionGrid = styled(Grid)({
  margin: 'auto 0px',
  flex: 1,
});

const PaginationContainerGrid = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  fontSize: '0.875rem',
});

const EmptyGrid = styled(Grid)({
  flex: 1,
});
