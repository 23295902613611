import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from '@material-ui/core';
import React, {Suspense, useCallback, useMemo, useState} from 'react';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import {Form, Formik, useFormikContext} from 'formik';
import {yup} from '@front-libs/core';
import Selector from '@molecules/Formik/fields/Selector';
import {fetchHospitalWards} from '@modules/hospital_wards/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {HospitalWard} from '@modules/hospital_wards/types';
import {useAsyncEffect} from '@front-libs/core';

type OptionProps = {value: string; label: string};
type HospitalRoomFormProps = {
  onSelectHospitalRoom: (options: OptionProps) => void;
};

const RegistrationForm: React.FC<HospitalRoomFormProps> = ({onSelectHospitalRoom}) => {
  const {myInfo} = useMyInfo();

  const [hospitalSettings, setHospitalSettings] = useState<HospitalWard[]>([]);
  useAsyncEffect(async () => {
    const res = await fetchHospitalWards(myInfo.hospitalHashId, {
      page: 0,
      perPage: 100,
    });
    setHospitalSettings(res.data);
  }, []);

  const hospitalWardsOpts = useMemo(() => {
    return hospitalSettings.map((item) => ({
      value: item.hashId,
      label: item.name,
    }));
  }, [hospitalSettings]);

  const handleChangeWholeProduct = useCallback(
    (e: OptionProps) => {
      onSelectHospitalRoom(e);
    },
    [onSelectHospitalRoom]
  );

  return (
    <div style={{width: '100%', marginBottom: '16px'}}>
      <label>大エリア</label>
      {hospitalWardsOpts.length && (
        <Selector
          name="hospitalWardHashId"
          size={'small'}
          options={hospitalWardsOpts}
          onChange={handleChangeWholeProduct}
        />
      )}
    </div>
  );
};

const HospitalRoomForm: React.FC<RoomEditationDialogProps> = (props) => {
  const {submitForm, setFieldValue, values, isValid} = useFormikContext();
  const handleClickResist = useCallback(async () => {
    await submitForm();
    await props.actions.resolve(values);
  }, [props.actions, submitForm, values]);

  return (
    <Dialog
      open={props.open ?? false}
      onClose={props.actions.reject}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth={'md'}>
      <DialogTitle>{props.count}件の小エリアを大エリアに追加</DialogTitle>
      <DialogContent>
        <Grid container>
          <Form style={{width: '100%'}}>
            <div style={{marginBottom: '16px'}}>
              <Suspense fallback={null}>
                <RegistrationForm
                  onSelectHospitalRoom={(options) => {
                    setFieldValue('hospitalWardHashId', options.value);
                  }}
                />
              </Suspense>
            </div>
          </Form>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button disabled={!isValid} variant={'contained'} color="primary" onClick={handleClickResist}>
          登録
        </Button>
        <Button onClick={props.actions.reject} color="primary">
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
};

type RoomEditationDialogProps = {
  count: number;
} & DialogProps;

type RoomEditationDialogActions = {
  hospitalWardHashId?: string;
};

export const RoomBulkEditationDialog: React.FC<RoomEditationDialogProps> = (props) => {
  const validationSchema = yup.object({
    hospitalWardHashId: yup.string().required(),
  });

  const handleSubmit = async (res: RoomEditationDialogActions) => {
    await props.actions.resolve(res);
  };

  return (
    <Formik initialValues={{}} onSubmit={handleSubmit} validationSchema={validationSchema} isInitialValid={false}>
      <HospitalRoomForm {...props} />
    </Formik>
  );
};
