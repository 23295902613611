import React from 'react';
import {Action, Mode, RecommendState} from '../common/type';
import {
  COMPLETE_MODE,
  ERROR_ACTION,
  ERROR_MODE,
  LOADING_MODE,
  NOT_FOUND_ACTION,
  NOT_FOUND_MODE,
  SEARCH_COMPLETE_ACTION,
  SEARCH_START_ACTION,
} from '../common/const';

export const reducer = (state: RecommendState, action: Action): RecommendState => {
  switch (action.type) {
    case SEARCH_START_ACTION:
      return {...state, mode: LOADING_MODE, isSearching: true};
    case SEARCH_COMPLETE_ACTION:
      return {...state, searchResult: action.payload, mode: COMPLETE_MODE, isSearching: false, isBlocked: true};
    case NOT_FOUND_ACTION:
      return {...state, mode: NOT_FOUND_MODE, isSearching: false, searchResult: null};
    case ERROR_ACTION:
      return {...state, mode: ERROR_MODE, isSearching: false};
    default:
      return state;
  }
};
