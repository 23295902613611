import React, {memo} from 'react';
import {Box, FormControlLabel, styled, Checkbox} from '@mui/material';
import {FormLabelBadge} from '@components/molecules/FormLabelBadge';

const GridBox = styled(Box)({
  display: 'grid',
  gridTemplateColumns: '320px 32px',
  alignItems: 'center',
});

type Props = {
  label: string;
  name: string;
  checked: boolean | undefined;
  onChange: (name: string, checked: boolean) => void;
};
/**
 * 「任意」表示のラベルバッチとチェックボックスを表示するコンポーネント
 * ラベルバッチの表示を切り替える場合は別途対応を入れる
 * @param param0
 * @returns
 */
export const LabelBadgeAndCheckbox = memo(({onChange, checked, name, label}: Props) => {
  return (
    <GridBox>
      <FormControlLabel
        name={name}
        onChange={(_e, _checked) => {
          onChange(name, _checked);
        }}
        control={<Checkbox checked={checked} color="primary" />}
        label={label}
        labelPlacement="end"
        value={checked}
      />
      <FormLabelBadge padding="1px 6px" />
    </GridBox>
  );
});
