import {axios, BaseListResult} from '@front-libs/core';
import {TrainingTypeValue} from '@modules/training_plans/types';
import {TrainingSchedule, ScheduleStatus, TrainingReport} from './types';
import {FileIndex} from '@modules/files/types';

export type CreateTrainingScheduleParam = {
  startAt: Date;
  finishAt: Date;
  status?: ScheduleStatus;
  place?: string;
  numberOfTrainees?: number;
  wholeProductHashIds: string[];

  trainingPlanHashId?: string;
  trainingPlanName?: string;
  trainingPlanTrainingTypes: string[];
};

export type FetchTrainingSchedulesParam = {
  statuses?: ScheduleStatus[];
  wholeProductHashIds?: string[];
  name?: string;
  trainingTypes?: TrainingTypeValue[];
  trainingPlanHashIds?: string[];
  page?: number;
  perPage?: number;
  order?: string;
};

export type FetchTrainingReportsParam = {
  name?: string;
  trainingScheduleHashIds?: string[];
  trainingPlanHashIds?: string[];
  page?: number;
  perPage?: number;
};

export type FetchTrainingSchedulesResult = BaseListResult<TrainingSchedule>;
export type FetchTrainingReportsResult = BaseListResult<TrainingReport>;
export type UpdateTrainingScheduleParam = {
  trainingScheduleHashId: string;
  startAt?: string;
  finishAt?: string;
  status?: ScheduleStatus;
  place?: string;
  numberOfTrainees?: string;
  wholeProductHashIds?: string[];
};
export type DeleteTrainingScheduleParam = {
  trainingScheduleHashId: string;
};

export type CreateTrainingScheduleMaterialsParam = {
  fileHashIds: string[];
};

export type CreateTrainingScheduleTraineeListsParam = {
  fileHashIds: string[];
};

export const createTrainingSchedule = async (hospitalHashId: string, data: CreateTrainingScheduleParam) => {
  const baseURL = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/training_managements/schedules`;
  return await axios.post<TrainingSchedule>(baseURL, data);
};

export const getTrainingSchedules = async (hospitalHashId: string, params: FetchTrainingSchedulesParam) => {
  const baseURL = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/training_managements/schedules`;
  const {data} = await axios.get<FetchTrainingSchedulesResult>(baseURL, {
    params: {
      ...params,
      statuses: params.statuses?.join(','),
      wholeProductHashIds: params.wholeProductHashIds?.join(','),
      trainingTypes: params.trainingTypes?.join(','),
      trainingPlanHashIds: params.trainingPlanHashIds?.join(','),
    },
  });
  return data;
};

export const getTrainingSchedule = async (hospitalHashId: string, trainingScheduleHashId: string) => {
  const baseURL = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/training_managements/schedules/${trainingScheduleHashId}`;
  const {data} = await axios.get<TrainingSchedule>(baseURL);
  return data;
};

export const getTrainingReports = async (hospitalHashId: string, params: FetchTrainingReportsParam) => {
  const baseURL = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/training_managements/reports`;
  const {data} = await axios.get<FetchTrainingReportsResult>(baseURL, {
    params: {...params, trainingScheduleHashIds: params.trainingScheduleHashIds?.join(',')},
  });
  return data;
};

export const updateTrainingSchedule = async (hospitalHashId: string, data: UpdateTrainingScheduleParam) => {
  const baseURL = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/training_managements/schedules/${data.trainingScheduleHashId}`;
  return await axios.put<TrainingSchedule>(baseURL, data);
};

export const deleteTrainingSchedule = async (hospitalHashId: string, trainingScheduleHashId: string) => {
  const baseURL = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/training_managements/schedules/${trainingScheduleHashId}`;
  return await axios.delete<void>(baseURL);
};

export const getTrainingScheduleMaterials = async (
  hospitalHashId: string,
  trainingScheduleHashId: string,
  order?: string
) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/training_managements/schedules/${trainingScheduleHashId}/materials`;
  const response = await axios.get<FileIndex[]>(baseUrl, {params: {order}});
  return response.data;
};

export const createTrainingScheduleMaterials = async (
  hospitalHashId: string,
  trainingScheduleHashId: string,
  data: CreateTrainingScheduleMaterialsParam
) => {
  const baseURL = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/training_managements/schedules/${trainingScheduleHashId}/materials`;
  return await axios.post<void>(baseURL, data);
};

export const getTrainingScheduleTraineeLists = async (
  hospitalHashId: string,
  trainingScheduleHashId: string,
  order?: string
) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/training_managements/schedules/${trainingScheduleHashId}/trainee_lists`;
  const response = await axios.get<FileIndex[]>(baseUrl, {params: {order}});
  return response.data;
};

export const createTrainingScheduleTraineeLists = async (
  hospitalHashId: string,
  trainingScheduleHashId: string,
  data: CreateTrainingScheduleTraineeListsParam
) => {
  const baseURL = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/training_managements/schedules/${trainingScheduleHashId}/trainee_lists`;
  return await axios.post<void>(baseURL, data);
};
