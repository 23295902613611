import React from 'react';
import {HospitalProductPlanListElement} from '../../types';
import {Grid} from '@mui/material';

type InspectionPeriodColumnProps = {
  rowData: HospitalProductPlanListElement;
};

export const InspectionPeriodColumn = ({rowData}: InspectionPeriodColumnProps) => {
  return <>{rowData.inspectionPeriod && <Grid>{rowData.inspectionPeriod}ヶ月</Grid>}</>;
};
