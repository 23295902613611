import {styled} from '@mui/material/styles';
import {Brightness1} from '@mui/icons-material';
import React from 'react';
import {inspectionStatusType} from '@constants/constants';

const FlexBox = styled('span')({
  display: 'flex',
  alignItems: 'center',
});

const StatusColor = styled(Brightness1)({
  width: '10px',
  marginTop: '-1px',
  position: 'absolute',
});

const Label = styled('span')({
  marginLeft: '16px',
});

type Props = {
  status: 'available' | 'draft';
};

/**
 * InspectionStatusコンポーネント
 * @param {Props} props - statusプロパティを含むプロパティ
 * @returns {JSX.Element} インスペクションステータスを表示するコンポーネント
 */
export const InspectionStatus: React.FC<Props> = ({status}) => {
  const inspectionStatusConst = inspectionStatusType[status] || {
    color: '#FFF',
    label: '',
  };

  return (
    <FlexBox>
      <StatusColor style={{color: inspectionStatusConst.color}} />
      <Label>{inspectionStatusConst.label}</Label>
    </FlexBox>
  );
};
