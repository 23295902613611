import React, {ChangeEvent} from 'react';
import {Box, Typography, TextField} from '@mui/material';
import {FormLabelBadgeNarrow} from '@molecules/FormLabelBadge/FormLabelBadgeNarrow';
import {Controller, Control, FieldValues, Path} from 'react-hook-form';

export type RequiredOptionalInputProps<T extends FieldValues> = {
  label: string;
  placeholder?: string;
  required?: boolean;
  control: Control<T>;
  name: Path<T>;
  handleChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
};

/**
 * ReactHookFormを利用した入力フォーム
 * 必須項目と任意項目のラベルを持つ
 */
export const RequiredOptionalInput = <T extends FieldValues>({
  label,
  placeholder,
  required,
  handleChange,
  control,
  name,
}: RequiredOptionalInputProps<T>) => (
  <Box>
    <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', mb: '8px', gap: '8px'}}>
      <Typography sx={{fontSize: '14px', minWidth: '72px'}}>{label}</Typography>
      {required !== undefined && <FormLabelBadgeNarrow required={required} />}
    </Box>
    <Controller
      name={name}
      control={control}
      render={({field}) => (
        <TextField
          {...field}
          fullWidth
          size="small"
          label=""
          placeholder={placeholder}
          onChange={(e) => {
            field.onChange(e);
            handleChange && handleChange(e);
          }}
        />
      )}
    />
  </Box>
);
