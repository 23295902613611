import React from 'react';
import {Box, Typography, styled} from '@mui/material';
import {FaultReceptionStatus} from '@modules/fault_receptions/types';
import {FaultReceptionStatusBadge} from '@components/atoms/FaultReceptionStatusBadge';

const StatusDisplayContainer = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
});

const ReceiptNumber = styled(Typography)(({title_color}: {title_color?: string}) => ({
  fontWeight: 'bold',
  color: title_color || '#172B4D',
  fontSize: '18px',
}));

type FaultReceptionStatusDisplayProps = {
  titleColor?: string;
  receiptNumber: string;
  status?: FaultReceptionStatus;
};

/**
 * 受付番号とステータスバッジの表示コンポーネント
 * @param param0
 * @returns
 */
export const FaultReceptionStatusDisplay = ({receiptNumber, status, titleColor}: FaultReceptionStatusDisplayProps) => {
  return (
    <StatusDisplayContainer>
      <ReceiptNumber title_color={titleColor}>{`受付番号：${receiptNumber}`}</ReceiptNumber>
      {status && <FaultReceptionStatusBadge status={status} />}
    </StatusDisplayContainer>
  );
};
