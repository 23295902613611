// 使用前点検のデータを取得し、indexedDBに保存する
import {getDateCalculations, upsertAllPagesForInspectionResult} from './helper';

/**
 * 使用前点検のデータを取得し、IndexedDBに保存します。
 *
 * - 未実施 (`unplanned`) のデータを全件取得し保存します。
 * - 実施途中 (`uncompleted`) のデータを全件取得し保存します。
 * - 完了 (`completed`) のデータを2日前から全件取得し保存します。
 *
 * @param hospitalHashId - データを取得する対象となる病院のハッシュID。
 * @returns Promise<void> - 非同期操作であり、すべてのデータが保存されるまで完了しません。
 */
export const getOfflineInspectionResultForPreUse = async (hospitalHashId: string, updatedAt?: string) => {
  // 2日前のデータを取得
  const {twoDaysAgo} = getDateCalculations();
  // 未実施のデータの全件取得、保存
  await upsertAllPagesForInspectionResult(
    hospitalHashId,
    'pre_use',
    'unplanned',
    0,
    {
      order: 'scheduled_time',
    },
    updatedAt
  );

  // 実施途中のデータの全件取得、保存
  await upsertAllPagesForInspectionResult(
    hospitalHashId,
    'pre_use',
    'uncompleted',
    0,
    {
      order: 'scheduled_time',
    },
    updatedAt
  );

  await upsertAllPagesForInspectionResult(
    hospitalHashId,
    'pre_use',
    'completed',
    0,
    {
      order: 'completed_at',
      completedAtFrom: twoDaysAgo,
    },
    updatedAt
  );
};

/**
 * 使用中点検のデータを取得し、IndexedDBに保存します。
 *
 * - 未実施 (`unplanned`) のデータを全件取得し保存します。
 * - 実施途中 (`uncompleted`) のデータを全件取得し保存します。
 * - 完了 (`completed`) のデータを2日前から全件取得し保存します。
 *
 * @param hospitalHashId - データを取得する対象となる病院のハッシュID。
 * @returns Promise<void> - 非同期操作であり、すべてのデータが保存されるまで完了しません。
 */
export const getOfflineInspectionResultForInUse = async (hospitalHashId: string, updatedAt?: string) => {
  // 2日前の日時を取得
  const {twoDaysAgo} = getDateCalculations();
  // 未実施のデータの全件取得、保存
  await upsertAllPagesForInspectionResult(
    hospitalHashId,
    'in_use',
    'unplanned',
    0,
    {
      order: 'scheduled_time',
    },
    updatedAt
  );

  // 実施途中のデータの全件取得、保存
  await upsertAllPagesForInspectionResult(
    hospitalHashId,
    'in_use',
    'uncompleted',
    0,
    {
      order: 'scheduled_time',
    },
    updatedAt
  );

  await upsertAllPagesForInspectionResult(
    hospitalHashId,
    'in_use',
    'completed',
    0,
    {
      order: 'completed_at',
      completedAtFrom: twoDaysAgo,
    },
    updatedAt
  );
};

/**
 * 使用後点検のデータを取得し、IndexedDBに保存します。
 *
 * - 未実施 (`unplanned`) のデータを全件取得し保存します。
 * - 実施途中 (`uncompleted`) のデータを全件取得し保存します。
 * - 完了 (`completed`) のデータを2日前から全件取得し保存します。
 *
 * @param hospitalHashId - データを取得する対象となる病院のハッシュID。
 * @returns Promise<void> - 非同期操作であり、すべてのデータが保存されるまで完了しません。
 */
export const getOfflineInspectionResultForPostUse = async (hospitalHashId: string, updatedAt?: string) => {
  // 2日前の日時を取得
  const {twoDaysAgo} = getDateCalculations();
  // 未実施のデータの全件取得、保存
  await upsertAllPagesForInspectionResult(
    hospitalHashId,
    'post_use',
    'unplanned',
    0,
    {
      order: 'scheduled_time',
    },
    updatedAt
  );

  // 実施途中のデータの全件取得、保存
  await upsertAllPagesForInspectionResult(
    hospitalHashId,
    'post_use',
    'uncompleted',
    0,
    {
      order: 'scheduled_time',
    },
    updatedAt
  );

  await upsertAllPagesForInspectionResult(
    hospitalHashId,
    'post_use',
    'completed',
    0,
    {
      order: 'completed_at',
      completedAtFrom: twoDaysAgo,
    },
    updatedAt
  );
};

/**
 * 定期点検のデータを取得し、IndexedDBに保存します。
 *
 * - 来月末までの未実施 (`unplanned`) のデータを取得し保存します。
 * - 実施途中 (`uncompleted`) のデータを全件取得し保存します。
 * - 完了 (`completed`) のデータを2ヶ月前から全件取得し保存します。
 *
 * @param hospitalHashId - データを取得する対象となる病院のハッシュID。
 * @returns Promise<void> - 非同期操作であり、すべてのデータが保存されるまで完了しません。
 */
export const getOfflineInspectionResultForPeriodic = async (hospitalHashId: string, updatedAt?: string) => {
  const {oneMonthLater, twoMonthsAgo} = getDateCalculations();
  // 来月までの未実施のデータ取得、保存
  await upsertAllPagesForInspectionResult(
    hospitalHashId,
    'periodic',
    'unplanned',
    0,
    {
      order: 'scheduled_time',
      scheduledAtTo: oneMonthLater,
    },
    updatedAt
  );

  // 実施途中のデータの全件取得、保存
  await upsertAllPagesForInspectionResult(
    hospitalHashId,
    'periodic',
    'uncompleted',
    0,
    {
      order: 'scheduled_time',
    },
    updatedAt
  );

  // 2ヶ月後のデータを取得
  await upsertAllPagesForInspectionResult(
    hospitalHashId,
    'periodic',
    'completed',
    0,
    {
      order: 'completed_at',
      completedAtFrom: twoMonthsAgo,
    },
    updatedAt
  );
};
