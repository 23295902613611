import React, {useState} from 'react';
import {styled, Box, Button, DialogActions, DialogTitle, TextField} from '@mui/material';
import {InspectionResultIndex} from '@modules/inspection_results/types';
import {RequiredLabel} from '@molecules/FormRequiredLabel';
import {openSnackBar} from '@components/molecules/SnackBar';
import {InspectionSummary, buildInspectionSummaryRows} from '../InspectionSummary';

type Props = {
  inspectionResult: InspectionResultIndex;
  onBack: () => void;
  onSubmit: (scheduledTime: string) => Promise<void>;
};

const Description = styled(Box)({
  marginTop: '32px',
});

const InputForm = styled(Box)({
  marginTop: '24px',
});

const Actions = styled(DialogActions)({
  marginTop: '40px',
  '& > button:nth-of-type(n+2)': {
    marginLeft: '16px',
  },
});

export const InspectionUpdateScheduledTime = ({inspectionResult, onBack, onSubmit}: Props) => {
  const [scheduledTime, setScheduledTime] = useState<string | null>(null);
  const [scheduled, setScheduled] = useState<boolean>(false);
  const canSubmit = !!scheduledTime;

  const resetInputValues = () => {
    setScheduledTime(null);
    setScheduled(false);
  };

  const handleChangeScheduledTime = (event: React.ChangeEvent<HTMLInputElement>) => {
    setScheduledTime(event.target.value);
  };

  const handleBack = () => {
    resetInputValues();
    onBack();
  };

  const handleSubmit = async () => {
    if (!canSubmit) return;

    try {
      await onSubmit(scheduledTime);
    } catch (error) {
      console.error(error);
      openSnackBar('点検予定日の更新に失敗しました', 'left', 'bottom', 'error');
      return;
    }

    setScheduled(true);
  };

  if (scheduled) {
    return <DialogTitle sx={{padding: 0, minWidth: '586px', fontWeight: 700}}>点検予定日を更新しました</DialogTitle>;
  }

  return (
    <>
      <DialogTitle sx={{padding: 0, minWidth: '586px', fontWeight: 700}}>点検予定日を変更</DialogTitle>
      <Description>
        <InspectionSummary data={buildInspectionSummaryRows(inspectionResult)} />
      </Description>
      <InputForm>
        <RequiredLabel>変更後の点検予定日</RequiredLabel>
        <TextField
          type="date"
          variant="outlined"
          size="small"
          fullWidth
          value={scheduledTime}
          onChange={handleChangeScheduledTime}
        />
      </InputForm>
      <Actions>
        <Button variant="text" sx={{color: '#0052CC'}} onClick={handleBack}>
          前に戻る
        </Button>
        <Button
          variant="contained"
          sx={{backgroundColor: '#0052CC', '&.Mui-disabled': {color: '#FFFFFF'}}}
          disabled={!canSubmit}
          onClick={handleSubmit}>
          保存
        </Button>
      </Actions>
    </>
  );
};
