import React, {useMemo} from 'react';
import {Form} from 'formik';
import {Grid, makeStyles} from '@material-ui/core';
import {DateTimeField, TextField, DateField} from '@molecules/Formik/fields';
import Selector from '@molecules/Formik/fields/Selector';
import {PropertyRequirements, RepairIndex} from '@modules/repairs/types';
import {propertyField} from '../../constants';
import {UserIndex} from '@modules/hospital_users/types';

type RequirementValidationDialogProps = {
  propertyRequirements: PropertyRequirements[];
  defaultFaultRepair: RepairIndex;
  hospitalUsers: UserIndex[];
};

export const PropertyForm: React.FC<RequirementValidationDialogProps> = (props) => {
  const classes = useStyles();
  const concreteProperties = useMemo(() => propertyField(props.hospitalUsers), [props.hospitalUsers]);
  return (
    <Form className={classes.root}>
      <Grid>
        {props.propertyRequirements.map((item, index) => {
          // FIXME: 型むずくて避けた
          // biome-ignore lint/suspicious/noExplicitAny: <explanation>
          const prop = (concreteProperties as any)[item.property];
          return (
            <div key={index} className={classes.fieldContainer}>
              <label className={classes.fieldLabel}>{prop.label}</label>
              {prop.type === 'text' ? (
                <TextField name={item.property} size={'small'} fullWidth />
              ) : prop.type === 'selector' ? (
                <Selector name={item.property} size={'small'} fullWidth options={prop.options} />
              ) : prop.type === 'date' ? (
                <DateField name={item.property} size={'small'} fullWidth />
              ) : prop.type === 'datetime' ? (
                <DateTimeField name={item.property} size={'small'} fullWidth />
              ) : prop.type === 'number' ? (
                <TextField inputProps={{type: 'number', min: 0}} name={item.property} size={'small'} fullWidth />
              ) : null}
            </div>
          );
        })}
      </Grid>
    </Form>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  fieldContainer: {
    width: '100%',
    marginBottom: '16px',
  },
  fieldLabel: {
    fontSize: 14,
    marginBottom: 4,
  },
}));
