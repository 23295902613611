import {dialogHandler} from '@components/molecules/Dialogs/DialogHandlerV5';
import {ProductStatusV5} from '@components/molecules/ProductStatus/ProductStatusV5';
import {AlertDialog, AlertDialogProps, openSnackBar} from '@front-libs/ui';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {deleteUnitRelations} from '@modules/unit_relations/api';
import {useFetchUnitRelationsQuery} from '@modules/unit_relations/hooks';
import type {HospitalProduct} from '@modules/unit_relations/types';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {
  Box,
  Button,
  CircularProgress,
  Link,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import React, {useImperativeHandle, useMemo} from 'react';
import {Link as RouterLink} from 'react-router-dom';

const TableStyle: SxProps = {
  width: '100%',
  maxWidth: '1024px',
  height: '100%',
  overflow: 'auto',
  '& td, & th': {
    border: 0,
  },
};

const ManagementIdStyle: SxProps<Theme> = {
  '& .MuiLink-root': {
    fontWeight: 'bold',
    color: (theme) => theme.palette.secondary.dark,
  },
};
const emptyContentAreaTextStyle: SxProps = {fontSize: '14px'};
const maxWidthStyle: SxProps = {
  maxWidth: '150px',
};

const LoadingBox = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <CircularProgress />
    </Box>
  );
};

type DeleteTargetType = {
  hospitalHashId: string;
  parentHashId: string | undefined;
  childHashId: string;
  deleteMessage: React.ReactNode;
  deleteCallback: () => void;
};

const DeleteMessageWithImpactWarning = () => {
  return (
    <Box sx={{fontSize: '14px', '& ul': {paddingInlineStart: '24px'}}}>
      こちらの機器は貸出中です。この操作を実行すると、以下の事象が発生します。
      <ul>
        <li>こちらの機器の返却時、関連する親機・子機が「返却する医療機器リスト」に表示されなくなる</li>
        <li>関連する親機・子機の返却時、こちらの機器が「返却する医療機器リスト」に表示されなくなる</li>
      </ul>
      この操作を実行しますか？実行すると元に戻せません。
    </Box>
  );
};

const DEFAULT_DELETE_MESSAGE = 'この操作を実行しますか？実行すると元に戻せません。';

async function confirmDelete(content: React.ReactNode): Promise<boolean> {
  try {
    await dialogHandler.open<AlertDialogProps>(AlertDialog, {
      title: '関連する子機の削除',
      content: content,
      positiveButtonLabel: '実行する',
    });

    return true;
  } catch (_e) {
    return false;
  }
}

const handleClickDelete = async ({
  hospitalHashId,
  parentHashId,
  childHashId,
  deleteMessage,
  deleteCallback,
}: DeleteTargetType) => {
  const isSubmit = await confirmDelete(deleteMessage);
  if (!isSubmit) return;

  if (!parentHashId) {
    openSnackBar('子機の削除時にエラーが発生しました。機器詳細を開き直してご確認ください。', 'error');
    return;
  }

  try {
    await deleteUnitRelations(hospitalHashId, parentHashId, childHashId);
    openSnackBar('関連する子機を削除しました。', 'success', {
      onRendered: () => {
        // レンダリング完了後にコールバックを実行
        deleteCallback();
      },
    });
  } catch (_) {
    openSnackBar('子機の削除時にエラーが発生しました。', 'error');
  }
};

const DeleteButton = (target: DeleteTargetType) => {
  return (
    <Button variant="text" startIcon={<DeleteOutlineOutlinedIcon />} onClick={() => handleClickDelete(target)}>
      削除
    </Button>
  );
};

const RowHead = [
  {label: '管理番号', minWidth: '100px'},
  {label: '稼働状況', minWidth: '84px'},
  {label: '機種名', minWidth: '150px'},
  {label: '型式', minWidth: '100px'},
  {label: '', minWidth: '84px'},
];

/**
 *
 * @param param0
 * @returns
 */
export const ChildUnitTable = React.forwardRef(({hashId}: {hashId: string}, ref) => {
  const {myInfo} = useMyInfo();
  const {data, isLoading, refetch, error} = useFetchUnitRelationsQuery(myInfo.hospitalHashId, hashId);
  const parentProduct = useMemo(() => (data?.parentHospitalProduct ? [data.parentHospitalProduct] : []), [data]);
  const childProducts = useMemo(() => data?.childHospitalProducts || [], [data]);
  const allProducts = [...childProducts, ...parentProduct];

  // 削除対象が「貸出中」ステータスで、かつ削除対象以外の親子機器が１つ以上「貸出中」ステータスの場合に true を返す
  const isWarning = (p: HospitalProduct) => {
    if (p.status !== 'working') return false;
    return allProducts.some((product) => product.managementId !== p.managementId && product.status === 'working');
  };

  // ref を使って親から refetch を呼び出せるようにする
  useImperativeHandle(ref, () => ({
    refetchData: refetch,
  }));

  if (isLoading) {
    return <LoadingBox />;
  }

  if (!data || !data?.childHospitalProducts || error) {
    return (
      <Typography sx={emptyContentAreaTextStyle}>
        {error ? 'エラーが発生しました' : '関連する子機が登録されていません。'}
      </Typography>
    );
  }

  return (
    <TableContainer sx={TableStyle} component={Box}>
      <Table stickyHeader aria-label="simple table">
        <TableHead>
          <TableRow>
            {RowHead.map((row) => (
              <TableCell key={row.label} sx={{minWidth: row.minWidth}}>
                {row.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {childProducts.map((row, index) => (
            <TableRow key={`${row.managementId}${index}`}>
              <TableCell component="th" scope="row" sx={ManagementIdStyle}>
                <Link component={RouterLink} to={`/products/${row.hospitalProductHashId}`} underline="hover">
                  {row.managementId}
                </Link>
              </TableCell>
              <TableCell align="left" sx={maxWidthStyle}>
                <ProductStatusV5 productStatus={row.status} marginTop="0px" />
              </TableCell>
              <TableCell align="left" sx={maxWidthStyle}>
                {row.displayName}
              </TableCell>
              <TableCell align="left">{row.name}</TableCell>
              <TableCell align="left">
                <DeleteButton
                  hospitalHashId={myInfo.hospitalHashId}
                  parentHashId={data?.parentHospitalProduct?.hospitalProductHashId}
                  childHashId={row.hospitalProductHashId}
                  deleteMessage={isWarning(row) ? <DeleteMessageWithImpactWarning /> : DEFAULT_DELETE_MESSAGE}
                  deleteCallback={() => {
                    refetch();
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
});
