import React, {useCallback, useMemo} from 'react';
import {Button, ButtonProps, createStyles, makeStyles, Popover, Theme} from '@material-ui/core';
import {Calendar} from '@material-ui/pickers';
import dayjs, {Dayjs} from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isBetween from 'dayjs/plugin/isBetween';

import clsx from 'clsx';

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '8px 16px',
    },
    dateMenu: {
      fontWeight: 'bold',
      color: theme.palette.primary.dark,
    },
  })
);

type DatePickerProps = {
  value: Date | null;
  placeholder: string;
  onChange: (date: Date | null, isFinish?: boolean) => void;
  disableFuture?: boolean;
  disablePast?: boolean;
  maxDate?: Date;
  minDate?: Date;
  buttonProps?: ButtonProps;
  buttonColor?: string;
};

export const DatePicker: React.FC<DatePickerProps> = ({disableFuture = false, disablePast = false, ...props}) => {
  const {value, placeholder, onChange} = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

  const handleClick = useCallback((event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const date = useMemo((): dayjs.Dayjs => {
    const originalDate = value ? dayjs(value) : dayjs();
    if (props.minDate === undefined && props.maxDate === undefined) {
      return originalDate;
    }

    const isSameOrAfterMin = props.minDate === undefined || originalDate.isSameOrAfter(props.minDate);
    const isSameOrBeforeMax = props.maxDate === undefined || originalDate.isSameOrBefore(props.maxDate);

    // minDateとmaxDateの範囲内なので元々の値を設定する
    if (isSameOrAfterMin && isSameOrBeforeMax) {
      return originalDate;
    }

    // minDateが設定されていて、かつvalueがminDateより小さい場合はminDateにする
    if (props.minDate !== undefined && isSameOrAfterMin) {
      return dayjs(props.minDate);
    }

    // maxDateが設定されていて、かつvalueがmaxDateより大きい場合はmaxDateにする
    if (props.maxDate !== undefined && isSameOrBeforeMax) {
      return dayjs(props.maxDate);
    }

    if (props.minDate !== undefined && props.maxDate !== undefined) {
      // 与えられた値が範囲外かつ今日が範囲内なら今日を返す
      const today = dayjs();
      if (today.isBetween(props.minDate, props.maxDate, 'day', '[]')) {
        return today;
      }

      // 範囲の中間の日を返す
      return dayjs((props.minDate.getTime() + props.maxDate.getTime()) / 2).startOf('day');
    }

    return originalDate;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const buttonLabel = useMemo(() => {
    return value !== null ? dayjs(value).format('YYYY/MM/DD') : placeholder;
  }, [value, placeholder]);

  const handleChange = useCallback(
    // eslint-disable-next-line no-shadow
    (date: Dayjs | null, isFinish?: boolean) => {
      onChange(date ? date.toDate() : null, isFinish);

      if (isFinish) {
        setAnchorEl(null);
      }
    },
    [onChange]
  );

  return (
    <div>
      <Button color="inherit" onClick={handleClick} className={clsx(props.buttonProps?.className)}>
        <span style={{fontWeight: 'bold', color: props.buttonColor ?? '#003FB5'}}>{buttonLabel}</span>
      </Button>
      <Popover
        PaperProps={{className: classes.root}}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <Calendar
          date={date}
          onChange={handleChange}
          disableFuture={!!disableFuture}
          disablePast={!!disablePast}
          minDate={props.minDate && dayjs(props.minDate)}
          maxDate={props.maxDate && dayjs(props.maxDate)}
        />
      </Popover>
    </div>
  );
};
