import React from 'react';
import {SvgIcon, SvgIconProps} from '@mui/material';

export const PinIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.7067 14.1924C20.0972 13.8019 20.0972 13.1687 19.7067 12.7782L17.3955 10.467C17.3833 10.4542 17.3709 10.4414 17.3583 10.4288L13.5716 6.6421C13.559 6.6295 13.5462 6.61711 13.5334 6.60492L11.2214 4.29289C10.8309 3.90237 10.1977 3.90237 9.80716 4.29289C9.41664 4.68342 9.41664 5.31658 9.80716 5.70711L10.7424 6.6423C10.6736 6.71139 10.6089 6.78648 10.549 6.86753L6.94275 11.7466C6.3543 12.5427 6.43686 13.6495 7.13689 14.3496L8.03947 15.2522L4.14938 19.1422C3.95411 19.3375 3.95411 19.6541 4.14938 19.8494C4.34464 20.0446 4.66122 20.0446 4.85648 19.8494L8.74658 15.9593L9.65083 16.8635C10.3509 17.5635 11.4577 17.6461 12.2538 17.0576L17.1329 13.4514C17.2139 13.3915 17.289 13.3268 17.3581 13.258L18.2924 14.1924C18.683 14.5829 19.3161 14.5829 19.7067 14.1924Z"
    />
  </SvgIcon>
);
