import {FieldProps, useFormikContext} from 'formik';
import React, {ComponentType, useCallback, useState} from 'react';
import {InputActionMeta} from 'react-select';
import AsyncSelect from 'react-select/async';
import wrapField from './wrapField';
import {theme} from '@atoms/theme';

type Props = {
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  loadOptions: (inputValue: string, callback: (options: ReadonlyArray<any>) => void) => Promise<any>;
  isDisabled: boolean;
  placeholder?: string;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  defaultValue?: any;
  onChange?: (e: React.ChangeEvent) => void;
  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
} & FieldProps;

export const AsyncSelector: React.FC<Props> = (props) => {
  const {field, loadOptions, isDisabled, defaultValue, placeholder, onInputChange, onChange} = props;
  const [inputValue, setInputValue] = useState('');
  const {setFieldValue} = useFormikContext();

  const handleChange = useCallback(
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    (option: any) => {
      if (onChange) onChange(option);
      setFieldValue(field.name, option.value);
    },
    [field.name, onChange, setFieldValue]
  );

  return (
    <AsyncSelect
      menuPortalTarget={document.body}
      styles={{
        menuPortal: (provided) => ({...provided, zIndex: theme.zIndex.modal + 1}),
      }}
      inputValue={inputValue}
      onChange={handleChange}
      onInputChange={(newValue: string, actionMeta: InputActionMeta) => {
        setInputValue(newValue);
        if (onInputChange) onInputChange(newValue, actionMeta);
      }}
      placeholder={placeholder ?? '検索...'}
      defaultValue={defaultValue}
      isDisabled={isDisabled}
      cacheOptions
      loadOptions={loadOptions}
      defaultOptions={true}
    />
  );
};

export default wrapField(AsyncSelector as ComponentType<FieldProps>);
