import React from 'react';
import {Grid, ListItem, ListItemText, Switch} from '@mui/material';

type Props = {
  label: string;
  value: string;
  required: boolean;
  onRequiredPropertyAdd: (property: string) => void;
  onRequiredPropertyDelete: (property: string) => void;
};

export const ProductRequiredPropertiesSettingFormInputItem = (props: Props) => {
  const handleChange = () => {
    switch (props.required) {
      case true:
        props.onRequiredPropertyDelete(props.value);
        break;
      case false:
        props.onRequiredPropertyAdd(props.value);
        break;
    }
  };

  return (
    <ListItem>
      <Grid container>
        <Grid item xs={10}>
          <ListItemText>{props.label}</ListItemText>
        </Grid>
        <Grid item xs={2}>
          <Switch
            checked={props.required}
            onChange={handleChange}
            color="primary"
            inputProps={{'aria-label': 'primary checkbox'}}
          />
        </Grid>
      </Grid>
    </ListItem>
  );
};
