import React from 'react';
import {styled, TableHead, TableRow, TableCell} from '@mui/material';
import {InspectionType} from '@components/organisms/Header/pc/GeneralMenu/HospitalProductMenu/types';

type Props = {
  inspectionType: InspectionType;
};

const Th = styled(TableCell)({
  border: 0,
  padding: '8px 16px',
  color: 'inherit',
});

export const InspectionResultSelectTableHead = ({inspectionType}: Props) => {
  const renderScheduledTime = () => {
    if (inspectionType !== 'periodic') return null;

    return <Th align="left">点検予定日</Th>;
  };

  const renderNumberOfSameDayFormerResults = () => {
    if (inspectionType !== 'pre_use') return null;

    return <Th align="left">当日の点検完了回数</Th>;
  };

  return (
    <TableHead>
      <TableRow>
        <Th />
        {renderScheduledTime()}
        <Th align="left">ステータス</Th>
        <Th align="left">点検名</Th>
        <Th align="left">使用する点検表</Th>
        {renderNumberOfSameDayFormerResults()}
        <Th align="left">中断日時</Th>
        <Th align="left">臨時点検</Th>
        <Th align="left">最終点検者</Th>
      </TableRow>
    </TableHead>
  );
};
