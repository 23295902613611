import React, {useEffect} from 'react';
import {Box, Button, styled, SxProps} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {selectedReturnWaitingAreaInfoAtom, selectedReturnWaitingReceptionAtom} from '../states';
import {ReceptionSelectionMap} from '../consts';
import {WardAssetMenuTemplate} from '@components/templates/WardAssetMenuTemplate';
import {useSetAtom} from 'jotai';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

const CircleIcon = () => {
  return (
    <Box
      sx={{
        width: '96px',
        height: '96px',
        backgroundColor: '#3C9E58',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <CheckRoundedIcon sx={{color: 'white', fontSize: '72px'}} />
    </Box>
  );
};

const flexCenter: SxProps = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};
const buttonStyle: SxProps = {
  fontWeight: 'bold',
  fontSize: '24px',
  color: '#0052CC',
  backgroundColor: 'white',
  padding: '24px 32px',
  borderRadius: '20px',
  boxShadow: '0px 6px 0px 0px rgba(209, 216, 245, 1)',
  minWidth: '160px',
  '&:hover': {
    backgroundColor: 'white',
    boxShadow: '0px 6px 0px 0px rgba(209, 216, 245, 1)',
  },
};

const StyledTitle = styled(Box)({
  color: '#374659',
  fontSize: '48px',
  fontWeight: 'bold',
  paddingLeft: '24px',
});

export const ReturnWaitingReceptionComplete = () => {
  const navigate = useNavigate();
  const setSelectItem = useSetAtom(selectedReturnWaitingReceptionAtom);
  const setAreaInfo = useSetAtom(selectedReturnWaitingAreaInfoAtom);

  useEffect(() => {
    setSelectItem({});
    setAreaInfo({});
  }, []);

  const onClick = () => {
    navigate(ReceptionSelectionMap.rootPath);
  };
  return (
    <WardAssetMenuTemplate headerProps={{title: ReceptionSelectionMap.title}} sx={{...flexCenter, gap: '80px'}}>
      <Box sx={flexCenter}>
        <CircleIcon />
        <StyledTitle>返却待ち受付が完了しました</StyledTitle>
      </Box>
      <Button variant="contained" onClick={onClick} sx={buttonStyle}>
        終了する
      </Button>
    </WardAssetMenuTemplate>
  );
};
