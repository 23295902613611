export type TableLayoutKey =
  | 'productList'
  | 'rentalList'
  | 'inspectionList'
  | 'inspectionTableList'
  | 'hospitalProductPlanList'
  // FIXME: HIT-3551のincident対応で固定値対応に変更。
  // | 'inspectionProductList'
  | 'inspectionHospitalProductsList'
  | 'WholeProductPlansList'
  | 'inspectionResultFileList'
  | 'inspectionResultList'
  | 'productListInInspectionPlanSetting'
  | 'inspectionPlanList'
  | 'userList'
  | 'repairList'
  | 'repairKanban'
  | 'hospitalFileList'
  | 'fileList'
  | 'documentList'
  | 'hospitalWardList'
  | 'hospitalRoomList'
  | 'newInspectionProductsList'
  | 'dashboardMonthlyList'
  | 'dashboardRentalHistory'
  | 'dashboardProductCommentList'
  | 'dashboardNonReturnedList'
  | 'dashboardRepairCommentList'
  | 'signupProductList'
  | 'appendImportProductList'
  | 'trainingPlanList'
  | 'trainingScheduleList'
  | 'trainingReportList'
  | 'inspectionProductStartDateList'
  | 'makerInspectionResultList'
  | 'faultReceptionList'
  | 'receptionList'
  | 'wholeProductList';

export type TableLayoutType = {
  [key in TableLayoutKey]: {
    title: string;
    field: string;
    disabledSelect?: boolean;
    defaultShow: boolean;
  }[];
};

/** TableLayoutKeyの型ガード */
export function isTableLayoutKey(key: string): key is TableLayoutKey {
  const validKeys: TableLayoutKey | string[] = [
    'productList',
    'rentalList',
    'inspectionList',
    'inspectionTableList',
    'hospitalProductPlanList',
    // 'inspectionProductList',
    'inspectionHospitalProductsList',
    'inspectionResultFileList',
    'inspectionResultList',
    'productListInInspectionPlanSetting',
    'inspectionPlanList',
    'userList',
    'repairList',
    'repairKanban',
    'hospitalFileList',
    'fileList',
    'documentList',
    'hospitalWardList',
    'hospitalRoomList',
    'newInspectionProductsList',
    'dashboardMonthlyList',
    'dashboardRentalHistory',
    'dashboardProductCommentList',
    'dashboardNonReturnedList',
    'dashboardRepairCommentList',
    'signupProductList',
    'appendImportProductList',
    'trainingPlanList',
    'trainingScheduleList',
    'trainingReportList',
    'makerInspectionResultList',
    'faultReceptionList',
    'receptionList',
  ];

  return validKeys.includes(key);
}
