import React from 'react';
import {DialogProps} from '@components/molecules/Dialogs/DialogHandler';
import {Button, CSSObject, Dialog, DialogActions, DialogContent, DialogTitle, Typography, styled} from '@mui/material';
import {CheckCircle} from '@mui/icons-material';
import {green} from '@mui/material/colors';

const defaultFontSize: CSSObject = {fontSize: '14px'};
const StyledDialogContent = styled(DialogContent)(({theme}) => ({
  paddingBottom: '48px',
  '& p': defaultFontSize,
  '& li': defaultFontSize,
  '& ul': {margin: '4px 0 24px'},
}));

export type FaultRepairConfirmDialogProps = DialogProps & {
  repairRegistrationNumber: string;
};

/**
 * 修理受付番号確認ダイアログ
 *
 * @example
 * こんな感じで使ってください
 * ```tsx
 * dialogHandler.open(FaultRepairConfirmDialog, {}).then(() => {
 *  // actions.resolve
 * }).catch(() => {
 *  // actions.reject
 * });
 * ```
 *
 * @param props.open ダイアログを開くかどうか
 * @param props.actions ダイアログの結果を返すためのresolve, reject関数
 * @param props.repairRegistrationNumber 修理受付番号
 */
export const FaultRepairConfirmDialog: React.FC<FaultRepairConfirmDialogProps> = (props) => {
  const {open, actions, repairRegistrationNumber} = props;

  const handleClose = () => {
    actions.resolve();
  };

  return (
    <Dialog open={!!open} onClose={handleClose} maxWidth="sm" sx={{color: '#172B4D'}}>
      <DialogTitle sx={{fontSize: '20px', fontWeight: 700}}>
        <CheckCircle sx={{color: green[500], paddingRight: '8px', top: '4px', position: 'relative'}} />
        修理の新規登録が完了しました
      </DialogTitle>
      <StyledDialogContent>
        <Typography>修理番号は「{repairRegistrationNumber}」です。</Typography>
      </StyledDialogContent>
      <DialogActions sx={{marginTop: 0}}>
        <Button onClick={handleClose}>閉じる</Button>
      </DialogActions>
    </Dialog>
  );
};
