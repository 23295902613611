import React, {Suspense, useState, useCallback} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Typography,
  IconButton,
} from '@material-ui/core';
import {Close} from '@material-ui/icons';
import {Form, Formik, useFormikContext} from 'formik';
import {TextField} from '@molecules/Formik/fields';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import * as yup from 'yup';
import {RequiredLabel} from '@molecules/FormRequiredLabel';

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '24px 32px 40px',
  },
  title: {
    color: '#172B4D',
  },
  content: {
    padding: '0px 32px',
  },
  fields: {
    '& > :not(:first-child)': {
      marginTop: '24px',
    },
  },
  inputDate: {
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  actions: {
    padding: '24px 32px 32px',
  },
}));

type UpdateScheduledDateFormProps = {
  open: boolean;
  onClose: React.MouseEventHandler;
};

type UpdateScheduledDateFormValue = {
  scheduled_date: string;
};

const UpdateScheduledDateForm: React.FC<UpdateScheduledDateFormProps> = (props) => {
  const {open, onClose} = props;
  const classes = useStyles();
  const {submitForm, isValid} = useFormikContext<UpdateScheduledDateFormValue>();

  const handleSubmit = useCallback(() => {
    submitForm();
  }, [submitForm]);

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="sm">
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Typography className={classes.title} style={{fontSize: '20px', fontWeight: 'bold'}}>
          点検予定日を変更
        </Typography>
        <IconButton aria-label="close" onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container className={classes.fields} direction="column">
          <Grid>
            <RequiredLabel>変更後の点検予定日時</RequiredLabel>
            <TextField type="date" name="scheduled_date" size="small" fullWidth />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button variant="contained" color="primary" onClick={handleSubmit} disabled={!isValid}>
          保存
        </Button>
        <Button variant="text" onClick={onClose}>
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export type UpdateScheduledDateDialogProps = DialogProps;

export type UpdateScheduledDateDialogResult = {
  scheduledDate: Date;
};

export const UpdateScheduledDateDialog: React.FC<UpdateScheduledDateDialogProps> = (props) => {
  const {open} = props;

  const [validationSchema] = useState(() =>
    yup.object({
      scheduled_date: yup.string().required(),
    })
  );

  const handleSubmit = useCallback(
    async (data: UpdateScheduledDateFormValue) => {
      const {scheduled_date} = data;
      props.actions.resolve({
        scheduledDate: new Date(scheduled_date),
      });
    },
    [props.actions]
  );

  const handleClose = useCallback(
    (e: React.MouseEvent) => {
      props.actions.reject();
    },
    [props.actions]
  );

  return (
    <Suspense fallback={null}>
      <Formik
        initialValues={{scheduled_date: ''}}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        isInitialValid={false}>
        <Form>
          <UpdateScheduledDateForm open={open} onClose={handleClose} />
        </Form>
      </Formik>
    </Suspense>
  );
};
