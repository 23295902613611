import React from 'react';
import {FolderOutlined} from '@mui/icons-material';
import {Tab} from './types';

export const tabValues = ['file'] as const;

export const tabs: readonly Tab[] = [
  {
    icon: <FolderOutlined />,
    label: 'ファイル',
    value: 'file',
  },
] as const;

export const initTabValue = tabs[0].value;
