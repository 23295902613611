import {UserIndex} from '@modules/hospital_users/types';
import {InspectionResultStatus, InspectionResultEvaluation} from '@modules/inspection_results/enum';
import {InspectionIndex, InspectionTableIndex} from '@modules/inspections/types';
import {FileIndex} from '@modules/files/types';
import {InspectionType} from '@modules/inspections/enum';
import {HospitalProductIndex} from '../hospital_products/types';
import {InspectionProduct} from '../inspection_setting/api';

export type InspectionResultIndex = {
  hashId: string;
  hospitalHashId: string;
  hospitalProductHashId: string;
  hospitalProduct: HospitalProductIndex;
  inspectionHashId: string;
  inspectionTableHashId: string;
  inspectorHashId: string | null;
  scheduledTime: string | null;
  type: InspectionType | null;
  status: InspectionResultStatus;
  result: InspectionResultEvaluation;
  items: ResultItem[];
  comment: string;
  inspection: InspectionIndex;
  inspectionTable: InspectionTableIndex;
  inspectionProduct: InspectionProduct;
  isAdhocDate: boolean;
  files: FileIndex[];
  createdAt: string;
  updatedAt: string;
  completedAt: string;
  suspendedAt: string | null;
  inspector: UserIndex | null;
  createdBy: UserIndex;
  updatedBy: UserIndex;
  skipReason: string;
  skippedTime: string | null;
  numberOfSameDayFormerResults: number | null;
};

export type SelectResultItem = {
  id: string;
  type: 'select';
  value: string | null;
};

export type MultiSelectResultItem = {
  id: string;
  type: 'multi-select';
  values: string[] | null;
};

export type NumericResultItem = {
  id: string;
  type: 'numeric';
  value: string | null;
};

export type TextResultItem = {
  id: string;
  type: 'text';
  value: string | null;
};

export type MultilineTextResultItem = {
  id: string;
  type: 'multiline-text';
  value: string | null;
};

export type DateResultItem = {
  id: string;
  type: 'date';
  value: string | null;
};

export type TimeResultItem = {
  id: string;
  type: 'time';
  value: string | null;
};

export type RequestExportInspectionResultCSVsParam = {
  scheduledAtFrom: string;
  scheduledAtTo: string;
  statuses: string;
  types: string;
};

export type ResultItem =
  | SelectResultItem
  | MultiSelectResultItem
  | NumericResultItem
  | TextResultItem
  | MultilineTextResultItem
  | DateResultItem
  | TimeResultItem;

export enum InspectionResultActionType {
  INSPECTION_RESULT_SET_LIST = 'INSPECTION_RESULT_SET_LIST',
}

export type InspectionResultSetListAction = {
  type: InspectionResultActionType.INSPECTION_RESULT_SET_LIST;
  payload: {
    results: InspectionResultIndex[];
  };
};

export type InspectionResultAction = InspectionResultSetListAction;

export type InspectionResultState = {
  results: InspectionResultIndex[];
};
