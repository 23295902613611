import {TabValues} from '@front-libs/helpers';

export const tabs = [
  {
    label: '点検結果',
    value: 'items',
  },
  {
    label: 'ファイル',
    value: 'files',
  },
] as const;

export type TabKey = TabValues<(typeof tabs)[number]>;
