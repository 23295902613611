import React from 'react';
import {FaultReceptionLocationType} from '@modules/fault_receptions/types';

type Props = {
  location: FaultReceptionLocationType;
  locationName?: string;
};

export const LocationColumn = ({location, locationName}: Props) => {
  const maxLength = 12;

  if (location === 'other') {
    if (!locationName) return null;
    const isLongName = locationName.length > maxLength;
    const displayedText = isLongName ? `${locationName.slice(0, maxLength / 2)}…` : locationName;

    return <span>その他（{displayedText}）</span>;
  }

  if (location === 'designated_location') {
    return <span>所定の場所</span>;
  }

  return null;
};
