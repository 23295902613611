import {Box, SxProps, Theme} from '@mui/material';
import React from 'react';

type Props = {
  label: string;
  onClick: VoidFunction;
};

/**
 * SelectionButton コンポーネント
 *
 * このコンポーネントは、病棟や部屋などのレンタル項目を表すボタンです。
 * ボタンをクリックすることで、ユーザーが項目を選択できます。
 *
 * プロパティ:
 * @param label - ボタンに表示されるラベル
 * @param onClick - ボタン押下時のコールバック関数
 */
export const SelectionButton = ({label, onClick}: Props) => {
  return (
    <Box component="button" sx={buttonStyle} onClick={onClick}>
      {label}
    </Box>
  );
};

const buttonStyle: SxProps<Theme> = {
  display: 'block',
  borderRadius: '25px',
  boxShadow: '0px 3px 0px 0px #D1D8F5',
  fontSize: '25px',
  fontWeight: 'bold',
  textAlign: 'center',
  minHeight: '120px',
  padding: '8px',
  textOrientation: 'sideways',
  backgroundColor: 'white',
  border: 'none',
  color: (theme) => theme.palette.rent.main,
  '&:active': {
    color: (theme) => theme.palette.rent.light,
  },
};
