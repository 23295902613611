import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useTrainingScheduleStore} from '@modules/training_schedules/hooks/useTrainingSchedule';
import {TrainingScheduleState} from '@modules/training_schedules/types';

type TrainingSchedulesStoreType = {
  incompleteScheduleState: TrainingScheduleState;
  doneScheduleState: TrainingScheduleState;
  listIncompleteSchedules: (wholeProductHashId: string) => Promise<void>;
  listDoneSchedules: (wholeProductHashId: string) => Promise<void>;
};

export const useTrainingSchedulesStore = (): TrainingSchedulesStoreType => {
  const {myInfo} = useMyInfo();

  // 未完了タブ
  const {state: incompleteScheduleState, dispatchListTrainingSchedules: dispatchListIncompleteSchedules} =
    useTrainingScheduleStore();
  const listIncompleteSchedules = async (wholeProductHashId: string) => {
    await dispatchListIncompleteSchedules(myInfo.hospitalHashId, {
      page: 0,
      perPage: 100, // NOTE: ページネーションなしで100件まで取得する
      order: 'scheduleDateRange',
      statuses: ['incomplete'],
      wholeProductHashIds: [wholeProductHashId],
    });
  };

  // 完了タブ
  const {state: doneScheduleState, dispatchListTrainingSchedules: dispatchListDoneSchedules} =
    useTrainingScheduleStore();
  const listDoneSchedules = async (wholeProductHashId: string) => {
    await dispatchListDoneSchedules(myInfo.hospitalHashId, {
      page: 0,
      perPage: 100, // NOTE: ページネーションなしで100件まで取得する
      order: '-scheduleDateRange',
      statuses: ['done'],
      wholeProductHashIds: [wholeProductHashId],
    });
  };

  return {
    incompleteScheduleState,
    doneScheduleState,
    listIncompleteSchedules,
    listDoneSchedules,
  };
};
