import React, {useMemo} from 'react';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import {SxProps} from '@mui/material/styles';
import {DialogTitle} from '../../atom/DialogTitle';
import {DialogContent} from '../../atom/DialogContent';
import {DialogActions} from '../../atom/DialogActions';
import ErrorIcon from '@mui/icons-material/Error';
import {DialogProps} from './DialogHandlerProps';
import {StrUtil} from '@front-libs/helpers';

const AlertTitleStyle: SxProps = {
  display: 'flex',
  alignItems: 'center',
  fontWeight: 700,
  fontSize: '20px',
};

const IconStyle: SxProps = {
  color: '#C7243A',
  paddingRight: '8px',
  fontSize: '24px',
};

const AlertTitle = ({warning, children}: {warning: boolean; children: React.ReactNode}) => (
  <Box sx={AlertTitleStyle}>
    {warning && <ErrorIcon sx={IconStyle} />}
    {children}
  </Box>
);

const paperStyle: SxProps = {
  minWidth: '600px',
  maxWidth: '90%',
};
const contentStyle: SxProps = {
  display: 'flex',
  alignItems: 'center',
  padding: '32px',
};
const positiveButtonStyle: SxProps = {
  '&.MuiButton-containedError': {
    backgroundColor: '#C7243A',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#a21b2b',
    },
  },
};

const defaultProps = {
  positiveButtonLabel: '削除する',
  negativeButtonLabel: 'キャンセル',
  warning: true,
};

export type AlertDialogProps = {
  title: React.ReactNode;
  content: React.ReactNode;
  positiveButtonLabel?: string;
  negativeButtonLabel?: string;
  warning?: boolean;
  boldTitle?: boolean;
} & DialogProps;

/**
 *
 * 汎用的なアラートダイアログ
 * @param props
 * @returns
 */
export const AlertDialog = (props: AlertDialogProps) => {
  const {
    open,
    actions,
    title,
    content,
    positiveButtonLabel = defaultProps.positiveButtonLabel,
    negativeButtonLabel = defaultProps.negativeButtonLabel,
    warning = defaultProps.warning,
  } = props;

  const shapedContent = useMemo(() => (typeof content === 'string' ? StrUtil.nl2br(content) : content), [content]);

  return (
    <Dialog open={!!open} onClose={actions.reject} PaperProps={{sx: paperStyle}}>
      <DialogTitle onClose={actions.reject}>
        <AlertTitle warning={warning}>{title}</AlertTitle>
      </DialogTitle>
      <DialogContent sx={contentStyle}>{shapedContent}</DialogContent>
      <DialogActions>
        <Button onClick={actions.resolve} variant="contained" color="error" sx={positiveButtonStyle}>
          {positiveButtonLabel}
        </Button>
        <Button onClick={actions.reject} color="inherit">
          {negativeButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
