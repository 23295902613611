import React from 'react';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  styled,
  Typography,
} from '@material-ui/core';
import ProgressImage from './progress.png';
import {Close} from '@material-ui/icons';

const StyledTitle = styled(DialogTitle)({
  color: '#172B4D',
  fontSize: '20px',
  fontWeight: 700,
  lineHeight: '24px',
});

export type InspectionScheduleConfigurationNavigateDialogProps = DialogProps;

/**
 * 機種別設定への勧奨アナウンスダイアログ
 *
 * @example
 * こんな感じで使ってください
 * ```tsx
 * dialogHandler.open(InspectionScheduleConfigurationNavigateDialog, {}).then(() => {
 *  // actions.resolve
 * }).catch(() => {
 *  // actions.reject
 * });
 * ```
 *
 * @param props.open ダイアログを開くかどうか
 * @param props.actions ダイアログの結果を返すためのresolve, reject関数
 */
export const InspectionScheduleConfigurationNavigateDialog: React.FC<
  InspectionScheduleConfigurationNavigateDialogProps
> = (props) => {
  const {open, actions} = props;

  const handleClose = () => {
    actions.reject();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
      <StyledTitle disableTypography>
        <Grid container justifyContent="space-between" alignItems="center">
          <p>{`続いて、機種別設定を行いますか？`}</p>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Grid>
      </StyledTitle>
      <DialogContent>
        <Grid container direction="column" style={{gap: '48px'}}>
          <Grid item>
            <Typography component={'p'}>
              作成した点検表は「機種別設定」で利用します。
              <br />
              続いて機種別設定を行う場合は、「機種別設定を行う」をクリックしてください。
            </Typography>
          </Grid>
          <Grid item>
            <img src={ProgressImage} alt="進捗" style={{width: '100%'}} />
          </Grid>
          <Grid item>
            <Typography component={'p'}>
              機種別設定は、「機種別設定」タブの「機種別に点検設定」ボタンから、
              <br />
              後ほど行うこともできます。
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={actions.resolve}>
          機種別点検設定を行う
        </Button>
        <Button onClick={handleClose} color="primary">
          後で設定する
        </Button>
      </DialogActions>
    </Dialog>
  );
};
