import React from 'react';
import {Typography} from '@material-ui/core';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import {KeyboardArrowUp} from '@material-ui/icons';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import {SectionStatus} from '@Apps/InspectionResult/sp/common/validator';
import {styled} from '@mui/material/styles';

type AccordionSummaryProps = {
  expanded: string;
};
const StyledAccordionSummary = styled(AccordionSummary)(({expanded}: AccordionSummaryProps) => ({
  margin: '0px 16px',
  padding: 0,
  '& > div.MuiAccordionSummary-content': {
    margin: '0px',
  },
  '&.MuiAccordionSummary-root.Mui-expanded': {
    minHeight: '0px',
  },
  '.MuiIconButton-label': {
    transform: expanded === 'true' ? 'rotate(0deg)' : 'rotate(90deg)',
  },
}));

const StyledTypography = styled(Typography)({
  position: 'relative',
  fontSize: '14px',
  fontWeight: 'bold',
  color: '#172B4D',
  margin: '0px',
});

const AbsoluteIcon: React.CSSProperties = {
  position: 'absolute',
  right: '24px',
  top: '12px',
};

const StyledCheckIcon = styled(CheckIcon)({
  fill: '#3C9E58',
  ...AbsoluteIcon,
});

const StyledErrorIcon = styled(ErrorIcon)({
  fill: '#C7243A',
  ...AbsoluteIcon,
});

type SectionHeaderProps = {
  name: string;
  expanded: boolean;
  inputStatus: SectionStatus;
};

/**
 *
 * ステータスに合わせてアイコンを返す
 * @param props.status ステータス情報
 * @returns
 */
const StatusIcon = ({status}: {status: SectionStatus}) => {
  switch (status) {
    case 'checked':
      return <StyledCheckIcon />;
    case 'error':
      return <StyledErrorIcon />;
    default:
      return null;
  }
};

/**
 * アコーディオンのヘッダー部分を表示する
 * このヘッダーには、セクションの名前が表示され、展開/折り畳みのアイコンが含まれています。
 *
 * @param props - コンポーネントに渡されるプロパティ
 * @param props.name - 表示されるセクションの名前
 * @param props.expanded - アコーディオンが展開されているかどうかを示すブール値。trueの場合、展開されている状態。
 * @returns アコーディオンのヘッダー部分をレンダリングするReactコンポーネント
 */
export const SectionHeader: React.FC<SectionHeaderProps> = ({name, expanded, inputStatus}) => {
  return (
    <StyledAccordionSummary
      expandIcon={<KeyboardArrowUp />}
      expanded={expanded.toString()}
      aria-controls="panel1a-content"
      id="panel1a-header">
      <StyledTypography>{name}</StyledTypography>
      <StatusIcon status={inputStatus} />
    </StyledAccordionSummary>
  );
};
