import React, {useMemo} from 'react';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import {Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, styled} from '@material-ui/core';
import {yup} from '@front-libs/core';
import {Formik, FormikHelpers} from 'formik';
import {Close} from '@material-ui/icons';
import Selector from '@molecules/Formik/fields/Selector';
import {RequiredLabel} from '@molecules/FormRequiredLabel';
import {isNullish} from '@front-libs/helpers';

const CURRENT_YEAR = new Date().getFullYear();

// 今から±10年を選択できるようにする
const YEAR_OPTIONS = Array.from({length: 21}, (_, index) => ({
  label: `${CURRENT_YEAR - 10 + index}年`,
  value: CURRENT_YEAR - 10 + index,
}));

// 月は1から12まで
// valueは0から11まで
const MONTH_OPTIONS = Array.from({length: 12}, (_, index) => ({
  label: `${index + 1}月`,
  value: index,
}));

const DAY_OPTIONS = Array.from({length: 28}, (_, index) => ({
  label: `${index + 1}日`,
  value: index + 1,
}));

const StyledTitle = styled(DialogTitle)({
  color: '#172B4D',
  fontSize: '20px',
  fontWeight: 700,
  lineHeight: '24px',
});

const validationSchema = yup.object({
  year: yup
    .number()
    .required()
    .max(CURRENT_YEAR + 10)
    .min(CURRENT_YEAR - 10), // yearは±10年が許容範囲
  month: yup.number().required().min(0).max(11), // monthは0-11で指定
  day: yup.number().required().min(1).max(28), // dayは28日まで。29日以降を指定しだすと月毎の繰り返し処理でバグるので28日。
});

export type InspectionScheduleSettingResult = yup.InferType<typeof validationSchema>;

export type InspectionScheduleConfigurationDialogProps = DialogProps<InspectionScheduleSettingResult, void> & {
  initialValues?: InspectionScheduleSettingResult;
};

/**
 * 点検開始日、月毎の点検日を設定するダイアログ
 * - 年は±10年まで選択できる
 * - 月は1-12まで選択できる（返却される値は0-11）
 * - 日は1-28まで選択できる
 *
 * @example
 * こんな感じで使ってください
 * ```tsx
 * const res = await dialogHandler.open<InspectionScheduleConfigurationDialogProps, InspectionScheduleConfigurationResult>(InspectionScheduleConfigurationDialog, {});
 * console.log(res);　// {year: 2021, month: 0, day: 28}
 * ```
 *
 * @param props.open ダイアログを開くかどうか
 * @param props.actions ダイアログの結果を返すためのresolve, reject関数
 * @param props.initialValues 初期値(optional) @defaultValue {year: currentYear, month: -1, day: -1}
 */
export const InspectionScheduleConfigurationDialog: React.FC<InspectionScheduleConfigurationDialogProps> = (props) => {
  const {open, actions, initialValues} = props;

  // 初期値は全部エラーになるようにとりあえずしとく
  const formInitialValues = useMemo(() => {
    return isNullish(initialValues) ? {year: CURRENT_YEAR, month: -1, day: -1} : initialValues;
  }, [initialValues]);

  const handleClose = () => {
    actions.reject();
  };

  const handleSubmit = (
    values: InspectionScheduleSettingResult,
    {setSubmitting}: FormikHelpers<InspectionScheduleSettingResult>
  ) => {
    actions.resolve(values);
    setSubmitting(false);
  };

  return (
    <Formik<InspectionScheduleSettingResult>
      initialValues={formInitialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}>
      {({submitForm, isSubmitting, dirty, isValid}) => (
        <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
          <StyledTitle disableTypography>
            <Grid container justifyContent="space-between" alignItems="center">
              <p>{`点検開始月・月毎の点検日を設定`}</p>
              <IconButton onClick={handleClose}>
                <Close />
              </IconButton>
            </Grid>
          </StyledTitle>
          <DialogContent>
            <Grid container direction="column" style={{gap: '24px'}}>
              <Grid item>
                <RequiredLabel>点検開始月</RequiredLabel>
                <Grid container style={{gap: '16px'}}>
                  <Grid item sm={5}>
                    <Selector name={'year'} size={'small'} placeholder={'年を選択'} fullWidth options={YEAR_OPTIONS} />
                  </Grid>
                  <Grid item sm={5}>
                    <Selector
                      name={'month'}
                      size={'small'}
                      placeholder={'月を選択'}
                      fullWidth
                      options={MONTH_OPTIONS}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={5}>
                <RequiredLabel>月毎の点検日</RequiredLabel>
                <Selector name={'day'} size={'small'} placeholder={'日を選択'} fullWidth options={DAY_OPTIONS} />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={submitForm}
              disabled={!isValid || !dirty || isSubmitting}>
              保存
            </Button>
            <Button onClick={handleClose} color="primary">
              キャンセル
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};
