import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Sidebar} from '@components/organisms/Sidebar';
import {Grid, Typography, styled, Box, FormHelperText, Theme} from '@mui/material';
import {NoteFieldDataTypes, HospitalProductNoteSettingsOptions} from '@modules/hospital_products/types';
import {
  StyledSettingsTempContent,
  StyledSettingsTempDivider,
  StyledSettingsTempFlex,
  StyledSettingsTempForm,
  StyledSettingsTempPageSubTitle1,
  StyledSettingsTempPageTitle,
  StyledSettingsTempSideBar,
} from '@templates/ContentLayout/InnerSidebarContentLayout';
import {useFormikContext} from 'formik';
import {DragDropContext, DropResult, Droppable} from 'react-beautiful-dnd';
import {DraggableItem} from './DraggableItem';
import {NameDrawer} from './NameDrawer';
import {NoteFieldDataTypeLabels} from './constants';
import {NoteField} from './types';
import {SelectTypeOptionCreateDialog} from './SelectTypeOptionCreateDialog';
import {SelectTypeOptionEditDialog} from './SelectTypeOptionEditDialog';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {AlertDialog} from '@components/molecules/Dialogs/AlertDialog';

// 備考1~3は名称以外変更不可
const DISABLE_NUM = 3;

const StyledSelectorTitleContainer = styled(Box)({
  marginTop: '24px',
});

const StyledSubtitle = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  margin: '26px 0px 14px',
});

const StyledTypography = styled(Typography)(({width}: {width: number}) => ({
  width: `${width}px`,
  textAlign: 'left',
  fontSize: '14px',
}));

const StyledFormHelperText = styled(FormHelperText)(({theme}: {theme: Theme}) => ({
  fontSize: 14,
  color: theme.palette.grey[600],
  margin: '8px 0px 8px',
}));

/**
 * タイトル部分
 * @todo 設定ページで共通化したい
 */
const Title = () => {
  return (
    <>
      <Grid container>
        <Grid item>
          <StyledSettingsTempPageTitle variant={'h5'}>機器管理</StyledSettingsTempPageTitle>
          <p>機器管理に関するユーザー共通設定を管理します。</p>
        </Grid>
        <StyledSettingsTempFlex />
      </Grid>
      <StyledSettingsTempDivider variant="middle" />
    </>
  );
};

/**
 * インフォメーション部分
 * @todo 設定ページで共通化したい
 */
const Information = () => {
  return (
    <Grid item>
      <StyledSettingsTempPageSubTitle1 variant={'h6'}>セットアップ</StyledSettingsTempPageSubTitle1>
      <StyledSelectorTitleContainer>
        <Typography>備考の設定</Typography>
        <StyledFormHelperText>機器詳細情報に表示する備考の名称等を編集することができます。</StyledFormHelperText>
      </StyledSelectorTitleContainer>
      <StyledSubtitle>
        <StyledTypography width={80}>備考</StyledTypography>
        <StyledTypography width={74}>表示</StyledTypography>
        <StyledTypography width={240}>名称</StyledTypography>
        <StyledTypography width={100}>データタイプ</StyledTypography>
      </StyledSubtitle>
    </Grid>
  );
};

/**
 * 機器管理に関するユーザー共通設定を管理するページ
 *
 * @param {NoteField[]} noteFields - 機器管理に関連する各ノートフィールドの配列
 */
export const ProductManagementForm = ({noteFields}: {noteFields: NoteField[]}) => {
  const {submitForm, values, setValues} = useFormikContext<NoteField[]>();
  // 備考名称に変更があったデータ
  const [changedNameData, setChangedNameData] = useState<{[id: string]: string}>({});
  // 各ノートフィールドの TextField の値を管理するステート
  const [textFieldValues, setTextFieldValues] = useState<{[id: string]: string}>({});

  const onDragEnd = useCallback((_result: DropResult) => {
    // 今回のスコープでは並べ替え実装は行わないので空関数
  }, []);

  // 備考欄の初期値
  const defaultTextValue = useMemo(() => {
    return noteFields.reduce(
      (acc, noteField) => {
        acc[noteField.id] = noteField.name;
        return acc;
      },
      {} as {[id: string]: string}
    );
  }, [noteFields]);

  // noteFields から textFieldValues の初期値を設定する
  useEffect(() => {
    setTextFieldValues(defaultTextValue);
  }, [defaultTextValue]);

  /** Drawerの入力を受け取る */
  const nameDrawerResult = useCallback(
    (result: {[id: string]: string}) => {
      if (Object.keys(result).length === 0) {
        setChangedNameData({});
        setTextFieldValues(defaultTextValue);
        return;
      }

      Object.keys(result).forEach((id) => {
        const value = values.find((val) => id === val.id);
        if (value) value.name = result[id];
      });

      setValues(values);
      setChangedNameData({});
      submitForm();
    },
    [defaultTextValue, setValues, submitForm, values]
  );

  /** 備考名入力時 */
  const nameChangeHandler = useCallback((id: string, newValue: string) => {
    setTextFieldValues((prev) => ({
      ...prev,
      [id]: newValue,
    }));
  }, []);

  /** 備考名のフォーカスが外れた時 */
  const nameBlueHandler = useCallback(
    (id: string, newValue: string) => {
      // 空文字が来たときは初期値に戻す
      if (!newValue) {
        const defaultText = defaultTextValue[id];
        setTextFieldValues((prev) => ({
          ...prev,
          [id]: defaultText,
        }));
        return;
      }
      setChangedNameData({...changedNameData, [id]: newValue});
    },
    [changedNameData, defaultTextValue]
  );

  /** 表示変更時 */
  const visibleChangeHandler = useCallback(
    (id: string, newVisible: boolean) => {
      const value = values.find((v) => v.id === id);
      if (!value) return;
      value.visible = newVisible;
      setValues(values);
      setChangedNameData({});
      submitForm();
    },
    [setValues, submitForm, values]
  );

  const openTypeChangeAlertDialog = async (name: string, type: NoteFieldDataTypes) => {
    try {
      await dialogHandler.open(AlertDialog, {
        title: 'データタイプの変更',
        content: `この操作を実行すると、すべての機器詳細情報から以下の情報が削除されます。

        ・「${name}」の${NoteFieldDataTypeLabels[type]}された情報
        
        データタイプの変更を実行しますか？
        この操作は元に戻せません。`,
        positiveButtonLabel: '変更を実行',
      });
    } catch (_e) {
      return false;
    }

    return true;
  };

  const openSelectTypeOptionCreateDialog = async (
    noteField: NoteField,
    onSubmit: (option: HospitalProductNoteSettingsOptions) => void
  ) => {
    try {
      await dialogHandler.open(SelectTypeOptionCreateDialog, {noteField, onSubmit});
    } catch (_e) {
      return false;
    }
    return true;
  };

  const openSelectTypeOptionEditDialog = async (
    noteField: NoteField,
    onSubmit: (option: HospitalProductNoteSettingsOptions) => void
  ) => {
    try {
      await dialogHandler.open(SelectTypeOptionEditDialog, {noteField, onSubmit});
    } catch (_e) {
      return false;
    }
    return true;
  };

  /** データタイプ変更時 */
  const typeChangeHandler = async (id: string, newType: NoteFieldDataTypes) => {
    const value = values.find((v) => v.id === id);
    if (!value) return;

    let result = false;
    switch (newType) {
      case 'text':
      case 'date':
        result = await openTypeChangeAlertDialog(value.name, value.type);
        if (result) value.option = null;
        break;
      case 'select':
        result = await openSelectTypeOptionCreateDialog(value, (option) => {
          value.option = option;
        });
        break;
    }
    if (!result) return;

    value.type = newType;
    setValues(values);
    setChangedNameData({});
    submitForm();
  };

  const handleSelectOptionEdit = async (id: string) => {
    const value = values.find((v) => v.id === id);
    if (!value) return;

    const result = await openSelectTypeOptionEditDialog(value, (option) => {
      value.option = option;
    });
    if (!result) return;

    setValues(values);
    setChangedNameData({});
    submitForm();
  };

  return (
    <>
      <StyledSettingsTempSideBar>
        <Sidebar />
      </StyledSettingsTempSideBar>
      <StyledSettingsTempForm>
        <StyledSettingsTempContent>
          <Title />
          <Grid container>
            <Information />
          </Grid>
          <Grid container>
            <Grid item>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="list">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {noteFields.map((noteField: NoteField, index: number) => (
                        <DraggableItem
                          noteField={noteField}
                          index={index}
                          key={noteField.id}
                          disable={index < DISABLE_NUM}
                          nameChangeHandler={nameChangeHandler}
                          nameBlueHandler={nameBlueHandler}
                          typeChangeHandler={typeChangeHandler}
                          visibleChangeHandler={visibleChangeHandler}
                          textFieldValue={textFieldValues[noteField.id] || ''}
                          onSelectOptionEdit={handleSelectOptionEdit}
                        />
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Grid>
          </Grid>
        </StyledSettingsTempContent>

        <NameDrawer nameData={changedNameData} result={nameDrawerResult} />
      </StyledSettingsTempForm>
    </>
  );
};
