import React from 'react';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  styled,
  Typography,
} from '@material-ui/core';
import ProgressImage from './progress.png';
import {Close} from '@material-ui/icons';

const StyledTitle = styled(DialogTitle)({
  color: '#172B4D',
  fontSize: '20px',
  fontWeight: 700,
  lineHeight: '24px',
});

export type InspectionPlanForWholeProductNavigationDialogProps = DialogProps;

/**
 * 続いて、定期点検計画を作成しますか？ダイアログ
 *
 * @example
 * こんな感じで使ってください
 * ```tsx
 * dialogHandler.open(InspectionPlanForWholeProductNavigationDialog, {}).then(() => {
 *  // actions.resolve
 * }).catch(() => {
 *  // actions.reject
 * });
 * ```
 *
 * @param props.open ダイアログを開くかどうか
 * @param props.actions ダイアログの結果を返すためのresolve, reject関数
 */
export const InspectionPlanForWholeProductNavigationDialog: React.FC<
  InspectionPlanForWholeProductNavigationDialogProps
> = (props) => {
  const {open, actions} = props;

  const handleClose = () => {
    actions.reject();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md">
      <StyledTitle disableTypography>
        <Grid container justifyContent="space-between" alignItems="center">
          <p>{`続いて、定期点検計画を作成しますか？`}</p>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Grid>
      </StyledTitle>
      <DialogContent>
        <Grid container direction="column" style={{gap: '48px'}}>
          <Grid item>
            <Typography component={'p'}>
              続いて定期点検計画を作成する場合は、「定期点検計画を作成」をクリックしてください。
              <br />
              定期点検計画を作成すると、点検実施予定が自動で作成されます。
              <br />
              （使用前・使用中・使用後点検に関しては、Step2の機種別設定後に自動で点検実施予定が作成されます）
            </Typography>
          </Grid>
          <Grid item>
            <img src={ProgressImage} alt="進捗" style={{width: '100%'}} />
          </Grid>
          <Grid item>
            <Typography component={'p'}>
              定期点検計画は、「定期点検計画」タブの一覧表から対象の点検を選択の上、「点検開始日・月ごとの点検日を設定」ボタンから、
              後ほど作成することもできます。
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={actions.resolve}>
          定期点検計画を作成
        </Button>
        <Button onClick={handleClose} color="primary">
          後で設定する
        </Button>
      </DialogActions>
    </Dialog>
  );
};
