import {isNullish} from '@front-libs/helpers';
import {useFetchHospitalWards} from '@modules/hospital_places/api';
import {HospitalRoom} from '@modules/hospital_places/types';
import {useFetchHospitalSettingQuery} from '@modules/hospital_settings/api';
import {rentalSettings} from '@modules/hospital_settings/types';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useFetchHospitalWardLayoutSetting} from '@modules/hospital_ward_layout_setting/api';
import {HospitalWard} from '@modules/hospital_wards/types';
import {Box, CSSObject, Grid, SxProps, Theme} from '@mui/material';
import {useAtomValue, useSetAtom} from 'jotai';
import _ from 'lodash';
import React, {useCallback, useEffect, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {Footer} from '../Footer';
import {Header} from '../Header';
import {RentalSelectionCard} from '../RentalSelectionCard';
import {SelectionSwiper} from '../SelectionSwiper';
import {
  allowRentalRoomUndefinedDataAtom,
  defaultBarcodeScannerTypeAtom,
  rentFloorAtom,
  rentRoomAtom,
  rentStepperAtom,
  rentWardAtom,
} from '../states';
import {contentFixedStyle} from '@components/templates/RentalTemplate';

/** 大エリアの選択画面の最大表示数 */
const MAX_WARDS_PER_PAGE = 18;

/**
 * 病棟選択画面
 *
 * @todo
 * 以下2つのファイルと共通化できるか検討
 * BaseSharedMenu/RoomSelection/index.tsx
 * BaseSharedMenu/FloorSelection/index.tsx
 */
export const WardSelection = () => {
  const navigate = useNavigate();
  const setRentalWard = useSetAtom(rentWardAtom);
  const setRentalFloor = useSetAtom(rentFloorAtom);
  const setRentalRoom = useSetAtom(rentRoomAtom);
  const setRentalStepper = useSetAtom(rentStepperAtom);
  const setAllowRentalRoomUndefinedData = useSetAtom(allowRentalRoomUndefinedDataAtom);
  const defaultBarcodeScannerType = useAtomValue(defaultBarcodeScannerTypeAtom);

  const {myInfo} = useMyInfo();
  const {data: wardsData} = useFetchHospitalWards(myInfo.hospitalHashId, {
    page: 0,
    perPage: 100,
    onlyRentableRooms: true,
  });

  const {data: wardLayoutSettingsData} = useFetchHospitalWardLayoutSetting(myInfo.hospitalHashId, myInfo.hashId);

  const filteredWardsData = useMemo(() => {
    const filtered = wardsData.filter((ward) => ward.numberOfRooms > 0);
    if (myInfo.rentalLayoutPreference === 'customize' && !isNullish(wardLayoutSettingsData)) {
      // myInfo.rentalLayoutPreferenceが'customize'の場合、
      // customSequenceに基づいてfilteredの順序を変更
      const customSequenceIds = wardLayoutSettingsData?.customSequence.map((item) => item.id) || [];

      // customSequenceIdsに存在するIDのみを抽出してfilteredの順序を変更
      const sortedFiltered = customSequenceIds
        .map((id) => filtered.find((ward) => ward.hashId === id))
        .filter((ward) => ward !== undefined) as HospitalWard[];

      return {data: _.chunk(sortedFiltered, MAX_WARDS_PER_PAGE), length: sortedFiltered.length};
    }
    return {data: _.chunk(filtered, MAX_WARDS_PER_PAGE), length: filtered.length};
  }, [wardsData, myInfo.rentalLayoutPreference, wardLayoutSettingsData]);

  const {data: allowRentalRoomUndefinedData} = useFetchHospitalSettingQuery(
    myInfo.hospitalHashId,
    rentalSettings.allow_undefined_room.key
  );

  const {data: skipFloorSelectionData} = useFetchHospitalSettingQuery(
    myInfo.hospitalHashId,
    rentalSettings.skip_floor_selection.key
  );

  const handleClickNextButton = useCallback(() => {
    setRentalRoom(null);
    defaultBarcodeScannerType === 'barcode_reader'
      ? navigate(`/shared/rental/product/barcode_reader`)
      : navigate(`/shared/rental/product/camera`);
  }, [defaultBarcodeScannerType, navigate, setRentalRoom]);

  const handleSelectChange = useCallback(
    (value: HospitalWard | HospitalRoom) => {
      if ('numberOfRooms' in value) {
        setRentalWard(value as HospitalWard);

        if (skipFloorSelectionData?.value === 'false') {
          navigate(`/shared/rental/floorSelection`);
        } else {
          setRentalFloor(null);
          navigate(`/shared/rental/roomSelection`);
        }
      }
    },
    [navigate, setRentalFloor, setRentalWard, skipFloorSelectionData?.value]
  );

  useEffect(() => {
    setRentalStepper(1);
    setAllowRentalRoomUndefinedData(allowRentalRoomUndefinedData?.value === 'true');
  }, [allowRentalRoomUndefinedData?.value, setAllowRentalRoomUndefinedData, setRentalStepper]);

  const GridComponent = () => {
    return (
      <Grid
        container
        justifyContent={'center'}
        alignItems="center"
        sx={[gridRootStyle, filteredWardsData.length > 4 && gridRootSecondLineStyle]}
        spacing={3}>
        {filteredWardsData.data[0] &&
          filteredWardsData.data[0].map((ward, index) => (
            <Grid item key={index} sx={filteredWardsData.length > 8 ? grid6ItemsStyle : grid4ItemsStyle}>
              <RentalSelectionCard
                label={ward.name}
                itemsCount={filteredWardsData.length}
                value={ward}
                onChange={handleSelectChange}
              />
            </Grid>
          ))}
      </Grid>
    );
  };

  return (
    <>
      <Header title={'貸出'} />
      <Box sx={contentFixedStyle}>
        {filteredWardsData.length <= 12 ? (
          <GridComponent />
        ) : (
          <SelectionSwiper<HospitalWard>
            data={filteredWardsData.data}
            getLabel={(item) => item.name}
            onSelect={(item) => handleSelectChange(item)}
          />
        )}
      </Box>
      <Footer
        text={'貸出先を選択してください'}
        nextButtonLabel={allowRentalRoomUndefinedData?.value === 'true' ? '貸出先を選択せず次へ' : undefined}
        onClickNextButton={handleClickNextButton}
      />
    </>
  );
};

const gridRootStyle: CSSObject = {
  margin: 'auto',
  flexWrap: 'nowrap',
  padding: '0px 80px 0px 56px',
};

const gridRootSecondLineStyle: CSSObject = {
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
};

const grid4ItemsStyle: SxProps<Theme> = {
  flexBasis: '25%',
};

const grid6ItemsStyle: SxProps<Theme> = {
  flexBasis: '16.66%',
};
