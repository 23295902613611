import React from 'react';
import Selector, {SingleValueProps, components, FormatOptionLabelMeta} from 'react-select';
import {isArray} from 'lodash';
import {FormControl, Box} from '@mui/material';
import {InspectionList} from '@modules/inspections/types';
import {InspectionTypeMap, InspectionType} from '@modules/inspections/enum';
import {theme} from '@atoms/theme';

type Option = {
  label: string;
  value: string;
  type: InspectionType;
};

/** 選択値の表示形式 */
const SingleValue: React.FC<SingleValueProps<Option>> = ({children: _children, ...props}) => {
  return <components.SingleValue {...props}>{props.data.label}</components.SingleValue>;
};

/** 選択肢の表示形式 */
const FormatOptionLabel = (option: Option, labelMeta: FormatOptionLabelMeta<Option, true | false>) => {
  // NOTE: selectValueの型は配列ではないが、実際には配列が入ってきてしまうため、配列として値を判定する
  const isActive = isArray(labelMeta.selectValue) && labelMeta.selectValue.some((v) => v.value === option.value);
  return (
    <Box>
      <Box>{option.label}</Box>
      <Box sx={{fontSize: 12, color: isActive ? '#FFFFFF' : '#5D6B82'}}>{InspectionTypeMap[option.type].label}</Box>
    </Box>
  );
};

type Props = {
  allInspections: InspectionList[];
  wholeProductInspections: InspectionList[];
  selectedHashId?: string;
  onSearch: (name: string) => void;
  onSelect: (hashId?: string) => void;
};

export const InspectionSelector: React.FC<Props> = ({
  allInspections,
  wholeProductInspections,
  selectedHashId,
  onSearch,
  onSelect,
}) => {
  const buildOption = (inspection: InspectionList): Option => ({
    label: inspection.name,
    value: inspection.hashId,
    type: inspection.type,
  });

  const inspectionOptions = [
    ...(wholeProductInspections.length > 0
      ? [{label: '機種に紐づく点検表', options: wholeProductInspections.map(buildOption)}]
      : []),
    {label: '全ての点検表', options: allInspections.map(buildOption)},
  ];
  const selectedOption = inspectionOptions.flatMap((o) => o.options).find((o) => o.value === selectedHashId);

  const handleSearch = (name: string) => {
    onSearch(name);
  };
  const handleSelect = (option: Option | null) => {
    onSelect(option?.value);
  };

  return (
    <FormControl variant="outlined" size={'small'} fullWidth>
      <Selector<Option, false>
        menuPortalTarget={document.body}
        size="small"
        placeholder="検索"
        styles={{
          control: (provided) => ({...provided, minHeight: '40px'}),
          menuPortal: (provided) => ({...provided, zIndex: theme.zIndex.modal * 10}),
        }}
        value={selectedOption}
        options={inspectionOptions}
        onInputChange={handleSearch}
        onChange={handleSelect}
        formatOptionLabel={FormatOptionLabel}
        components={{SingleValue, IndicatorSeparator: null}}
      />
    </FormControl>
  );
};
