import React from 'react';
import {Grid} from '@mui/material';
import {StyledSettingsTempPageTitle} from '@templates/ContentLayout/InnerSidebarContentLayoutV5';

export const ProductRequiredPropertiesSettingFormTitle = () => {
  return (
    <Grid container>
      <Grid item>
        <StyledSettingsTempPageTitle variant={'h5'}>機器登録</StyledSettingsTempPageTitle>
        <p>機器登録に関するユーザー共通設定を管理します。</p>
      </Grid>
    </Grid>
  );
};
