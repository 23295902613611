import React, {useCallback} from 'react';
import * as Yup from 'yup';
import {InferType} from 'yup';
import {Box, Paper, styled} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {Formik} from 'formik';
import {useAtom} from 'jotai';
import {FaultReceptionForm} from './FaultReceptionForm/FaultReceptionForm';
import {Footer} from './Footer';
import {FaultReceptionLocationType, FaultReceptionsDetail} from '@modules/fault_receptions/types';
import {FaultReceptionHeader} from '@components/molecules/FaultReceptionHeader';
import {FaultReceptionsDetailAtom} from './states';

const PaperContainer = styled(Paper)({
  backgroundColor: 'white',
  height: 'calc(100vh - 406px)', // Header 36・Footer 148 とMargin(32x2)+Padding(64x2) とヘッダーの高さを引いた値
  margin: '32px 64px',
  padding: '64px',
  overflow: 'auto',
  borderRadius: '20px',
});
const StyledBody = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
});

const validationSchema = Yup.object({
  productFeature: Yup.string().required(),
  faultDetail: Yup.string().required(),
  rentalRoomName: Yup.string().required(),
  reporterName: Yup.string().required(),
  location: Yup.string().required(),
  locationName: Yup.string().when('location', {
    is: (location: FaultReceptionLocationType) => location === 'other', // その他のときのみバリデーション
    then: Yup.string().required(),
    otherwise: Yup.string(),
  }),
  isNeedAlternativeDevice: Yup.boolean().notRequired(),
  isNeedUrgentResponse: Yup.boolean().notRequired(),
});

export type FaultReceptionFormType = InferType<typeof validationSchema>;

/**
 * 不具合受付入力画面
 * @returns
 */
export const FaultReceptionInput = () => {
  const navigate = useNavigate();
  const [faultReceptionsDetail, setFaultReceptionsDetail] = useAtom(FaultReceptionsDetailAtom);

  const initialValues: FaultReceptionFormType = {
    /** どんな機器ですかの回答 */
    productFeature: faultReceptionsDetail.productFeature || '',
    /** どんな不具合ですかの回答 */
    faultDetail: faultReceptionsDetail.faultDetail || '',
    /** 機器を使用していた場所の回答 */
    rentalRoomName: faultReceptionsDetail.rentalRoomName || '',
    /** この機器はどこで回収できるか */
    reporterName: faultReceptionsDetail.reporterName || '',
    /** 回収場所 初期値は空文字である必要があるので一時的に型を矯正*/
    location: faultReceptionsDetail.location || ('' as FaultReceptionLocationType),
    /** 回収場所名 location が otherの時に入力*/
    locationName: faultReceptionsDetail.locationName || '',
    /** 代替機が必要か */
    isNeedAlternativeDevice: faultReceptionsDetail.isNeedAlternativeDevice || false,
    /** 至急の対応が必要か */
    isNeedUrgentResponse: faultReceptionsDetail.isNeedUrgentResponse || false,
  };

  const handleSubmit = useCallback(
    (data: FaultReceptionFormType) => {
      const _faultReceptionsDetail: FaultReceptionsDetail = {
        productFeature: data.productFeature,
        faultDetail: data.faultDetail,
        rentalRoomName: data.rentalRoomName,
        reporterName: data.reporterName,
        location: data.location as FaultReceptionLocationType,
        locationName: data.locationName,
        isNeedAlternativeDevice: Boolean(data.isNeedAlternativeDevice),
        isNeedUrgentResponse: Boolean(data.isNeedUrgentResponse),
      };

      setFaultReceptionsDetail(_faultReceptionsDetail);
      navigate(`/shared/faut_reception/register/preview`);
    },
    [navigate, setFaultReceptionsDetail]
  );

  return (
    <StyledBody>
      <FaultReceptionHeader title="不具合受付" />
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnMount={true}
        validateOnChange={true}>
        {({submitForm, isValid}) => (
          <>
            <PaperContainer>
              <FaultReceptionForm />
            </PaperContainer>
            <Footer
              text={'不具合受付する機器情報を入力してください'}
              backButtonHandler={() => navigate('/shared/reception_menu')}
              nextButton={{
                label: '確認画面へ',
                disabled: !isValid,
                buttonHandler: submitForm,
              }}
            />
          </>
        )}
      </Formik>
    </StyledBody>
  );
};
