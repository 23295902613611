import React from 'react';
import {Grid, Typography, styled} from '@material-ui/core';
import {Error} from '@material-ui/icons';

type InvalidValueErrorProps = {
  text?: string;
};

const StyledGrid = styled(Grid)({
  height: '32px',
  marginTop: '12px',
});
const StyledTypography = styled(Typography)({
  color: '#C7243A',
  fontSize: '14px',
  marginLeft: '16px',
  fontWeight: 'bold',
});
const StyledError = styled(Error)({
  color: '#C7243A',
  fontWeight: 'bold',
  marginTop: '-2px',
});

export const InvalidValueError: React.FC<InvalidValueErrorProps> = (props) => {
  return (
    <StyledGrid container>
      <StyledError />
      <StyledTypography>{props.text ?? 'この回答では異常があります'}</StyledTypography>
    </StyledGrid>
  );
};
