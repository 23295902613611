import * as React from 'react';
import {SxProps, Theme, styled} from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, {stepConnectorClasses} from '@mui/material/StepConnector';
import {StepIconProps} from '@mui/material/StepIcon';
import {useAtomValue} from 'jotai';
import {rentOrReturnAtom, rentStepperAtom} from '../states';
import {RentalSteps, ReturnSteps} from './consts';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import currentStepIcon from '@assets/current_step.svg';

const QontoConnector = styled(StepConnector)(({theme}) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 10px)',
    right: 'calc(50% + 10px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.grey[900],
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.grey[900],
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.grey[100],
    borderTopWidth: 1,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled('div')<{active: boolean}>(({theme, active}) => ({
  color: theme.palette.grey[100],
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(active && {
    color: theme.palette.grey[900],
  }),
  [`&.${stepConnectorClasses.completed}`]: {
    color: theme.palette.grey[900],
    zIndex: 1,
    fontSize: 18,
  },
}));

/** ヘッダーの中央に配置するためabsoluteで設定 */
const StackStyle: SxProps = {
  width: '44%',
  display: 'flex',
  position: 'absolute',
  top: '32px',
  left: '50%',
  transform: 'translateX(-50%)',
};

const stepLabelStyle: SxProps<Theme> = (theme) => ({
  color: theme.palette.grey[900],
});

const stepIconDefaultStyle: SxProps<Theme> = {
  width: '16px !important',
  height: '16px !important',
};

const stepIconActiveStyle: SxProps<Theme> = (theme) => ({
  ...stepIconDefaultStyle,
  color: theme.palette.grey[900],
});

function QontoStepIcon(props: StepIconProps) {
  const {active, completed} = props;
  const currentStep = useAtomValue(rentStepperAtom);

  const currentIcon = () => {
    if (completed) {
      return <FiberManualRecordIcon sx={stepIconActiveStyle} />;
    }
    if (active) {
      if (currentStep !== 3) {
        return <img src={currentStepIcon} alt="currentStep" />;
      } else {
        return <CheckCircleIcon fontSize="small" />;
      }
    } else {
      return <FiberManualRecordIcon sx={stepIconDefaultStyle} />;
    }
  };

  return <QontoStepIconRoot active={!!active}>{currentIcon()}</QontoStepIconRoot>;
}

type StepperProps = {
  activeStep: number;
};

export const RentalStepper = ({activeStep}: StepperProps) => {
  const currentStep = useAtomValue(rentStepperAtom);
  const rentOrReturn = useAtomValue(rentOrReturnAtom);
  const steps = rentOrReturn === 'rent' ? RentalSteps : ReturnSteps;

  return (
    <Stack sx={StackStyle} spacing={4}>
      <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
        {steps.map((step) => (
          <Step key={step.value}>
            <StepLabel sx={stepLabelStyle} StepIconComponent={QontoStepIcon}>
              {currentStep === step.value ? step.label : ''}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
};
