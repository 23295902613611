import React, {useMemo, useCallback} from 'react';
import {KeyboardDatePicker, DatePickerProps} from '@material-ui/pickers';
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import DayjsUtils from '@date-io/dayjs';
import localeJa from 'dayjs/locale/ja';
import dayjs from 'dayjs';

export type DateFieldValue = dayjs.ConfigTypeMap['default'];

type CustomProps = {
  onChange: (value: DateFieldValue) => void;
  value: DateFieldValue;
  fieldFormat?: string;
};

export type Props = Omit<DatePickerProps, 'onChange' | 'value'> & CustomProps;

export const DateField: React.FC<Props> = ({
  format = 'YYYY/MM/DD',
  fieldFormat = 'YYYY-MM-DD',
  onChange,
  value,
  inputVariant = 'outlined',
  invalidDateMessage = '無効な日付フォーマットです',
  ...rest
}) => {
  const actualValue = useMemo(() => (value !== null ? dayjs(value) : null), [value]);
  const handleChange = useCallback(
    (date: MaterialUiPickersDate | null) => {
      onChange(date !== null ? date.format(fieldFormat) : null);
    },
    [fieldFormat, onChange]
  );

  return (
    <MuiPickersUtilsProvider utils={DayjsUtils} locale={localeJa}>
      <KeyboardDatePicker
        {...rest}
        autoOk
        variant="inline"
        inputVariant={inputVariant}
        format={format}
        value={actualValue}
        InputAdornmentProps={{position: 'start'}}
        onChange={handleChange}
        invalidDateMessage={invalidDateMessage}
      />
    </MuiPickersUtilsProvider>
  );
};
