import React, {useState} from 'react';
import {Collapse, Grid, List, ListItem, ListItemIcon, ListItemText, makeStyles, Theme} from '@material-ui/core';
import {ExpandMore, ChevronRight} from '@material-ui/icons';
import {FastField, useFormikContext} from 'formik';
import {TrainingSchedule} from '@modules/training_schedules/types';

type FormField = {
  type: string;
  label: string;
  name?: keyof TrainingSchedule;
  getValue?: (plan: TrainingSchedule) => string | number | boolean | string[] | undefined;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  InputComponent: React.ComponentType<any>;
  readOnly?: boolean;
  options?: {
    label: string;
    value: string | number | boolean | (string | number | boolean)[];
  }[];
  multiline?: boolean;
  clearable?: boolean;
  multiple?: boolean;
};

export type FormSection = {
  sectionName: string;
  fields: FormField[];
};

type Props = FormSection;

export const FormFieldSection: React.FC<Props> = ({sectionName, fields}) => {
  const classes = useStyles();
  const context = useFormikContext<TrainingSchedule>();
  const [open, setOpen] = useState(true);

  return (
    <>
      <ListItem
        button
        className={classes.collapseControl}
        onClick={() => {
          setOpen(!open);
        }}>
        <ListItemIcon className={classes.listIcon}>{open ? <ExpandMore /> : <ChevronRight />}</ListItemIcon>
        <ListItemText
          classes={{
            primary: classes.listText,
          }}
          primary={sectionName}
        />
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {fields.map((field, index) => {
            // prefer to use value by getValue
            const value = field.getValue
              ? field.getValue(context.values)
              : context.values[field.name ?? 'hashId'] ?? '';
            return (
              <Grid container className={classes.fieldContainer} key={field.name ?? '' + index}>
                <FastField
                  type={field.type}
                  label={field.label}
                  fullWidth
                  name={field.name}
                  value={value}
                  component={field.InputComponent}
                  disabled={field.readOnly}
                  options={field.options}
                  multiline={field.multiline}
                  clearable={field.clearable}
                  multiple={field.multiple}
                />
              </Grid>
            );
          })}
        </List>
      </Collapse>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  fieldContainer: {
    padding: '8px 16px',
  },
  collapseControl: {
    paddingTop: 24,
    paddingBottom: 24,
    paddingLeft: 16,
    borderTop: '1px solid #D1D5DD',
  },
  listIcon: {
    minWidth: 0,
    paddingRight: '8px',
  },
  listText: {
    fontSize: theme.typography.fontSize,
    fontWeight: 'bold',
  },
}));
