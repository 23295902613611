import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {Grid, Pagination, styled} from '@mui/material';
import {useAtom, useAtomValue} from 'jotai';
import React, {useCallback, useMemo} from 'react';
import {useFaultReceptionQuery} from '../hooks';
import {pageAtom, pageSizeAtom} from '../jotai';
import {FaultReceptionTable} from './FaultReceptionTable';

const FooterContainer = styled(Grid)({
  marginTop: '16px',
  position: 'fixed',
  bottom: '98px',
  left: 0,
  right: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const BodyContainer = styled(Grid)({
  overflow: 'auto',
  padding: '24px 36px',
  width: 'stretch',
  height: ' calc(100vh - 248px - 24px)',
  '& .MuiPaper-root': {
    height: '100%',
  },
});

export const FaultReceptionList = () => {
  const {myInfo} = useMyInfo();
  const [page, setPage] = useAtom(pageAtom);
  const pageSize = useAtomValue(pageSizeAtom);
  const query = useFaultReceptionQuery(myInfo.hospitalHashId);

  const totalPage = useMemo(() => Math.ceil(query.totalCount / pageSize), [pageSize, query.totalCount]);

  const handleChangePage = useCallback((_e: React.ChangeEvent<unknown>, p: number) => setPage(p), [setPage]);

  return (
    <>
      <BodyContainer>
        <FaultReceptionTable />
      </BodyContainer>
      <FooterContainer>
        <Pagination page={page} count={totalPage} shape="rounded" color="primary" onChange={handleChangePage} />
      </FooterContainer>
    </>
  );
};
