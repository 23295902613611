import {Atom, PrimitiveAtom, useAtomValue} from 'jotai';
import {selectAtom, useReducerAtom} from 'jotai/utils';
import {useCallback, useMemo} from 'react';
import {HospitalProductPlanStatus} from '@modules/hospital_product_plan/enum';
import {atomWithLocation} from 'jotai-location';
import {isNullish} from '@front-libs/helpers';

export type HospitalProductPlanListState = {
  initialized: boolean;
  name?: string;
  status: HospitalProductPlanStatus;
  wholeProductHashId?: string;
  rootCategoryHashId?: string;
  narrowCategoryHashId?: string;
  isDisplayAnnounceBar?: boolean;
  perPage: number;
  page: number;
  order?: string;
};

export const initialState: HospitalProductPlanListState = {
  initialized: false,
  name: undefined,
  wholeProductHashId: undefined,
  status: 'all',
  rootCategoryHashId: undefined,
  narrowCategoryHashId: undefined,
  isDisplayAnnounceBar: false,

  perPage: 100,
  page: 1,
  order: 'planName,managementId', // HIT-4104 デフォルトのソートは 点検名の昇順+管理番号
};

export const HospitalProductPlanListStateAtom = atomWithLocation<HospitalProductPlanListState>({
  getLocation: () => {
    const searchParams = new URLSearchParams(window.location.search);
    const initialized = searchParams.get('initialized');
    const name = searchParams.get('name');
    const status = searchParams.get('status') as HospitalProductPlanStatus;
    const wholeProductHashId = searchParams.get('wholeProductHashId');
    const rootCategoryHashId = searchParams.get('rootCategoryHashId');
    const narrowCategoryHashId = searchParams.get('narrowCategoryHashId');
    const perPage = searchParams.get('perPage');
    const page = searchParams.get('page');
    const order = searchParams.get('order');
    const isDisplayAnnounceBar = searchParams.get('isDisplayAnnounceBar');

    return {
      initialized: !isNullish(initialized) ? Boolean(initialized) : false,
      name: name ?? undefined,
      status: status ?? 'all',
      wholeProductHashId: wholeProductHashId ?? undefined,
      rootCategoryHashId: rootCategoryHashId ?? undefined,
      narrowCategoryHashId: narrowCategoryHashId ?? undefined,
      perPage: perPage ? Number(perPage) : 100,
      page: page ? Number(page) : 1,
      order: order ?? 'planName,managementId',
      isDisplayAnnounceBar: isDisplayAnnounceBar === 'true' ? true : undefined,
    };
  },
  applyLocation: (state, options) => {
    const newState: Partial<HospitalProductPlanListState> = {};

    if (!isNullish(state.status)) newState.status = state.status;
    if (!isNullish(state.isDisplayAnnounceBar)) newState.isDisplayAnnounceBar = state.isDisplayAnnounceBar;
    if (!isNullish(state.name)) newState.name = state.name;
    if (!isNullish(state.wholeProductHashId)) newState.wholeProductHashId = state.wholeProductHashId;
    if (!isNullish(state.rootCategoryHashId)) newState.rootCategoryHashId = state.rootCategoryHashId;
    if (!isNullish(state.narrowCategoryHashId)) newState.narrowCategoryHashId = state.narrowCategoryHashId;
    if (!isNullish(state.perPage)) newState.perPage = state.perPage;
    if (!isNullish(state.page)) newState.page = state.page;
    if (!isNullish(state.order)) newState.order = state.order;

    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    const newSearchParams = new URLSearchParams(newState as any);

    if (options?.replace) {
      window.history.replaceState(null, '', `?${newSearchParams.toString()}`);
    } else {
      window.history.pushState(null, '', `?${newSearchParams.toString()}`);
    }
  },
}) as unknown as PrimitiveAtom<HospitalProductPlanListState>;

// ActionCreator
export const createSetInitializedAction = (initialized: boolean) => ({
  type: 'SET_INITIALIZED' as const,
  initialized,
});

export const createChangeNameAction = (name: string) => ({
  type: 'CHANGE_NAME' as const,
  name: name === '' ? undefined : name,
  page: 1,
});

export const createChangeStatusAction = (status: HospitalProductPlanStatus) => ({
  type: 'CHANGE_STATUS' as const,
  status,
  page: 1,
});

export const createChangeWholeProductHashIdAction = (wholeProductHashId: string) => ({
  type: 'CHANGE_WHOLE_PRODUCT_HASH_ID' as const,
  wholeProductHashId: wholeProductHashId === '' ? undefined : wholeProductHashId,
  page: 1,
});

export const createChangeRootCategoryHashIdAction = (rootCategoryHashId: string) => ({
  type: 'CHANGE_ROOT_CATEGORY_HASH_ID' as const,
  rootCategoryHashId: rootCategoryHashId === '' ? undefined : rootCategoryHashId,
  narrowCategoryHashId: undefined,
  page: 1,
});

export const createChangeNarrowCategoryHashIdAction = (narrowCategoryHashId: string) => ({
  type: 'CHANGE_NARROW_CATEGORY_HASH_ID' as const,
  narrowCategoryHashId: narrowCategoryHashId === '' ? undefined : narrowCategoryHashId,
  page: 1,
});

export const createChangePerPageAction = (perPage: number) => ({
  type: 'CHANGE_PER_PAGE' as const,
  perPage,
  page: 1,
});

export const createChangePageAction = (page: number) => ({
  type: 'CHANGE_PAGE' as const,
  page,
});

export const createChangeOrderAction = (order: string | undefined) => ({
  type: 'CHANGE_ORDER' as const,
  order,
});

export const createChangeIsDisplayAnnounceBarAction = (isDisplayAnnounceBar: boolean) => ({
  type: 'CHANGE_IS_DISPLAY_ANNOUNCE_BAR' as const,
  isDisplayAnnounceBar,
});

// Action
export type Actions =
  | ReturnType<typeof createSetInitializedAction>
  | ReturnType<typeof createChangeNameAction>
  | ReturnType<typeof createChangeStatusAction>
  | ReturnType<typeof createChangeWholeProductHashIdAction>
  | ReturnType<typeof createChangeRootCategoryHashIdAction>
  | ReturnType<typeof createChangeNarrowCategoryHashIdAction>
  | ReturnType<typeof createChangePerPageAction>
  | ReturnType<typeof createChangePageAction>
  | ReturnType<typeof createChangeOrderAction>
  | ReturnType<typeof createChangeIsDisplayAnnounceBarAction>;

// Reducer
const HospitalProductPlanListStateReducer = (
  prev: HospitalProductPlanListState,
  action: Actions
): HospitalProductPlanListState => {
  switch (action.type) {
    case 'SET_INITIALIZED':
      return {...prev, initialized: action.initialized};
    case 'CHANGE_NAME':
      return {...prev, name: action.name, page: action.page};
    case 'CHANGE_STATUS':
      return {...prev, status: action.status, page: action.page};
    case 'CHANGE_WHOLE_PRODUCT_HASH_ID':
      return {...prev, wholeProductHashId: action.wholeProductHashId, page: action.page};
    case 'CHANGE_ROOT_CATEGORY_HASH_ID':
      return {
        ...prev,
        rootCategoryHashId: action.rootCategoryHashId,
        narrowCategoryHashId: action.narrowCategoryHashId,
        page: action.page,
      };
    case 'CHANGE_NARROW_CATEGORY_HASH_ID':
      return {...prev, narrowCategoryHashId: action.narrowCategoryHashId, page: action.page};
    case 'CHANGE_PER_PAGE':
      return {...prev, perPage: action.perPage, page: action.page};
    case 'CHANGE_PAGE':
      return {...prev, page: action.page};
    case 'CHANGE_ORDER':
      return {...prev, order: action.order};
    case 'CHANGE_IS_DISPLAY_ANNOUNCE_BAR':
      return {...prev, isDisplayAnnounceBar: action.isDisplayAnnounceBar};
    default:
      console.warn('undefined action is dispatched', action);
      return prev;
  }
};

// helper
export const useHospitalProductPlanListState = () =>
  useReducerAtom(HospitalProductPlanListStateAtom, HospitalProductPlanListStateReducer);

const useMemoizedDispatch = () => {
  const [, dispatch] = useHospitalProductPlanListState();
  return useMemo(() => dispatch, [dispatch]);
};

const useSelectState = <T, U = T>(valueAtom: Atom<T>, actionCreator: (value: U) => Actions) => {
  const value = useAtomValue(valueAtom);
  const dispatch = useMemoizedDispatch();
  const update = useCallback((x: U) => dispatch(actionCreator(x)), [actionCreator, dispatch]);

  return [value, update] as const;
};

// hooks
const _initializedAtom = selectAtom(HospitalProductPlanListStateAtom, (state) => state.initialized);
export const useStateInitialized = () =>
  useSelectState(_initializedAtom, (initialized: boolean) => createSetInitializedAction(initialized));

const _nameAtom = selectAtom(HospitalProductPlanListStateAtom, (state) => state.name);
export const useName = () => useSelectState(_nameAtom, (name: string) => createChangeNameAction(name));

const _wholeProductHashIdAtom = selectAtom(HospitalProductPlanListStateAtom, (state) => state.wholeProductHashId);
export const useWholeProductHashId = () =>
  useSelectState(_wholeProductHashIdAtom, (wholeProductHashId: string) =>
    createChangeWholeProductHashIdAction(wholeProductHashId)
  );

const _rootCategoryHashIdAtom = selectAtom(HospitalProductPlanListStateAtom, (state) => state.rootCategoryHashId);
export const useRootCategoryHashId = () =>
  useSelectState(_rootCategoryHashIdAtom, (rootCategoryHashId: string) =>
    createChangeRootCategoryHashIdAction(rootCategoryHashId)
  );

const _narrowCategoryHashIdAtom = selectAtom(HospitalProductPlanListStateAtom, (state) => state.narrowCategoryHashId);
export const useNarrowCategoryHashId = () =>
  useSelectState(_narrowCategoryHashIdAtom, (narrowCategoryHashId: string) =>
    createChangeNarrowCategoryHashIdAction(narrowCategoryHashId)
  );

const _perPageAtom = selectAtom(HospitalProductPlanListStateAtom, (state) => state.perPage);
export const usePerPage = () => useSelectState(_perPageAtom, (perPage: number) => createChangePerPageAction(perPage));

const _statusAtom = selectAtom(HospitalProductPlanListStateAtom, (state) => state.status);
export const useStatus = () =>
  useSelectState(_statusAtom, (status: HospitalProductPlanStatus) => createChangeStatusAction(status));

const _pageAtom = selectAtom(HospitalProductPlanListStateAtom, (state) => state.page);
export const usePage = () => useSelectState(_pageAtom, (page: number) => createChangePageAction(page));

const _orderAtom = selectAtom(HospitalProductPlanListStateAtom, (state) => state.order);
export const useOrder = () => useSelectState(_orderAtom, (order: string | undefined) => createChangeOrderAction(order));

const _isDisplayAnnounceBarAtom = selectAtom(HospitalProductPlanListStateAtom, (state) => state.isDisplayAnnounceBar);
export const useIsDisplayAnnounceBar = () =>
  useSelectState(_isDisplayAnnounceBarAtom, (isDisplayAnnounceBar: boolean) =>
    createChangeIsDisplayAnnounceBarAction(isDisplayAnnounceBar)
  );

export const useQueryVariables = () => {
  const [page, updatePage] = usePage();
  const [perPage, updatePerPage] = usePerPage();
  const [name, updateName] = useName();
  const [wholeProductHashId, updateWholeProductHashId] = useWholeProductHashId();
  const [rootCategoryHashId, updateRootCategoryHashId] = useRootCategoryHashId();
  const [narrowCategoryHashId, updateNarrowCategoryHashId] = useNarrowCategoryHashId();
  const [status, updateStatus] = useStatus();
  const [order, updateOrder] = useOrder();

  return {
    page,
    perPage,
    name,
    wholeProductHashId,
    order,
    rootCategoryHashId,
    narrowCategoryHashId,
    status,

    updatePage,
    updatePerPage,
    updateName,
    updateWholeProductHashId,
    updateOrder,
    updateRootCategoryHashId,
    updateNarrowCategoryHashId,
    updateStatus,
  };
};
