import {Card, CardActionArea, CardContent, RadioProps, SxProps, Theme} from '@mui/material';
import React from 'react';

type Props = {
  label: string;
  onClick: (value: string) => void;
} & Omit<RadioProps, 'onChange'>;

export const ActivateCameraButton = ({label, ...props}: Props) => {
  const handleClick = () => {
    props.onClick(props.value as string);
  };

  return (
    <Card sx={cardStyle}>
      <CardActionArea onClick={handleClick}>
        <CardContent sx={contentStyle}>{label}</CardContent>
      </CardActionArea>
    </Card>
  );
};

const cardStyle: SxProps<Theme> = {
  borderRadius: '45px',
  boxShadow: '0px 8px 0px 0px rgba(209, 216, 245, 1)',
  width: '100%',
};

const contentStyle: SxProps<Theme> = (theme) => ({
  maxWidth: '100%',
  padding: '16px 20px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: 'bold',
  fontSize: '16px',
  color: theme.palette.rent.main,
  [theme.breakpoints.up('tabletV')]: {
    fontSize: '26px',
    padding: '20px 40px',
  },
  [theme.breakpoints.up('tabletH')]: {
    fontSize: '36px',
  },
});
