import React, {useMemo} from 'react';
import clsx from 'clsx';
import {Outlet, useLocation} from 'react-router-dom';
import {Grid, makeStyles, Theme} from '@material-ui/core';
import {Header, NavLinkType} from '@components/organisms/Header/pc';
import {useTemplate} from '@templates/NoSideBarLayoutForPC';
import {withSuspense} from '@front-libs/core';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {isNullish} from '@front-libs/helpers';
import {useFetchHospitalQuery} from '@modules/hospital/api';
import {useLogout, useUserPermissions} from '@modules/auth/hooks';
import {HospitalUserPermission} from '@modules/auth/consts';
import {isMobileOnly} from 'react-device-detect';
import {useMyRole} from '@modules/hospital_users/hooks/useMyRole';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

const isMobilePath = (pathname: string) => {
  return pathname.indexOf('/sp') === 0;
};

type BaseProductManagerProps = {
  showHeader: boolean;
  children?: React.ReactNode;
};

const _BaseProductManager: React.FC<BaseProductManagerProps> = ({children, showHeader, ...props}) => {
  const templateClasses = useTemplate();
  const classes = useStyles();
  const logout = useLogout();
  const {myInfo} = useMyInfo();
  const {isRentalOnly, isAdmin, isWard, canAccessAsset} = useMyRole();
  const {data} = useFetchHospitalQuery(myInfo.hospitalHashId);
  const {isPermitted} = useUserPermissions();
  const location = useLocation();

  const navLinkItems: NavLinkType[] = useMemo(() => {
    const items: NavLinkType[] = [
      {
        to: '/dashboard',
        label: 'ダッシュボード',
        // hasPermission: () => isPermitted([HospitalUserPermission.Dashboard]),
      },
      {
        to: '/products',
        label: '機器一覧',
      },
      {
        to: '/inspection_v2/results',
        label: '点検',
        // hasPermission: () => isPermitted([HospitalUserPermission.Inspection]),
      },
      {
        to: '/repairs',
        label: '修理',
        // hasPermission: () => isPermitted([HospitalUserPermission.Repair]),
      },
      {
        to: '/rentals',
        label: '貸出・返却',
        // hasPermission: () => isPermitted([HospitalUserPermission.Rental]),
      },
      {
        to: '/training_managements',
        label: '研修',
        childLinks: [
          {label: '研修計画マスタ', to: '/training_managements/plans'},
          {label: '研修（予定）', to: '/training_managements/schedules'},
          {label: '研修（実施済み）', to: '/training_managements/reports'},
        ],
        hasPermission: () => isPermitted([HospitalUserPermission.TrainingManagement]),
      },
    ].filter((item) => item !== null) as NavLinkType[]; // nullをフィルタリングして型を修正

    return items;
  }, [isPermitted]);

  const navigate = useNavigate();

  useEffect(() => {
    const hasInitialSettingAlerted = sessionStorage.getItem('hasInitialSettingAlerted');
    if (isMobileOnly && !isMobilePath(location.pathname)) {
      navigate('/sp/products', {replace: true});
      return;
    }

    if (!isNullish(data) && !data.assetInitialized) {
      navigate('/signup/hospital', {replace: true});
      return;
    }
    // 初期設定が済んでいないユーザーは別システムへ遷移
    if (!isNullish(myInfo?.initialized) && !myInfo.initialized && !isMobileOnly) {
      if (!hasInitialSettingAlerted) {
        sessionStorage.setItem('hasInitialSettingAlerted', 'true');
        window.alert(`ユーザー情報の初回設定のため、HITOTSU Hospitalに遷移します`);
      }
      window.location.href = import.meta.env.VITE_ACCOUNT_SERVICE_URL;
      return;
    }
    if (!isNullish(myInfo.initialized) && !myInfo.initialized) {
      navigate('/signup/user', {replace: true});
      return;
    }
    if (!canAccessAsset) {
      alert('このユーザーではAssetを利用できません。Asset利用設定済のユーザーでログインしてください。');
      logout();
    }
    if (isWard) {
      navigate('/shared/reception_menu', {replace: true});
    }
    if (isRentalOnly) {
      navigate('/shared', {replace: true});
    }
  }, [canAccessAsset, data, isRentalOnly, isWard, location.pathname, logout, myInfo, navigate]);

  return (
    <Grid container className={clsx(templateClasses.root, classes.root)}>
      {showHeader && <Header className={templateClasses.header} navLinkItems={navLinkItems} isUserAdmin={isAdmin} />}
      <Grid container className={showHeader ? templateClasses.content : templateClasses.noHeaderContent}>
        {children}
        <Outlet />
      </Grid>
    </Grid>
  );
};

export const BaseProductManager = withSuspense(_BaseProductManager, null);
