import {axios} from '@front-libs/core';
import {
  ListHospitalProductRequiredPropertyItemsResponse,
  UpdateHospitalProductRequiredPropertiesRequest,
  UpdateHospitalProductRequiredPropertiesResponse,
  ListHospitalProductRequiredPropertiesResponse,
} from './types';

/** 設定中の必須項目一覧を返す。 */
export const listHospitalProductRequiredProperties = async (hospitalHashId: string) => {
  const url = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/hospital_product_required_properties`;
  const {data} = await axios.get<ListHospitalProductRequiredPropertiesResponse>(url);
  return data;
};

/** 必須項目一覧の設定を反映する。 */
export const updateHospitalProductRequiredProperties = async (
  hospitalHashId: string,
  req: UpdateHospitalProductRequiredPropertiesRequest
) => {
  const url = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/hospital_product_required_properties`;
  const {data} = await axios.post<UpdateHospitalProductRequiredPropertiesResponse>(url, req);
  return data;
};

/** 機器に設定可能な必須項目一覧を返す。 */
export const listHospitalProductRequiredPropertyItems = async (hospitalHashId: string) => {
  const url = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/hospital_product_required_properties/items`;
  const {data} = await axios.get<ListHospitalProductRequiredPropertyItemsResponse>(url);
  return data;
};
