import React from 'react';
import {AlertDialog} from '@molecules/Dialogs/AlertDialog';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import {CSSObject, Stack} from '@mui/material';

export type ConfirmBulkDeleteInspectionPlansDialogProps = DialogProps;

/**
 * 「計画済みの点検開始月・月ごとの点検日の削除」に関する確認ダイアログ
 *
 * @example
 * こんな感じで使ってください
 * ```tsx
 * dialogHandler.open(ConfirmBulkDeleteInspectionPlansDialog, {}).then(() => {
 *   // resolve時の処理
 * }).catch(() => {
 *   // reject時の処理
 * });
 * ```
 *
 * @param props.open ダイアログを開くかどうか
 * @param props.actions ダイアログの結果を返すためのresolve, reject関数
 */
export const ConfirmBulkDeleteInspectionPlansDialog: React.FC<ConfirmBulkDeleteInspectionPlansDialogProps> = (
  props
) => {
  const {open, actions} = props;
  return (
    <AlertDialog
      open={open}
      actions={actions}
      maxWidth={'md'}
      boldTitle={true}
      title="「計画済みの点検開始月・月ごとの点検日の削除」に関する確認"
      positiveButtonLabel="実行する">
      <Stack sx={wrapper}>
        削除対象に「計画済みの定期点検」が含まれています。
        <br />
        <br />
        点検開始月・月ごとの点検日を削除すると、当該定期点検計画は「未計画」に移行し、
        <br />
        既に存在する「未実施の点検予定」および「予定月超過の点検予定」は削除されます。
        <br />
        （スキップした点検、実施途中の点検、および完了した点検には反映されません）。
        <br />
      </Stack>
      <Stack>この操作を実行しますか？実行すると元に戻せません。</Stack>
    </AlertDialog>
  );
};

const wrapper: CSSObject = {marginBottom: 4, width: '720px'};
