import {isNullish} from '@front-libs/helpers';
import {useState, useEffect} from 'react';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {GetHospitalWholeProductParam, useFetchHospitalWholeProductsQuery} from '@modules/products/api';
import {useDebounceCallback} from '@front-libs/core';
import {atom, useAtom} from 'jotai';
export type PullDownType = 'to' | 'from';

// PullDownType をキーとするオブジェクト型を定義
type PullDownValues = {
  [key in PullDownType]: string;
};

const pullDownSelectedValues = atom<PullDownValues>({
  to: '',
  from: '',
});

type WholeProductSearchPullDownProps = {
  onSelect: (value: string) => void;
  pullDownType?: PullDownType;
  excludedWholeProductHashIds?: string[];
};

type WholeProductOption = {
  hashId: string;
  displayName: string;
  name: string;
  isShared: boolean;
};

export const useWholeProductSearchPullDown = ({
  onSelect,
  pullDownType,
  excludedWholeProductHashIds,
}: WholeProductSearchPullDownProps) => {
  const {myInfo} = useMyInfo();
  const [selectedValue, setSelectedValue] = useState<WholeProductOption | null>(null);
  const [searchName, setSearchName] = useState<string>('');
  const [params, setParams] = useState<GetHospitalWholeProductParam>({
    page: 0,
    perPage: 100,
  });
  const {data, totalCount} = useFetchHospitalWholeProductsQuery(myInfo.hospitalHashId, params);
  const [selectedValues, setSelectedValues] = useAtom(pullDownSelectedValues);

  const handleChange = useDebounceCallback(
    (newValue: WholeProductOption | null) => {
      setSelectedValue(newValue);
      const value = newValue ? newValue.hashId : '';
      onSelect(value);

      if (pullDownType) {
        // HIT-4605[Front]統合元、統合先で同一機種が選択できてしまう
        setSelectedValues({
          ...selectedValues,
          [pullDownType]: value,
        });
      }
    },
    300,
    [selectedValue]
  );
  const handlePageChange = (newPage: number) => {
    setParams((prevParams) => ({
      ...prevParams,
      page: newPage - 1,
    }));
  };

  const handleInputChange = (value: string) => {
    setSearchName(value);
  };

  const filteredData = data?.filter((item) => {
    // NOTE 機種統合でHITOTSUマスタは一覧から表示外にしている
    // FIXME HITOTSUマスタを完全撲滅後、この処理は削除する
    if (item.isShared) {
      return false;
    }
    return true;
  });

  useEffect(() => {
    setParams((prevParams) => ({
      ...prevParams,
      name: !isNullish(searchName) ? searchName : undefined,
      excludedWholeProductHashIds:
        excludedWholeProductHashIds && excludedWholeProductHashIds.length > 0
          ? excludedWholeProductHashIds.join(',')
          : undefined,
    }));
  }, [searchName, excludedWholeProductHashIds]);

  return {
    selectedValue,
    data: filteredData,
    handleChange,
    handleInputChange,
    handlePageChange,
    totalCount,
  };
};
