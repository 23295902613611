import {Swiper, SwiperSlide} from 'swiper/react';

import {Navigation, Pagination} from 'swiper/modules';
import {Box} from '@mui/material';
import {SelectionButton} from './SelectionButton';
import React, {CSSProperties} from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

type SwiperComponentProps<T> = {
  data: T[][];
  getLabel: (item: T) => string;
  onSelect: (item: T) => void;
  rowNum?: number;
};

/**
 * 貸出返却メニュー用スライダーコンポーネント
 *
 * @template T - スライド内で表示されるデータの型
 * @param {T[]} data - スライドで表示するデータの配列
 * @param {(item: T) => string} getLabel - 各データから表示するラベルを取得する関数
 * @param {(item: T) => void} onSelect - アイテムが選択されたときに呼び出される関数
 * @param {number} rowNum - 1ページあたりの行数 デフォルト3行
 */
export const SelectionSwiper = <T,>({data, getLabel, onSelect, rowNum = 3}: SwiperComponentProps<T>) => {
  return (
    <Swiper
      modules={[Navigation, Pagination]}
      spaceBetween={24}
      slidesPerView={1}
      navigation
      pagination={{clickable: true}}
      style={SwiperSlideStyle}>
      {data.map((items, index) => (
        <SwiperSlide key={index}>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(6, 1fr)', // 6列
              gridTemplateRows: `repeat(${rowNum}, 1fr)`, // デフォルト3行
              gap: '24px',
              margin: '40px 72px',
            }}>
            {items.map((item, index2) => (
              <SelectionButton label={getLabel(item)} key={index2} onClick={() => onSelect(item)} />
            ))}
          </Box>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
const SwiperSlideStyle: CSSProperties = {
  margin: '0 24px',
  width: '100%',
};
