import {useQuery} from 'react-query';
import {getReturnWaitingReceptions} from './api';
import {returnsReservationRequestParam} from './type';
import {getReturnWaitingReceptionsKey} from '@constants/api';

/** 返却待ち受付の一覧取得 */
export const useFetchReturnWaitingReceptions = (hospitalHashId: string, params: returnsReservationRequestParam) => {
  return useQuery(
    [getReturnWaitingReceptionsKey, hospitalHashId, params],
    () => getReturnWaitingReceptions(hospitalHashId, params),
    {
      enabled: !!hospitalHashId,
      refetchOnWindowFocus: false,
    }
  );
};
