import {useMemo} from 'react';
import {axios, BaseListResult} from '@front-libs/core';
import {atom} from 'jotai';
import {CreateWholeProduct, ProductIndex, WholeProductIndex} from './types';
import {useQuery} from 'react-query';
import {getProductKey, getProductsKey, getWholeProductsKey} from '@constants/api';

const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/products`;

export type FetchProductsParams = {
  name?: string;
  categoryHashId?: string;
  makerHashId?: string;
  page?: number;
  perPage?: number;
  gs1Barcode?: string;
  isExcludeCopiedHitotsuMaster?: boolean; // HITOTSUマスタから複製した院内製品マスタがある場合、複製元のHITOTSUマスタを除外する
  originalProductHashId?: string;
  isShared?: boolean; // HITOTSUマスタを除外するか　false=院内マスタで絞り込み
};

export type FetchProductsResult = {
  totalCount: number;
  page: number;
  data: ProductIndex[];
};

export type UpdateProductParams = {
  thumbnailUrl: string;
};

export const fetchProductsDto = atom<FetchProductsParams | null>(null);
export const fetchProducts = atom(async (get) => {
  const param = get(fetchProductsDto);
  if (param === null) {
    return {
      totalCount: 0,
      page: 0,
      data: [],
    };
  }

  const res = await axios.get<FetchProductsResult>(baseUrl, {params: param});
  return res.data;
});

export const getProduct = async (productHashId: string) => {
  const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/products/${productHashId}`;
  const {data} = await axios.get<ProductIndex>(baseUrl, {});
  return data;
};

export const getProducts = async (params: FetchProductsParams) => {
  const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/products`;
  const {data} = await axios.get<FetchProductsResult>(baseUrl, {params});
  return data;
};

export const updateProduct = async (hospitalHashId: string, productHashId: string, params: UpdateProductParams) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/whole_products/${productHashId}`;
  const {data} = await axios.put<ProductIndex>(baseUrl, params);
  return data;
};

export type UpdateWholeProductColumns =
  | 'narrow_category_hash_id'
  | 'display_name'
  | 'name'
  | 'approval_number'
  | 'jmdn_code'
  | 'jan_code'
  | 'is_specific_maintain'
  | 'class_name'
  | 'catalog_price'
  | 'maker_hash_id'
  | 'new_maker_name';

// 機種の一部データを更新
export type PatchWholeProductParams = {
  updateColumns: UpdateWholeProductColumns[];
  narrowCategoryHashId?: string;
  makerHashId?: string;
  newMakerName?: string;
  displayName?: string;
  name?: string;
  approvalNumber?: string;
  jmdnCode?: number;
  janCode?: string;
  isSpecificMaintain?: boolean;
  className?: string;
  catalogPrice?: number;
};

export const patchProduct = async (
  hospitalHashId: string,
  wholeProductHashId: string,
  params: PatchWholeProductParams
) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/whole_products/${wholeProductHashId}`;
  await axios.patch(baseUrl, params);
};

const getWholeProduct = async (hospitalHashId: string, wholeProductHashId: string) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/whole_products/${wholeProductHashId}`;
  const {data} = await axios.get<WholeProductIndex>(baseUrl);
  return data;
};

export const useFetchProductQuery = (hashId?: string) => {
  return useQuery([getProductKey, hashId], () => getProduct(hashId ?? ''), {
    enabled: !!hashId,
  });
};

export const useFetchWholeProductQuery = (hospitalHashId: string, wholeProductHashId: string) => {
  const query = useQuery(
    [getProductKey, hospitalHashId, wholeProductHashId],
    () => getWholeProduct(hospitalHashId, wholeProductHashId),
    {
      enabled: !!hospitalHashId && !!wholeProductHashId,
    }
  );
  return useMemo(
    () => ({
      ...query,
    }),
    [query]
  );
};

export const useFetchProductsQuery = (params: FetchProductsParams) => {
  const query = useQuery([getProductsKey, params], () => getProducts(params));
  return useMemo(
    () => ({
      ...query,
      data: query.data?.data ?? [],
      totalCount: query.data?.totalCount ?? 0,
    }),
    [query]
  );
};

export type GetHospitalWholeProductParam = {
  name?: string;
  categoryHashId?: string;
  categoryHashIds?: string;
  makerHashId?: string;
  hasInspectionSetting?: boolean; // 機種設定の計画済み、未計画のフィルター
  approvalNumber?: string; // 承認番号
  jmdnCode?: number; // JMDNコード
  newJanCode?: string; // GTIN(GS1のコード)
  janCode?: string; // Janコード
  isSpecificMaintain?: boolean; // 特定保守製品
  className?: string; // クラス分類
  catalogPriceFrom?: number; // 定価
  catalogPriceTo?: number; // 定価
  perPage: number;
  page: number;
  order?: string;
  excludedWholeProductHashIds?: string; // 除外する機種
  excludeEmptyNames?: boolean; // 機種名と型式が両方とも空またはnullのデータを除外
};

export const getHospitalWholeProducts = async (hospitalHashId: string, params: GetHospitalWholeProductParam) => {
  const candidateWholeProductsBaseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/whole_products`;
  const {data} = await axios.get<BaseListResult<WholeProductIndex>>(candidateWholeProductsBaseUrl, {params});
  return data;
};

type GetCandidateWholeProductsParam = Partial<{
  name: string;
  displayName: string;
  janCode: string;
  approvalNumber: string;
}>;

export const getCandidateWholeProducts = async (hospitalHashId: string, reqData: GetCandidateWholeProductsParam) => {
  const candidateWholeProductsBaseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/whole_products`;
  const {data} = await axios.post<WholeProductIndex>(candidateWholeProductsBaseUrl, reqData);
  return data;
};

type BulkInsertWholeProductsParam = {
  name?: string;
  displayName?: string;
  isSpecificMaintain?: boolean;
  className?: string;
  janCode?: string;
  newJanCode?: string;
  jmdnCode?: number;
  approvalNumber?: string;
  makerHashId?: string;
  makerName?: string;
  makerDurableYear?: number;
  rootCategoryHashId?: string;
  narrowCategoryHashId?: string;
  isShared: boolean;
};

export const bulkInsertWholeProducts = async (
  hospitalHashId: string,
  newWholeProducts: BulkInsertWholeProductsParam[]
) => {
  const bulkInsertWholeProductsUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/whole_products/jobs`;
  const {data} = await axios.post<WholeProductIndex[]>(bulkInsertWholeProductsUrl, {newWholeProducts});
  return data;
};

export const createWholeProduct = async (hospitalHashId: string, data: CreateWholeProduct) => {
  const baseURL = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/whole_products/whole_product`;
  return await axios.post<WholeProductIndex>(baseURL, data);
};

export type GetWholeProductsInspectionProductsResult = {
  hospitalHashId: string;
  wholeProductHashId: string;
  inspectionProductHashIds: string[];
};

export const getWholeProductsInspectionProducts = async (hospitalHashId: string) => {
  const getWholeProductsInspectionProductsURL = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/whole_products/inspections`;
  const {data} = await axios.get<GetWholeProductsInspectionProductsResult[]>(getWholeProductsInspectionProductsURL);
  return data;
};

export const useFetchHospitalWholeProductsQuery = (hospitalHashId: string, params: GetHospitalWholeProductParam) => {
  const query = useQuery(
    [getWholeProductsKey, hospitalHashId, ...Object.values(params)],
    () => getHospitalWholeProducts(hospitalHashId, params),
    {
      enabled: !!hospitalHashId,
    }
  );
  return useMemo(
    () => ({
      ...query,
      data: query.data?.data ?? [],
      totalCount: query.data?.totalCount ?? 0,
    }),
    [query]
  );
};
