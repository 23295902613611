import React, {useCallback, useEffect, useMemo} from 'react';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useAtomValue, useSetAtom} from 'jotai';
import {
  layoutSettingRoomVariablesAtom,
  layoutSettingSelectedFloorStatus,
  layoutSettingSelectedWardStatus,
  radioAtom,
  tabAtom,
} from './states';
import {useFetchHospitalWards} from '@modules/hospital_wards/api';
import {HospitalWardPosition} from './HospitalWard.tsx/HospitalWard';
import {HospitalRoomPosition} from './HospitalRoom.tsx/HospitalRoom';
import {useOnlyOnce} from '@front-libs/core';
import {InnerLoading} from '@components/molecules/Loading';
import {isNullish} from '@front-libs/helpers';
import {useFetchHospitalRooms} from '@modules/hospital_places/api';
import {HospitalRoom} from '@modules/hospital_places/types';
import {useFetchMyInfo} from '@modules/hospital_users/api';
import {useFetchHospitalSettingQuery} from '@modules/hospital_settings/api';
import {rentalSettings} from '@modules/hospital_settings/types';

export type LayoutSequenceType = {
  id: string;
  name: string;
}[];

export const CardsPositionSetting = () => {
  const {myInfo} = useMyInfo();
  const {data} = useFetchMyInfo(myInfo.hashId);
  const tab = useAtomValue(tabAtom);
  const setRadioButton = useSetAtom(radioAtom);
  const variables = useAtomValue(layoutSettingRoomVariablesAtom);
  const setSelectedWard = useSetAtom(layoutSettingSelectedWardStatus);
  const setSelectedFloor = useSetAtom(layoutSettingSelectedFloorStatus);

  const {data: skipFloorSelectionData} = useFetchHospitalSettingQuery(
    myInfo.hospitalHashId,
    rentalSettings.skip_floor_selection.key
  );

  useOnlyOnce(() => {
    if (data?.rentalLayoutPreference) setRadioButton(data?.rentalLayoutPreference);
  }, !isNullish(data?.rentalLayoutPreference));

  const {data: wardsData} = useFetchHospitalWards(myInfo.hospitalHashId, {
    page: 0,
    perPage: 100,
    onlyRentableRooms: true,
  });

  // 小エリア昇順用データ
  const {data: roomData} = useFetchHospitalRooms(myInfo.hospitalHashId, {
    page: 0,
    perPage: 100,
    hospitalWardHashId: variables.hospitalWardHashId,
    showRentalPlace: true,
    ...(variables.floorNumber !== undefined && {floorNumber: Number(variables.floorNumber)}),
    isGroundFloor: variables.isGroundFloor,
    order: 'name',
  });

  // 小エリア一覧取得
  const {data: roomsData} = useFetchHospitalRooms(myInfo.hospitalHashId, {
    page: 0,
    perPage: 100,
    hospitalWardHashId: variables.hospitalWardHashId,
    showRentalPlace: true,
    order: 'name',
  });

  const getFloorDescription = useCallback((room: HospitalRoom) => {
    if (room.isGroundFloor) {
      return `${room.floorNumber}階`;
    }
    return `地下${room.floorNumber}階`;
  }, []);

  // 階数セレクトボックス用
  const floorOptions = useMemo(() => {
    const roomMap = new Map();
    roomsData.forEach((room) => {
      const key = `${room.floorNumber}:${room.isGroundFloor}`;
      if (!roomMap.has(key)) {
        roomMap.set(key, room);
      }
    });
    return [...roomMap.values()].map((room) => ({
      label: getFloorDescription(room),
      value: room.hashId,
      floorNumber: room.floorNumber,
      isGroundFloor: room.isGroundFloor,
    }));
  }, [getFloorDescription, roomsData]);

  // 大エリア 並び替え用(昇順)
  const filteredWardsData = useMemo(() => {
    const filterWard = wardsData.filter((ward) => ward.numberOfRooms > 0);
    return filterWard.map((ward) => {
      return {
        id: ward.hashId,
        name: ward.name,
      };
    });
  }, [wardsData]);

  // 小エリア 並び替え(昇順)
  const convertRoomData = useMemo(() => {
    return roomData.map((room) => {
      return {
        id: room.hashId,
        name: room.name,
      };
    });
  }, [roomData]);

  // 小エリア（階数指定無し）
  const convertSkipFloorRoomData = useMemo(() => {
    return roomsData.map((room) => {
      return {
        id: room.hashId,
        name: room.name,
      };
    });
  }, [roomsData]);

  // 大エリア セレクトボックス用
  const wardOptions = useMemo(() => {
    const filterWard = wardsData.filter((ward) => ward.numberOfRooms > 0);
    return filterWard.map((ward) => {
      return {
        label: ward.name,
        value: ward.hashId,
      };
    });
  }, [wardsData]);

  useEffect(() => {
    if (isNullish(wardOptions[0])) return;
    setSelectedWard(wardOptions[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wardOptions[0]]);

  useEffect(() => {
    if (isNullish(floorOptions[0]) || isNullish(skipFloorSelectionData)) return;
    if (skipFloorSelectionData.value === 'true') {
      setSelectedFloor(null);
    } else {
      setSelectedFloor(floorOptions[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [floorOptions[0], skipFloorSelectionData]);

  if (
    filteredWardsData.length === 0 ||
    wardOptions.length === 0 ||
    convertRoomData.length === 0 ||
    convertSkipFloorRoomData.length === 0
  ) {
    return <InnerLoading />;
  }

  return (
    <>
      {tab === 'ward' && (
        <HospitalWardPosition filterWard={filteredWardsData} wardOptions={wardOptions} floorOptions={floorOptions} />
      )}
      {tab === 'room' && (
        <HospitalRoomPosition
          wardOptions={wardOptions}
          floorOptions={floorOptions}
          convertRoom={convertRoomData}
          convertSkipFloorRoom={convertSkipFloorRoomData}
        />
      )}
    </>
  );
};
