import React from 'react';
import {openSnackBar} from '@molecules/SnackBar';
import {PageDescription, PageTitle, ProcessTitle} from '../common/PageComponents';
import {Button, Grid, styled} from '@material-ui/core';
import {Table} from '@molecules/Table';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useSteps} from '../hooks';
import {isNullish} from '@front-libs/helpers';
import {useImportHospitalProductExcel} from '@organisms/HospitalProductImporter';
import {
  ExcelFileDropZone,
  HospitalProductTable,
  WorksheetSelector,
} from '@organisms/HospitalProductImporter/components';

type Props = {
  onCompleteImportProduct: VoidFunction;
};

/* 
〜〜HospitalProductsをインポートできるまでの遠い道のり〜
1. ユーザーがファイルを準備

2. Excel か CSVを読み込む
2-2a-1. Excelの場合、シートを選択する
2-2a-2. 選択されたシートの1行目をヘッダーとして読み込む。20行表示する
2-2b-1. CSVの場合、1行目をヘッダーとして読み込む。20行表示する

3-1-1. ヘッダーの内容を読み取りシステム予測する。システム予測ができた場合、SelectValueを埋める
3-1-2. システム予測ができない場合、ヘッダの名前からカラム予測する
3-2. ユーザーがカラム内容を設定
3-3. カラム内容を確定するボタンクリック

4-1. 最終的なHospitalProducts一覧を表示
4-2. インポート処理実行
*/
export const HospitalProductsImport: React.FC<Props> = ({onCompleteImportProduct}) => {
  const {myInfo} = useMyInfo();

  const {
    uploadFile,
    selectXlsxWorksheet,
    confirmMatchColumn,
    confirmHospitalProducts,
    fileType,
    displayedSampleRows,
    workSheetOptions,
    selectedWorkSheet,
    headerRow,
    hospitalProducts,
    prepareAllHospitalProducts,
    totalHospitalProductsCount,
    hospitalProductsPage,
    setHospitalProductsPage,
  } = useImportHospitalProductExcel(myInfo.hospitalHashId);

  const {next} = useSteps();

  const handleUpload = (acceptedFile: File) => {
    uploadFile(acceptedFile);
    openSnackBar('ファイルの読み込みが完了しました。登録内容を確認してください。');
  };

  const handleChangeSheet = (sheetOpt: {label: string; value: number}) => {
    selectXlsxWorksheet(sheetOpt);
  };

  const handleConfirmHospitalProducts = async () => {
    await confirmHospitalProducts();
    next(true);
    onCompleteImportProduct();
  };

  return (
    <>
      <PageTitle>医療機器台帳登録</PageTitle>
      <PageDescription>
        お使いの医療機器台帳、もしくは各社システムからエクスポートされたデータを元に、医療機器データをインポートできます。
      </PageDescription>

      <ProcessTitle>1. Excel/CSVデータの準備</ProcessTitle>
      <PageDescription>お使いの管理台帳から医療機器の一括インポートを行えます。</PageDescription>

      <ProcessTitle>2. Excel/CSVデータのアップロード</ProcessTitle>
      <PageDescription>
        医療機器台帳をアップロードしてください。列情報の判定のため、1行目をヘッダー列としてください。
      </PageDescription>

      <Grid style={{marginBottom: 16}}>
        <ExcelFileDropZone onUpload={handleUpload} />
      </Grid>

      {fileType === 'xlsx' && (
        <Grid style={{marginBottom: 32}}>
          <Label>シートの選択</Label>
          <WorksheetSelector
            worksheetOptions={workSheetOptions}
            selectedWorksheet={selectedWorkSheet}
            onChangeWorksheet={handleChangeSheet}
          />
        </Grid>
      )}

      <ProcessTitle style={{marginTop: 32}}>3. 医療機器台帳とHITOTSUのヘッダー項目の結び付け</ProcessTitle>
      <PageDescription>
        データ移行では医療機器台帳のヘッダー項目とHITOTSUのヘッダー項目を結び付ける必要があり、項目の設定作業を実施していきます。
        既にHITOTSUのシステム側で項目の類推は実施しておりますが、お客様側で項目の再設定や確認作業をお願いいたします。
        <br />
        ※なお、初回データ登録(本作業)以降でも、医療機器の登録を実施できます。
      </PageDescription>
      {displayedSampleRows.length > 0 && !isNullish(selectedWorkSheet) && (
        <>
          <Table showSelection={false} data={displayedSampleRows} columns={headerRow} />
          <Button
            color="primary"
            variant="contained"
            style={{width: 192, marginBottom: 32, marginTop: 16}}
            onClick={confirmMatchColumn}>
            登録する
          </Button>
        </>
      )}
      {hospitalProducts.length > 0 && (
        <>
          <ProcessTitle>4. 医療機器：管理番号単位の全データの確定</ProcessTitle>
          <PageDescription>
            読み込んだファイルの医療機器の一覧をHITOTSUのデータに割り当てた場合の情報を表示しています。
            <br />
            一部のみ表示しておりますが、アップロードいただいた全量のデータにつきまして、インポートいたします。
          </PageDescription>
          <HospitalProductTable
            data={hospitalProducts}
            page={hospitalProductsPage + 1}
            totalCount={totalHospitalProductsCount}
            onPageChange={(newPage: number) => {
              setHospitalProductsPage(newPage - 1);
            }}
          />
          <Button
            disabled={!prepareAllHospitalProducts}
            color="primary"
            variant="contained"
            style={{width: 192, marginBottom: 32}}
            onClick={handleConfirmHospitalProducts}>
            登録する
          </Button>
        </>
      )}
    </>
  );
};

export const Label = styled('label')({
  fontSize: 14,
});
