import React from 'react';
import {Grid, Button, Typography, Drawer, Theme, styled, Paper, SxProps} from '@mui/material';

const PaperStyles: SxProps<Theme> = (theme: Theme) => ({
  backgroundColor: theme.palette.secondary.light,
  borderTop: `1px solid ${theme.palette.grey[200]}`,
});

const StyledContentContainer = styled(Grid)({
  padding: '16px 56px',
});

const StyledProperty = styled(Grid)({
  display: 'flex',
  flexWrap: 'wrap',
  alignContent: 'center',
});
const StyledButton = styled(Button)({
  marginLeft: '12px',
  marginRight: '32px',
});

type NameDrawerProps = {
  nameData: {[id: string]: string};
  result: (result: {[id: string]: string}) => void;
};
export const NameDrawer = ({nameData, result}: NameDrawerProps) => {
  return (
    <Drawer
      anchor={'bottom'}
      open={Object.keys(nameData).length > 0}
      variant="persistent"
      PaperProps={{sx: PaperStyles, elevation: 4}}>
      <StyledContentContainer container>
        <Grid item>
          <Button
            variant={'contained'}
            color={'primary'}
            size={'large'}
            onClick={() => {
              result(nameData);
            }}>
            保存
          </Button>
          <StyledButton
            variant={'outlined'}
            color={'primary'}
            size={'large'}
            onClick={() => {
              // キャンセル時は空を返す
              result({});
            }}>
            キャンセル
          </StyledButton>
        </Grid>
        <StyledProperty>
          <Typography>{`${Object.keys(nameData).length}件の名称の変更内容を保存しますか？`}</Typography>
        </StyledProperty>
      </StyledContentContainer>
    </Drawer>
  );
};
