import {StrUtil} from '@front-libs/helpers';
import {ChevronLeft, ChevronRight} from '@mui/icons-material';
import {Box, Button, SxProps, Theme} from '@mui/material';
import React from 'react';

type ButtonOption = {
  label?: string;
  disabled?: boolean;
  buttonHandler: VoidFunction;
};

type Props = {
  text: string;
  backButton?: ButtonOption | null;
  nextButton?: ButtonOption | null;
  containerSx?: SxProps<Theme>;
};

/**
 * フッターを表示するコンポーネント
 * テキスト、およびオプションの戻るボタン・次へボタンを含む
 */
export const SharedFooter = ({text, backButton, nextButton, containerSx}: Props) => {
  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.rent.main,
        display: 'flex',
        alignItems: 'center',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        height: '128px',
        ...containerSx,
      }}>
      {backButton && (
        <Button
          data-testid="rental-footer-back-button"
          variant="outlined"
          size="small"
          onClick={backButton.buttonHandler}
          sx={backButtonStyle}>
          <ChevronLeft sx={chevronLeftStyle} />
          <Box sx={buttonTextStyle}>{backButton.label || '戻る'}</Box>
        </Button>
      )}
      <Box sx={textStyle}>{StrUtil.nl2br(text)}</Box>
      {nextButton && (
        <Button
          variant="outlined"
          disabled={nextButton.disabled}
          onClick={nextButton.buttonHandler}
          sx={nextButtonStyle}>
          <Box sx={nextBtnTextStyle}>{nextButton.label || '次へ'}</Box>
          <ChevronRight sx={chevronRightStyle} />
        </Button>
      )}
    </Box>
  );
};
const buttonTextStyle: SxProps<Theme> = {
  paddingTop: '4px',
  paddingRight: '24px',
  minWidth: '52px',
};

const nextBtnTextStyle: SxProps<Theme> = {
  paddingTop: '4px',
  paddingLeft: '24px',
};

const chevronLeftStyle: SxProps<Theme> = {
  paddingTop: '4px',
  paddingRight: '8px',
  fontSize: '24px',
};

const chevronRightStyle: SxProps<Theme> = {
  paddingTop: '4px',
  paddingRight: '8px',
  fontSize: '24px',
};

const textStyle: SxProps<Theme> = (theme) => ({
  color: theme.palette.common.white,
  fontSize: '22px',
  fontWeight: 'bold',
  textAlign: 'center',
  width: '100%',
  [theme.breakpoints.up('tabletV')]: {
    fontSize: '32px',
    lineHeight: '1.2em',
  },
  [theme.breakpoints.up('tabletH')]: {
    fontSize: '36px',
  },
  [theme.breakpoints.up('laptop')]: {
    fontSize: '36px',
  },
});

const backButtonStyle: SxProps<Theme> = (theme) => ({
  justifyContent: 'left',
  alignItems: 'center',
  backgroundColor: theme.palette.common.white,
  color: theme.palette.rent.main,
  position: 'absolute',
  left: '0px',
  fontSize: '18px',
  fontWeight: 'bold',
  height: '60px',
  minWidth: '108px',
  padding: '5px 0px 5px 8px',
  borderRadius: '0px 45px 45px 0px',
  border: 'none',
  boxShadow: '0px 4px 0px 0px rgba(209, 216, 245, 1)',
  '&:hover': {
    backgroundColor: theme.palette.common.white,
    border: 'none',
  },
  [theme.breakpoints.up('tabletV')]: {
    minWidth: '140px',
    fontSize: '18px',
  },
  [theme.breakpoints.up('tabletH')]: {
    minWidth: '160px',
    fontSize: '20px',
  },
  [theme.breakpoints.up('laptop')]: {
    minWidth: '176px',
    fontSize: '24px',
    padding: '0 16px',
  },
  '& .MuiButton-label': {
    justifyContent: 'left',
  },
});

const nextButtonStyle: SxProps<Theme> = (theme) => ({
  justifyContent: 'right',
  backgroundColor: theme.palette.common.white,
  color: theme.palette.rent.main,
  position: 'absolute',
  right: '0px',
  fontSize: '18px',
  fontWeight: 'bold',
  height: '60px',
  padding: '5px 0px 5px 8px',
  '&:hover': {
    backgroundColor: theme.palette.common.white,
    border: 'none',
  },
  [theme.breakpoints.up('tabletH')]: {
    fontSize: '20px',
  },
  [theme.breakpoints.up('laptop')]: {
    fontSize: '24px',
    padding: '0px 16px',
  },
  borderRadius: '45px 0px 0px 45px',
  border: 0,
  boxShadow: '0px 4px 0px 0px rgba(209, 216, 245, 1)',
});
