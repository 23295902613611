import React, {useMemo, useState} from 'react';
import {Clear, Fullscreen, FullscreenExit, Minimize} from '@material-ui/icons';
import {makeStyles, Grid, IconButton, Button, Theme} from '@material-ui/core';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import clsx from 'clsx';

export type CommentDialogProps = {
  defaultValue?: string;
} & Omit<DialogProps, 'open'>;

type StyleProps = {
  isFullscreen: boolean;
  isMinimized: boolean;
};

export const CommentDialog: React.FC<CommentDialogProps> = (props) => {
  const [isFullscreen, setFullScreen] = useState(false);
  const [isMinimized, setMinimized] = useState(false);
  const [value, setValue] = useState(props.defaultValue ?? '');
  const classes = useStyle({isFullscreen, isMinimized});

  const disabled = useMemo(() => value === '', [value]);

  const headerButtons = useMemo(() => {
    return [
      <IconButton key="minimized" className={classes.button} onClick={() => setMinimized(!isMinimized)}>
        <Minimize style={{transform: isMinimized ? 'rotate(180deg)' : undefined}} />
      </IconButton>,
      isFullscreen ? (
        <IconButton
          key={'exit-fullscreen'}
          className={classes.button}
          onClick={() => {
            if (isMinimized) setMinimized(false);
            else setFullScreen(false);
          }}>
          <FullscreenExit />
        </IconButton>
      ) : (
        <IconButton
          key={'fullscreen'}
          className={classes.button}
          onClick={() => {
            if (isMinimized) setMinimized(false);
            else setFullScreen(true);
          }}>
          <Fullscreen />
        </IconButton>
      ),
      <IconButton key="reject" className={classes.button} onClick={props.actions.reject}>
        <Clear />
      </IconButton>,
    ];
  }, [classes.button, isFullscreen, isMinimized, props.actions.reject]);

  return (
    <div className={clsx({[classes.backdrop]: !isMinimized && isFullscreen})}>
      <Grid className={classes.root}>
        <Grid container className={classes.header}>
          <Grid item className={classes.title}>
            コメント
          </Grid>
          <Grid item className={classes.buttons}>
            <Grid>{headerButtons}</Grid>
          </Grid>
        </Grid>
        <Grid className={classes.body}>
          <textarea
            value={value}
            onChange={(e) => setValue(e.target.value)}
            tabIndex={isMinimized ? -1 : 1}
            className={classes.inputComment}
            placeholder={'コメントを残すには入力してください・・・'}
          />
        </Grid>
        <Grid className={classes.footer}>
          <Grid item className={classes.footerBtnContainer}>
            <Button
              disabled={disabled}
              onClick={() => props.actions.resolve(value)}
              color={'primary'}
              variant={'contained'}
              disableElevation
              style={{padding: '8px 24px'}}>
              コメントを保存
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const useStyle = makeStyles<Theme, StyleProps>(({palette, zIndex}: Theme) => ({
  root: {
    position: 'fixed',
    margin: 0,
    border: 'none',
    boxShadow: '0px 0px 20px 4px rgba(0,0,0,0.2)',
    overflow: 'hidden',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    transitionDuration: '.2s',
    zIndex: zIndex.drawer + 10,
    bottom: ({isFullscreen, isMinimized}) => (!isFullscreen || isMinimized ? 0 : 'unset'),
    right: ({isFullscreen, isMinimized}) => (!isFullscreen || isMinimized ? 60 : 'unset'),
    width: ({isFullscreen, isMinimized}) => (isFullscreen && !isMinimized ? '92vw' : 720),
    height: ({isFullscreen, isMinimized}) => (isMinimized ? 50 : isFullscreen ? '92vh' : 560),
  },
  header: {
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#506F90',
    color: 'white',
    fontSize: 18,
    height: 56,
    flex: '0 0 50px',
    padding: '0px 24px',
  },
  title: {
    flex: '0 0 auto',
    width: 'auto',
  },
  buttons: {
    flex: '0 0 auto',
    width: 'auto',
  },
  button: {
    color: 'white !important',
  },
  body: {
    flex: '1 1 0',
    padding: '16px 24px',
  },
  inputComment: {
    width: '100%',
    height: '100%',
    borderStyle: 'none',
    resize: 'none',
    fontSize: 16,
    '&:focus-visible': {
      borderStyle: 'none',
      outline: 'none',
    },
  },
  footer: {
    height: 80,
    borderTop: `2px solid ${palette.grey[200]}`,
    boxSizing: 'border-box',
  },
  footerBtnContainer: {
    height: '100%',
    padding: '16px 0 24px 32px',
  },
  backdrop: {
    zIndex: zIndex.appBar + 1000,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    position: 'fixed',
    right: 0,
    bottom: 0,
    top: 0,
    left: 0,
  },
}));
