import {CodeReaderState, CodeReaderAction, CodeReaderActionType} from '../types';

export const codeReaderInitialState: CodeReaderState = {
  codeReaderType: 'barcodeReader',
};

export const codeReaderReducer = (state: CodeReaderState, action: CodeReaderAction): CodeReaderState => {
  switch (action.type) {
    case CodeReaderActionType.CODE_READER_PUT_ITEM: {
      return {
        ...state,
        codeReaderType: action.payload.codeReaderType,
      };
    }
    default:
      return state;
  }
};
