import {createStyles, makeStyles, Theme} from '@material-ui/core';

export const appHeaderHeight = '56px';

export const useTemplate = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      overflow: 'hidden',
    },
    header: {
      zIndex: theme.zIndex.drawer - 1,
      backgroundColor: theme.palette.common.white,
      height: appHeaderHeight,
      position: 'fixed',
      left: 0,
      right: 0,
    },
    content: {
      maxHeight: `calc(100vh - ${appHeaderHeight})`,
      marginTop: appHeaderHeight,
      overflow: 'auto',
    },
    noHeaderContent: {
      maxHeight: `100vh`,
      marginTop: 0,
      overflow: 'auto',
    },
  })
);
