import React from 'react';
import {HospitalProductPlanListElement} from '../../types';
import {HospitalProductPlanStatus} from '@components/molecules/HospitalProductPlanStatus';

type StatusColumnProps = {
  rowData: HospitalProductPlanListElement;
};

export const StatusColumn = ({rowData}: StatusColumnProps) => {
  return <HospitalProductPlanStatus status={rowData.status} />;
};
