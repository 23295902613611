import {HeadingLabel} from '@Apps/FaultReception/HeadingLabel';
import {ReturnsReservationRequest} from '@modules/returns_reservation/type';
import {Option} from '@molecules/Selector';
import {Paper, SxProps} from '@mui/material';
import React from 'react';
import {Control, Controller} from 'react-hook-form';
import {FormSelectBox} from './FormSelectBox';
import {FormTextField} from './FormTextField';
import {ReceptionSelectionMap} from '../consts';

const paperStyle: SxProps = {
  backgroundColor: 'white',
  height: 'calc(100vh - 406px)',
  margin: '0 112px 32px',
  padding: '64px',
  overflow: 'auto',
  borderRadius: '20px',
};

type FormContainerProps = {control: Control<ReturnsReservationRequest>};

/**
 * 返却待ち受付のフォームコンテナ
 * @param control: Control<ReturnsReservationRequest>
 * @returns
 */
export const FormContainer = ({control}: FormContainerProps) => {
  const selectData: Option<string>[] = new Array(20).fill(0).reduce((acc, _, i) => {
    const value = `${i + 1}`;
    acc.push({label: value, value: value});
    return acc;
  }, [] as Option<string>[]);

  return (
    <Paper sx={paperStyle}>
      <HeadingLabel title={ReceptionSelectionMap.label.reservationCount} required />
      <Controller
        name="reservationCount"
        control={control}
        render={({field}) => (
          <FormSelectBox
            {...field}
            selectData={selectData}
            onChange={(value) => field.onChange(Number(value))}
            value={String(field.value || '')}
          />
        )}
      />
      <HeadingLabel title={ReceptionSelectionMap.label.contactInformation} required={false} />
      <Controller
        name="contactInformation"
        control={control}
        render={({field}) => <FormTextField {...field} onChange={(val) => field.onChange(val)} />}
      />
      <HeadingLabel title={ReceptionSelectionMap.label.transitionMessage} required={false} />
      <Controller
        name="transitionMessage"
        control={control}
        render={({field}) => <FormTextField {...field} onChange={(val) => field.onChange(val)} rows={3} />}
      />
    </Paper>
  );
};
