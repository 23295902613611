import React from 'react';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import {
  Button,
  CSSObject,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  styled,
} from '@mui/material';
import {Close} from '@mui/icons-material';

const defaultFontSize: CSSObject = {fontSize: '14px'};
const StyledDialogContent = styled(DialogContent)(({theme}) => ({
  paddingBottom: 0,
  '& p': defaultFontSize,
  '& li': defaultFontSize,
  '& ul': {margin: '4px 0 24px'},
}));

export type InspectionUpdateAlertDialogProps = DialogProps;

/**
 *  定期点検計画の更新時に表示されるダイアログ
 *
 * @example
 * こんな感じで使ってください
 * ```tsx
 * try {
 *   await dialogHandler.open(InspectionPlanUpdateDialog, {});
 * } catch (e) {
 *   // キャンセル押下時の動作
 *   return;
 * }
 * ```
 *
 * @param props.open ダイアログを開くかどうか
 * @param props.actions ダイアログの結果を返すためのresolve, reject関数
 */
export const InspectionUpdateAlertDialog: React.FC<DialogProps> = (props) => {
  const {open, actions} = props;

  const handleClose = () => {
    actions.reject();
  };
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" sx={{color: '#172B4D'}}>
      <DialogTitle sx={{fontSize: '20px', fontWeight: 700}}>
        編集内容を保存しますか？
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: '#172B4D',
          }}>
          <Close />
        </IconButton>
      </DialogTitle>
      <StyledDialogContent>
        <Typography>保存した内容は、以下で使用される点検表に反映されます。</Typography>
        <ul>
          <li>
            <Typography>未実施の点検実施予定</Typography>
          </li>
          <li>
            <Typography>予定月超過の点検実施予定</Typography>
          </li>
        </ul>
        <Typography>以下で使用される点検表には反映されません。</Typography>
        <ul>
          <li>
            <Typography>スキップした点検実施予定</Typography>
          </li>
          <li>
            <Typography>実施途中の点検</Typography>
          </li>
          <li>
            <Typography>完了した点検</Typography>
          </li>
        </ul>
      </StyledDialogContent>
      <DialogActions sx={{marginTop: 0}}>
        <Button variant="contained" color="primary" onClick={actions.resolve}>
          保存する
        </Button>
        <Button onClick={handleClose} color="primary">
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
};
