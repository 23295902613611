import React from 'react';
import {styled, TableRow, TableCell, Radio} from '@mui/material';
import dayjs from 'dayjs';
import {SmallInspectionResultStatusBadge} from '@components/atoms/InspectionResultStatusBadge';
import {InspectionResultIndex} from '@modules/inspection_results/types';
import {UserFormatter} from '@modules/hospital_users/helpers';
import {InspectionType} from '@components/organisms/Header/pc/GeneralMenu/HospitalProductMenu/types';

type Props = {
  inspectionType: InspectionType;
  inspectionResult: InspectionResultIndex;
  checked: boolean;
  onSelect: (inspectionResult: InspectionResultIndex) => void;
};

const Td = styled(TableCell)({
  borderBottom: 0,
  borderTop: '1px solid #F3F4F6',
  padding: '8px 16px',
  color: 'inherit',
});

export const InspectionResultSelectTableRow = ({inspectionType, inspectionResult, checked, onSelect}: Props) => {
  const {
    scheduledTime,
    status,
    inspectionProduct,
    inspection: {name: inspectionName},
    numberOfSameDayFormerResults,
    suspendedAt,
    isAdhocDate,
    inspector,
  } = inspectionResult;

  const renderScheduledTime = () => {
    if (inspectionType !== 'periodic') return null;
    if (!scheduledTime) return <Td />;

    const scheduledDay = dayjs(scheduledTime);
    const scheduledDayText = scheduledDay.format('YYYY/MM/DD');
    const isOver = scheduledDay.isBefore(dayjs().startOf('month'), 'month');
    if (isOver) {
      return (
        <Td align="left" sx={{color: '#C7243A'}}>
          {`${scheduledDayText}（予定月超過）`}
        </Td>
      );
    }

    return <Td align="left">{scheduledDayText}</Td>;
  };

  const renderNumberOfSameDayFormerResults = () => {
    if (inspectionType !== 'pre_use') return null;

    if (numberOfSameDayFormerResults === null) return <Td />;
    return <Td align="left">{`${numberOfSameDayFormerResults}回`}</Td>;
  };

  const handleSelect = () => {
    onSelect(inspectionResult);
  };

  return (
    <TableRow hover>
      <Td align="center">
        <Radio sx={{padding: 0}} name="inspection-result-radio-buttons" checked={checked} onChange={handleSelect} />
      </Td>
      {renderScheduledTime()}
      <Td align="left" sx={{'& > div': {minWidth: 'unset'}}}>
        <SmallInspectionResultStatusBadge status={status} />
      </Td>
      <Td align="left">
        {
          inspectionProduct?.name /* NOTE: 型の定義上、inspectionProductはnullになり得ないが、実際にAPIが返す値を見るとnullの時があったのでinspectionProduct?.nameとしている。 */
        }
      </Td>
      <Td align="left">{inspectionName}</Td>
      {renderNumberOfSameDayFormerResults()}
      <Td align="left">{suspendedAt && dayjs(suspendedAt).format('YYYY/MM/DD HH:mm')}</Td>
      <Td align="left">{isAdhocDate ? '臨時' : '通常'}</Td>
      <Td align="left">{UserFormatter.getFullName(inspector ?? undefined)}</Td>
    </TableRow>
  );
};
