import React from 'react';
import {Card, CardActionArea, CardContent, SxProps, Theme, Box} from '@mui/material';
import {LenderNameCardProps} from './types';

export const LenderNameCard: React.FC<LenderNameCardProps> = ({userName, onSubmitInput}) => {
  return (
    <Card sx={cardStyle}>
      <CardActionArea onClick={() => onSubmitInput(userName)}>
        <CardContent sx={cardContentStyle}>{userName}</CardContent>
      </CardActionArea>
    </Card>
  );
};

const cardStyle: SxProps<Theme> = (theme) => ({
  width: '120px',
  height: '64px',
  display: 'flex',
  borderRadius: '20px',
  boxShadow: '0px 4px 0px 0px rgba(209, 216, 245, 1)',
  overflow: 'auto',
  wordBreak: 'break-all',
  [theme.breakpoints.up('tabletH')]: {
    width: '128px',
  },
});

const cardContentStyle: SxProps<Theme> = (theme) => ({
  color: theme.palette.rent.main,
  textAlign: 'center',
  fontSize: '16px',
  fontWeight: 'bold',
  padding: '4px 16px',
});
