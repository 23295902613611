export const initialValues = {
  rootCategoryHashId: '',
  narrowCategoryHashId: '',
  displayName: '',
  name: '',
  makerHashId: null,
  approvalNumber: null,
  jmdnCode: null,
  janCode: null,
  isSpecificMaintain: null,
  className: null,
  catalogPrice: null,
};
