import React from 'react';
import {
  ClickAwayListener,
  createStyles,
  Grid,
  Grow,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {SettingMenuItemType, SettingMenuItemTypesToLabel} from './types';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import {isNullish} from '@front-libs/helpers';
import {Settings} from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      borderTop: `4px solid ${theme.palette.primary.dark}`,
    },
    headerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: '16px',
    },
    leftContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
    informationContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      marginLeft: '0px',
    },
    paperTitle: {
      fontWeight: 'bold',
      padding: '4px',
    },
    fullPaperSpace: {
      height: 'calc(100% - 36px)',
    },
    tooltip: {
      cursor: 'pointer',
      padding: '4px',
    },
    info: {
      cursor: 'pointer',
      padding: '10px 4px 0px 0px',
      width: '15px',
      height: '15px',
    },
    popper: {
      zIndex: 1000,
    },
    dragIcon: {
      height: '100%',
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
  })
);

type Props = {
  title: string;
  onClickSettingMenu?: () => void;
  settingMenuItems?: SettingMenuItemType[];
};

export const PaperHeader: React.FC<Props> = (props) => {
  const {title, onClickSettingMenu, settingMenuItems} = props;
  const classes = useStyles();

  return (
    <Grid container className={classes.headerContainer}>
      <Grid item className={classes.leftContainer}>
        <DragIndicatorIcon className={`${classes.dragIcon} dragHandle`} />
        <Typography variant={'subtitle1'} className={classes.paperTitle}>
          {title}
        </Typography>
      </Grid>
      <Grid item>
        {!isNullish(settingMenuItems) && !isNullish(onClickSettingMenu) && (
          <SettingMenu settingMenuItems={settingMenuItems} onClickSettingMenu={onClickSettingMenu} />
        )}
      </Grid>
    </Grid>
  );
};

type SettingMenuProps = {
  settingMenuItems: SettingMenuItemType[];
  // biome-ignore lint/suspicious/noConfusingVoidType: <explanation>
  onClickSettingMenu: () => void | null;
};

export const SettingMenu: React.FC<SettingMenuProps> = (props) => {
  const {settingMenuItems, onClickSettingMenu} = props;
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  const clickButton = (e: React.MouseEvent) => {
    e.stopPropagation();
    setOpen(!open);
  };

  const handleMenuItemClick = () => {
    onClickSettingMenu();
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="設定" className={classes.tooltip}>
        <Settings ref={anchorRef} onClick={clickButton} />
      </Tooltip>

      <Popper className={classes.popper} open={open} anchorEl={anchorRef.current} transition>
        {({TransitionProps}) => (
          <Grow {...TransitionProps} style={{transformOrigin: 'placement'}}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {settingMenuItems.map((item, index) => (
                    <MenuItem key={index} onClick={handleMenuItemClick}>
                      {SettingMenuItemTypesToLabel[item]}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
