import React, {useCallback} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Typography,
  IconButton,
} from '@material-ui/core';
import {useFormikContext, Formik, Form} from 'formik';
import Selector from '@molecules/Formik/fields/Selector';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import * as yup from 'yup';
import CloseIcon from '@material-ui/icons/Close';
import {RequiredLabel} from '@molecules/FormRequiredLabel';
import {InferType} from 'yup';

const useStyles = makeStyles((theme) => ({
  title: {
    padding: '24px 32px 0px',
    '& h2': {
      color: '#172B4D',
      fontSize: '20px',
      fontWeight: 'bold',
    },
  },
  closeButton: {
    padding: '0px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '40px',
    padding: '0px 32px 0px',
    '& > div:not(:first-child)': {
      marginTop: '24px',
    },
  },
  formHelperText: {
    fontSize: 14,
    color: theme.palette.grey[600],
    marginLeft: '36px',
  },
  actions: {
    marginTop: '24px',
    padding: '0px 32px 32px',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

type ExportPeriodicInspectionResultFormProps = {
  open: boolean;
  onClose: React.MouseEventHandler;
};

const NEXT_YEAR_TO_SELECT = 1;

const ExportPeriodicInspectionResultForm: React.FC<ExportPeriodicInspectionResultFormProps> = (props) => {
  const {open, onClose} = props;
  const classes = useStyles();
  const {submitForm} = useFormikContext();

  const handleSubmit = useCallback(() => {
    submitForm();
  }, [submitForm]);

  const currentYear = new Date().getFullYear();
  const startYear = Array.from(new Array(11), (_, index) => {
    const year: number = currentYear + NEXT_YEAR_TO_SELECT - index;
    return {
      value: year,
      label: `${year}年`,
    };
  });

  const startMonth: {value: number; label: string}[] = Array.from(new Array(12), (_, index) => {
    const month: number = index;
    return {
      value: month,
      label: `${month + 1}月`, // valueが0~11のため、表示するときは+1する
    };
  });

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="xs" aria-labelledby="form-dialog-title">
      <DialogTitle className={classes.title} disableTypography>
        <Grid container justify="space-between" alignItems="center">
          <Typography variant="h2">{`定期点検計画の開始年月を選択`}</Typography>
          <IconButton onClick={onClose} className={classes.closeButton}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Grid item>
          <RequiredLabel>開始年</RequiredLabel>
          <Selector name="startYear" size="small" options={startYear} />
        </Grid>
        <Grid item>
          <RequiredLabel>開始月</RequiredLabel>
          <Selector name="startMonth" size="small" options={startMonth} />
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          確定
        </Button>
        <Button onClick={onClose} color="primary">
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export type ExportPeriodicInspectionResultDialogProps = DialogProps<ExportPeriodicInspectionResultDialogResult> & {
  startYear: number;
  startMonth: number;
};

export type ExportPeriodicInspectionResultDialogResult = {
  startYear: number;
  startMonth: number;
};

const validationSchema = yup.object({
  startYear: yup.number().required(),
  startMonth: yup.number().required(),
});

type ExportPeriodicInspectionResultFormData = InferType<typeof validationSchema>;

export const ExportPeriodicInspectionResultDialog: React.FC<ExportPeriodicInspectionResultDialogProps> = (props) => {
  const {open, startMonth, startYear} = props;
  const handleSubmit = useCallback(
    async (res: ExportPeriodicInspectionResultFormData) => {
      await props.actions.resolve(res as ExportPeriodicInspectionResultDialogResult);
    },
    [props.actions]
  );

  const handleClose = useCallback(
    (e: React.MouseEvent) => {
      props.actions.reject();
    },
    [props.actions]
  );

  return (
    <Formik initialValues={{startYear, startMonth}} onSubmit={handleSubmit} isInitialValid={false}>
      <Form>
        <ExportPeriodicInspectionResultForm open={open ?? false} onClose={handleClose} />
      </Form>
    </Formik>
  );
};
