import {useCallback, useEffect} from 'react';
import qs from 'qs';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import {useNavigate} from 'react-router-dom';
import {useOnlyOnce} from '@front-libs/core';
import {useSearchParams} from '@front-libs/core';
import {pickFirstQuery} from '@front-libs/helpers';
import {convertDateToRFC3339, convertRFC3339ToDate} from '@front-libs/helpers';
import {
  createResetAction,
  initialState,
  useCompletedAtRange,
  useInspectionResultListState,
  useInspectionResultStatus,
  useInspectionType,
  useInspector,
  useOrderKey,
  usePage,
  useScheduledTimeRange,
  useQuerySearchName,
  useRootCategory,
  useNarrowCategory,
  useIsAdhoc,
  useIsPassed,
  useInitialized,
  usePerPage,
  useHospitalWard,
  useHospitalRoom,
  useRentHospitalRoom,
  useRentHospitalWard,
} from './states';
import {ViewInspectionTypes, getInspectionStatusMenus} from '../../consts';
import {getValidScheduledAtFrom, getValidScheduledAtTo} from './selectors';

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export const useResetByQueryParams = () => {
  const queryParams = useSearchParams();
  const [, dispatch] = useInspectionResultListState();
  const [initialized, setInitialized] = useInitialized();

  const reset = useCallback(() => {
    const state = initialState;

    state.page = Number(queryParams.page) || 1;

    const queryInspectionType = pickFirstQuery(queryParams.type);
    state.inspectionType = ViewInspectionTypes.find((m) => m === queryInspectionType) ?? 'pre_use';

    const queryInspectionResultStatus = pickFirstQuery(queryParams.status);
    state.inspectionResultStatus =
      getInspectionStatusMenus(state.inspectionType).find(({value}) => value === queryInspectionResultStatus)?.value ??
      'unplanned';
    const completedAtFrom = convertRFC3339ToDate(pickFirstQuery(queryParams.completedAtFrom) ?? '');
    const completedAtTo = convertRFC3339ToDate(pickFirstQuery(queryParams.completedAtTo) ?? '');
    if (
      // (1) 両方セットされていてかつ大小関係が正しい
      (completedAtFrom !== null && completedAtTo !== null && dayjs(completedAtFrom).isSameOrBefore(completedAtTo)) ||
      // (2) どちらか片方がセットされている
      completedAtFrom !== null ||
      completedAtTo !== null
    ) {
      if (completedAtFrom !== null) {
        state.completedAtFrom = completedAtFrom;
      }

      if (completedAtTo !== null) {
        state.completedAtTo = completedAtTo;
      }
    }

    //  ダッシュボード経由で遷移した時の日時取得用 convertRFC3339ToDateで変換出来ないのでDayjsに渡している
    const scheduledTimeFrom = pickFirstQuery(queryParams.scheduledTimeFrom);
    if (scheduledTimeFrom) {
      // ISO 8601形式になるようにタイムゾーン指定を+HH:mmになるようにreplace
      state.scheduledTimeFrom = dayjs(scheduledTimeFrom.replace(' ', '+')).toDate();
    }
    const scheduledTimeTo = pickFirstQuery(queryParams.scheduledTimeTo);
    if (scheduledTimeTo) {
      state.scheduledTimeTo = dayjs(scheduledTimeTo.replace(' ', '+')).toDate();
    }

    const scheduledAtFrom = convertRFC3339ToDate(pickFirstQuery(queryParams.scheduledAtFrom) ?? '');
    const scheduledAtTo = convertRFC3339ToDate(pickFirstQuery(queryParams.scheduledAtTo) ?? '');
    // どちらか片方がセットされている または どちらともセットしていて大小関係が正しい
    if (
      // (1) 両方セットされていてかつ大小関係が正しい
      (scheduledAtFrom !== null && scheduledAtTo !== null && dayjs(scheduledAtFrom).isSameOrBefore(scheduledAtTo)) ||
      // (2) どちらか片方がセットされている
      scheduledAtFrom !== null ||
      scheduledAtTo !== null
    ) {
      if (scheduledAtFrom !== null) {
        if (
          // 定期点検でない
          state.inspectionType !== 'periodic' ||
          // または、未実施ではない
          state.inspectionResultStatus !== 'unplanned' ||
          // または、当月以降の下限が与えられている
          dayjs(scheduledAtFrom).isSameOrAfter(dayjs().startOf('month'))
        ) {
          state.scheduledTimeFrom = scheduledAtFrom;
        }
      }

      if (scheduledAtTo !== null) {
        if (
          // 定期点検でない
          state.inspectionType !== 'periodic' ||
          // または、予定月超過でない
          state.inspectionResultStatus !== 'overdue' ||
          // または、先月以前の上限が与えられている
          dayjs(scheduledAtTo).isSameOrBefore(dayjs().subtract(1, 'month').endOf('month'))
        ) {
          state.scheduledTimeTo = scheduledAtTo;
        }
      }
    }

    const inspector = pickFirstQuery(queryParams.inspector);
    if (inspector) {
      state.inspector = inspector;
    }

    const isAdhoc = pickFirstQuery(queryParams.isAdhoc);
    if (isAdhoc) {
      state.isAdhoc = Boolean(isAdhoc);
    }

    const isPassed = pickFirstQuery(queryParams.isPassed);
    if (isPassed) {
      state.isPassed = Boolean(isPassed);
    }

    const categories = pickFirstQuery(queryParams.categories);
    if (categories) {
      state.rootCategory = categories;
    }

    const hospitalWard = pickFirstQuery(queryParams.hospitalWard);
    if (hospitalWard) {
      state.hospitalWard = hospitalWard;
    }

    const hospitalRoom = pickFirstQuery(queryParams.hospitalRoom);
    if (hospitalRoom) {
      state.hospitalRoom = hospitalRoom;
    }

    const rentHospitalWard = pickFirstQuery(queryParams.rentHospitalWard);
    if (rentHospitalWard) {
      state.rentHospitalWard = rentHospitalWard;
    }

    const rentHospitalRoom = pickFirstQuery(queryParams.rentHospitalRoom);
    if (rentHospitalRoom) {
      state.rentHospitalRoom = rentHospitalRoom;
    }

    dispatch(createResetAction(state));
  }, []);

  useOnlyOnce(() => {
    if (!initialized) {
      reset();
    }
  });

  useCallback(() => {
    return () => {
      setInitialized(false);
    };
  }, [setInitialized]);

  return reset;
};

export const useUpdateQueryParamsByState = () => {
  const navigate = useNavigate();
  const [initialized] = useInitialized();

  const [orderKey] = useOrderKey();
  const [page] = usePage();
  const [perPage] = usePerPage();
  const searchName = useQuerySearchName();
  const [inspectionType] = useInspectionType();
  const [inspectionResultStatus] = useInspectionResultStatus();
  const [inspectorHashId] = useInspector();
  const [[scheduledAtFrom, scheduledAtTo]] = useScheduledTimeRange();
  const [[completedAtFrom, completedAtTo]] = useCompletedAtRange();
  const [isAdhoc] = useIsAdhoc();
  const [isPassed] = useIsPassed();
  const [rootCategory] = useRootCategory();
  const [narrowCategory] = useNarrowCategory();
  const [hospitalWard] = useHospitalWard();
  const [hospitalRoom] = useHospitalRoom();
  const [rentHospitalWard] = useRentHospitalWard();
  const [rentHospitalRoom] = useRentHospitalRoom();

  useEffect(() => {
    if (!initialized) return;

    const query: {[key: string]: string | number | boolean | undefined} = {};

    if (page >= 2) {
      query.page = page;
    }

    if (perPage) {
      query.perPage = perPage;
    }

    if (orderKey) query.order = orderKey;
    if (searchName) query.name = searchName;

    query.type = inspectionType;
    query.status =
      getInspectionStatusMenus(inspectionType).find(({value}) => value === inspectionResultStatus)?.value ??
      'unplanned';

    if (scheduledAtFrom !== null) {
      const validFrom = getValidScheduledAtFrom(inspectionType, inspectionResultStatus, scheduledAtFrom)?.toDate();
      query.scheduledAtFrom = validFrom ? convertDateToRFC3339(validFrom) : undefined;
    }

    if (scheduledAtTo !== null) {
      const validTo = getValidScheduledAtTo(inspectionType, inspectionResultStatus, scheduledAtTo)?.toDate();
      query.scheduledAtTo = validTo ? convertDateToRFC3339(validTo) : undefined;
    }

    if (completedAtFrom !== null) {
      query.completedAtFrom = convertDateToRFC3339(completedAtFrom);
    }

    if (completedAtTo !== null) {
      query.completedAtTo = convertDateToRFC3339(completedAtTo);
    }

    if (isAdhoc !== null) {
      query.isAdhoc = isAdhoc;
    }

    if (isPassed !== null) {
      query.isPassed = isPassed;
    }

    if (rootCategory && !narrowCategory) {
      query.categories = rootCategory;
    } else if (narrowCategory) {
      query.categories = narrowCategory;
    }

    if (hospitalWard !== null) {
      query.hospitalWard = hospitalWard;
    }

    if (hospitalRoom !== null) {
      query.hospitalRoom = hospitalRoom;
    }

    if (rentHospitalWard !== null) {
      query.rentHospitalWard = rentHospitalWard;
    }

    if (rentHospitalRoom !== null) {
      query.rentHospitalRoom = rentHospitalRoom;
    }
    navigate(
      {
        search: qs.stringify(query, {arrayFormat: 'brackets'}),
      },
      {replace: true}
    );
  }, [
    navigate,
    initialized,
    page,
    perPage,
    orderKey,
    searchName,
    inspectionType,
    inspectionResultStatus,
    inspectorHashId,
    completedAtFrom,
    completedAtTo,
    isAdhoc,
    isPassed,
    rootCategory,
    narrowCategory,
    scheduledAtFrom,
    scheduledAtTo,
    hospitalWard,
    hospitalRoom,
    rentHospitalWard,
    rentHospitalRoom,
  ]);
};
