import React, {useMemo, useRef, useState} from 'react';
import {FetchInspectionsParams, useFetchInspectionsQuery} from '@modules/inspections/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {InspectionIndex} from '@modules/inspections/types';
import {isNullish} from '@front-libs/helpers';
import {PopperBtnInspectionTableProps} from './PopperBtnInspectionTable';

const PER_PAGE = 100;

export const useInspectionTable = ({onChangeInspection, type}: PopperBtnInspectionTableProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState<string | null>(null);
  const [page, setPage] = useState(1);
  const {myInfo} = useMyInfo();
  const params = useMemo(() => {
    const _p: FetchInspectionsParams = {
      page: page - 1,
      perPage: PER_PAGE,
      statuses: 'available',
      type: isNullish(type) ? undefined : type,
      name: searchText ? searchText : undefined,
    };
    return _p;
  }, [page, type, searchText]);

  const {data, totalCount} = useFetchInspectionsQuery(myInfo.hospitalHashId, params);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setOpen((prev) => !prev);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchName = event.target.value;
    if (!isNullish(searchName)) {
      setSearchText(searchName);
      setPage(1);
    }
  };

  const handleItemClick = (newInspection: InspectionIndex) => {
    setOpen(false);
    onChangeInspection(newInspection);
  };

  const handlePageChange = (value: number) => {
    setPage(value);
  };

  const totalPage = useMemo(() => Math.ceil(totalCount / PER_PAGE), [totalCount]);

  const startDisplayPosition = useMemo(() => {
    return totalCount === 0 ? 0 : Math.ceil((page - 1) * PER_PAGE + 1);
  }, [page, totalCount]);

  const endDisplayPosition = useMemo(() => {
    const endPosition = Math.ceil(page * PER_PAGE);
    return endPosition > totalCount ? totalCount : endPosition;
  }, [page, totalCount]);

  const buttonRef = useRef<HTMLButtonElement>(null);
  return {
    anchorEl,
    open,
    setOpen,
    searchText,
    page,
    data,
    totalCount,
    handleClick,
    handleInputChange,
    handleItemClick,
    handlePageChange,
    totalPage,
    startDisplayPosition,
    endDisplayPosition,
    buttonRef,
    type,
  };
};
