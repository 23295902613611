import React from 'react';
import {ListItem, InputLabel, Box, TextField, Button} from '@mui/material';

type Props = {
  index: number;
  option: string;
  removeFieldState: 'visible' | 'disabled';
  onChange: (index: number, newOption: string) => void;
  onRemove: (index: number) => void;
};

export const SelectTypeOptionCreateListItem = ({index, option, removeFieldState, onChange, onRemove}: Props) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(index, e.target.value);
  };

  const handleRemove = () => {
    onRemove(index);
  };

  return (
    <ListItem sx={{flexDirection: 'column', alignItems: 'start', padding: 0}}>
      <InputLabel sx={{fontSize: '12px', color: 'inherit'}}>{`選択項目${index + 1}`}</InputLabel>
      <Box sx={{flexDirection: 'row', marginTop: '8px'}}>
        <TextField
          variant="outlined"
          size="small"
          sx={{width: '430px'}}
          inputProps={{sx: {padding: '8px 16px', height: '16px', fontSize: '14px', color: '#172B4D'}}}
          tabIndex={index + 1}
          value={option}
          onChange={handleChange}
        />
        <Button
          variant="outlined"
          sx={{
            marginLeft: '24px',
            padding: '8px',
            height: '32px',
            borderColor: '#C7243A',
            fontSize: '14px',
            color: '#C7243A',
            '&:hover': {
              borderColor: '#C7243A',
            },
          }}
          tabIndex={-1}
          disabled={removeFieldState === 'disabled'}
          onClick={handleRemove}>
          項目を削除
        </Button>
      </Box>
    </ListItem>
  );
};
