import React from 'react';
import {Box, Typography} from '@mui/material';
import {Pagination} from '@mui/material';

type PaginationAndPerPageProps = {
  /* 全アイテムの総数 */
  totalCount: number;
  /* 現在のページ番号 */
  currentPage: number;
  /* 1ページあたりの表示件数 */
  pageSize: number;
  /**
   * ページ番号が変更された時に呼び出されるコールバック関数
   * @param newPage 新しいページ番号
   */
  onPageChange: (newPage: number) => void;
  maxDisplayPosition: number;
};

export const DialogPagination = ({
  totalCount,
  currentPage,
  pageSize,
  onPageChange,
  maxDisplayPosition,
}: PaginationAndPerPageProps) => {
  const startDisplayPosition = React.useMemo(() => {
    return totalCount === 0 ? 0 : (currentPage - 1) * pageSize + 1;
  }, [currentPage, pageSize, totalCount]);

  const endDisplayPosition = React.useMemo(() => {
    return Math.min(currentPage * pageSize, totalCount);
  }, [currentPage, pageSize, totalCount]);

  const totalPages = React.useMemo(() => {
    return Math.ceil(totalCount / pageSize) || 1;
  }, [pageSize, totalCount]);

  const handlePageChange = (_e: React.ChangeEvent<unknown>, page: number) => {
    onPageChange(page);
  };

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
      <Box sx={{margin: '16px', width: '100%'}}>
        {totalCount}件のうち{startDisplayPosition}件目-{endDisplayPosition}件目までを表示しています
      </Box>
      <Box sx={{display: 'flex', alignItems: 'center', gap: '16px'}}>
        <Pagination
          page={currentPage}
          count={totalPages}
          shape="rounded"
          onChange={handlePageChange}
          aria-label="ページ切り替え"
        />
        <Typography component={'div'} sx={{fontWeight: 'bold', color: '#0052CC'}}>
          {`${maxDisplayPosition}件`}
        </Typography>
      </Box>
    </Box>
  );
};
