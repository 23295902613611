import {deleteInspectionResultsByHospitalHashId} from './repository';
import {
  getOfflineInspectionResultForInUse,
  getOfflineInspectionResultForPeriodic,
  getOfflineInspectionResultForPostUse,
  getOfflineInspectionResultForPreUse,
} from './fetchAndUpsert';

// 初期データ取得
export const initInspectionResults = async (hospitalHashId: string) => {
  Promise.all([
    getOfflineInspectionResultForPreUse(hospitalHashId),
    getOfflineInspectionResultForInUse(hospitalHashId),
    getOfflineInspectionResultForPostUse(hospitalHashId),
    getOfflineInspectionResultForPeriodic(hospitalHashId),
  ]);
};

// 差分データ取得
export const updateInspectionResults = async (hospitalHashId: string, updatedAt: string) => {
  Promise.all([
    getOfflineInspectionResultForPreUse(hospitalHashId, updatedAt),
    getOfflineInspectionResultForInUse(hospitalHashId, updatedAt),
    getOfflineInspectionResultForPostUse(hospitalHashId, updatedAt),
    getOfflineInspectionResultForPeriodic(hospitalHashId, updatedAt),
  ]);
};

// 病院IDに紐づくデータを削除
export const deleteInspectionResults = async (hospitalHashId: string) => {
  await deleteInspectionResultsByHospitalHashId(hospitalHashId);
};
