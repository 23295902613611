import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {TableViewLayout} from '@components/layouts/TableViewLayout';
import {TwoColumns} from '@components/layouts/columns/TwoColumns';
import {CategoryFormatter} from '@modules/categories/helpers';
import {TableLayoutResult, useTableLayout} from '@modules/table_layout/hooks/useTableLayout';
import {PopperMenuButton} from '@molecules/Buttons/PopperMenuButton';
import {PopperSelectBoxButton} from '@molecules/Buttons/PopperSelectBoxButton';
import {Search, Tune} from '@mui/icons-material';
import {Button, ButtonProps, Grid, TextField, Theme, Typography, styled} from '@mui/material';
import {useWholeProductsListContentHeader} from '../../modules/hospital_whole_products_bulk_update/hooks';
import {useAtom, useSetAtom} from 'jotai';
import {openDrawerAtom, wholeProductCurrentLayout} from '@modules/hospital_whole_products_bulk_update/jotai';

/**
 * 機種一覧ページ機種テーブルヘッダー
 */
export const WholeProductsListContentHeader = () => {
  const requiredColumns = {displayName: true, name: true, maker: true};
  const [tableLayout, setTableLayout] = useTableLayout('wholeProductList', requiredColumns);
  const setCurrentLayout = useSetAtom(wholeProductCurrentLayout);
  useEffect(() => {
    setCurrentLayout(tableLayout.currentLayout);
  }, [tableLayout]);
  const [openDrawer, setOpenDrawer] = useAtom(openDrawerAtom);

  /** テーブルレイアウト切り替え */
  const onChangeTableLayout = useCallback(
    (newTableLayout: TableLayoutResult) => {
      setTableLayout(newTableLayout);
    },
    [setTableLayout]
  );

  const {
    searchName,
    searchRootCategories,
    searchSubCategories,
    rootCategoryQuery,
    descendantCategoryOptions,
    handleSearchName,
    handleChangeRootCategories,
    handleChangeSubCategories,
    actionMenuItems,
    handleMenuClick,
    isDetailFilterActive,
  } = useWholeProductsListContentHeader({
    tableLayout,
    onChangeTableLayout,
  });

  return (
    <TableViewLayout.Header>
      <TwoColumns alignItems="center" style={{marginTop: 8}}>
        <TwoColumns.Left>
          <SearchGrid item>
            <SearchTextField
              label={'機種名・型式で検索'}
              variant={'outlined'}
              fullWidth
              size={'small'}
              InputProps={{
                endAdornment: <Search />,
              }}
              InputLabelProps={{
                style: {
                  fontSize: 14,
                },
              }}
              defaultValue={searchName}
              onChange={handleSearchName}
            />
          </SearchGrid>
          <Grid item>
            <Grid container>
              <Grid item>
                <PopperSelectBoxButton
                  buttonLabel={'大分類'}
                  options={CategoryFormatter.getOptions(rootCategoryQuery.data)}
                  isMulti={true}
                  onChange={handleChangeRootCategories}
                  searchable={true}
                  initialOption={searchRootCategories}
                />
              </Grid>
              <Grid item>
                <PopperSelectBoxButton
                  buttonLabel={'小分類'}
                  options={descendantCategoryOptions}
                  isMulti={true}
                  onChange={handleChangeSubCategories}
                  searchable={true}
                  initialOption={searchSubCategories}
                />
              </Grid>
              <Grid item>
                <TuneButton
                  color="inherit"
                  onClick={() => setOpenDrawer(true)}
                  isActive={isDetailFilterActive || openDrawer}>
                  <ActionMenuTune />
                  <ActionMenuSpan>詳細で絞り込む</ActionMenuSpan>
                </TuneButton>
              </Grid>
            </Grid>
          </Grid>
        </TwoColumns.Left>
        <TwoColumns.Right>
          <PopperMenuButton
            buttonProps={{
              variant: 'contained',
              disableElevation: true,
              style: {
                width: '100%',
                background: 'rgba(9, 30, 66, 0.04)',
                border: '1px solid #C6CBD4',
              },
            }}
            menuItemList={actionMenuItems}
            onMenuClick={handleMenuClick}>
            アクション
          </PopperMenuButton>
        </TwoColumns.Right>
      </TwoColumns>
    </TableViewLayout.Header>
  );
};

const SearchGrid = styled(Grid)({
  width: '400px',
  marginRight: '24px',
});

const SearchTextField = styled(TextField)(({theme}: {theme: Theme}) => ({
  backgroundColor: theme.palette.common.white,
  fontSize: '14px',
}));

type BarButtonBoxProps = {isActive: boolean} & ButtonProps;

const TuneButton = styled(({isActive, ...otherProps}: BarButtonBoxProps) => <Button {...otherProps} />)(
  ({isActive}: {isActive: boolean}) => ({
    marginLeft: '8px',
    backgroundColor: isActive ? '#D6E8FC' : undefined,
    '&&:hover': {
      backgroundColor: isActive ? '#D6E8FC' : undefined,
    },
  })
);

const ActionMenuTune = styled(Tune)(({theme}: {theme: Theme}) => ({
  fontWeight: 700,
  color: theme.palette.primary.dark,
  marginLeft: '8px',
}));

const ActionMenuSpan = styled('span')({
  fontWeight: 700,
  color: '#003FB5',
  marginLeft: '8px',
});
