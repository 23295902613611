import {useQuery} from 'react-query';
import {FetchHospitalProductInspectionPlansParam, getHospitalProductInspectionPlans} from './api';
import {getHospitalProductInspectionPlanCountsKey, getHospitalProductInspectionPlansKey} from '@constants/api';
import {useMemo} from 'react';

export const useGetHospitalProductInspectionPlansQuery = (
  hospitalHashId: string,
  params: FetchHospitalProductInspectionPlansParam
) => {
  return useQuery([getHospitalProductInspectionPlansKey, hospitalHashId, params], () =>
    getHospitalProductInspectionPlans(hospitalHashId, params)
  );
};

export const useGetHospitalProductInspectionPlansCountsQuery = (
  hospitalHashId: string,
  params: FetchHospitalProductInspectionPlansParam
) => {
  const query = useQuery([getHospitalProductInspectionPlanCountsKey, hospitalHashId, params], () =>
    Promise.all([
      getHospitalProductInspectionPlans(hospitalHashId, {
        ...params,
        status: 'planned',
      }),
      getHospitalProductInspectionPlans(hospitalHashId, {
        ...params,
        status: 'unplanned',
      }),
    ])
  );

  const statusCountsQuery = useMemo(
    () => ({
      ...query,
      planned: query.data ? query.data[0].totalCount : null,
      unplanned: query.data ? query.data[1].totalCount : null,
    }),
    [query]
  );

  return {statusCountsQuery, refetch: query.refetch};
};
