import React from 'react';

type Props = {
  text: string;
};

export const ChangeTextColumn = ({text}: Props) => {
  const maxLength = 10;

  if (text.length <= maxLength) {
    return <span>{text}</span>;
  }

  const truncatedText = `${text.slice(0, maxLength)}…`;
  return <span>{truncatedText}</span>;
};
