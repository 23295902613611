import React, {useCallback} from 'react';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {FileIndex} from '@modules/files/types';
import {FileList} from '@organisms/Files/FileList';
import {useState} from 'react';
import {useEffect} from 'react';
import {makeStyles, createStyles, Grid} from '@material-ui/core';
import {Box, Typography} from '@mui/material';
import {VerticalAlignTop} from '@mui/icons-material';
import {createTrainingScheduleTraineeLists} from '@modules/training_schedules/api';
import {useFetchTrainingScheduleTraineeLists} from '@modules/training_schedules/hooks/useFetchTrainingScheduleTraineeLists';
import {useTableLayout} from '@modules/table_layout/hooks/useTableLayout';
import {useMyRole} from '@modules/hospital_users/hooks/useMyRole';

type FilesFormProps = {
  trainingScheduleHashId: string;
};

export const TraineeFileLists: React.FC<FilesFormProps> = ({trainingScheduleHashId}) => {
  const classes = useStyles();
  const {myInfo} = useMyInfo();
  const {isReadOnly} = useMyRole();
  const [tableLayout] = useTableLayout('fileList');
  const [order, setOrder] = useState<string>();
  const {data, refetch, isLoading} = useFetchTrainingScheduleTraineeLists(
    myInfo.hospitalHashId,
    trainingScheduleHashId,
    order
  );

  const [files, setFiles] = useState(data);
  useEffect(() => {
    setFiles(data || []);
  }, [data]);

  const handleUploadFile = useCallback(
    async (newFile: FileIndex) => {
      await createTrainingScheduleTraineeLists(myInfo.hospitalHashId, trainingScheduleHashId, {
        fileHashIds: [newFile.hashId],
      });
      await refetch();
    },
    [myInfo.hospitalHashId, refetch, trainingScheduleHashId]
  );
  const handleRefetch = () => refetch();

  const handleOrderChange = useCallback(
    (columnIndex: number, orderDirection: 'asc' | 'desc') => {
      setOrder(`${orderDirection === 'desc' ? '-' : ''}${String(tableLayout.currentLayout[columnIndex].field)}`);
    },
    [tableLayout.currentLayout]
  );

  return (
    <Grid className={classes.root} container justifyContent="center">
      <FileList
        files={files}
        fileCategory={'training_schedule_trainee_list'}
        isLoading={isLoading}
        onDeleteFile={handleRefetch}
        onEditFileName={handleRefetch}
        onUploadFile={handleUploadFile}
        onOrderChange={handleOrderChange}
        searchFileName={false}
        uploadButtonTitle={
          <Box sx={{display: 'flex', alignItems: 'center', height: '28px'}}>
            <VerticalAlignTop sx={{fontSize: '20px'}} />
            <Typography sx={{fontSize: '14px'}}>ファイルをアップロード</Typography>
          </Box>
        }
        disableUpload={isReadOnly}
        disableEdit={isReadOnly}
      />
    </Grid>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
    },
  })
);
