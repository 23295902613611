import {Box, Typography, styled} from '@material-ui/core';
import React from 'react';

export const ItemInspectionPoint = ({inspectionPoint}: ItemInspectionPointProps) => {
  return (
    <RootBox>
      <InspectionPointTxt>{inspectionPoint}</InspectionPointTxt>
    </RootBox>
  );
};

type ItemInspectionPointProps = {
  inspectionPoint: string;
};

const RootBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

const InspectionPointTxt = styled(Typography)({
  fontSize: '14px',
  color: '#65676B',
  margin: '0px',
});
