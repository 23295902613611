import React, {useCallback} from 'react';
import {Grid, TextField} from '@material-ui/core';

type SingleLineTextFieldProps = {
  value: string;
  error?: React.ReactNode;
  placeholder: string;
  isEditable: boolean;
  showsError: boolean;
  onChange?: (value: string) => void;
};

export const SingleLineTextField: React.FC<SingleLineTextFieldProps> = (props) => {
  const {value, error, placeholder, isEditable, showsError, onChange} = props;

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (isEditable === true && onChange) {
        onChange(e.target.value);
      }
    },
    [onChange, isEditable]
  );

  return (
    <Grid container direction="column">
      <TextField
        value={value}
        error={showsError === true && error !== undefined}
        onChange={handleChange}
        variant="standard"
        placeholder={placeholder}
        InputProps={{
          readOnly: !isEditable,
        }}
      />
      {error}
    </Grid>
  );
};
