import React, {useCallback, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import qs from 'qs';
import {useSearchParams} from '@front-libs/core';
import {ProductTransactionHistory} from './TracsactionHistory';
import {Files} from './Files';
import {RepairIndex} from '@modules/repairs/types';
import {MEIDAI_HIT_4587_CONSUME_PARTS} from '@constants/constants';
import {RepairParts} from './RepairParts';
import {Grid, SxProps, Tab, Tabs} from '@mui/material';

const tabs = [
  {
    label: '対応履歴',
    value: 'history',
  },
  {
    label: 'ファイル',
    value: 'file',
  },
  {
    ...(MEIDAI_HIT_4587_CONSUME_PARTS && {
      label: '部品・交換品',
      value: 'part',
    }),
  },
];

const rootStyle: SxProps = {
  padding: '24px',
  paddingBottom: '82px',
};

const tabStyle: SxProps = {
  padding: '6px 12px',
  minWidth: 160,
};

type Props = {
  faultRepair: RepairIndex;
};

export const ProductDetailInfo: React.FC<Props> = ({faultRepair}) => {
  const hashId = faultRepair?.hashId;
  const navigate = useNavigate();
  const {tab = tabs[0].value} = useSearchParams();
  const changeTab = useCallback(
    (_, newTab: string) => {
      navigate({search: qs.stringify({tab: newTab}, {arrayFormat: 'brackets'})});
    },
    [navigate]
  );

  const tabContent = useMemo(() => {
    switch (tab) {
      case 'history':
        return <ProductTransactionHistory faultRepair={faultRepair} />;
      case 'file':
        return <Files faultRepairHashId={hashId ?? ''} />;
      case 'part':
        return <RepairParts />;
      default:
        return null;
    }
  }, [faultRepair, hashId, tab]);

  return (
    <Grid container sx={rootStyle}>
      <Tabs value={tab} onChange={changeTab} indicatorColor="primary" textColor="primary">
        {tabs.map((item, index) => (
          <Tab sx={tabStyle} label={item.label} value={item.value} key={index} />
        ))}
      </Tabs>
      {tabContent}
    </Grid>
  );
};
