import React, {useMemo} from 'react';
import Button, {ButtonProps} from '@material-ui/core/Button';
import {Dialog, DialogProps} from '@atoms/Dialog/Dialog';
import {DialogActions} from '@atoms/Dialog/DialogActions';
import {DialogContent} from '@atoms/Dialog/DialogContent';
import {DialogProps as DialogHandlerProps} from './DialogHandler';
import {makeStyles} from '@material-ui/styles';
import {DialogTitle} from '@material-ui/core';
import {DialogTitleProps} from '@atoms/Dialog/DialogTitle';
import {StrUtil} from '@front-libs/helpers';
import {theme} from '@atoms/theme';
import clsx from 'clsx';

const defaultProps = {
  positiveButtonLabel: '保存',
  negativeButtonLabel: 'キャンセル',
  dialogTitleProps: {},
  positiveButtonProps: {},
  negativeButtonProps: {},
  maxWidth: 'sm' as DialogProps['maxWidth'],
  warning: false,
};

export type SimpleDialogProps = {
  title?: string | React.ReactNode;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  content?: any;
  positiveButtonLabel?: string;
  negativeButtonLabel?: string;
  dialogTitleProps?: Omit<DialogTitleProps, 'children'>;
  positiveButtonProps?: ButtonProps;
  negativeButtonProps?: ButtonProps;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  dialogContentProps?: any;
  maxWidth?: DialogProps['maxWidth'];
  dialogProps?: Omit<DialogProps, 'open'>;
  warning?: boolean;
  disabled?: boolean;
  isHiddenNegativeButton?: boolean;
  boldTitle?: boolean;
} & DialogHandlerProps;

export const SimpleDialog: React.FC<SimpleDialogProps> = (props) => {
  const {
    open,
    title,
    content,
    positiveButtonLabel,
    negativeButtonLabel,
    actions,
    dialogContentProps,
    positiveButtonProps,
    negativeButtonProps,
    maxWidth,
    dialogProps,
    children,
    warning = false,
    disabled = false,
    boldTitle = false,
    isHiddenNegativeButton = false,
  } = {...defaultProps, ...props};
  const classes = useStyles();
  const shapedContent = useMemo(
    () => (children ? children : typeof content === 'string' ? StrUtil.nl2br(content) : content),
    [content, children]
  );

  return (
    <Dialog
      open={Boolean(open)}
      PaperProps={{className: classes.paper, style: {overflow: 'visible', minWidth: 400}}}
      maxWidth={maxWidth}
      {...dialogProps}>
      {title && (
        <DialogTitle>
          <span className={clsx(boldTitle && classes.boldTitle)}>{title}</span>
        </DialogTitle>
      )}
      <DialogContent {...dialogContentProps}>{shapedContent}</DialogContent>
      <DialogActions>
        <Button
          variant={'contained'}
          color="primary"
          onClick={actions.resolve}
          disabled={disabled}
          {...positiveButtonProps}
          className={clsx(!disabled && warning && classes.warningSubmitButton)}>
          {positiveButtonLabel}
        </Button>
        {isHiddenNegativeButton !== true && (
          <Button
            {...negativeButtonProps}
            onClick={actions.reject}
            color="primary"
            className={warning ? classes.warningCancelButton : ''}>
            {negativeButtonLabel}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((_) => ({
  paper: {
    '& > div:first-child:not(.MuiDialogTitle-root)': {
      paddingTop: '16px',
    },
  },
  warningSubmitButton: {
    backgroundColor: `#C7243A !important`,
    '&:hover': {
      backgroundColor: `${theme.palette.error.main} !important`,
    },
  },
  warningCancelButton: {
    color: `${theme.palette.error.main} !important`,
    backgroundColor: '#fff',
  },
  boldTitle: {
    fontWeight: 'bold',
  },
}));
