const classNameMap = {
  一般医療機器: 'one',
  管理医療機器: 'two',
  高度管理医療機器: 'three',
  '高度管理医療機器（クラス4）': 'four',
};

export const getProductClassName = (className: string) => {
  if (className in classNameMap) {
    return classNameMap[className as keyof typeof classNameMap];
  }
  return null;
};
