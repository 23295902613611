import {atom} from 'jotai';
import {
  searchRootCategoriesAtom,
  searchSubCategoriesAtom,
  searchNameAtom,
  pageAtom,
  pageSizeAtom,
  selectedMakerAtom,
} from './jotai';
import {GetHospitalWholeProductParam} from '@modules/products/api';

export const hospitalWholeProductVariables = atom<GetHospitalWholeProductParam>((get) => {
  const rootCategories = get(searchRootCategoriesAtom);
  const subCategories = get(searchSubCategoriesAtom);
  const searchName = get(searchNameAtom);
  const selectMaker = get(selectedMakerAtom);

  const _p: GetHospitalWholeProductParam = {
    page: get(pageAtom) - 1 || 0,
    perPage: get(pageSizeAtom),
    hasInspectionSetting: false,
  };
  if (searchName) {
    _p.name = searchName;
  }
  if (subCategories && subCategories.length > 0) {
    _p.categoryHashId = subCategories[0];
  } else if (rootCategories && rootCategories.length > 0) {
    _p.categoryHashId = rootCategories[0];
  }

  if (selectMaker) {
    _p.makerHashId = selectMaker;
  }
  return _p;
});
