import React, {useCallback} from 'react';

import {DialogProps} from '@molecules/Dialogs/DialogHandler';

import {Step1FormContainer} from './Step1Form';
import {Form, Formik} from 'formik';
import {yup} from '@front-libs/core';
import {InferType} from 'yup';

export type NewRegistrationDialogProps = DialogProps;

export type NewRegistrationDialogResult = {
  rootCategoryHashId: string;
  subCategoryHashId: string;
  name?: string | null;
  displayName?: string | null;
  janCode?: string | null;
  approvalNumber?: string | null;
  makerHashId?: string | null;
  newMakerName?: string | null;
  className?: string | null;
};

type FormValues = {
  rootCategoryHashId?: string;
  subCategoryHashId?: string;
  name?: string | null;
  displayName?: string | null;
  janCode?: string | null;
  approvalNumber?: string | null;
  makerHashId?: string | null;
  className?: string | null;
};

const validationSchema = yup.object({
  rootCategoryHashId: yup.string().required(),
  subCategoryHashId: yup.string().required(),
  makerHashId: yup.string().nullable(),
  name: yup.string().nullable(),
  displayName: yup.string().nullable(),
  janCode: yup.string().nullable(),
  approvalNumber: yup.string().nullable(),
  className: yup.string().nullable(),
});

type ProductRegistrationFormType = InferType<typeof validationSchema>;

export const NewRegistrationDialog: React.FC<NewRegistrationDialogProps> = (props) => {
  const {open} = props;

  const handleSubmit = useCallback(
    async (res: FormValues) => {
      await props.actions.resolve(res as NewRegistrationDialogResult);
    },
    [props.actions]
  );

  const handleClose = useCallback(
    (e: React.MouseEvent) => {
      props.actions.reject();
    },
    [props.actions]
  );

  return (
    <Formik<ProductRegistrationFormType>
      initialValues={{
        rootCategoryHashId: '',
        subCategoryHashId: '',
        name: null,
        displayName: null,
        makerHashId: null,
        janCode: null,
        approvalNumber: null,
        className: null,
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      isInitialValid={false}>
      <Form>
        <Step1FormContainer open={open} onClose={handleClose} />
      </Form>
    </Formik>
  );
};
