import {TableBody, TableRow} from '@mui/material';
import React from 'react';
import {ADD_MODE, EDIT_MODE, VIEW_MODE, WAIT_MODE} from './type';
import {useWholeProductEditPlan} from './hooks';
import {AddModeRow, EditModeRow, ViewModeRow, WaitModeRow} from './ui/EditWholeProductRow';
import {EditWholeProductPlan, EditWholeProductPlanAction} from './utils/reducer';

type EditWholeProductListBodyProps = {
  inspectionSettings: EditWholeProductPlan[];
  dispatch: React.Dispatch<EditWholeProductPlanAction>;
};

export const EditWholeProductListBody = ({inspectionSettings, dispatch}: EditWholeProductListBodyProps) => {
  return (
    <TableBody sx={{border: 'none', boxShadow: 'none', borderBottom: 'none'}}>
      {inspectionSettings.map((inspectionSetting) => {
        return (
          <EditWholeProductRow
            key={inspectionSetting.uuid}
            inspectionSetting={inspectionSetting}
            dispatch={dispatch}
            inspectionSettings={inspectionSettings}
          />
        );
      })}
    </TableBody>
  );
};

type EditWholeProductRowProps = {
  inspectionSetting: EditWholeProductPlan;
  inspectionSettings: EditWholeProductPlan[];
  dispatch: React.Dispatch<EditWholeProductPlanAction>;
};

const EditWholeProductRow = ({inspectionSetting, dispatch, inspectionSettings}: EditWholeProductRowProps) => {
  const {
    handleEditMode,
    handleDelete,
    handleSave,
    handleAddSave,
    handleCancel,
    handleInspectionChange,
    handleInspectionSettingNameChange,
    handleInspectionPeriodChange,
    handleInspectionTypeChange,
    canDelete,
    isValid,
    displayInspectionTypeOptions,
  } = useWholeProductEditPlan({
    dispatch,
    inspectionSetting,
    inspectionSettings,
  });

  return (
    <TableRow>
      {inspectionSetting.mode === VIEW_MODE && (
        <ViewModeRow
          onEdit={() => {
            handleEditMode();
          }}
          onDelete={() => {
            handleDelete();
          }}
          inspectionSetting={inspectionSetting}
          canDelete={canDelete}
        />
      )}
      {inspectionSetting.mode === EDIT_MODE && (
        <EditModeRow
          onSave={() => handleSave()}
          onCancel={() => {
            handleCancel();
          }}
          inspectionSetting={inspectionSetting}
          onChangeInspection={(inspectionHashId: string, inspectionName: string) => {
            handleInspectionChange(inspectionHashId, inspectionName);
          }}
          onChangeInspectionSettingName={(inspectionSettingName: string) => {
            handleInspectionSettingNameChange(inspectionSettingName);
          }}
          canSave={isValid}
        />
      )}
      {inspectionSetting.mode === ADD_MODE && (
        <AddModeRow
          onSave={() => handleAddSave()}
          onCancel={() => {
            handleCancel();
          }}
          inspectionSetting={inspectionSetting}
          onChangeInspection={(inspectionHashId: string, inspectionName: string) => {
            handleInspectionChange(inspectionHashId, inspectionName);
          }}
          onChangeInspectionSettingName={(inspectionSettingName: string) => {
            handleInspectionSettingNameChange(inspectionSettingName);
          }}
          onChangeInspectionPeriod={(inspectionPeriod: number) => {
            handleInspectionPeriodChange(inspectionPeriod);
          }}
          onChangeInspectionType={(inspectionType: string) => {
            handleInspectionTypeChange(inspectionType);
          }}
          canSave={isValid}
          displayInspectionTypeOptions={displayInspectionTypeOptions}
        />
      )}
      {inspectionSetting.mode === WAIT_MODE && (
        <WaitModeRow inspectionSetting={inspectionSetting} canDelete={canDelete} />
      )}
    </TableRow>
  );
};
