import React, {useEffect} from 'react';
import {openSnackBar} from '@molecules/SnackBar';
import {RequiredPropertiesChangedMessages} from './constants';
import {useProductRequiredPropertiesSettingForm} from './hooks';
import {ProductRegistration} from './ProductRegistration';

export const SettingsHospitalProductRegistration = () => {
  const {
    hospitalProductRequiredPropertyState,
    isInitLoading,
    initProductRequiredPropertiesSettingForm,
    addProductRequiredProperty,
    deleteProductRequiredProperty,
  } = useProductRequiredPropertiesSettingForm();

  useEffect(() => {
    initProductRequiredPropertiesSettingForm();
  }, []);

  const handleRequiredPropertyAdd = async (property: string) => {
    try {
      await addProductRequiredProperty(property);
    } catch (error) {
      openSnackBar(RequiredPropertiesChangedMessages.failed, 'left', 'bottom', 'error');
      console.error('Failed to add hospital product required properties', error);
      return;
    }

    openSnackBar(RequiredPropertiesChangedMessages.succeeded);
  };

  const handleRequiredPropertyDelete = async (property: string) => {
    try {
      await deleteProductRequiredProperty(property);
    } catch (error) {
      openSnackBar(RequiredPropertiesChangedMessages.failed, 'left', 'bottom', 'error');
      console.error('Failed to delete hospital product required properties', error);
      return;
    }

    openSnackBar(RequiredPropertiesChangedMessages.succeeded);
  };

  return (
    <ProductRegistration
      isLoading={isInitLoading}
      propertyItems={hospitalProductRequiredPropertyState.hospitalProductRequiredPropertyItems}
      requiredProperties={hospitalProductRequiredPropertyState.hospitalProductRequiredProperties}
      onRequiredPropertyAdd={handleRequiredPropertyAdd}
      onRequiredPropertyDelete={handleRequiredPropertyDelete}
    />
  );
};
