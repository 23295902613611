import {useFetchHospitalDepartments} from './api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';

export const useHospitalDepartmentsOptions = (selectableHospitalDepartmentHashID?: string, showDeleted = false) => {
  const {myInfo} = useMyInfo();
  const {data, isLoading} = useFetchHospitalDepartments(myInfo.hospitalHashId, {
    showDeleted: showDeleted,
    page: 0,
    perPage: 100,
  });

  // showDeletedがfalseの場合には削除された部署を除外する
  const currentSelectableHospitalDepartments = !showDeleted ? data : data?.filter((item) => !item.deletedBy);

  // 修理情報の詳細表示時に、削除済みでも選択された部署を表示するための処理
  if (selectableHospitalDepartmentHashID) {
    const isSelectable = currentSelectableHospitalDepartments.some(
      (data) => data.hashId === selectableHospitalDepartmentHashID
    );
    const selectableHospitalDepartment = data.find((data) => data.hashId === selectableHospitalDepartmentHashID);

    if (!isSelectable && selectableHospitalDepartment) {
      currentSelectableHospitalDepartments.push(selectableHospitalDepartment);
    }
  }

  const hospitalDepartmentOptions = currentSelectableHospitalDepartments
    ? currentSelectableHospitalDepartments.map((department) => ({
        label: department.name,
        value: department.hashId,
      }))
    : [];

  return {hospitalDepartmentOptions, isLoading};
};
