import {Typography, IconButton} from '@mui/material';
import React from 'react';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';

type Props = {
  onUploadFile: () => void;
};

/**
 * Pmdaファイルアップロードアイコンボタン
 * packages/front-app-pc/src/components/molecules/Buttons/PmdaFileUploadIconButton/PmdaFileUploadIconButton.tsxのMui V5対応
 * @returns
 */
export const PmdaFileUploadIconButton = ({onUploadFile}: Props) => (
  <IconButton sx={{display: 'flex', alignItems: 'center', color: '#0052CC'}} onClick={onUploadFile}>
    <VerticalAlignTopIcon />
    <Typography sx={{pl: '8px', fontSize: '14px'}}>添付文書ファイルをアップロード</Typography>
  </IconButton>
);
