import {
  HospitalProductRequiredPropertyState,
  HospitalProductRequiredPropertyAction,
  HospitalProductRequiredPropertyActionType,
} from './types';

export const initialState: HospitalProductRequiredPropertyState = {
  hospitalProductRequiredProperties: [],
  hospitalProductRequiredPropertyItems: [],
};

export const reducer = (
  state: HospitalProductRequiredPropertyState,
  action: HospitalProductRequiredPropertyAction
): HospitalProductRequiredPropertyState => {
  switch (action.type) {
    case HospitalProductRequiredPropertyActionType.HOSPITAL_PRODUCT_REQUIRED_PROPERTY_SET_LIST: {
      return {
        ...state,
        hospitalProductRequiredProperties: action.payload.hospitalProductRequiredProperties,
      };
    }
    case HospitalProductRequiredPropertyActionType.HOSPITAL_PRODUCT_REQUIRED_PROPERTY_SET_LIST_ITEMS: {
      return {
        ...state,
        hospitalProductRequiredPropertyItems: action.payload.hospitalProductRequiredPropertyItems,
      };
    }
    default:
      return state;
  }
};
