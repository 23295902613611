import {NotificationCategory} from './types';

export const notificationCategoryOptions: {label: string; value: NotificationCategory}[] = [
  {
    value: 'rent_notification',
    label: '貸出',
  },
  {
    value: 'return_notification',
    label: '返却',
  },
  {
    value: 'inspection_reminder',
    label: 'リマインダー',
  },
  {
    value: 'fault_repair_pic_notification',
    label: '修理担当者の割り当て',
  },
  {
    value: 'export_hospital_product_notification',
    label: 'バックグラウンド処理',
  },
  {
    value: 'export_inspection_result_notification',
    label: 'バックグラウンド処理',
  },
  {
    value: 'export_training_plan_notification',
    label: 'バックグラウンド処理',
  },
  {
    value: 'export_fault_repairs_notification',
    label: 'バックグラウンド処理',
  },
  {
    value: 'export_rentals_notification',
    label: 'バックグラウンド処理',
  },
  {
    value: 'export_fault_repairs_notification',
    label: 'バックグラウンド処理',
  },
  {
    value: 'export_inspection_results_csv_notification',
    label: 'バックグラウンド処理',
  },
  {
    value: 'export_maker_inspection_results_notification',
    label: 'バックグラウンド処理',
  },
  {
    value: 'import_hospital_product_notification',
    label: 'バックグラウンド処理',
  },
  {
    value: 'generate_inspection_result_pdfs_notification',
    label: 'バックグラウンド処理',
  },
  {
    value: 'pmda_upload_notification',
    label: '添付文書の更新',
  },
  {
    value: 'pmda_recall_notification',
    label: '回収情報の更新',
  },
  {
    value: 'fault_reception_notification',
    label: '不具合受付',
  },
  // {
  //   value: 'rental_amount_notification',
  //   label: '機器数の不足',
  // },
];

export const getNotificationCategoryByValue = (value: NotificationCategory): string => {
  const option = notificationCategoryOptions.find((item) => item.value === value);
  return option ? option.label : '';
};
