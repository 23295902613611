import {openSnackBar} from '@components/molecules/SnackBar';
import {updateHospitalUser, useFetchMyInfo} from '@modules/hospital_users/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  SxProps,
  Tab,
  Tabs,
  Theme,
} from '@mui/material';
import {useAtom} from 'jotai';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {radioAtom, tabAtom} from '../states';
import {CUSTOMIZE, DEFAULT_ORDER, RadioKey, TabKey, tabs} from './consts';

export const HeaderBottom = () => {
  const {myInfo} = useMyInfo();
  const {refetch} = useFetchMyInfo(myInfo.hashId);
  const [radioButton, setRadioButton] = useAtom(radioAtom);
  const [tab, setTab] = useAtom(tabAtom);
  const navigate = useNavigate();

  const handleChangeTab = (key: string) => {
    setTab(key as TabKey);
  };

  const handleRadioChange = async (key: string) => {
    try {
      await updateHospitalUser(myInfo.hospitalHashId, myInfo.hashId, {rentalLayoutPreference: key as RadioKey});
      setRadioButton(key as RadioKey);
      const msg = key === DEFAULT_ORDER ? '昇順' : 'カスタマイズ';
      openSnackBar(`並び順を${msg}に変更しました。`, 'left', 'bottom');
      refetch();
      navigate({search: ''}, {replace: true});
    } catch (_e) {
      return;
    }
  };

  return (
    <Box sx={rootStyle}>
      <Box sx={formControlContainerStyle}>
        <FormControl component="fieldset">
          <FormLabel component="legend" />
          <RadioGroup
            row
            aria-label="tab"
            name="tab"
            value={radioButton}
            onChange={(_e, v) => {
              handleRadioChange(v);
            }}
            sx={radioGroupStyle}>
            <FormControlLabel value={DEFAULT_ORDER} control={<Radio color="primary" />} label="昇順" />
            <FormControlLabel value={CUSTOMIZE} control={<Radio color="primary" />} label="カスタマイズ" />
          </RadioGroup>
        </FormControl>
      </Box>
      <Box sx={tabsContainerStyle}>
        <Tabs
          value={tab}
          onChange={(_e, v) => {
            handleChangeTab(v);
          }}
          indicatorColor="primary"
          textColor="primary">
          {tabs.map((item) => (
            <Tab key={item.value} label={item.label} value={item.value} />
          ))}
        </Tabs>
      </Box>
    </Box>
  );
};

const rootStyle: SxProps<Theme> = {
  padding: '24px 48px 0',
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
};

const formControlContainerStyle: SxProps<Theme> = {
  width: '100%',
};

const radioGroupStyle: SxProps<Theme> = {
  gap: '40px',
};

const tabsContainerStyle: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'flex-start',
  '& button': {
    minWidth: '120px',
    fontSize: '16px',
  },
  '& .Mui-selected': {
    fontWeight: 600,
  },
};
