import {RecommendState} from './type';

//　表示で利用するモード
// 検索開始前状態 TODO: 名称を変更した方が良いかも
export const SEARCH_MODE = 'search';
// 検索実施中
export const LOADING_MODE = 'loading';
// 検索した結果、該当なし
export const NOT_FOUND_MODE = 'not_found';
// 検索した結果、該当あり
export const COMPLETE_MODE = 'complete';
// 検索した結果、エラー発生
export const ERROR_MODE = 'error';

// reducerのACTION TYPE
export const SEARCH_START_ACTION = 'SEARCH_START_ACTION';
export const SEARCH_COMPLETE_ACTION = 'SEARCH_COMPLETE_ACTION';
export const NOT_FOUND_ACTION = 'NOT_FOUND_ACTION';
export const ERROR_ACTION = 'ERROR_ACTION';

// reducerの初期値
export const recommendInitialState: RecommendState = {
  mode: SEARCH_MODE,
  isSearching: false,
  searchResult: null,
  isBlocked: false,
};

// WholeProductUnregisteredSearchState

export const searchRequestInitialState = {
  name: null,
  displayName: null,
  makerName: null,
  janCode: null,
  approvalNumber: null,
};

// クエリパラメータで取得対象にするキー群
export const queryParamTargetKeys = ['name', 'displayName', 'makerName', 'janCode', 'approvalNumber']; // 取得したいキーのリスト
