import {Footer} from '@Apps/BaseSharedMenu/Footer';
import {Header} from '@Apps/BaseSharedMenu/Header';
import {RentalErrorDialog} from '@Apps/BaseSharedMenu/RentalErrorDialog';
import {InnerLoading} from '@components/molecules/Loading';
import {contentFixedStyle} from '@components/templates/RentalTemplate';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {FetchRentalsParams, getRentals} from '@modules/rentals/api';
import {Box, SxProps, Theme, styled} from '@mui/material';
import {useAtom} from 'jotai';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Scanner} from '../../../Scanner';
import {scannedProductAtom} from '../states';
import {ScannedProductTable} from './ScannedProductTable';

/**
 * 返却ページでのカメラでスキャン後の機器選択画面
 * @todo 貸出にほぼ同じUIのページがあるので共通化したい
 */
export const ScanReturnDeviceBarcode = () => {
  const scannerRef = useRef(null);
  const {myInfo} = useMyInfo();
  const navigate = useNavigate();
  const [scanning, setScanning] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [returnHospitalProducts, setReturnHospitalProducts] = useAtom(scannedProductAtom);

  const isDisabled = useMemo(() => !(returnHospitalProducts.length > 0), [returnHospitalProducts.length]);
  const constraints = useMemo(() => ({width: window.innerWidth * 0.6, height: window.innerHeight * 0.5}), []);

  const updateRentHospitalProductsByManagementId = useCallback(
    async (managementId: string) => {
      // managementIdをもとに機器情報を検索する
      const params: FetchRentalsParams = {
        status: 'renting',
        managementId: managementId,
      };
      const {data, totalCount} = await getRentals(myInfo.hospitalHashId, params);
      if (totalCount > 0) {
        setReturnHospitalProducts((prevState) => [
          ...prevState,
          ...data.filter((item) => !prevState.some((d) => d.hashId === item.hashId)),
        ]);
      } else {
        setOpenDialog(true);
      }
    },
    [myInfo.hospitalHashId, setReturnHospitalProducts]
  );

  const onDetected = useCallback(
    (managementId: string) => {
      updateRentHospitalProductsByManagementId(managementId);
    },
    [updateRentHospitalProductsByManagementId]
  );

  const handleClickCancel = useCallback(
    (rowIndex: number) => {
      setReturnHospitalProducts(returnHospitalProducts.filter((_item, idx) => idx !== rowIndex));
    },
    [returnHospitalProducts, setReturnHospitalProducts]
  );

  const handleClickNextButton = useCallback(() => {
    navigate('/shared/return/product/camera/checkout');
  }, [navigate]);

  useEffect(() => {
    setScanning(!scanning);
  }, []);

  return (
    <>
      <Header title={'返却'} />
      <Box sx={contentFixedStyle}>
        <Box ref={scannerRef} sx={videoContainerStyle}>
          <StyledCanvas />
          {scanning ? (
            <Scanner scannerRef={scannerRef} onDetected={onDetected} constraints={constraints} />
          ) : (
            <InnerLoading />
          )}
        </Box>
        <Box sx={resultStyle}>
          <ScannedProductTable returnHospitalProducts={returnHospitalProducts} onClickCancel={handleClickCancel} />
        </Box>
      </Box>
      <Footer
        text={'返却機器のバーコードを\nカメラで読み取って下さい'}
        nextButtonLabel={'確認画面へ'}
        onClickNextButton={handleClickNextButton}
        isDisabled={isDisabled}
      />
      <RentalErrorDialog open={openDialog} onClickButton={() => setOpenDialog(false)} status={null} type="return" />
    </>
  );
};

const videoContainerStyle: SxProps<Theme> = {
  position: 'relative',
  width: '70%',
  ml: 4,
};

const resultStyle: SxProps<Theme> = (theme) => ({
  width: '30%',
  maxHeight: '100%',
  overflow: 'auto',
  ml: 4,
  mr: 4,
  [theme.breakpoints.up('tabletH')]: {
    paddingLeft: 7,
    paddingRight: 7,
  },
  [theme.breakpoints.up('desktop')]: {
    paddingLeft: 10,
    paddingRight: 10,
  },
});

const StyledCanvas = styled('canvas')({
  top: '0px',
  left: '0px',
  height: '100%',
  width: '100%',
  position: 'absolute',
});
