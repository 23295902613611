import {useCallback, useMemo} from 'react';
import {atom, Atom, useAtomValue} from 'jotai';
import {selectAtom} from 'jotai/utils';
import {ViewInspectionResultStatus, ViewInspectionType} from '../../types';
import {ViewInspectionTypes} from '../../consts';
import {useReducerAtom} from 'jotai/utils';
import {useDebounceCallback} from '@front-libs/core';
import {isNullish} from '@front-libs/helpers';
import {isNull} from 'lodash';
import {DefaultResultType, FilterOption} from '@molecules/Drawers/FilterDrawer/types';
import {getResultTypesFromDefaultTypes} from '@molecules/Drawers/FilterDrawer';

// constants
const LOCAL_STORAGE_KEY_INSPECTION_TYPE_STATUS = 'hitotsu/inspection_type_status';
const LOCAL_STORAGE_KEY_INSPECTION_LIST_SIZE = 'hitotsu/inspection_page_size_status';

// State
export type InspectionResultListState = {
  initialized: boolean;
  orderKey: string | null;
  page: number;
  perPage: number;
  searchName: string;
  querySearchName: string;
  inspectionType: ViewInspectionType;
  inspectionResultStatus: ViewInspectionResultStatus;
  inspector: string | null;
  rootCategory: string | null;
  narrowCategory: string | null;
  // 詳細で絞り込む フィルター
  completedAtFrom: Date | null;
  completedAtTo: Date | null;
  scheduledTimeFrom: Date | null;
  scheduledTimeTo: Date | null;
  skippedTimeFrom: Date | null;
  skippedTimeTo: Date | null;
  isAdhoc: boolean | null;
  isPassed: boolean | null;
  hospitalWard: string | null;
  hospitalRoom: string | null;
  rentHospitalWard: string | null;
  rentHospitalRoom: string | null;
  inspectionName: string | null;
  inspectionSettingName: string | null;
};

export const initialState: InspectionResultListState = {
  initialized: false,
  orderKey: null,
  page: 1,
  perPage: localStorage.getItem(LOCAL_STORAGE_KEY_INSPECTION_LIST_SIZE)
    ? Number(localStorage.getItem(LOCAL_STORAGE_KEY_INSPECTION_LIST_SIZE))
    : 20,
  searchName: '',
  querySearchName: '',
  inspectionType: 'pre_use',
  inspectionResultStatus: 'unplanned',
  inspector: null,
  completedAtFrom: null,
  completedAtTo: null,
  scheduledTimeFrom: null,
  scheduledTimeTo: null,
  skippedTimeFrom: null,
  skippedTimeTo: null,
  isAdhoc: null,
  isPassed: null,
  rootCategory: null,
  narrowCategory: null,
  hospitalWard: null,
  hospitalRoom: null,
  rentHospitalWard: null,
  rentHospitalRoom: null,
  inspectionName: null,
  inspectionSettingName: null,
};

// Actions
export const createResetAction = (state: InspectionResultListState) => ({
  type: 'RESET' as const,
  state,
});

export const createSetInitializedAction = (initialized: boolean) => ({
  type: 'SET_INITIALIZED' as const,
  initialized,
});

export const createChangeOrderKeyAction = (orderKey: string | null) => ({
  type: 'CHANGE_ORDER_KEY' as const,
  orderKey,
});

export const createChangePageAction = (page: number) => ({
  type: 'CHANGE_PAGE' as const,
  page,
});

export const createChangePerPageAction = (perPage: number) => ({
  type: 'CHANGE_PER_PAGE' as const,
  perPage,
});

export const createChangeSearchNameAction = (searchName: string) => ({
  type: 'CHANGE_SEARCH_NAME' as const,
  searchName,
});

export const createChangeQuerySearchNameAction = (searchName: string) => ({
  type: 'CHANGE_QUERY_SEARCH_NAME' as const,
  searchName,
});

export const createChangeInspectionTypeAction = (inspectionType: ViewInspectionType) => ({
  type: 'CHANGE_INSPECTION_TYPE' as const,
  inspectionType,
});

export const createChangeInspectionResultStatus = (resultStatus: ViewInspectionResultStatus) => ({
  type: 'CHANGE_INSPECTION_RESULT_STATUS' as const,
  resultStatus,
});

export const createChangeInspector = (inspector: string | null) => ({
  type: 'CHANGE_INSPECTOR' as const,
  inspector,
});

export const createChangeCompletedAtRange = (from: Date | null, to: Date | null) => ({
  type: 'CHANGE_COMPLETED_AT_RANGE' as const,
  from,
  to,
});

export const createChangeScheduledDateRange = (from: Date | null, to: Date | null) => ({
  type: 'CHANGE_SCHEDULED_DATE_RANGE' as const,
  from,
  to,
});

export const createChangeSkippedDateRange = (from: Date | null, to: Date | null) => ({
  type: 'CHANGE_SKIPPED_DATE_RANGE' as const,
  from,
  to,
});

export const createChangeIsAdhoc = (isAdhoc: boolean | null) => ({
  type: 'CHANGE_IS_ADHOC' as const,
  isAdhoc,
});

export const createChangeIsPassed = (isPassed: boolean | null) => ({
  type: 'CHANGE_IS_PASSED' as const,
  isPassed,
});

export const createChangeRootCategory = (rootCategory: string | null) => ({
  type: 'CHANGE_ROOT_CATEGORY' as const,
  rootCategory,
});

export const createChangeNarrowCategory = (narrowCategory: string | null) => ({
  type: 'CHANGE_SUB_CATEGORY' as const,
  narrowCategory,
});

export const createChangeHospitalWard = (hospitalWard: string | null) => ({
  type: 'CHANGE_HOSPITAL_WARD' as const,
  hospitalWard,
});

export const createChangeHospitalRoom = (hospitalRoom: string | null) => ({
  type: 'CHANGE_HOSPITAL_ROOM' as const,
  hospitalRoom,
});

export const createChangeRentHospitalWard = (hospitalWard: string | null) => ({
  type: 'CHANGE_RENT_HOSPITAL_WARD' as const,
  hospitalWard,
});

export const createChangeRentHospitalRoom = (hospitalRoom: string | null) => ({
  type: 'CHANGE_RENT_HOSPITAL_ROOM' as const,
  hospitalRoom,
});

export const createChangeInspectionName = (inspectionName: string | null) => ({
  type: 'CHANGE_INSPECTION_NAME' as const,
  inspectionName,
});

export const createChangeInspectionSettingName = (inspectionSettingName: string | null) => ({
  type: 'CHANGE_INSPECTION_SETTING_NAME' as const,
  inspectionSettingName,
});

export type Actions =
  | ReturnType<typeof createResetAction>
  | ReturnType<typeof createSetInitializedAction>
  | ReturnType<typeof createChangeOrderKeyAction>
  | ReturnType<typeof createChangePageAction>
  | ReturnType<typeof createChangePerPageAction>
  | ReturnType<typeof createChangeSearchNameAction>
  | ReturnType<typeof createChangeQuerySearchNameAction>
  | ReturnType<typeof createChangeInspectionTypeAction>
  | ReturnType<typeof createChangeInspectionResultStatus>
  | ReturnType<typeof createChangeInspector>
  | ReturnType<typeof createChangeCompletedAtRange>
  | ReturnType<typeof createChangeScheduledDateRange>
  | ReturnType<typeof createChangeSkippedDateRange>
  | ReturnType<typeof createChangeIsAdhoc>
  | ReturnType<typeof createChangeIsPassed>
  | ReturnType<typeof createChangeRootCategory>
  | ReturnType<typeof createChangeNarrowCategory>
  | ReturnType<typeof createChangeHospitalWard>
  | ReturnType<typeof createChangeHospitalRoom>
  | ReturnType<typeof createChangeRentHospitalWard>
  | ReturnType<typeof createChangeRentHospitalRoom>
  | ReturnType<typeof createChangeInspectionName>
  | ReturnType<typeof createChangeInspectionSettingName>;

// Reducer
const getInspectionTypeStatus = (): InspectionResultListState => {
  const inspectionTypeStatus: ViewInspectionType =
    (localStorage.getItem(LOCAL_STORAGE_KEY_INSPECTION_TYPE_STATUS) as ViewInspectionType) ?? 'pre_use';
  return {...initialState, inspectionType: inspectionTypeStatus};
};

const INITIAL_PAGE = 1;

const inspectionResultListStateReducer = (
  prev: InspectionResultListState,
  action: Actions
): InspectionResultListState => {
  switch (action.type) {
    // 絞り込んだ結果として該当のページが存在しない可能性があるため、ページをリセットする。
    case 'RESET':
      return {...getInspectionTypeStatus(), initialized: true};
    case 'SET_INITIALIZED':
      return {...prev, initialized: action.initialized};
    case 'CHANGE_ORDER_KEY':
      return {...prev, orderKey: action.orderKey};
    case 'CHANGE_PAGE':
      return {...prev, page: action.page};
    case 'CHANGE_PER_PAGE':
      localStorage.setItem(LOCAL_STORAGE_KEY_INSPECTION_LIST_SIZE, action.perPage + '');
      return {...prev, perPage: action.perPage, page: INITIAL_PAGE};
    case 'CHANGE_SEARCH_NAME':
      return {...prev, searchName: action.searchName, page: INITIAL_PAGE};
    case 'CHANGE_QUERY_SEARCH_NAME':
      return {...prev, querySearchName: action.searchName, page: INITIAL_PAGE};
    case 'CHANGE_INSPECTION_TYPE': {
      const inspectionType = ViewInspectionTypes.find((t) => t === action.inspectionType) ?? 'pre_use';
      localStorage.setItem(LOCAL_STORAGE_KEY_INSPECTION_TYPE_STATUS, inspectionType);
      return {
        ...prev,
        inspectionType: inspectionType,
        page: INITIAL_PAGE,
      };
    }
    case 'CHANGE_INSPECTION_RESULT_STATUS':
      return {
        ...prev,
        inspectionResultStatus: action.resultStatus,
        page: INITIAL_PAGE,
      };
    case 'CHANGE_INSPECTOR':
      return {
        ...prev,
        inspector: action.inspector,
        page: INITIAL_PAGE,
      };
    case 'CHANGE_COMPLETED_AT_RANGE': {
      return {
        ...prev,
        completedAtFrom: action.from,
        completedAtTo: action.to,
        page: INITIAL_PAGE,
      };
    }
    case 'CHANGE_SCHEDULED_DATE_RANGE': {
      return {
        ...prev,
        scheduledTimeFrom: action.from,
        scheduledTimeTo: action.to,
        page: INITIAL_PAGE,
      };
    }
    case 'CHANGE_SKIPPED_DATE_RANGE': {
      return {
        ...prev,
        skippedTimeFrom: action.from,
        skippedTimeTo: action.to,
        page: INITIAL_PAGE,
      };
    }
    case 'CHANGE_IS_ADHOC': {
      return {
        ...prev,
        isAdhoc: action.isAdhoc,
        page: INITIAL_PAGE,
      };
    }
    case 'CHANGE_IS_PASSED': {
      return {
        ...prev,
        isPassed: action.isPassed,
        page: INITIAL_PAGE,
      };
    }
    case 'CHANGE_ROOT_CATEGORY':
      return {
        ...prev,
        rootCategory: action.rootCategory,
        page: INITIAL_PAGE,
      };
    case 'CHANGE_SUB_CATEGORY':
      return {
        ...prev,
        narrowCategory: action.narrowCategory,
        page: INITIAL_PAGE,
      };
    case 'CHANGE_HOSPITAL_WARD':
      return {
        ...prev,
        hospitalWard: action.hospitalWard,
        page: INITIAL_PAGE,
      };
    case 'CHANGE_HOSPITAL_ROOM':
      return {
        ...prev,
        hospitalRoom: action.hospitalRoom,
        page: INITIAL_PAGE,
      };
    case 'CHANGE_RENT_HOSPITAL_WARD':
      return {
        ...prev,
        rentHospitalWard: action.hospitalWard,
        page: INITIAL_PAGE,
      };
    case 'CHANGE_RENT_HOSPITAL_ROOM':
      return {
        ...prev,
        rentHospitalRoom: action.hospitalRoom,
        page: INITIAL_PAGE,
      };
    case 'CHANGE_INSPECTION_NAME':
      return {
        ...prev,
        inspectionName: action.inspectionName,
        page: INITIAL_PAGE,
      };
    case 'CHANGE_INSPECTION_SETTING_NAME':
      return {
        ...prev,
        inspectionSettingName: action.inspectionSettingName,
        page: INITIAL_PAGE,
      };
    default:
      console.warn('undefined action is dispatched', action);
      return prev;
  }
};

export const inspectionResultListStateAtom = atom<InspectionResultListState>(getInspectionTypeStatus());

// helper
export const useInspectionResultListState = () =>
  useReducerAtom(inspectionResultListStateAtom, inspectionResultListStateReducer);

const useMemoizedDispatch = () => {
  const [, dispatch] = useInspectionResultListState();
  return useMemo(() => dispatch, [dispatch]);
};

const useSelectState = <T>(valueAtom: Atom<T>, actionCreator: (value: T) => Actions) => {
  const value = useAtomValue(valueAtom);
  const dispatch = useMemoizedDispatch();
  // actionCreatorは静的に決定されるべきなので、メモ更新条件に指定しない
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const update = useCallback((x: T) => dispatch(actionCreator(x)), [dispatch]);

  return [value, update] as const;
};

const _orderKeyAtom = selectAtom(inspectionResultListStateAtom, (state) => state.orderKey);

export const useOrderKey = () => useSelectState(_orderKeyAtom, (orderKey) => createChangeOrderKeyAction(orderKey));

const _pageAtom = selectAtom(inspectionResultListStateAtom, (state) => state.page);

export const usePage = () => useSelectState(_pageAtom, (page) => createChangePageAction(page));

const _perPageAtom = selectAtom(inspectionResultListStateAtom, (state) => state.perPage);

export const usePerPage = () => useSelectState(_perPageAtom, (perPage) => createChangePerPageAction(perPage));

const _searchNameAtom = selectAtom(inspectionResultListStateAtom, (state) => state.searchName);

export const useSearchName = (debouncePeriod = 500) => {
  const searchName = useAtomValue(_searchNameAtom);
  const dispatch = useMemoizedDispatch();

  const updateQuerySearchName = useDebounceCallback(
    // eslint-disable-next-line no-shadow
    (searchName: string) => {
      dispatch(createChangeQuerySearchNameAction(searchName));
    },
    debouncePeriod,
    [dispatch]
  );

  const updateSearchName = useCallback(
    // eslint-disable-next-line no-shadow
    (searchName: string) => {
      dispatch(createChangeSearchNameAction(searchName));
      updateQuerySearchName(searchName);
    },
    [dispatch, updateQuerySearchName]
  );

  return [searchName, updateSearchName] as const;
};

const _querySearchNameAtom = selectAtom(inspectionResultListStateAtom, (state) => state.querySearchName);

export const useQuerySearchName = () => useAtomValue(_querySearchNameAtom);

export const _inspectionTypeAtom = selectAtom(inspectionResultListStateAtom, (state) => state.inspectionType);

export const useInspectionType = () =>
  useSelectState(_inspectionTypeAtom, (inspectionType) => createChangeInspectionTypeAction(inspectionType));

export const _inspectionResultStatusAtom = selectAtom(
  inspectionResultListStateAtom,
  (state) => state.inspectionResultStatus
);

export const useInspectionResultStatus = () =>
  useSelectState(_inspectionResultStatusAtom, (inspectionResultStatus) =>
    createChangeInspectionResultStatus(inspectionResultStatus)
  );

const _inspectorAtom = selectAtom(inspectionResultListStateAtom, (state) => state.inspector);

export const useInspector = () => useSelectState(_inspectorAtom, (inspector) => createChangeInspector(inspector));

const _completedRangeAtom = selectAtom(
  inspectionResultListStateAtom,
  (state) => [state.completedAtFrom, state.completedAtTo] as const,
  ([prevFrom, prevTo], [nextFrom, nextTo]) => prevFrom === nextFrom && prevTo === nextTo
);

export const useCompletedAtRange = () =>
  useSelectState(_completedRangeAtom, ([completedAtFrom, completedAtTo]) =>
    createChangeCompletedAtRange(completedAtFrom, completedAtTo)
  );

export const _scheduledTimeRangeAtom = selectAtom(
  inspectionResultListStateAtom,
  (state) => [state.scheduledTimeFrom, state.scheduledTimeTo] as const,
  ([prevFrom, prevTo], [nextFrom, nextTo]) => prevFrom === nextFrom && prevTo === nextTo
);

export const useScheduledTimeRange = () =>
  useSelectState(_scheduledTimeRangeAtom, ([scheduledTimeFrom, scheduledTimeTo]) =>
    createChangeScheduledDateRange(scheduledTimeFrom, scheduledTimeTo)
  );

export const _skippedTimeRangeAtom = selectAtom(
  inspectionResultListStateAtom,
  (state) => [state.skippedTimeFrom, state.skippedTimeTo] as const,
  ([prevFrom, prevTo], [nextFrom, nextTo]) => prevFrom === nextFrom && prevTo === nextTo
);

export const useSkippedTimeRange = () =>
  useSelectState(_skippedTimeRangeAtom, ([skippedTimeFrom, skippedTimeTo]) =>
    createChangeSkippedDateRange(skippedTimeFrom, skippedTimeTo)
  );

const _isAdhocAtom = selectAtom(inspectionResultListStateAtom, (state) => state.isAdhoc);

export const useIsAdhoc = () => useSelectState(_isAdhocAtom, (isAdhoc) => createChangeIsAdhoc(isAdhoc));

const _isPassedAtom = selectAtom(inspectionResultListStateAtom, (state) => state.isPassed);

export const useIsPassed = () => useSelectState(_isPassedAtom, (isPassed) => createChangeIsPassed(isPassed));

const _rootCategoryAtom = selectAtom(inspectionResultListStateAtom, (state) => state.rootCategory);

export const useRootCategory = () =>
  useSelectState(_rootCategoryAtom, (rootCategory) => createChangeRootCategory(rootCategory));

const _narrowCategoryAtom = selectAtom(inspectionResultListStateAtom, (state) => state.narrowCategory);

export const useNarrowCategory = () =>
  useSelectState(_narrowCategoryAtom, (narrowCategory) => createChangeNarrowCategory(narrowCategory));

const _hospitalWardAtom = selectAtom(inspectionResultListStateAtom, (state) => state.hospitalWard);

export const useHospitalWard = () =>
  useSelectState(_hospitalWardAtom, (hospitalWard) => createChangeHospitalWard(hospitalWard));

const _hospitalRoomAtom = selectAtom(inspectionResultListStateAtom, (state) => state.hospitalRoom);

export const useHospitalRoom = () =>
  useSelectState(_hospitalRoomAtom, (hospitalRoom) => createChangeHospitalRoom(hospitalRoom));

const _rentHospitalWardAtom = selectAtom(inspectionResultListStateAtom, (state) => state.rentHospitalWard);

export const useRentHospitalWard = () =>
  useSelectState(_rentHospitalWardAtom, (hospitalWard) => createChangeRentHospitalWard(hospitalWard));

const _rentHospitalRoomAtom = selectAtom(inspectionResultListStateAtom, (state) => state.rentHospitalRoom);

export const useRentHospitalRoom = () =>
  useSelectState(_rentHospitalRoomAtom, (hospitalRoom) => createChangeRentHospitalRoom(hospitalRoom));

const _inspectionName = selectAtom(inspectionResultListStateAtom, (state) => state.inspectionName);

export const useInspectionName = () =>
  useSelectState(_inspectionName, (inspectionName) => createChangeInspectionName(inspectionName));

const _inspectionSettingName = selectAtom(inspectionResultListStateAtom, (state) => state.inspectionSettingName);

export const useInspectionSettingName = () =>
  useSelectState(_inspectionSettingName, (inspectionSettingName) =>
    createChangeInspectionSettingName(inspectionSettingName)
  );

export const useIsDetailFilterActive = () => {
  const [[scheduledTimeFrom, scheduledTimeTo]] = useScheduledTimeRange();
  const [[completedAtFrom, completedAtTo]] = useCompletedAtRange();
  const [[skippedTimeFrom, skippedTimeTo]] = useSkippedTimeRange();
  const [isAdhoc] = useIsAdhoc();
  const [isPassed] = useIsPassed();
  const [hospitalWard] = useHospitalWard();
  const [hospitalRoom] = useHospitalRoom();
  const [rentHospitalWard] = useRentHospitalWard();
  const [rentHospitalRoom] = useRentHospitalRoom();
  const [inspectionName] = useInspectionName();
  const [inspectionSettingName] = useInspectionSettingName();

  const values = [
    scheduledTimeFrom,
    scheduledTimeTo,
    completedAtFrom,
    completedAtTo,
    skippedTimeFrom,
    skippedTimeTo,
    isAdhoc,
    isPassed,
    hospitalWard,
    hospitalRoom,
    rentHospitalWard,
    rentHospitalRoom,
    inspectionName,
    inspectionSettingName,
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => values.some((v) => !isNullish(v)), [...values]);
};

export const useDefaultDetailResultValues = (filterOptions: FilterOption[], initialized: boolean) => {
  const [scheduledTimeRange] = useScheduledTimeRange();
  const [completedAtRange] = useCompletedAtRange();
  const [skippedTimeRange] = useSkippedTimeRange();
  const [isAdhoc] = useIsAdhoc();
  const [isPassed] = useIsPassed();
  const [hospitalWard] = useHospitalWard();
  const [hospitalRoom] = useHospitalRoom();
  const [rentHospitalWard] = useRentHospitalWard();
  const [rentHospitalRoom] = useRentHospitalRoom();
  const [inspectionName] = useInspectionName();
  const [inspectionSettingName] = useInspectionSettingName();

  // 初期値がセットされたタイミングだけ値を作る
  return useMemo(() => {
    const res: DefaultResultType[] = [];

    if (!isNullish(isAdhoc)) {
      res.push({
        key: 'isAdhoc',
        resultValue: isAdhoc,
      });
    }
    if (!isNullish(isPassed)) {
      res.push({
        key: 'isPassed',
        resultValue: isPassed,
      });
    }

    // どっちか値がある場合
    if (scheduledTimeRange.some((item) => !isNull(item))) {
      res.push({
        key: 'scheduleDate',
        resultValue: scheduledTimeRange.map((item) => (isNull(item) ? undefined : item)),
      });
    }
    // どっちか値がある場合
    if (completedAtRange.some((item) => !isNull(item))) {
      res.push({
        key: 'completedAt',
        resultValue: completedAtRange.map((item) => (isNull(item) ? undefined : item)),
      });
    }
    // どっちか値がある場合
    if (skippedTimeRange.some((item) => !isNull(item))) {
      res.push({
        key: 'skippedTime',
        resultValue: skippedTimeRange.map((item) => (isNull(item) ? undefined : item)),
      });
    }

    if (!isNullish(hospitalWard)) {
      res.push({
        key: 'hospitalWard',
        resultValue: hospitalWard,
      });
    }
    if (!isNullish(hospitalRoom)) {
      res.push({
        key: 'hospitalRoom',
        resultValue: hospitalRoom,
      });
    }
    if (!isNullish(rentHospitalWard)) {
      res.push({
        key: 'rentHospitalWard',
        resultValue: rentHospitalWard,
      });
    }
    if (!isNullish(rentHospitalRoom)) {
      res.push({
        key: 'rentHospitalRoom',
        resultValue: rentHospitalRoom,
      });
    }
    if (!isNullish(inspectionName)) {
      res.push({
        key: 'inspectionName',
        resultValue: inspectionName,
      });
    }
    if (!isNullish(inspectionSettingName)) {
      res.push({
        key: 'inspectionSettingName',
        resultValue: inspectionSettingName,
      });
    }

    return getResultTypesFromDefaultTypes(res, filterOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialized]);
};

const _initializedAtom = selectAtom(inspectionResultListStateAtom, (state) => state.initialized);

export const useInitialized = () =>
  useSelectState(_initializedAtom, (initialized: boolean) => createSetInitializedAction(initialized));
