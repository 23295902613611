import React, {useMemo, useState} from 'react';
import {BackButtonFooter} from '../BackButtonFooter';
import {Box, Pagination, SxProps} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {FaultReceptionHeader} from '@components/molecules/FaultReceptionHeader';
import {EnhancedTable, EnhancedColumn} from '@front-libs/ui';
import {returnsReservationRequestParam, ReturnWaitingReceptionsResponse} from '@modules/returns_reservation/type';
import {useFetchReturnWaitingReceptions} from '@modules/returns_reservation/hook';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import dayjs from 'dayjs';
import {StatusIcon} from '../StatusIcon';
import {TooltipMessage} from '../TooltipMessage';

const StatusIconSx: SxProps = {
  fontSize: '12px',
  width: '80px',
};

const Columns: EnhancedColumn<ReturnWaitingReceptionsResponse>[] = [
  {
    title: '受付日時',
    field: 'receptionAt',
    fieldValue: (row) => dayjs(row.receptionAt).format('YYYY年MM月DD日 HH:mm'),
  },
  {
    title: '対応状況',
    field: 'status',
    fieldValue: (row) => <StatusIcon status={row.status} sx={StatusIconSx} />,
  },
  {
    title: '受付対象の機器',
    field: 'narrowCategoryName',
    fieldValue: (row) => <TooltipMessage message={row.narrowCategoryName} />,
  },
  {
    title: '受付台数',
    field: 'reservationCount',
  },
  {
    title: '機器を使用する場所',
    field: 'roomName',
    fieldValue: (row) => <TooltipMessage message={row.roomName} />,
  },
  {
    title: '連絡先',
    field: 'contactInformation',
    fieldValue: (row) => <TooltipMessage message={row.contactInformation} />,
  },
  {
    title: '伝達事項',
    field: 'transitionMessage',
    fieldValue: (row) => <TooltipMessage message={row.transitionMessage.replace(/[\r\n]+/g, '')} />,
  },
  {
    title: 'コメント',
    field: 'comment',
    fieldValue: (row) => <TooltipMessage message={row.comment} />,
  },
];

const PaperStyle: SxProps = {
  backgroundColor: 'white',
  height: 'calc(100vh - 284px)',
  minWidth: '860px',
  width: '90%',
  margin: '32px auto',
  borderRadius: '10px',
  overflow: 'auto',
  '& th': {
    fontSize: '18px',
  },
  '& td': {
    fontSize: '16px',
  },
};

/**
 * 返却待ち受付の履歴画面
 */
export const ReturnWaitingReceptionHistory = () => {
  const navigate = useNavigate();
  const {myInfo} = useMyInfo();
  const [page, setPage] = useState(1);
  const param = useMemo<returnsReservationRequestParam>(
    () => ({
      perPage: 100,
      page: page,
      order: 'receptionAt',
      orderDirection: 'desc',
    }),
    [page]
  );

  const {data, isLoading} = useFetchReturnWaitingReceptions(myInfo.hospitalHashId, param);

  const handlePageChange = (newPage: number) => {
    if (page === newPage) return;
    setPage(newPage);
  };

  const responseData: ReturnWaitingReceptionsResponse[] = React.useMemo(() => {
    // TODO: MockAPIをソート。本来はAPIから取得したデータをそのまま使用する
    return (data ? data.data : []).sort(
      (a, b) => new Date(b.receptionAt).getTime() - new Date(a.receptionAt).getTime()
    );
  }, [data]);

  return (
    <Box
      sx={{
        '& .MuiPagination-root': {
          display: 'flex',
          justifyContent: 'center',
        },
      }}>
      <FaultReceptionHeader title="返却待ち受付の履歴" />
      <EnhancedTable<ReturnWaitingReceptionsResponse>
        data={responseData}
        columns={Columns.map((column) => ({...column, noBodyWrap: true}))}
        isLoading={isLoading}
        options={{paperSx: PaperStyle}}
        stickyHeader
      />
      <Pagination
        page={page}
        count={1} //TODO totalCount/20でページネーションを実装する
        shape="rounded"
        onChange={(_e, page) => handlePageChange(page)}
        aria-label="ページ切り替え"
      />
      <BackButtonFooter onClick={() => navigate('/shared/reception_menu')} />
    </Box>
  );
};
