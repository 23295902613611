import React, {memo} from 'react';
import {InputBase, styled} from '@mui/material';

const DefaultMarginBottom = '16px';
const StyledInputBase = styled(InputBase)(({top, bottom}: {top?: string; bottom?: string}) => ({
  marginTop: top || '4px',
  padding: '4px 0px 0px',
  marginBottom: bottom || DefaultMarginBottom,
  '& .MuiInputBase-inputMultiline': {
    borderRadius: '10px',
    backgroundColor: '#F3F4F6',
    fontSize: '20px',
    padding: '16px',
    lineHeight: '1.25em',
  },
}));

type Props = {
  rows?: number;
  name: string;
  value: string;
  autoFocus?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  top?: string;
  bottom?: string;
};
export const ReceptionTextField = memo(
  ({rows = 1, autoFocus, name, value, top, bottom, onChange}: Props) => {
    return (
      <StyledInputBase
        id={name}
        name={name}
        placeholder="入力してください"
        multiline
        autoFocus={Boolean(autoFocus)}
        rows={rows}
        value={value}
        onChange={onChange}
        top={top}
        bottom={bottom}
      />
    );
  },
  (prevProps, nextProps) => {
    // valueの変更時のみ再レンダリングされる
    return prevProps.value === nextProps.value;
  }
);
