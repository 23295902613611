/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import _ from 'lodash';

// biome-ignore lint/complexity/noStaticOnlyClass: <explanation>
export class StrUtil {
  static nl2br(str: string): Array<React.ReactNode | string> {
    const reg = /(\r\n|\r|\n)/g;
    return str.split(reg).map((line, index) => {
      if (line.match(reg)) {
        return React.createElement('br', {
          key: index,
        });
      }
      return line;
    });
  }

  static addComma(value: number) {
    if (value !== 0 && !value) {
      return '';
    }
    const onlyNums = String(value).replace(/[^\d]/g, '');
    return onlyNums.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
  }

  static eraseChar(char: string) {
    const reg = new RegExp(char, 'g');
    return (str: string) => {
      const value = str.toString();
      return value.replace(reg, '');
    };
  }

  static eraseSpace(str: string) {
    return StrUtil.eraseChar(' ')(str);
  }

  static toString(value: unknown): string {
    if (value == null) {
      return '';
    }
    return value + '';
  }

  static toHankakuNumber(str: string): string {
    const hankakuNumbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    const zenkakuNumbers = ['０', '１', '２', '３', '４', '５', '６', '７', '８', '９'];
    const valueArray = str.split('');
    const res = valueArray.map((letter) => {
      const letterIndex = zenkakuNumbers.indexOf(letter);
      if (letterIndex !== -1) {
        return hankakuNumbers[letterIndex];
      } else return letter;
    });
    return res.join('');
  }

  static toHankaku(str: string): string {
    // 半角変換
    const halfVal = str.replace(/[！-～]/g, (tmpStr) => {
      // 文字コードをシフト
      return String.fromCharCode(tmpStr.charCodeAt(0) - 0xfee0);
    });

    // 文字コードシフトで対応できない文字の変換
    return (
      halfVal
        .replace(/”/g, '"')
        .replace(/’/g, "'")
        .replace(/‘/g, '`')
        .replace(/￥/g, '\\')
        // eslint-disable-next-line no-irregular-whitespace
        .replace(/　/g, ' ')
        .replace(/〜/g, '~')
        .replace(/「/g, '[')
        .replace(/」/g, ']')
    );
  }
}

export function partialMatch(str1: string, str2: string) {
  str1 = str1.toLowerCase().replace(/ /g, '');
  str2 = str2.toLowerCase().replace(/ /g, '');
  return str1.indexOf(str2) !== -1;
}

export function partialsMatch(target: object, partials: Array<string>, search: string) {
  return partials.some((partial) => {
    const targetStr = partial.split('+').reduce((acc, part) => `${acc}${_.get(target, part, '')}`, '');
    return partialMatch(targetStr, search);
  });
}

export function partialsMatches(target: object, partials: Array<string>, searches: string[]) {
  const targetStringArray = partials.map((partial) =>
    partial.split('+').reduce((acc, part) => `${acc}${_.get(target, part, '')}`, '')
  );
  return searches.every((search) => targetStringArray.some((targetString) => partialMatch(targetString, search)));
}

export function removeNewlineCode(str: string) {
  return str.replace(/\n|\r/g, '');
}

export function zenkaku2Hankaku(str: string) {
  return str.replace(/[！-～]/g, (input) => String.fromCharCode(input.charCodeAt(0) - 0xfee0));
}

export function stringArrayToString(str: string[]): string {
  return str.join('\n');
}
