import React, {useState, useCallback} from 'react';
import {makeStyles, Button, ClickAwayListener} from '@material-ui/core';
import clsx from 'clsx';
import {isMobile} from 'react-device-detect';
import {MenuItemType, PopperMenuButton} from '@molecules/Buttons/PopperMenuButton';
import {
  RowAction as RowActionType,
  RowButtonAction as RowButtonActionType,
  RowMenuAction as RowMenuActionType,
} from '../props';

const CLASS_VISIBLE_ON_ROW_HOVER = 'visible-on-row-hover';

const useStyle = makeStyles((theme) => ({
  button: {
    marginLeft: '8px',
    backgroundColor: theme.palette.grey[50],
  },
  smallButton: {
    height: '20px',
  },
  defaultInvisible: {
    visibility: 'hidden',
  },
  defaultVisible: {
    visibility: 'visible',
  },
}));

type ButtonActionProps<T> = {
  data: T;
  action: RowButtonActionType<T>;
  actionIndex: number;
  buttonSize: 'medium' | 'small';
  selecting: boolean;
  onChangeSelecting: (selecting: boolean) => void;
};

// eslint-disable-next-line @typescript-eslint/ban-types
const ButtonAction = <T extends {}>(props: ButtonActionProps<T>) => {
  const {data, action, actionIndex, buttonSize, selecting, onChangeSelecting} = props;
  const classes = useStyle();

  const defaultMouseCallback = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();

    return false;
  }, []);

  const handleClick = useCallback(
    async (e: React.MouseEvent) => {
      e.stopPropagation();

      onChangeSelecting(true);

      await action.onClick(e, data);

      onChangeSelecting(false);

      return false;
    },
    [onChangeSelecting, action, data]
  );

  const shouldShowVisible = isMobile || selecting;

  return (
    <Button
      data-testid={`row-action-${actionIndex}`}
      key={actionIndex}
      className={clsx(
        classes.button,
        buttonSize === 'small' && classes.smallButton,
        shouldShowVisible ? classes.defaultVisible : classes.defaultInvisible,
        CLASS_VISIBLE_ON_ROW_HOVER
      )}
      variant="outlined"
      onMouseDown={defaultMouseCallback}
      onMouseUp={defaultMouseCallback}
      onClick={handleClick}>
      {action.label}
    </Button>
  );
};

type MenuActionProps<T> = {
  data: T;
  action: RowMenuActionType<T>;
  actionIndex: number;
  buttonSize: 'medium' | 'small';
  selecting: boolean;
  onChangeSelecting: (selecting: boolean) => void;
};

// eslint-disable-next-line @typescript-eslint/ban-types
const MenuAction = <T extends {}>(props: MenuActionProps<T>) => {
  const {data, action, actionIndex, buttonSize, selecting, onChangeSelecting} = props;
  const classes = useStyle();

  const defaultMouseCallback = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();

    return false;
  }, []);

  const handleMenuOpen = useCallback(() => {
    onChangeSelecting(true);
  }, [onChangeSelecting]);

  const handleMenuClose = useCallback(() => {
    onChangeSelecting(false);
  }, [onChangeSelecting]);

  const handleClickAway = useCallback(() => {
    onChangeSelecting(false);
  }, [onChangeSelecting]);

  const handleMenuClick = useCallback(
    async (selected: MenuItemType, e: React.MouseEvent<Document, MouseEvent>) => {
      e.stopPropagation();

      onChangeSelecting(true);

      const value: number = selected.value;

      if (value < action.items.length) {
        const item = action.items[value];
        await item.onClick(e as React.MouseEvent<never, MouseEvent>, data);
      }

      onChangeSelecting(false);

      return false;
    },
    [action.items, data, onChangeSelecting]
  );

  const shouldShowVisible = isMobile || selecting;

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      {/* NOTE:Warning: Failed prop type: Invalid prop `children`
       supplied to `ClickAwayListener`. Expected an element that can hold a ref.
       の対応としてdivでラップ */}
      <div>
        <PopperMenuButton
          onOpen={handleMenuOpen}
          onClose={handleMenuClose}
          data-testid={`row-action-${actionIndex}`}
          placement="bottom-start"
          containerProps={{
            onMouseDown: defaultMouseCallback,
            onMouseUp: defaultMouseCallback,
          }}
          buttonProps={{
            variant: 'outlined',
            disableElevation: true,
            className: clsx(
              classes.button,
              buttonSize === 'small' && classes.smallButton,
              shouldShowVisible ? classes.defaultVisible : classes.defaultInvisible,
              CLASS_VISIBLE_ON_ROW_HOVER
            ),
          }}
          itemProps={{style: {fontSize: '14px'}}}
          menuItemList={action.items.map((item, itemIndex) => ({
            label: typeof item.label === 'string' ? item.label : item.label(data),
            value: itemIndex,
          }))}
          onMenuClick={handleMenuClick}>
          {action.label}
        </PopperMenuButton>
      </div>
    </ClickAwayListener>
  );
};

type RowActionsProps<T> = {
  data: T;
  actions: RowActionType<T>[];
  buttonSize: 'medium' | 'small';
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const RowActions = <T extends {}>(props: RowActionsProps<T>) => {
  // eslint-disable-next-line no-shadow
  const {data, actions, buttonSize} = props;
  const [selecting, setSelecting] = useState(false);

  return (
    <>
      {actions
        .filter((action) => action.isHidden === undefined || action.isHidden(data) === false)
        .map((action, index) => {
          switch (action.type) {
            case 'button':
              return (
                <ButtonAction
                  key={index}
                  data={data}
                  action={action}
                  actionIndex={index}
                  buttonSize={buttonSize}
                  selecting={selecting}
                  onChangeSelecting={setSelecting}
                />
              );
            case 'menu':
              return (
                <MenuAction
                  key={index}
                  data={data}
                  action={action}
                  actionIndex={index}
                  buttonSize={buttonSize}
                  selecting={selecting}
                  onChangeSelecting={setSelecting}
                />
              );
            default:
              return null;
          }
        })}
    </>
  );
};
