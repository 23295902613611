export type UserIndex = {
  hashId: string;
  hospitalHashId: string;
  email: string;
  username: string;
  thumbnailUrl?: string;
  thumbnailFile?: {
    hashId: string;
    hospitalHashId: string;
    category: string;
    driver: string;
    fileType: string;
    originalName: string;
    fileName: string;
    path: string;
    url: string;
  };
  lastName: string;
  firstName: string;
  lastNameKana: string;
  firstNameKana: string;
  isShared: boolean;
  lastSignInAt?: Date;
  nextInitializeStep?: number;
  initialized?: boolean;
  occupations?: string[];
  isSafetyControlManager?: boolean;
  isUserAdmin: boolean;
  createdAt: string;
  createdBy: UserIndex | null;
  updatedAt: string;
  updatedBy: UserIndex | null;
  deletedBy: UserIndex | null;
  deletedAt: string | null;
  permissions?: string[];
  roleResources?: RoleResource[];
  canAccessMgmtDashboard?: boolean;
  sessionExpirationTimeSec: number | null;
  rentalLayoutPreference: 'default_order' | 'customize';
};

export type UserResource = {
  hashId: string;
  name: string;
  applicationHashId: string;
  ownerOrganizationHashId: string;
};

export type Role = {
  hashId: string;
  name: string;
  ownerOrganizationHashId: string;
  applicationHashId: string;
  isShared: boolean;
};

export type RoleUsers = {
  role: Role;
  users: {user: UserIndex}[];
};

export enum HospitalUserActionType {
  HOSPITAL_USER_SET_LIST = 'HOSPITAL_USER_SET_LIST',
}

export type HospitalUserSetListAction = {
  type: HospitalUserActionType.HOSPITAL_USER_SET_LIST;
  payload: {
    users: UserIndex[];
  };
};

export type HospitalUserAction = HospitalUserSetListAction;

export type HospitalUserState = {
  users: UserIndex[];
};

export enum PermissionLevel {
  NONE = 0,
  READ_ONLY = 1,
  READ_EDIT = 2,
  READ_EDIT_DELETE = 4,
}

export type ResourceKey = {
  id: string;
  name: string;
  path: string;
};

export const RESOURCES = {
  DASHBOARD: {id: 'dashboard', name: 'Dashboard', path: '/dashboard'},
  MANAGEMENT_DASHBOARD: {id: 'management_dashboard', name: 'Management Dashboard', path: '/management'},
  DEVICE_LIST: {id: 'device_list', name: 'Device List', path: '/devices'},
  CHANNEL_LIST: {id: 'channel_list', name: 'Channel List', path: '/channels'},
  INSPECTION: {id: 'inspection', name: 'Inspection', path: '/inspection'},
  REPAIR: {id: 'repair', name: 'Repair', path: '/repair'},
  LENDING_RETURN: {id: 'lending_return', name: 'Lending Return', path: '/lending'},
  LENDING_RETURN_RECEPTION: {id: 'lending_return_reception', name: 'Reception', path: '/reception'},
  TRAINING: {id: 'training', name: 'Training', path: '/training'},
  WARD_SERVICE: {id: 'ward_service', name: 'Ward Service', path: '/ward'},
  FULL_ACCESS_TO_MGMT_SECTION: {id: 'full_access_to_mgmt_section', name: 'Management Access', path: '/management'},
} as const;

export type Resource = {
  canDelete: boolean;
  isReadOnly: boolean;
  resource: {
    hashId: string;
    name: string; //FIXME:Enum化
    applicationHashId: string;
    ownerOrganizationHashId: string;
    createdAt: string;
    createdBy?: string;
    updatedAt: string;
    updatedBy?: string;
    deletedBy?: string;
  };
};

export type RoleResource = {
  role: Role;
  resources: Resource[];
};
