import {zenkaku2Hankaku} from '@front-libs/helpers';

export const formatApprovalNumber = (s: string): string => {
  return zenkaku2Hankaku(s).toUpperCase();
};

export const formatJANCode = (s: string): string => {
  return zenkaku2Hankaku(s);
};

export const formatSerialNumber = (s: string): string => {
  return zenkaku2Hankaku(s);
};

export const formatLotNumber = (s: string): string => {
  return zenkaku2Hankaku(s);
};

export const formatAssetRegisterNumber = (s: string): string => {
  return zenkaku2Hankaku(s);
};

export const formatGS1Barcode = (s: string): string => {
  return zenkaku2Hankaku(s);
};

export const formatManagementId = (s: string): string => {
  return zenkaku2Hankaku(s);
};

export const formatBarcode = (s: string): string => {
  return zenkaku2Hankaku(s);
};

export const formatJMDNCoda = (s: string): string => {
  return zenkaku2Hankaku(s);
};
