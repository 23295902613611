import React from 'react';
import {createStyles, Grid, makeStyles, Theme, FormHelperText} from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorContainer: {
      marginTop: '4px',
    },
    errorContainerHeight: {
      height: '22px',
    },
    error: {
      marginTop: '0px',
    },
  })
);

type FormErrorProps = {
  error: string | undefined;
  reserveSpace?: boolean;
};

export const FormError: React.FC<FormErrorProps> = (props) => {
  const {error, reserveSpace} = props;
  const classes = useStyles();

  const rootClass =
    reserveSpace || error ? clsx(classes.errorContainer, classes.errorContainerHeight) : clsx(classes.errorContainer);

  return (
    <Grid className={rootClass}>
      {error !== null && (
        <FormHelperText error={true} className={classes.error}>
          {error}
        </FormHelperText>
      )}
    </Grid>
  );
};
