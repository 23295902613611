import React, {memo} from 'react';
import {Box, Grid, Typography} from '@material-ui/core';
import {ProductStatusIcon} from './ProductStatusIcon';
import {useNavigate} from 'react-router-dom';
import {InspectionResultIndex} from '@modules/inspection_results/types';
import {ActionBtnContainer} from './ActionBtnContainer';
import {InspectionCustomItem} from './InspectionCustomItem';
import {styled} from '@material-ui/core/styles';

const LEFT_CONTAINER_WIDTH = '80px';
const StyledRootGrid = styled(Grid)({
  display: 'flex',
  marginBottom: '8px',
});

const StyledLeftContainer = styled(Grid)({
  width: LEFT_CONTAINER_WIDTH,
  paddingRight: '8px',
});

const StyledRightContainer = styled(Grid)({
  width: '250px',
  marginTop: '2px',
  marginRight: '8px',
  flexDirection: 'column',
});

const StyledDisplayNameText = styled(Typography)({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontWeight: 'bold',
  maxWidth: '100%',
});

const StyledNameText = styled(Typography)({
  fontSize: '14px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '100%',
});

const StyledContainerBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '8px 24px',
  borderBottom: '1px solid #DDE0E4',
});

const StyledHiddenBox = styled(Box)({
  inlineSize: '50px',
  overflowWrap: 'break-word',
  maxHeight: '36px',
  marginTop: '4px',
  marginLeft: '8px',
  whiteSpace: 'break-spaces',
});

const StyledTypography = styled(Typography)({
  fontSize: '14px',
  whiteSpace: 'nowrap',
});

type InspectionResultProps = {
  inspectionResult: InspectionResultIndex;
  refetch: () => void;
};

export const InspectionResult = memo(({inspectionResult, refetch}: InspectionResultProps) => {
  const navigate = useNavigate();
  const handleActionMenuClick = () => {
    navigate(`/sp/products/${inspectionResult.hospitalProduct.hashId}`);
  };
  const changeText = (text: string, maxLength: number) =>
    text.length > maxLength ? text.substring(0, maxLength - 1) + '…' : text;

  return (
    <StyledContainerBox>
      <StyledRootGrid item onClick={handleActionMenuClick}>
        <StyledLeftContainer container item>
          <ProductStatusIcon status={inspectionResult.hospitalProduct.status} />
          <StyledHiddenBox>{changeText(inspectionResult.hospitalProduct.managementId, 20)}</StyledHiddenBox>
        </StyledLeftContainer>
        <StyledRightContainer container item>
          <StyledDisplayNameText>
            {inspectionResult.hospitalProduct.displayName !== '' ? (
              inspectionResult.hospitalProduct.displayName
            ) : (
              <br />
            )}
          </StyledDisplayNameText>
          <StyledNameText>
            {inspectionResult.hospitalProduct.name !== '' ? inspectionResult.hospitalProduct.name : <br />}
          </StyledNameText>
          <StyledTypography>点検名：{inspectionResult?.inspectionProduct?.name}</StyledTypography>
          <InspectionCustomItem inspectionResult={inspectionResult} />
        </StyledRightContainer>
      </StyledRootGrid>
      <ActionBtnContainer inspectionResult={inspectionResult} refetch={refetch} />
    </StyledContainerBox>
  );
});
