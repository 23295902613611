import React, {useState, useCallback} from 'react';
import {useAtom} from 'jotai';
import {Auth0Provider, useAuth0} from '@auth0/auth0-react';
import {axios} from '@front-libs/core';
import {useAsync} from 'react-use';
import {useAsyncEffect} from '@front-libs/core';
import {useOnlyOnce} from '@front-libs/core';
import {accessToken as accessTokenState} from '../jotai';
import {Loading} from '@organisms/Loading';

export const withAuth0 = <P extends Record<string, unknown>>(Component: React.ComponentType<P>) => {
  // NOTE:関数コンポーネントのPropsがchildrenプロパティを含むためPropsWithChildrenを定義
  const WithAuth0Component: React.FC<React.PropsWithChildren<P>> = (props) => {
    return (
      <Auth0Provider
        domain={import.meta.env.VITE_AUTH0_DOMAIN as string}
        clientId={import.meta.env.VITE_AUTH0_CLIENT_ID as string}
        useRefreshTokens={true}
        cacheLocation="localstorage"
        audience={import.meta.env.VITE_AUTH0_AUDIENCE as string}
        redirectUri={window.location.origin}>
        <Auth0Authentication>
          <Component {...props} />
        </Auth0Authentication>
      </Auth0Provider>
    );
  };
  return WithAuth0Component;
};

// Auth0の設定より短い方が好ましい
const REFRESH_INTERVAL_TIME = 1000 * 60 * 5;

const Auth0Authentication: React.FC = ({children}) => {
  const {isAuthenticated, loginWithRedirect, isLoading, getAccessTokenSilently, logout} = useAuth0();
  const [innerLoading, setInnerLoading] = useState(true);
  const [accessToken, setAccessToken] = useState<null | string>(null);
  const [, setTokenAtom] = useAtom(accessTokenState);

  const updateToken = useCallback(async () => {
    try {
      setAccessToken(await getAccessTokenSilently());
    } catch (_error) {
      logout({returnTo: window.location.origin});
    }
  }, [logout, getAccessTokenSilently]);

  useAsyncEffect(async () => {
    if (!isLoading && !isAuthenticated) await loginWithRedirect();
  }, [isLoading, isAuthenticated]);

  useOnlyOnce(async () => {
    await updateToken();

    setInterval(async () => {
      await updateToken();
    }, REFRESH_INTERVAL_TIME);
  }, isAuthenticated);

  useAsync(async () => {
    if (!isAuthenticated) return;

    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    setTokenAtom(accessToken);
    setInnerLoading(false);
  }, [accessToken]);

  if (isLoading || innerLoading) return <Loading />;
  // React.Fragment消せないのでdisable
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{isAuthenticated && children}</>;
};
