import React from 'react';
import {Box, Stack, SxProps, Typography} from '@mui/material';
import {FilePreview} from '@molecules/FilePreview';
import {UserFormatter} from '@modules/hospital_users/helpers';
import {UserIndex} from '@modules/hospital_users/types';
import {convertDisplaySimpleDate} from '@front-libs/helpers';

const styles: Record<string, SxProps> = {
  fileName: {
    fontSize: '14px',
    lineHeight: '24px',
  },
  fileInfo: {
    fontSize: '12px',
    lineHeight: '20px',
  },
  preview: {
    marginTop: '16px',
    height: '100%',
  },
};

type InspectionManualProps = {
  fileHashId?: string;
  fileType?: string;
  fileName?: string;
  uploadedUser?: UserIndex;
  uploadedAt?: Date;
};

export const InspectionManual = (props: InspectionManualProps) => {
  const {fileHashId, fileType, fileName, uploadedUser, uploadedAt} = props;
  return (
    <Stack sx={{height: '100%'}}>
      <Box>
        <Typography sx={styles.fileName}>ファイル名：{fileName}</Typography>
      </Box>
      <Box>
        <Typography sx={styles.fileInfo}>
          {uploadedAt ? convertDisplaySimpleDate(uploadedAt) : ''}、{UserFormatter.getFullName(uploadedUser)}さんが共有
        </Typography>
      </Box>
      {fileHashId && fileType && fileName && (
        <Box sx={styles.preview}>
          <FilePreview fileHashId={fileHashId} fileType={fileType} fileName={fileName} scale={1.0} />
        </Box>
      )}
    </Stack>
  );
};
