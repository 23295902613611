import {HospitalRoom} from '@modules/hospital_places/types';
import {HospitalWard} from '@modules/hospital_wards/types';
import {Card, CardActionArea, CardContent, RadioProps, SxProps, Theme} from '@mui/material';
import React, {useMemo} from 'react';

type Props<T> = {
  label: string;
  itemsCount: number;
  value: T;
  onChange: (value: T) => void;
} & Omit<RadioProps, 'onChange'>;

/**
 * RentalSelectionCard コンポーネント
 *
 * このコンポーネントは、病棟や部屋などのレンタル項目を表すカードです。
 * カードをクリックすることで、ユーザーが項目を選択できます。
 *
 * プロパティ:
 * @param label - カードに表示されるラベル。
 * @param itemsCount - 項目の数（スタイル調整に使用）。
 * @param value - 病棟または部屋を表す値。
 * @param onChange - カードがクリックされたときに呼び出されるコールバック関数。
 */
export const RentalSelectionCard = <T = HospitalWard | HospitalRoom>({
  label,
  itemsCount,
  value,
  onChange,
}: Props<T>) => {
  const handleClick = () => {
    onChange(value);
  };

  const itemsOverEight = useMemo(() => itemsCount > 8, [itemsCount]);

  return (
    <Card sx={cardStyle(itemsOverEight)}>
      <CardActionArea onClick={handleClick} data-testid="rental-card">
        <CardContent sx={contentStyle(itemsOverEight)}>{label}</CardContent>
      </CardActionArea>
    </Card>
  );
};

const cardStyle = (itemsOverEight: boolean): SxProps<Theme> => ({
  borderRadius: itemsOverEight ? '25px' : '35px',
  boxShadow: '0px 8px 0px 0px rgba(209, 216, 245, 1)',
  '&:active': {
    color: (theme) => theme.palette.rent.light,
  },
});

const contentStyle = (itemsOverEight: boolean): SxProps<Theme> => ({
  maxWidth: itemsOverEight ? '112px' : '144px',
  height: itemsOverEight ? '72px' : '80px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: itemsOverEight ? '16px' : '20px',
  fontWeight: 'bold',
  textAlign: 'center',
  margin: 'auto',
  color: (theme) => theme.palette.rent.main,
  '@media (min-width: 1024px)': {
    fontSize: itemsOverEight ? '20px' : '40px',
    maxWidth: itemsOverEight ? '180px' : '200px',
    height: itemsOverEight ? '80px' : '128px',
  },
  '@media (max-height: 640px)': {
    height: itemsOverEight ? '60px' : '128px',
  },
});
