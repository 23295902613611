import {Grid, styled, TextField} from '@material-ui/core';
import {Search} from '@material-ui/icons';
import React from 'react';
import {MenuItemType, PopperMenuButton} from '@molecules/Buttons/PopperMenuButton';

type FacetSearchFormProps = {
  searchName: string | null;
  handleSearchName: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleActionMenuClick: (item: MenuItemType) => void;
};

const actionMenus = [
  {
    label: '表示項目を変更',
    value: 'changeTableLayout',
  },
];

export const Toolbar = ({searchName, handleSearchName, handleActionMenuClick}: FacetSearchFormProps) => {
  return (
    <ToolBarContainer container>
      <SearchNameContainer item sm={4} md={3}>
        <SearchNameTextField
          label={'受付番号・機器情報で検索'}
          variant={'outlined'}
          fullWidth
          size={'small'}
          InputProps={{
            endAdornment: <Search />,
          }}
          InputLabelProps={{
            style: {
              fontSize: 14,
            },
          }}
          onChange={handleSearchName}
          value={searchName}
        />
      </SearchNameContainer>
      <PopperMenuButton
        buttonProps={{
          variant: 'contained',
          disableElevation: true,
          style: {
            width: '100%',
            background: 'rgba(9, 30, 66, 0.04)',
            border: '1px solid #C6CBD4',
          },
        }}
        menuItemList={actionMenus}
        onMenuClick={handleActionMenuClick}>
        アクション
      </PopperMenuButton>
    </ToolBarContainer>
  );
};

const ToolBarContainer = styled(Grid)({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 8,
});

const SearchNameContainer = styled(Grid)({
  marginRight: 32,
});

const SearchNameTextField = styled(TextField)(({theme}) => ({
  backgroundColor: theme.palette.common.white,
  fontSize: '14px',
}));
