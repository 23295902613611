export const defaultRoleMap = {
  /** 管理者ユーザー */
  admin: 'admin',
  /** 貸出・返却専用ユーザー */
  lendingAndReturnOnly: 'lending and return-only',
  /**  閲覧専用ユーザー */
  deviceUse: 'device use',
  /**  一般ユーザー */
  general: 'general',
  /**  病棟ユーザー */
  ward: 'ward',
} as const;
