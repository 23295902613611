import React from 'react';
import dayjs from 'dayjs';
import {styled, Box, Typography} from '@mui/material';
import {InspectionResultIndex} from '@modules/inspection_results/types';
import {UserFormatter} from '@modules/hospital_users/helpers';
import {InspectionTypeLabelMap} from '@components/organisms/Header/pc/GeneralMenu/HospitalProductMenu/constants';
import {
  isInspectionType,
  InspectionSummaryRow,
} from '@components/organisms/Header/pc/GeneralMenu/HospitalProductMenu/types';

type Props = {
  data: InspectionSummaryRow[];
};

const Frame = styled(Box)({
  borderRadius: '6px',
  padding: '16px',
  backgroundColor: '#F3F4F6',
  '& > div:nth-of-type(n+2)': {
    marginTop: '24px',
  },
});

const Label = styled(Typography)({
  fontSize: '12px',
  color: '#8B95A6',
});

const Value = styled(Typography)({
  marginTop: '8px',
  fontSize: '14px',
  color: '#172B4D',
});

export const InspectionSummary = ({data}: Props) => {
  return (
    <Frame>
      {data.map((row, i) => (
        <Box key={`inspection-summary-${i}`}>
          <Label>{row.label}</Label>
          <Value>{row.value}</Value>
        </Box>
      ))}
    </Frame>
  );
};

export const buildInspectionSummaryRows = ({
  status,
  type,
  scheduledTime,
  inspectionProduct,
  inspection,
  numberOfSameDayFormerResults,
  suspendedAt,
  isAdhocDate,
  inspector,
}: InspectionResultIndex): InspectionSummaryRow[] => {
  // 項目の値を設定
  const inspectionType = type ?? '';
  const inspectionTypeLabel = isInspectionType(inspectionType) ? InspectionTypeLabelMap[inspectionType] : '-';
  const buildScheduledTimeText = () => {
    if (!scheduledTime) return '-';

    const scheduledDay = dayjs(scheduledTime);
    const scheduledDayText = scheduledDay.format('YYYY/MM/DD');
    const isOver = scheduledDay.isBefore(dayjs().startOf('month'), 'month');
    if (isOver) return `${scheduledDayText}（予定月超過）`;

    return scheduledDayText;
  };
  const inspectionProductName = inspectionProduct?.name ?? '-'; // NOTE: 型の定義上、inspectionProductはnullになり得ないが、実際にAPIが返す値を見るとnullの時があったのでinspectionProduct?.nameとしている
  const numberOfSameDayFormerResultsText =
    numberOfSameDayFormerResults !== null ? `${numberOfSameDayFormerResults}回` : '-';
  const suspendedAtText = suspendedAt ? dayjs(suspendedAt).format('YYYY/MM/DD HH:mm') : '-';
  const isAdhocDateText = isAdhocDate ? '臨時' : '通常';
  const inspectorName = UserFormatter.getFullName(inspector ?? undefined);

  // 項目の表示／非表示を制御
  const scheduledTimeRow =
    inspectionType === 'periodic' ? [{label: '点検予定日', value: buildScheduledTimeText()}] : [];
  const numberOfSameDayFormerResultsRow =
    inspectionType === 'pre_use' ? [{label: '当日の点検完了回数', value: numberOfSameDayFormerResultsText}] : [];

  switch (status) {
    case 'unplanned':
      return [
        {label: '点検タイプ', value: inspectionTypeLabel},
        ...scheduledTimeRow,
        {label: '点検名', value: inspectionProductName},
        {label: '使用する点検表', value: inspection.name},
        ...numberOfSameDayFormerResultsRow,
      ];
    case 'uncompleted':
      return [
        {label: '点検タイプ', value: inspectionTypeLabel},
        ...scheduledTimeRow,
        {label: '点検名', value: inspectionProductName},
        {label: '使用する点検表', value: inspection.name},
        ...numberOfSameDayFormerResultsRow,
        {label: '中断日時', value: suspendedAtText},
        {label: '臨時点検', value: isAdhocDateText},
        {label: '最終点検者', value: inspectorName},
      ];
    default:
      return [];
  }
};
