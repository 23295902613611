import {InnerLoading} from '@components/molecules/Loading';
import {WholeProductSearchPullDown} from '@components/organisms/WholeProductSearchPullDownBtn/WholeProductSearchPullDown';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import CloseIcon from '@mui/icons-material/Close';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography} from '@mui/material';
import React, {Suspense, useState} from 'react';
import {RelinkConfirmationMessage} from './RelinkConfirmationMessage';

export type SelectRelinkDeviceDialogProps = {wholeProductHashId: string} & DialogProps;

/**
 * 再紐付けする院内機種を選択 ダイアログ
 * @returns Promise<WholeProductHashId>
 */
export const SelectRelinkDeviceDialog: React.FC<SelectRelinkDeviceDialogProps> = ({
  open,
  actions,
  wholeProductHashId,
}: SelectRelinkDeviceDialogProps) => {
  const [selectedWholeProductHashId, setSelectedWholeProductHashId] = useState<string>('');
  const excludedWholeProductHashIds = [wholeProductHashId];
  return (
    <Dialog maxWidth="lg" open={!!open} PaperProps={{sx: {minWidth: '512px'}}}>
      <Suspense fallback={<InnerLoading />}>
        <DialogTitle sx={{fontWeight: 700, padding: '32px 32px 0'}}>再紐付けする院内機種を選択</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => actions.reject()}
          sx={{
            position: 'absolute',
            fontSize: 24,
            right: '16px',
            top: '24px',
          }}>
          <CloseIcon sx={{color: '#172B4D'}} />
        </IconButton>
        <DialogContent>
          <WholeProductSearchPullDown
            onSelect={(value) => {
              setSelectedWholeProductHashId(value);
            }}
            excludedWholeProductHashIds={excludedWholeProductHashIds}
          />
          <Typography variant="body1" sx={{color: ' #5D6B82', fontSize: '12px', padding: '8px'}}>
            ※現時点で対象機器に紐づいている機種は選択できません
          </Typography>
          <RelinkConfirmationMessage messageStyle={{margin: '32px 8px'}} />
        </DialogContent>
        <DialogActions sx={{margin: '8px 32px 32px'}}>
          <Button
            variant={'contained'}
            color="primary"
            onClick={() => actions.resolve(selectedWholeProductHashId)}
            disabled={!selectedWholeProductHashId}
            sx={{backgroundColor: '#0052CC'}}>
            再紐付けする
          </Button>
          <Button onClick={() => actions.reject()} sx={{color: `#0052CC !important`, backgroundColor: '#fffff'}}>
            キャンセル
          </Button>
        </DialogActions>
      </Suspense>
    </Dialog>
  );
};
