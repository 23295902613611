import {classNameOpts} from '@Apps/ProductRegistration/constants';
import {WholeProductFormValue} from './hooks';

type BaseField = {
  label: string;
  name: keyof WholeProductFormValue;
  disabled?: boolean;
};

type TextField = BaseField & {
  type: 'text' | 'number';
};

type SelectField = BaseField & {
  type: 'select';
  options: Array<{label: string; value: string}>;
};

// NOTE:AutoCompleteFieldでdisabledが必要であれば型を修正しform/FieldFactory.tsxのAutoCompleteFieldを修正する
type AutoCompleteField = Omit<BaseField, 'disabled'> & {
  type: 'auto_complete';
};

export type FormLayoutField = TextField | SelectField | AutoCompleteField;

export const wholeProductDetailFormFields: FormLayoutField[] = [
  {type: 'auto_complete', label: '大分類', name: 'rootCategory'},
  {type: 'auto_complete', label: '小分類', name: 'narrowCategory'},
  {type: 'auto_complete', label: 'メーカー名', name: 'maker'},
  {type: 'text', label: '機種名', name: 'displayName'},
  {type: 'text', label: '型式', name: 'name'},
  {type: 'text', label: '承認番号', name: 'approvalNumber'},
  {type: 'text', label: 'JMDNコード', name: 'jmdnCode'},
  {type: 'text', label: 'JANコード', name: 'janCode'},
  {type: 'text', label: 'GTINコード', name: 'newJanCode', disabled: true},
  {
    type: 'select',
    label: '特定保守製品',
    name: 'specificMaintain',
    options: [
      {label: '該当', value: 'apply'},
      {label: '非該当', value: 'not_apply'},
    ],
  },
  {
    type: 'select',
    label: 'クラス分類',
    name: 'className',
    options: classNameOpts,
  },
  {type: 'number', label: '定価', name: 'catalogPrice'},
];
