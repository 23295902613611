import {useReducer} from 'react';
import dayjs from 'dayjs';
import {useHospitalUserStore} from '@modules/hospital_users/hooks/useHospitalUserStore';
import {HospitalUserState, UserIndex} from '@modules/hospital_users/types';
import {getInspectionList, FetchInspectionListParams, FetchInspectionListResult} from '@modules/inspections/api';
import {createInspectionResult, updateInspectionResult} from '@modules/inspection_results/api';
import {useInspectionResultStore} from '@modules/inspection_results/hooks';
import {InspectionResultState, InspectionResultIndex} from '@modules/inspection_results/types';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {inspectionInitialState, inspectionReducer} from '../reducers';
import {InspectionType, InspectionState, InspectionActionType} from '../types';

type InspectionStartMenuStoreType = {
  myInfo: UserIndex;
  inspectionResultState: InspectionResultState;
  listInspectionResults: (hospitalProductHashId: string, type: InspectionType) => Promise<void>;
  createAdhocInspectionResult: (
    hospitalProductHashId: string,
    inspectionHashId: string,
    inspectorHashId: string,
    scheduledTime: string
  ) => Promise<InspectionResultIndex>;
  skipInspectionResult: (
    inspectionHashId: string,
    inspectionResultHashId: string,
    skippedTime: string,
    selectedSkippedByHashId: string,
    skipReason: string
  ) => Promise<void>;
  updateInspectionResultScheduledTime: (
    inspectionHashId: string,
    inspectionResultHashId: string,
    scheduledTime: string
  ) => Promise<void>;
  hospitalUserState: HospitalUserState;
  listHospitalUsers: () => Promise<void>;
  inspectionState: InspectionState;
  listInspections: (type: InspectionType, wholeProductHashId?: string, name?: string) => Promise<void>;
};

export const useInspectionStartMenuStore = (): InspectionStartMenuStoreType => {
  const {myInfo} = useMyInfo();
  const [inspectionState, dispatchInspection] = useReducer(inspectionReducer, inspectionInitialState);
  const {state: inspectionResultState, dispatchListInspectionResults} = useInspectionResultStore();
  const {state: hospitalUserState, dispatchListHospitalUsers} = useHospitalUserStore();

  const listInspectionResults = async (hospitalProductHashId: string, type: InspectionType) => {
    if (!hospitalProductHashId) return;

    await dispatchListInspectionResults(myInfo.hospitalHashId, 'none', {
      hospitalProductHashId,
      types: type,
      statuses: 'unplanned,uncompleted',
      order: 'scheduledTime',
      scheduledAtTo: type === 'periodic' ? dayjs(new Date()).add(2, 'month').endOf('month').toISOString() : undefined,
    });
  };

  const createAdhocInspectionResult = async (
    hospitalProductHashId: string,
    inspectionHashId: string,
    inspectorHashId: string,
    scheduledTime: string
  ) => {
    const res = await createInspectionResult(myInfo.hospitalHashId, inspectionHashId, {
      hospitalProductHashId,
      category: 'in_hospital',
      scheduledTime,
      inspectorHashId,
      status: 'draft',
      items: {},
      isAdhoc: true,
    });
    return res.data;
  };

  const skipInspectionResult = async (
    inspectionHashId: string,
    inspectionResultHashId: string,
    skippedTime: string,
    skippedByHashId: string,
    skipReason: string
  ) => {
    const status = 'skipped';
    const reason = skipReason.length > 0 ? skipReason : undefined; // NOTE: 空文字でリクエストするとAPIからエラーが返るため、空文字の場合はundefinedに変換する

    await updateInspectionResult(myInfo.hospitalHashId, inspectionHashId, inspectionResultHashId, {
      status,
      skippedByHashId,
      skippedTime,
      skipReason: reason,
    });
  };

  const updateInspectionResultScheduledTime = async (
    inspectionHashId: string,
    inspectionResultHashId: string,
    scheduledTime: string
  ) => {
    await updateInspectionResult(myInfo.hospitalHashId, inspectionHashId, inspectionResultHashId, {scheduledTime});
  };

  const listHospitalUsers = async () => {
    const initialParams = {page: 0, perPage: 100}; // NOTE: 点検画面の点検開始時と同じく `page: 0, perPage: 100` を指定する
    await dispatchListHospitalUsers(myInfo.hospitalHashId, initialParams);
  };

  const listInspections = async (type: InspectionType, wholeProductHashId?: string, name?: string) => {
    if (name === '') name = undefined;
    if (wholeProductHashId === '') wholeProductHashId = undefined;

    // NOTE: 点検画面の点検開始時と同じく `statuses: 'available', perPage: 100` を指定する
    const initialParams: FetchInspectionListParams = {statuses: 'available', perPage: 100};
    const params: FetchInspectionListParams = {
      ...initialParams,
      inspectionType: type !== 'adhoc' ? type : undefined,
      name,
    };

    const promises: Promise<FetchInspectionListResult>[] = [];

    // 「全ての点検表」を取得
    promises.push(getInspectionList(myInfo.hospitalHashId, params));
    if (wholeProductHashId === undefined) {
      const [allRes] = await Promise.all(promises);
      dispatchInspection({
        type: InspectionActionType.INSPECTION_SET_LIST,
        payload: {allInspections: allRes.data, wholeProductInspections: []},
      });
      return;
    }

    // 「機種に紐づく点検表」を取得
    promises.push(getInspectionList(myInfo.hospitalHashId, {...params, wholeProductHashId}));

    const [allRes, wholeProdRes] = await Promise.all(promises);
    const wholeProdInspectionHashIds = wholeProdRes.data.map((i) => i.hashId);
    dispatchInspection({
      type: InspectionActionType.INSPECTION_SET_LIST,
      payload: {
        allInspections: allRes.data.filter((i) => !wholeProdInspectionHashIds.includes(i.hashId)),
        wholeProductInspections: wholeProdRes.data,
      },
    });
  };

  return {
    myInfo,
    inspectionResultState,
    listInspectionResults,
    createAdhocInspectionResult,
    skipInspectionResult,
    updateInspectionResultScheduledTime,
    hospitalUserState,
    listHospitalUsers,
    inspectionState,
    listInspections,
  };
};
