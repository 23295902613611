import {ButtonProps} from '@material-ui/core';
import React, {useState, useCallback, useEffect, useMemo} from 'react';
import {PopperSelectBox} from './PopperSelectBox';
import {OpenerButton} from './OpenerButton';

type MultipleSelectBox = {
  /**
   * セレクトボックスでの選択を複数選択可能にするかどうか
   */
  isMulti: true;

  /**
   * セレクトボックスで全て選択ボタンを表示するかどうか
   * @default false
   */
  allowSelectAll?: boolean;

  /**
   * セレクトボックスで初期値を設定する場合のOptionのリスト。
   * 単一か複数かによって型が変わる。
   *
   * @default null
   */
  initialOption?: SelectOptionProps[];

  /**
   * セレクトボックスの値が変化した場合のコールバック関数
   */
  onChange: (selectedValues?: SelectOptionProps[], selectValue?: SelectOptionProps) => void;
};

type SingleSelectBox = {
  /**
   * セレクトボックスでの選択を複数選択可能にするかどうか
   */
  isMulti: false;

  /**
   * セレクトボックスで全て選択ボタンを表示するかどうか
   */
  allowSelectAll?: false;

  /**
   * セレクトボックスで初期値を設定する場合のOptionのリスト。
   * 単一か複数かによって型が変わる。
   *
   * @default null
   */
  initialOption?: SelectOptionProps;

  /**
   * セレクトボックスの値が変化した場合のコールバック関数
   */
  onChange: (selectValue?: SelectOptionProps) => void;
};

export type SelectOptionProps = {
  value: string;
  label: string | React.ReactNode;
  subLabel?: string;
  searchAlias?: string[];
};

export type PopperSelectBoxProps = {
  /**
   * ボタンのラベル名
   */
  buttonLabel: string;

  /**
   * ボタンのラベルの末端に矢印を表示するかどうか
   * @default false
   */
  hiddenArrow?: boolean;

  /**
   * Material UIのボタンのPropsをオーバーライドする場合に指定
   */
  buttonProps?: ButtonProps;

  /**
   * セレクトボックスのオプション一覧
   */
  options: SelectOptionProps[];

  /**
   * セレクトボックスに検索を可能にするかどうか
   * @default false
   */
  searchable?: boolean;

  /**
   * セレクトボックスの検索用のテキストボックスのプレースホルダー
   * @default 検索
   */
  searchLabel?: string;

  /**
   * セレクトボックスの選択されたvalueをリセットするかどうか
   * @default false
   */
  resetValue?: boolean;
  /**
   * セレクトボックスが開いた場合のコールバック
   */
  onOpen?: () => void;

  /**
   * セレクトボックスが閉じた場合のコールバック
   */
  onClose?: () => void;

  /**
   * セレクトボックスの検索欄が変更された場合のコールバック
   */
  onInputChange?: (inputValue: string) => void;
} & (MultipleSelectBox | SingleSelectBox);

// FIXME: オプション検索のfilterロジックの修正要（DOMの中を捜査する、labelの中身みる、aliasの中身見る）
export const PopperSelectBoxButton: React.FC<PopperSelectBoxProps> = ({
  buttonLabel,
  onOpen,
  onClose,
  options,
  isMulti,
  allowSelectAll = false,
  onChange,
  buttonProps,
  initialOption = null,
  searchable = false,
  hiddenArrow = false,
  searchLabel = '検索',
  resetValue = false,
  onInputChange,
}) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [value, setValue] = useState<SelectOptionProps | SelectOptionProps[] | null>(initialOption);

  useEffect(() => {
    if (initialOption) setValue(initialOption);
  }, [initialOption]);

  useEffect(() => {
    if (open) onOpen && onOpen();
    else onClose && onClose();
  }, [onClose, onOpen, open]);

  // FIX ME: あんまりuseEffectで頑張るのは良くないので、どこかでvalueのstateをページ側に持っていく。
  useEffect(() => {
    if (resetValue) setValue(null);
  }, [resetValue]);

  const toggleMenu = () => setOpen((_open) => !_open);

  const handleClose = useCallback(
    (e?: React.MouseEvent<Document, MouseEvent>) => {
      if (e) e.stopPropagation();
      if (!open) return;
      setOpen(false);
    },
    [open]
  );
  const handleSelectChange = useCallback(
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    (val: any) => {
      setValue(val);
      if (isMulti) {
        onChange(val, val);
      } else {
        // 単一選択の場合は、選択されたら即時でPopperを閉じる
        onChange(val);
        setOpen(false);
      }
    },
    [isMulti, onChange]
  );

  const popperDom = useMemo(
    () => (
      <PopperSelectBox
        open={open}
        anchorEl={anchorEl}
        isMulti={isMulti}
        allowSelectAll={allowSelectAll}
        searchable={searchable}
        searchLabel={searchLabel}
        options={options}
        onSelectChange={handleSelectChange}
        value={value}
        onInputChange={onInputChange}
      />
    ),
    [
      allowSelectAll,
      anchorEl,
      handleSelectChange,
      isMulti,
      onInputChange,
      open,
      options,
      searchLabel,
      searchable,
      value,
    ]
  );

  const handleClickButton = useCallback((_anchorEl: HTMLElement) => {
    setAnchorEl(_anchorEl);
    toggleMenu();
  }, []);

  const handleClear = useCallback(() => {
    handleSelectChange(null);
  }, [handleSelectChange]);

  return (
    <OpenerButton
      value={value}
      hiddenArrow={hiddenArrow}
      buttonLabel={buttonLabel}
      buttonProps={buttonProps}
      options={options}
      onOpenSelectBox={handleClickButton}
      onClose={handleClose}
      onClear={handleClear}
      isMulti={isMulti}
      allowSelectAll={allowSelectAll}>
      {popperDom}
    </OpenerButton>
  );
};
