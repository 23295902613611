import React from 'react';
import {Box} from '@mui/material';
import {RentalHistoryIndex} from '@modules/rentals/types';
import {
  BaseSharedListTable,
  BaseSharedListTableColumn,
  tableTitleStyle,
} from '@Apps/BaseSharedMenu/BaseSharedListTable';
import {CancelButton} from '@Apps/BaseSharedMenu/CancelButton';

type RowData = {
  managementId: string;
  cancelButton: JSX.Element | null;
};

const columns: BaseSharedListTableColumn<RowData>[] = [
  {id: 'managementId', label: '管理番号', minWidth: '140px'},
  {id: 'cancelButton', label: '', minWidth: 'fit-content', sx: {width: 'fix-content'}},
];

export type TableProps = {
  returnHospitalProducts: RentalHistoryIndex[];
  onClickCancel: (rowIndex: number) => void;
};

export const ScannedProductTable: React.FC<TableProps> = ({returnHospitalProducts, onClickCancel}) => {
  const tableRows = returnHospitalProducts.map(({rentalHospitalProduct}, index) => ({
    managementId: rentalHospitalProduct.managementId ?? '',
    cancelButton: rentalHospitalProduct.managementId ? (
      <CancelButton
        onClickCancel={() => {
          onClickCancel(index);
        }}
      />
    ) : null,
  }));

  return (
    <>
      <Box sx={tableTitleStyle}>返却する医療機器リスト</Box>
      <BaseSharedListTable<RowData> tableRows={tableRows} columns={columns} />
    </>
  );
};
