import React, {useCallback, useLayoutEffect, useRef} from 'react';
import {styled, Box} from '@mui/material';
import Quagga, {QuaggaJSResultCallbackFunction, QuaggaJSConfigObject} from '@ericblade/quagga2';
import {getMedianOfCodeErrors} from '@front-libs/helpers';
import QrCodeReader from './QrCodeReader';
import {QrCodeReaderName, Config} from './constants';

type Props = {
  width?: number;
  height?: number;
  onInputData: (barcode: string) => void;
};

const VideoContainer = styled(Box)({
  position: 'relative',
  width: '100%',
  '& > video': {
    width: '100%',
  },
});

const Canvas = styled('canvas')({
  position: 'absolute',
  top: '0px',
  left: '0px',
  height: '100%',
  width: '100%',
});

Quagga.registerReader(QrCodeReaderName, QrCodeReader);

export const useBarcodeReadCamera = ({width, height, onInputData}: Props) => {
  const videoRef = useRef<HTMLDivElement>(null);

  const createQuaggaConfig = useCallback(
    (): QuaggaJSConfigObject => ({
      inputStream: {
        type: Config.defaultInputStreamType,
        target: videoRef.current ?? undefined,
        constraints: {
          width: width,
          height: height,
          facingMode: Config.defaultFacingMode,
        },
      },
      locator: Config.defaultLocatorSettings,
      numOfWorkers: navigator.hardwareConcurrency,
      decoder: {readers: Config.defaultDecodeReaders},
      locate: Config.defaultLocate,
    }),
    [width, height]
  );

  /** コード検出中に表示するボックスの描画 */
  const drawBoxesPath = useCallback(
    (boxes: number[][][], canvas: HTMLCanvasElement, canvasCtx: CanvasRenderingContext2D, excludeBox?: number[][]) => {
      const w = parseInt(canvas.getAttribute('width') ?? '0');
      const h = parseInt(canvas.getAttribute('height') ?? '0');
      canvasCtx.clearRect(0, 0, w, h);
      boxes
        .filter((box) => box !== excludeBox)
        .forEach((box) => {
          Quagga.ImageDebug.drawPath(box, {x: 0, y: 1}, canvasCtx, Config.boxesPathStyle);
        });
    },
    []
  );

  /** コード検出時に表示するボックスの描画 */
  const drawBoxPath = useCallback((box: number[][], canvasCtx: CanvasRenderingContext2D) => {
    Quagga.ImageDebug.drawPath(box, {x: 0, y: 1}, canvasCtx, Config.boxPathStyle);
  }, []);

  /** カメラでコードを検出中のハンドリング */
  const handleProcessing: QuaggaJSResultCallbackFunction = useCallback(
    (result) => {
      if (!result) return; // NOTE: resultがnullの場合がある

      const drawingCanvas = Quagga.canvas.dom.overlay;
      const drawingCtx = Quagga.canvas.ctx.overlay;
      if (result.boxes) drawBoxesPath(result.boxes, drawingCanvas, drawingCtx, result.box); // NOTE: boxesがundefinedの場合がある
      if (result.box) drawBoxPath(result.box, drawingCtx); // NOTE: boxがundefinedの場合がある

      if (result?.codeResult?.code) onInputData(result.codeResult.code); // 検出中にコードを取得できた場合
    },
    [drawBoxesPath, drawBoxPath, onInputData]
  );

  /** カメラでコードを検出時のハンドリング */
  const handleDetected: QuaggaJSResultCallbackFunction = useCallback(
    (result) => {
      if (!result.codeResult.decodedCodes) return; // NOTE: decodedCodesがundefinedの場合がある

      const err = getMedianOfCodeErrors(result.codeResult.decodedCodes);
      if (err >= Config.defaultDecodeErrorThreshold) return;
      if (!result.codeResult.code) return;

      onInputData(result.codeResult.code);
    },
    [onInputData]
  );

  useLayoutEffect(() => {
    const quaggaConfig = createQuaggaConfig();

    Quagga.init(quaggaConfig, (err) => {
      if (err) {
        console.error('Error on Quagga.init:', err);
        return;
      }
      if (!videoRef?.current) return;

      Quagga.start();
    });
    Quagga.onProcessed(handleProcessing);
    Quagga.onDetected(handleDetected);

    return () => {
      Quagga.offDetected(handleDetected);
      Quagga.offProcessed(handleProcessing);
      Quagga.stop();
    };
  }, [
    videoRef,
    // NOTE: useCallbackなどを利用しないと、何度もuseLayoutEffectが走り、Quaggaが複数回initされたりcleanupされるので注意。(カメラが止まらなくなったりする原因になる。)
    createQuaggaConfig,
    handleDetected,
    handleProcessing,
  ]);

  const BarcodeInput = (
    <VideoContainer ref={videoRef}>
      <Canvas className="drawingBuffer" />
    </VideoContainer>
  );

  return {
    BarcodeInput,
  };
};
