import React, {useEffect, useState} from 'react';
import {styled, Box} from '@mui/material';
import {ScheduleStatus} from '@modules/training_schedules/types';
import {Tabs} from './Tabs';
import {List} from './List';
import {initTabValue} from './constants';
import {useTrainingSchedulesStore} from './hooks';

type Props = {
  wholeProductHashId: string;
};

const Container = styled(Box)({
  position: 'absolute',
  left: 0, // NOTE: 機種詳細画面の右ペインに幅を合わせるため、右ペインからの絶対位置を指定している。
  width: '100%',
  color: '#172B4D',
});

const TrainingSchedules = ({wholeProductHashId}: Props) => {
  const [tabValue, setTabValue] = useState<ScheduleStatus>(initTabValue);
  const [loading, setLoading] = useState(true);
  const {incompleteScheduleState, doneScheduleState, listIncompleteSchedules, listDoneSchedules} =
    useTrainingSchedulesStore();

  const loadSchedules = async () => {
    setLoading(true);
    await Promise.all([listIncompleteSchedules(wholeProductHashId), listDoneSchedules(wholeProductHashId)]);
    setLoading(false);
  };

  useEffect(() => {
    loadSchedules();
  }, []);

  const schedules = () => {
    switch (tabValue) {
      case 'incomplete':
        return incompleteScheduleState.schedules;
      case 'done':
        return doneScheduleState.schedules;
      default:
        return [];
    }
  };

  const handleChange = (newValue: ScheduleStatus) => {
    setTabValue(newValue);
  };

  const render = () => {
    if (loading) return null;

    return (
      <Container>
        <Tabs
          value={tabValue}
          incompleteCount={incompleteScheduleState.schedules.length}
          doneCount={doneScheduleState.schedules.length}
          onChange={handleChange}
        />
        <List schedules={schedules()} />
      </Container>
    );
  };

  return render();
};

export default TrainingSchedules;
