import React, {useMemo} from 'react';
import {Link} from 'react-router-dom';
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputAdornment,
  SxProps,
  Theme,
  Typography,
  styled,
} from '@mui/material';
import {Sidebar} from '@components/organisms/Sidebar';
import {Form, Formik, useFormikContext} from 'formik';
import {InnerLoading} from '@molecules/Loading';
import {FormikFormSubmitDrawer} from '@molecules/Formik/FormSubmitDrawer';
import {withSuspense} from '@front-libs/core';
import {Inspection} from '@modules/inspection/types';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {updateHospitalSettings, useFetchHospitalSettingsQuery} from '@modules/hospital_settings/api';
import {
  inspectionTypeTokAllowApplyPreviousInspectionResultSettings,
  allowTypeStatusKey,
  allowUpdateCompletedInspectionResultStatusKey,
  automationCreateStatusKey,
  HospitalSettingKey,
  UpdateHospitalSettingsParams,
} from '@modules/hospital_settings/types';
import {Checkbox} from '@atoms/Checkbox';
import {openSnackBar} from '@molecules/SnackBar';
import {InspectionTypeOptions} from '@modules/inspections/enum';
import {TextField} from '@molecules/Formik/fields';
import {extractJointingPostUseInspectionSettings} from '@modules/hospital_settings/hooks';
import {getSettingsContentTemplate as templateClasses} from '@components/templates/ContentLayout/InnerSidebarContentLayoutV5';
import {LabeledCheckbox} from '@molecules/LabeledCheckbox';

const applyPreviousInspectionResultTypeOptions = [
  {
    label: '日常点検',
    value: 'daily',
  },
  ...InspectionTypeOptions,
] as const;

const LinkStyled = styled(Link)(({theme}) => ({
  color: theme.palette.secondary.dark,
  textDecoration: 'none',
  marginLeft: 0,
}));

const SubTitle: SxProps<Theme> = {
  fontWeight: 'bold',
  marginTop: 4,
  marginBottom: 4,
};

const _SettingsHospitalInspection: React.FC = () => {
  return (
    <Grid container sx={templateClasses.grid}>
      <InspectionContainer>
        <InspectionForm />
      </InspectionContainer>
    </Grid>
  );
};

type InspectionContainerProps = {
  children: React.ReactNode;
};

const InspectionContainer = ({children}: InspectionContainerProps) => {
  const {myInfo} = useMyInfo();
  const {data, isLoading, refetch} = useFetchHospitalSettingsQuery(myInfo.hospitalHashId);

  const initialData: Inspection | undefined = useMemo(() => {
    if (isLoading || !data) return undefined;

    const jointingPostUseInspectionSetting = extractJointingPostUseInspectionSettings(data);

    return {
      hashId: myInfo.hospitalHashId,
      allowTypeStatus: data?.data?.find((x) => x.key === allowTypeStatusKey)?.value === 'true',
      automationCreateStatus: data?.data?.find((x) => x.key === automationCreateStatusKey)?.value === 'true',
      allowApplyPreviousInspectionResultDaily:
        data?.data?.find((x) => x.key === inspectionTypeTokAllowApplyPreviousInspectionResultSettings.daily.key)
          ?.value === 'true',
      allowApplyPreviousInspectionResultPreUse:
        data?.data?.find((x) => x.key === inspectionTypeTokAllowApplyPreviousInspectionResultSettings.pre_use.key)
          ?.value === 'true',
      allowApplyPreviousInspectionResultInUse:
        data?.data?.find((x) => x.key === inspectionTypeTokAllowApplyPreviousInspectionResultSettings.in_use.key)
          ?.value === 'true',
      allowApplyPreviousInspectionResultPostUse:
        data?.data?.find((x) => x.key === inspectionTypeTokAllowApplyPreviousInspectionResultSettings.post_use.key)
          ?.value === 'true',
      allowApplyPreviousInspectionResultPeriodic:
        data?.data?.find((x) => x.key === inspectionTypeTokAllowApplyPreviousInspectionResultSettings.periodic.key)
          ?.value === 'true',
      allowUpdateCompletedInspectionResult:
        data?.data?.find((x) => x.key === allowUpdateCompletedInspectionResultStatusKey)?.value === 'true',
      allowJointingPostUseInspectionResult: jointingPostUseInspectionSetting.allowed,
      periodForJointingPostUseInspectionResult: jointingPostUseInspectionSetting.period,
    };
  }, [data, isLoading, myInfo.hospitalHashId]);

  if (isLoading || initialData === undefined) {
    return <InnerLoading />;
  }

  const handleSubmit = async (res: Inspection) => {
    const keys: (keyof Inspection)[] = Object.keys(initialData) as (keyof Inspection)[];
    const updatedKeys = keys.filter((item: keyof Inspection): item is keyof Omit<Inspection, 'hashId'> => {
      return initialData[item] !== res[item];
    });

    try {
      const updatedData: UpdateHospitalSettingsParams[] = updatedKeys.map((key: string) => {
        return {
          hospitalHashId: res.hashId,
          key: key.replace(/[A-Z]/g, (s) => `_${s.toLowerCase()}`) as HospitalSettingKey,
          value: `${res[key as keyof Inspection]}`,
        };
      });
      await Promise.all(
        updatedData.map((d) => {
          return updateHospitalSettings(res.hashId, d);
        })
      );
      await refetch();
      openSnackBar('点検の設定を更新しました');
    } catch (error) {
      openSnackBar('点検の設定の更新に失敗しました', 'left', 'bottom', 'error');
      throw error;
    }
  };

  return (
    <Formik initialValues={initialData} onSubmit={handleSubmit} enableReinitialize={true}>
      {children}
    </Formik>
  );
};

const InspectionForm = () => {
  const {values, setFieldValue} = useFormikContext<Inspection>();

  return (
    <Box sx={templateClasses.form}>
      <Form>
        <Grid container sx={templateClasses.grid}>
          <Grid item sx={templateClasses.sideBar}>
            <Sidebar />
          </Grid>
          <Grid item sx={{...templateClasses.content, height: 'auto'}}>
            <Grid container>
              <Grid item>
                <Typography variant={'h5'} sx={templateClasses.pageTitle}>
                  点検
                </Typography>
                <p>点検に関するユーザー共通設定を管理します。</p>
              </Grid>
            </Grid>
            <Divider variant="middle" sx={templateClasses.divider} />
            {/* 各セクション */}
            <Grid container>
              <Grid item>
                <Typography variant="h6" sx={SubTitle}>
                  セットアップ
                </Typography>
                <FormControl component="fieldset">
                  <Typography sx={templateClasses.pageSubTitle2}>
                    <LinkStyled to="/inspection_v2/tables">点検表フォーマットの設定</LinkStyled>
                  </Typography>
                  <FormHelperText sx={{mt: 0, ml: 0}}>
                    点検表フォーマットに関するユーザー共通設定を管理します。
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item>
                <Typography variant="h6" sx={{...SubTitle, mb: 0}}>
                  点検の一括完了
                </Typography>
                <Typography variant="body1" sx={{fontSize: '14px', mb: 3}}>
                  点検タイプ毎に未実施点検の一括完了を有効化します
                </Typography>
                <FormControl component="fieldset">
                  <FormGroup>
                    {applyPreviousInspectionResultTypeOptions.map(({label, value}) => {
                      const {field} = inspectionTypeTokAllowApplyPreviousInspectionResultSettings[value];
                      // 日常点検の場合はそれぞれどれかがfalseの場合は強制的にfalseにする
                      const disabled =
                        value === 'daily' &&
                        (['pre_use', 'in_use', 'post_use'] as const).some((value) => {
                          const {field} = inspectionTypeTokAllowApplyPreviousInspectionResultSettings[value];
                          return values[field] === false;
                        });

                      return (
                        <FormControlLabel
                          data-testid={`inspection-type-to-allow-apply-previous-inspection-result-settings-${value}`}
                          key={value}
                          disabled={disabled}
                          checked={values[field]}
                          onChange={() => setFieldValue(field, !values[field])}
                          control={<Checkbox color="secondary" disableRipple={false} />}
                          label={label}
                        />
                      );
                    })}
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item>
                <Typography variant="h6" sx={SubTitle}>
                  機能の有効化
                </Typography>
                <LabeledCheckbox
                  label={'完了済みの点検結果の編集を許可'}
                  subLabel={'点検完了後に点検結果の編集を可能にします。'}
                  checked={values.allowUpdateCompletedInspectionResult}
                  onChange={() =>
                    setFieldValue('allowUpdateCompletedInspectionResult', !values.allowUpdateCompletedInspectionResult)
                  }
                  data-testid={'allow-update-completed-inspection-result'}
                />
              </Grid>
            </Grid>
            <Grid container sx={{marginTop: '24px'}}>
              <Grid item>
                <Typography variant="h6" sx={SubTitle}>
                  自動化
                </Typography>
                <LabeledCheckbox
                  label={'返却後に使用後点検予定を自動作成'}
                  subLabel={'点検表を紐づけた機器を返却した場合、使用後点検の予定を自動で作成します。'}
                  checked={values.automationCreateStatus}
                  onChange={() => setFieldValue('automationCreateStatus', !values.automationCreateStatus)}
                  data-testid={'automation-create-status'}
                />
              </Grid>
            </Grid>
            <Grid sx={{mt: 2}}>
              <LabeledCheckbox
                label={'定期点検完了時に未実施の使用後点検をスキップする'}
                subLabel={
                  '使用後点検を開始する際に、期間内に予定されている定期点検がある場合は定期点検完了時に使用後点検をスキップします'
                }
                checked={values.allowJointingPostUseInspectionResult}
                onChange={() =>
                  setFieldValue('allowJointingPostUseInspectionResult', !values.allowJointingPostUseInspectionResult)
                }
                data-testid={'allow-jointing-postuse-inspection-result'}
              />
              <Box sx={{width: '150px', mt: 1, ml: 2}}>
                <TextField
                  data-testid={'period-for-jointing-postuse-inspection-result'}
                  disabled={!values.allowJointingPostUseInspectionResult}
                  name="periodForJointingPostUseInspectionResult"
                  type="number"
                  size="small"
                  InputProps={{
                    inputProps: {
                      min: 1,
                    },
                    endAdornment: <InputAdornment position="end">日以内</InputAdornment>,
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <FormikFormSubmitDrawer />
      </Form>
    </Box>
  );
};

export const SettingsHospitalInspection = withSuspense(_SettingsHospitalInspection, null);
