import {Box, Grid, makeStyles} from '@material-ui/core';
import React from 'react';
import {ProductIndex} from '@modules/products/types';
import {CategoryFormatter} from '@modules/categories/helpers';

export const ProductCardHeaderHeight = '80px';

type Props = {
  product: ProductIndex;
};

export const ProductCardHeader: React.FC<Props> = (props) => {
  const {product} = props;
  const classes = useStyles();

  const rootCategory = CategoryFormatter.getRootCategory(product.categories)?.name ?? '';
  const narrowCategory = CategoryFormatter.getNarrowCategory(product.categories)?.name ?? '';

  return (
    <Box className={classes.box}>
      <Grid container className={classes.container}>
        <Grid item style={{fontSize: 14}}>
          {product.displayName}
        </Grid>
        <Grid item>
          {rootCategory} {'>'} {narrowCategory}
        </Grid>
      </Grid>
      {/* FIXME PMDAやメーカーHPのデータが整い次第表示。 */}
      {/* <MenuInfo /> */}
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  box: {
    boxSizing: 'border-box',
    height: ProductCardHeaderHeight,
    width: '100%',
    padding: '16px 0px 0px 18px',
    backgroundColor: 'white',
    display: 'flex',
  },
  container: {
    flexDirection: 'column',
    fontSize: '12px',
  },
  tooltip: {
    cursor: 'pointer',
    padding: '4px',
  },
}));
