import React from 'react';
import {Rentables} from './Rentables';
import {InspectionCalendar} from './InspectionCalendar';
import {PapersObjType, PapersType} from './types';
import {RentalHistoryTable} from './RentalHistoryTable';
import {InspectionProgressByType} from './UncompletedInspectionByType';
import {MonthlyInspectionsTable} from './MonthlyInspectionsTable';
import {UncompletedInspectionByCategory} from './UncompletedInspectionByCategory';
import {ProductCommentList} from './ProductCommentList';
import {NonReturnedRentalProductsTable} from './NonReturnedRentalProductsTable';
import {NoticeBoard} from './NoticeBoard';
import {RepairCommentList} from './RepairCommentList';

export const papers: PapersObjType = {
  noticeBoard: {
    label: 'お知らせ',
    value: 'notice_board',
    component: <NoticeBoard />,
    settingMenuItems: [],
  },
  productCommentList: {
    label: '各機器のピンどめコメント一覧',
    value: 'product_comment_list',
    component: <ProductCommentList />,
    settingMenuItems: ['changeLayout'],
  },
  repairCommentList: {
    label: '各修理のピンどめコメント一覧',
    value: 'repair_comment_list',
    component: <RepairCommentList />,
    settingMenuItems: ['changeLayout'],
  },
  inspectionList: {
    label: '今月の点検リスト',
    value: 'inspection_list',
    component: <MonthlyInspectionsTable />,
    settingMenuItems: ['changeLayout'],
  },
  monthlyInspectionPercent: {
    label: '今月の点検未実施割合',
    value: 'monthly_inspection_percent',
    component: <InspectionProgressByType durationType="monthly" inspectionTypes={['post_use', 'periodic']} />,
  },
  yearlyInspectionPercent: {
    label: '年間の点検未実施割合',
    value: 'yearly_inspection_percent',
    component: <InspectionProgressByType durationType="yearly" inspectionTypes={['periodic']} />,
  },
  inspectionPercentByProduct: {
    label: '機器分類別の年間点検未実施割合',
    value: 'inspection_percent_by_product',
    component: <UncompletedInspectionByCategory />,
  },
  inspectionCalendar: {
    label: '定期点検年間カレンダー',
    value: 'inspection_calendar',
    component: <InspectionCalendar />,
  },
  rentables: {
    label: '貸出可能数・貸出率',
    value: 'rentables',
    component: <Rentables />,
  },
  nonReturnedRentalProductsTable: {
    label: '長期間未返却リスト',
    value: 'non_returned_rental_products_table',
    component: <NonReturnedRentalProductsTable />,
    settingMenuItems: ['changeLayout'],
  },
  rentalHistory: {
    label: '48時間以内の貸出・返却履歴',
    value: 'rental_history',
    component: <RentalHistoryTable />,
    settingMenuItems: ['changeLayout'],
  },
};

export const paperTypes: PapersType[] = [
  'notice_board',
  'product_comment_list',
  'repair_comment_list',
  'inspection_list',
  'monthly_inspection_percent',
  'yearly_inspection_percent',
  'inspection_percent_by_product',
  'inspection_calendar',
  'rentables',
  'non_returned_rental_products_table',
  'rental_history',
];
