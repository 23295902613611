import {TextField, styled} from '@material-ui/core';
import React, {useCallback, useRef} from 'react';
import {FileUploadIconButton} from '@molecules/Buttons/FileUploadIconButton';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {openSnackBar} from '@molecules/SnackBar';
import {uploadFile} from '@modules/files/api';
import {Search} from '@material-ui/icons';
import {FileListItem} from '@molecules/MobileFileListItem';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useFetchHospitalProductFiles} from '@modules/hospital_products/hooks/useHospitalProductFile';
import {HospitalProductIndex} from '@modules/hospital_products/types';
import {UploadBulkDocumentDialog} from '@organisms/Files/FileList/UploadBulkDocumentDialog';
import {createHospitalProductFiles} from '@modules/hospital_products/api';
import {useMyRole} from '@modules/hospital_users/hooks/useMyRole';

const RootContainer = styled('div')({
  margin: '16px 32px',
  flexGrow: 1,
});

const FileHeader = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const FileContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  margin: '16px 0',
  overflow: 'auto',
  maxHeight: 'calc(100vh - 300px)',
});

type Props = {
  hospitalProduct: HospitalProductIndex;
};

export const ProductFileTab = ({hospitalProduct}: Props) => {
  const {myInfo} = useMyInfo();
  const {isAdmin, isReadOnly} = useMyRole();
  const isFileSharingAcrossProducts = useRef(false);
  const [searchName, setSearchName] = React.useState<string>('');
  // ファイル取得
  const {data: files, refetch} = useFetchHospitalProductFiles(myInfo.hospitalHashId, hospitalProduct.hashId, {
    searchName: searchName,
    order: '-createdAt',
    perPage: 50,
  });

  const handleSearchNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchName(e.target.value);
  }, []);

  const handleUploadDocument = useCallback(async () => {
    const fileData = await dialogHandler.open(UploadBulkDocumentDialog, {
      setIsFileSharingAcrossProducts: isFileSharingAcrossProducts,
    });

    try {
      const newFile = await uploadFile({
        ...fileData,
        category: 'general',
      });
      await createHospitalProductFiles(myInfo.hospitalHashId, hospitalProduct.hashId, {
        fileHashIds: [newFile.data.hashId],
        isFileSharingAcrossProducts: isFileSharingAcrossProducts.current,
      });
      refetch();
    } catch (_e) {
      openSnackBar('ファイルのアップロードに失敗しました', 'left', 'bottom', 'error');
    }
  }, [hospitalProduct.hashId, myInfo.hospitalHashId, refetch]);
  const handleRefetch = () => refetch();

  return (
    <RootContainer>
      <FileHeader>
        {!isReadOnly && <FileUploadIconButton onUploadFile={handleUploadDocument} />}
        <TextField
          label={'ファイル名を検索'}
          variant={'outlined'}
          fullWidth
          size={'small'}
          InputProps={{
            endAdornment: <Search />,
          }}
          style={{marginTop: '16px'}}
          onChange={handleSearchNameChange}
        />
      </FileHeader>
      <FileContent>
        {files?.map((file, index) => (
          <FileListItem
            key={`file${index}`}
            file={file}
            onEditFileName={handleRefetch}
            onUpdateFile={handleRefetch}
            onDeleteFile={handleRefetch}
            canDelete={isAdmin}
            isReadOnly={isReadOnly}
          />
        ))}
      </FileContent>
    </RootContainer>
  );
};
