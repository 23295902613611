import React from 'react';
import {Header} from './Header';
import {Theme, styled, useMediaQuery} from '@material-ui/core';
import {FaultReceptionDetailSection} from './Content/FaultReceptionDetailSection';
import {FaultReceptionCommentList} from './Content/FaultReceptionCommentList';
import {useParams} from 'react-router-dom';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';

import {InnerLoading} from '@components/molecules/Loading';
import {useFaultReceptionDetailQuery} from '@modules/fault_receptions/hooks';

const LEFT_COLUMN_SIZE = '595px';

const FlexDiv = styled('div')({
  display: 'flex',
  width: '100%',
  height: '100%',
});

type ColumnProps = {
  theme: Theme;
  basis: string;
};

/**
 * 修理の詳細、コメント一覧のカラム
 */
const Column = styled('div')(({theme, basis}: ColumnProps) => ({
  flexBasis: basis || 'auto',
  flexGrow: basis === LEFT_COLUMN_SIZE ? 0 : 1,
  minWidth: basis === LEFT_COLUMN_SIZE ? LEFT_COLUMN_SIZE : 'initial',
}));

const ColumnLeft = styled('div')(() => ({
  padding: '16px',
  margin: '0 32px',
  backgroundColor: 'white',
  borderRadius: '16px',
  height: 'calc(100% - 64px)',
}));

const ColumnRight = styled('div')(() => ({
  margin: '0 32px 0 16px',
  height: '100%',
}));

/**
 * RootContainer
 * HeaderとContentの縦の配置を管理する
 */
const RootContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  overflowY: 'hidden',
  height: 'calc(100vh - 56px)', // 要調整
}));

const ResponsiveLayout: React.FC = () => {
  const isTablet = useMediaQuery('(max-width:1024px)');
  const {hashId} = useParams();
  const {myInfo} = useMyInfo();

  const {data, isLoading, refetch} = useFaultReceptionDetailQuery(myInfo.hospitalHashId, hashId ?? '');

  if (isLoading || !data) {
    return <InnerLoading />;
  }

  return (
    <FlexDiv>
      <Column basis={isTablet ? '50%' : 'auto'}>
        <ColumnLeft>
          <FaultReceptionDetailSection param={data} status={data.status} />
        </ColumnLeft>
      </Column>
      <Column basis={isTablet ? '50%' : LEFT_COLUMN_SIZE}>
        <ColumnRight>
          <FaultReceptionCommentList status={data.status} refetch={refetch} />
        </ColumnRight>
      </Column>
    </FlexDiv>
  );
};

/**
 * 不具合受付詳細表示ページコンポーネント
 * @returns
 */
export const FaultReceptionDetail: React.FC = () => {
  return (
    <RootContainer>
      <Header />
      <ResponsiveLayout />
    </RootContainer>
  );
};
