import {RepairHistoryState, RepairHistoryAction, RepairHistoryActionType} from '../types';

export const repairHistoryInitialState: RepairHistoryState = {
  repairs: [],
};

export const repairHistoryReducer = (state: RepairHistoryState, action: RepairHistoryAction): RepairHistoryState => {
  switch (action.type) {
    case RepairHistoryActionType.REPAIR_HISTORY_SET_LIST: {
      return {
        ...state,
        repairs: action.payload.repairs,
      };
    }
    default:
      return state;
  }
};
