import React from 'react';

import {InspectionStatus as InspectionStatusIndicator} from '@components/molecules/InspectionStatus';
import {InspectionListElement} from '../types';
import {Typography} from '@mui/material';

type InspectionStatusColumnProps = {
  rowData: InspectionListElement;
};

export const InspectionStatusColumn = ({rowData}: InspectionStatusColumnProps) => {
  return (
    <Typography sx={{fontSize: '0.875rem'}}>
      <InspectionStatusIndicator status={rowData.name.status as 'available' | 'draft'} />
    </Typography>
  );
};
