import {Grid, Typography} from '@material-ui/core';
import React from 'react';
import NoNotificationImg from '@assets/sentimental_mood.svg';

export const NoNotification: React.FC = () => {
  return (
    <Grid container direction="column" justifyContent="center" alignContent="center">
      <Grid item style={{textAlign: 'center', marginBottom: 24, marginTop: 120}}>
        <img src={NoNotificationImg} alt="No Notification" width={100} />
      </Grid>
      <Grid item>
        <Typography style={{fontWeight: 700, fontSize: 24}}>通知はありません</Typography>
      </Grid>
    </Grid>
  );
};
