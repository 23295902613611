import {axios} from '@front-libs/core';
import {useQueries, useQuery} from 'react-query';
import {isNullish} from '@front-libs/helpers';
import {CategoryFormatter} from '@modules/categories/helpers';
import {CategoryIndex} from '../types';
import {FetchCategoriesParams, FetchCategoriesResult} from './categoryApi';
import {useCallback, useEffect, useState} from 'react';

export type FetchHospitalCategoriesParams = {
  hospitalHashId: string;
} & FetchCategoriesParams;

export const getHospitalCategories = async (hospitalHashId: string, params: FetchCategoriesParams) => {
  const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/categories`;
  const {data} = await axios.get<FetchCategoriesResult>(baseUrl, {params});
  return data;
};

const getHospitalDescendantCategories = async (
  hospitalHashId: string,
  categoryHashId?: string,
  isMedicalDevice?: boolean
) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/categories/${categoryHashId}/descendants`;

  const response = await axios.get<CategoryIndex[]>(baseUrl, {params: {isMedical: isMedicalDevice}});
  return response;
};

export const useGetHospitalCategories = (hospitalHashId: string, params: FetchCategoriesParams) => {
  const query = useQuery(
    ['useGetHospitalCategories', hospitalHashId, params],
    () => getHospitalCategories(hospitalHashId, params),
    {
      cacheTime: 0,
    }
  );
  return {...query, data: query.data?.data ?? []};
};

export const DEPTH_FOR_NARROW_CATEGORY = 1;

export const useGetHospitalDescendantCategories = (
  hospitalHashId: string,
  broadCategoryHashId?: string,
  isMedicalDevice?: boolean
) => {
  const query = useQuery(
    ['useGetHospitalDescendantCategories', hospitalHashId, broadCategoryHashId, isMedicalDevice],
    async (): Promise<CategoryIndex[]> => {
      if (isNullish(broadCategoryHashId)) {
        const {data: res} = await getHospitalCategories(hospitalHashId, {
          depth: DEPTH_FOR_NARROW_CATEGORY,
          isMedicalDevice,
        });
        return res;
      } else {
        const {data: res} = await getHospitalDescendantCategories(hospitalHashId, broadCategoryHashId, isMedicalDevice);
        return (res ?? []).filter((item) => item.depth === DEPTH_FOR_NARROW_CATEGORY); // 小分類のみにフィルタ
      }
    }
  );
  return {...query, data: query.data ?? []};
};

export const useGetHospitalSomeDescendantCategories = (hospitalHashId: string, categoryHashIds: string[]) => {
  const query = useQueries(
    categoryHashIds.map((categoryHashId) => ({
      queryKey: ['useGetHospitalDescendantCategories', hospitalHashId, categoryHashIds, categoryHashId],
      queryFn: () => getHospitalDescendantCategories(hospitalHashId, categoryHashId),
    }))
  );
  return query;
};

/**
 * カテゴリーの大分類をOption型に変換し返す
 * @param hospitalHashId
 * @returns
 */
export const useGetHospitalRootCategoriesOptions = (hospitalHashId: string) => {
  const {data: allRootCategory, isLoading: isRootLoading} = useGetHospitalCategories(hospitalHashId, {depth: 0});
  return {
    rootCategoryOptions: CategoryFormatter.getOptions(allRootCategory),
    isLoading: isRootLoading,
  };
};

/**
 * useGetHospitalDescendantCategoriesのデータ取得部分を切り出したもの
 * @param hospitalHashId
 * @param broadCategoryHashId
 * @param isMedicalDevice
 * @returns
 */
const fetchCategories = async (
  hospitalHashId: string,
  broadCategoryHashId?: string,
  isMedicalDevice?: boolean
): Promise<CategoryIndex[]> => {
  if (isNullish(broadCategoryHashId)) {
    const {data: res} = await getHospitalCategories(hospitalHashId, {
      depth: DEPTH_FOR_NARROW_CATEGORY,
      isMedicalDevice,
    });
    return res;
  } else {
    const {data: res} = await getHospitalDescendantCategories(hospitalHashId, broadCategoryHashId, isMedicalDevice);
    return (res ?? []).filter((item) => item.depth === DEPTH_FOR_NARROW_CATEGORY);
  }
};

/**
 * カテゴリーの小分類をOptionに変換し返す
 * broadCategoryHashIdは選択された大分類、変更があるとgetHospitalDescendantCategoriesを利用して
 * 大分類に紐づく小分類項目を返す
 *
 * getHospitalDescendantCategoriesとの違いはbroadCategoryHashIdに変更があったときのみに再取得が行われる
 * @param hospitalHashId
 * @returns
 */
export const useGetHospitalNarrowCategoriesOptions = (
  hospitalHashId: string,
  broadCategoryHashId?: string,
  isMedicalDevice?: boolean
) => {
  const [categories, setCategories] = useState<CategoryIndex[]>([]);
  const fetchData = useCallback(async () => {
    const result = await fetchCategories(hospitalHashId, broadCategoryHashId, isMedicalDevice);
    setCategories(result);
  }, [hospitalHashId, broadCategoryHashId, isMedicalDevice]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    narrowCategoryOptions: CategoryFormatter.getOptions(categories),
  };
};
