import React from 'react';
import {Button, SxProps} from '@mui/material';
import {StrUtil} from '@front-libs/helpers';

type Props = {
  label: React.ReactNode;
  onChange: () => void;
};

export const MenuCard = ({label, onChange}: Props) => {
  return (
    <Button sx={ButtonStyle} onClick={onChange} data-testid={'menu-card'}>
      {typeof label === 'string' ? StrUtil.nl2br(label) : label}
    </Button>
  );
};
const ButtonStyle: SxProps = {
  borderRadius: '40px',
  boxShadow: '0px 8px 0px 0px rgba(209, 216, 245, 1)',
  '&:active': {
    color: '#2A96E8', //theme.palette.rent.light,
  },
  '&:hover': {
    backgroundColor: 'white',
  },
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 'clamp(20px, 4vmin, 48px)', //clampの相対値は右記 48px (フォントサイズ) / 1440px (対象画面サイズ) * 100 = 3.3333
  fontWeight: 'bold',
  textAlign: 'center',
  color: '#0052CC', //theme.palette.rent.main,
  backgroundColor: 'white',
  lineHeight: '1.3em',
};
