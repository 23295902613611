import {makeStyles, TextField, Theme} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';
import _ from 'lodash';
import React from 'react';
import {fieldToTextField} from './StandardTextField';

const useStyles = makeStyles((theme: Theme) => ({
  inputRoot: {
    '&:hover': {
      borderBottom: `1px solid ${theme.palette.primary.dark}`,
    },
  },
  inputText: {
    fontSize: theme.typography.fontSize,
  },
  shrink: {
    transform: 'translate(0, 1.5px) scale(0.9)',
  },
  underline: {
    '&&&:before': {
      borderBottom: '0px solid',
    },
    '&&:after': {
      borderBottom: `1px solid ${theme.palette.primary.dark}`,
    },
  },
  option: {
    fontSize: theme.typography.fontSize,
  },
}));

export type SelectOptionProps = {
  label: string;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  value: any;
};

// type Props = {
//   textFieldProps: TextFieldProps;
//   options: SelectOptionProps[];
// } & FieldProps;

const getFieldValue = (
  value: string | SelectOptionProps | (string | SelectOptionProps)[] | null
): null | SelectOptionProps['value'] | SelectOptionProps['value'][] => {
  if (_.isArray(value)) {
    return value.map<SelectOptionProps['value']>((item) => (typeof item === 'string' ? item : item.value));
  } else {
    if (!value || typeof value === 'string') return value;
    return value.value;
  }
};

// FIXME: any自警団
// biome-ignore lint/suspicious/noExplicitAny: TODO:any修正します
export const StandardSelect: React.FC<any> = ({textFieldProps, ...props}) => {
  const {
    form: {setTouched, setFieldValue, ...formProps},
    label,
  } = props;
  const {error, helperText, autoComplete, ...field} = fieldToTextField(props);
  const {name} = field;
  const classes = useStyles();

  if (import.meta.env?.VITE_APP_ENV !== 'production') {
    if (props.multiple && !_.isArray(field.value)) {
      console.warn(
        ['HITOTSU Component: StandardSelect is invalid. multiple is true but value is not Array type'].join('\n')
      );
    }
  }

  return (
    <Autocomplete<SelectOptionProps, boolean, boolean, boolean>
      {...props}
      {...field}
      data-testid={`standard-select-${name}`}
      onChange={(_e, value) => {
        setFieldValue(name, getFieldValue(value), false);
        formProps.handleChange(name);
      }}
      getOptionLabel={(option: {value: unknown}) => {
        // biome-ignore lint/suspicious/noPrototypeBuiltins: <explanation>
        if (option.hasOwnProperty('value')) {
          const search = props.options.find((item: SelectOptionProps) => item.value === option.value);
          return search ? search.label : '';
        } else {
          const search = props.options.find((item: SelectOptionProps) => item.value === option);
          return search ? search.label : '';
        }
      }}
      classes={{
        option: classes.option,
      }}
      onBlur={() => setTouched({[name + '']: true})}
      getOptionSelected={(item, current) => {
        return item.value + '' === current.value + '';
      }}
      disableClearable
      renderInput={(inputProps) => {
        return (
          <TextField
            variant={'standard'}
            {...inputProps}
            {...textFieldProps}
            helperText={helperText}
            error={error}
            label={label}
            InputLabelProps={{
              ...inputProps.InputLabelProps,
              classes: {
                root: classes.inputText,
                shrink: classes.shrink,
              },
            }}
            InputProps={{
              ...inputProps.InputProps,
              classes: {
                root: classes.inputRoot,
                input: classes.inputText,
                underline: classes.underline,
              },
              readOnly: props.readOnly,
            }}
          />
        );
      }}
    />
  );
};
