import {Grid, IconButton, styled} from '@material-ui/core';
import React, {useCallback} from 'react';
import {UserIndex} from '@modules/hospital_users/types';
import {UserFormatter} from '@modules/hospital_users/helpers';
import dayjs from 'dayjs';
import {isNullish} from '@front-libs/helpers';
import {FileActionPopperBtn} from '@organisms/FileActionPopperBtn';
import {MultipleFolderIcon} from '@atoms/Icons/multipleFolder';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {FilePreviewDialog} from '@molecules/Dialogs/FilePreviewDialog';

const FileTitle = styled('div')({
  fontSize: '16px',
  lineHeight: '24px',
  color: '#172B4D',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
});
const FileMetaInfo = styled('div')({
  fontSize: '12px',
  lineHeight: '14px',
  color: '#172B4D',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
});
const FileGrid = styled(Grid)({
  borderBottom: '1px solid #DDE0E4',
  padding: '10px 10px 4px',
});

export type FileElement = {
  hashId: string;
  fileName: string;
  fileType: string;
  url: string;
  path: string;
  createdAt: string;
  createdBy: UserIndex;
  isFileSharingAcrossProducts?: boolean;
  hospitalProductHashId?: string;
};

export type FileListItemProps = {
  file: FileElement;

  canDelete: boolean;
  isReadOnly: boolean;

  onEditFileName: (fileHashId: string) => void;
  onUpdateFile?: (fileHashId: string, isFileSharingAcrossProducts: boolean) => void;
  onDeleteFile: (fileHashId: string) => void;
  onClickMenu?: (fileHashId: string) => void;
  onClickDownload?: (fileHashId: string) => void;
};

export const FileListItem = (props: FileListItemProps) => {
  const {file, onClickMenu, canDelete, isReadOnly} = props;
  const {hashId, fileName, createdAt, createdBy, isFileSharingAcrossProducts} = file;
  const displayCreatedAt = dayjs(createdAt).format('YYYY.MM.DD');
  const fullName = UserFormatter.getFullName(createdBy);

  /** プレビューダイアログ表示 */
  const handleClickPreview = useCallback(async (data: FileElement) => {
    await dialogHandler.open(FilePreviewDialog, {
      fileName: data.fileName,
      fileType: data.fileType,
      title: 'ファイルプレビュー',
      fileHashId: data.hashId,
    });
  }, []);

  return (
    <FileGrid container justifyContent="space-between">
      <Grid item style={{width: '42px'}}>
        {isFileSharingAcrossProducts && <MultipleFolderIcon />}
      </Grid>
      <Grid item style={{width: 'calc(100% - 90px)', cursor: 'pointer'}} onClick={() => handleClickPreview(file)}>
        <FileTitle>{fileName}</FileTitle>
        <FileMetaInfo>{`${displayCreatedAt}${!isNullish(fullName) ? `、${fullName}さんが共有` : ''}`}</FileMetaInfo>
      </Grid>
      <Grid item>
        <IconButton
          onClick={() => {
            if (!onClickMenu) return;
            onClickMenu(hashId);
          }}>
          <FileActionPopperBtn {...props} canDelete={canDelete} isReadOnly={isReadOnly} />
        </IconButton>
      </Grid>
    </FileGrid>
  );
};
