import React, {useMemo, useCallback} from 'react';
import {TableBody as MTableBody, TableRow as MTableRow, TableCell as MTableCell} from '@material-ui/core';
import {useAtomValue} from 'jotai';
import {columnsAtom, dataAtom, orderAtom, useSelectOne} from '../atoms';
import {TableBodyProps} from '../props';
import {getComparator, stableSort} from '../utils';
import {useLoadingData} from './hooks';
import {NoDataCell} from './NoDataCell';
import {Loader} from './Loader';
import {Columns} from './Columns';
import styled from '@emotion/styled';

const RelativeTableBody = styled(MTableBody)({
  position: 'relative',
});

const LoaderDiv = styled('div')({
  position: 'relative',
  width: '100vw',
  height: '100%',
  marginTop: '-20px',
});

const Height50Tr = styled('tr')({
  height: '50%',
});

// eslint-disable-next-line @typescript-eslint/ban-types
export const TableBody = <T extends {}>(props: TableBodyProps<T>) => {
  const {
    isLoading,
    isCustomOrdered,
    rowActions,
    rowSize,
    rowActionIndex,
    page,
    pageSize,
    showSelection,
    noDataComponent,
    onRowClick,
    onSelectionChange,
  } = props;

  const columns = useAtomValue(columnsAtom);
  const stateData = useAtomValue(dataAtom);
  const order = useAtomValue(orderAtom);
  const selectOne = useSelectOne();

  const data = useLoadingData(stateData, isLoading);

  const handleSelectRowChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>, rowIndex: number) => {
      if (!showSelection) {
        return;
      }

      const newChecked = e.target.checked;
      const itemIndex = rowIndex + (page - 1) * pageSize;
      const newData = await selectOne([itemIndex, newChecked]);

      if (onSelectionChange) {
        const newSelectedData = newData.filter((x) => x.tableData?.checked);
        const newTarget = newData[itemIndex];

        onSelectionChange(newSelectedData, newTarget, newChecked);
      }
    },
    [selectOne, onSelectionChange, showSelection, page, pageSize]
  );

  const displayedData = useMemo(() => {
    if (isCustomOrdered) {
      return data;
    }

    if (order === null) {
      return data;
    }

    const column = columns.find((c) => c.field === order.fieldId);
    if (column === undefined) {
      console.warn(`column ${order.fieldId} is not found in columns`);

      return data;
    }

    const comparator = column.customSort ? column.customSort : getComparator(order.direction, order.fieldId);
    const sortedData = stableSort(data, comparator);

    return sortedData.slice((page - 1) * pageSize, page * pageSize);
  }, [isCustomOrdered, order, columns, data, page, pageSize]);

  const numColumns = columns.length + (showSelection ? 1 : 0);
  const hasData = data.length > 0;
  const showsCheckBox = showSelection;
  const showsNoDataCell = !hasData && !!noDataComponent;
  // noDataComponentが無いときSpinnerを表示するスペースが無いため、空白の行を表示させる
  const showsBlankCell = isLoading && !hasData && !noDataComponent;

  // NOTE: <div> cannot appear as a child of <tbody>のWarning対応
  if (isLoading) {
    return (
      <tbody>
        <tr>
          <td>
            <LoaderDiv>
              <Loader />
            </LoaderDiv>
          </td>
        </tr>
      </tbody>
    );
  }

  return (
    <RelativeTableBody data-testid="tbody">
      {showsNoDataCell && <NoDataCell numColumns={numColumns} component={noDataComponent} />}
      {showsBlankCell && (
        <MTableRow>
          <MTableCell align="justify" colSpan={numColumns}>
            <Height50Tr />
          </MTableCell>
        </MTableRow>
      )}
      <Columns
        data={displayedData}
        columns={columns}
        rowActions={rowActions}
        rowSize={rowSize}
        rowActionIndex={rowActionIndex}
        showsCheckbox={showsCheckBox}
        onSelectRowChange={handleSelectRowChange}
        onRowClick={onRowClick}
      />
    </RelativeTableBody>
  );
};
