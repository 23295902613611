import {useCallback, useMemo} from 'react';
import {useSearchParams} from 'react-router-dom';
import {useQuery} from 'react-query';
import {getHospitalWholeProducts, useFetchHospitalWholeProductsQuery} from '@modules/products/api';
import {
  pageAtom,
  pageSizeAtom,
  searchNameAtom,
  hospitalWholeProductsVariables,
  searchRootCategoriesAtom,
  searchSubCategoriesAtom,
  searchFilterResultsAtom,
} from './jotai';
import {SelectOptionProps} from '@molecules/Buttons/PopperSelectBoxButton';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {CategoryFormatter} from '@modules/categories/helpers';
import {useCategoryQuery} from '@Apps/ProductsList/pc/hooks';
import {useDebounceCallback, useOnlyOnce} from '@front-libs/core';
import {useAtom, useAtomValue} from 'jotai';
import {TableLayoutResult} from '@modules/table_layout/hooks/useTableLayout';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {TableLayoutDialog, TableLayoutDialogProps} from '@organisms/Table/TableLayoutDialog';
import {MenuItemType} from '@molecules/Buttons/PopperMenuButton';
import {useDescendantCategoryQuery, useFetchRootCategoriesQuery} from '../categories/api/categoryApi';

export const useWholeProductsQuery = (hospitalHashId: string) => {
  const variables = useAtomValue(hospitalWholeProductsVariables);
  const query = useQuery(['useWholeProductsQuery', hospitalHashId, variables], () =>
    getHospitalWholeProducts(hospitalHashId, variables)
  );
  return useMemo(() => query, [query]);
};

type UseWholeProductsListContentHeaderProps = {
  tableLayout?: TableLayoutResult;
  onChangeTableLayout: (layout: TableLayoutResult) => void;
};

export const useWholeProductsListContentHeader = ({
  tableLayout,
  onChangeTableLayout,
}: UseWholeProductsListContentHeaderProps) => {
  const {myInfo} = useMyInfo();
  const [page, setPage] = usePage();
  const [searchName, setSearchName] = useAtom(searchNameAtom);
  const [searchRootCategories, setSearchRootCategories] = useAtom(searchRootCategoriesAtom);
  const [searchSubCategories, setSearchSubCategories] = useAtom(searchSubCategoriesAtom);
  const {rootCategoryQuery, narrowCategoryQuery, selectRootCategory, descendantCategoriesQueries} = useCategoryQuery(
    myInfo.hospitalHashId
  );
  const searchFilterResults = useAtomValue(searchFilterResultsAtom);

  const descendantCategoryOptions = useMemo(() => {
    if (descendantCategoriesQueries.length > 0) {
      const catQuery = descendantCategoriesQueries.flatMap((query) =>
        (query.data?.data ?? []).filter((item) => item.depth === 1)
      );
      return CategoryFormatter.getOptions(catQuery);
    } else {
      return CategoryFormatter.getOptions(narrowCategoryQuery.data);
    }
  }, [descendantCategoriesQueries, narrowCategoryQuery.data]);

  const handleSearchName = useDebounceCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setPage(1);
      setSearchName(e.target.value);
    },
    300,
    [setPage, setSearchName]
  );

  const handleChangeRootCategories = useDebounceCallback(
    (values: SelectOptionProps[] | null) => {
      setPage(1);
      selectRootCategory(values && values.length > 0 ? values.map((i) => i.value) : null);
      setSearchRootCategories(values ?? []);
    },
    300,
    [setPage, setSearchRootCategories]
  );

  const handleChangeSubCategories = useDebounceCallback(
    (values: SelectOptionProps[] | null) => {
      setPage(1);
      setSearchSubCategories(values ?? []);
    },
    300,
    [setPage, setSearchSubCategories]
  );

  const actionMenuItems = [
    {
      label: '表示項目を変更',
      value: 'changeLayout',
    },
  ];

  const handleChangeLayout = useCallback(async () => {
    try {
      const currentLayout = await dialogHandler.open<TableLayoutDialogProps>(TableLayoutDialog, {
        tableColumns: tableLayout?.tableLayout ?? [],
        defaultOptions: tableLayout?.currentLayout ?? [],
        forceValue: tableLayout?.forceValue ?? {},
      });
      const newTableLayout = {
        tableLayout: tableLayout?.tableLayout ?? [],
        currentLayout: currentLayout,
      };
      onChangeTableLayout(newTableLayout);
    } catch (e) {
      console.error(e);
    }
  }, [tableLayout, onChangeTableLayout]);

  const handleMenuClick = useCallback(
    (item: MenuItemType) => {
      switch (item.value) {
        case 'changeLayout':
          handleChangeLayout();
          break;
      }
    },
    [handleChangeLayout]
  );

  const isDetailFilterActive = searchFilterResults.length > 0;

  return {
    searchName,
    searchRootCategories,
    searchSubCategories,
    rootCategoryQuery,
    descendantCategoryOptions,
    handleSearchName,
    handleChangeRootCategories,
    handleChangeSubCategories,
    actionMenuItems,
    handleMenuClick,
    isDetailFilterActive,
  };
};

export const useWholeProductsListContentFooter = (totalCount: number) => {
  const [page, setPage] = usePage();
  const [pageSize, setPageSize] = useAtom(pageSizeAtom);

  const totalPage = useMemo(() => {
    return Math.ceil(totalCount / pageSize);
  }, [pageSize, totalCount]);

  const startDisplayPosition = useMemo(() => {
    return totalCount === 0 ? 0 : Math.ceil((page - 1) * pageSize + 1);
  }, [totalCount, page, pageSize]);

  const endDisplayPosition = useMemo(() => {
    const endPosition = Math.ceil(page * pageSize);
    return endPosition > (totalCount ?? 0) ? totalCount : endPosition;
  }, [page, pageSize, totalCount]);

  /** ページネーション */
  const handleChangePage = useCallback(
    (_e: React.ChangeEvent<unknown>, p: number) => {
      setPage(p);
    },
    [setPage]
  );

  return {
    page,
    setPage,
    pageSize,
    setPageSize,
    totalPage,
    startDisplayPosition,
    endDisplayPosition,
    handleChangePage,
  };
};

const usePage = () => {
  const [page, setPage] = useAtom(pageAtom);
  const [searchParams, setSearchParams] = useSearchParams();

  const getInitPage = useCallback((): number => {
    const queryPage = searchParams.get('page');
    if (queryPage === null) {
      return 1;
    }

    const parsed = Number.parseInt(queryPage);
    if (!isNaN(parsed) && parsed >= 1) {
      return parsed;
    }

    return 1;
  }, [searchParams]);

  // 初期化時クエリからページを読み込む
  useOnlyOnce(() => {
    setPage(getInitPage());
  });

  const updatePage = useCallback(
    (newPage: number) => {
      setPage(newPage);

      searchParams.set('page', newPage.toString());
      setSearchParams([...searchParams.entries()]);
    },
    [searchParams, setPage, setSearchParams]
  );

  return [page, updatePage] as const;
};

/**
 * 大分類・小分類カテゴリーをOption型に変換し返す
 * @param hospitalHashId
 * @returns
 */
export const useGetCategoriesOptions = (broadCategoryHashId: string | undefined) => {
  const {data: rootCategoryQuery} = useFetchRootCategoriesQuery({depth: 0});
  const descendantCategoriesQueries = useDescendantCategoryQuery(broadCategoryHashId);
  return {
    rootCategoryOptions: CategoryFormatter.getOptions(rootCategoryQuery),
    narrowCategoryOptions: descendantCategoriesQueries.isLoading
      ? []
      : CategoryFormatter.getOptions(descendantCategoriesQueries?.data ?? []),
  };
};

/**
 * メーカー名をOption型に変換し返す
 * @param hospitalHashId
 * @returns
 */
export const useGetMakerOptions = (hospitalHashId: string) => {
  const {data} = useFetchHospitalWholeProductsQuery(hospitalHashId, {page: 0, perPage: 100});
  const makerNameOptions = useMemo(() => {
    const uniqueNames = new Set();

    return data
      .filter((product) => {
        const makerId = product?.maker?.hashId;
        return makerId && !uniqueNames.has(makerId) && uniqueNames.add(makerId);
      })
      .map((product) => ({
        label: product.maker.name,
        value: product.maker.hashId,
      }));
  }, [data]);

  return {makerNameOptions};
};
