import React, {useCallback, useMemo, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from '@material-ui/core';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import {Form, Formik, useFormikContext} from 'formik';
import {yup} from '@front-libs/core';
import {FetchHospitalProductsParams, getHospitalProducts} from '@modules/hospital_products/api';
import {Close} from '@material-ui/icons';
import AsyncSelector from '@molecules/Formik/fields/AsyncSelector';
import {HospitalRoomsParams} from '@modules/hospital_places/api';
import {FetchChannelsParams} from '@modules/channels/types';
import {fetchChannelsInfo} from '@modules/channels/api';
import {fetchHospitalRooms} from '@modules/hospital_wards/room_api';
import {useUpdateChannelSubject} from '@Apps/Channel/hooks';
import {ZoneIdType} from '@Apps/Channel/consts';
import {RequiredLabel} from '@molecules/FormRequiredLabel';
import {HospitalRoomFormatter} from '@modules/hospital_wards/helpers';
import {defaultChannelHashIdAtom, dialogChannelNumberAtom} from '@Apps/Channel/states';
import {useAtom} from 'jotai';
import {useAtomValue} from 'jotai';

const ChannelForm: React.FC<Props> = (props) => {
  const {isValid, submitForm, isSubmitting} = useFormikContext<Partial<RegistrationChannelDialogProps>>();

  const dialogChannelNumber = useAtomValue(dialogChannelNumberAtom);
  const [zoneIds, setZoneIds] = useState<ZoneIdType[]>([]);
  const {notify} = useUpdateChannelSubject();
  const hiddenProductSelector = useMemo(
    () => props.readOnlyKeys?.includes('hospitalProductHashId'),
    [props.readOnlyKeys]
  );

  const submit = useCallback(async () => {
    await submitForm();
    setTimeout(() => notify(zoneIds), 200);
  }, [notify, submitForm, zoneIds]);

  const loadUnassignedChannelOptions = useCallback(
    async (inputValue: string) => {
      const _p: FetchChannelsParams = {
        page: 0,
        perPage: 480,
        isAssigned: false,
      };
      const res = await fetchChannelsInfo(props.hospitalHashId, _p);
      const options = res.data.map((c) => ({
        value: c.channelHashId,
        label: c.channelNumber,
        zoneIds: c.zoneIds.map(String),
      }));
      return options.filter((option) => String(option.label).includes(inputValue));
    },
    [props.hospitalHashId]
  );

  const handleOnChangeChannel = useCallback((e) => {
    setZoneIds(e.zoneIds);
  }, []);

  const loadQueryRoomOptions = useCallback(
    async (inputValue: string) => {
      const _p: HospitalRoomsParams = {
        page: 0,
        perPage: 100,
      };
      const res = await fetchHospitalRooms(props.hospitalHashId, _p);
      const options = res.data.map((room) => ({
        value: room.hashId,
        label: HospitalRoomFormatter.getFullRoom(room),
      }));
      return options.filter((option) => option.label.includes(inputValue));
    },
    [props.hospitalHashId]
  );

  const loadHospitalProductOptions = useCallback(
    async (newValue: string) => {
      const baseParams: FetchHospitalProductsParams = {
        perPage: 100,
        isTransmitter: true,
        order: 'management_id',
        statuses: 'ready,working,uninspected,repairing',
      };
      if (newValue !== '') baseParams.name = newValue;
      const res = await getHospitalProducts(props.hospitalHashId, baseParams);
      const options = res.data.map((item) => ({label: item.managementId, value: item.hashId}));
      return options.filter((option) => option.label.includes(newValue));
    },
    [props.hospitalHashId]
  );

  return (
    <Dialog
      open={props.open}
      onClose={(e, reason) => {
        if (reason === 'backdropClick') return;
        props.actions.reject(e);
      }}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth={'sm'}>
      <DialogTitle>
        <Grid container>
          <Grid item>
            <span>{dialogChannelNumber ? `チャンネル[${dialogChannelNumber}]を変更` : 'チャンネルを新規登録'}</span>
          </Grid>
          <div style={{flexGrow: 1}} />
          <Grid item>
            <Button onClick={props.actions.reject}>
              <Close />
            </Button>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Form style={{width: '100%'}}>
            {!hiddenProductSelector && (
              <div style={{width: '100%', marginBottom: '16px'}}>
                <RequiredLabel>チャンネルを登録するモニタリング機器</RequiredLabel>
                <AsyncSelector
                  name="hospitalProductHashId"
                  placeholder={'検索...'}
                  loadOptions={loadHospitalProductOptions}
                />
              </div>
            )}
            <div style={{width: '100%', marginBottom: '16px'}}>
              <RequiredLabel>モニタリング機器を設置する場所</RequiredLabel>
              <AsyncSelector name="roomHashId" size={'small'} placeholder={'選択'} loadOptions={loadQueryRoomOptions} />
            </div>
            {!props.defaultValues?.channelHashId && (
              <div style={{width: '100%', marginBottom: '16px'}}>
                <RequiredLabel>チャンネル番号</RequiredLabel>
                <AsyncSelector
                  name="channelHashId"
                  size={'small'}
                  placeholder={'選択'}
                  loadOptions={loadUnassignedChannelOptions}
                  onChange={handleOnChangeChannel}
                />
              </div>
            )}
          </Form>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button disabled={!isValid && !isSubmitting} variant={'contained'} color="primary" onClick={submit}>
          登録
        </Button>
        <Button onClick={props.actions.reject} color="primary">
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export type RegistrationChannelDialogProps = {
  hospitalProductHashId: string;
  wardHashId: string;
  channelHashId: string;
};

type Props = {
  hospitalHashId: string;
  defaultValues?: Partial<RegistrationChannelDialogProps>;
  readOnlyKeys?: [keyof RegistrationChannelDialogProps];
  channelNumber?: number;
} & DialogProps;

export const RegistrationChannelDialog: React.FC<Props> = (props) => {
  const [defaultChannelHashId] = useAtom(defaultChannelHashIdAtom);

  const validationSchema = yup.object({
    hospitalProductHashId: yup.string().required(),
    roomHashId: yup.string().required(),
    channelHashId: defaultChannelHashId ? yup.string() : yup.string().required(),
  });

  const handleSubmit = (res: Partial<RegistrationChannelDialogProps>) => {
    props.actions.resolve(res);
  };

  return (
    <Formik
      initialValues={props.defaultValues ?? {}}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      isInitialErrors={true}>
      <ChannelForm {...props} />
    </Formik>
  );
};
