import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useInspection} from '@modules/inspections/hooks';

type InspectionEditorQueryProps = {
  inspectionHashId?: string;
};

export const useInspectionEditorQuery = (props: InspectionEditorQueryProps) => {
  const {inspectionHashId} = props;
  const {myHospitalInfo} = useMyInfo();

  const {data: inspection, isFetching, refetch} = useInspection(myHospitalInfo?.hashId, inspectionHashId);
  return {
    inspection,
    isFetching,
    refetch,
  };
};
