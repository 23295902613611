import {isNullish} from '@front-libs/helpers';
import {useFaultReceptionDetailQuery} from '@modules/fault_receptions/hooks';
import {useFetchFaultReceptionLogsQuery} from '@modules/fault_receptions/hooks/useFaultReceptionLog';
import dayjs from 'dayjs';
import {HistoryCardProps} from './Cards/HistoryCard';
import {useFetchFaultRepairLogsQuery} from '@modules/repairs/hooks';
import {useCallback, useMemo} from 'react';
import {repairLogTypeEnum} from '@modules/repairs/constants';
import {TimeLineTitle} from '@Apps/ProductDetail/styled';
import {Build} from '@material-ui/icons';
import React from 'react';
import {RepairIndex} from '@modules/repairs/types';
import {UserFormatter} from '@modules/hospital_users/helpers';
import {Button} from '@material-ui/core';

export const useFaultReceptionHistory = (hospitalHashId: string, faultReceptionHashId: string) => {
  const faultReceptionDetail = useFaultReceptionDetailQuery(hospitalHashId, faultReceptionHashId);

  const faultReceptionLog = useFetchFaultReceptionLogsQuery(hospitalHashId, faultReceptionHashId);
  const isLoading = faultReceptionDetail.isLoading || faultReceptionLog.isLoading;
  const isError = faultReceptionDetail.isError || faultReceptionLog.isError;

  return {
    faultReceptionDetail: faultReceptionDetail.data ?? null,
    faultReceptionLog: faultReceptionLog.data,
    isLoading,
    isError,
  };
};

// カスタムフックの引数として必要な型を定義します。
type UseFaultHistoryParams = {
  hospitalHashId: string;
  faultRepair: RepairIndex;
};

/**
 * 修理の履歴を取得
 */
export const useFaultHistory = ({hospitalHashId, faultRepair}: UseFaultHistoryParams) => {
  const faultLogQuery = useFetchFaultRepairLogsQuery(hospitalHashId, faultRepair.hashId, {perPage: 100});
  const faultReceptionQuery = useFaultReceptionHistory(hospitalHashId, faultRepair.faultReceptionHashId ?? '');

  const isLoading = useMemo(
    () => faultLogQuery.isLoading || faultReceptionQuery.isLoading,
    [faultLogQuery, faultReceptionQuery]
  );

  const hasError = useMemo(
    () => faultLogQuery.isError || faultReceptionQuery.isError,
    [faultLogQuery, faultReceptionQuery]
  );

  const {faultReceptionDetail, faultReceptionLog} = faultReceptionQuery;

  const [statusChangeLogs, commentLogs] = useMemo(() => {
    if (isLoading && hasError) return [[], [], []];
    return [
      faultLogQuery.data.filter((item) => item.logType === repairLogTypeEnum.statusChange),
      faultLogQuery.data.filter((item) => item.logType === repairLogTypeEnum.comment),
    ];
  }, [faultLogQuery.data, hasError, isLoading]);

  const productHistory: HistoryCardProps[] = useMemo(() => {
    if (isLoading && hasError) return [];
    let historyCards: HistoryCardProps[] = [];

    historyCards = historyCards.concat(
      statusChangeLogs.map<HistoryCardProps>((item) => ({
        cardType: repairLogTypeEnum.faultReceptionComment,
        title: <TimeLineTitle>修理情報の変更</TimeLineTitle>,
        icon: <Build />,
        logHashId: item.hashId,
        displayDate: item.createdAt,
        contentSlot: [
          {label: '変更内容：', value: `ステータスを「${item.statusFromName}」から「${item.statusToName}」に変更`},
          {label: '変更者　：', value: UserFormatter.getFullName(item.createdBy)},
        ],
      }))
    );
    historyCards = historyCards.concat(
      commentLogs.map<HistoryCardProps>((item) => ({
        cardType: repairLogTypeEnum.comment,
        logHashId: item.hashId,
        pinned: item.pinned,
        user: item.createdBy,
        displayDate: item.createdAt,
        description: item.description,
        updatedAt: item.updatedAt,
        isArchived: item.isArchived,
        archivedBy: item.archivedBy,
      }))
    );

    historyCards = historyCards.concat(
      faultReceptionLog.map<HistoryCardProps>((item) => ({
        cardType: repairLogTypeEnum.faultReceptionComment,
        title: <TimeLineTitle>不具合受付のコメント</TimeLineTitle>,
        icon: <Build />,
        logHashId: item.hashId,
        displayDate: item.createdAt,
        description: item.description,
        contentSlot: [
          {label: '', value: item.description ?? '不明な登録者'},
          {label: '投稿者：', value: UserFormatter.getFullName(item.createdBy)},
        ],
      }))
    );

    if (!isNullish(faultReceptionDetail)) {
      historyCards.push({
        cardType: repairLogTypeEnum.faultReception,
        title: <TimeLineTitle>不具合受付の登録</TimeLineTitle>,
        icon: <Build />,
        logHashId: faultReceptionDetail.hashId,
        user: faultReceptionDetail.createdBy,
        displayDate: faultReceptionDetail.createdAt,
        contentSlot: [{label: '登録者：', value: faultReceptionDetail.reporterName ?? '不明な登録者'}],
      });
    }
    historyCards.push({
      cardType: 'registration',
      title: <TimeLineTitle>修理の登録</TimeLineTitle>,
      icon: <Build />,
      logHashId: faultRepair.hashId,
      user: faultRepair.createdBy,
      displayDate: faultRepair.createdAt,
      contentSlot: [{label: '登録者：', value: UserFormatter.getFullName(faultRepair.createdBy) ?? '不明な登録者'}],
      optionalItem: faultRepair.faultReceptionHashId ? (
        <LinkButton to={`/repairs/fault_receptions/${faultRepair.faultReceptionHashId}`}>
          不具合受付情報を確認
        </LinkButton>
      ) : null,
    });

    return historyCards.sort((a, b) => {
      if (a.displayDate < b.displayDate) return 1;
      if (a.displayDate > b.displayDate) return -1;
      return 0;
    });
  }, [
    isLoading,
    hasError,
    statusChangeLogs,
    commentLogs,
    faultReceptionLog,
    faultReceptionDetail,
    faultRepair.hashId,
    faultRepair.createdBy,
    faultRepair.createdAt,
    faultRepair.faultReceptionHashId,
  ]);

  const monthlyProductHistory: {section: string; productHistories: HistoryCardProps[]}[] = useMemo(() => {
    const tmpHistory: {section: string; productHistories: HistoryCardProps[]}[] = [];
    const pinnedHistory: {section: string; productHistories: HistoryCardProps[]}[] = [];
    productHistory
      .filter((item) => item.pinned === true)
      .forEach((item, index) => {
        if (index === 0) {
          pinnedHistory.push({
            section: 'ピンどめしたコメント',
            productHistories: [item],
          });
        } else {
          pinnedHistory[pinnedHistory.length - 1].productHistories.push(item);
        }
      });
    productHistory
      .filter((item) => !(item.pinned === true))
      .forEach((item, index) => {
        if (index === 0 || tmpHistory[tmpHistory.length - 1].section !== dayjs(item.displayDate).format('MM月 YYYY')) {
          tmpHistory.push({
            section: dayjs(item.displayDate).format('MM月 YYYY'),
            productHistories: [item],
          });
        } else {
          tmpHistory[tmpHistory.length - 1].productHistories.push(item);
        }
      });
    return [...pinnedHistory, ...tmpHistory];
  }, [productHistory]);

  return {monthlyProductHistory, faultLogQuery};
};

export const LinkButton: React.FC<{to: string}> = ({to, children}) => {
  const handleClick = useCallback(() => {
    window.open(to, '_blank');
  }, [to]);
  return (
    <Button variant={'outlined'} color={'primary'} onClick={handleClick}>
      {children}
    </Button>
  );
};
