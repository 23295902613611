import React from 'react';
import {FaultReceptionList} from './FaultReceptionList';
import {Footer} from './Footer';
import {FaultReceptionHeader} from '@components/molecules/FaultReceptionHeader';

export const FaultReceptionHistory = () => {
  return (
    <>
      <FaultReceptionHeader title="不具合受付の履歴" text="リストをクリックすると、詳細情報を見ることができます" />
      <FaultReceptionList />
      <Footer />
    </>
  );
};
