import React from 'react';
import TextField, {StandardTextFieldProps} from '@material-ui/core/TextField';
import {FieldProps, getIn} from 'formik';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {DateTimePicker, DateTimePickerProps as MuiDateTimePickerProps} from '@material-ui/pickers';
import {IconButton, InputAdornment} from '@material-ui/core';
import {Clear} from '@material-ui/icons';

export interface DateTimePickerProps extends FieldProps, Omit<MuiDateTimePickerProps, 'name' | 'value' | 'error'> {
  textFieldProps?: StandardTextFieldProps;
  fieldFormat?: string;
  clearable?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  inputRoot: {
    '&:hover': {
      borderBottom: `1px solid ${theme.palette.primary.dark}`,
    },
  },
  inputText: {
    fontSize: theme.typography.fontSize,
  },
  shrink: {
    transform: 'translate(0, 1.5px) scale(0.9)',
  },
  underline: {
    '&&&:before': {
      borderBottom: '0px solid',
    },
    '&&:after': {
      borderBottom: `1px solid ${theme.palette.primary.dark}`,
    },
  },
}));

export function fieldToDateTimePickerField({
  disabled,
  field: {onBlur: fieldOnBlur, ...field},
  form: {isSubmitting, touched, errors},
  onBlur,
  helperText,
  ...props
}: DateTimePickerProps): MuiDateTimePickerProps {
  const fieldError = getIn(errors, field.name);
  const showError = getIn(touched, field.name) && !!fieldError;

  return {
    error: showError,
    helperText: showError ? fieldError : helperText,
    disabled: disabled ?? isSubmitting,
    onBlur:
      onBlur ??
      function (e) {
        fieldOnBlur(e ?? field.name);
      },
    ...props,
    ...field,
  };
}

export const StandardDateTimeField: React.FC<DateTimePickerProps> = ({
  textFieldProps,
  fieldFormat = 'YYYY-MM-DDTHH:mm:ssZ',
  format = 'YYYY/MM/DD HH:mm',
  ...props
}) => {
  const {
    form: {setTouched, setFieldValue, ...formProps},
    label,
    clearable,
    ...rest
  } = props;
  const {error, helperText, autoComplete, ...field} = fieldToDateTimePickerField(props);
  const {name} = field;
  const classes = useStyles();

  return (
    <DateTimePicker
      {...rest}
      emptyLabel={''}
      value={field.value}
      className={classes.root}
      variant="inline"
      format={format}
      autoOk={true}
      onChange={(date) => {
        if (date !== null) {
          setFieldValue(name + '', date.format(fieldFormat));
          formProps.handleChange(name);
        } else {
          setFieldValue(name + '', null);
        }
      }}
      TextFieldComponent={(inputProps) => {
        return (
          <TextField
            {...textFieldProps}
            {...inputProps}
            variant={'standard'}
            helperText={helperText}
            error={error}
            label={label}
            InputLabelProps={{
              ...textFieldProps?.InputLabelProps,
              ...inputProps.InputLabelProps,
              classes: {
                root: classes.inputText,
                shrink: classes.shrink,
              },
            }}
            InputProps={{
              ...textFieldProps?.InputProps,
              ...inputProps.InputProps,
              classes: {
                root: classes.inputRoot,
                input: classes.inputText,
                underline: classes.underline,
              },
              readOnly: props.readOnly,
              endAdornment: clearable && (
                <InputAdornment position="end">
                  <IconButton
                    size={'small'}
                    onClick={(e) => {
                      e.stopPropagation();
                      setFieldValue(name + '', null);
                    }}>
                    <Clear />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        );
      }}
    />
  );
};
