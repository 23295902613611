import React from 'react';
import {styled, Typography} from '@mui/material';
import {StrUtil} from '@front-libs/helpers';
const Container = styled('div')({
  margin: '8px 0',
});

const Label = styled(Typography)(({label: labelColor}: {label: string | undefined}) => ({
  color: labelColor || '#8B95A6',
  fontSize: '16px',
}));

const Text = styled(Typography)({
  color: '#172B4D',
  fontSize: '20px',
});

type LabelAndTextProps = {
  label: string;
  text: string;
  labelColor?: string;
};

/**
 * ラベルとテキストを縦に並べて表示するコンポーネント
 * ラベルは灰色で小さいフォントサイズ、テキストは濃い青色でやや大きいフォントサイズで表示される
 *
 * @param props - `LabelAndTextProps` 型のプロパティ
 * @param props.label - 上部に表示されるラベルテキスト
 * @param props.text - ラベルの下に表示される本文テキスト
 * @param props.labelColor - ラベルのテキストカラー（オプショナル）
 */
export const LabelAndText = ({label, text, labelColor}: LabelAndTextProps) => {
  return (
    <Container>
      <Label variant="subtitle2" label={labelColor}>
        {label}
      </Label>
      <Text variant="body1">{StrUtil.nl2br(text)}</Text>
    </Container>
  );
};
