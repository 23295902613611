import React, {useCallback} from 'react';
import {styled, Box, Button, DialogActions, DialogTitle} from '@mui/material';
import {useBarcodeReadCamera} from '@front-libs/core';
import {CodeReaderType} from '../types';

type Props = {
  onRead: (managementId: string) => void;
  onChangeCodeReaderType: (codeReaderType: CodeReaderType) => void;
};

const CameraInput = styled(Box)({
  marginTop: '40px',
});

const Actions = styled(DialogActions)({
  marginTop: '40px',
  '& > button:nth-of-type(n+2)': {
    marginLeft: '16px',
  },
});

export const Camera = ({onRead, onChangeCodeReaderType}: Props) => {
  // NOTE: useBarcodeReadCameraのonInputDataをuseCallbackで利用しているため、こちらのhandleReadにuseCallbackを利用している
  const handleRead = useCallback(
    (managementId: string) => {
      onRead(managementId);
    },
    [onRead]
  );

  const {BarcodeInput} = useBarcodeReadCamera({width: 768, height: 432, onInputData: handleRead});

  const handleSwitchToBarcodeReadCamera = () => {
    onChangeCodeReaderType('barcodeReader');
  };

  return (
    <>
      <DialogTitle sx={{padding: 0, minWidth: '748px', fontWeight: 700}}>
        カメラで、対象機器のバーコードまたはQRコードを読み取ってください
      </DialogTitle>
      <CameraInput>{BarcodeInput}</CameraInput>
      <Actions>
        <Button variant="text" sx={{color: '#0052CC'}} onClick={handleSwitchToBarcodeReadCamera}>
          リーダーに切り替える
        </Button>
      </Actions>
    </>
  );
};
