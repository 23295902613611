import React, {useState} from 'react';
import {styled, Box, Button, DialogActions, DialogTitle, TextField} from '@mui/material';
import {UserIndex} from '@modules/hospital_users/types';
import {InspectionList} from '@modules/inspections/types';
import {RequiredLabel} from '@molecules/FormRequiredLabel';
import {convertDateToSimpleDate} from '@front-libs/helpers';
import {openSnackBar} from '@components/molecules/SnackBar';
import {InspectionTypeLabelMap} from '@components/organisms/Header/pc/GeneralMenu/HospitalProductMenu/constants';
import {InspectionSummary} from '../InspectionSummary';
import {InspectionSelector} from './InspectionSelector';
import {UserSelector} from './UserSelector';

const Description = styled(Box)({
  marginTop: '32px',
});

const InputForm = styled(Box)({
  marginTop: '24px',
  '& > div:nth-of-type(n+2)': {
    marginTop: '24px',
  },
});

const Actions = styled(DialogActions)({
  marginTop: '40px',
  '& > button:nth-of-type(n+2)': {
    marginLeft: '16px',
  },
});

type Props = {
  hospitalUsers: UserIndex[];
  allInspections: InspectionList[];
  wholeProductInspections: InspectionList[];
  defaultInspectorHashId: string;
  onSearchInspection: (name: string) => void;
  onBack: () => void;
  onSubmit: (inspectionHashId: string, inspectorHashId: string, scheduledTime: string) => Promise<void>;
};

export const InspectionAdhoc = ({
  hospitalUsers,
  allInspections,
  wholeProductInspections,
  defaultInspectorHashId,
  onSearchInspection,
  onBack,
  onSubmit,
}: Props) => {
  const todayText = convertDateToSimpleDate(new Date());
  const [scheduledTime, setScheduledTime] = useState<string>(todayText);
  const [selectedInspectionHashId, setSelectedInspectionHashId] = useState<string | null>(null);
  const [selectedInspectorHashId, setSelectedInspectorHashId] = useState<string>(defaultInspectorHashId);
  const canSubmit = !!selectedInspectionHashId && !!selectedInspectorHashId && !!scheduledTime;

  const resetInputValues = () => {
    setScheduledTime(todayText);
    setSelectedInspectionHashId(null);
    setSelectedInspectorHashId(defaultInspectorHashId);
  };

  const handleSearchInspection = (name: string) => {
    onSearchInspection(name);
  };
  const handleSelectInspection = (hashId?: string) => {
    if (!hashId) return;

    setSelectedInspectionHashId(hashId);
  };
  const handleSelectInspector = (hashId?: string) => {
    if (!hashId) return;

    setSelectedInspectorHashId(hashId);
  };
  const handleChangeScheduledTime = (event: React.ChangeEvent<HTMLInputElement>) => {
    setScheduledTime(event.target.value);
  };

  const handleBack = () => {
    resetInputValues();
    onBack();
  };

  const handleSubmit = async () => {
    if (!canSubmit) return;

    try {
      await onSubmit(selectedInspectionHashId, selectedInspectorHashId, scheduledTime);
    } catch (error) {
      console.error(error);
      openSnackBar('臨時点検の開始に失敗しました', 'left', 'bottom', 'error');
    }
  };

  return (
    <>
      <DialogTitle sx={{padding: 0, minWidth: '586px', fontWeight: 700}}>点検を開始</DialogTitle>
      <Description>
        <InspectionSummary data={[{label: '点検タイプ', value: InspectionTypeLabelMap.adhoc}]} />
      </Description>
      <InputForm>
        <Box>
          <RequiredLabel>点検表</RequiredLabel>
          <InspectionSelector
            allInspections={allInspections}
            wholeProductInspections={wholeProductInspections}
            selectedHashId={selectedInspectionHashId ?? ''}
            onSearch={handleSearchInspection}
            onSelect={handleSelectInspection}
          />
        </Box>
        <Box>
          <RequiredLabel>点検実施日</RequiredLabel>
          <TextField
            type="date"
            variant="outlined"
            size="small"
            fullWidth
            InputProps={{inputProps: {max: todayText}}}
            value={scheduledTime}
            onChange={handleChangeScheduledTime}
          />
        </Box>
        <Box>
          <RequiredLabel>点検者</RequiredLabel>
          <UserSelector
            placeholder="点検者"
            users={hospitalUsers}
            selectedHashId={selectedInspectorHashId}
            onSelect={handleSelectInspector}
          />
        </Box>
      </InputForm>
      <Actions>
        <Button variant="text" sx={{color: '#0052CC'}} onClick={handleBack}>
          点検タイプ選択画面に戻る
        </Button>
        <Button
          variant="contained"
          sx={{backgroundColor: '#0052CC', '&.Mui-disabled': {color: '#FFFFFF'}}}
          disabled={!canSubmit}
          onClick={handleSubmit}>
          開始
        </Button>
      </Actions>
    </>
  );
};
