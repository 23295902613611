import React, {memo, useMemo, useCallback} from 'react';
import {Grid} from '@material-ui/core';
import {FastField, FieldInputProps, FieldMetaProps, FormikProps, getIn} from 'formik';
import {
  BaseField,
  RadioField,
  PullDownField,
  CheckboxField,
  NumericField,
  SingleLineTextField,
  MultiLineTextField,
  DateField,
  TimeField,
} from '@components/molecules/InspectionTableFormItems';
import {
  EMPTY_REQUIRED_INSPECTION_ITEM_VALUE_ERROR,
  ErrorTypeToFormError,
  FormError as FormErrorType,
  FormValue,
  INSPECTION_NUMERIC_ITEM_VALIDATION_ERROR,
  INSPECTION_SELECT_ITEM_VALIDATION_ERROR,
} from '@Apps/InspectionResult/common/types';
import {InvalidValueError} from '@components/molecules/InspectionTableFormItems/InvalidValueError';
import {FormError as FormErrorView} from '@components/molecules/InspectionTableFormItems/FormError';
import _ from 'lodash';
import {InspectionItem} from '@modules/inspections/api';

type ItemFieldProps = {
  field: FieldInputProps<FormValue['items'][string]>;
  meta: FieldMetaProps<FormValue['items'][string]>;
  form: FormikProps<FormValue['items'][string]>;
};

type FormErrorProps = {
  error?: string;
};

// TODO:HIT-4529 SP参照あり
export const FormError = (props: FormErrorProps) => {
  const {error} = props;

  const errorText = useMemo(() => {
    if (error === undefined || !(error in ErrorTypeToFormError)) {
      return null;
    }

    return ErrorTypeToFormError[error as FormErrorType['type']];
  }, [error]);

  return errorText ? <FormErrorView error={errorText} /> : null;
};

type ItemInnerProps = {
  item: InspectionItem & {id: string};
};

const ItemInner: React.FC<ItemInnerProps & ItemFieldProps> = (props) => {
  const {item, form, field, meta} = props;

  const onChange = useCallback(
    (key: string, value: string | {[key: string]: boolean}) => {
      form.setFieldValue(`${field.name}.${key}`, value);
      form.setFieldTouched(field.name, true, false);
    },
    [field.name, form]
  );

  const value = useMemo(() => {
    switch (item.type) {
      case 'select':
      case 'numeric':
      case 'text':
      case 'multiline-text':
      case 'date':
      case 'time':
        return getIn(field.value, 'value');
      // multiple values
      case 'multi-select':
        return getIn(field.value, 'values');
      case null:
        return null;
    }
  }, [item.type, field.value]);

  const error = useMemo(() => {
    if (item.type === null || !meta.touched) {
      return null;
    }

    const rawError = item.type !== 'multi-select' ? getIn(meta.error, 'value') : getIn(meta.error, 'values');

    switch (item.type) {
      case 'select':
      case 'multi-select':
        return rawError === INSPECTION_SELECT_ITEM_VALIDATION_ERROR ? (
          <InvalidValueError />
        ) : (
          <FormError error={rawError} />
        );

      case 'numeric': {
        if (_.isString(rawError) && rawError !== EMPTY_REQUIRED_INSPECTION_ITEM_VALUE_ERROR) {
          try {
            const {error: errorCode, customError} = JSON.parse(rawError);
            if (errorCode === INSPECTION_NUMERIC_ITEM_VALIDATION_ERROR) {
              const validationErrorText = (customError ?? '').length > 0 ? customError : undefined;

              return <Grid style={{height: '32px'}}>{<InvalidValueError text={validationErrorText} />}</Grid>;
            }
          } catch (_e) {
            // empty
          }
        }
        return <FormError error={rawError} />;
      }

      case 'text':
      case 'multiline-text':
      case 'date':
      case 'time':
        return <FormError error={rawError} />;
    }
  }, [item.type, meta.touched, meta.error]);

  if (item.type === null || item.type === 'section' || item.type === 'bool') {
    // should not reach here
    return null;
  }

  return (
    <BaseField item={item}>
      {(() => {
        switch (item.type) {
          case 'select':
            if (item.view === 'radio') {
              return (
                <RadioField
                  options={item.options}
                  isEditable={true}
                  value={value}
                  error={error}
                  onChange={(v) => onChange('value', v)}
                />
              );
            } else if (item.view === 'pull-down') {
              return (
                <PullDownField
                  options={item.options}
                  isEditable={true}
                  value={value}
                  error={error}
                  onChange={(v) => onChange('value', v)}
                />
              );
            }
            break;
          case 'multi-select':
            return (
              <CheckboxField
                options={item.options}
                isEditable={true}
                values={value}
                error={error}
                onChange={(v) => onChange('values', v)}
              />
            );
          case 'numeric':
            return (
              <NumericField
                unit={item.unit}
                isEditable={true}
                showsError={item.required}
                value={value}
                error={error}
                onChange={(v) => onChange('value', v)}
                validator={item.validator ?? null}
              />
            );
          case 'text':
            return (
              <SingleLineTextField
                placeholder="回答を入力"
                isEditable={true}
                showsError={item.required}
                value={value}
                error={error}
                onChange={(v) => onChange('value', v)}
              />
            );
          case 'multiline-text':
            return (
              <MultiLineTextField
                placeholder="回答を入力"
                isEditable={true}
                showsError={item.required}
                value={value}
                error={error}
                onChange={(v) => onChange('value', v)}
              />
            );
          case 'date':
            return (
              <DateField
                isEditable={true}
                showsError={item.required}
                value={value}
                error={error}
                onChange={(v) => onChange('value', v)}
              />
            );
          case 'time':
            return (
              <TimeField
                isEditable={true}
                showsError={item.required}
                value={value}
                error={error}
                onChange={(v) => onChange('value', v)}
              />
            );
          default:
            return null;
        }
      })()}
    </BaseField>
  );
};

export const Item: React.FC<ItemInnerProps> = memo((props) => {
  const id = props.item.id;

  return (
    <FastField name={`items[${id}]`}>
      {({field, form, meta}: ItemFieldProps) => <ItemInner {...props} field={field} meta={meta} form={form} />}
    </FastField>
  );
});
