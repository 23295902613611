import React, {useCallback, useMemo} from 'react';
import {Grid, TextField, styled} from '@mui/material';
import {Search} from '@mui/icons-material';
import {PopperSelectBoxButton, SelectOptionProps} from '@molecules/Buttons/PopperSelectBoxButton';
import {InspectionStatus, InspectionType} from '@modules/inspections/enum';
import {MenuItemType, PopperMenuButton} from '@molecules/Buttons/PopperMenuButton';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {TableLayoutDialog, TableLayoutDialogProps} from '@organisms/Table/TableLayoutDialog';
import {TableLayoutResult} from '@modules/table_layout/hooks/useTableLayout';

const inspectionOptions: {label: string; value: InspectionType}[] = [
  {
    label: '使用前点検',
    value: 'pre_use',
  },
  {
    label: '使用中点検',
    value: 'in_use',
  },
  {
    label: '使用後点検',
    value: 'post_use',
  },
  {
    label: '定期点検',
    value: 'periodic',
  },
];

const statusOptions: {label: string; value: InspectionStatus}[] = [
  {
    label: '利用中',
    value: 'available',
  },
  {
    label: '下書き',
    value: 'draft',
  },
];

const actionMenuItems = [
  {
    label: '表示項目を変更',
    value: 'changeLayout',
  },
];

const StyledTextField = styled(TextField)(({theme}) => ({
  width: '270px',
  backgroundColor: theme.palette.common.white,
  fontSize: '14px',
}));
const StyledFlex = styled('div')({
  flexGrow: 1,
});

type SearchBarProps = {
  defaultSearchName: string;
  defaultType: string | null;
  defaultStatus: string | null;
  tableLayout?: TableLayoutResult;
  onChangeSearchName: React.ChangeEventHandler<HTMLInputElement>;
  onChangeType: (type: InspectionType | null) => void;
  onChangeStatus: (status: InspectionStatus | null) => void;
  onChangeTableLayout: (layout: TableLayoutResult) => void;
};

export const SearchBar = (props: SearchBarProps) => {
  const {
    defaultSearchName,
    defaultType,
    defaultStatus,
    tableLayout,
    onChangeSearchName,
    onChangeType,
    onChangeStatus,
    onChangeTableLayout,
  } = props;

  const initialOption = useMemo(() => {
    return defaultType !== null ? inspectionOptions.find((o) => o.value === defaultType) : undefined;
  }, [defaultType]);

  const initialStatusOption = useMemo(() => {
    return defaultStatus !== null ? statusOptions.find((o) => o.value === defaultStatus) : undefined;
  }, [defaultStatus]);

  const handleChangeType = useCallback(
    (selected?: SelectOptionProps) => {
      onChangeType(selected?.value ? (selected.value as InspectionType) : null);
    },
    [onChangeType]
  );

  const handleChangeStatusType = useCallback(
    (selected?: SelectOptionProps) => {
      onChangeStatus(selected?.value ? (selected.value as InspectionStatus) : null);
    },
    [onChangeStatus]
  );

  const handleChangeLayout = useCallback(async () => {
    try {
      const currentLayout = await dialogHandler.open<TableLayoutDialogProps>(TableLayoutDialog, {
        tableColumns: tableLayout?.tableLayout ?? [],
        defaultOptions: tableLayout?.currentLayout ?? [],
        forceValue: tableLayout?.forceValue ?? {},
      });
      const newTableLayout = {
        tableLayout: tableLayout?.tableLayout ?? [],
        currentLayout: currentLayout,
      };
      onChangeTableLayout(newTableLayout);
    } catch (_e) {
      console.error(_e);
    }
  }, [onChangeTableLayout, tableLayout]);

  const handleActionMenuClick = useCallback(
    (item: MenuItemType) => {
      switch (item.value) {
        case 'changeLayout':
          handleChangeLayout();
          break;
      }
    },
    [handleChangeLayout]
  );

  return (
    <Grid container sx={{marginTop: '8px'}}>
      <StyledTextField
        label="点検表の名前を検索"
        variant="outlined"
        size="small"
        InputProps={{
          endAdornment: <Search />,
        }}
        InputLabelProps={{
          style: {
            fontSize: 14,
          },
        }}
        defaultValue={defaultSearchName}
        onChange={onChangeSearchName}
      />
      <PopperSelectBoxButton
        buttonLabel="ステータス"
        options={statusOptions}
        isMulti={false}
        searchable={false}
        initialOption={initialStatusOption}
        onChange={handleChangeStatusType}
      />
      <PopperSelectBoxButton
        buttonLabel="すべての点検タイプ"
        options={inspectionOptions}
        isMulti={false}
        searchable={false}
        initialOption={initialOption}
        onChange={handleChangeType}
      />
      <StyledFlex />
      <PopperMenuButton menuItemList={actionMenuItems} onMenuClick={handleActionMenuClick}>
        アクション
      </PopperMenuButton>
    </Grid>
  );
};
