import React, {Suspense, useCallback, useEffect, useMemo} from 'react';
import {Formik} from 'formik';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles} from '@material-ui/core';
import {yup} from '@front-libs/core';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import {PropertyRequirements, RepairIndex} from '@modules/repairs/types';
import {UserIndex} from '@modules/hospital_users/types';
import {PropertyForm} from './PropertyForm';

type PropertyProposalDialogProps = {
  statusName: string;
  propertyRequirements: PropertyRequirements[];
  defaultFaultRepair: RepairIndex;
  hospitalUsers: UserIndex[];
} & Omit<DialogProps<Partial<RepairIndex>>, 'content'>;

export const PropertyProposalDialog: React.FC<PropertyProposalDialogProps> = (props) => {
  useEffect(() => {
    if (props.propertyRequirements.length === 0) props.actions.resolve({});
  }, [props.actions, props.propertyRequirements.length]);
  const classes = useStyles();
  const validationSchema = useMemo(
    () =>
      yup
        .object()
        .shape(
          props.propertyRequirements.reduce(
            (obj, item) => (item.isRequired ? {...obj, [item.property]: yup.mixed().required()} : obj),
            {}
          )
        ),
    [props.propertyRequirements]
  );

  const handleSubmit = useCallback(
    (values: RepairIndex) => {
      props.actions.resolve(values);
    },
    [props.actions]
  );

  return (
    <Dialog {...props} fullWidth>
      <Formik<RepairIndex>
        initialValues={props.defaultFaultRepair}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={handleSubmit}>
        {(formikProps) => (
          <>
            <DialogTitle>{`${props.statusName}へ移動`}</DialogTitle>
            <DialogContent>
              <Grid container className={classes.fields} direction="column">
                <Suspense fallback={null}>
                  <PropertyForm
                    propertyRequirements={props.propertyRequirements}
                    defaultFaultRepair={props.defaultFaultRepair}
                    hospitalUsers={props.hospitalUsers}
                  />
                </Suspense>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                variant={'contained'}
                onClick={formikProps.submitForm}
                color="primary"
                disabled={!formikProps.isValid}>
                登録
              </Button>
              <Button onClick={() => props.actions.reject()} color="primary">
                キャンセル
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

const useStyles = makeStyles(() => ({
  title: {
    padding: '24px 48px 24px',
  },
  divider: {
    marginLeft: '0px',
    marginRight: '0px',
    width: '100%',
  },
  content: {
    padding: '24px 48px 0px',
  },
  fields: {
    '& > div': {
      marginTop: '16px',
    },
  },
  fileInput: {
    visibility: 'hidden',
  },
  inputName: {
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  actions: {
    padding: '0px 48px 24px',
  },
}));
