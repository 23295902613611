import React from 'react';
import {Table, TableContainer, Paper, Button} from '@mui/material';
import {CreateWholeProductListBody} from './CreateWholeProductListBody';
import {CreateWholeProductHeader} from './ui/CreateWholeProductHeader';
import {ADD_ACTION, CreateWholeProductPlanAction} from './utils/reducer';
import {CreateWholeProductPlan} from './type';

type CreateWholeProductListProps = {
  inspectionSettings: CreateWholeProductPlan[];
  dispatch: React.Dispatch<CreateWholeProductPlanAction>;
};

export const CreateWholeProductList = ({inspectionSettings, dispatch}: CreateWholeProductListProps) => {
  return (
    <TableContainer component={Paper} style={{boxShadow: 'none'}}>
      <Table sx={{'& .MuiTableCell-root': {paddingBottom: '16px'}}}>
        <CreateWholeProductHeader />
        <CreateWholeProductListBody inspectionSettings={inspectionSettings} dispatch={dispatch} />
      </Table>
      <Button onClick={() => dispatch({type: ADD_ACTION})} color={'primary'}>
        + 新規点検を追加
      </Button>
    </TableContainer>
  );
};
