import {HospitalUserState, HospitalUserAction, HospitalUserActionType} from './types';

export const hospitalUserInitialState: HospitalUserState = {
  users: [],
};

export const hospitalUserReducer = (state: HospitalUserState, action: HospitalUserAction): HospitalUserState => {
  switch (action.type) {
    case HospitalUserActionType.HOSPITAL_USER_SET_LIST: {
      return {
        ...state,
        users: action.payload.users,
      };
    }
    default:
      return state;
  }
};
