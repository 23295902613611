import {atomWithReset, atomWithStorage} from 'jotai/utils';
import {TabKey, RadioKey, DEFAULT_ORDER} from './Header/consts';
import {atom} from 'jotai';
import {HospitalWard} from '@modules/hospital_wards/types';
import {HospitalFloorType} from '../types';

export const radioAtom = atomWithReset<RadioKey>(DEFAULT_ORDER);

export const tabAtom = atomWithReset<TabKey>('ward');

export const filterWardAtom = atomWithReset<HospitalWard[]>([]);

export const filterFloorAtom = atomWithStorage<HospitalFloorType | null>('filterFloor', null);

const LOCAL_STORAGE_KEY_LAYOUT_SETTING_SELECTED_WARD_STATUS = 'hitotsu/layout_setting_selected_ward_status';
const LOCAL_STORAGE_KEY_LAYOUT_SETTING_SELECTED_FLOOR_STATUS = 'hitotsu/layout_setting_selected_floor_status';

export const layoutSettingSelectedWardStatus = atomWithStorage<{label: string; value: string}>(
  LOCAL_STORAGE_KEY_LAYOUT_SETTING_SELECTED_WARD_STATUS,
  {
    label: '',
    value: '',
  }
);

export const layoutSettingSelectedFloorStatus = atomWithStorage<{
  label: string;
  value: string;
  floorNumber: string;
  isGroundFloor: boolean;
} | null>(LOCAL_STORAGE_KEY_LAYOUT_SETTING_SELECTED_FLOOR_STATUS, {
  label: '',
  value: '',
  floorNumber: '',
  isGroundFloor: false,
});

type layoutSettingRoomVariables = {
  hospitalWardHashId: string | undefined;
  floorNumber: string | undefined;
  isGroundFloor: boolean | undefined;
};

export const layoutSettingRoomVariablesAtom = atom<layoutSettingRoomVariables>((get) => {
  const selectedWard = get(layoutSettingSelectedWardStatus);
  const selectedFloor = get(layoutSettingSelectedFloorStatus);

  return {
    hospitalWardHashId: selectedWard.value !== '' ? selectedWard.value : undefined,
    floorNumber: selectedFloor?.floorNumber !== undefined ? selectedFloor.floorNumber : undefined,
    isGroundFloor: selectedFloor?.isGroundFloor !== undefined ? selectedFloor.isGroundFloor : undefined,
  };
});
