import React, {memo, useCallback} from 'react';
import {Form, useFormikContext} from 'formik';
import {Box, Button, Grid, styled} from '@material-ui/core';
import {FetchHospitalProductsParams, getHospitalProducts} from '@modules/hospital_products/api';
import {symptomDetailCategoryOptions} from '@modules/repairs/constants';
import {RepairIndex, SymptomCategoryType, SymptomDetailCategoryType} from '@modules/repairs/types';
import {TextField} from '@molecules/Formik/fields';
import AsyncSelector from '@molecules/Formik/fields/AsyncSelector';
import Selector from '@molecules/Formik/fields/Selector';
import {SelectOptionProps} from '@molecules/Formik/fields/StandardSelectorField';
import {RequiredLabelBadge} from '@components/atoms/RequiredLabelBadge';

const FullWidthMB16Div = styled('div')({
  width: '100%',
  marginBottom: '16px',
});
const FullWidthForm = styled(Form)({
  width: '100%',
});

const LabelBox = styled(Box)({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  marginBottom: '8px',
  fontSize: '14px',
});

const ScrollBox = styled(Box)(() => ({
  overflowX: 'hidden',
  overflowY: 'auto',
  height: 'calc(100vh - 254px);',
  marginTop: '16px',
}));
const UnderButtonBox = styled(Box)(() => ({
  width: '100%',
  height: '54px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  marginBottom: '42px',
  gap: '16px',
}));

type RegistrationRepairFormProps = {
  hospitalProductHashId: string;
  symptomCategory?: SymptomCategoryType;
  symptomDetailCategory?: SymptomDetailCategoryType;
  causeOfIssueDetail?: string;
  requestUserHashId?: string;
  requests?: string;
  requestForRepairAt?: string;
  substitutionHospitalProductHashId?: string;
  statusHashId?: string;
};

type FormFieldsType = {
  value: keyof RepairIndex;
  label: string;
  type: string;
  isRequired: boolean | undefined;
  sequence: number | undefined;
  options: SelectOptionProps[] | undefined;
};

type RepairFromProps = {
  formFields: FormFieldsType[];
  hospitalHashId: string;
  onClose: VoidFunction;
};

type FieldProps = {
  field: FormFieldsType;

  symptomCategory?: string;
};

/**
 * 事象詳細区分フィールド
 */
const SymptomCategoryField = memo(
  ({field, symptomCategory}: FieldProps) => {
    return (
      <>
        <FullWidthMB16Div>
          <LabelBox>
            <label>{field.label}</label>
            {field.isRequired && <RequiredLabelBadge />}
          </LabelBox>
          <Selector name={field.value} size={'small'} options={field.options} />
        </FullWidthMB16Div>
        {symptomCategory === 'internal_failure' && (
          <FullWidthMB16Div>
            <LabelBox>
              <label>事象詳細区分</label>
            </LabelBox>
            <Selector name="symptomDetailCategory" size={'small'} options={symptomDetailCategoryOptions} />
          </FullWidthMB16Div>
        )}
      </>
    );
  },
  (prev, next) => prev.symptomCategory === next.symptomCategory
);

/**
 * テキストもしくはセレクトボックスのフィールド
 */
const NormalField = memo(
  ({field}: FieldProps) => {
    return (
      <FullWidthMB16Div>
        <LabelBox>
          <label>{field.label}</label>
          {field.isRequired && <RequiredLabelBadge />}
        </LabelBox>

        {field.type === 'select' ? (
          <Selector name={field.value} size={'small'} options={field.options} />
        ) : (
          <TextField
            type={field.type === 'datetime' || field.value === 'dateOfDisposal' ? 'date' : field.type}
            name={field.value}
            size={'small'}
            fullWidth
          />
        )}
      </FullWidthMB16Div>
    );
  },
  (prev, next) => true
);

/**
 * 修理対象機器のフィールド
 */
const RepairTargetItemField = memo(
  ({hospitalHashId}: {hospitalHashId: string}) => {
    const loadHospitalProductOptions = useCallback(
      async (newValue: string) => {
        const baseParams: FetchHospitalProductsParams = {
          perPage: 100,
          statuses: 'ready,working,uninspected,repairing',
          order: 'management_id',
        };
        if (newValue !== '') baseParams.name = newValue;
        const res = await getHospitalProducts(hospitalHashId, baseParams);
        return res.data.map((item) => ({label: item.managementId, value: item.hashId}));
      },
      [hospitalHashId]
    );

    return (
      <FullWidthMB16Div>
        <LabelBox>
          <label>修理対象機器</label>
          <RequiredLabelBadge />
        </LabelBox>
        <AsyncSelector
          name="hospitalProductHashId"
          placeholder={'管理番号で検索...'}
          loadOptions={loadHospitalProductOptions}
        />
      </FullWidthMB16Div>
    );
  },
  (prev, next) => true
);

/**
 * 修理フォーム
 * components/organisms/FaultRepairs/dialogs/RegistrationRepairDialog.tsxとほぼ同じ
 * @param param0
 * @returns
 */
export const RepairForm: React.FC<RepairFromProps> = memo(
  ({formFields, hospitalHashId, onClose}: RepairFromProps) => {
    const {isValid, submitForm, values} = useFormikContext<Partial<RegistrationRepairFormProps>>();
    return (
      <>
        <ScrollBox>
          <Grid container>
            <FullWidthForm>
              <RepairTargetItemField hospitalHashId={hospitalHashId} />
              {formFields.map((field, index) => {
                if (field.value === 'symptomCategory') {
                  // 事象詳細区分は事象区分（symptomCategory）が内部故障として選択されたときのみ表示する
                  return (
                    <SymptomCategoryField
                      field={field}
                      key={`field_${index}`}
                      symptomCategory={values.symptomCategory || ''}
                    />
                  );
                } else {
                  return <NormalField field={field} key={`field_${index}`} />;
                }
              })}
            </FullWidthForm>
          </Grid>
        </ScrollBox>
        <UnderButtonBox>
          <Button color="primary" variant="contained" onClick={submitForm} disabled={!isValid}>
            保存
          </Button>
          <Button color="primary" variant="text" onClick={onClose}>
            キャンセル
          </Button>
        </UnderButtonBox>
      </>
    );
  },
  (prev, next) => prev.formFields === next.formFields
);
