import {ProductClassType} from './types';

export const productClassTypeToLabel: {[key in ProductClassType]: string} = {
  one: 'Ⅰ',
  two: 'Ⅱ',
  three: 'Ⅲ',
  four: 'Ⅳ',
};

export const InspectionProductUnPlanned = 'un_planned';
export const InspectionProductPlanned = 'planned';

export const productClassTypeToClassNameLabel: {[key in ProductClassType]: string} = {
  one: '一般医療機器（クラスI）',
  two: '管理医療機器（クラスII）',
  three: '高度管理医療機器（クラスIII）',
  four: '高度管理医療機器（クラスIV）',
};
