import React from 'react';
import {Box} from '@mui/material';
import {Forward} from '@mui/icons-material';
import {RentalHistoryIndex} from '@modules/rentals/types';
import {CategoryFormatter} from '@modules/categories/helpers';
import {BaseSharedListTable, BaseSharedListTableColumn, tableTitleStyle} from '../BaseSharedListTable';
import {CancelButton} from '../CancelButton';

type RowData = {
  managementId: string;
  rentalRoom: string | undefined;
  forwardIcon: JSX.Element | null;
  returnRoom: string;
  narrowCategoryName: string;
  displayName: string;
  cancelButton: JSX.Element | null;
};

const columns: BaseSharedListTableColumn<RowData>[] = [
  {id: 'managementId', label: '管理番号', minWidth: '120px'},
  {id: 'rentalRoom', label: '貸出場所', minWidth: '72px'},
  {id: 'forwardIcon', label: '', minWidth: '16px'},
  {id: 'returnRoom', label: '返却場所', minWidth: '72px'},
  {id: 'narrowCategoryName', label: '小分類', minWidth: '72px'},
  {id: 'displayName', label: '機種名', minWidth: '120px'},
  {id: 'cancelButton', label: '', minWidth: 'fit-content', sx: {width: 'fix-content'}},
];

type TableProps = {
  returnHospitalProducts: RentalHistoryIndex[];
  onClickCancel: (rowIndex: number) => void;
};

export const RentalTable = ({returnHospitalProducts, onClickCancel}: TableProps) => {
  const tableRows: RowData[] = returnHospitalProducts.map(({rentalHospitalProduct, rentalRoom}, index) => ({
    managementId: rentalHospitalProduct.managementId ?? '',
    forwardIcon: rentalHospitalProduct.managementId ? <Forward /> : null,
    narrowCategoryName: CategoryFormatter.getNarrowCategory(rentalHospitalProduct.categories ?? [])?.name ?? '',
    displayName: rentalHospitalProduct.displayName ?? '',
    rentalRoom: rentalRoom?.name ?? '',
    returnRoom: rentalHospitalProduct?.hospitalRoom?.name ?? '',
    cancelButton: rentalHospitalProduct.managementId ? (
      <CancelButton
        onClickCancel={() => {
          onClickCancel(index);
        }}
      />
    ) : null,
  }));

  return (
    <>
      <Box sx={tableTitleStyle}>返却する医療機器リスト</Box>
      <BaseSharedListTable<RowData> tableRows={tableRows} columns={columns} />
    </>
  );
};
