import React, {useCallback} from 'react';
import {FormControlLabel, FormControl, FormGroup, Checkbox} from '@material-ui/core';

type CheckboxFieldProps = {
  values: {[key: string]: boolean};
  options: {label: string; value: string}[];
  error?: React.ReactNode;
  isEditable: boolean;
  onChange?: (values: {[key: string]: boolean}) => void;
};

export const CheckboxField: React.FC<CheckboxFieldProps> = (props) => {
  const {values, options, error, isEditable, onChange} = props;

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (isEditable && onChange) {
        onChange({
          ...values,
          [e.target.value]: e.target.checked,
        });
      }
    },
    [isEditable, onChange, values]
  );

  return (
    <FormControl component="fieldset">
      <FormGroup>
        {options.map((option, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                name={option.value}
                value={option.value}
                checked={(values ?? {})[option.value] ?? false}
                onChange={handleChange}
                inputProps={{
                  readOnly: !isEditable,
                }}
              />
            }
            label={option.label}
          />
        ))}
      </FormGroup>
      {error}
    </FormControl>
  );
};
