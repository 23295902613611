import {ThemeProvider, createTheme} from '@mui/material';
import React from 'react';

// ブレークポイントの型を拡張
declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    tabletV: true; // adds the `tablet` breakpoint
    tabletH: true;
    laptop: true;
    desktop: true;
    largeDesktop: true;
  }
}

// `rent`をパレットに追加するために型を拡張
declare module '@mui/material/styles' {
  interface Palette {
    rent: Palette['primary'];
    green: Palette['primary'];
  }
  interface PaletteOptions {
    rent?: PaletteOptions['primary'];
    green?: PaletteOptions['primary'];
  }
}

// Material UIのテーマ上書き
export const theme = createTheme({
  typography: {
    fontSize: 14,
  },

  palette: {
    mode: 'light', // 'type' は 'mode' に変更された
    common: {
      black: '#050505',
      white: '#ffffff',
    },
    primary: {
      light: '#D6E8FC',
      main: '#0A52CC',
      dark: '#003FB5',
    },
    secondary: {
      light: '#E2F4FB',
      main: '#6DC0FF',
      dark: '#2A96E8',
    },
    grey: {
      50: '#F0F2F5',
      100: '#E4E6EC',
      300: '#E0E0E0',
      400: '#D9DFE6',
      600: '#65676B',
      700: '#46484B',
      900: '#172B4D',
    },
    info: {
      light: '#E2F4FB',
      main: '#6DC0FF',
      dark: '#2A96E8',
    },
    success: {
      main: '#4caf50',
    },
    error: {
      main: '#C7243A',
    },
    rent: {
      light: '#2A96E8',
      main: '#0052CC',
      dark: '#0E3870',
    },
    green: {
      light: '#4CAF50',
      main: '#3C9E58',
    },
  },
  zIndex: {
    tooltip: 2147483647, //modalのzIndexとして極端に高い値を指定
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        root: {
          zIndex: 21474836475, // hub spotのzIndex＋１
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none', // ボタン内アルファベット文字を大文字変換しない
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536, // xs ~ xl の値はデフォルトと同義
      tabletV: 768,
      tabletH: 1024,
      laptop: 1280,
      desktop: 1536,
      largeDesktop: 2008,
    },
  },
});

export const MuiV5ThemeProvider = ({children}: {children: React.ReactNode}) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
