import React, {useState} from 'react';
import {HospitalProductIndex} from '@modules/hospital_products/types';
import {MainMenu} from './MenuContent/MainMenu';
import {InspectionStartMenu} from './MenuContent/InspectionStartMenu';
import {FaultRepairMenu} from './MenuContent/FaultRepairMenu';
import {useFaultRepairMenuStore} from '../hooks';
import {MenuItemValue} from '../types';

type Props = {
  hospitalProduct: HospitalProductIndex;
  onBack: () => void;
};

export const Menu = ({hospitalProduct, onBack}: Props) => {
  const [selectedMenuItem, setSelectedMenuItem] = useState<MenuItemValue | null>(null);
  const {repairHistoryState, listRepairHistoriesByHospitalProductHashId} = useFaultRepairMenuStore();

  const handleSelectMenuItem = async (value: MenuItemValue) => {
    switch (value) {
      case 'hospitalProductDetail':
        window.open(`/products/${hospitalProduct.hashId}`, '_blank');
        break;
      case 'faultRepairDetail':
        await listRepairHistoriesByHospitalProductHashId(hospitalProduct.hashId);
        break;
      case 'inspectionStart':
        break;
      default:
        console.error('not implemented: ', value);
    }
    setSelectedMenuItem(value);
  };

  const resetSelectedMenuItem = () => {
    setSelectedMenuItem(null);
  };

  const handleBack = () => {
    resetSelectedMenuItem();
    onBack();
  };

  const handleBackToMainMenu = () => {
    resetSelectedMenuItem();
  };

  switch (selectedMenuItem) {
    case 'inspectionStart':
      return <InspectionStartMenu hospitalProduct={hospitalProduct} onBack={handleBackToMainMenu} />;
    case 'faultRepairDetail':
      return <FaultRepairMenu repairs={repairHistoryState.repairs} onBack={handleBackToMainMenu} />;
    default:
      return <MainMenu hospitalProduct={hospitalProduct} onBack={handleBack} onSubmit={handleSelectMenuItem} />;
  }
};
