export const RentalSteps = [
  {label: '持出者登録', value: 0},
  {label: '貸出先登録', value: 1},
  {label: '貸出機器登録', value: 2},
  {label: '完了', value: 3},
];

export const ReturnSteps = [
  {label: '返却者登録', value: 0},
  {label: '返却機器登録', value: 1},
  {label: '完了', value: 2},
];
