import {Box, Button, CSSObject} from '@mui/material';
import React from 'react';

type Props = {
  handleEditWholeProduct: () => void;
  handleBackClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled: boolean;
};

const NextButtonStyle: CSSObject = {
  marginRight: '36px',
  backgroundColor: 'white',
  '&.Mui-disabled': {
    backgroundColor: '#D1D5DB',
  },
};

export const Footer = ({handleEditWholeProduct, handleBackClick, disabled}: Props) => {
  return (
    <Box
      sx={{
        padding: '20px 40px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: '#F2F6FC',
      }}>
      <Button sx={{backgroundColor: 'white', border: '1px solid #0052CC'}} onClick={handleBackClick}>
        機器一覧に戻る
      </Button>
      <Button sx={NextButtonStyle} onClick={handleEditWholeProduct} disabled={disabled}>
        次へ
      </Button>
    </Box>
  );
};
