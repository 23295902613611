import React from 'react';
import {BackButtonFooter} from '../BackButtonFooter';
import {Box, Paper} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {FaultReceptionHeader} from '@components/molecules/FaultReceptionHeader';
import {Content} from '@Apps/RentableDevices/Content';

export const ReturnWaitingReception = () => {
  const navigate = useNavigate();
  return (
    <Box>
      <FaultReceptionHeader
        title="貸出可能機器の閲覧・返却待ち受付"
        text="返却待ち受付をする場合は、貸出可能数/保有数の横にある「返却待ち受付」ボタンを押してください。"
      />
      <Paper
        sx={{
          backgroundColor: 'white',
          height: 'calc(100vh - 162px)',
          maxWidth: '860px',
          width: '90%',
          margin: '32px auto',
          borderRadius: '10px',
        }}>
        <Content isReturnWaitButton={true} />
      </Paper>
      <BackButtonFooter onClick={() => navigate('/shared/reception_menu')} />
    </Box>
  );
};
