import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {styled, Box, Link, Typography} from '@mui/material';
import {trainingTypeLabelMap} from '@modules/training_plans/constants';
import {TrainingTypeValue} from '@modules/training_plans/types';
import {TrainingSchedule} from '@modules/training_schedules/types';
import {convertDisplaySimpleDate} from '@front-libs/helpers';

type Props = {
  schedules: TrainingSchedule[];
};

const Container = styled(Box)({
  padding: '16px 32px',
  fontSize: '14px',
  '& > div:nth-of-type(n+2)': {
    marginTop: '24px',
  },
});

const Item = styled(Box)({
  '& > div:nth-of-type(n+2)': {
    marginTop: '8px',
  },
});

const Field = styled(Box)({
  display: 'flex',
});

const Label = styled(Typography)({
  width: '64px',
  fontSize: 'inherit',
});

const Value = styled(Typography)({
  fontSize: 'inherit',
});

export const List = ({schedules}: Props) => {
  const convTrainingTypeLabel = (trainingType: TrainingTypeValue) => {
    return trainingTypeLabelMap[trainingType];
  };

  return (
    <Container>
      {schedules.map((schedule, i) => (
        <Item key={`training-schedule-${i}`}>
          <Field>
            <Link
              component={RouterLink}
              sx={{fontSize: 'inherit', fontWeight: 700, color: '#2A96E8'}}
              underline="hover"
              to={`/training_managements/schedules/${schedule.hashId}`}>
              {schedule.trainingPlan.name} - {schedule.currentTimes}回目
            </Link>
          </Field>
          <Field>
            <Label>内容　：</Label>
            <Value>{schedule.trainingPlan.trainingTypes.map(convTrainingTypeLabel).join('、')}</Value>
          </Field>
          <Field>
            <Label>予定日：</Label>
            <Value>
              {convertDisplaySimpleDate(schedule.startAt)} 〜 {convertDisplaySimpleDate(schedule.finishAt)}
            </Value>
          </Field>
        </Item>
      ))}
    </Container>
  );
};
