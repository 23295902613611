import React, {useMemo} from 'react';
import {Typography, Box, SxProps} from '@mui/material';

const rootStyle: SxProps = {
  minWidth: '100px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
};

const statusStyle: SxProps = {
  flex: '0 0 65px',
  borderRadius: '80px',
  padding: '2px 0px',
  fontSize: '12px',
  fontWeight: 'bold',
  textAlign: 'center',
  borderWidth: '2px',
  borderStyle: 'solid',
};

const redStyle: SxProps = {
  ...statusStyle,
  background: '#feeaed',
  color: '#C7243A',
  borderColor: '#C7243A',
};

const yellowStyle: SxProps = {
  ...statusStyle,
  background: '#FEF4E5',
  color: '#FF9800',
  borderColor: '#FF9800',
};

const greenStyle: SxProps = {
  ...statusStyle,
  background: '#EAF5EA',
  color: '#3C9E58',
  borderColor: '#3C9E58',
};

type PercentBadgeProps = {
  percent: number;
};
/**
 * `PercentBadge`コンポーネント
 * パーセンテージ値を表示し、その値の範囲に応じた背景色で装飾されたバッジを表示します
 *
 * - 80以上の場合は赤色
 * - 50から79の間の場合は黄色
 * - 50未満の場合は緑色
 *
 * @param props.percent - 表示するパーセンテージ値
 */
export const PercentBadge = ({percent}: PercentBadgeProps) => {
  const displayPercent = useMemo(() => Math.round(percent), [percent]);

  const textStyle = useMemo(() => {
    if (displayPercent >= 80) {
      return redStyle;
    } else if (displayPercent >= 50) {
      return yellowStyle;
    } else {
      return greenStyle;
    }
  }, [displayPercent]);

  return (
    <Box sx={rootStyle}>
      <Typography sx={textStyle} variant="inherit">
        {displayPercent}%
      </Typography>
    </Box>
  );
};
