import {axios} from '@front-libs/core';
import {useInfiniteQuery, useQuery} from 'react-query';
import {
  getInspectionResultsKey,
  getInspectionResultFilesKey,
  getInspectionResultKey,
  getInspectionResultsCountsKey,
} from '@constants/api';
import {
  InspectionResultIndex,
  RequestExportInspectionResultCSVsParam,
  ResultItem,
} from '@modules/inspection_results/types';
import {InspectionResultEvaluation, InspectionResultStatus} from '@modules/inspection_results/enum';
import {FileIndex} from '@modules/files/types';
import {InspectionCategory, InspectionType} from '@modules/inspections/enum';
import {useEffect, useMemo} from 'react';
import {ViewInspectionResultStatuses} from '@Apps/InspectionResultList/pc/types';
import dayjs from 'dayjs';

export type CreateInspectionResultParam = {
  hospitalProductHashId: string;
  inspectionTableHashId?: string;
  inspectorHashId?: string;
  scheduledTime?: string;
  category: InspectionCategory;
  type?: InspectionType; // 点検新規作成用
  status: InspectionResultStatus;
  items: {[id: string]: ResultItem};
  comment?: string;
  isAdhoc?: boolean;
};

export type UpdateInspectionResultParam = {
  hospitalProductHashId?: string;
  inspectionHashId?: string;
  inspectionTableHashId?: string;
  inspectorHashId?: string;
  scheduledTime?: string;
  completedAt?: string;
  status?: InspectionResultStatus;
  items?: ResultItem[];
  comment?: string;
  skippedByHashId?: string;
  skippedTime?: string;
  skipReason?: string;
  changeHospitalProductStatusToReady?: boolean;
};

export type UpsertInspectionResultParam = UpdateInspectionResultParam & {
  type?: InspectionType;
};

export type NextUpdateInspectionStatusParam = {
  inspectorHashId: string;
  status: InspectionResultStatus;
};

export type UpdateInspectionResultsParam = {
  inspectionResults: {
    inspectionResultHashId: string;
    inspectorHashId?: string;
    scheduledTime?: string;
    type?: InspectionType;
    status?: InspectionResultStatus;
    comment?: string;
    skippedByHashId?: string;
    skippedTime?: string;
    skipReason?: string;
  }[];
};

export type DeleteInspectionResultsParam = {
  inspectionResultHashIds: string[];
};

export type CreateInspectionResultFilesParam = {
  fileHashIds: string[];
};

export type FetchInspectionResultsParam = {
  // pagination
  perPage?: number;
  page?: number;

  // search
  name?: string;
  order?: string;

  // condition
  hospitalProductHashId?: string;
  inspectionHashId?: string;
  inspectionTableHashId?: string;
  inspectionPlanHashId?: string;
  inspectorHashId?: string;
  types?: string;
  statuses?: string;
  result?: InspectionResultEvaluation;
  scheduledAtFrom?: string;
  scheduledAtTo?: string;
  completedAtFrom?: string;
  completedAtTo?: string;
  skippedAtFrom?: string;
  skippedAtTo?: string;
  categories?: string;
  isAdhoc?: boolean;
  updatedAtFrom?: string;
  hospitalWardHashId?: string;
  hospitalRoomHashId?: string;
  rentHospitalWardHashId?: string;
  rentHospitalRoomHashId?: string;
  inspectionName?: string;
  inspectionSettingName?: string;
};

export type FetchInspectionResultsResponse = {
  totalCount: number;
  page: number;
  data: InspectionResultIndex[];
};

export type ValidationType = 'invalid_result' | 'no_inspection_result' | 'difference_inspection';

export type CheckPreviousInspectionResults = {
  validationType: ValidationType;
  inspectionResultHashIds: string[];
};

export type CheckPreviousInspectionResultsParam = {
  inspectionResultHashIds: string;
};

export type ApplyPreviousInspectionResultsParam = {
  inspectionResultHashIds: string[];
  isDaily?: boolean;
};

export type RequestExportInspectionResultPDFsParam = {
  inspectionResultHashIds: string[];
};

export type ApplyPreviousInspectionResults = {
  inspectionResultHashId: string;
  succeed: boolean;
  inspectionResult: InspectionResultIndex;
};

export type GenerateInspectionResultPDFResult = {
  url: string;
  s3DownloadPath: string;
};

export const createInspectionResult = async (
  hospitalHashId: string,
  inspectionHashId: string,
  data: CreateInspectionResultParam
) => {
  const baseURL = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/inspections/${inspectionHashId}/results`;
  return await axios.post<InspectionResultIndex>(baseURL, data);
};

export const updateInspectionResult = async (
  hospitalHashId: string,
  inspectionHashId: string,
  inspectionResultHashId: string,
  data: UpdateInspectionResultParam
) => {
  const baseURL = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/inspections/${inspectionHashId}/results/${inspectionResultHashId}`;
  return await axios.put<InspectionResultIndex>(baseURL, data);
};

export const updateInspectionResults = async (
  hospitalHashId: string,
  inspectionHashId: string,
  data: UpdateInspectionResultsParam
) => {
  const baseURL = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/inspections/${inspectionHashId}/results/jobs`;
  return await axios.put<InspectionResultIndex[]>(baseURL, data);
};

export const deleteInspectionResults = async (
  hospitalHashId: string,
  inspectionHashId: string,
  data: DeleteInspectionResultsParam
) => {
  const baseURL = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/inspections/${inspectionHashId}/results/jobs`;
  return await axios.delete<number>(baseURL, {
    data,
  });
};

export const createInspectionResultFiles = async (
  hospitalHashId: string,
  inspectionHashId: string,
  inspectionResultHashId: string,
  data: CreateInspectionResultFilesParam
) => {
  const baseURL = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/inspections/${inspectionHashId}/results/${inspectionResultHashId}/files`;
  return await axios.post<void>(baseURL, data);
};

export const getInspectionResult = async (
  hospitalHashId: string,
  inspectionHashId: string,
  inspectionResultHashId: string
) => {
  const baseURL = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/inspections/${inspectionHashId}/results/${inspectionResultHashId}`;
  const {data} = await axios.get<InspectionResultIndex>(baseURL);
  return data;
};

export const getInspectionResults = async (
  hospitalHashId: string,
  // todo: inspectionHashId is necessary, but temporary disabled
  inspectionHashId: string,
  params: FetchInspectionResultsParam
) => {
  const baseURL = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/inspections/${inspectionHashId}/results`;
  const {data} = await axios.get<FetchInspectionResultsResponse>(baseURL, {params});
  return data;
};

export const getInspectionResultFiles = async (
  hospitalHashId: string,
  inspectionHashId: string,
  inspectionResultHashId: string,
  order?: string
) => {
  const baseURL = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/inspections/${inspectionHashId}/results/${inspectionResultHashId}/files`;
  const {data} = await axios.get<FileIndex[]>(baseURL, {params: {order}});
  return data;
};

export const checkPreviousInspectionResults = async (
  hospitalHashId: string,
  inspectionHashId: string,
  params: CheckPreviousInspectionResultsParam
) => {
  const baseURL = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/inspections/${inspectionHashId}/results/check-previous`;
  const {data} = await axios.get<CheckPreviousInspectionResults[]>(baseURL, {params});
  return data;
};

export const generateInspectionResultPDF = async (
  hospitalHashId: string,
  inspectionHashId: string,
  inspectionResultHashId: string
) => {
  const baseURL = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/inspections/${inspectionHashId}/results/${inspectionResultHashId}/pdf`;

  return await axios.post<GenerateInspectionResultPDFResult>(baseURL, {});
};

export const applyPreviousInspectionResults = async (
  hospitalHashId: string,
  inspectionHashId: string,
  data: ApplyPreviousInspectionResultsParam
) => {
  const baseURL = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/inspections/${inspectionHashId}/results/apply-previous`;
  return await axios.post<ApplyPreviousInspectionResults[]>(baseURL, data);
};

export const requestExportInspectionResultPDFs = async (
  hospitalHashId: string,
  inspectionHashId: string,
  data: RequestExportInspectionResultPDFsParam
) => {
  const baseURL = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/inspections/${inspectionHashId}/results/pdfs`;
  return await axios.post(baseURL, data);
};

export const useFetchInspectionResultQuery = (
  hospitalHashId: string,
  inspectionHashId: string,
  inspectionResultHashId: string
) => {
  return useQuery([getInspectionResultKey, hospitalHashId, inspectionHashId, inspectionResultHashId], () =>
    getInspectionResult(hospitalHashId, inspectionHashId, inspectionResultHashId)
  );
};

export const useFetchInspectionResultFilesQuery = (
  hospitalHashId: string,
  inspectionHashId: string,
  inspectionResultHashId: string,
  order?: string
) => {
  return useQuery([getInspectionResultFilesKey, hospitalHashId, inspectionHashId, inspectionResultHashId, order], () =>
    getInspectionResultFiles(hospitalHashId, inspectionHashId, inspectionResultHashId, order)
  );
};

export const useFetchInspectionResultsQuery = (
  hospitalHashId: string,
  // todo: inspectionHashId is necessary, but temporary disabled
  inspectionHashId: string,
  params: FetchInspectionResultsParam,
  options?: Partial<{enabled: boolean}>
) => {
  const query = useQuery(
    [getInspectionResultsKey, hospitalHashId, inspectionHashId, params],
    () => getInspectionResults(hospitalHashId, inspectionHashId, params),
    options
  );

  return useMemo(
    () => ({
      ...query,
      data: query.data?.data ?? [],
      totalCount: query.data?.totalCount,
    }),
    [query]
  );
};

export const useFetchInspectionResultsCountsQuery = (
  hospitalHashId: string,
  // todo: inspectionHashId is necessary, but temporary disabled
  inspectionHashId: string,
  type: string
) => {
  return useQuery([getInspectionResultsCountsKey, hospitalHashId, inspectionHashId, type], async () => {
    return Object.fromEntries(
      (
        await Promise.all(
          ViewInspectionResultStatuses.map((status) => {
            switch (status) {
              case 'unplanned':
                return getInspectionResults(hospitalHashId, inspectionHashId, {
                  perPage: 1,
                  types: type,
                  statuses: status,
                  scheduledAtFrom: type === 'periodic' ? dayjs(new Date()).startOf('month').toISOString() : undefined,
                });
              case 'overdue':
                return getInspectionResults(hospitalHashId, inspectionHashId, {
                  perPage: 1,
                  types: type,
                  statuses: 'unplanned',
                  scheduledAtTo: dayjs(new Date()).subtract(1, 'month').endOf('month').toISOString(),
                });
              default:
                return getInspectionResults(hospitalHashId, inspectionHashId, {
                  perPage: 1,
                  types: type,
                  statuses: status,
                });
            }
          })
        )
      ).map((res, index) => [ViewInspectionResultStatuses[index], res.totalCount])
    ) as Record<(typeof ViewInspectionResultStatuses)[number] | 'overdue', number>;
  });
};

// 点検計画出力 (Excel)
export const requestExportPeriodicInspectionScheduleTask = async (
  hospitalHashId: string,
  startedAt: string,
  finishedAt: string
) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/inspections/results/tasks/export`;
  return axios.post(baseUrl, {startedAt, finishedAt});
};

// 点検履歴 CSVエクスポート
export const requestExportInspectionResultCSVsTask = async (
  hospitalHashId: string,
  data: RequestExportInspectionResultCSVsParam
) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/inspections/results/tasks/export_csv`;
  return axios.post(baseUrl, data);
};

// オフラインでの自動更新用API
export const updateOfflineInspectionResult = async (
  hospitalHashId: string,
  inspectionHashId: string,
  inspectionResultHashId: string,
  data: UpdateInspectionResultParam
) => {
  const baseURL = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/offline/inspections/${inspectionHashId}/results/${inspectionResultHashId}`;
  return await axios.post(baseURL, data);
};

export const useFetchInfiniteInspectionResultsQuery = (
  hospitalHashId: string,
  inspectionHashId: string,
  params: FetchInspectionResultsParam
) => {
  const {data, isLoading, isFetching, hasNextPage, fetchNextPage, refetch} = useInfiniteQuery(
    [getInspectionResultsKey, hospitalHashId, params],
    ({pageParam = 0}) => {
      return getInspectionResults(hospitalHashId, inspectionHashId, {...params, page: pageParam});
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.data.length === 0) {
          return undefined; // データがない場合、次のページはなし
        }
        return allPages.length + 1; // 次のページ番号を返す
      },
    }
  );

  // 最後までスクロールしたらページを1ずつ増やす
  useEffect(() => {
    if (hasNextPage && !isFetching) {
      fetchNextPage(); // 引数なしで呼び出す
    }
  }, [data?.pages, hasNextPage, isFetching, isLoading, fetchNextPage]);

  return {data, isLoading, isFetching, hasNextPage, fetchNextPage, refetch};
};
