import React, {useCallback, useMemo, useState} from 'react';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useAtom, useAtomValue, useSetAtom} from 'jotai';
import {
  pageAtom,
  pageSizeAtom,
  searchNameAtom,
  searchRootCategoriesAtom,
  searchSubCategoriesAtom,
  selectedMakerAtom,
} from './jotai';
import {useResetAtom} from 'jotai/utils';
import {useDebounceCallback, useOnlyOnce} from '@front-libs/core';
import {useCategoryQuery} from '@Apps/ProductsList/pc/hooks';
import {SelectOptionProps} from '@molecules/Buttons/PopperSelectBoxButton';
import {CategoryIndex} from '@modules/categories/types';
import {CategoryFormatter} from '@modules/categories/helpers';
import {FetchCompaniesParam, useFetchCompaniesQuery} from '@modules/companies/api';
import {hospitalWholeProductVariables} from './states';
import {useFetchHospitalWholeProductsQuery} from '@modules/products/api';

const DEBOUNCE_DELAY = 300;

export const useResetOnMount = () => {
  const resetSearchName = useResetAtom(searchNameAtom);
  const resetRootCategory = useResetAtom(searchRootCategoriesAtom);
  const resetNarrowCategory = useResetAtom(searchSubCategoriesAtom);
  const resetMaker = useResetAtom(selectedMakerAtom);
  useOnlyOnce(() => {
    resetSearchName();
    resetRootCategory();
    resetNarrowCategory();
    resetMaker();
  });
};

export const useCategoryLogic = (hospitalHashId: string) => {
  const setSearchRootCategories = useSetAtom(searchRootCategoriesAtom);
  const setSearchSubCategories = useSetAtom(searchSubCategoriesAtom);
  const {rootCategoryQuery, narrowCategoryQuery, selectRootCategory, descendantCategoriesQueries} =
    useCategoryQuery(hospitalHashId);

  const handleChangeRootCategories = useDebounceCallback(
    (values: SelectOptionProps | null) => {
      const selectedValues = values ? [values.value] : null;
      selectRootCategory(selectedValues);
      setSearchRootCategories(selectedValues ?? []);
    },
    DEBOUNCE_DELAY,
    [setSearchRootCategories]
  );

  const handleChangeSubCategories = useDebounceCallback(
    (values: SelectOptionProps | null) => {
      const selectedValues = values ? [values.value] : null;
      setSearchSubCategories(selectedValues ?? []);
    },
    DEBOUNCE_DELAY,
    [setSearchSubCategories]
  );

  const descendantCategoryOptions = useMemo(() => {
    if (descendantCategoriesQueries.length > 0) {
      let catQuery: CategoryIndex[] = [];
      for (const descendantCategoriesQuery of descendantCategoriesQueries) {
        const filteredCategories = (descendantCategoriesQuery.data?.data ?? []).filter((item) => item.depth === 1);
        catQuery = [...catQuery, ...filteredCategories];
      }
      return CategoryFormatter.getOptions(catQuery);
    } else {
      return CategoryFormatter.getOptions(narrowCategoryQuery.data);
    }
  }, [descendantCategoriesQueries, narrowCategoryQuery.data]);

  return {
    handleChangeRootCategories,
    handleChangeSubCategories,
    descendantCategoryOptions,
    rootCategoryQuery,
  };
};

export const useSearchBarLogic = () => {
  const setSearchName = useSetAtom(searchNameAtom);
  const handleChangeSearchName = useDebounceCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchName(e.target.value);
    },
    DEBOUNCE_DELAY,
    []
  );

  return {
    handleChangeSearchName,
  };
};

export const useMakerSelectLogic = (hospitalHashId: string) => {
  const [fetchCompaniesQueryParam, setFetchCompaniesQueryParam] = useState<FetchCompaniesParam>({});
  const {data: companies} = useFetchCompaniesQuery(hospitalHashId, fetchCompaniesQueryParam);
  const setSelectedMaker = useSetAtom(selectedMakerAtom);
  const handleMakerChange = (selected?: SelectOptionProps) => {
    setSelectedMaker(selected?.value ?? null);
  };

  const onInputChange = useCallback((inputValue: string) => {
    setFetchCompaniesQueryParam({name: inputValue === '' ? undefined : inputValue});
  }, []);

  return {companies, handleMakerChange, onInputChange};
};

export const useProductDialog = () => {
  const {myInfo} = useMyInfo();
  const variables = useAtomValue(hospitalWholeProductVariables);
  const pageSize = useAtomValue(pageSizeAtom);
  const [page, setPage] = useAtom(pageAtom);
  // TODO: APIの向き先変更する
  const query = useFetchHospitalWholeProductsQuery(myInfo.hospitalHashId, variables);

  const rows = useMemo(() => {
    return query.data.map((item) => {
      return {
        hashID: item.hashId,
        name: item.name,
        displayName: item.displayName,
        makerName: item.maker.name,
      };
    });
  }, [query.data]);

  const handleChangePage = useCallback(
    (_e: React.ChangeEvent<unknown>, p: number) => {
      setPage(p);
    },
    [setPage]
  );

  const totalPage = useMemo(() => {
    return Math.ceil(query.totalCount / pageSize);
  }, [pageSize, query.totalCount]);

  const startDisplayPosition = useMemo(() => {
    return query.totalCount === 0 ? 0 : Math.ceil((page - 1) * pageSize + 1);
  }, [query.totalCount, page, pageSize]);

  const endDisplayPosition = useMemo(() => {
    const endPosition = Math.ceil(page * pageSize);
    return endPosition > query.totalCount ? query.totalCount : endPosition;
  }, [page, pageSize, query.totalCount]);

  return {
    ...query,
    handleChangePage,
    page,
    rows,
    totalPage,
    startDisplayPosition,
    endDisplayPosition,
  };
};
