import {FaultReceptionsDetailAtom} from '../../FaultReception/states';
import {useResetAtom} from 'jotai/utils';
import {useOnlyOnce} from '@front-libs/core';

export const useResetFaultReceptionAtom = () => {
  const resetFaultReceptionsDetailAtom = useResetAtom(FaultReceptionsDetailAtom);

  useOnlyOnce(() => {
    resetFaultReceptionsDetailAtom();
  });
};
