import {useRef} from 'react';
import {useOnlyOnce} from './useOnlyOnce';

export const useInitialized = (condition?: boolean) => {
  const isInitializedRef = useRef(false);

  useOnlyOnce(
    () => {
      isInitializedRef.current = true;
    },
    condition && isInitializedRef.current === false
  );

  return isInitializedRef.current;
};
