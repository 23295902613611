import {z} from 'zod';

export const schema = z.object({
  name: z.string().min(1, '型式は必須です'),
  displayName: z.string().min(1, '機種名は必須です'),
  makerName: z.string().min(1, 'メーカー名は必須です'),
  janCode: z.string().optional(),
  approvalNumber: z.string().optional(),
});

export type WholeProductSearchFormData = z.infer<typeof schema>;
