import {SimpleDialog} from '@front-libs/ui';
import {zodResolver} from '@hookform/resolvers/zod';
import {useForm} from 'react-hook-form';
import {DialogContent} from './dialog/DialogContents';
import {PartsDialogFormSchema, PartsDialogFormValues} from './schema';
import React, {useEffect, useState} from 'react';
import {WholeProductParts} from '@modules/whole_product_parts/types';

type CreatePartsDialogProps = {
  open: boolean;
  title: string;
  positiveButtonLabel: string;
  errorMessage?: string;
  onSubmit: (value: PartsDialogFormValues) => void;
  onClose: () => void;
  defaultValues?: WholeProductParts; // 編集時は初期値を渡す
};

/**
 * 部品交換品登録・編集ダイアログ
 * 機種の紐づけも行う
 */
export const PartsDialog = ({
  open,
  title,
  positiveButtonLabel,
  errorMessage,
  onSubmit,
  onClose,
  defaultValues,
}: CreatePartsDialogProps) => {
  const [isDirty, setIsDirty] = useState(false);
  const {
    handleSubmit,
    control,
    setValue,
    reset,
    watch,
    formState: {isValid, errors},
  } = useForm<PartsDialogFormValues>({
    resolver: zodResolver(PartsDialogFormSchema),
    defaultValues: {
      name: defaultValues?.name || '',
      partCode: defaultValues?.partCode || '',
      ...(defaultValues?.products ? {assignWholeProductHashIds: defaultValues.products.map((v) => v.hashId)} : {}),
    },
  });

  const errorStr = errorMessage ? errorMessage : errors.name?.message || errors.partCode?.message;

  useEffect(() => {
    if (!open) {
      reset(defaultValues);
    }
  }, [open]);

  const watchValues = watch();
  useEffect(() => {
    // react-hook-formのisDirtyでは配列の変更は検知できない為JSON文字列に変換して比較
    setIsDirty(JSON.stringify(watchValues) !== JSON.stringify(control._defaultValues));
  }, [watchValues]);

  return (
    <SimpleDialog
      open={open}
      title={title}
      fullWidth={true}
      positiveButtonLabel={positiveButtonLabel}
      disabledButton={defaultValues ? !isDirty || !isValid : !isValid} // デフォルト値がある場合は更新として扱う
      actions={{
        resolve: async () =>
          handleSubmit((data) => {
            onSubmit(data);
            return data;
          })(),
        reject: async () => onClose(),
      }}>
      <DialogContent
        control={control}
        setValue={setValue}
        errorMessage={errorStr}
        selectedData={
          defaultValues?.products.map((v) => {
            return {id: v.hashId, name: `${v.displayName} ${v.name}`};
          }) || []
        }
      />
    </SimpleDialog>
  );
};
