import {CreateFaultReceptionParam, FaultReceptionLocationType} from '@modules/fault_receptions/types';
import {atomWithReset} from 'jotai/utils';

export const FaultReceptionsDetailAtom = atomWithReset<CreateFaultReceptionParam>({
  /** どんな機器ですかの回答 */
  productFeature: '',
  /** どんな不具合ですかの回答 */
  faultDetail: '',
  /** 機器を使用していた場所の回答 */
  rentalRoomName: '',
  /** この機器はどこで回収できるか */
  reporterName: '',
  /** 回収場所 初期値は空文字である必要があるので一時的に型を矯正*/
  location: '' as FaultReceptionLocationType,
  /** 代替機が必要か */
  isNeedAlternativeDevice: false,
  /** 至急の対応が必要か */
  isNeedUrgentResponse: false,
});
