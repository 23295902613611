import {Atom, PrimitiveAtom, useAtomValue} from 'jotai';
import {selectAtom, useReducerAtom} from 'jotai/utils';
import {useCallback, useMemo} from 'react';
import {atomWithLocation} from 'jotai-location';
import {isNullish} from '@front-libs/helpers';

export type WholeProductPlanListState = {
  isDisplayRegistrationCautionBar?: boolean;
};

export const initialState: WholeProductPlanListState = {
  isDisplayRegistrationCautionBar: false,
};

export const WholeProductListStateAtom = atomWithLocation<WholeProductPlanListState>({
  getLocation: () => {
    const searchParams = new URLSearchParams(window.location.search);
    const isDisplayRegistrationCautionBar = searchParams.get('isDisplayRegistrationCautionBar');

    return {
      isDisplayRegistrationCautionBar: isDisplayRegistrationCautionBar === 'true' ? true : undefined,
    };
  },
  applyLocation: (state, options) => {
    const newState: Partial<WholeProductPlanListState> = {};

    if (!isNullish(state.isDisplayRegistrationCautionBar)) {
      newState.isDisplayRegistrationCautionBar = state.isDisplayRegistrationCautionBar;
    }

    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    const newSearchParams = new URLSearchParams(newState as any);

    if (options?.replace) {
      window.history.replaceState(null, '', `?${newSearchParams.toString()}`);
    } else {
      window.history.pushState(null, '', `?${newSearchParams.toString()}`);
    }
  },
}) as unknown as PrimitiveAtom<WholeProductPlanListState>;

// ActionCreator
export const createChangeIsDisplayRegistrationCautionBarAction = (isDisplayRegistrationCautionBar: boolean) => ({
  type: 'CHANGE_IS_DISPLAY_REGISTRATION_CAUTION_BAR' as const,
  isDisplayRegistrationCautionBar,
});

// Action
export type Actions = ReturnType<typeof createChangeIsDisplayRegistrationCautionBarAction>;

// Reducer
const WholeProductListStateReducer = (prev: WholeProductPlanListState, action: Actions): WholeProductPlanListState => {
  switch (action.type) {
    case 'CHANGE_IS_DISPLAY_REGISTRATION_CAUTION_BAR':
      return {...prev, isDisplayRegistrationCautionBar: action.isDisplayRegistrationCautionBar};
    default:
      console.warn('undefined action is dispatched', action);
      return prev;
  }
};

// helper
export const useWholeProductListState = () => useReducerAtom(WholeProductListStateAtom, WholeProductListStateReducer);

const useMemoizedDispatch = () => {
  const [, dispatch] = useWholeProductListState();
  return useMemo(() => dispatch, [dispatch]);
};

const useSelectState = <T, U = T>(valueAtom: Atom<T>, actionCreator: (value: U) => Actions) => {
  const value = useAtomValue(valueAtom);
  const dispatch = useMemoizedDispatch();
  const update = useCallback((x: U) => dispatch(actionCreator(x)), [actionCreator, dispatch]);

  return [value, update] as const;
};

// hooks
const _isDisplayRegistrationCautionBarAtom = selectAtom(
  WholeProductListStateAtom,
  (state) => state.isDisplayRegistrationCautionBar
);
export const useIsDisplayRegistrationCautionBar = () =>
  useSelectState(_isDisplayRegistrationCautionBarAtom, (isDisplayRegistrationCautionBar: boolean) =>
    createChangeIsDisplayRegistrationCautionBarAction(isDisplayRegistrationCautionBar)
  );
