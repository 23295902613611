import {axios} from '@front-libs/core';
import {useQuery} from 'react-query';
import {getHospitalProductPmdaFilesKey, getWholeProductPmdaFilesKey} from '@constants/api';
import {CreatePmdaFileParam, PmdaFile, PmdaFilesResult, WholeProductPmdaFilesResult} from './types';

const getHospitalProductPmdaFiles = async (hospitalHashId: string, hospitalProductHashId: string) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/products/${hospitalProductHashId}/pmda_files`;
  const response = await axios.get<PmdaFilesResult>(baseUrl);
  return response.data;
};

export const deletePmdaFile = async (hospitalHashId: string, hospitalProductHashId: string, pmdaFileHashId: string) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/products/${hospitalProductHashId}/pmda_files/${pmdaFileHashId}`;
  return await axios.delete(baseUrl);
};

export const useHospitalProductPmdaFilesQuery = (hospitalHashId: string, hospitalProductHashId: string) => {
  return useQuery(
    [getHospitalProductPmdaFilesKey, hospitalHashId],
    () => getHospitalProductPmdaFiles(hospitalHashId, hospitalProductHashId),
    {
      enabled: Boolean(hospitalHashId && hospitalProductHashId),
    }
  );
};

export const createPmdaFile = async (
  hospitalHashId: string,
  hospitalProductHashId: string,
  data: CreatePmdaFileParam
) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/products/${hospitalProductHashId}/pmda_files`;
  const response = await axios.post<PmdaFile>(baseUrl, data);
  return response.data;
};

/**
 * 院内機種用PMDAファイル一覧取得
 * @param hospitalHashId
 * @param wholeProductHashId
 */
const getWholeProductPmdaFiles = async (hospitalHashId: string, wholeProductHashId: string) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/whole_products/${wholeProductHashId}/pmda_files?page=0`;
  return await axios.get<WholeProductPmdaFilesResult>(baseUrl);
};
/**
 * 院内機種用PMDAファイル登録
 * @param hospitalHashId
 * @param wholeProductHashId
 * @param param.fileHashId
 * @param param.isUserUpload
 */

export const postWholeProductPmdaFiles = async (
  hospitalHashId: string,
  wholeProductHashId: string,
  param: {
    fileHashId: string;
    isUserUpload: boolean;
  }
) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/whole_products/${wholeProductHashId}/pmda_files`;
  return await axios.post<unknown>(baseUrl, param);
};
/**
 * 院内機種用PMDAファイル削除
 * @param hospitalHashId
 * @param wholeProductHashId
 * @param pmdaFileHashId
 */
export const deleteWholeProductPmdaFiles = async (
  hospitalHashId: string,
  wholeProductHashId: string,
  pmdaFileHashId: string
) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/whole_products/${wholeProductHashId}/pmda_files/${pmdaFileHashId}`;
  return await axios.delete<unknown>(baseUrl);
};

export const useWholeProductPmdaFilesQuery = (hospitalHashId: string, wholeProductHashId: string) => {
  return useQuery(
    [getWholeProductPmdaFilesKey, hospitalHashId],
    async () => {
      const {data} = await getWholeProductPmdaFiles(hospitalHashId, wholeProductHashId);
      return data;
    },
    {
      enabled: !!(hospitalHashId && wholeProductHashId),
      refetchOnWindowFocus: false,
    }
  );
};
