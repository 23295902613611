import React, {useEffect, useState} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import {Close} from '@material-ui/icons';
import {useFormikContext} from 'formik';
import Selector from '@molecules/Formik/fields/Selector';
import {RequiredLabel} from '@molecules/FormRequiredLabel';
import {InspectionSelector} from '@components/organisms/CreateInspectionResultDialog/InspectionSelector';
import {InspectionListOrIndex} from '@modules/inspections/types';

const useStyles = makeStyles((_theme) => ({
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '24px 32px 8px',
  },
  title: {
    color: '#172B4D',
  },
  content: {
    padding: '0px 32px',
  },
  optionalSelector: {
    marginBottom: '16px',
  },
  actions: {
    padding: '24px 32px 32px',
  },
}));

type StartInspectionFormBodyProps = {
  open: boolean;
  // inspection result selector (optional)
  showsInspectionResultSelector: boolean;
  inspectionResultOptions?: Array<{label: string; value: string; defaultInspectionHashId?: string}>;
  defaultInspectionResult?: {label: string; value: string};
  // inspection selector
  showsInspectionSelector: boolean;
  inspectionOptions: Array<{label: string; options: Array<{label: string; value: InspectionListOrIndex}>}>;
  defaultInspectionHashId?: string;
  // user selector
  userOptions: Array<{label: string; value: string}>;
  defaultUser?: {label: string; value: string};
  // callbacks
  onSearchInspection: (name: string) => void;
  onClose: React.MouseEventHandler;
};

export type StartInspectionFormValue = {
  inspectionResult: string;
  inspection: string;
  inspector: string;
};

export const StartInspectionFormBody: React.FC<StartInspectionFormBodyProps> = (props) => {
  const {
    open,
    showsInspectionResultSelector,
    inspectionResultOptions,
    defaultInspectionResult,
    inspectionOptions,
    userOptions,
    defaultUser,
    showsInspectionSelector,
    onSearchInspection,
    onClose,
  } = props;
  const classes = useStyles();
  const {values, submitForm, isValid, setFieldValue} = useFormikContext<StartInspectionFormValue>();

  const [defaultInspectionHashId, setDefaultInspectionHashId] = useState(props.defaultInspectionHashId);

  // inspection resultを選択した際に、
  // inspection resultにdefault inspectionが設定されている場合そちらに更新する
  useEffect(() => {
    const result = (inspectionResultOptions ?? []).find((r) => r.value === values.inspectionResult);
    if (result?.defaultInspectionHashId !== undefined && values.inspection !== result?.defaultInspectionHashId) {
      setFieldValue('inspection', result?.defaultInspectionHashId);
      setDefaultInspectionHashId(result?.defaultInspectionHashId);
    }
  }, [values.inspectionResult, values.inspection, inspectionResultOptions, setFieldValue]);

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="sm">
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Typography className={classes.title} style={{fontSize: '20px', fontWeight: 'bold'}}>
          点検を開始
        </Typography>
        <IconButton aria-label="close" onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column">
          {showsInspectionResultSelector && (
            <Grid className={classes.optionalSelector}>
              <RequiredLabel>点検</RequiredLabel>
              <Selector
                name="inspectionResult"
                size="small"
                placeholder="点検"
                options={inspectionResultOptions}
                defaultValue={defaultInspectionResult}
              />
            </Grid>
          )}
          {showsInspectionSelector && (
            <Grid className={classes.optionalSelector}>
              <RequiredLabel>点検表</RequiredLabel>
              <InspectionSelector
                key={defaultInspectionHashId}
                inspectionOptions={inspectionOptions}
                defaultInspectionHashId={defaultInspectionHashId}
                onSearchInspection={onSearchInspection}
                onSelectInspection={(value?: InspectionListOrIndex) => setFieldValue('inspection', value?.hashId)}
              />
            </Grid>
          )}
          <Grid>
            <RequiredLabel>点検者</RequiredLabel>
            <Selector
              name="inspector"
              size="small"
              placeholder="点検者"
              options={userOptions}
              defaultValue={defaultUser}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button variant="contained" color="primary" onClick={submitForm} disabled={!isValid}>
          開始
        </Button>
        <Button variant="text" onClick={onClose}>
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
};
