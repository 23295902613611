import {useQuery} from 'react-query';
import {FetchWholeProductPartsParam} from './types';
import {getWholeProductPartsKey} from '@constants/api';
import {getWholeProductParts} from './api';

export const useFetchWholeProductPartsQuery = (hospitalHashId: string, params: FetchWholeProductPartsParam) => {
  const query = useQuery(
    [getWholeProductPartsKey, hospitalHashId, params],
    () => getWholeProductParts(hospitalHashId, params),
    {
      enabled: Object.keys(params).length > 0,
    }
  );

  return {...query, data: query.data?.data ?? [], totalCount: query.data?.totalCount ?? 0};
};
