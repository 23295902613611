import {HospitalProductIndex} from '@modules/hospital_products/types';
import Dexie, {Table} from 'dexie';
import {deleteHospitalProducts, initHospitalProducts, updateHospitalProducts} from './hospitalProducts/service';
import {deleteInspectionResults, initInspectionResults, updateInspectionResults} from './inspectionResults/service';
import {InspectionResultIndex} from '@modules/inspection_results/types';
import {InspectionIndex} from '@modules/inspections/types';
import {deleteInspections, initInspections, updateInspections} from './inspections/service';
import {initAndUpdateMe} from './me/service';
import {UserIndex} from '@modules/hospital_users/types';
import {deleteHospitalUsers, initHospitalUsers, updateHospitalUsers} from './hospitalUsers/service';
import {fetchMyInfoForCache} from '@modules/hospital_users/api';
import {isNullish, isToday} from '@front-libs/helpers';
import {HospitalLastSyncIndex} from './hospitalLastSync/types';
import {getHospitalLastSync} from './hospitalLastSync/repository';
import {createOrUpdateHospitalLastSync} from './hospitalLastSync/service';
import {submitOfflineInspectionResult} from './submit';
import {SubmitInspectionResultsIndex} from '@indexedDB/SubmitInspectionResults/types';
import dayjs from 'dayjs';
import {
  deleteWholeProductsInspectionProducts,
  initAndUpdateWholeProductsInspectionProducts,
} from './wholeProductsInspectionProducts/service';
import {GetWholeProductsInspectionProductsResult} from '@modules/products/api';
import {TableLayoutResponse} from '@modules/table_layout/api';
import {
  updateDifferentialTableLayoutSetting,
  initTableLayoutSetting,
  setUserTableLayoutSetting,
} from './tableLayoutSetting/service';

export let dexieDB: DexieLocalDatabase | null = null;

export type TableLayoutResponseDB = {
  hospitalHashId: string;
  hospitalUserHashId: string;
  tableLayoutResponse: TableLayoutResponse[];
};

export class DexieLocalDatabase extends Dexie {
  hospitalProducts!: Table<HospitalProductIndex>;
  inspectionResults!: Table<InspectionResultIndex>;
  inspections!: Table<InspectionIndex>;
  me!: Table<UserIndex>;
  hospitalUsers!: Table<UserIndex>;
  hospitalLastSync!: Table<HospitalLastSyncIndex>;
  submitInspectionResults!: Table<SubmitInspectionResultsIndex>;
  wholeProductInspectionProducts!: Table<GetWholeProductsInspectionProductsResult>;
  tableLayoutSetting!: Table<TableLayoutResponseDB>;

  constructor() {
    super('local-database');
    this.version(1).stores({
      hospitalProducts: `hashId,hospitalHashId,managementId`,
      inspectionResults: `hashId,hospitalHashId, [hospitalHashId+type+status]`,
      inspections: `hashId,hospitalHashId`,
      me: `hashId,hospitalHashId`,
      hospitalUsers: `hashId,hospitalHashId`,
      hospitalLastSync: `hospitalHashId,updatedAt`,
      submitInspectionResults: `++id,hospitalHashId,inspectionResultHashId,inspectionResultUuId`,
    });
    this.version(2).stores({
      inspections: `hashId,hospitalHashId,[hospitalHashId+status]`,
    });
    this.version(3).stores({
      wholeProductInspectionProducts: `++id,hospitalHashId,[hospitalHashId+wholeProductHashId]`,
      inspections: `hashId,hospitalHashId,[hospitalHashId+status],[hashId+hospitalHashId+status]`,
    });
    this.version(4).stores({
      tableLayoutSetting: `hospitalHashId,hospitalUserHashId`,
    });
  }
}

export const initIndexedDB = () => {
  dexieDB = new DexieLocalDatabase();

  dexieDB
    .open()
    .then(() => {
      // biome-ignore lint/suspicious/noConsoleLog: インシデント調査用
      console.log('Database is now open');
    })
    .catch((error) => {
      console.error(error);
    });
};

export const executeLoadDB = async () => {
  if (navigator.onLine) {
    const data = await fetchMyInfoForCache();
    const hospitalHashId = data.data.hospitalHashId;
    const userIndex = data.data;
    const hospitalLastSync = await getHospitalLastSync(hospitalHashId);

    // 未完了の場合、フルデータ取得。完了済みの場合、差分データ取得
    if (isNullish(hospitalLastSync)) {
      // biome-ignore lint/suspicious/noConsoleLog: 本番確認用に残している
      console.log('初回フルデータ取得');
      await initLoadDB(hospitalHashId, userIndex);
      await createOrUpdateHospitalLastSync(hospitalHashId);
    } else if (!isToday(hospitalLastSync.updatedAt)) {
      // 最終更新時刻が今日でない場合、データの洗い替えを実施
      // biome-ignore lint/suspicious/noConsoleLog: 本番確認用に残している
      console.log('データ洗い替え実施');
      // オフラインで作成したデータをサーバに送信
      await updateLoadDB(hospitalLastSync);
      await deleteHospitalData(hospitalHashId);
      await createOrUpdateHospitalLastSync(hospitalHashId);
      await initLoadDB(hospitalHashId, userIndex);
    } else {
      // biome-ignore lint/suspicious/noConsoleLog: 本番確認用に残している
      console.log('差分データ取得');
      // オフラインで作成したデータをサーバに送信
      await updateLoadDB(hospitalLastSync);
      // 差分データの取得
      await getDiffData(hospitalLastSync, userIndex);
      // 最終更新時刻の更新
      await createOrUpdateHospitalLastSync(hospitalHashId);
    }
  }
};

/**
 * データベース初期化
 * @param hospitalHashId
 * @param userIndex
 */
const initLoadDB = async (hospitalHashId: string, userIndex: UserIndex) => {
  await Promise.all([
    initHospitalProducts(hospitalHashId),
    initInspectionResults(hospitalHashId),
    initInspections(hospitalHashId),
    initAndUpdateMe(),
    initHospitalUsers(hospitalHashId),
    initAndUpdateWholeProductsInspectionProducts(hospitalHashId),
    initTableLayoutSetting(userIndex),
  ]);
};

// オフラインで作成したデータをサーバに送信
const updateLoadDB = async (hospitalLastSync: HospitalLastSyncIndex) => {
  // データ送信(点検結果の送信だけ)
  await submitOfflineInspectionResult(hospitalLastSync.hospitalHashId);
};

/**
 * 最終更新時刻をキーに差分データを取得
 * @param hospitalLastSync
 * @param userIndex
 */
const getDiffData = async (hospitalLastSync: HospitalLastSyncIndex, userIndex: UserIndex) => {
  // データ送信(点検結果の送信だけ)
  const hospitalHashId = hospitalLastSync.hospitalHashId;

  const updatedAt = dayjs(hospitalLastSync.updatedAt).format('YYYY-MM-DDTHH:mm:ssZ');

  await Promise.all([
    updateHospitalProducts(hospitalHashId, updatedAt),
    updateInspectionResults(hospitalHashId, updatedAt),
    updateInspections(hospitalHashId, updatedAt),
    initAndUpdateMe(),
    updateHospitalUsers(hospitalHashId, updatedAt),
    initAndUpdateWholeProductsInspectionProducts(hospitalHashId),
    setUserTableLayoutSetting(userIndex),
    updateDifferentialTableLayoutSetting(userIndex),
  ]);
};

// データ洗い替えに向けたデータ削除(他の病院データむ含むため、対象病院のデータのみ削除)
// 使用前点検などの物理削除レコードが残り続けるため、データの洗い替えで削除する
const deleteHospitalData = async (hospitalHashId: string) => {
  await Promise.all([
    deleteHospitalProducts(hospitalHashId),
    deleteHospitalUsers(hospitalHashId),
    deleteInspectionResults(hospitalHashId),
    deleteInspections(hospitalHashId),
    deleteWholeProductsInspectionProducts(hospitalHashId),
  ]);
};
