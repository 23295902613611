import {FilterOption} from '@molecules/Drawers/FilterDrawer/types';
import {ColumnType} from './types';

export const ColumnTypeValues = ['text', 'textarea', 'number', 'date', 'select'] as const;

export const NoteFieldFilterOptionType: Record<FilterOption['optionType'], ColumnType> = {
  text: 'text',
  dateRange: 'date', // NOTE: componentの実装でdateRangeの場合にdateを指定しているため、合わせている(2024/09/05時点)
  selector: 'select',
  // NOTE: 以下は未実装なので、一律textにしている
  number: 'text',
  date: 'text',
  num: 'text',
};
