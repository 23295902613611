import {axios} from '@front-libs/core';
import {RecommendWholeProductRequest, RecommendWholeProductResult, UnifyWholeProductRequest} from './type';

export const recommendWholeProduct = async (hospitalHashId: string, data: RecommendWholeProductRequest) => {
  const baseURL = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/whole_products/recommend`;
  return await axios.post<RecommendWholeProductResult>(baseURL, data);
};

export const unifyWholeProduct = async (hospitalHashId: string, data: UnifyWholeProductRequest) => {
  const baseURL = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/whole_products/unify`;
  return await axios.put(baseURL, data);
};
