import React from 'react';
import {Theme, Grid, makeStyles, Card, Typography} from '@material-ui/core';
import {WholeProduct} from '@organisms/HospitalProductImporter';

type Props = {
  wholeProduct: WholeProduct;
};

export const TrainingScheduleProductListItem: React.FC<Props> = ({wholeProduct}) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.fieldContainer} direction={'column'}>
      <Card variant="outlined">
        <Grid container className={classes.productThumbnail} wrap="nowrap">
          <Grid item className={classes.thumbnailImage}>
            <img src={wholeProduct.thumbnailUrl} alt="サムネイル" width={100} />
          </Grid>
          <Grid item className={classes.categories}>
            <Typography variant={'body2'}>
              {wholeProduct.categories[0].name} &nbsp;&gt;&nbsp;{wholeProduct.categories[1].name}
            </Typography>
            <Typography variant={'body2'}>{wholeProduct.displayName}</Typography>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  fieldContainer: {
    padding: '8px 16px',
    fontSize: '14px',
  },
  productThumbnail: {
    padding: '24px 16px',
    minHeight: '120px',
  },
  thumbnailImage: {
    marginRight: '8px',
  },
  categories: {
    '& > :not(:first-child)': {
      marginTop: '4px',
    },
  },
  listItem: {
    paddingBottom: '8px',
  },
}));
