import React from 'react';
import {Box, Typography, SxProps} from '@mui/material';

const boxStyle: SxProps = {
  color: '#172B4D',
  '& .MuiTypography-root': {
    fontSize: '14px',
  },
};

const importantTextStyle: SxProps = {
  color: '#C7243A',
  fontWeight: 700,
};

/**
 * 再紐付けする院内機種を選択/入力ダイアログの説明文
 * @returns
 */
export const RelinkConfirmationMessage = ({messageStyle}: {messageStyle?: SxProps}) => {
  return (
    <Box sx={{...boxStyle, ...messageStyle}}>
      <Typography>機種の再紐付けを行うと、対象機器の定期点検計画が削除されます。</Typography>
      <Typography>
        また、対象機器の「未実施の点検実施予定」、及び「予定月超過の点検実施予定」も削除されます。
      </Typography>
      <Typography>※スキップした点検実施予定、実施途中の点検、及び完了した点検は削除対象外です。</Typography>
      <Typography sx={importantTextStyle}>※再紐付け後、定期点検計画の再設定をお願いします。</Typography>
    </Box>
  );
};
