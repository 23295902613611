import React from 'react';
import {Sidebar as SettingMenuSidebar} from '@components/organisms/Sidebar';
import {StyledSettingsTempSideBar} from '@templates/ContentLayout/InnerSidebarContentLayoutV5';

export const Sidebar = () => {
  return (
    <StyledSettingsTempSideBar>
      <SettingMenuSidebar />
    </StyledSettingsTempSideBar>
  );
};
