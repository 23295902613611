import React from 'react';
import {Box, Button, SxProps} from '@mui/material';
import {ChevronLeft} from '@mui/icons-material';

type BackButtonFooterProps = {
  onClick?: () => void;
  backButtonText?: string;
};
/**
 * 戻るボタンのみのFooter
 */
export const BackButtonFooter = ({onClick, backButtonText = '戻る'}: BackButtonFooterProps) => {
  return (
    <Box sx={rootStyle}>
      <Button sx={backButtonStyle} variant="text" onClick={() => onClick && onClick()}>
        <ChevronLeft sx={chevronLeftStyle} />
        {backButtonText}
      </Button>
    </Box>
  );
};

const rootStyle: SxProps = {
  position: 'fixed',
  bottom: '44px',
  left: 0,
};

const backButtonStyle: SxProps = {
  backgroundColor: 'white',
  color: '#0052CC',
  fontSize: '24px',
  fontWeight: 'bold',
  height: '60px',
  padding: '5px 0px 5px 8px',
  '&:hover': {
    backgroundColor: 'white',
  },
  width: '124px',
  left: '0px',
  borderRadius: '0px 45px 45px 0px',
  boxShadow: '0px 4px 0px 0px rgba(209, 216, 245, 1)',
};

const chevronLeftStyle: SxProps = {
  fontSize: '1.2em',
};
