import {Paper, styled} from '@material-ui/core';
import React, {useCallback, useState} from 'react';
import {DashboardMenu, TabMenuKeys} from './components';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import {useAuthz} from '@modules/auth/hooks';
import {useOnlyOnce} from '@front-libs/core';
import {isNullish} from '@front-libs/helpers';

const tabRouteMap: Record<TabMenuKeys, string> = {
  // cost_management: '/general_menu/management_dashboard/cost_management',
  rental_ratio: '/general_menu/management_dashboard/rental_ratio',
  // repair_ratio: '/general_menu/management_dashboard/repair_ratio',
};

const routeTabMap: Record<string, TabMenuKeys> = {
  // '/general_menu/management_dashboard/cost_management': 'cost_management',
  '/general_menu/management_dashboard/rental_ratio': 'rental_ratio',
  // '/general_menu/management_dashboard/repair_ratio': 'repair_ratio',
};

export const ManagementDashboard: React.FC = () => {
  const {permissions} = useAuthz();
  const navigate = useNavigate();
  const location = useLocation();
  const [tabMenu, setTabMenu] = useState<TabMenuKeys>(routeTabMap[location.pathname] ?? 'rental_ratio');

  // 権限によって表示するタブを変更する。
  useOnlyOnce(() => {
    const defaultRouteTab = routeTabMap[location.pathname];
    // if (permissions?.includes(HospitalUserPermission.ReadCostManagementDashboard)) {
    //   // setTabMenu('cost_management');
    //   setTabMenu('rental_ratio');
    //   navigate(tabRouteMap.rental_ratio);
    //   return;
    // }
    if (defaultRouteTab !== 'rental_ratio') {
      setTabMenu('rental_ratio');
      navigate(tabRouteMap.rental_ratio);
      return;
    }
    // if (
    //   defaultRouteTab === 'repair_ratio' &&
    //   permissions?.includes(HospitalUserPermission.ReadRepairRatioManagementDashboard)
    // ) {
    //   setTabMenu('repair_ratio');
    //   navigate(tabRouteMap.repair_ratio);
    //   return;
    // }
  }, !isNullish(permissions));

  const handleChangeTab = useCallback(
    (value: TabMenuKeys) => {
      setTabMenu(value);
      navigate(tabRouteMap[value]);
    },
    [navigate]
  );

  return (
    <DashboardRoot>
      <PageTitle>経営ダッシュボード</PageTitle>
      <Paper style={{width: '100%'}}>
        <DashboardMenu value={tabMenu} onChangeTab={handleChangeTab} />
        <Outlet />
      </Paper>
    </DashboardRoot>
  );
};

const DashboardRoot = styled('div')({
  margin: 24,
  width: '100%',
});

const PageTitle = styled('div')({
  fontSize: 20,
  fontWeight: 700,
  marginBottom: 24,
});
