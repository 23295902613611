import React from 'react';
import {UserIndex} from '@modules/hospital_users/types';
import {styled} from '@material-ui/styles';
import {Grid, Paper} from '@material-ui/core';
import dayjs from 'dayjs';
import {ArrowTooltipComponent} from '@components/atoms/ArrowTooltip';
import {UserFormatter} from '@modules/hospital_users/helpers';

interface StyledPaperProps {
  width: 'none' | '100%';
}

const StyledPaper = styled(Paper)(({width}: StyledPaperProps) => ({
  padding: 16,
  width: width, // 'none' か '100%' を動的に設定
  backgroundColor: '#e0e0e0',
  border: '1px solid #9e9e9e',
  display: 'flex',
}));

const GridContainer = styled(Grid)({
  padding: '0px 8px',
  justifyContent: 'space-between',
});

type DeleteCardProps = {
  displayDate: Date | string;
  updateAt: Date | string;
  updateBy: UserIndex;
  width?: 'none' | '100%';
};
/**
 * 削除済みコメントカードの表示
 * @param {DeleteCardProps} DeleteCardProps
 * @param updateBy - 編集者のUserIndex
 * @param updateAt - 変更日のDate
 * @returns
 */
export const DeleteCard = ({updateBy, updateAt, displayDate, width = '100%'}: DeleteCardProps) => {
  return (
    <StyledPaper width={width}>
      <GridContainer container>
        <ArrowTooltipComponent tooltipText={`${dayjs(updateAt).format('YYYY年M月D日 HH:mm')}にコメント削除`}>
          <Grid>{`このコメントは${UserFormatter.getFullName(updateBy)}さんにより削除済みです`}</Grid>
        </ArrowTooltipComponent>
        <Grid>{`${dayjs(displayDate).format('YYYY年M月D日 HH:mm')}`}</Grid>
      </GridContainer>
    </StyledPaper>
  );
};
