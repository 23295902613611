import {useHospitalProductStore} from '@modules/hospital_products/hooks/useHospitalProduct';
import {HospitalProductState} from '@modules/hospital_products/types';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';

type HospitalProductMenuStoreType = {
  hospitalProductState: HospitalProductState;
  resetHospitalProductState: () => void;
  listHospitalProductsByManagementId: (managementId: string) => Promise<void>;
};

export const useHospitalProductMenuStore = (): HospitalProductMenuStoreType => {
  const {myInfo} = useMyInfo();
  const {
    state: hospitalProductState,
    dispatchResetHospitalProducts,
    dispatchListHospitalProducts,
  } = useHospitalProductStore();

  const resetHospitalProductState = () => {
    dispatchResetHospitalProducts();
  };

  const listHospitalProductsByManagementId = async (managementId: string) => {
    if (!managementId) {
      return;
    }

    const statuses = 'ready,working,uninspected,repairing'; // 廃棄機器は除外
    await dispatchListHospitalProducts(myInfo.hospitalHashId, {managementId, statuses});
  };

  return {
    hospitalProductState,
    resetHospitalProductState,
    listHospitalProductsByManagementId,
  };
};
