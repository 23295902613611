import {z} from 'zod';
import {zodResolver} from '@hookform/resolvers/zod';

// バリデーション用のZodスキーマ
export const PartsDialogFormSchema = z.object({
  name: z.string().min(1, '部品・交換品名を入力してください'),
  partCode: z.string().min(1, '部品・交換品目を入力してください'),
  assignWholeProductHashIds: z.array(z.string()).optional(),
});

// 型推論のためにスキーマから型を取得
export type PartsDialogFormValues = z.infer<typeof PartsDialogFormSchema>;
