import React from 'react';
import {HospitalProductPlanListElement} from '../../types';
import {Grid, styled} from '@mui/material';

const StyledGrid = styled(Grid)({
  borderBottom: '1px solid',
});
type StartMonthColumnProps = {
  rowData: HospitalProductPlanListElement;
};

export const StartMonthColumn = ({rowData}: StartMonthColumnProps) => {
  const formattedStartMonth = rowData.startMonth
    ? `${rowData.startMonth.substring(0, 4)}/${rowData.startMonth.substring(4)}`
    : '';
  return <>{rowData.startMonth && <StyledGrid>{formattedStartMonth}</StyledGrid>}</>;
};
