import React, {useCallback} from 'react';
import {ListPageLayout} from '@components/layouts/ListPageLayout';
import {withSuspense} from '@front-libs/core';
import {InspectionMenuHeader} from '@components/organisms/InspectionMenuHeader';
import {InspectionScheduleBackButton} from '@components/organisms/InspectionMenuHeader/InspectionScheduleBackButton';
import {WholeProductPlansList} from './WholeProductPlansList';
import {Button} from '@mui/material';
import {dialogHandler} from '@components/molecules/Dialogs/DialogHandler';
import {CreateProductsDialog, CreateProductsDialogProps} from './CreateProductsDialog';
import {useNavigate} from 'react-router-dom';

const MenuButton = ({onClick}: {onClick: () => void}) => {
  return (
    <Button variant={'contained'} color={'primary'} onClick={onClick}>
      機種別点検を設定
    </Button>
  );
};

const _WholeProductPlan: React.FC = () => {
  const navigate = useNavigate();

  const handleClickCreateProductsDialog = useCallback(async () => {
    try {
      const {hashId} = await dialogHandler.open<CreateProductsDialogProps>(CreateProductsDialog, {});
      if (!hashId) return;
      navigate(`/inspection_v2/whole_product_plans/${hashId}/create`);
    } catch (_e) {
      return;
    }
  }, [navigate]);

  return (
    <ListPageLayout page="whole_product_plan_list">
      <ListPageLayout.Header>
        <InspectionScheduleBackButton />
      </ListPageLayout.Header>
      <InspectionMenuHeader node={<MenuButton onClick={handleClickCreateProductsDialog} />} />
      <ListPageLayout.Content>
        <WholeProductPlansList />
      </ListPageLayout.Content>
    </ListPageLayout>
  );
};

export const WholeProductPlans = withSuspense(_WholeProductPlan, null);
