import {useQuery} from 'react-query';
import {getInspectionResultKey} from '@constants/api';
import {getInspectionResult, useFetchInspectionResultsQuery} from '@modules/inspection_results/api';
import {isUndefined} from 'lodash';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';

type QueryOptions = {
  noCache?: boolean;
};

export const FAKE_INSPECTION_ID = 'EgqdR7b5l3mzr6Y';
// TODO:HIT-4529 SP参照あり
export const useInspectionResult = (
  hospitalHashId: string,
  inspectionHashId: string,
  inspectionResultHashId: string,
  queryOption?: QueryOptions
) => {
  const query = useQuery(
    [getInspectionResultKey, hospitalHashId, inspectionHashId, inspectionResultHashId],
    () => getInspectionResult(hospitalHashId, inspectionHashId, inspectionResultHashId),
    {
      cacheTime: !isUndefined(queryOption?.noCache) && queryOption?.noCache ? 0 : undefined,
    }
  );
  return query;
};

export const useLatestInspectionResult = (inspectionHashId: string, hospitalProductHashId?: string) => {
  const {myInfo} = useMyInfo();
  const query = useFetchInspectionResultsQuery(myInfo.hospitalHashId, FAKE_INSPECTION_ID, {
    hospitalProductHashId: hospitalProductHashId,
    inspectionHashId: inspectionHashId,
    statuses: 'completed',
    order: '-completedAt',
  });

  return {
    ...query,
    data: query.data.length > 0 ? query.data[0] : null,
  };
};
