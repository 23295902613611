import {TextField, makeStyles, createStyles, Theme} from '@material-ui/core';
import {Search} from '@material-ui/icons';
import React from 'react';

type MobileSearchBarProp = {
  searchName: string | null;
  onChangeSearchName: React.ChangeEventHandler<HTMLInputElement>;
};

export const MobileSearchBar: React.FC<MobileSearchBarProp> = (props) => {
  const {searchName, onChangeSearchName} = props;
  const classes = useStyles();

  return (
    <TextField
      className={classes.searchName}
      variant={'outlined'}
      fullWidth
      size={'small'}
      placeholder="名称・型式・管理番号で検索"
      InputProps={{
        startAdornment: <Search className={classes.searchIcon} />,
        style: {
          color: '65676B', // テキストの色を濃くする
        },
      }}
      InputLabelProps={{
        style: {
          fontSize: 14,
        },
      }}
      defaultValue={searchName}
      onChange={onChangeSearchName}
    />
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchName: {
      backgroundColor: '#F3F4F6',
      fontSize: '14px',
      '& input::placeholder': {
        color: theme.palette.grey[600],
      },
    },
    searchIcon: {
      color: theme.palette.grey[600],
    },
  })
);
