import React from 'react';
import {makeStyles, Grid, Box} from '@material-ui/core';
import {PRODUCT_REGISTRATION_HEADER_HEIGHT} from './constants';

export const Header: React.FC = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Grid container>
        <Grid container>HITOTSUに機器を登録</Grid>
        <Grid item className={classes.gridItem}>
          <span className={classes.title}>登録機種の特定</span>
        </Grid>
      </Grid>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    boxSizing: 'border-box',
    height: PRODUCT_REGISTRATION_HEADER_HEIGHT,
    width: '100%',
    padding: '20px 0px 0px 40px',
    backgroundColor: 'white',
  },
  title: {
    color: '#374659',
    fontSize: '24px',
    fontWeight: 'bold',
  },
  gridItem: {
    paddingTop: '24px',
  },
}));
