import React from 'react';
import {makeStyles, Drawer, Theme, DrawerProps} from '@material-ui/core';
import {DrawerHeader} from './DrawerHeader';
import {NotificationContainer} from './NotificationContrainer';
import {NotificationCategory, NotificationOptionFilter} from '@modules/notifications/types';

export type NotificationDrawerProps = {
  open: boolean;
  onClose: () => void;
  onAllClear?: () => void;
  masterCategories: NotificationCategory[]; //ユーザ通知、PMDA通知のマスターカテゴリー
  optionFilters: NotificationOptionFilter[]; // ユーザ通知、PMDA通知のフィルター用カテゴリー
  drawerTitle: string;
} & DrawerProps;

export const NotificationDrawer: React.FC<NotificationDrawerProps> = ({
  open,
  onClose,
  onAllClear,
  masterCategories,
  optionFilters,
  drawerTitle,
}) => {
  const classes = useStyles();
  return (
    <Drawer anchor={'right'} open={open} variant="persistent" PaperProps={{className: classes.root, elevation: 4}}>
      <NotificationContainer
        open={open}
        onAllClear={onAllClear}
        onClose={onClose}
        masterCategories={masterCategories}
        optionFilters={optionFilters}>
        <DrawerHeader onClose={onClose} drawerTitle={drawerTitle} />
      </NotificationContainer>
    </Drawer>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    zIndex: theme.zIndex.drawer + 1,
    width: 450,
  },
}));
