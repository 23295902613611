import React from 'react';
import {Box} from '@mui/material';
import {Pagination} from '@mui/material';

type PaginationAndPerPageProps = {
  /* 全アイテムの総数 */
  totalCount: number;
  /* 現在のページ番号 */
  currentPage: number;
  /* 1ページあたりの表示件数 */
  pageSize: number;
  /**
   * ページ番号が変更された時に呼び出されるコールバック関数
   * @param newPage 新しいページ番号
   */
  onPageChange: (newPage: number) => void;
};

export const PaginationButtons: React.FC<PaginationAndPerPageProps> = ({
  totalCount,
  currentPage,
  pageSize,
  onPageChange,
}) => {
  const startDisplayPosition = React.useMemo(() => {
    return totalCount === 0 ? 0 : (currentPage - 1) * pageSize + 1;
  }, [currentPage, pageSize, totalCount]);

  const endDisplayPosition = React.useMemo(() => {
    return Math.min(currentPage * pageSize, totalCount);
  }, [currentPage, pageSize, totalCount]);

  const totalPages = React.useMemo(() => {
    return Math.ceil(totalCount / pageSize) || 1;
  }, [pageSize, totalCount]);

  const handlePageChange = (_e: React.ChangeEvent<unknown>, page: number) => {
    onPageChange(page);
  };

  return (
    <Box alignItems="center" sx={{fontSize: '0.875rem', width: '100%'}}>
      <Box display="flex" flexDirection="column" alignItems="center" width="100%">
        <Box mb="4px">
          {totalCount}件のうち{startDisplayPosition}件目-{endDisplayPosition}件目までを表示しています
        </Box>
        <Box>
          <Pagination
            page={currentPage}
            count={totalPages}
            shape="rounded"
            onChange={handlePageChange}
            aria-label="ページ切り替え"
          />
        </Box>
      </Box>
    </Box>
  );
};
