import React from 'react';
import {Typography, SxProps, Theme, Box} from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import {Draggable} from 'react-beautiful-dnd';

type RentalDnDCardProps = {
  title: string;
  index: number;
  isDnDEnabled: boolean;
};

/**
 * ドラッグ可能なテキストカードコンポーネント
 */
export const RentalDnDCard = ({title, index, isDnDEnabled}: RentalDnDCardProps) => {
  return (
    <Draggable draggableId={title} index={index} isDragDisabled={!isDnDEnabled}>
      {(provided) => (
        <Box
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          sx={cardRootStyle(isDnDEnabled)}>
          {isDnDEnabled && <DragIndicatorIcon sx={dragIndicatorStyle} color="action" fontSize="small" />}
          <Typography variant="h6" component="div" sx={centeredTextStyle}>
            {title}
          </Typography>
        </Box>
      )}
    </Draggable>
  );
};

const cardRootStyle = (isDnDEnabled: boolean): SxProps => ({
  width: '94px',
  height: '60px',
  borderRadius: '10px',
  margin: '8px',
  background: '#FFF',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: isDnDEnabled ? 'grab' : 'pointer',
  '&:active': {
    cursor: isDnDEnabled ? 'grabbing' : 'pointer',
  },
  // Material-ui CardのBoxShadowと同じ値
  boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
});

const dragIndicatorStyle: SxProps = {
  position: 'absolute',
  top: '2px',
  left: '0px',
};

const centeredTextStyle: SxProps<Theme> = (theme) => ({
  textAlign: 'center',
  color: theme.palette.rent.main,
  fontSize: '14px',
  lineHeight: '15px',
  fontWeight: 700,
  padding: '0px 16px',
});
