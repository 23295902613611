import {Box, Button, CSSObject} from '@mui/material';
import React, {ChangeEvent} from 'react';
import {Control, FieldValues} from 'react-hook-form';
import {FormField} from './FormField';

type WholeProductFormFieldsProps = {
  control: Control<FieldValues>;
  handleChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  isSearching: boolean;
  isValid: boolean;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
};

const FlexColumnStyle: CSSObject = {display: 'flex', flexDirection: 'column'};

const SearchButton = ({disabled}: {disabled: boolean}) => {
  return (
    <Box display="flex" m={0} justifyContent="flex-end">
      <Button
        type="submit"
        sx={{
          color: 'white',
          backgroundColor: '#0052CC',
          height: '32px',
          padding: '6px 8px',
          margin: '8px 0',
          '&.Mui-disabled': {
            backgroundColor: '#D1D5DB',
          },
        }}
        disabled={disabled}>
        検索
      </Button>
    </Box>
  );
};

export const WholeProductRecommendSearchForm = ({
  control,
  handleChange,
  onSubmit,
  isSearching,
  isValid,
}: WholeProductFormFieldsProps) => {
  return (
    <Box
      component="form"
      sx={{display: 'flex', flexDirection: 'row', py: 3, px: 7, backgroundColor: '#F3F4F6'}}
      onSubmit={onSubmit}>
      <Box flex={1} sx={FlexColumnStyle}>
        <FormField
          name="displayName"
          control={control}
          label="機種名"
          placeholder="機種名を入力"
          handleChange={handleChange}
        />
        <FormField name="name" control={control} label="型式" placeholder="型式を入力" handleChange={handleChange} />
        <FormField
          name="makerName"
          control={control}
          label="メーカー名"
          placeholder="メーカー名を入力"
          handleChange={handleChange}
        />
      </Box>
      <Box flex={1} sx={{...FlexColumnStyle, ml: 6}}>
        <FormField
          name="janCode"
          control={control}
          label="JANコード"
          placeholder="JANコードを入力"
          required={false}
          handleChange={handleChange}
        />
        <FormField
          name="approvalNumber"
          control={control}
          label="承認番号"
          placeholder="承認番号を入力"
          required={false}
          handleChange={handleChange}
        />
        <SearchButton disabled={!isValid || isSearching} />
      </Box>
    </Box>
  );
};
