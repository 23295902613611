import React from 'react';
import {AlertDialog} from '@molecules/Dialogs/AlertDialog';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import {CSSObject, Stack} from '@mui/material';

export type ConfirmDeleteInspectionDialogProps = DialogProps;

/**
 * 対象の点検表が紐づく機種別の点検設定、定期点検計画、及び点検実施予定の削除を確認するダイアログ
 *
 * @example
 * こんな感じで使ってください
 * ```tsx
 * dialogHandler.open(ConfirmDeleteInspectionDialog, {}).then(() => {
 *   // resolve時の処理
 * }).catch(() => {
 *   // reject時の処理
 * });
 * ```
 *
 * @param props.open ダイアログを開くかどうか
 * @param props.actions ダイアログの結果を返すためのresolve, reject関数
 */
export const ConfirmDeleteInspectionDialog: React.FC<ConfirmDeleteInspectionDialogProps> = (props) => {
  const {open, actions} = props;
  return (
    <AlertDialog
      open={open}
      actions={actions}
      maxWidth={'md'}
      boldTitle
      title={'「点検表の削除」に関する確認'}
      positiveButtonLabel="実行する">
      <Stack sx={wrapper}>
        点検表を削除すると、この点検表が紐づいている以下の設定・点検予定が削除されます。
        <br />
        <br />① 機種別点検設定
        <br />② 定期点検計画
        <br />③ ①②に関連する未実施の点検予定、予定月超過の点検予定
        <br />
        （スキップした点検および完了した点検は削除されません）
        <br />
        <br />
        なお、実施途中の点検がある場合、点検表は削除できません。
      </Stack>
      <Stack>この操作を実行しますか？実行すると元に戻せません。</Stack>
    </AlertDialog>
  );
};

const wrapper: CSSObject = {marginBottom: 4};
