import {isNullish} from '@front-libs/helpers';
import {Box, SxProps, styled} from '@mui/material';
import React, {useCallback, useState} from 'react';
import barcode from './assets/readLenderBarcode.png';

type Props = {
  onSubmitInput: (lenderName: string) => void;
};

export const BarcodeContent = ({onSubmitInput}: Props) => {
  const [lenderName, setLenderName] = useState('');

  const focusInput = useCallback((e: React.FocusEvent<HTMLInputElement, Element>) => {
    // FIX ME: 名前検索の実装後、by_direct_inputは消す
    e.target.focus();
  }, []);

  const handleBarcodeReaderInput = useCallback(
    async (event: React.KeyboardEvent<HTMLInputElement>) => {
      // Enter以外のキーを検知したらlenderNameに連結する
      if (event.key !== 'Enter') {
        setLenderName(lenderName + event.key);
        return;
      } else if (event.key === 'Enter' && lenderName?.length === 0) {
        return;
      }

      onSubmitInput(lenderName);
    },
    [lenderName, onSubmitInput]
  );

  return (
    <>
      <BarcodeInput
        type="text"
        autoFocus
        readOnly
        onBlur={focusInput}
        value={!isNullish(lenderName) ? lenderName : ''}
        onKeyDown={handleBarcodeReaderInput}
      />
      <Box sx={BarcodeImgStyle}>
        <BarcodeImg src={barcode} alt="バーコードの使い方" />
      </Box>
    </>
  );
};

const BarcodeImgStyle: SxProps = {
  position: 'fixed',
  inset: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const BarcodeInput = styled('input')({
  position: 'absolute',
  top: '20px',
  marginTop: '-1550px',
});

const BarcodeImg = styled('img')(({theme}) => ({
  width: '464px',
  [theme.breakpoints.up('laptop')]: {
    width: '580px',
  },
  [theme.breakpoints.up('desktop')]: {
    width: '696px',
  },
}));
