import React from 'react';
import {Card, Grid, makeStyles, Theme} from '@material-ui/core';
import {ProductIndex} from '@modules/products/types';
import {ProductCardHeader} from './ProductCardHeader';
import {selectedProductAtom} from '../state';
import {ProductCardFooter} from './ProductCardFooter';
import no_image from '@assets/no_image.svg';
import {useAtom} from 'jotai';

type Props = {
  product: ProductIndex;
};

export const ProductCard: React.FC<Props> = (props) => {
  const {product} = props;
  const [selectedProduct, setSelectedProduct] = useAtom(selectedProductAtom);
  const classes = useStyles();

  const handleClick = () => {
    setSelectedProduct(product);
  };
  // biome-ignore lint/complexity/noUselessTernary: <explanation>
  const isSelected = selectedProduct?.hashId === product.hashId ? true : false;
  return (
    <Card className={isSelected ? classes.selectedCard : classes.card} onClick={handleClick}>
      <ProductCardHeader product={product} />
      <Grid item container className={classes.content}>
        {product.thumbnailUrl === '' ? (
          <img src={no_image} alt="サムネイル" width={'80px'} object-fit={'contain'} loading="lazy" decoding="async" />
        ) : (
          <img
            src={product.thumbnailUrl}
            alt="サムネイル"
            className={classes.img}
            decoding="async"
            loading="lazy"
            onError={({currentTarget}) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = no_image;
            }}
          />
        )}
      </Grid>
      <ProductCardFooter product={product} />
    </Card>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    cursor: 'pointer',
    width: '240px',
    height: '300px',
    padding: '0px 12px 0px 0px',
    margin: '0px 16px 16px 0px',
  },
  selectedCard: {
    cursor: 'pointer',
    width: '240px',
    height: '300px',
    padding: '0px 12px 0px 0px',
    margin: '0px 16px 16px 0px',
    border: '2px solid #0052CC',
  },
  content: {
    justifyContent: 'center',
    height: '120px',
  },
  img: {
    width: '132px',
    maxHeight: '100px',
    objectFit: 'contain',
  },
}));
