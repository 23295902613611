import {RequiredOptionalInput} from '@components/molecules/Form/RequiredOptionalInput';
import {Box, Divider, SxProps} from '@mui/material';
import React from 'react';
import {Control, UseFormSetValue} from 'react-hook-form';
import {PartsDialogFormValues} from '../schema';
import {SelectorDataType, WholeProductSelector} from './WholeProductSelector';
import ErrorIcon from '@mui/icons-material/Error';

const dialogContentStyle: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  minWidth: '800px',
};

const DividerStyle: SxProps = {
  margin: '16px 0',
  borderColor: '#D1D5DB',
};

const BottomContainerStyle: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
};

const UpperContainerStyle: SxProps = {
  display: 'flex',
  gap: '16px',
  flexDirection: 'column',
  width: '50%',
};

export type DialogContentProps = {
  control: Control<PartsDialogFormValues>;
  setValue: UseFormSetValue<PartsDialogFormValues>;
  selectedData?: SelectorDataType[];
  errorMessage?: string;
};

export const DialogContent = ({control, setValue, selectedData, errorMessage}: DialogContentProps) => {
  return (
    <Box sx={dialogContentStyle}>
      <Box sx={UpperContainerStyle}>
        <RequiredOptionalInput<PartsDialogFormValues>
          label="部品・交換品名"
          required={true}
          control={control}
          name="name"
        />
        <RequiredOptionalInput<PartsDialogFormValues>
          label="部品・交換品目"
          required={true}
          control={control}
          name="partCode"
        />
        {errorMessage && (
          <Box sx={{color: '#C7243A', display: 'flex', alignItems: 'center', gap: '4px'}}>
            <ErrorIcon />
            {errorMessage}
          </Box>
        )}
      </Box>
      <Divider sx={DividerStyle} />
      <Box sx={BottomContainerStyle}>
        <WholeProductSelector control={control} setValue={setValue} selectedData={selectedData} />
      </Box>
    </Box>
  );
};
