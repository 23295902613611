import React, {useCallback, useMemo} from 'react';
import {FilterDrawer} from '@molecules/Drawers/FilterDrawer';
import {useAtom, useAtomValue, useSetAtom} from 'jotai';
import {
  openDrawerAtom,
  wholeProductTotalCount,
  searchFilterResultsAtom,
  pageAtom,
} from '@modules/hospital_whole_products_bulk_update/jotai';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useGetMakerOptions} from '@modules/hospital_whole_products_bulk_update/hooks';
import {FilterDrawerOptions} from './consts';
import {ResultType} from 'src/components/molecules/Drawers/FilterDrawer/types';

export const WholeProductFilterDrawer = () => {
  const {myInfo} = useMyInfo();
  const [openDrawer, setOpenDrawer] = useAtom(openDrawerAtom);
  const totalCount = useAtomValue(wholeProductTotalCount);
  const [searchFilterResults, setSearchFilterResults] = useAtom(searchFilterResultsAtom);
  const setPage = useSetAtom(pageAtom);
  const handleDrawerClose = useCallback(() => setOpenDrawer(false), []);
  const {makerNameOptions} = useGetMakerOptions(myInfo.hospitalHashId);

  const filterDrawerOptions = useMemo(() => {
    return FilterDrawerOptions(makerNameOptions);
  }, [makerNameOptions]);

  const handleAdditionalFilterResults = useCallback(
    (results: ResultType[]) => {
      setSearchFilterResults(results);
      setPage(1);
    },
    [setSearchFilterResults]
  );

  return (
    <FilterDrawer
      open={openDrawer}
      onDrawerClose={handleDrawerClose}
      currentCount={totalCount}
      filterOptions={filterDrawerOptions}
      onFilterChange={handleAdditionalFilterResults}
      defaultFilterResults={searchFilterResults}
    />
  );
};
