import React from 'react';
import {AlertDialog} from '@molecules/Dialogs/AlertDialog';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import {CSSObject, Stack} from '@mui/material';

export type InspectionWholeProductPlanDeleteAlertDialogProps = DialogProps;

/**
 * 対象の点検に関連する定期点検計画、及び点検実施予定の削除を確認するダイアログ
 *
 * @example
 * こんな感じで使ってください
 * ```tsx
 * dialogHandler.open(InspectionWholeProductPlanDeleteAlertDialog, {}).then(() => {
 *   // resolve時の処理
 * }).catch(() => {
 *   // reject時の処理
 * });
 * ```
 *
 * @param props.open ダイアログを開くかどうか
 * @param props.actions ダイアログの結果を返すためのresolve, reject関数
 */
export const InspectionWholeProductPlanDeleteAlertDialog: React.FC<InspectionWholeProductPlanDeleteAlertDialogProps> = (
  props
) => {
  const {open, actions} = props;
  return (
    <AlertDialog
      open={open}
      actions={actions}
      boldTitle
      maxWidth={'md'}
      title="対象の点検に関連する定期点検計画、及び点検実施予定の削除"
      positiveButtonLabel="実行する">
      <Stack sx={wrapper}>
        機種別の点検設定を削除すると、関連する定期点検計画（点検開始月・月ごとの点検日を設定）が削除されます。
        <br />
        また、上記点検設定・計画に関連する「未実施の点検実施予定」、及び「予定月超過の点検実施予定」も削除されます。
        <br />
        ※スキップした点検実施予定、実施途中の点検、及び完了した点検は削除対象外です。
      </Stack>
      <Stack>この操作を実行しますか？実行すると元に戻せません。</Stack>
    </AlertDialog>
  );
};

const wrapper: CSSObject = {marginBottom: 4};
