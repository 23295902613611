import {atom} from 'jotai';
import {atomWithReset} from 'jotai/utils';
import {ProductIndex} from '@modules/products/types';

export const selectedProductAtom = atomWithReset<ProductIndex | null>(null);
export const searchNameAtom = atomWithReset<string>('');
export const selectedMakerAtom = atom<string | null>(null);
export const selectedRootCategoryAtom = atom<string | null>(null);
export const selectedNarrowCategoryAtom = atom<string | null>(null);
export const selectedCategoryAtom = atom<string | null>(null);
