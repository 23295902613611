import React from 'react';
import {HospitalProductPlanListElement} from '../../types';
import {Grid, styled} from '@mui/material';

const StyledGrid = styled(Grid)({
  borderBottom: '1px solid',
});
type StartDateColumnProps = {
  rowData: HospitalProductPlanListElement;
};

export const StartDateColumn = ({rowData}: StartDateColumnProps) => {
  return <>{rowData.startDate && <StyledGrid>{rowData.startDate}日</StyledGrid>}</>;
};
