import React from 'react';
import {Grid, TextField, styled} from '@mui/material';
import {Search} from '@material-ui/icons';
import {PopperSelectBoxButton} from '@molecules/Buttons/PopperSelectBoxButton';
import {CategoryFormatter} from '@modules/categories/helpers';
import {CompanyFormatter} from '@modules/companies/helpers';
import {UserIndex} from '@modules/hospital_users/types';
import {useCategoryLogic, useMakerSelectLogic, useResetOnMount, useSearchBarLogic} from './hooks';

const StyledTextField = styled(TextField)(({theme}) => ({
  width: '320px',
  backgroundColor: theme.palette.common.white,
  fontSize: '14px',
}));

const StyledGrid = styled(Grid)(() => ({
  gap: '24px',
  paddingBottom: '24px',
}));

// ダイアログ内だとスタイルが適用されないため
const buttonStyle = {
  color: '#0052CC',
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: '#8e99f3',
  },
};

type SearchBarProps = {
  myInfo: UserIndex;
};

export const SearchBar = (props: SearchBarProps) => {
  const {myInfo} = props;
  const {handleChangeSearchName} = useSearchBarLogic();
  const {handleChangeRootCategories, handleChangeSubCategories, descendantCategoryOptions, rootCategoryQuery} =
    useCategoryLogic(myInfo.hospitalHashId);
  const {companies, handleMakerChange, onInputChange} = useMakerSelectLogic(myInfo.hospitalHashId);

  useResetOnMount();

  return (
    <StyledGrid container>
      <StyledTextField
        label="機種名・型式で検索"
        variant="outlined"
        size="small"
        InputProps={{
          endAdornment: <Search />,
        }}
        InputLabelProps={{
          style: {
            fontSize: 14,
          },
        }}
        onChange={handleChangeSearchName}
      />
      <PopperSelectBoxButton
        buttonLabel={'大分類'}
        options={CategoryFormatter.getOptions(rootCategoryQuery.data)}
        isMulti={false}
        onChange={handleChangeRootCategories}
        searchable={true}
        buttonProps={{
          style: buttonStyle,
        }}
      />
      <PopperSelectBoxButton
        buttonLabel={'小分類'}
        options={descendantCategoryOptions}
        isMulti={false}
        searchable={true}
        onChange={handleChangeSubCategories}
        buttonProps={{
          style: buttonStyle,
        }}
      />
      <PopperSelectBoxButton
        buttonLabel={'メーカー'}
        options={CompanyFormatter.getOptions(companies?.data ?? [])}
        isMulti={false}
        searchable={true}
        onChange={handleMakerChange}
        onInputChange={onInputChange}
        buttonProps={{
          style: buttonStyle,
        }}
      />
    </StyledGrid>
  );
};
