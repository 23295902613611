import {axios} from '@front-libs/core';
import {useQuery} from 'react-query';
import {StandardListApiResponse} from '../common/type';
import {HospitalDepartMent} from './types';
import {useMemo} from 'react';
import {getHospitalDepartmentsKey} from '@constants/api';
import {useRef, useEffect} from 'react';
import {atom, useAtom} from 'jotai';
import {isEqual} from 'lodash';

export type HospitalDepartment = {
  hashId: string;
  hospitalHashId: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
  deletedBy: null | string;
  name: string;
};

export type HospitalDepartments = HospitalDepartment[];

export type HospitalDepartmentsResponse = {
  page: number;
  totalCount: number;
  data: HospitalDepartments;
};

export type HospitalDepartmentParams = {
  page: number;
  perPage: number;
  name?: string;
  order?: string;
};
/**
 * 管理部署の一覧を取得
 * @param hospitalHashId
 * @returns
 */
export const getHospitalDepartment = async (hospitalHashId: string, params?: HospitalDepartmentParams) => {
  const url = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/hospital_department`;
  const response = await axios.get<HospitalDepartmentsResponse>(url, params && {params});
  return response.data;
};

/**
 * 管理部署の追加
 * @param hospitalHashId
 * @param HospitalDepartmentParams
 * @returns
 */
export const createHospitalDepartment = async (hospitalHashId: string, params: HospitalDepartmentParams) => {
  const url = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/hospital_department`;
  const res = await axios.post(url, {name: params.name});
  return res.data;
};

const hospitalProductDepartmentAtom = atom<
  {
    label: string;
    value: string;
  }[]
>([]);

const departmentSectionAtom = atom<HospitalDepartmentsResponse | undefined>(undefined);

export const useHospitalDepartment = (hospitalHashId: string) => {
  const [managementDepartment, setManagementDepartment] = useAtom(departmentSectionAtom);

  const isLoading = useRef(true);

  useEffect(() => {
    (async () => {
      if (!isLoading.current) return;

      const hospitalDepartments = await getHospitalDepartment(hospitalHashId);

      // NOTE:deletedByに値が入っていれば削除済みとして表示させない
      hospitalDepartments.data.filter((v) => v.deletedBy === null);
      if (!isEqual(managementDepartment, hospitalDepartments)) {
        setManagementDepartment(hospitalDepartments);
      }
      isLoading.current = false;
    })();
  }, [hospitalHashId, managementDepartment, setManagementDepartment]);

  return managementDepartment;
};

/**
 * 指定された病院のハッシュIDに基づいて、病院製品管理セクションのオプションを管理するためのフック
 *
 * このフックは、病院の製品管理セクションをフェッチし、削除されたものをフィルタリングし、
 * アクティブなセクションで管理部門の状態を設定します。フックは管理部門の現在の状態を返します
 *
 * @param hospitalHashId - 製品管理セクションを管理する病院のハッシュID
 * @returns - 管理部門の現在の状態
 *
 * @example
 * const managementDepartment = useHospitalDepartmentOption('ある病院のハッシュID');
 */
export const useHospitalDepartmentOption = (hospitalHashId: string, params?: HospitalDepartmentParams) => {
  const [managementDepartment, setManagementDepartment] = useAtom(hospitalProductDepartmentAtom);

  const isLoading = useRef(true);

  useEffect(() => {
    (async () => {
      if (!isLoading.current) return;

      const hospitalDepartments = (await getHospitalDepartment(hospitalHashId, params)).data;

      // NOTE:deletedByに値が入っていれば削除済みとして表示させない
      const options = hospitalDepartments
        .filter((v) => v.deletedBy === null)
        .map((hospitalDepartment) => ({
          label: hospitalDepartment.name,
          value: hospitalDepartment.hashId,
        }));
      if (!isEqual(managementDepartment, options)) {
        setManagementDepartment(options);
      }
      isLoading.current = false;
    })();
  }, [hospitalHashId, managementDepartment, params, setManagementDepartment]);

  return managementDepartment;
};

export type GetDepartmentsParams = {
  name?: string;
  page?: number;
  perPage?: number;
  order?: string;
  showDeleted?: boolean; // 削除済み取得
};

export const fetchHospitalDepartments = async (hospitalHashId: string, params: GetDepartmentsParams) => {
  const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/hospital_department`;
  const {data} = await axios.get<StandardListApiResponse<HospitalDepartMent>>(baseUrl, {params});
  return data;
};

export const useFetchHospitalDepartments = (hospitalHashId: string, params: GetDepartmentsParams) => {
  const query = useQuery([getHospitalDepartmentsKey, hospitalHashId, params], () =>
    fetchHospitalDepartments(hospitalHashId, params)
  );
  return useMemo(
    () => ({
      ...query,
      data: query.data?.data ?? [],
      totalCount: query.data?.totalCount ?? 0,
    }),
    [query]
  );
};
