import React from 'react';
import {SimpleDialog, SimpleDialogProps} from '@molecules/Dialogs/BaseDialog';
import {DialogTitleProps} from '@atoms/Dialog/DialogTitle';
import warningExclamation from '@assets/warning_exclamation.svg';

const defaultProps: Partial<SimpleDialogProps> = {
  positiveButtonLabel: '削除する',
  negativeButtonLabel: 'キャンセル',
  dialogTitleProps: {} as DialogTitleProps,
  warning: true,
  boldTitle: false,
};

const injectDialogProps = {disabledCancel: true};
type Props = SimpleDialogProps;

const AlertTitle = ({warning, children}: {warning: boolean; children: React.ReactNode}) => (
  <>
    {warning && (
      <img src={warningExclamation} alt="警告" style={{paddingRight: '8px', top: '4px', position: 'relative'}} />
    )}
    {children}
  </>
);

export const AlertDialog: React.FC<Props> = (props) => {
  const {title, dialogTitleProps, ...rest} = {
    ...defaultProps,
    ...props,
  };

  return (
    <SimpleDialog
      title={<AlertTitle warning={rest.warning ?? true}>{title}</AlertTitle>}
      {...rest}
      dialogTitleProps={{...injectDialogProps, ...dialogTitleProps}}
      boldTitle={rest.boldTitle}
    />
  );
};
