import React from 'react';
import {PDFDisplay} from '../Utils/PDFDisplay';
import {fetchFileUrlByHashId} from '@modules/files/api';
import {useAsyncMemo} from '@front-libs/core';
import {Box} from '@mui/material';

export type FilePreviewProps = {
  fileHashId: string;
  fileType: string;
  fileName: string;
  scale: number;
};

export const FilePreview = ({scale, fileType, fileHashId, fileName}: FilePreviewProps) => {
  const {redirectUrl, error} = useAsyncMemo(
    () => {
      return fetchFileUrlByHashId(fileHashId);
    },
    {redirectUrl: null, error: 'Loading...'},
    [fileHashId]
  );

  if (error) {
    return <div>{error}</div>;
  }

  if (!redirectUrl || redirectUrl === '' || (!fileType.match('application/pdf') && !fileType.match('image/*'))) {
    return <div>この形式のファイルはプレビューできません。</div>;
  }

  return (
    <Box sx={{width: '100%', height: '100%'}}>
      {fileType.match('application/pdf') ? (
        <PDFDisplay src={redirectUrl} scale={scale} />
      ) : fileType.match('image/*') ? (
        <img
          src={redirectUrl}
          alt={fileName}
          style={{
            objectFit: 'contain',
            width: '100%',
            height: '100%',
          }}
        />
      ) : null}
    </Box>
  );
};
