import React, {ReactNode} from 'react';
import {InspectionMenu} from './InspectionMenu';
import {CSSObject, Grid} from '@mui/material';

const GridItemStyle: CSSObject = {
  margin: '0px 24px',
};

type Props = {
  node?: ReactNode;
};

export const InspectionMenuHeader = ({node}: Props) => {
  return (
    <Grid container justifyContent={'space-between'}>
      <Grid item sx={GridItemStyle}>
        <InspectionMenu />
      </Grid>
      <Grid item sx={GridItemStyle}>
        {node}
      </Grid>
    </Grid>
  );
};
