import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, createStyles, Grid, makeStyles, Theme, Typography, styled} from '@material-ui/core';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {openSnackBar} from '@molecules/SnackBar';
import {PageRoutingMenu} from '@organisms/PageRoutingMenu';
import {PopperSelectBoxButton, SelectOptionProps} from '@molecules/Buttons/PopperSelectBoxButton';
import {RegistrationChannelDialog} from '@Apps/Channel/RegistrationChannelDialog';
import {HospitalWardsParams, useFetchHospitalWards} from '@modules/hospital_places/api';
import {allZoneIds, allZoneOptions} from '@Apps/Channel/consts';
import {ZoneTable} from '@Apps/Channel/ZoneTable';
import {assignChannel, useFetchChannelsInfo} from '@modules/channels/api';
import {
  defaultChannelHashIdAtom,
  dialogChannelNumberAtom,
  isOpenChannelDialogAtom,
  zoneFilterStatus,
} from '@Apps/Channel/states';
import {useAtom} from 'jotai';
import {useUpdateChannelSubject} from '@Apps/Channel/hooks';
import Axios from 'axios';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {ListPageLayout} from '@components/layouts/ListPageLayout';
import {TwoColumns} from '@components/layouts/columns/TwoColumns';
import {ChevronLeft} from '@material-ui/icons';
import {useBackPrevious} from '@front-libs/core';
import {useUserResourcesPermissions} from '@modules/hospital_users/hooks/useUserPermissions';
import {FEATURE_CUSTOM_ASSET_ROLE_FLAG} from '@constants/constants';
import {useMyRole} from '@modules/hospital_users/hooks/useMyRole';

const BackButton = styled(Button)({
  fontWeight: 400,
  fontSize: '14px',
  padding: 0,
});
const BackProductsButton = () => {
  const backPrevious = useBackPrevious('/products');
  const handleClickProductsLink = () => {
    backPrevious();
  };
  return (
    <BackButton onClick={handleClickProductsLink}>
      <ChevronLeft />
      機器一覧
    </BackButton>
  );
};

const scrollToChannelNumber = (channelNumber: string) => {
  const elements = document.getElementsByTagName('p');

  let foundElement = null;
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i] as HTMLElement;

    // 要素がテキストを含んでいるか確認
    if (element.innerText.includes(channelNumber)) {
      foundElement = element;
      break;
    }
  }

  if (foundElement) {
    foundElement.scrollIntoView({behavior: 'auto', block: 'center', inline: 'center'});
  }
};

export const Channel: React.FC = () => {
  const {canAccess, canEdit} = useUserResourcesPermissions('CHANNEL_LIST');
  const navigate = useNavigate();
  if (FEATURE_CUSTOM_ASSET_ROLE_FLAG) {
    useEffect(() => {
      if (!canAccess) {
        navigate('/dashboard');
      }
    }, [canAccess, navigate]);
    if (!canAccess) return null;
  }

  const classes = useStyles();
  const {myInfo} = useMyInfo();
  const {isReadOnly} = useMyRole();
  const {notify} = useUpdateChannelSubject();
  const [searchParams] = useSearchParams();

  const params: HospitalWardsParams = {
    page: 0,
    perPage: 100,
  };
  const queryWard = useFetchHospitalWards(myInfo.hospitalHashId, params);

  const queryChannel = useFetchChannelsInfo(myInfo.hospitalHashId, {
    page: 1,
    perPage: 1,
    isAssigned: true,
  });

  const channelNumber = searchParams.get('channelNumber');
  useEffect(() => {
    scrollToChannelNumber(channelNumber ?? '6001');
  }, [channelNumber, queryChannel.data]);

  // const handleActionMenuClick = async (item: MenuItemType) => {
  //   switch (item.value) {
  //     case 'export':
  //       handleExport();
  //       break;
  //   }
  // };

  // const handleExport = () => {
  //   openSnackBar('ファイルのエクスポートが完了しました', 'center', 'top');
  // };
  const [isOpenChannelDialog, setIsOpenChannelDialog] = useAtom(isOpenChannelDialogAtom);
  const [defaultChannelHashId, setDefaultChannelHashId] = useAtom(defaultChannelHashIdAtom);
  const [dialogChannelNumber, setDialogChannelNumber] = useAtom(dialogChannelNumberAtom);

  const registrationActions = {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    resolve: async (result: any) => {
      setIsOpenChannelDialog(false);
      try {
        await assignChannel(myInfo.hospitalHashId, result.hospitalProductHashId, {
          hospitalRoomHashId: result.roomHashId,
          channelHashId: defaultChannelHashId ? defaultChannelHashId : result.channelHashId,
        });
      } catch (e) {
        const errorMessage =
          Axios.isAxiosError(e) && e.response?.status === 400
            ? '混信の可能性があるため、登録できませんでした。'
            : 'チャンネルの登録に失敗しました';
        openSnackBar(errorMessage, 'left', 'bottom', 'error');
        setIsOpenChannelDialog(false);
        setDialogChannelNumber(null);
        return;
      }
      notify('all');
      openSnackBar('機器をチャンネルに登録しました。');
      setDialogChannelNumber(null);
      setDefaultChannelHashId('');
    },
    reject: async () => {
      setIsOpenChannelDialog(false);
      setDefaultChannelHashId('');
      setDialogChannelNumber(null);
    },
  };

  const [zoneIds, setZoneIds] = useAtom(zoneFilterStatus);
  const initialZoneOptions = allZoneOptions.filter((v) => zoneIds.includes(v.value));
  const handleZoneChange = (v: SelectOptionProps[] | undefined) => {
    const zones = v?.map((item) => item.value) || [];
    if (zones.length > 0) {
      setZoneIds(
        zones.sort((f, s) => {
          const first = Number(f);
          const second = Number(s);
          if (first > second) {
            return 1;
          } else {
            return -1;
          }
        })
      );
    } else {
      setZoneIds([]);
    }
  };

  const [wards, setWards] = useState<string[]>([]);
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const handleWardChange = useCallback((e: any) => {
    if (e) setWards(e.map((w: {value: string; label: string}) => w.value) ?? []);
    else setWards([]);
  }, []);

  useEffect(() => {
    notify('all');
  }, [notify, wards]);

  const returnCurrentChannelNumber = () => {
    return dialogChannelNumber ?? undefined;
  };

  const ZoneTables = useMemo(() => {
    const list: React.ReactElement[] = [];
    const addZoneTable = (id: string, index: number) => {
      list.push(
        <ZoneTable
          key={`zone-${id}_${index}`}
          zoneId={id}
          hospitalHashId={myInfo.hospitalHashId}
          hospitalWardHashIds={wards}
        />
      );
    };
    if (zoneIds.length > 0) {
      zoneIds.forEach((id, index) => addZoneTable(id, index));
    } else {
      allZoneIds.forEach((id, index) => addZoneTable(id, index));
    }
    return list;
  }, [zoneIds, myInfo.hospitalHashId, wards]);

  return (
    <ListPageLayout page="channels">
      <ListPageLayout.Header>
        <TwoColumns alignItems="center">
          <TwoColumns.Left>
            <Grid item>
              <BackProductsButton />
              <PageRoutingMenu>
                <Typography variant={'h5'} className={classes.pageTitle}>
                  チャンネル一覧
                </Typography>
              </PageRoutingMenu>
              <Typography variant={'caption'} className={classes.totalCount}>
                {queryChannel.data?.totalCount}件のチャンネル使用
              </Typography>
            </Grid>
          </TwoColumns.Left>
          <TwoColumns.Right>
            {((FEATURE_CUSTOM_ASSET_ROLE_FLAG && canEdit) || (!FEATURE_CUSTOM_ASSET_ROLE_FLAG && !isReadOnly)) && (
              <Button variant={'contained'} color={'primary'} onClick={() => setIsOpenChannelDialog(true)}>
                チャンネルを新規登録
              </Button>
            )}
          </TwoColumns.Right>
        </TwoColumns>
      </ListPageLayout.Header>
      <ListPageLayout.Content className={classes.content}>
        <Grid container className={classes.tableContainer}>
          <Grid item className={classes.selector}>
            <PopperSelectBoxButton
              buttonLabel={'ゾーン'}
              options={allZoneOptions}
              isMulti={true}
              initialOption={initialZoneOptions}
              onChange={(v) => handleZoneChange(v)}
              searchable={true}
            />
          </Grid>
          <Grid item className={classes.selector}>
            <PopperSelectBoxButton
              buttonLabel={'機器管理場所'}
              options={queryWard.data.map((ward) => ({
                value: ward.hashId,
                label: ward.name,
              }))}
              isMulti={true}
              onChange={handleWardChange}
              searchable={true}
            />
          </Grid>
          {/* <div className={classes.flex} />
        <Grid item style={{minWidth: '150px'}}>
          <PopperMenuButton
            buttonProps={{variant: 'contained', className: classes.actionBtn, disableElevation: true}}
            menuItemList={actionMenuItems}
            onMenuClick={handleActionMenuClick}>
            アクション
          </PopperMenuButton>
        </Grid> */}
        </Grid>
        {ZoneTables}
      </ListPageLayout.Content>
      <RegistrationChannelDialog
        open={isOpenChannelDialog}
        actions={registrationActions}
        hospitalHashId={myInfo.hospitalHashId}
        defaultValues={{channelHashId: defaultChannelHashId}}
        channelNumber={returnCurrentChannelNumber()}
      />
    </ListPageLayout>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageTitle: {
      fontWeight: 'bold',
      fontSize: 20,
      color: theme.palette.primary.dark,
    },
    flex: {
      flexGrow: 1,
    },
    content: {
      overflow: 'scroll',
    },
    tableContainer: {
      marginBottom: '24px',
    },
    selector: {
      display: 'flex',
      alignItems: 'center',
    },
    actionBtn: {
      width: '100%',
      background: 'rgba(9, 30, 66, 0.04)',
      border: '1px solid #C6CBD4',
    },
    totalCount: {
      paddingLeft: 8,
      fontSize: 14,
      fontWeight: 700,
      color: theme.palette.grey[600],
    },
  })
);
