import {useReducer} from 'react';
import {
  ListHospitalProductRequiredPropertiesResponse,
  UpdateHospitalProductRequiredPropertiesRequest,
  UpdateHospitalProductRequiredPropertiesResponse,
  ListHospitalProductRequiredPropertyItemsResponse,
} from './types';
import {
  listHospitalProductRequiredProperties,
  updateHospitalProductRequiredProperties,
  listHospitalProductRequiredPropertyItems,
} from './api';
import {reducer, initialState} from './reducer';
import {HospitalProductRequiredPropertyState, HospitalProductRequiredPropertyActionType} from './types';

type HospitalProductRequiredPropertyStoreType = {
  state: HospitalProductRequiredPropertyState;
  dispatchListHospitalProductRequiredProperties: (
    hospitalHashId: string
  ) => Promise<ListHospitalProductRequiredPropertiesResponse>;
  dispatchUpdateHospitalProductRequiredProperties: (
    hospitalHashId: string,
    req: UpdateHospitalProductRequiredPropertiesRequest
  ) => Promise<UpdateHospitalProductRequiredPropertiesResponse>;
  dispatchListHospitalProductRequiredPropertyItems: (
    hospitalHashId: string
  ) => Promise<ListHospitalProductRequiredPropertyItemsResponse>;
};

export const useHospitalProductRequiredPropertyStore = (): HospitalProductRequiredPropertyStoreType => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const dispatchListHospitalProductRequiredProperties = async (hospitalHashId: string) => {
    const res = await listHospitalProductRequiredProperties(hospitalHashId);
    dispatch({
      type: HospitalProductRequiredPropertyActionType.HOSPITAL_PRODUCT_REQUIRED_PROPERTY_SET_LIST,
      payload: {hospitalProductRequiredProperties: res.data},
    });
    return res;
  };

  const dispatchUpdateHospitalProductRequiredProperties = async (
    hospitalHashId: string,
    req: UpdateHospitalProductRequiredPropertiesRequest
  ) => {
    const res = await updateHospitalProductRequiredProperties(hospitalHashId, req);
    dispatch({
      type: HospitalProductRequiredPropertyActionType.HOSPITAL_PRODUCT_REQUIRED_PROPERTY_SET_LIST,
      payload: {hospitalProductRequiredProperties: res.data},
    });
    return res;
  };

  const dispatchListHospitalProductRequiredPropertyItems = async (hospitalHashId: string) => {
    const res = await listHospitalProductRequiredPropertyItems(hospitalHashId);
    dispatch({
      type: HospitalProductRequiredPropertyActionType.HOSPITAL_PRODUCT_REQUIRED_PROPERTY_SET_LIST_ITEMS,
      payload: {hospitalProductRequiredPropertyItems: res.data},
    });
    return res;
  };

  return {
    state,
    dispatchListHospitalProductRequiredProperties,
    dispatchUpdateHospitalProductRequiredProperties,
    dispatchListHospitalProductRequiredPropertyItems,
  };
};
