import React, {Suspense, useMemo} from 'react';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import {yup} from '@front-libs/core';
import {Form, Formik, useFormikContext} from 'formik';
import {Dialog} from '@atoms/Dialog/Dialog';
import {Button, DialogActions, Grid, styled, TextField} from '@material-ui/core';
import {RequiredLabel} from '@molecules/FormRequiredLabel';
import Selector from '@molecules/Formik/fields/Selector';
import {useFetchHospitalRooms} from '@modules/hospital_places/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {HospitalRoomFormatter} from '@modules/hospital_wards/helpers';

import {UserFormatter} from '@modules/hospital_users/helpers';
import {useHospitalUsers} from '@modules/hospital_users/hooks/useHospitalUsers';

type BulkMoveBuildingFormProps = {
  hospitalRoomHashId: string;
  registererHashId: string;
  movedAt: string;
};

const BulkMoveBuildingForm = () => {
  const {initialValues, setFieldValue} = useFormikContext<BulkMoveBuildingFormProps>();
  const {myInfo} = useMyInfo();

  const {hospitalUsers} = useHospitalUsers();
  const {data: hospitalRooms} = useFetchHospitalRooms(myInfo.hospitalHashId, {
    page: 0,
    perPage: 100,
    order: 'hospitalWard,name',
  });

  const hospitalRoomOptions = useMemo(() => {
    const roomOptions = hospitalRooms.map((item) => ({
      label: HospitalRoomFormatter.getFullRoom(item),
      value: item.hashId,
    }));

    return roomOptions;
  }, [hospitalRooms]);

  const userOptions = useMemo(
    () => UserFormatter.getOptions(hospitalUsers, {withAlias: true, withSubLabel: true}),
    [hospitalUsers]
  );

  const defaultUser = useMemo(
    () => userOptions.find((o) => o.value === initialValues.registererHashId),
    [initialValues.registererHashId, userOptions]
  );

  if (userOptions.length === 0) {
    return null;
  }

  return (
    <StyledForm>
      <StyledGrid>
        <RequiredLabel>登録者</RequiredLabel>
        <Selector name="registererHashId" size="small" options={userOptions} defaultValue={defaultUser} />
      </StyledGrid>
      <StyledGrid>
        <RequiredLabel>転棟日時</RequiredLabel>
        <StyledTextField
          type="datetime-local"
          name="movedAt"
          size="small"
          fullWidth
          defaultValue={initialValues.movedAt}
          onChange={(e) => {
            setFieldValue('movedAt', e.target.value);
          }}
        />
      </StyledGrid>
      <StyledGrid>
        <RequiredLabel>転棟先</RequiredLabel>
        <Selector name="hospitalRoomHashId" size="small" options={hospitalRoomOptions} />
      </StyledGrid>
      <StyledGrid>貸出中の機器のみ転棟処理ができます</StyledGrid>
    </StyledForm>
  );
};

const StyledTextField = styled(TextField)({
  borderRadius: '4px',
  '&&': {
    border: '1px solid #ccc',
  },
  '& .MuiInput-underline:before': {
    borderBottom: 'none',
  },
  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
    borderBottom: 'none',
  },
  '& .MuiInput-underline:after': {
    borderBottom: 'none',
  },
  '& .MuiInputBase-inputMarginDense': {
    margin: '4px',
  },
});

type BulkMoveBuildingDialogProps = {
  defaultUserHashId: string;
  defaultMovedDate: string;
} & DialogProps;

export const BulkMoveBuildingDialog = (props: BulkMoveBuildingDialogProps) => {
  const validationSchema = yup.object({
    registererHashId: yup.string().required(),
    movedAt: yup.date().required(),
    hospitalRoomHashId: yup.string().required(),
  });

  const handleSubmit = (res: BulkMoveBuildingFormProps) => {
    props.actions.resolve(res);
  };

  const handleClose = () => {
    props.actions.reject();
  };

  return (
    <Suspense fallback={null}>
      <Formik<BulkMoveBuildingFormProps>
        initialValues={{
          hospitalRoomHashId: '',
          registererHashId: props.defaultUserHashId,
          movedAt: props.defaultMovedDate,
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        isInitialValid={false}>
        {(formik) => (
          <Dialog {...props} fullWidth maxWidth="sm">
            <Container>
              <Title>転棟</Title>
              <BulkMoveBuildingForm />
              <Actions>
                <Button
                  disabled={!formik.isValid || formik.isSubmitting}
                  onClick={() => handleSubmit(formik.values)}
                  color="primary"
                  variant="contained">
                  登録
                </Button>
                <Button disabled={formik.isSubmitting} onClick={handleClose}>
                  キャンセル
                </Button>
              </Actions>
            </Container>
          </Dialog>
        )}
      </Formik>
    </Suspense>
  );
};

const Title = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingBottom: '20px',
  fontSize: '20px',
  fontWeight: 400,
});

const Container = styled('div')({
  margin: '20px',
});

const Actions = styled(DialogActions)({
  display: 'flex',
  justifyContent: 'flex-end',
});

const StyledGrid = styled(Grid)({
  '&:not(:first-child)': {
    marginTop: '16px',
  },
});

const StyledForm = styled(Form)({
  paddingBottom: '8px',
});
