import {axios} from '@front-libs/core';
import {HospitalProductNoteSettings, NoteSettings} from '../types';

/**
 * 汎用項目を返す
 * @param hospitalHashId
 * @returns
 */
export const getHospitalProductNoteSettings = async (hospitalHashId: string): Promise<HospitalProductNoteSettings> => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/hospital_product_note_settings`;
  const response = await axios.get<HospitalProductNoteSettings>(baseUrl);
  return response.data;
};

export const putHospitalProductNoteSettings = async (hospitalHashId: string, data: NoteSettings) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/hospital_product_note_settings`;

  const response = await axios.post<HospitalProductNoteSettings>(baseUrl, {note_settings: data});
  return response.data;
};
