import React from 'react';
import {createStyles, makeStyles, Theme, FormHelperText, Grid} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorContainer: {
      height: '20px',
    },
  })
);

type FieldErrorProps = {
  error?: string;
  showsError: boolean;
};

export const FieldError: React.FC<FieldErrorProps> = (props) => {
  const {error, showsError} = props;
  const classes = useStyles();

  if (showsError !== true) return null;

  return (
    <Grid className={classes.errorContainer}>
      {error !== undefined && <FormHelperText error={true}>{error}</FormHelperText>}
    </Grid>
  );
};
