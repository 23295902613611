import React from 'react';
import {styled, Button, DialogActions, DialogTitle} from '@mui/material';
import {useBarcodeReader} from '@front-libs/core';
import {CodeReaderType} from '../types';

type Props = {
  onRead: (managementId: string) => void;
  onChangeCodeReaderType: (codeReaderType: CodeReaderType) => void;
};

const Actions = styled(DialogActions)({
  marginTop: '40px',
  '& > button:nth-of-type(n+2)': {
    marginLeft: '16px',
  },
});

export const BarcodeReader = ({onRead, onChangeCodeReaderType}: Props) => {
  const handleRead = (managementId: string) => {
    onRead(managementId);
  };

  const {BarcodeInput} = useBarcodeReader({
    forcedFocus: true,
    onInputData: handleRead,
  });

  const handleSwitchToCamera = () => {
    onChangeCodeReaderType('camera');
  };

  return (
    <>
      <DialogTitle sx={{padding: 0, minWidth: '748px', fontWeight: 700}}>
        リーダーで、対象機器のバーコードまたはQRコードを読み取ってください
      </DialogTitle>
      {BarcodeInput}
      <Actions>
        <Button variant="text" sx={{color: '#0052CC'}} onClick={handleSwitchToCamera}>
          カメラに切り替える
        </Button>
      </Actions>
    </>
  );
};
