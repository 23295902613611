import React, {useCallback, useEffect, useState} from 'react';
import {Button, Grid, IconButton, styled} from '@material-ui/core';
import {ChatBubbleOutline, ChevronLeft} from '@material-ui/icons';
import {useNavigate} from 'react-router-dom';
import {MobileProductInfo} from '@molecules/MobileProductInfo';
import {HeaderFileUploadButton} from '@components/molecules/Buttons/sp/HeaderFileUploadButton';
import {useAtomValue} from 'jotai';
import {showHeaderFileUploadButtonAtom} from '../HospitalProductFileList';
import {HospitalProductDetail} from '@modules/hospital_products/types';
import {createHospitalProductLog} from '@modules/hospital_products/api';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {MobileCommentDialog} from '@molecules/MobileCommentDialog';
import {useAuth0} from '@auth0/auth0-react';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useMobileMonthlyProductHistory} from '@Apps/ProductDetail/hooks';
import {openSnackBar} from '@molecules/SnackBar';
import {useWindowSize} from 'react-use';

const Container = styled(Grid)({
  padding: '8px 16px',
  backgroundColor: 'white',
});

const StickyContainer = styled('div')(({width}: {width: number}) => ({
  position: 'sticky',
  top: 0,
  width: width,
}));

const ButtonContainer = styled('div')({
  display: 'flex',
  '& .MuiIconButton-root': {
    margin: '0 4px',
  },
});

const NavigateButton = styled(Button)(({theme}) => ({
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '24px',
  color: theme.palette.text.primary,
}));

export type MobileProductDetailHeaderProps = {
  onClickBack?: VoidFunction;
  hospitalProductDetail: HospitalProductDetail;
};

/**スクロールバーの有無を判定 */
const isScrollbarPresent = () => {
  // ビューポートの高さ
  const viewportHeight = window.innerHeight;
  // ドキュメントの全体の高さ
  const documentHeight = document.documentElement.scrollHeight;
  // スクロールバーの有無を判定
  return documentHeight > viewportHeight;
};

export const MobileProductDetailHeader = (props: MobileProductDetailHeaderProps) => {
  const {onClickBack, hospitalProductDetail} = props;
  const [isShrink, setIsShrink] = useState(false);
  const showHeaderFileUploadButton = useAtomValue(showHeaderFileUploadButtonAtom);
  const windowSize = useWindowSize();
  const {user} = useAuth0();
  const {myInfo} = useMyInfo();

  const {hospitalProductLogQuery} = useMobileMonthlyProductHistory({
    productHashId: hospitalProductDetail.hashId,
    hospitalProduct: hospitalProductDetail,
    userHashIds: [],
    selectedOptions: [],
  });

  const handleAddCommentClick = useCallback(
    async (e: React.MouseEvent) => {
      e.stopPropagation();
      let comment = '';
      try {
        const res = await dialogHandler.open(MobileCommentDialog, {
          userIndex: user,
        });
        comment = res;
      } catch (_e) {
        return;
      }
      try {
        await createHospitalProductLog(myInfo.hospitalHashId, hospitalProductDetail.hashId, {
          description: String(comment),
          logType: 'comment',
        });
        hospitalProductLogQuery.refetch();
        openSnackBar('コメントを追加しました');
      } catch (_e) {
        return;
      }
    },
    [hospitalProductDetail.hashId, hospitalProductLogQuery, myInfo.hospitalHashId, user]
  );

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 1) {
        // 100ピクセル以上スクロールした場合
        setIsShrink(true);
      } else if (isScrollbarPresent()) {
        // TODO:Headerのサイズ変更後、スクロールバーがない状態になるとWindow .scrollYが0になり無限ループになるため暫定処置
        setIsShrink(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isShrink]);

  const navigate = useNavigate();

  const navigateToProductList = useCallback(() => {
    navigate('/sp/products');
    onClickBack && onClickBack();
  }, [navigate, onClickBack]);

  return (
    <StickyContainer width={windowSize.width}>
      <Container container justifyContent="space-between" alignItems="center">
        <Grid item>
          <NavigateButton startIcon={<ChevronLeft />} onClick={navigateToProductList}>
            機器一覧
          </NavigateButton>
        </Grid>
        <Grid item>
          <ButtonContainer>
            {showHeaderFileUploadButton && <HeaderFileUploadButton hospitalHashId={myInfo.hospitalHashId} />}
            <IconButton size="small" style={{marginRight: 4}} onClick={handleAddCommentClick}>
              <ChatBubbleOutline />
            </IconButton>
            {/* TODO アクション未実装のため、一旦閉じている。 */}
            {/* <IconButton size="small" onClick={onClickMenu}>
            <Menu onClick={onClickMenu} />
          </IconButton> */}
          </ButtonContainer>
        </Grid>
      </Container>
      <MobileProductInfo
        managementId={hospitalProductDetail.managementId}
        name={hospitalProductDetail.wholeProduct?.name ?? ''}
        displayName={hospitalProductDetail.wholeProduct?.displayName ?? ''}
        makerName={hospitalProductDetail.wholeProduct?.maker.name ?? ''}
        status={hospitalProductDetail.status}
        thumbnailUrl={hospitalProductDetail.thumbnailUrl}
        isShrink={isShrink}
      />
    </StickyContainer>
  );
};
