import {VerticalPairTable} from '@Apps/BaseSharedMenu/VerticalPairTable';
import {useAsyncEffect} from '@front-libs/core';
import {StrUtil} from '@front-libs/helpers';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {bulkUpdateRentals, DeviceLender, FetchRentalsParams, getRentals} from '@modules/rentals/api';
import {RentalHistoryIndex} from '@modules/rentals/types';
import {Box, styled, SxProps, Theme} from '@mui/material';
import {contentFixedStyle} from '@templates/RentalTemplate';
import {useAtomValue, useSetAtom} from 'jotai';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Footer} from '../../Footer';
import {Header} from '../../Header';
import {NoRowsContent} from '../../NoRowsContent';
import {RentalErrorDialog} from '../../RentalErrorDialog';
import {RentButton} from '../../RentDevices/RentButton';
import {
  canUseDeviceCameraAtom,
  defaultBarcodeScannerTypeAtom,
  rentalSettingAtom,
  rentStepperAtom,
  returnLenderNameAtom,
} from '../../states';
import {RentalTable} from '../RentalTable';

/**
 * 返却のバーコードリーダー画面
 * @todo 貸出のバーコードリーダー画面とほぼ一緒なので共通化させたい
 */
export const ReturnByBarcodeReader = () => {
  const {myInfo} = useMyInfo();
  const [returnHospitalProducts, setReturnHospitalProducts] = useState<RentalHistoryIndex[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [managementId, setManagementId] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();
  const returnLenderName = useAtomValue(returnLenderNameAtom);
  const setRentalStepper = useSetAtom(rentStepperAtom);
  const hospitalRentalSetting = useAtomValue(rentalSettingAtom);
  const canUseDeviceCamera = useAtomValue(canUseDeviceCameraAtom);
  const setDefaultBarcodeScannerType = useSetAtom(defaultBarcodeScannerTypeAtom);

  const deviceLender: DeviceLender | undefined = useMemo(() => {
    if (hospitalRentalSetting === 'not_need') return undefined;

    return {
      userId: hospitalRentalSetting === 'by_barcode' ? returnLenderName ?? undefined : undefined,
      userName: hospitalRentalSetting === 'by_direct_input' ? returnLenderName ?? undefined : undefined,
    };
  }, [hospitalRentalSetting, returnLenderName]);

  const handleClickCancel = useCallback(
    (rowIndex: number) => {
      setReturnHospitalProducts(returnHospitalProducts.filter((_item, idx) => idx !== rowIndex));
    },
    [returnHospitalProducts]
  );

  const handleClickSwitchDeviceCamera = useCallback(() => {
    if (canUseDeviceCamera) {
      setDefaultBarcodeScannerType('camera');
      navigate('/shared/return/product/camera');
    }
  }, [canUseDeviceCamera, navigate, setDefaultBarcodeScannerType]);

  const handleBarcodeReaderInput = useCallback(
    async (event: React.KeyboardEvent<HTMLInputElement>) => {
      // NOTE:onKeyPressが非推奨になったのでKeyDownイベントに変更したところ特殊キー(Esc/ArrowDown)等も受信するようになったのでフィルタする

      // 文字入力を検知した場合、managementIdに連結する
      if (event.key.length === 1) {
        setManagementId(managementId + event.key);
        return;
      }

      // KeyBindの制御：Enter, BackSpace以外のキーを無視する
      // BackSpaceを検知したらmanagementIdから最後の文字を削除する
      // Enterキーであっても 管理番号が空の場合は早期リターン
      if (event.key === 'Backspace') {
        setManagementId(managementId.slice(0, -1));
        return;
      } else if (event.key !== 'Enter') {
        return;
      } else if (managementId.length === 0) {
        return;
      }

      // Enterを検知したら、managementIdをもとにレンタル情報を検索する
      let parameterManagementId = StrUtil.toHankaku(managementId);
      if (parameterManagementId[0] === '!') {
        parameterManagementId = parameterManagementId.slice(0, -1);
        const index = parameterManagementId.indexOf('('); // `(` のインデックスを取得
        if (index !== -1) {
          // `(` が見つかった場合
          parameterManagementId = parameterManagementId.slice(index); // `(` の次の文字から末尾までを取得
        }
      }

      setManagementId('');

      const params: FetchRentalsParams = {
        status: 'renting',
        managementId: parameterManagementId,
      };
      const {data, totalCount} = await getRentals(myInfo.hospitalHashId, params);
      if (totalCount > 0) {
        setReturnHospitalProducts([
          ...returnHospitalProducts,
          ...data.filter((item) => !returnHospitalProducts.some((d) => d.hashId === item.hashId)),
        ]);
      } else {
        setOpenDialog(true);
      }
    },
    [managementId, myInfo.hospitalHashId, returnHospitalProducts]
  );

  const handleSubmit = async () => {
    if (!isSubmitting) setIsSubmitting(true);
  };

  useAsyncEffect(async () => {
    if (!isSubmitting) return;
    await bulkUpdateRentals(
      myInfo.hospitalHashId,
      returnHospitalProducts.map((item) => ({
        rentalHashId: item.hashId,
        returnUserHashId: myInfo.hashId,
        returnDeviceLender: deviceLender,
      }))
    );
    navigate('/shared/complete');
    setRentalStepper(2);
  }, [isSubmitting]);

  useEffect(() => {
    setRentalStepper(1);
  }, [setRentalStepper]);

  const focusInput = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      if (!openDialog) e.target.focus();
    },
    [openDialog]
  );

  const ReturnTableView = () => {
    return (
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box sx={tableContainerStyle}>
          <RentalTable returnHospitalProducts={returnHospitalProducts} onClickCancel={handleClickCancel} />
        </Box>
        <Box sx={operationContainerStyle}>
          {returnLenderName && (
            <Box sx={operatorTableStyle}>
              <VerticalPairTable label="返却者" name={returnLenderName} />
            </Box>
          )}
          <Box sx={{width: '100%'}}>
            <RentButton label={'返却する'} onChange={handleSubmit} />
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Header title={'返却'} />
      <Box sx={contentFixedStyle}>
        <StyledBarcodeInput
          type="text"
          autoFocus
          readOnly
          onBlur={focusInput}
          value={managementId}
          onKeyDown={handleBarcodeReaderInput}
        />
        <Box sx={rootStyle}>{returnHospitalProducts.length == 0 ? <NoRowsContent /> : <ReturnTableView />}</Box>
      </Box>
      <Footer
        text={'返却する医療機器の\nバーコードを読み取って下さい'}
        nextButtonLabel={canUseDeviceCamera ? 'カメラで読み取る' : undefined}
        onClickNextButton={handleClickSwitchDeviceCamera}
      />
      <RentalErrorDialog open={openDialog} onClickButton={() => setOpenDialog(false)} status={null} type="return" />
    </>
  );
};

const rootStyle: SxProps = {margin: '0 60px', width: '100%'};

const tableContainerStyle: SxProps = {
  maxHeight: '100%',
  overflow: 'auto',
  height: 'fit-content',
  width: '70%',
};

const StyledBarcodeInput = styled('input')({
  position: 'absolute',
  top: 20,
  marginTop: '-550px',
  '@media screen and (orientation: portrait)': {
    marginTop: '0px',
    marginLeft: '-5500px',
  },
});

const operationContainerStyle: SxProps<Theme> = (theme) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  width: '25%',
  height: '398px',
  overflow: 'auto',
  gap: '20px',
  [theme.breakpoints.up('laptop')]: {
    paddingBottom: '16px',
    paddingTop: '16px',
  },
});

const operatorTableStyle: SxProps<Theme> = (theme) => ({
  width: '100%',
  marginTop: '48px',
  [theme.breakpoints.up('laptop')]: {
    marginTop: '28px',
  },
});
