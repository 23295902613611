import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {IconButton, StyledEngineProvider} from '@mui/material';
import {OptionsObject, SnackbarKey, SnackbarProvider, VariantType, useSnackbar} from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom';

// render snackbar DOM into div element in index.html
const mountPoint = document.getElementById('snackbar');

const defaultEnqueueOptions = {
  autoHideDuration: 5000,
};

/**
 * スナックバーを表示する。
 *
 * @param msg - スナックバーに表示するメッセージ。
 * @param variant - スナックバーのタイプ。通常は 'success'、'error'、'warning' など。デフォルトは 'success'。
 * @param option - スナックバーの設定オプション。
 * @param option.horizontal - スナックバーの水平位置。デフォルトは 'left'。
 * @param option.vertical - スナックバーの垂直位置。デフォルトは 'bottom'。
 * @param option.options - スナックバーの追加オプション。OptionsObject を参照。
 * @param option.onRendered - スナックバーがレンダリングされた際に呼び出されるコールバック関数。
 *
 * @example
 * ```typescript
 * // オプションを使用しない最低限の使い方
 * openSnackBar('データが正常に保存されました');
 *
 * // VariantTypeのみ使用する例
 * openSnackBar('データの保存に失敗しました', 'error');
 *
 * // オプションを使用する例
 * openSnackBar('データが正常に保存されました', 'success', {
 *   horizontal: 'right',
 *   vertical: 'top',
 *   options: { autoHideDuration: 3000 },
 *   onRendered: () => {
 *     console.log('スナックバーが正常にレンダリングされました。');
 *   }
 * });
 * ```
 */
export const openSnackBar = (
  msg: string,
  variant: VariantType = 'success',
  option?: {
    horizontal?: 'left' | 'center' | 'right';
    vertical?: 'top' | 'bottom';
    options?: OptionsObject;
    onRendered?: () => void;
  }
) => {
  const {horizontal = 'left', vertical = 'bottom', options = {}, onRendered = () => {}} = option || {};

  const ShowSnackbar = ({message, ...opts}: {message: string; opts?: OptionsObject}) => {
    const {enqueueSnackbar} = useSnackbar();
    React.useEffect(() => {
      enqueueSnackbar(message, {variant, ...defaultEnqueueOptions, ...opts});
    }, [message, opts, enqueueSnackbar]);
    return null;
  };

  ReactDOM.render(
    <StyledEngineProvider injectFirst>
      <SnackbarProvider
        action={(key) => <SnackbarCloseButton id={key} />}
        iconVariant={{
          success: <CheckCircleOutlineIcon sx={{marginRight: '8px'}} />,
          error: <ErrorOutlineIcon sx={{marginRight: '8px'}} />,
        }}
        maxSnack={3}
        anchorOrigin={{horizontal, vertical}}>
        <ShowSnackbar message={msg} {...options} />
      </SnackbarProvider>
    </StyledEngineProvider>,
    mountPoint,
    () => {
      onRendered && onRendered();
    }
  );
};

const SnackbarCloseButton = ({id}: {id: SnackbarKey}) => {
  const {closeSnackbar} = useSnackbar();

  return (
    <IconButton
      onClick={() => {
        closeSnackbar(id);
      }}>
      <CloseIcon style={{color: 'white'}} />
    </IconButton>
  );
};
