import React from 'react';
import {
  EditInspectionCell,
  InspectionPeriodCell,
  InspectionTypeCell,
  EditSettingNameCell,
  EditActionButtonCell,
  ViewSettingNameCell,
  ViewInspectionCell,
  ViewActionButtonCell,
  WaitActionButtonCell,
  EditInspectionPeriodCell,
  EditInspectionTypeCell,
} from './EditWholeProductCell';
import {EditWholeProductPlan} from '../utils/reducer';

type ViewModeRowProps = {
  onEdit: () => void;
  onDelete: () => void;
  inspectionSetting: EditWholeProductPlan;
  canDelete: boolean;
};

export const ViewModeRow = ({onEdit, onDelete, inspectionSetting, canDelete}: ViewModeRowProps) => {
  return (
    <>
      <ViewSettingNameCell settingName={inspectionSetting.inspectionSettingName} />
      <InspectionTypeCell inspectionTypeName={inspectionSetting.inspectionType} />
      <InspectionPeriodCell
        isPeriodic={inspectionSetting.inspectionType === 'periodic'}
        inspectionPeriod={inspectionSetting.inspectionPeriod}
      />
      <ViewInspectionCell inspectionName={inspectionSetting.inspectionName} />
      <ViewActionButtonCell onEdit={onEdit} onDelete={onDelete} canDelete={canDelete} />
    </>
  );
};

type EditModeRowProps = {
  onSave: () => void;
  onCancel: () => void;
  onChangeInspection: (inspectionHashId: string, inspectionName: string) => void;
  onChangeInspectionSettingName: (inspectionSettingName: string) => void;
  inspectionSetting: EditWholeProductPlan;
  canSave: boolean;
};

export const EditModeRow = ({
  onSave,
  onCancel,
  inspectionSetting,
  onChangeInspection,
  onChangeInspectionSettingName,
  canSave,
}: EditModeRowProps) => {
  return (
    <>
      <EditSettingNameCell
        settingName={inspectionSetting.inspectionSettingName}
        onInspectionSettingNameChange={onChangeInspectionSettingName}
      />
      <InspectionTypeCell inspectionTypeName={inspectionSetting.inspectionType} />
      <InspectionPeriodCell
        isPeriodic={inspectionSetting.inspectionType === 'periodic'}
        inspectionPeriod={inspectionSetting.inspectionPeriod}
      />
      <EditInspectionCell
        inspectionName={inspectionSetting.inspectionName}
        inspectionType={inspectionSetting.inspectionType}
        onChangeInspection={onChangeInspection}
      />
      <EditActionButtonCell onSave={onSave} onCancel={onCancel} canSave={canSave} />
    </>
  );
};

type WaitModeRowProps = {
  inspectionSetting: EditWholeProductPlan;
  canDelete: boolean;
};

export const WaitModeRow = ({inspectionSetting, canDelete}: WaitModeRowProps) => {
  return (
    <>
      <ViewSettingNameCell settingName={inspectionSetting.inspectionSettingName} />
      <InspectionTypeCell inspectionTypeName={inspectionSetting.inspectionType} />
      <InspectionPeriodCell
        isPeriodic={inspectionSetting.inspectionType === 'periodic'}
        inspectionPeriod={inspectionSetting.inspectionPeriod}
      />
      <ViewInspectionCell inspectionName={inspectionSetting.inspectionName} />
      <WaitActionButtonCell canDelete={canDelete} />
    </>
  );
};

type AddModeRowProps = {
  onSave: () => void;
  onCancel: () => void;
  onChangeInspection: (inspectionHashId: string, inspectionName: string) => void;
  onChangeInspectionSettingName: (inspectionSettingName: string) => void;
  onChangeInspectionPeriod: (inspectionPeriod: number) => void;
  onChangeInspectionType: (inspectionType: string) => void;
  inspectionSetting: EditWholeProductPlan;
  canSave: boolean;
  displayInspectionTypeOptions: {value: string; label: string}[];
};

// FIXME修正
export const AddModeRow = ({
  onSave,
  onCancel,
  inspectionSetting,
  onChangeInspection,
  onChangeInspectionSettingName,
  onChangeInspectionPeriod,
  onChangeInspectionType,
  canSave,
  displayInspectionTypeOptions,
}: AddModeRowProps) => {
  return (
    <>
      <EditSettingNameCell
        settingName={inspectionSetting.inspectionSettingName}
        onInspectionSettingNameChange={onChangeInspectionSettingName}
      />
      <EditInspectionTypeCell
        inspectionSetting={inspectionSetting}
        onInspectionTypeChange={onChangeInspectionType}
        displayInspectionTypeOptions={displayInspectionTypeOptions}
      />
      <EditInspectionPeriodCell
        isPeriodic={inspectionSetting.inspectionType === 'periodic'}
        inspectionPeriod={inspectionSetting.inspectionPeriod}
        onInspectionPeriodChange={onChangeInspectionPeriod}
      />
      <EditInspectionCell
        inspectionName={inspectionSetting.inspectionName}
        inspectionType={inspectionSetting.inspectionType}
        onChangeInspection={onChangeInspection}
      />
      <EditActionButtonCell onSave={onSave} onCancel={onCancel} canSave={canSave} />
    </>
  );
};
