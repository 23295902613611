import {useAtomValue, useSetAtom} from 'jotai';
import {useCallback, useMemo} from 'react';
import {rentOrReturnAtom, rentWardAtom, defaultBarcodeScannerTypeAtom, rentOrReturnSettingAtom} from '../states';
import {useNavigate} from 'react-router-dom';
import {useFetchHospitalSettingsQuery} from '@modules/hospital_settings/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {rentalSettings} from '@modules/hospital_settings/types';
import {isNullish} from '@front-libs/helpers';
import {useFetchHospitalRooms, useFetchHospitalWards} from '@modules/hospital_places/api';
import {useAtom} from 'jotai';

const nextLinks = {
  lender: '/shared/lenderSelection',
  returnByBarcodeReader: '/shared/return/product/barcode_reader',
  returnByCamera: '/shared/return/product/camera',
  ward: '/shared/rental/wardSelection',
  room: '/shared/rental/roomSelection',
};

export const useNavigateRentOrReturn = () => {
  const setRentOrReturn = useSetAtom(rentOrReturnAtom);
  const setRentalWard = useSetAtom(rentWardAtom);
  const defaultBarcodeScannerType = useAtomValue(defaultBarcodeScannerTypeAtom);

  const {myInfo} = useMyInfo();
  const {data: hospitalSettings} = useFetchHospitalSettingsQuery(myInfo.hospitalHashId);
  const {data: wardsData} = useFetchHospitalWards(myInfo.hospitalHashId, {
    page: 0,
    perPage: 100,
    onlyRentableRooms: true,
  });

  const filteredWardsData = wardsData.filter((ward) => ward.numberOfRooms > 0);

  const {data: roomData} = useFetchHospitalRooms(myInfo.hospitalHashId, {
    page: 0,
    perPage: 100,
    hospitalWardHashId: filteredWardsData[0]?.hashId,
    showRentalPlace: true,
  });

  const filteredRooms = useMemo(() => {
    const roomMap = new Map();

    roomData.forEach((room) => {
      const key = `${room.floorNumber}:${room.isGroundFloor}`;
      if (!roomMap.has(key)) {
        roomMap.set(key, room);
      }
    });

    return [...roomMap.values()];
  }, [roomData]);

  const navigate = useNavigate();

  const hospitalRentalSetting = useMemo(
    () => hospitalSettings?.data.find((setting) => setting.key === rentalSettings.use_device_lender.key),
    [hospitalSettings?.data]
  );

  const proceedRent = useCallback(() => {
    setRentOrReturn('rent');
    if (isNullish(hospitalRentalSetting) || hospitalRentalSetting?.value === 'not_need') {
      if (filteredWardsData.length > 1 || filteredRooms.length > 1) {
        navigate(nextLinks.ward);
      } else {
        setRentalWard(filteredWardsData[0]);
        navigate(nextLinks.room);
      }
    } else {
      navigate(nextLinks.lender);
    }
  }, [setRentOrReturn, hospitalRentalSetting, filteredWardsData, filteredRooms.length, navigate, setRentalWard]);

  const proceedReturn = useCallback(() => {
    setRentOrReturn('return');
    if (isNullish(hospitalRentalSetting) || hospitalRentalSetting?.value === 'not_need') {
      defaultBarcodeScannerType === 'barcode_reader'
        ? navigate(nextLinks.returnByBarcodeReader)
        : navigate(nextLinks.returnByCamera);
    } else {
      navigate(nextLinks.lender);
    }
  }, [defaultBarcodeScannerType, hospitalRentalSetting, navigate, setRentOrReturn]);
  return {proceedRent, proceedReturn};
};

export const useRentOrReturnDeviceSetting = () => {
  const [rentOrReturnSetting, setRentOrReturnSetting] = useAtom(rentOrReturnSettingAtom);
  return {rentOrReturnSetting, setRentOrReturnSetting};
};
