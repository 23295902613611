import {useFetchCompaniesQuery} from '@modules/companies/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  SxProps,
  TextField,
  Theme,
  createFilterOptions,
} from '@mui/material';
import React, {useEffect, useMemo, useState} from 'react';
import {Option} from '@molecules/Selector';
type AutoCompleteOption = {
  isFreeSolo: boolean;
} & Option<string>;

const filter = createFilterOptions<AutoCompleteOption>();

const inputTextSx: SxProps<Theme> = {
  fontSize: (theme) => theme.typography.fontSize,
};

const underlineSx: SxProps<Theme> = {
  '&&&:before': {
    borderBottom: '0px solid',
  },
  '&&:after': {
    borderBottom: (theme) => `1px solid ${theme.palette.primary.dark}`,
  },
};

const optionSx: SxProps<Theme> = {
  fontSize: (theme) => theme.typography.fontSize,
};

type Value = {
  hashId?: string;
  name?: string;
  isNew: boolean;
};

type Props = {
  onChangeMaker: (requestName: string, value: string) => void;
};

export const MakerSelect = ({onChangeMaker}: Props) => {
  const {myInfo} = useMyInfo();
  const [companyName, setCompanyName] = useState<string>();
  const [values, setValues] = useState<Value>();
  const {data, isLoading} = useFetchCompaniesQuery(myInfo.hospitalHashId, {
    page: 0,
    perPage: 100,
    name: companyName,
  });

  useEffect(() => {
    if (values?.isNew && values.name) {
      // 新しいメーカー名
      onChangeMaker('makerName', values.name);
    } else if (values?.hashId) {
      // 既存のメーカーのハッシュID
      onChangeMaker('makerHashId', values.hashId);
    }
  }, [values]);

  const companies = useMemo(
    () => data?.data.filter((item) => data?.data.some((i) => i.name === item.name)) ?? [],
    [data?.data]
  );

  const filedValue = useMemo(
    () =>
      values?.isNew
        ? {
            label: values.name ?? '',
            value: values.name ?? '',
            isFreeSolo: true,
          }
        : {label: values?.name, value: values?.hashId, isFreeSolo: false},
    [values?.hashId, values?.isNew, values?.name]
  );

  const options = useMemo(
    () => companies.map((company) => ({label: company.name, value: company.hashId, isFreeSolo: false})) ?? [],
    [companies]
  );

  return (
    <>
      <div>メーカー名</div>
      <Autocomplete
        freeSolo
        disableClearable
        fullWidth
        options={options}
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.label ?? '')}
        loading={isLoading}
        value={filedValue}
        sx={optionSx}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            {...params}
            variant="outlined"
            InputLabelProps={{
              ...params.InputLabelProps,
            }}
            InputProps={{
              ...params.InputProps,
              sx: [underlineSx, inputTextSx],
            }}
          />
        )}
        filterOptions={(opts, params) => {
          const filtered = filter(opts as AutoCompleteOption[], params);
          const isExisting = options.some((option) => params.inputValue === option.label);
          if (params.inputValue !== '' && !isExisting) {
            filtered.push({
              value: params.inputValue,
              label: `"${params.inputValue}"を追加`,
              isFreeSolo: true,
            });
          }

          return filtered;
        }}
        onInputChange={(_e, value) => {
          if (value === '' || values?.name === value) {
            setCompanyName(undefined);
          } else {
            setCompanyName(value);
          }
        }}
        onChange={(e, value) => {
          if (typeof value === 'string') {
            setValues({name: value, isNew: true});
          } else if (value.isFreeSolo) {
            setValues({name: value.value, isNew: true});
          } else {
            const maker = companies.find((item) => item.hashId === value.value);
            setValues({hashId: maker?.hashId, name: maker?.name, isNew: false});
          }
        }}
      />
    </>
  );
};
