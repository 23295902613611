import React from 'react';
import {Grid, Paper, styled} from '@material-ui/core';
import {Build} from '@material-ui/icons';
import dayjs from 'dayjs';

type MobileRepairRegistrationCardProps = {
  displayDate: Date;
  repairRegistrationNumber?: string;
};

export const MobileRepairRegistrationCard = (props: MobileRepairRegistrationCardProps) => {
  const {displayDate, repairRegistrationNumber} = props;
  return (
    <StyledPaper elevation={0}>
      <Header container>
        <Grid item>
          <StyledGrid container>
            <Grid item>
              <IconContainer>
                <Build />
              </IconContainer>
            </Grid>
            <StyledTitleGrid>
              <StyledSpan>修理情報</StyledSpan>の登録
            </StyledTitleGrid>
          </StyledGrid>
        </Grid>
        <Grid item>{dayjs(displayDate).format('YYYY/M/D')}</Grid>
      </Header>
      {repairRegistrationNumber && (
        <ContentContainer container>
          <ContentRow item>
            <CardText>修理番号：{repairRegistrationNumber}</CardText>
          </ContentRow>
        </ContentContainer>
      )}
      {/* <Grid container alignItems={'center'} style={{paddingLeft: 40}}>
        <Grid item style={{width: '100%'}}>
          <CardText>登録者：{executeUser ? `${UserFormatter.getFullName(executeUser)}` : '不明なユーザー'}</CardText>
        </Grid>
      </Grid> */}
    </StyledPaper>
  );
};

const Header = styled(Grid)({
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: 8,
});
const StyledGrid = styled(Grid)({
  gap: 8,
  alignItems: 'center',
});
const StyledSpan = styled('span')({
  color: '#0052CC',
});
const StyledTitleGrid = styled(Grid)({
  fontSize: 16,
});
const StyledPaper = styled(Paper)({
  borderRadius: 0,
  borderBottom: '0.5px solid #DDE0E4',
  padding: 16,
  marginLeft: '-16px',
  marginRight: '-16px',
});
const IconContainer = styled('div')({
  width: 28,
  height: 28,
  paddingTop: 4,
  paddingLeft: 4,
  background: '#F0F2F5',
  borderRadius: '16px',
});
const ContentContainer = styled(Grid)({
  alignItems: 'center',
  paddingLeft: 40,
});
const ContentRow = styled(Grid)({
  width: '100%',
});
const CardText = styled('div')({
  fontSize: '14px',
  lineHeight: '16px',
  color: '#172B4D',
});
