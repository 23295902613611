import React from 'react';
import {Grid, Paper, styled} from '@material-ui/core';
import InputIcon from '@material-ui/icons/Input';
import dayjs from 'dayjs';

type MobileRentHistoryCardProps = {
  displayDate: Date;
  rentalRoom: string;
  deviceLenderUserName: string;
  rentalDate: string;
};

export const MobileRentHistoryCard = (props: MobileRentHistoryCardProps) => {
  const {displayDate, deviceLenderUserName, rentalDate, rentalRoom} = props;
  return (
    <Paper
      elevation={0}
      style={{
        borderRadius: 0,
        borderBottom: '0.5px solid #DDE0E4',
        padding: 16,
        marginLeft: '-16px',
        marginRight: '-16px',
      }}>
      <Grid container alignItems={'center'} justifyContent="space-between" style={{marginBottom: 8}}>
        <Grid item>
          <Grid container style={{gap: 8}} alignItems="center">
            <Grid item>
              <div
                style={{
                  width: 28,
                  height: 28,
                  paddingTop: 4,
                  paddingLeft: 4,
                  background: '#F0F2F5',
                  borderRadius: '16px',
                }}>
                <InputIcon />
              </div>
            </Grid>
            <Grid item style={{fontSize: 16}}>
              <span style={{color: '#0052CC'}}>貸出情報</span>の登録
            </Grid>
          </Grid>
        </Grid>
        <Grid item>{dayjs(displayDate).format('YYYY/M/D')}</Grid>
      </Grid>
      <Grid container alignItems={'center'} style={{paddingLeft: 40}}>
        <Grid item style={{width: '100%'}}>
          <CardText>貸出先：{rentalRoom}</CardText>
          <CardText>貸出日：{rentalDate}</CardText>
          <CardText>持出者：{deviceLenderUserName}</CardText>
        </Grid>
      </Grid>
    </Paper>
  );
};

const CardText = styled('div')({
  fontSize: '14px',
  lineHeight: '16px',

  color: '#172B4D',
});
