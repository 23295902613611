import {PeriodUnit} from './types';

export const formatPeriod = (period: number, periodUnit: PeriodUnit) => {
  switch (periodUnit) {
    case 'day':
      return `${period}日`;
    case 'month':
      return `${period}ヶ月`;
    case 'year':
      return `${period}年`;
    default:
      // should not reach here
      break;
  }
  return '';
};
