import React, {Suspense, useCallback} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
  Checkbox,
} from '@material-ui/core';
import {styled} from '@material-ui/styles';
import dayjs from 'dayjs';
import {RequiredLabel} from '../FormRequiredLabel';
import {DatePickerDialogProps} from './DatePickerDialog';
import {Formik, useFormikContext} from 'formik';
import {yup} from '@front-libs/core';
import {Close} from '@material-ui/icons';

type FormField = {
  startDate: string;
  endDate: string;
  isContainPastUnreturned: boolean;
};

const StyledDialogTitle = styled(DialogTitle)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '24px 32px 40px',
});

const StyledTypography = styled(Typography)({
  color: '#172B4D',
  fontSize: '20px',
  fontWeight: 'bold',
});

const StyledDialogContent = styled(DialogContent)({
  padding: '0px 32px',
});

const StyledGrid = styled(Grid)({
  '& > :not(:first-child)': {
    marginTop: '24px',
  },
});

const StyledCheckboxGrid = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
});

const StyledDialogActions = styled(DialogActions)({
  padding: '24px 32px 32px',
});

export const CreateRentalsDatePickerDialog = (props: DatePickerDialogProps) => {
  const {title, description, initialStartDate, initialEndDate, startDateLabel, endDateLabel} = props;
  const validationSchema = yup.object({
    startDate: yup.date().required('開始日を選択してください'),
    endDate: yup.date().required('終了日を選択してください'),
  });

  const handleSubmit = useCallback(
    async (values: FormField) => {
      props.actions.resolve(values);
    },
    [props.actions]
  );

  const handleClose = useCallback(
    (e: React.MouseEvent) => {
      props.actions.resolve(null);
    },
    [props.actions]
  );

  return (
    <Suspense fallback={null}>
      <Formik
        initialValues={{
          startDate: initialStartDate,
          endDate: initialEndDate,
          isContainPastUnreturned: false,
        }}
        onSubmit={handleSubmit}
        onClose={handleClose}
        validationSchema={validationSchema}
        validateOnMount={true}>
        <DateDialogForm
          open={true}
          title={title}
          description={description}
          actions={props.actions}
          initialStartDate={initialStartDate}
          initialEndDate={initialEndDate}
          startDateLabel={startDateLabel ?? '開始日'}
          endDateLabel={endDateLabel ?? '終了日'}
          onClose={handleClose}
        />
      </Formik>
    </Suspense>
  );
};

const DateDialogForm = ({
  open,
  title,
  description,
  initialStartDate,
  initialEndDate,
  startDateLabel,
  endDateLabel,
  onClose,
  ...props
}: DatePickerDialogProps) => {
  const {errors, values, setFieldTouched, setFieldValue, submitForm} = useFormikContext<FormField>();

  const handleChangeStartDate = useCallback(
    async (date: string) => {
      await setFieldTouched('startDate', true);
      await setFieldValue('startDate', date);
    },
    [setFieldValue, setFieldTouched]
  );

  const handleChangeEndDate = useCallback(
    async (date: string) => {
      await setFieldTouched('endDate', true);
      await setFieldValue('endDate', date);
    },
    [setFieldValue, setFieldTouched]
  );

  const handleChangeIsContainPastUnreturned = useCallback(
    (_e: React.ChangeEvent<unknown>, checked: boolean) => {
      setFieldValue('isContainPastUnreturned', checked);
    },
    [setFieldValue]
  );

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="sm">
      <StyledDialogTitle disableTypography>
        <StyledTypography>{title}</StyledTypography>
        <IconButton aria-label="close" onClick={onClose}>
          <Close />
        </IconButton>
      </StyledDialogTitle>
      <StyledDialogContent>
        <Typography>{description}</Typography>
        <br />
        <StyledGrid container direction="column">
          <Grid item>
            <RequiredLabel>{startDateLabel}</RequiredLabel>
            <TextField
              type="date"
              name="startDate"
              value={values.startDate}
              size="small"
              variant="outlined"
              fullWidth
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeStartDate(dayjs(e.target.value).format('YYYY-MM-DD'))
              }
            />
          </Grid>
          <Grid item>
            <RequiredLabel>{endDateLabel}</RequiredLabel>
            <TextField
              type="date"
              name="endDate"
              value={values.endDate}
              size="small"
              variant="outlined"
              fullWidth
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeEndDate(dayjs(e.target.value).format('YYYY-MM-DD'))
              }
            />
          </Grid>
          <StyledCheckboxGrid>
            <Checkbox
              style={{marginLeft: '-11px', padding: '12px', borderRadius: '50%'}}
              id="unreturned-checkbox"
              color="primary"
              disableRipple={false}
              value={values.isContainPastUnreturned}
              onChange={handleChangeIsContainPastUnreturned}
            />
            <label htmlFor="unreturned-checkbox">開始日より前に貸出された未返却データを含む</label>
          </StyledCheckboxGrid>
        </StyledGrid>
      </StyledDialogContent>
      <StyledDialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={submitForm}
          disabled={!!errors?.startDate || !!errors?.endDate}>
          エクスポート
        </Button>
        <Button variant="text" onClick={onClose}>
          キャンセル
        </Button>
      </StyledDialogActions>
    </Dialog>
  );
};
