import React, {useEffect} from 'react';
import {Provider} from 'jotai';
import {Paper, Table as MTable} from '@material-ui/core';
import {TableProps} from './props';
import {TableHead} from './TableHead';
import {TableBody} from './TableBody';
import {initColumnsAtom, initDataAtom, useInitialized} from './atoms';
import {useSetAtom} from 'jotai';
import {isNullish} from '@front-libs/helpers';

const MIN_PAGE = 1;
const MIN_PAGE_SIZE = 1;
const DEFAULT_PAGE_SIZE = 20;

/**
 * `TableInner` コンポーネントは、テーブルの主要なレンダリングロジックを担います。
 * これには、テーブルヘッドとテーブルボディのレンダリング、
 * 並びにページネーションとソートの状態管理が含まれます。
 * @template T テーブルの行データの型を表します
 * @param props {@link TableProps<T>} 型のプロパティ
 *              テーブルの設定、データ、およびイベントハンドラを含みます
 * @returns 実際のテーブルコンポーネント
 */
// eslint-disable-next-line @typescript-eslint/ban-types
const TableInner = <T extends {}>(props: TableProps<T>) => {
  const {
    rowActions = [],
    isLoading = false,
    showSelection = true,
    showAllSelection = false,
    enablesSort = true,
    enablesDragColumn = false,
    stickyHeader = false,
    tableSize = 'medium',

    defaultOrder,

    selectionButtons = [],
    noDataComponent,
    rowActionIndex,

    onRowClick,
    onSelectionChange,
    onOrderChange,
    onColumnsOrderChange,
  } = props;

  const initColumns = useSetAtom(initColumnsAtom);
  const initData = useSetAtom(initDataAtom);
  const initialized = useInitialized();

  useEffect(() => {
    // col.hidden is undefined and hidden function is disabled as default
    initColumns((props.columns ?? []).filter((col) => col.hidden !== true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.columns]);

  useEffect(() => {
    initData(props.data ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  const page = Math.max(MIN_PAGE, props.page ?? 0);
  const pageSize = Math.max(MIN_PAGE_SIZE, props.pageSize ?? DEFAULT_PAGE_SIZE);

  if (!initialized) {
    return null;
  }

  return (
    <MTable stickyHeader={stickyHeader} size={tableSize}>
      <TableHead
        showSelection={showSelection}
        selectionActions={selectionButtons}
        enablesSort={enablesSort}
        enablesDragColumn={enablesDragColumn}
        rowSize={tableSize}
        defaultOrder={defaultOrder}
        onSelectionChange={onSelectionChange}
        onOrderChange={onOrderChange}
        onColumnsOrderChange={onColumnsOrderChange}
        showsAllSelection={showAllSelection}
      />
      <TableBody
        page={page}
        pageSize={pageSize}
        rowActions={rowActions}
        rowSize={tableSize}
        isLoading={isLoading}
        isCustomOrdered={!isNullish(onOrderChange)}
        showSelection={showSelection}
        enablesSort={enablesSort}
        noDataComponent={noDataComponent}
        onRowClick={onRowClick}
        onSelectionChange={onSelectionChange}
        rowActionIndex={rowActionIndex}
      />
    </MTable>
  );
};

/**
 * `RawTable` コンポーネントは、Jotaiの `Provider` を使用して状態管理を行い、
 * `TableInner` コンポーネントをラップします
 * @template T テーブルの行データの型を表します
 * @param props {@link TableProps<T>} 型のプロパティ
 *              テーブルの設定、データ、およびイベントハンドラを含みます
 * @returns `Provider` 内にレンダリングされるテーブルコンポーネント
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const RawTable = <T extends {}>(props: TableProps<T>) => {
  return (
    <Provider>
      <TableInner {...props} />
    </Provider>
  );
};

/**
 * `Table` コンポーネントは、Material-UIの `Paper` および `Table` 要素を使用して、
 * 柔軟なテーブル表示機能を提供します。このコンポーネントは、カスタム列、
 * 行のアクション、ソート機能、ページネーション、および選択機能をサポートします。
 * @template T テーブルの行データの型を表します
 * @param props {@link TableProps<T>} 型のプロパティ
 *              テーブルの設定、データ、およびイベントハンドラを含みます
 * ```tsx
 * export type TableProps<T> = {
 *   // テーブルに表示されるデータの配列
 *   data: T[];
 *   // テーブルの列を定義するオブジェクトの配列
 *   columns: Column<T>[];
 *   // 各行に対するアクションを定義するオプションの配列
 *   rowActions?: RowAction<T>[];
 *   // 現在のページ番号（ページネーション用）
 *   page?: number;
 *   // 1ページあたりの行数（ページネーション用）
 *   pageSize?: number;
 *   // デフォルトの並び替え順序
 *   defaultOrder?: Order;
 *   // 選択時に表示するアクションの配列
 *   selectionButtons?: SelectionAction<T>[];
 *   // テーブルを包含するPaperコンポーネントのプロパティ
 *   paperProps?: PaperProps;
 *   // テーブルのサイズ（小または中）
 *   tableSize?: 'small' | 'medium';
 *   // ローディング中かどうかを示すフラグ
 *   isLoading?: boolean;
 *   // 行の選択機能を表示するかどうかのフラグ
 *   showSelection?: boolean;
 *   // 全選択機能を表示するかどうかのフラグ
 *   showAllSelection?: boolean;
 *   // 並び替え機能を有効にするかどうかのフラグ
 *   enablesSort?: boolean;
 *   // 列のドラッグによる並び替えを有効にするかどうかのフラグ
 *   enablesDragColumn?: boolean;
 *   // ヘッダーを固定するかどうかのフラグ
 *   stickyHeader?: boolean;
 *   // 行がクリックされたときのイベントハンドラ
 *   onRowClick?: (e: React.MouseEvent, rawData: T) => void | Promise<void>;
 *   // 選択状態が変更されたときのイベントハンドラ
 *   onSelectionChange?: (selected: T[], item: T | null, checked: boolean) => CallbackReturnValue;
 *   // 並び替えの順序が変更されたときのイベントハンドラ
 *   onOrderChange?: (columnIndex: number, orderDirection: 'asc' | 'desc') => CallbackReturnValue;
 *   // 列の順序が変更されたときのイベントハンドラ
 *   onColumnsOrderChange?: (newColumns: Column<T>[]) => void | Promise<void>;
 *   // データがない(0件)の際に表示するエレメント
 *   noDataComponent?: React.ReactNode
 * };
 * ```
 * @returns Material-UIの `Paper` 要素内にレンダリングされるテーブルコンポーネント
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const Table = <T extends {}>(props: TableProps<T>) => {
  return (
    <Paper {...props.paperProps} style={{width: '100%', overflowX: 'auto', ...props.paperProps?.style}}>
      <RawTable {...props} />
    </Paper>
  );
};
