import {TabValues} from '@front-libs/helpers';

export const tabs = [
  {
    label: '点検項目の設定',
    value: 'items',
  },
  {
    label: '点検項目のプレビュー',
    value: 'preview',
  },
  {
    label: '点検マニュアルの登録',
    value: 'upload_manual',
  },
] as const;

export type TabKey = TabValues<(typeof tabs)[number]>;

export const inspectionTypes = [
  {
    label: '定期点検',
    value: 'periodic',
  },
  {
    label: '使用後点検',
    value: 'post_use',
  },
] as const;

export type InspectionTypeKey = TabValues<(typeof inspectionTypes)[number]>;

export type FormValue = {
  name: string;
  type: InspectionTypeKey;
  sections: Section[];
};

export type Section = {
  id?: string;
  name: string;
  fields: Field[];
};

export type BaseFieldSettings = {
  // 点検箇所を表示するか
  showsInspectionPoint: boolean;
  // 説明を表示するか
  showsDescription: boolean;
};

export type BaseField = {
  id?: string;
  name: string;
  required: boolean;
  inspectionPoint: string | null;
  description: string | null;
};

export type EmptyField = BaseField & {
  type: '';
  settings: BaseFieldSettings;
};

export type SelectorOption = {
  value: string;
  label: string;
};

export type CheckboxSettings = BaseFieldSettings & {
  showsValidator: boolean;
};

export type SelectorValidator = {
  values: string[];
};

export type CheckboxValidator = SelectorValidator;

export type CheckboxField = BaseField & {
  type: 'checkbox';
  options: SelectorOption[];
  settings: CheckboxSettings;
  validators: CheckboxValidator;
};

export type PullDownFieldSettings = BaseFieldSettings & {
  showsValidator: boolean;
};

export type PullDownFieldValidator = SelectorValidator;

export type PullDownField = BaseField & {
  type: 'pull-down';
  options: SelectorOption[];
  settings: PullDownFieldSettings;
  validators: PullDownFieldValidator;
};

export type RadioFieldSettings = BaseFieldSettings & {
  showsValidator: boolean;
};

export type RadioFieldValidator = SelectorValidator;

export type RadioField = BaseField & {
  type: 'radio';
  options: SelectorOption[];
  settings: RadioFieldSettings;
  validators: RadioFieldValidator;
};

export type NumericValidator =
  | {
      type: null;
    }
  | {
      type: 'gt' | 'gte' | 'lt' | 'lte' | 'eq' | 'neq';
      value: number;
      errorText?: string;
    }
  | {
      type: 'between' | 'not-between';
      lower: number;
      upper: number;
      errorText?: string;
    };

export type NumericFieldSettings = BaseFieldSettings & {
  // 検証条件フォームを表示するか
  showsValidator: boolean;
};

export type NumericField = BaseField & {
  type: 'numeric';
  unit: string;
  validators: NumericValidator[];
  settings: NumericFieldSettings;
};

export type TextField = BaseField & {
  type: 'text';
  settings: BaseFieldSettings;
};

export type MultilineTextField = BaseField & {
  type: 'multiline-text';
  settings: BaseFieldSettings;
};

export type DateField = BaseField & {
  type: 'date';
  settings: BaseFieldSettings;
};

export type TimeField = BaseField & {
  type: 'time';
  settings: BaseFieldSettings;
};

export type NullField = BaseField & {
  type: '';
  settings: BaseFieldSettings;
};

export type Field =
  | EmptyField
  | CheckboxField
  | PullDownField
  | RadioField
  | NumericField
  | TextField
  | MultilineTextField
  | DateField
  | TimeField
  | NullField;

export type FocusedItem = {
  el: Element | null;
  sectionIndex: number;
  fieldIndex: number | null;
};

export const EMPTY_INSPECTION_NAME_ERROR = 'EMPTY_INSPECTION_NAME_ERROR' as const;
export const INVALID_INSPECTION_TYPE_ERROR = 'INVALID_INSPECTION_TYPE_ERROR' as const;
export const EMPTY_SECTIONS_ERROR = 'EMPTY_SECTIONS_ERROR' as const;
export const EMPTY_SECTION_NAME_ERROR = 'EMPTY_SECTION_NAME_ERROR' as const;
export const EMPTY_FIELDS_ERROR = 'EMPTY_FIELDS_ERROR' as const;
export const EMPTY_FIELD_NAME_ERROR = 'EMPTY_FIELD_NAME_ERROR' as const;
export const EMPTY_FIELD_TYPE_ERROR = 'EMPTY_FIELD_TYPE_ERROR' as const;
export const INVALID_FIELD_TYPE_ERROR = 'INVALID_FIELD_TYPE_ERROR' as const;
export const EMPTY_FIELD_OPTIONS_ERROR = 'EMPTY_FIELD_OPTIONS_ERROR' as const;
export const DUPLICATE_FIELD_OPTIONS_ERROR = 'DUPLICATE_FIELD_OPTIONS_ERROR' as const;
export const EMPTY_FIELD_OPTION_VALUE_ERROR = 'EMPTY_FIELD_OPTION_VALUE_ERROR' as const;
export const EMPTY_FIELD_VALIDATORS_ERROR = 'EMPTY_FIELD_VALIDATORS_ERROR' as const;
export const EMPTY_FIELD_VALIDATOR_ERROR = 'EMPTY_FIELD_VALIDATOR_ERROR' as const;
export const EMPTY_NUMERIC_VALIDATOR_TYPE_ERROR = 'EMPTY_NUMERIC_VALIDATOR_TYPE_ERROR' as const;
export const INVALID_NUMERIC_VALIDATOR_TYPE_ERROR = 'INVALID_NUMERIC_VALIDATOR_TYPE_ERROR' as const;
export const EMPTY_NUMERIC_VALIDATOR_COMPARISON_VALUE_ERROR = 'EMPTY_NUMERIC_VALIDATOR_COMPARISON_VALUE_ERROR' as const;
export const INVALID_NUMERIC_VALIDATOR_RANGE_ERROR = 'INVALID_NUMERIC_VALIDATOR_RANGE_ERROR' as const;

export type EmptyInspectionNameError = {
  type: typeof EMPTY_INSPECTION_NAME_ERROR;
};

export type InvalidInspectionTypeError = {
  type: typeof INVALID_INSPECTION_TYPE_ERROR;
};

export type EmptySectionsError = {
  type: typeof EMPTY_SECTIONS_ERROR;
};

export type EmptySectionNameError = {
  type: typeof EMPTY_SECTION_NAME_ERROR;
  sectionIndex: number;
};

export type EmptyFieldsError = {
  type: typeof EMPTY_FIELDS_ERROR;
  sectionName: string;
  sectionIndex: number;
};

export type EmptyFieldNameError = {
  type: typeof EMPTY_FIELD_NAME_ERROR;
  sectionName: string;
  sectionIndex: number;
  fieldIndex: number;
};

export type EmptyFieldTypeError = {
  type: typeof EMPTY_FIELD_TYPE_ERROR;
  sectionName: string;
  sectionIndex: number;
  fieldIndex: number;
  fieldName: string;
};

export type InvalidFieldTypeError = {
  type: typeof INVALID_FIELD_TYPE_ERROR;
  sectionName: string;
  sectionIndex: number;
  fieldIndex: number;
  fieldName: string;
};

export type EmptyFieldOptionsError = {
  type: typeof EMPTY_FIELD_OPTIONS_ERROR;
  sectionName: string;
  sectionIndex: number;
  fieldIndex: number;
  fieldName: string;
};

export type DuplicateFieldOptionsError = {
  type: typeof DUPLICATE_FIELD_OPTIONS_ERROR;
  sectionName: string;
  sectionIndex: number;
  fieldName: string;
  fieldIndex: number;
};

export type EmptyFieldOptionValueError = {
  type: typeof EMPTY_FIELD_OPTION_VALUE_ERROR;
  sectionName: string;
  sectionIndex: number;
  fieldName: string;
  fieldIndex: number;
  optionIndex: number;
};

export type EmptyFieldValidatorsError = {
  type: typeof EMPTY_FIELD_VALIDATORS_ERROR;
  sectionName: string;
  sectionIndex: number;
  fieldName: string;
  fieldIndex: number;
};

export type EmptyFieldValidatorError = {
  type: typeof EMPTY_FIELD_VALIDATOR_ERROR;
  sectionName: string;
  sectionIndex: number;
  fieldName: string;
  fieldIndex: number;
};

export type EmptyNumericValidatorTypeError = {
  type: typeof EMPTY_NUMERIC_VALIDATOR_TYPE_ERROR;
  sectionName: string;
  sectionIndex: number;
  fieldName: string;
  fieldIndex: number;
  validatorIndex: number;
};

export type InvalidNumericValidatorTypeError = {
  type: typeof INVALID_NUMERIC_VALIDATOR_TYPE_ERROR;
  sectionName: string;
  sectionIndex: number;
  fieldName: string;
  fieldIndex: number;
  validatorIndex: number;
};

export type EmptyNumericValidatorComparisonValueError = {
  type: typeof EMPTY_NUMERIC_VALIDATOR_COMPARISON_VALUE_ERROR;
  sectionName: string;
  sectionIndex: number;
  fieldName: string;
  fieldIndex: number;
  validatorIndex: number;
};

export type InvalidNumericValidatorRangeError = {
  type: typeof INVALID_NUMERIC_VALIDATOR_RANGE_ERROR;
  sectionName: string;
  sectionIndex: number;
  fieldName: string;
  fieldIndex: number;
  validatorIndex: number;
};

export type FormError =
  | EmptyInspectionNameError
  | InvalidInspectionTypeError
  | EmptySectionsError
  | EmptySectionNameError
  | EmptyFieldsError
  | EmptyFieldNameError
  | EmptyFieldTypeError
  | InvalidFieldTypeError
  | EmptyFieldOptionsError
  | DuplicateFieldOptionsError
  | EmptyFieldOptionValueError
  | EmptyFieldValidatorsError
  | EmptyFieldValidatorError
  | EmptyNumericValidatorTypeError
  | InvalidNumericValidatorTypeError
  | EmptyNumericValidatorComparisonValueError
  | InvalidNumericValidatorRangeError;

export const ErrorTypeToFormError: Record<FormError['type'], string> = {
  EMPTY_INSPECTION_NAME_ERROR: '点検表の名前は空白にできません',
  INVALID_INSPECTION_TYPE_ERROR: '不正な点検表タイプです',
  EMPTY_SECTIONS_ERROR: '点検表には1つ以上の点検項目を含める必要があります',
  EMPTY_SECTION_NAME_ERROR: '点検項目は必須です',
  EMPTY_FIELDS_ERROR: '点検項目には点検内容を含める必要があります',
  EMPTY_FIELD_NAME_ERROR: '点検内容は必須です',
  EMPTY_FIELD_TYPE_ERROR: '点検内容には点検タイプが必要です',
  INVALID_FIELD_TYPE_ERROR: '不正な点検タイプです',
  EMPTY_FIELD_OPTIONS_ERROR: '点検タイプが選択式の場合には選択肢が必要です',
  DUPLICATE_FIELD_OPTIONS_ERROR: '重複する選択肢があります',
  EMPTY_FIELD_OPTION_VALUE_ERROR: '空の選択肢があります',
  EMPTY_FIELD_VALIDATORS_ERROR: 'エラー値の設定が空白です',
  EMPTY_FIELD_VALIDATOR_ERROR: 'エラー値の設定項目に空白があります',
  EMPTY_NUMERIC_VALIDATOR_TYPE_ERROR: '検証項目に条件タイプが必要です',
  INVALID_NUMERIC_VALIDATOR_TYPE_ERROR: '検証項目の条件タイプが不正です',
  EMPTY_NUMERIC_VALIDATOR_COMPARISON_VALUE_ERROR: '検証項目の条件値が空白です',
  INVALID_NUMERIC_VALIDATOR_RANGE_ERROR: '検証項目の条件範囲が不正です',
};
