import React, {useCallback, useState} from 'react';
import {Grid} from '@material-ui/core';
import {FileCategory, HospitalProductFileIndex} from '@modules/files/types';
import {FileIndex} from '@modules/files/types';
import {FileTable} from './FileTable';
import {FileListToolBar} from './FileListToolBar';
import {useEffect} from 'react';

export type FileListProps = {
  files?: FileIndex[] | HospitalProductFileIndex[];
  fileCategory: FileCategory;
  searchFileName?: boolean;
  isLoading?: boolean;
  onUploadFile: (newFile: FileIndex, isFileSharingAcrossProducts?: boolean) => Promise<void>;
  onEditFileName: (fileHashId: string) => void;
  onDeleteFile: (fileHashId: string) => void;
  onOrderChange?: (columnIndex: number, orderDirection: 'asc' | 'desc') => void | Promise<void>;
  onUpdateFile?: (fileHashId: string, isFileSharingAcrossProducts: boolean) => void;
  disableUpload?: boolean;
  disableEdit?: boolean;
  uploadButtonTitle?: string | React.ReactNode;
  isDarkButton?: boolean;
  isBulkUploadDialog?: boolean;
};

const initialNoDataComponent = (
  <div>
    <p style={{fontWeight: 'bold'}}>ファイルは登録されていません。</p>
    <p>右上のボタンからファイルをアップロードできます。</p>
  </div>
);

const noSearchedDataComponent = (
  <div>
    <p style={{fontWeight: 'bold'}}>現在の検索条件に一致するファイルは登録されていません。</p>
    <p>検索条件を変えて、再度検索してみてください。</p>
  </div>
);

export const FileList: React.FC<FileListProps> = ({
  onUploadFile,
  files,
  fileCategory,
  searchFileName = true,
  disableUpload = false,
  ...props
}) => {
  const [fileName, setFileName] = useState<string>('');
  const [fileList, setFileList] = useState<FileIndex[] | undefined>(files);
  const [noDataComponent, setNoDataComponent] = useState(initialNoDataComponent);
  const {isDarkButton, uploadButtonTitle, isBulkUploadDialog} = props;
  useEffect(() => {
    if (fileName !== '') setFileList(files?.filter((item) => item.fileName.includes(fileName)));
    else setFileList(files);
  }, [fileName, files]);

  const handleChangeFileNameInput = useCallback(
    (editedFileName: string) => {
      if (editedFileName !== '') {
        setFileList(files?.filter((item) => item.fileName.includes(editedFileName)));
        setNoDataComponent(noSearchedDataComponent);
      } else {
        setFileList(files);
        setNoDataComponent(initialNoDataComponent);
      }
      setFileName(editedFileName);
    },
    [files]
  );
  return (
    <Grid container>
      <FileListToolBar
        searchFileName={searchFileName}
        fileCategory={fileCategory}
        onUploadFile={onUploadFile}
        onChangeFileNameInput={handleChangeFileNameInput}
        uploadButtonTitle={uploadButtonTitle}
        isDarkButton={isDarkButton}
        isBulkUploadDialog={isBulkUploadDialog}
        disableUpload={disableUpload}
      />
      <FileTable {...props} noDataComponent={noDataComponent} files={fileList ?? []} />
    </Grid>
  );
};
