import React from 'react';
import {Grid, styled} from '@material-ui/core';
import {useAtomValue} from 'jotai';
import {selectedTemplateAtom} from '../states';
import {InspectionPreview} from '@organisms/InspectionPreview';

const Container = styled(Grid)({
  root: {
    maxHeight: ' calc(100vh - 56px - 48px - 36px - 120px - 24px)',
  },
});

export const MainContent: React.FC = () => {
  const selectedTemplate = useAtomValue(selectedTemplateAtom);

  if (!selectedTemplate) {
    return null;
  }

  return (
    <Container container justifyContent="center">
      <InspectionPreview inspectionTable={selectedTemplate.table} />
    </Container>
  );
};
