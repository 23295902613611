import React, {useState, useCallback} from 'react';
import {Grid, makeStyles} from '@material-ui/core';
import Select from 'react-select';
import {SelectorConditionValue} from '../types';

type Props = {
  label: string;
  value: string;
  isMulti?: boolean;
  optionType: 'selector';
  options: SelectorConditionValue;
  defaultOptions?: SelectorConditionValue;
  onSubmit: (options: SelectorConditionValue) => void;
  onChange: (options: SelectorConditionValue) => void;
};

export const FilterDrawerSelectorField: React.FC<Props> = (props) => {
  const {options, defaultOptions = [], onChange, isMulti} = props;
  const classes = useStyles();
  const [selected, setSelected] = useState<SelectorConditionValue>(defaultOptions);

  const handleChange = useCallback(
    (values) => {
      const value = (isMulti ? values : [values]) as SelectorConditionValue;

      setSelected(value);
      onChange(value);
    },
    [onChange, isMulti]
  );

  return (
    <Grid container className={classes.root}>
      <div style={{width: '100%'}}>
        <Select
          isMulti={isMulti}
          value={selected}
          isClearable={false}
          closeMenuOnSelect={!isMulti}
          options={options}
          style={{width: '100%'}}
          placeholder={''}
          components={{IndicatorSeparator: null}}
          onChange={handleChange}
        />
      </div>
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: '32px',
  },
}));
