import _ from 'lodash';
import {
  EMPTY_REQUIRED_INSPECTION_ITEM_VALUE_ERROR,
  FormValue,
  NumericField,
  MultilineTextField,
  DateField,
  TimeField,
  MultiSelectField,
  SelectField,
  TextField,
} from '../../common/types';
import {FormikErrors, FieldInputProps, getIn} from 'formik';
import {
  DateInspectionItem,
  InspectionItem,
  MultiSelectInspectionItem,
  MultilineTextInspectionItem,
  NumericInspectionItem,
  SelectInspectionItem,
  TextInspectionItem,
  TimeInspectionItem,
} from '@modules/inspections/api';

export type SectionStatus = 'checked' | 'error' | 'none';
export type SectionStatusMap = {[sectionName: string]: SectionStatus};

const checkValue = (value: unknown, status?: SectionStatus): SectionStatus => {
  if (value && value !== EMPTY_REQUIRED_INSPECTION_ITEM_VALUE_ERROR) {
    return 'error';
  } else if (value === EMPTY_REQUIRED_INSPECTION_ITEM_VALUE_ERROR) {
    return 'none';
  }

  return status || 'none';
};

/**
 * セッションの状態(入力済み、エラーあり、未入力)を返す
 * @param field
 * @param errors
 * @param sections
 * @returns
 */
export const getSectionStatus = (
  field: FieldInputProps<FormValue>,
  errors: FormikErrors<FormValue>,
  sections: InspectionItem[]
) => {
  const itemMap: {[key: string]: SectionStatus} = {};
  const sectionItemMap: {[sectionName: string]: {id: string; status: SectionStatus; required: boolean}[]} = {};
  const sectionStatus: SectionStatusMap = {};
  // 入力済みチェック
  const fieldItems = field.value.items;
  for (const key in fieldItems) {
    if (Object.prototype.hasOwnProperty.call(fieldItems, key)) {
      const item = fieldItems[key];
      switch (item.type) {
        case 'select':
        case 'numeric':
        case 'text':
        case 'multiline-text':
        case 'time':
        case 'date': {
          const typedItem = item as SelectField | NumericField | TextField | MultilineTextField | DateField | TimeField;
          itemMap[item.id] = typedItem.value ? 'checked' : 'none';
          break;
        }
        case 'multi-select': {
          const typedInput = item as MultiSelectField;
          itemMap[item.id] = typedInput.values ? 'checked' : 'none';
          break;
        }
      }
    }
  }

  // エラーチェック
  if (errors.items && _.isPlainObject(errors.items)) {
    Object.entries(errors.items).forEach(([id, item]) => {
      if (!item) return;
      {
        const valueError = getIn(item, 'value');
        itemMap[id] = checkValue(valueError, itemMap[id]);
      }

      {
        const valuesError = getIn(item, 'values');
        itemMap[id] = checkValue(valuesError, itemMap[id]);
      }
    });
  }

  // セクション毎に結果をまとめる
  sections.forEach((section) => {
    if (section.type !== 'section') return;
    sectionItemMap[section.name] = [];
    section.items.forEach((item) => {
      if (!item.id) return;

      const sectionItem = item as
        | SelectInspectionItem
        | MultiSelectInspectionItem
        | NumericInspectionItem
        | TextInspectionItem
        | MultilineTextInspectionItem
        | DateInspectionItem
        | TimeInspectionItem;

      sectionItemMap[section.name].push({
        id: item.id,
        status: itemMap[item.id],
        required: sectionItem.required,
      });
    });
  });

  for (const key in sectionItemMap) {
    if (Object.prototype.hasOwnProperty.call(sectionItemMap, key)) {
      const sectionItem = sectionItemMap[key];

      if (sectionItem.find((v) => v.status === 'error')) {
        sectionStatus[key] = 'error';
      } else if (sectionItem.filter((v) => v.required).every((v) => v.status === 'checked')) {
        sectionStatus[key] = 'checked';
      } else {
        sectionStatus[key] = 'none';
      }
    }
  }

  return sectionStatus;
};
