import React, {useCallback, useEffect, useState} from 'react';
import {Popper, Grow, Paper, makeStyles} from '@material-ui/core';
import {SelectOptionProps} from '.';
import {Control, DropdownIndicator, NoOptionsMessage, Option} from './reactSelectOverrides';
import Select from 'react-select';

type Props = {
  open: boolean;
  isMulti: boolean;
  allowSelectAll: boolean;
  searchable: boolean;
  searchLabel: string;
  options: SelectOptionProps[];
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  onSelectChange: any;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  onInputChange?: any;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  value: any;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  anchorEl: any;
};

export const PopperSelectBox: React.FC<Props> = ({
  open,
  anchorEl,
  isMulti,
  allowSelectAll,
  searchable,
  searchLabel,
  options,
  value,
  onSelectChange,
  onInputChange,
}) => {
  const classes = useStyles();
  return (
    <Popper
      open={open}
      modifiers={{
        computeStyle: {
          gpuAcceleration: false,
        },
      }}
      anchorEl={anchorEl}
      className={classes.root}
      transition>
      {({TransitionProps}) => (
        <Grow {...TransitionProps} style={{transformOrigin: 'top center'}}>
          <Paper elevation={2} className={classes.popper}>
            <div>
              <Selector
                isMulti={isMulti}
                allowSelectAll={allowSelectAll}
                searchable={searchable}
                options={options}
                defaultValue={value}
                searchLabel={searchLabel}
                onSelectChange={onSelectChange}
                onInputChange={onInputChange}
              />
            </div>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

// biome-ignore lint/suspicious/noExplicitAny: TODO:型定義をおこなう
const Selector: React.FC<any> = ({
  isMulti,
  allowSelectAll,
  searchable,
  options,
  defaultValue,
  searchLabel,
  onSelectChange,
  onInputChange,
}) => {
  const [value, setValue] = useState<SelectOptionProps | SelectOptionProps[] | null>(defaultValue ?? null);
  const allOption = {label: 'すべて選択', value: 'all'};

  const handleSelectChange = useCallback(
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    (val: any) => {
      setValue(val);
      onSelectChange(val);
    },
    [onSelectChange]
  );

  // ボタン側のXを押された時に、valueをnullにできるようにstateを更新する。
  // 極力再レンダーしないようにnullの場合のみstateを更新する。
  useEffect(() => {
    if (defaultValue === null) setValue(defaultValue);
  }, [defaultValue]);

  return (
    <Select<SelectOptionProps, true | false>
      autoFocus
      isMulti={isMulti}
      backspaceRemovesValue={false}
      components={{
        IndicatorSeparator: null,
        Option,
        Control,
        DropdownIndicator,
        NoOptionsMessage,
      }}
      defaultValue={defaultValue}
      controlShouldRenderValue={false}
      hideSelectedOptions={false}
      isSearchable={searchable}
      isClearable={false}
      onInputChange={onInputChange}
      menuIsOpen
      allowSelectAll={allowSelectAll}
      onChange={handleSelectChange}
      options={allowSelectAll ? [allOption, ...options] : options}
      placeholder={searchLabel}
      styles={{
        menuList: () => ({
          padding: 0,
          borderRadius: 4,
          maxHeight: '240px',
          overflowY: 'scroll',
        }),
        menu: () => ({
          margin: 0,
          borderRadius: 4,
        }),
      }}
      tabSelectsValue={false}
      value={value}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.modal,
    minWidth: '160px',
  },
  popper: {
    border: `1px solid ${theme.palette.primary.light}`,
    borderRadius: 3,
  },
}));
