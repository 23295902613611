import {useMemo} from 'react';
import {convertDateToRFC3339, isNullish} from '@front-libs/helpers';
import {
  useCompletedAtRange,
  useInspectionResultStatus,
  useInspector,
  useIsAdhoc,
  useIsPassed,
  useOrderKey,
  usePage,
  useQuerySearchName,
  useRootCategory,
  useNarrowCategory,
  usePerPage,
  useHospitalWard,
  useHospitalRoom,
  useRentHospitalWard,
  useRentHospitalRoom,
  useInspectionName,
  useInspectionSettingName,
  useSkippedTimeRange,
} from './states';
import {
  useFetchInspectionResultsQuery,
  FetchInspectionResultsParam,
  useFetchInspectionResultsCountsQuery,
} from '@modules/inspection_results/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {ViewInspectionType} from '../../types';
import dayjs from 'dayjs';
import {useAtomValue} from 'jotai';
import {queryScheduledAtFromAtom, queryScheduledAtToAtom} from './selectors';

const FAKE_INSPECTION_ID = 'EgqdR7b5l3mzr6Y';

export const useInspectionResults = (type: ViewInspectionType) => {
  const {myInfo} = useMyInfo();

  const [orderKey] = useOrderKey();
  const [page] = usePage();
  const [perPage] = usePerPage();
  const searchName = useQuerySearchName();
  const [inspectionResultStatus] = useInspectionResultStatus();
  const [inspectorHashId] = useInspector();
  const [[completedAtFrom, completedAtTo]] = useCompletedAtRange();
  const [[skippedDateFrom, skippedDateTo]] = useSkippedTimeRange();
  const [isAdhoc] = useIsAdhoc();
  const [isPassed] = useIsPassed();
  const [rootCategory] = useRootCategory();
  const [narrowCategory] = useNarrowCategory();
  const [hospitalWard] = useHospitalWard();
  const [hospitalRoom] = useHospitalRoom();
  const [rentHospitalWard] = useRentHospitalWard();
  const [rentHospitalRoom] = useRentHospitalRoom();
  const [inspectionName] = useInspectionName();
  const [inspectionSettingName] = useInspectionSettingName();

  const queryScheduledAtFrom = useAtomValue(queryScheduledAtFromAtom);
  const queryScheduledAtTo = useAtomValue(queryScheduledAtToAtom);

  const params = useMemo(() => {
    const _p: FetchInspectionResultsParam = {
      page: page - 1,
      perPage: perPage,
      statuses: inspectionResultStatus !== 'overdue' ? inspectionResultStatus : 'unplanned',
    };

    _p.types = type;

    if (orderKey) {
      _p.order = orderKey;
    } else if (isNullish(orderKey) && inspectionResultStatus === 'completed') {
      _p.order = '-completedAt';
    }

    if (searchName) _p.name = searchName;

    if (inspectionResultStatus === 'completed' && inspectorHashId !== null) {
      _p.inspectorHashId = inspectorHashId;
    }

    if (queryScheduledAtFrom) {
      _p.scheduledAtFrom = queryScheduledAtFrom;
    }

    if (queryScheduledAtTo) {
      _p.scheduledAtTo = queryScheduledAtTo;
    }

    if (completedAtFrom !== null) {
      _p.completedAtFrom = convertDateToRFC3339(dayjs(completedAtFrom).add(-1, 'day').endOf('day').toDate());
    }

    if (completedAtTo !== null) {
      _p.completedAtTo = convertDateToRFC3339(dayjs(completedAtTo).endOf('day').toDate());
    }

    if (skippedDateFrom !== null) {
      _p.skippedAtFrom = convertDateToRFC3339(dayjs(skippedDateFrom).add(-1, 'day').endOf('day').toDate());
    }

    if (skippedDateTo !== null) {
      _p.skippedAtTo = convertDateToRFC3339(dayjs(skippedDateTo).endOf('day').toDate());
    }

    if (isAdhoc !== null) {
      _p.isAdhoc = isAdhoc;
    }

    if (isPassed !== null) {
      _p.result = isPassed ? 'passed' : 'failed';
    }

    if (rootCategory && !narrowCategory) {
      _p.categories = rootCategory;
    } else if (narrowCategory) {
      _p.categories = narrowCategory;
    }

    if (hospitalWard !== null) {
      _p.hospitalWardHashId = hospitalWard;
    }

    if (hospitalRoom !== null) {
      _p.hospitalRoomHashId = hospitalRoom;
    }

    if (rentHospitalWard !== null) {
      _p.rentHospitalWardHashId = rentHospitalWard;
    }

    if (rentHospitalRoom !== null) {
      _p.rentHospitalRoomHashId = rentHospitalRoom;
    }

    if (inspectionName !== null) {
      _p.inspectionName = inspectionName;
    }

    if (inspectionSettingName !== null) {
      _p.inspectionSettingName = inspectionSettingName;
    }

    return _p;
  }, [
    type,
    page,
    perPage,
    orderKey,
    searchName,
    inspectionResultStatus,
    inspectorHashId,
    completedAtFrom,
    completedAtTo,
    skippedDateFrom,
    skippedDateTo,
    queryScheduledAtFrom,
    queryScheduledAtTo,
    isAdhoc,
    isPassed,
    rootCategory,
    narrowCategory,
    hospitalWard,
    hospitalRoom,
    rentHospitalWard,
    rentHospitalRoom,
    inspectionName,
    inspectionSettingName,
  ]);

  return useFetchInspectionResultsQuery(myInfo.hospitalHashId, FAKE_INSPECTION_ID, params);
};

export const useInspectionResultsCounts = (type: ViewInspectionType) => {
  const {myInfo} = useMyInfo();

  return useFetchInspectionResultsCountsQuery(myInfo.hospitalHashId, FAKE_INSPECTION_ID, type);
};
