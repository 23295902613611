import {Box, Button, Typography, styled} from '@material-ui/core';

export const FormFieldsSectionContainer = styled(Box)({
  width: '100%',
  height: '100%',
  margin: 0,
  marginBottom: '32px',
  borderRight: '1px solid #D1D5DB',
  overflow: 'hidden',
});

type StatusLabelProps = {
  visible: string;
};

export const StatusLabel = styled('h2')(({visible}: StatusLabelProps) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  fontSize: '16px',
  fontWeight: 'bold',
  height: '42px',
  left: '116px',
  position: 'relative',
  visibility: visible === 'true' ? 'visible' : 'hidden',
}));

export const SectionLabel = styled('h2')({
  paddingLeft: '16px',
  display: 'flex',
  alignItems: 'center',
  fontSize: 16,
  fontWeight: 'bold',
  marginBottom: 16,
});

export const SectionContainer = styled('div')({
  marginBottom: '8px',
});

export const SectionDetail = styled('div')({
  display: 'grid',
  gap: '16px',
  // 232pxはForm200pxの幅とpaddingを合わせた数値
  gridTemplateColumns: 'repeat(auto-fit, minmax(232px, 1fr))',
  // 1588pxは一つのForm232pxが4つ並んだとき 右ペイン(タイムライン等)の幅660pxを足した数値
  '@media (min-width: 1588px)': {
    gridTemplateColumns: 'repeat(4, 1fr)', // 最大4列に制限
  },
  // ProductContentColumnRight(右ペイン)が1024pxでWidthが490pxに切り替わる為Column数を固定している
  '@media (max-width: 1024px)': {
    gridTemplateColumns: 'repeat(1, 1fr)', // 最大1列に制限
  },
});

export const SectionDetailInTablet = styled(SectionDetail)({
  flexDirection: 'column',
});

export const SectionFormField = styled('div')({
  padding: '8px 16px',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  minWidth: '200px',
  flexGrow: 0,
  flexShrink: 0,
});

export const SectionFormFieldInTablet = styled(SectionFormField)({
  maxWidth: '100%',
  flexBasis: '50%',
});

// 機種情報、機器情報、詳細情報の3つのセクションでスクロールが有効化するために、調整用の高さを設定
export const ADJUST_SCROLL_HEIGHT = '202px';
export const ADJUST_SCROLL_HEIGHT_IN_TABLET = '240px';

export const FormFieldsSections = styled('div')({
  // 56pxはヘッダーの高さ 44pxは機器一覧に戻るの高さ 次の44pxは機種名表示領域の高さ 42pxはラベルの高さ 最期の42pxはマージン
  height: `calc(100vh - 56px - 44px - 44px - 42px - 42px)`,
  padding: '0 16px',
  overflowY: 'auto',
});

export const NavigationButton = styled(Button)({
  fontWeight: 400,
  fontSize: '14px',
});
export const NavigationArea = styled('div')({
  width: '100%',
  height: '44px',
  display: 'flex',
  justifyContent: 'space-between',
});

export const NavigationButtonContainer = styled('div')({
  height: '100%',
  display: 'flex',
  margin: '0px 20px',
});

export const Header = styled('div')({display: 'flex', margin: '16px 32px'});

export const AppendixHeaderRoot = styled('div')({display: 'flex', margin: '16px 32px'});

export const StyledButton = styled(Button)({
  height: '28px',
  padding: '0px',
  borderRadius: '3px',
  backgroundColor: 'white',
  marginRight: '8px',
  whiteSpace: 'nowrap',
  '&:last-child': {
    marginRight: '0',
  },
});

export const StyledIconButton = styled(StyledButton)({
  minWidth: '14px',
});

export const SelectedTabButton = styled(StyledButton)({
  color: '#0052CC',
  backgroundColor: '#DAE5F7',
  '&:hover': {
    backgroundColor: '#DAE5F7',
  },
});

export const IconWrapper = styled('span')({
  width: '16px',
  height: '16px',
  margin: '0px 4px',
});

export const BtnLabelTypography = styled(Typography)({
  fontSize: '14px',
  marginRight: '4px',
  whiteSpace: 'nowrap',
});

export const RentalAndReturnCardTitle = styled(Typography)({
  color: '#172B4D',
  fontSize: '14px',
  fontWeight: 600,
});

export const SelectedBtnLabelTypography = styled(BtnLabelTypography)({
  fontWeight: 600,
});

// サムネイル写真のPadding込のサイズ
export const THUMBNAIL_SIZE = 88 + 8 * 2;
export const PRODUCT_PC_APPENDIX_WIDTH = '600px';
export const PRODUCT_TABLET_APPENDIX_WIDTH = '490px';
export const APPENDIX_WIDTH_BREAK_POINT = '1024px';

/**
 * 機器詳細情報のルートコンテナ
 * 機器詳細ヘッダー、機器詳細情報、右側の補足情報(タイムライン/ファイル/添付文書/点検・修理予定)を表示する。
 */
export const SectionRootContainer = styled('div')({
  width: '100%',
  height: 'calc(100vh - 56px)',
  overflow: 'hidden',
});

/**
 * 機器詳細情報のコンテナ
 * 機器詳細情報、右側の補足情報(タイムライン/ファイル/添付文書/点検・修理予定)を管理する。
 */
export const ProductContentContainer = styled(Box)({
  padding: '0px',
  display: 'flex',
  width: '100%',
  height: '100%',
  backgroundColor: 'white',
});

const defaultColumn = {
  width: '50%',
  padding: '0px',
};

/**
 * 機器詳細情報のカラム
 * 機器詳細情報コンテナ内は2カラム構成のため、各カラムを管理する事が目的。
 */
export const ProductContentColumnLeft = styled(Box)({
  ...defaultColumn,
  '@media (min-width: 1025px)': {
    flexGrow: 1,
  },
});
export const ProductContentColumnRight = styled(Box)({
  ...defaultColumn,
  '@media (min-width: 1025px)': {
    width: '600px',
  },
});

/**
 * チャンネル表示
 * 機器詳細情報コンテナ内は2カラム構成のため、各カラムを管理する事が目的。
 */
export const ChannelLabel = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  fontSize: 16,
  fontWeight: 'bold',
}));

/**
 * チャンネル表示
 * チャンネル：
 */
export const ChannelLabelTypography = styled(Typography)({
  fontSize: '14px',
  fontWeight: 400,
});

export const ChannelNoTypography = styled(Typography)({
  fontSize: '14px',
  fontWeight: 600,
  color: '#0052CC',
  cursor: 'pointer',
});

export const TimeLineTitle = styled('div')({
  fontWeight: 600,
  color: '#172B4D',
});

export const Link = styled('a')({
  color: '#0052CC',
  textDecoration: 'none',
});
