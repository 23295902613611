import {useCallback, useMemo, useState} from 'react';
import {useFaultReceptionsQuery} from '@modules/fault_receptions/api/faultReceptionApi';
import {FaultReception} from '@modules/fault_receptions/types';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import React from 'react';
import {useUpdateAPIQueryParams} from './hooks';
import {BEFORE_CONFIRMATION_STATUS} from './constants';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {TableLayoutDialog, TableLayoutDialogProps} from '@organisms/Table/TableLayoutDialog';
import {TableLayoutResult, useTableLayout} from '@modules/table_layout/hooks/useTableLayout';
import {MenuItemType} from '@components/molecules/Buttons/PopperMenuButton';

interface FaultReceptionListProps {
  children: (props: {
    data: FaultReception[];
    totalCount: number;
    page: number;
    handlePageChange: (e: React.ChangeEvent<unknown>, p: number) => void;
    pageSize: number;
    handlePageSizeChange: (p: number) => void;
    totalPage: number;
    searchName: string | null;
    handleSearchName: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleActionMenuClick: (item: MenuItemType) => void;
    status: string | null;
    handleStatus: (status: string | null) => void;
    handleOrderChange: (columnIndex: number, orderDirection: 'asc' | 'desc') => void;
    tableLayout: TableLayoutResult;
  }) => React.ReactNode;
}

export const FacetSearchForm = ({children}: FaultReceptionListProps) => {
  const [order, setOrder] = useState<string | null>(null);
  const [searchName, setSearchName] = useState<string | null>(null);
  const [status, setStatus] = useState<string | null>(BEFORE_CONFIRMATION_STATUS);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [tableLayout, setTableLayout] = useTableLayout('receptionList');

  const handleSearchName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value; // イベントから値を直接取得
      setSearchName(newValue); // 状態を更新
      setPage(1);
    },
    [setSearchName]
  );

  const handleChangeLayout = async () => {
    const currentLayout = await dialogHandler.open<TableLayoutDialogProps>(TableLayoutDialog, {
      tableColumns: tableLayout?.tableLayout ?? [],
      defaultOptions: tableLayout?.currentLayout ?? [],
    });
    const newTableLayout = {
      tableLayout: tableLayout?.tableLayout ?? [],
      currentLayout: currentLayout,
    };
    setTableLayout(newTableLayout);
  };

  const handleActionMenuClick = (item: MenuItemType) => {
    switch (item.value) {
      case 'changeTableLayout':
        handleChangeLayout();
        break;
    }
  };
  const handleOrderChange = useCallback(
    (columnIndex: number, orderDirection: 'asc' | 'desc') => {
      if (columnIndex === -1) {
        setOrder(null);
      } else {
        setOrder(`${orderDirection === 'desc' ? '-' : ''}${String(tableLayout?.currentLayout[columnIndex].field)}`);
      }
    },
    [setOrder, tableLayout?.currentLayout]
  );
  const handleStatus = useCallback((_status: string | null) => {
    if (_status === 'all') {
      _status = null;
    }
    setStatus(_status);
    setPage(1);
  }, []);

  const handlePageChange = useCallback(
    (e: React.ChangeEvent<unknown>, p: number) => {
      setPage(p);
    },
    [setPage]
  );
  const handlePageSizeChange = useCallback(
    (p: number) => {
      setPage(1);
      setPageSize(p);
    },
    [setPageSize]
  );

  const {params, beforeConfParam} = useUpdateAPIQueryParams(page, pageSize, searchName, status, order);
  const {myInfo} = useMyInfo();

  const query = useFaultReceptionsQuery(myInfo.hospitalHashId, params);

  const beforeConfirmationQuery = useFaultReceptionsQuery(myInfo.hospitalHashId, beforeConfParam);

  const totalPage = useMemo(() => {
    return Math.ceil(query.totalCount / pageSize);
  }, [pageSize, query.totalCount]);

  const queryResult = {
    data: query.data,
    totalCount: beforeConfirmationQuery.totalCount,
    totalPage: totalPage,
    page: page,
    handlePageChange,
    searchName,
    handleSearchName,
    handleActionMenuClick,
    status,
    handleStatus,
    pageSize,
    handlePageSizeChange,
    handleOrderChange,
    tableLayout,
  };
  return <>{children(queryResult)}</>;
};
