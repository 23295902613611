import React, {useMemo, Suspense, useCallback, useState} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Typography,
  IconButton,
  Box,
} from '@material-ui/core';
import {Close} from '@material-ui/icons';
import {Form, Formik, useFormikContext} from 'formik';
import {TextField} from '@molecules/Formik/fields';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import * as yup from 'yup';
import {UserFormatter} from '@modules/hospital_users/helpers';
import {useHospitalUsers} from '@modules/hospital_users/hooks/useHospitalUsers';
import {RequiredLabel} from '@molecules/FormRequiredLabel';
import {InspectionResultIndex} from '@modules/inspection_results/types';
import {HospitalProductInfo} from '@organisms/HospitalProductInfo/sp';
import {styled} from '@material-ui/styles';
import {KeyboardArrowDown} from '@material-ui/icons';
import {UserIndex} from '@modules/hospital_users/types';
import {SkipInspectorSelector} from './SkipInspectorSelector';
import {useAtom} from 'jotai';
import {skipInspector} from './hooks';

const validationSchema = yup.object({
  skipDate: yup.string().required(),
  operator: yup.string().required(),
  reason: yup.string(),
});

const StyledButton = styled('button')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '4px',
  border: `1px solid  #C6CBD4`,
  borderRadius: '4px',
  backgroundColor: 'white',
  width: '100%',
});
const StyledKeyboardArrowDown = styled(KeyboardArrowDown)({
  fontSize: '12px',
});
const StyledSelectBoxText = styled('p')({
  color: '#172B4D',
  fontSize: '16px',
  margin: '6px',
});

type SkipInspectionFormProps = {
  open: boolean;
  defaultUserHashId: string;
  onClose: React.MouseEventHandler;
  inspectionResult: InspectionResultIndex;
};

type SkipInspectionFormValue = yup.InferType<typeof validationSchema>;

const SkipInspectionDialogForm: React.FC<SkipInspectionFormProps> = (props) => {
  const {open, defaultUserHashId, onClose, inspectionResult} = props;
  const classes = useStyles();
  const {submitForm, isValid} = useFormikContext<SkipInspectionFormValue>();
  const {hospitalUsers} = useHospitalUsers();
  const [selectedInspector] = useAtom(skipInspector);
  const [openInspectorBox, setOpenInspectorBox] = useState(false);

  const handleSubmit = useCallback(() => {
    submitForm();
  }, [submitForm]);

  /** スキップクリック */
  const onClickInspector = useCallback(() => {
    setOpenInspectorBox(true);
  }, []);
  const onCloseInspectorBox = useCallback((userIndex?: UserIndex) => {
    setOpenInspectorBox(false);
  }, []);

  const defaultUser = useMemo(
    () => hospitalUsers.find((o) => o.hashId === defaultUserHashId) ?? hospitalUsers[0],
    [defaultUserHashId, hospitalUsers]
  );

  if (hospitalUsers.length === 0) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      PaperProps={{
        style: {
          margin: 0,
          maxHeight: '100%',
          height: '100%',
          width: '100%',
          borderRadius: 0,
        },
      }}>
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <IconButton aria-label="close" onClick={onClose}>
          <Close />
        </IconButton>
        <Box className={classes.box}>
          <Typography className={classes.title} style={{fontSize: '20px', fontWeight: 'bold'}}>
            点検をスキップ
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent className={classes.content} style={{flex: 'none'}}>
        <Box>
          {openInspectorBox && (
            <SkipInspectorSelector
              userIndexList={hospitalUsers}
              defaultUser={selectedInspector ?? defaultUser}
              completeCallBack={onCloseInspectorBox}
            />
          )}
          <HospitalProductInfo hospitalProduct={inspectionResult.hospitalProduct} />
          <Grid container className={classes.fields} direction="column">
            <Grid style={{margin: '16px 16px 0px 16px'}}>
              <RequiredLabel>スキップをした日時</RequiredLabel>
              <TextField type="date" name="skipDate" size="small" fullWidth />
            </Grid>
            <Grid style={{margin: '16px 16px 0px 16px'}}>
              <RequiredLabel>スキップ実施者</RequiredLabel>
              <StyledButton onClick={onClickInspector}>
                <StyledSelectBoxText>
                  {selectedInspector
                    ? UserFormatter.getFullName(selectedInspector)
                    : defaultUser
                      ? UserFormatter.getFullName(defaultUser)
                      : `点検者`}
                </StyledSelectBoxText>
                <StyledKeyboardArrowDown />
              </StyledButton>
            </Grid>
            <Grid style={{margin: '16px 16px 0px 16px'}}>
              <label>スキップ理由</label>
              <TextField type="text" name="reason" size="small" fullWidth />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button variant="contained" color="primary" onClick={handleSubmit} disabled={!isValid} style={{width: '100%'}}>
          スキップ
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export type SkipInspectionDialogProps = DialogProps & {
  defaultOperatorHashId: string;
  defaultSkipDate: string;
  inspectionResult: InspectionResultIndex;
};

export type SkipInspectionDialogResult = {
  skipDate: Date;
  operator: string;
  reason?: string;
};

export const SkipInspectionDialog: React.FC<SkipInspectionDialogProps> = (props) => {
  const {open, defaultOperatorHashId, defaultSkipDate, inspectionResult} = props;

  const [operator, setOperator] = useAtom(skipInspector);
  const handleSubmit = useCallback(
    async (data: SkipInspectionFormValue) => {
      const {skipDate, reason} = data;
      props.actions.resolve({
        skipDate: new Date(skipDate),
        operator: operator?.hashId,
        reason: reason !== '' ? reason : undefined,
      });
      // 選択状態をクリア
      setTimeout(() => {
        setOperator(undefined);
      }, 300);
    },
    [props.actions, operator?.hashId, setOperator]
  );

  const handleClose = useCallback(
    (e: React.MouseEvent) => {
      setTimeout(() => {
        setOperator(undefined);
      }, 300);
      props.actions.reject();
    },
    [props.actions, setOperator]
  );

  return (
    <Suspense fallback={null}>
      <Formik
        initialValues={{skipDate: defaultSkipDate, operator: defaultOperatorHashId, reason: ''}}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        isInitialValid={true}>
        <Form>
          <SkipInspectionDialogForm
            open={open}
            onClose={handleClose}
            defaultUserHashId={defaultOperatorHashId}
            inspectionResult={inspectionResult}
          />
        </Form>
      </Formik>
    </Suspense>
  );
};

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px',
  },
  title: {
    color: '#172B4D',
  },
  content: {
    width: '100%',
    padding: '0px',
    overflow: 'hidden',
  },
  fields: {
    '& > :not(:first-child)': {
      marginTop: '24px',
    },
  },
  inputDate: {
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  actions: {
    padding: '48px 16px 32px',
  },
  box: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    position: 'fixed',
    pointerEvents: 'none',
  },
}));
