import {axios} from '@front-libs/core';

export type UpdateData = {
  // biome-ignore lint/suspicious/noExplicitAny: 現時点ではanyで一括編集の対象項目がすべて判明次第修正
  [key: string]: any;
};

export const bulkUpdateWholeProducts = async (
  hospitalHashId: string,
  data: UpdateData,
  wholeProductHashIds: string[]
) => {
  const hospitalUserUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/whole_products/bulk_update`;
  const res = await axios.put<string>(hospitalUserUrl, {
    whole_products: {wholeProductHashIds: wholeProductHashIds, ...data},
  });
  return res.data;
};
