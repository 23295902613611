import React, {useState} from 'react';
import {styled, Box} from '@mui/material';
import {Tabs} from './Tabs';
import {TraineeFileLists} from './TraineeFileLists';
import {initTabValue} from './constants';
import {TabValue} from './types';

type Props = {
  trainingScheduleHashId: string;
};

const Container = styled(Box)({
  padding: '16px 0',
});

const Contents = styled(Box)({
  position: 'relative', // NOTE: タブの位置固定に利用する。
});

const TabSelect = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
});

const TraineeContents: React.FC<Props> = ({trainingScheduleHashId}) => {
  const [tabValue, setTabValue] = useState<TabValue>(initTabValue);

  const handleTabChange = (newValue: TabValue) => {
    setTabValue(newValue);
  };

  const renderTabContent = () => {
    switch (tabValue) {
      case 'file':
        return <TraineeFileLists trainingScheduleHashId={trainingScheduleHashId} />;
      default:
        return null;
    }
  };

  return (
    <Container>
      <Contents>
        <TabSelect>
          <Tabs value={tabValue} onChange={handleTabChange} />
        </TabSelect>
        {renderTabContent()}
      </Contents>
    </Container>
  );
};

export default TraineeContents;
