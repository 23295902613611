import {atom} from 'jotai';
import {atomWithReset, atomWithStorage} from 'jotai/utils';
import {GetHospitalWholeProductParam} from '@modules/products/api';
import {SelectOptionProps} from '@molecules/Buttons/PopperSelectBoxButton';
import {Order} from '@molecules/Table/props';
import {TableLayout} from '../table_layout/hooks/useTableLayout';
import {
  NumConditionValue,
  NumberConditionValue,
  ResultType,
  SelectorConditionValue,
  TextConditionValue,
} from '@components/molecules/Drawers/FilterDrawer/types';

const LOCAL_STORAGE_KEY_WHOLE_PRODUCTS_LIST = 'hitotsu/whole_products_page_size_status';
export const wholeProductTotalCount = atom(0);
export const wholeProductCurrentLayout = atom<TableLayout[] | undefined>(undefined);
export const searchNameAtom = atom<string | null>(null);
export const defaultOrder: Order = {fieldId: 'displayName', direction: 'asc'};
export const orderKeyAtom = atom<Order | null>(null);
export const searchRootCategoriesAtom = atom<SelectOptionProps[]>([]);
export const searchSubCategoriesAtom = atom<SelectOptionProps[]>([]);
export const searchFilterResultsAtom = atom<ResultType[]>([]);
export const openDrawerAtom = atom<boolean>(false);
export const pageAtom = atomWithReset<number>(1);
export const pageSizeAtom = atomWithStorage<number>(LOCAL_STORAGE_KEY_WHOLE_PRODUCTS_LIST, 20);

type DetailFilter = {
  makerHashId: string;
  approvalNumber: string;
  jmdnCode: number;
  newJanCode: string;
  janCode: string;
  isSpecificMaintain: boolean;
  className: string;
  catalogPriceFrom: number;
  catalogPriceTo: number;
};

export const hospitalWholeProductsVariables = atom<GetHospitalWholeProductParam>((get) => {
  const rootCategories = get(searchRootCategoriesAtom);
  const subCategories = get(searchSubCategoriesAtom);
  const order = get(orderKeyAtom);
  const detailFilter = convertFilterResults(get(searchFilterResultsAtom));

  const _p: GetHospitalWholeProductParam = {
    page: get(pageAtom) - 1 || 0,
    perPage: get(pageSizeAtom),
    name: get(searchNameAtom) ?? undefined,
    categoryHashIds:
      subCategories.length > 0
        ? subCategories.map((item) => item.value).join(',')
        : rootCategories.length > 0
          ? rootCategories.map((item) => item.value).join(',')
          : undefined,
    order: order ? `${order.direction === 'desc' ? '-' : ''}${order.fieldId}` : 'displayName',
    ...detailFilter,
  };
  return _p;
});

const convertFilterResults = (filterResults: ResultType[]): Partial<DetailFilter> => {
  const detailFilter: Partial<DetailFilter> = {};
  filterResults.forEach((item) => {
    switch (item.key) {
      case 'makerHashId':
        detailFilter.makerHashId = (item.resultValue as SelectorConditionValue)[0].value as string;
        break;
      case 'approvalNumber':
        detailFilter.approvalNumber = item.resultValue as TextConditionValue;
        break;
      case 'jmdnCode':
        detailFilter.jmdnCode = item.resultValue as NumConditionValue;
        break;
      case 'newJanCode':
        detailFilter.newJanCode = item.resultValue as TextConditionValue;
        break;
      case 'janCode':
        detailFilter.janCode = item.resultValue as TextConditionValue;
        break;
      case 'isSpecificMaintain':
        detailFilter.isSpecificMaintain = (item.resultValue as SelectorConditionValue)[0].value;
        break;
      case 'className':
        detailFilter.className = (item.resultValue as SelectorConditionValue)[0].value as string;
        break;
      case 'catalogPrice': {
        const typedValue = item.resultValue as NumberConditionValue;
        detailFilter.catalogPriceFrom = typedValue.from;
        detailFilter.catalogPriceTo = typedValue.to;
        break;
      }
      default:
        break;
    }
  });
  return detailFilter;
};
