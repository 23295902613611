import {Box, Typography} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import React from 'react';

export const SearchStartDisplay = () => {
  return (
    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
      <Box sx={{mr: 2}}>
        <Box sx={{fontWeight: 700, fontSize: 24, color: '#5D6B82', mb: 2}}>ここに検索結果が表示されます</Box>
        <Typography sx={{color: '#8B95A6'}}>機種の販売名、承認番号などの情報が一覧で表示されます</Typography>
      </Box>
      <Box>
        <SearchIcon sx={{fontSize: 220, color: '#DADADA'}} />
      </Box>
    </Box>
  );
};
