import {axios} from '@front-libs/core';
import {useQuery} from 'react-query';
import {getFaultReceptionsKey} from '@constants/api';
import {useMemo} from 'react';
import {CreateFaultReceptionParam, FaultReception, FaultReceptionParams, FaultReceptionsResponse} from '../types';

/**
 * 不具合受付の一覧を取得
 * @param hospitalHashId
 * @param params
 * @returns
 */
export const getFaultReceptions = async (hospitalHashId: string, params?: FaultReceptionParams) => {
  const url = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/fault_receptions`;
  const response = await axios.get<FaultReceptionsResponse>(url, params && {params});
  return response.data;
};

/**
 * 不具合受付の詳細を取得
 * @param hospitalHashId
 * @param faultReceptionHashId
 * @returns
 */
export const getFaultReception = async (hospitalHashId: string, faultReceptionHashId: string) => {
  const url = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/fault_receptions/${faultReceptionHashId}`;
  const {data} = await axios.get<FaultReception>(url);
  return data;
};

type UpdateFaultReceptionLogParams = {
  pinned?: boolean;
  description: string;
};

/**
 * 病院の不具合受付のログ情報を更新する関数
 */
export const updateFaultReceptionLog = async (
  hospitalHashId: string,
  faultReceptionHashId: string,
  logHashId: string,
  data: UpdateFaultReceptionLogParams
) => {
  const baseURL = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/fault_receptions/${faultReceptionHashId}/logs/${logHashId}`;
  return await axios.put<FaultReception>(baseURL, data);
};

/**
 * 病院の不具合受付のログ情報を削除する関数
 */
export const deleteFaultReceptionLog = async (
  hospitalHashId: string,
  faultReceptionHashId: string,
  logHashId: string
) => {
  const baseURL = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/fault_receptions/${faultReceptionHashId}/logs/${logHashId}`;
  return await axios.delete<FaultReception>(baseURL);
};

/**
 * 不具合受付の一覧を取得するReactQuery
 * @param hospitalHashId
 * @param params
 * @returns
 */
export const useFaultReceptionsQuery = (hospitalHashId: string, params: FaultReceptionParams) => {
  const query = useQuery([getFaultReceptionsKey, hospitalHashId, params], () =>
    getFaultReceptions(hospitalHashId, params)
  );
  return useMemo(
    () => ({
      ...query,
      data: query.data?.data ?? [],
      totalCount: query.data?.totalCount ?? 0,
      page: query.data?.page ?? 0,
    }),
    [query]
  );
};

/**
 * 不具合受付を登録
 * @param hospitalHashId
 * @param data
 * @returns
 */
export const createFaultReception = async (hospitalHashId: string, data: CreateFaultReceptionParam) => {
  const baseURL = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/fault_receptions`;
  return await axios.post<FaultReception>(baseURL, data);
};
