import React, {useCallback} from 'react';
import {Grid, Button, Typography, styled} from '@material-ui/core';
import {ChevronLeft} from '@material-ui/icons';
import {useNavigate} from 'react-router-dom';

export const Header: React.FC = () => {
  const navigate = useNavigate();

  const onClickPrevButton = useCallback(() => {
    navigate('/repairs');
  }, [navigate]);

  return (
    <Grid container>
      <Grid item container direction="column">
        <StyledButton color="inherit">
          <ChevronLeft />
          <span onClick={onClickPrevButton}>修理一覧に戻る</span>
        </StyledButton>
        <StyledTypography variant="h1">不具合受付</StyledTypography>
      </Grid>
    </Grid>
  );
};

const StyledButton = styled(Button)({
  width: '200px',
  padding: '0px',
  justifyContent: 'flex-start',
  fontColor: '#000000',
  fontWeight: 400,
  paddingBottom: '4px',
  marginTop: '-8px',
  '&:hover': {
    backgroundColor: 'inherit',
  },
});

const StyledTypography = styled(Typography)({
  fontSize: '18px',
  fontWeight: 'bold',
  fontColor: '#000000',
});
