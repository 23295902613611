import {FaultRepairLog, RepairIndex} from '@modules/repairs/types';
import {UserIndex} from '@modules/hospital_users/types';
import dayjs from 'dayjs';
import React, {useMemo} from 'react';
import {HistoryCardProps} from './Content/Cards/HistoryCard';
import {repairLogTypeEnum} from '@modules/repairs/constants';
import {groupBy} from 'lodash';
import {
  FetchHospitalProductFaultRepairHistoriesResult,
  useFetchHospitalProductFaultRepairHistoriesQuery,
} from '@modules/hospital_products/api';
import {useFetchHospitalProductFaultRepairLogsQuery} from '@modules/repairs/hooks';
import {useFetchHospitalProductLogsQuery} from '@modules/hospital_products/hooks/useHospitalProductLog';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {UseQueryResult, useQuery} from 'react-query';
import {getInspectionResults} from '@modules/inspection_results/api';
import {HospitalProductDetail, ProductStatusConst} from '@modules/hospital_products/types';
import {UserFormatter} from '@modules/hospital_users/helpers';
import {InspectionTypeMap} from '@modules/inspections/enum';
import {filterProductHistoryOptions} from './constants';
import {DeviceLender} from '@modules/rentals/api';
import {isNullish} from '@front-libs/helpers';
import {Link, RentalAndReturnCardTitle, TimeLineTitle} from './styled';
import {HospitalRoomFormatter} from '@modules/hospital_wards/helpers';
import {useFaultReceptionDetails} from '@modules/fault_receptions/hooks';
import {useFetchFaultReceptionLogsListQuery} from '@modules/fault_receptions/hooks/useFaultReceptionLog';
import {Build} from '@material-ui/icons';
import {v4 as uuidv4} from 'uuid';
import {useSetAtom} from 'jotai';
import {productsAtom} from '../ProductsList/pc/ProductsRegistrationDialog/state';
import {useNavigate} from 'react-router-dom';

const getDeviceLenderName = (deviceLender: DeviceLender | null, user: UserIndex): string => {
  if (isNullish(deviceLender)) return UserFormatter.getFullName(user);
  return `${!isNullish(deviceLender?.userId) ? deviceLender?.userId + ': ' : ''}${deviceLender?.userName ?? ''}`;
};

type CommentLogType = {
  faultRepairLogType: 'comment';
  comment: string;
  commentUser?: UserIndex;
};

type StatusChangeLogType = {
  faultRepairLogType: 'statusChange';
  fromStatusName: string;
  toStatusName: string;
  statusChangeUser?: UserIndex;
};

type RegistrationLogType = {
  createdUser?: UserIndex;
  repairRegistrationNumber: string;
  faultRepairLogType: 'registration';
};

type FaultRepairLogsData = {
  faultRepairHashId: string;
  displayDate: Date;
} & (CommentLogType | StatusChangeLogType | RegistrationLogType);

export const useFaultRepairLogs = (
  faultRepairs: RepairIndex[],
  faultRepairLogs: FaultRepairLog[],
  logFilters: {
    userHashIds: string[];
    selectedOptions: string[];
  }
) => {
  const filteredFaultRepairs = useMemo(() => {
    return faultRepairs.filter((_item) => logFilters.userHashIds.length === 0);
  }, [faultRepairs, logFilters.userHashIds.length]);

  const loggedFaultRepairs: string[] = [];
  faultRepairLogs.forEach((item) => {
    if (!loggedFaultRepairs.includes(item.faultRepairHashId) && logFilters.userHashIds.length === 0) {
      loggedFaultRepairs.push(item.faultRepairHashId);
    }
  });

  if (logFilters.selectedOptions.length > 0 && !logFilters.selectedOptions.includes('repair')) {
    return {faultRepairLogData: [] as FaultRepairLogsData[]};
  }

  return {
    faultRepairLogData: [
      ...faultRepairLogs
        .filter(
          (repairLog) =>
            logFilters.userHashIds.length === 0 || logFilters.userHashIds.includes(repairLog.createdBy.hashId)
        )
        .map<FaultRepairLogsData>((repairLog) => {
          const typedLog: CommentLogType | StatusChangeLogType =
            repairLog.logType === 'status_change'
              ? {
                  faultRepairLogType: 'statusChange',
                  fromStatusName: repairLog.statusFromName ?? '',
                  toStatusName: repairLog.statusToName ?? '',
                  statusChangeUser: repairLog.createdBy,
                }
              : {
                  faultRepairLogType: 'comment',
                  comment: repairLog.description ?? '',
                  commentUser: repairLog.createdBy,
                };
          return {
            faultRepairHashId: repairLog.faultRepairHashId,
            displayDate: dayjs(repairLog.createdAt).toDate(),
            ...typedLog,
          };
        }),
      ...filteredFaultRepairs.map<FaultRepairLogsData>((item) => ({
        createdUser: item.createdBy,
        faultRepairHashId: item.hashId,
        displayDate: dayjs(item.createdAt).toDate(),
        faultRepairLogType: 'registration',
        repairRegistrationNumber: item.repairRegistrationNumber,
      })),
    ],
  };
};

/**
 * 機器管理の履歴を取得。(Mobile版)
 */
// TODO PC版と共通化する。UIコンポーネントがPC版と異なっているため、共通化にちょっと時間かかるため、見送っている。
export const useMobileMonthlyProductHistory = ({
  productHashId,
  hospitalProduct,
  userHashIds,
  selectedOptions,
}: {
  productHashId: string;
  hospitalProduct: HospitalProductDetail;
  userHashIds: string[];
  selectedOptions: string[];
}) => {
  const {myInfo} = useMyInfo();

  // const rentalQuery = useFetchHospitalProductRentalHistoriesQuery(myInfo.hospitalHashId, productHashId);
  const faultQuery = useFetchHospitalProductFaultRepairHistoriesQuery(myInfo.hospitalHashId, productHashId);
  const faultRepairLogQuery = useFetchHospitalProductFaultRepairLogsQuery(myInfo.hospitalHashId, {
    hospitalProductHashId: productHashId,
    perPage: 100,
  });
  const hospitalProductLogQuery = useFetchHospitalProductLogsQuery(myInfo.hospitalHashId, productHashId, {
    perPage: 100,
  });
  const {faultRepairLogData} = useFaultRepairLogs(faultQuery.data?.data ?? [], faultRepairLogQuery.data, {
    userHashIds,
    selectedOptions,
  });

  const {
    isLoading: faultReceptionLoading,
    faultReceptionDetails,
    faultReceptionLogsList,
  } = useFaultReceptionLogs(myInfo.hospitalHashId, faultQuery);

  const inspectionResultsQuery = useQuery(['inspectionResultsForTimeline', myInfo.hospitalHashId], async () => {
    if (!myInfo.hospitalHashId) return {totalCount: 0, page: 1, data: []};
    // NOTE: inspectionResultsのinspectionIdはdummyでも通る。
    return await getInspectionResults(myInfo.hospitalHashId, 'dummy', {
      hospitalProductHashId: productHashId,
      statuses: 'completed',
      order: '-updatedAt',
      perPage: 50,
    });
  });

  const isLoading = useMemo(
    () =>
      faultQuery.isLoading ||
      faultRepairLogQuery.isLoading ||
      hospitalProductLogQuery.isLoading ||
      inspectionResultsQuery.isLoading ||
      faultReceptionLoading,
    [
      faultQuery.isLoading,
      faultReceptionLoading,
      faultRepairLogQuery.isLoading,
      hospitalProductLogQuery.isLoading,
      inspectionResultsQuery.isLoading,
    ]
  );
  const hasError = useMemo(
    () =>
      faultQuery.isError ||
      faultRepairLogQuery.isError ||
      hospitalProductLogQuery.isError ||
      inspectionResultsQuery.isError,
    [faultQuery.isError, faultRepairLogQuery.isError, hospitalProductLogQuery.isError, inspectionResultsQuery.isError]
  );

  const [statusChangeLogs, commentLogs, rentalLogs, returnLogs, moveTargetBuilding] = useMemo(() => {
    if (isLoading && hasError) return [[], [], [], [], []];

    const groupByType = groupBy(hospitalProductLogQuery.data, 'logType');

    return [
      groupByType[repairLogTypeEnum.statusChange] ?? [],
      groupByType[repairLogTypeEnum.comment] ?? [],
      groupByType[repairLogTypeEnum.rental] ?? [],
      groupByType[repairLogTypeEnum.return] ?? [],
      groupByType[repairLogTypeEnum.moveTargetBuilding] ?? [],
    ];
  }, [hospitalProductLogQuery, isLoading, hasError]);

  const productHistory: HistoryCardProps[] = useMemo(() => {
    if (isLoading && hasError) return [];

    let historyCards: HistoryCardProps[] = [];
    const registration: HistoryCardProps = {
      cardType: 'registration',
      displayDate: new Date(hospitalProduct.createdAt),
      hospitalProduct: hospitalProduct,
    };

    historyCards = historyCards.concat(
      statusChangeLogs.map<HistoryCardProps>((item) => ({
        cardType: repairLogTypeEnum.statusChange,
        logHashId: item.hashId,
        user: item.createdBy,
        displayDate: new Date(item.createdAt),
        contentSlot: [
          {value: item.statusFrom, label: item.statusFrom},
          {value: item.statusTo, label: item.statusTo},
        ],
      }))
    );

    historyCards = historyCards.concat(
      commentLogs
        .filter((comment) => {
          return userHashIds.length === 0 || userHashIds.includes(comment.createdBy.hashId);
        })
        .map<HistoryCardProps>((item) => ({
          cardType: repairLogTypeEnum.comment,
          logHashId: item.hashId,
          pinned: item.pinned,
          user: item.createdBy,
          updateBy: item.updatedBy,
          updatedAt: item.updatedAt,
          displayDate: new Date(item.createdAt),
          description: item.description,
          isArchived: item.isArchived,
        }))
    );

    if (faultRepairLogData.length > 0) {
      historyCards = historyCards.concat(
        faultRepairLogData.map((item) =>
          item.faultRepairLogType === 'comment'
            ? {
                faultRepairHashId: item.faultRepairHashId,
                description: item.comment,
                cardType: 'repair_comment',
                displayDate: item.displayDate,
                user: item.commentUser,
              }
            : item.faultRepairLogType === 'statusChange'
              ? {
                  faultRepairHashId: item.faultRepairHashId,
                  contentSlot: [
                    {label: 'fromStatusName', value: item.fromStatusName},
                    {label: 'toStatusName', value: item.toStatusName},
                  ],
                  description: '',
                  cardType: 'repair_status_change',
                  displayDate: item.displayDate,
                  user: item.statusChangeUser,
                }
              : {
                  faultRepairHashId: item.faultRepairHashId,
                  description: '',
                  cardType: 'repair_registration',
                  displayDate: item.displayDate,
                  repairRegistrationNumber: item.repairRegistrationNumber,
                }
        )
      );
    }

    if (rentalLogs.length > 0) {
      historyCards = historyCards.concat(
        rentalLogs
          .filter((log) => userHashIds.length === 0 || userHashIds.includes(log.createdBy.hashId))
          .map((log) => {
            const deviceLenderUserName = getDeviceLenderName(log.deviceLender, log.createdBy);
            const displayDate = log.createdAt;
            const rentalRoom = log.rentalRoom?.name ?? '（貸出先未定）';

            return {
              user: log.createdBy,
              cardType: 'rental',
              title: (
                <div>
                  {deviceLenderUserName}さんが<span style={{fontWeight: 700}}>{rentalRoom}</span>
                  に貸出を行いました。
                </div>
              ),
              displayDate: new Date(displayDate),
              contentSlot: [
                {label: 'ステータス', value: '貸出中'},
                {
                  label: '貸出日',
                  value: dayjs(displayDate).format('YYYY年MM月DD日 HH:mm:ss'),
                },
                {
                  label: '持出者',
                  value: deviceLenderUserName,
                },
                {
                  label: '貸出先',
                  value: rentalRoom,
                },
              ],
            };
          })
      );
    }

    if (returnLogs.length > 0) {
      historyCards = historyCards.concat(
        returnLogs
          .filter((log) => userHashIds.length === 0 || userHashIds.includes(log.createdBy.hashId))
          .map((log) => {
            const deviceLenderUserName = getDeviceLenderName(log.deviceLender, log.createdBy);
            const displayDate = log.createdAt;

            return {
              user: log.createdBy,
              cardType: 'rental',
              title: <div>{deviceLenderUserName}さんが返却を行いました。</div>,
              displayDate: new Date(displayDate),
              contentSlot: [
                {label: 'ステータス', value: '返却済み'},
                {
                  label: '返却日',
                  value: dayjs(displayDate).format('YYYY年MM月DD日 HH:mm:ss'),
                },
                {
                  label: '返却者',
                  value: deviceLenderUserName,
                },
              ],
            };
          })
      );
    }

    // 転棟のログ表示
    if (moveTargetBuilding.length > 0) {
      historyCards = historyCards.concat(
        moveTargetBuilding
          .filter((log) => userHashIds.length === 0 || userHashIds.includes(log.createdBy.hashId))
          .map((log) => {
            const registererBy = log.registererBy;
            const fullName = registererBy ? `${UserFormatter.getFullName(registererBy)}` : '不明なユーザー';
            const displayDate = log.createdAt;
            const movedAt = log.movedAt ?? log.createdAt;

            const movedRoom = log.rentalRoom ? HospitalRoomFormatter.getFullRoom(log.rentalRoom) : '（転棟先未定）';
            return {
              user: log.createdBy,
              cardType: 'move_target_building',
              title: <RentalAndReturnCardTitle>転棟の実施</RentalAndReturnCardTitle>,
              displayDate: new Date(displayDate),
              contentSlot: [
                {label: '転棟先', value: `：${movedRoom}`},
                {
                  label: '転棟日時',
                  value: `：${dayjs(movedAt).format('YYYY年MM月DD日 HH:mm:ss')}`,
                },
                {
                  label: '登録者',
                  value: `：${fullName}`,
                },
              ],
            };
          })
      );
    }

    if (inspectionResultsQuery.data) {
      historyCards = historyCards.concat(
        inspectionResultsQuery.data.data
          .filter((item) => {
            return userHashIds.length === 0 || (item.inspector && userHashIds.includes(item.inspector.hashId));
          })
          .map((item) => {
            const inspector = item.inspector;
            const fullName = inspector ? `${UserFormatter.getFullName(inspector)}` : '不明なユーザー';
            const resultLink = `/inspections/${item.inspectionHashId}/result/${item.hashId}`;
            const inspectionTitle = `${item.type ? InspectionTypeMap[item.type]?.label ?? '点検' : '点検'}${
              item.isAdhocDate ? '（臨時）' : ''
            }`;
            return {
              user: inspector ?? undefined,
              cardType: 'inspection_result',
              title: (
                <div>
                  {fullName}さんが
                  <a
                    style={{fontWeight: 700, textDecoration: 'none', color: '#2A96E8'}}
                    target="_blank"
                    href={resultLink}
                    rel="noreferrer">
                    {inspectionTitle}
                  </a>
                  を行いました。
                </div>
              ),
              displayDate: new Date(item.completedAt),
              contentSlot: [
                {label: '結果', value: `${item.result === 'passed' ? '正常' : '異常あり'}`},
                {label: '点検完了日', value: dayjs(item.completedAt).format('YYYY年MM月DD日')},
                {label: '最終点検者', value: fullName},
                {label: '点検表', value: inspectionTitle},
              ],
            };
          })
      );
    }

    if (faultReceptionDetails.data.length > 0) {
      historyCards = historyCards.concat(
        faultReceptionDetails.data
          .filter((item) => userHashIds.length === 0 || userHashIds.includes(item.createdBy.hashId))
          .map((item) => {
            return {
              cardType: 'fault_reception',
              displayDate: new Date(item.createdAt),
              contentSlot: [
                {label: '受付番号：', value: item.receptionId ?? ''},
                {label: '登録者　：', value: item.reporterName ?? '不明な登録者'},
              ],
            };
          })
      );
    }

    if (faultReceptionLogsList.data.length > 0) {
      const faultReceptionLogs = faultReceptionLogsList.data.flatMap((item) => item.data);

      historyCards = historyCards.concat(
        faultReceptionLogs
          .filter((item) => userHashIds.length === 0 || userHashIds.includes(item.createdBy.hashId))
          .map((item) => {
            return {
              cardType: 'fault_reception_comment',
              displayDate: new Date(item.createdAt),
              contentSlot: [
                {label: '', value: item.description ?? ''},
                {label: '投稿者：', value: `${UserFormatter.getFullName(item.createdBy)}`},
              ],
            };
          })
      );
    }

    if (selectedOptions.length !== 0 && selectedOptions.length !== filterProductHistoryOptions.length) {
      historyCards = historyCards.filter(
        (historyCard) =>
          selectedOptions.includes(historyCard.cardType) ||
          ((historyCard.cardType === 'repair_registration' ||
            historyCard.cardType === 'repair_comment' ||
            historyCard.cardType === 'repair_status_change') &&
            selectedOptions.includes('repair'))
      );
    } else if (
      userHashIds.length === 0 ||
      userHashIds.includes(registration.hospitalProduct?.createdBy?.hashId ?? '')
    ) {
      historyCards.push(registration);
    }

    return historyCards.sort((a, b) => {
      if (a.displayDate < b.displayDate) return 1;
      if (a.displayDate > b.displayDate) return -1;
      return 0;
    });
  }, [
    isLoading,
    hasError,
    hospitalProduct,
    statusChangeLogs,
    commentLogs,
    faultRepairLogData,
    rentalLogs,
    returnLogs,
    moveTargetBuilding,
    inspectionResultsQuery.data,
    faultReceptionDetails.data,
    faultReceptionLogsList.data,
    selectedOptions,
    userHashIds,
  ]);

  /**
   * 月単位の機器管理履歴
   * @param section
   * @param {productHistories} productHistories
   */
  const monthlyProductHistory: {section: string; productHistories: HistoryCardProps[]}[] = useMemo(() => {
    const tmpHistory: {section: string; productHistories: HistoryCardProps[]}[] = [];
    const pinnedHistory: {section: string; productHistories: HistoryCardProps[]}[] = [];
    // ピンどめしたコメント
    productHistory
      .filter((item) => item.pinned)
      .forEach((item, index) => {
        if (index === 0) {
          pinnedHistory.push({
            section: 'ピンどめしたコメント',
            productHistories: [item],
          });
        } else {
          pinnedHistory[pinnedHistory.length - 1].productHistories.push(item);
        }
      });
    // ピンどめされていないコメント
    productHistory
      .filter((item) => !item.pinned)
      .forEach((item, index) => {
        if (index === 0 || tmpHistory[tmpHistory.length - 1].section !== dayjs(item.displayDate).format('MM月 YYYY')) {
          tmpHistory.push({
            section: dayjs(item.displayDate).format('MM月 YYYY'),
            productHistories: [item],
          });
        } else {
          tmpHistory[tmpHistory.length - 1].productHistories.push(item);
        }
      });
    return [...pinnedHistory, ...tmpHistory];
  }, [productHistory]);

  return {monthlyProductHistory, hospitalProductLogQuery};
};

/**
 * 機器管理の履歴を取得。(PC/タブレット版)
 */
export const useMonthlyProductHistory = ({
  productHashId,
  hospitalProduct,
  userHashIds,
  selectedOptions,
}: {
  productHashId: string;
  hospitalProduct: HospitalProductDetail;
  userHashIds: string[];
  selectedOptions: string[];
}) => {
  const {myInfo} = useMyInfo();

  const faultQuery = useFetchHospitalProductFaultRepairHistoriesQuery(myInfo.hospitalHashId, productHashId);

  const faultRepairLogQuery = useFetchHospitalProductFaultRepairLogsQuery(myInfo.hospitalHashId, {
    hospitalProductHashId: productHashId,
    perPage: 100,
  });
  const hospitalProductLogQuery = useFetchHospitalProductLogsQuery(myInfo.hospitalHashId, productHashId, {
    perPage: 100,
  });

  const {faultRepairLogData} = useFaultRepairLogs(faultQuery.data?.data ?? [], faultRepairLogQuery.data, {
    userHashIds,
    selectedOptions,
  });

  const {
    isLoading: faultReceptionLoading,
    faultReceptionDetails,
    faultReceptionLogsList,
  } = useFaultReceptionLogs(myInfo.hospitalHashId, faultQuery);

  const inspectionResultsQuery = useQuery(['inspectionResultsForTimeline', myInfo.hospitalHashId], async () => {
    if (!myInfo.hospitalHashId) return {totalCount: 0, page: 1, data: []};
    // NOTE: inspectionResultsのinspectionIdはdummyでも通る。
    return await getInspectionResults(myInfo.hospitalHashId, 'dummy', {
      hospitalProductHashId: productHashId,
      statuses: 'completed',
      order: '-updatedAt',
      perPage: 50,
    });
  });

  const isLoading = useMemo(
    () =>
      faultQuery.isLoading ||
      faultRepairLogQuery.isLoading ||
      hospitalProductLogQuery.isLoading ||
      inspectionResultsQuery.isLoading ||
      faultReceptionLoading,
    [
      faultQuery.isLoading,
      faultRepairLogQuery.isLoading,
      hospitalProductLogQuery.isLoading,
      inspectionResultsQuery.isLoading,
      faultReceptionLoading,
    ]
  );
  const hasError = useMemo(
    () =>
      faultQuery.isError ||
      faultRepairLogQuery.isError ||
      hospitalProductLogQuery.isError ||
      inspectionResultsQuery.isError,
    [faultQuery.isError, faultRepairLogQuery.isError, hospitalProductLogQuery.isError, inspectionResultsQuery.isError]
  );

  const [statusChangeLogs, commentLogs, rentalLogs, returnLogs, moveTargetBuilding] = useMemo(() => {
    if (isLoading && hasError) return [[], [], [], [], []];

    const groupByType = groupBy(hospitalProductLogQuery.data, 'logType');
    return [
      groupByType[repairLogTypeEnum.statusChange] ?? [],
      groupByType[repairLogTypeEnum.comment] ?? [],
      groupByType[repairLogTypeEnum.rental] ?? [],
      groupByType[repairLogTypeEnum.return] ?? [],
      groupByType[repairLogTypeEnum.moveTargetBuilding] ?? [],
    ];
  }, [hospitalProductLogQuery, isLoading, hasError]);

  const productHistory: HistoryCardProps[] = useMemo(() => {
    if (isLoading && hasError) return [];

    let historyCards: HistoryCardProps[] = [];
    const registration: HistoryCardProps = {
      cardType: 'registration',
      displayDate: new Date(hospitalProduct.createdAt),
      hospitalProduct: hospitalProduct,
    };

    historyCards = historyCards.concat(
      statusChangeLogs.map<HistoryCardProps>((item) => ({
        cardType: repairLogTypeEnum.statusChange,
        logHashId: item.hashId,
        user: item.createdBy,
        displayDate: new Date(item.createdAt),
        contentSlot: [
          {value: item.statusFrom, label: item.statusFrom},
          {value: item.statusTo, label: item.statusTo},
        ],
      }))
    );

    historyCards = historyCards.concat(
      commentLogs
        .filter((comment) => {
          return userHashIds.length === 0 || userHashIds.includes(comment.createdBy.hashId);
        })
        .map<HistoryCardProps>((item) => ({
          cardType: repairLogTypeEnum.comment,
          logHashId: item.hashId,
          pinned: item.pinned,
          user: item.createdBy,
          updateBy: item.updatedBy,
          updatedAt: item.updatedAt,
          displayDate: new Date(item.createdAt),
          description: item.description,
          isArchived: item.isArchived,
        }))
    );

    if (faultRepairLogData.length > 0) {
      historyCards = historyCards.concat(
        faultRepairLogData.map((item) =>
          item.faultRepairLogType === 'comment'
            ? {
                faultRepairHashId: item.faultRepairHashId,
                description: item.comment,
                cardType: 'repair_comment',
                displayDate: item.displayDate,
                user: item.commentUser,
              }
            : item.faultRepairLogType === 'statusChange'
              ? {
                  faultRepairHashId: item.faultRepairHashId,
                  contentSlot: [
                    {label: 'fromStatusName', value: item.fromStatusName},
                    {label: 'toStatusName', value: item.toStatusName},
                  ],
                  description: '',
                  cardType: 'repair_status_change',
                  displayDate: item.displayDate,
                  user: item.statusChangeUser,
                }
              : {
                  user: item.createdUser,
                  faultRepairHashId: item.faultRepairHashId,
                  description: '',
                  cardType: 'repair_registration',
                  displayDate: item.displayDate,
                  repairRegistrationNumber: item.repairRegistrationNumber,
                }
        )
      );
    }

    if (rentalLogs.length > 0) {
      historyCards = historyCards.concat(
        rentalLogs
          .filter((log) => userHashIds.length === 0 || userHashIds.includes(log.createdBy.hashId))
          .map((log) => {
            const deviceLenderUserName = getDeviceLenderName(log.deviceLender, log.createdBy);
            const displayDate = log.createdAt;
            const rentalRoom = log.rentalRoom?.name ?? '（貸出先未定）';

            return {
              user: log.createdBy,
              cardType: 'rental',
              title: <RentalAndReturnCardTitle>貸出の実施</RentalAndReturnCardTitle>,
              displayDate: new Date(displayDate),
              contentSlot: [
                {
                  label: '貸出先',
                  value: `：${rentalRoom}`,
                },
                {
                  label: '貸出日',
                  value: `：${dayjs(displayDate).format('YYYY年MM月DD日 HH:mm:ss')}`,
                },
                {
                  label: '持出者',
                  value: `：${deviceLenderUserName}`,
                },
              ],
            };
          })
      );
    }

    if (returnLogs.length > 0) {
      historyCards = historyCards.concat(
        returnLogs
          .filter((log) => userHashIds.length === 0 || userHashIds.includes(log.createdBy.hashId))
          .map((log) => {
            const deviceLenderUserName = getDeviceLenderName(log.deviceLender, log.createdBy);
            const displayDate = log.createdAt;

            const returnRoom = log.rentalRoom?.name ?? '（返却元未定）';

            return {
              user: log.createdBy,
              cardType: 'return',
              title: <RentalAndReturnCardTitle>返却の実施</RentalAndReturnCardTitle>,
              displayDate: new Date(displayDate),
              contentSlot: [
                {label: '返却元', value: `：${returnRoom}`},
                {
                  label: '返却日時',
                  value: `：${dayjs(displayDate).format('YYYY年MM月DD日 HH:mm:ss')}`,
                },
                {
                  label: '返却者',
                  value: `：${deviceLenderUserName}`,
                },
              ],
            };
          })
      );
    }

    // 転棟のログ表示
    if (moveTargetBuilding.length > 0) {
      historyCards = historyCards.concat(
        moveTargetBuilding
          .filter((log) => userHashIds.length === 0 || userHashIds.includes(log.createdBy.hashId))
          .map((log) => {
            const registererBy = log.registererBy;
            const fullName = registererBy ? `${UserFormatter.getFullName(registererBy)}` : '不明なユーザー';
            const displayDate = log.createdAt;
            const movedAt = log.movedAt ?? log.createdAt;

            const movedRoom = log.rentalRoom ? HospitalRoomFormatter.getFullRoom(log.rentalRoom) : '（転棟先未定）';
            return {
              user: log.createdBy,
              cardType: 'move_target_building',
              title: <RentalAndReturnCardTitle>転棟の実施</RentalAndReturnCardTitle>,
              displayDate: new Date(displayDate),
              contentSlot: [
                {label: '転棟先', value: `：${movedRoom}`},
                {
                  label: '転棟日時',
                  value: `：${dayjs(movedAt).format('YYYY年MM月DD日 HH:mm:ss')}`,
                },
                {
                  label: '登録者',
                  value: `：${fullName}`,
                },
              ],
            };
          })
      );
    }

    if (inspectionResultsQuery.data) {
      historyCards = historyCards.concat(
        inspectionResultsQuery.data.data
          .filter((item) => {
            return userHashIds.length === 0 || (item.inspector && userHashIds.includes(item.inspector.hashId));
          })
          .map((item) => {
            const inspector = item.inspector;
            const fullName = inspector ? `${UserFormatter.getFullName(inspector)}` : '不明なユーザー';
            const resultLink = `/inspections/${item.inspectionHashId}/result/${item.hashId}`;
            const inspectionTitle = `${item.type ? InspectionTypeMap[item.type]?.label ?? '点検' : '点検'}${
              item.isAdhocDate ? '（臨時）' : ''
            }`;
            return {
              user: inspector ?? undefined,
              cardType: 'inspection_result',
              title: (
                <div>
                  <a
                    style={{fontWeight: 700, textDecoration: 'none', color: '#0052CC'}}
                    target="_blank"
                    href={resultLink}
                    rel="noreferrer">
                    {inspectionTitle}
                  </a>
                  の実施
                </div>
              ),
              displayDate: new Date(item.completedAt),
              contentSlot: [
                {label: '結果', value: `：${item.result === 'passed' ? '正常' : '異常あり'}`},
                {label: '点検完了日', value: `：${dayjs(item.completedAt).format('YYYY年MM月DD日 HH:mm')}`},
                {label: '最終点検者', value: `：${fullName}`},
              ],
            };
          })
      );
    }
    if (faultReceptionDetails.data.length > 0) {
      historyCards = historyCards.concat(
        faultReceptionDetails.data
          .filter((item) => userHashIds.length === 0 || userHashIds.includes(item.createdBy.hashId))
          .map((item) => {
            return {
              cardType: 'fault_reception',
              icon: <Build />,
              title: (
                <TimeLineTitle>
                  <Link href={`/repairs/fault_receptions/${item.hashId}`} target="_blank" rel="noreferrer">
                    不具合受付
                  </Link>
                  の登録
                </TimeLineTitle>
              ),
              displayDate: new Date(item.createdAt),
              contentSlot: [
                {label: '受付番号：', value: item.receptionId ?? ''},
                {label: '登録者　：', value: item.reporterName ?? '不明な登録者'},
              ],
            };
          })
      );
    }

    if (faultReceptionLogsList.data.length > 0) {
      const faultReceptionLogs = faultReceptionLogsList.data.flatMap((item) => item.data);

      historyCards = historyCards.concat(
        faultReceptionLogs
          .filter((item) => userHashIds.length === 0 || userHashIds.includes(item.createdBy.hashId))
          .map((item) => {
            return {
              cardType: 'fault_reception_comment',
              icon: <Build />,
              title: (
                <TimeLineTitle>
                  <Link
                    href={`/repairs/fault_receptions/${item.faultReceptionHashId}`}
                    target="_blank"
                    rel="noreferrer">
                    不具合受付
                  </Link>
                  のコメント
                </TimeLineTitle>
              ),
              displayDate: new Date(item.createdAt),
              contentSlot: [
                {label: '', value: item.description ?? ''},
                {label: '投稿者：', value: `${UserFormatter.getFullName(item.createdBy)}`},
              ],
            };
          })
      );
    }

    if (selectedOptions.length !== 0 && selectedOptions.length !== filterProductHistoryOptions.length) {
      historyCards = historyCards.filter(
        (historyCard) =>
          selectedOptions.includes(historyCard.cardType) ||
          ((historyCard.cardType === 'repair_registration' ||
            historyCard.cardType === 'repair_comment' ||
            historyCard.cardType === 'repair_status_change') &&
            selectedOptions.includes('repair')) ||
          ((historyCard.cardType === 'rental' || historyCard.cardType === 'return') &&
            selectedOptions.includes('rental'))
      );
    } else if (
      userHashIds.length === 0 ||
      userHashIds.includes(registration.hospitalProduct?.createdBy?.hashId ?? '')
    ) {
      historyCards.push(registration);
    }

    return historyCards.sort((a, b) => {
      if (a.displayDate < b.displayDate) return 1;
      if (a.displayDate > b.displayDate) return -1;
      return 0;
    });
  }, [
    isLoading,
    hasError,
    hospitalProduct,
    statusChangeLogs,
    commentLogs,
    faultRepairLogData,
    rentalLogs,
    returnLogs,
    moveTargetBuilding,
    inspectionResultsQuery.data,
    faultReceptionDetails.data,
    faultReceptionLogsList.data,
    selectedOptions,
    userHashIds,
  ]);

  /**
   * 月単位の機器管理履歴
   * @param section
   * @param {productHistories} productHistories
   */
  const monthlyProductHistory: {section: string; productHistories: HistoryCardProps[]}[] = useMemo(() => {
    const tmpHistory: {section: string; productHistories: HistoryCardProps[]}[] = [];
    const pinnedHistory: {section: string; productHistories: HistoryCardProps[]}[] = [];
    // 'ピンどめしたコメント'のエリアに表示するコメント
    productHistory
      .filter((item) => item.pinned)
      .forEach((item, index) => {
        const copiedItem = Object.assign({}, item);
        copiedItem.isPinnedArea = true;
        if (index === 0) {
          pinnedHistory.push({
            section: 'ピンどめしたコメント',
            productHistories: [copiedItem],
          });
        } else {
          pinnedHistory[pinnedHistory.length - 1].productHistories.push(copiedItem);
        }
      });
    // 月単位のエリアに表示（ピンどめしたコメントも含む）
    productHistory.forEach((item, index) => {
      if (index === 0 || tmpHistory[tmpHistory.length - 1].section !== dayjs(item.displayDate).format('MM月 YYYY')) {
        tmpHistory.push({
          section: dayjs(item.displayDate).format('MM月 YYYY'),
          productHistories: [item],
        });
      } else {
        tmpHistory[tmpHistory.length - 1].productHistories.push(item);
      }
    });
    return [...pinnedHistory, ...tmpHistory];
  }, [productHistory]);

  return {monthlyProductHistory, hospitalProductLogQuery};
};

/**
 * 不具合受付のログ取得(コメント、登録)
 * @param hospitalHashId - 病院のハッシュID
 * @param faultQuery - 修理情報
 * @returns ローディングの状態と不具合受付の詳細情報
 */
const useFaultReceptionLogs = (
  hospitalHashId: string,
  faultQuery: UseQueryResult<FetchHospitalProductFaultRepairHistoriesResult, unknown>
) => {
  const faultReceptionHashIds =
    faultQuery.data?.data?.reduce((acc: string[], item) => {
      if (!isNullish(item.faultReceptionHashId)) {
        acc.push(item.faultReceptionHashId);
      }
      return acc;
    }, [] as string[]) || [];

  const faultReceptionDetails = useFaultReceptionDetails(hospitalHashId, faultReceptionHashIds);
  const faultReceptionLogsList = useFetchFaultReceptionLogsListQuery(hospitalHashId, faultReceptionHashIds);

  return {
    isLoading: faultQuery.isLoading || faultReceptionDetails.isLoading || faultReceptionLogsList.isLoading,
    faultReceptionDetails,
    faultReceptionLogsList,
  };
};

/**
 * 機器登録画面に現在の情報をコピーした上で遷移するためのhooks.
 * 原則全ての項目を複製しますが、一部の項目については下記の要件としています.
 *  - 稼働状況：待機中で固定
 *  - 廃棄日：空欄
 *  - 廃棄理由：空欄
 * (管理番号についても複製するため、複製先の画面にて管理番号の重複チェックが発生する可能性があります)
 * @returns {(hospitalProduct: HospitalProductDetail) => void} - 機器登録画面に現在の情報をコピーした上で遷移する関数
 */
export const useNavigateProductRegistrationWithCopy = () => {
  const setProducts = useSetAtom(productsAtom);
  const navigate = useNavigate();
  const navigateWithCopy = (hospitalProduct: HospitalProductDetail) => {
    setProducts([
      {
        ...hospitalProduct,

        uuid: uuidv4(),

        // 型が違うため、明示的に指定
        rawBarcode: hospitalProduct.gs1Barcode ?? '',
        hospitalRoomHashId: hospitalProduct.hospitalRoomHashId ?? null,
        hospitalDealerHashId: hospitalProduct.hospitalDealerHashId ?? null,
        hospitalDepartmentHashId: hospitalProduct.hospitalDepartmentHashId ?? null,
        periodicInspectionStartDate: hospitalProduct.periodicInspectionStartDate ?? null,
        isBaseUnit: hospitalProduct.isBaseUnit ?? 'notBaseUnit',

        // 固定値
        status: 'ready' as ProductStatusConst,
        dateOfDisposal: null,
        reasonOfDisposal: null,
      },
    ]);
    navigate(`/products/registration/Step2/${hospitalProduct.wholeProductHashId}`, {state: {fromCopy: true}});
  };
  return {navigateWithCopy};
};
