import React, {useCallback, useMemo, useRef, useState} from 'react';
import {Document, Page, pdfjs} from 'react-pdf';

import {Grid, Typography, styled} from '@mui/material';
import {InnerLoading} from '../../Loading';

const WrapperDiv = styled('div')({
  overflow: 'auto',
  height: '100%',
  width: '100%',
});

type Props = {
  src: string;
  scale: number;
};

const ErrorTypo = () => {
  return (
    <Grid
      container
      sx={{padding: '16px', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
      <Typography variant="h5">PDFファイルが読み込めません</Typography>
    </Grid>
  );
};

/**
 * Tablet向けのPDFビュワー。拡大縮小機能を含む
 * @param {string} props.src - 表示するpdfのURL
 * @returns
 */
export const PDFDisplayForTablet: React.FC<Props> = ({src, scale}) => {
  const [numPages, setNumPages] = useState<number>();
  const wrapRef = useRef<HTMLDivElement>(null);
  const documentOptions = useMemo(
    () => ({
      // cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
      // NOTE:2024/06/25時点の最新バージョン pdfjsのバージョンが9以降になったら上のコメントアウトを外して実機確認
      cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@4.3.136/cmaps/`,
      cMapPacked: true,
    }),
    []
  );

  const wrapWidth = useCallback(() => {
    return wrapRef.current ? wrapRef.current.clientWidth - 1 : 0;
  }, [wrapRef]);

  const renderAllPages = () => {
    return Array.from(new Array(numPages), (el, index) => (
      <Page
        width={scale * wrapWidth()}
        renderTextLayer={false}
        renderAnnotationLayer={false}
        key={`page_${index + 1}`}
        pageNumber={index + 1}
      />
    ));
  };

  return (
    <WrapperDiv ref={wrapRef}>
      <Document
        file={src}
        onLoadSuccess={(document) => {
          setNumPages(document.numPages);
        }}
        error={<ErrorTypo />}
        loading={<InnerLoading />}
        options={documentOptions}>
        {renderAllPages()}
      </Document>
    </WrapperDiv>
  );
};
