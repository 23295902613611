import {
  TrainingScheduleState,
  TrainingScheduleAction,
  TrainingScheduleActionType,
} from '@modules/training_schedules/types';

export const trainingScheduleInitialState: TrainingScheduleState = {
  schedules: [],
};

export const trainingScheduleReducer = (
  state: TrainingScheduleState,
  action: TrainingScheduleAction
): TrainingScheduleState => {
  switch (action.type) {
    case TrainingScheduleActionType.TRAINING_SCHEDULE_SET_LIST: {
      return {
        ...state,
        schedules: action.payload.schedules,
      };
    }
    default:
      return state;
  }
};
