import {Atom, atom, useAtomValue} from 'jotai';
import {selectAtom, useReducerAtom} from 'jotai/utils';
import {useCallback, useMemo} from 'react';

export type GeneralFileListState = {
  initialized: boolean;
  searchWord?: string;
  perPage: number;
  page: number;
  order?: string;
};

export const initialState: GeneralFileListState = {
  initialized: false,
  searchWord: undefined,
  perPage: 100,
  page: 0,
  order: '-createdAt',
};

export const generalFileListStateAtom = atom<GeneralFileListState>({...initialState});

// ActionCreator
export const createSetInitializedAction = (initialized: boolean) => ({
  type: 'SET_INITIALIZED' as const,
  initialized,
});

export const createChangeSearchWordAction = (searchWord: string) => ({
  type: 'CHANGE_SEARCH_WORD' as const,
  searchWord: searchWord === '' ? undefined : searchWord,
});

export const createChangePerPageAction = (perPage: number) => ({
  type: 'CHANGE_PER_PAGE' as const,
  perPage,
});

export const createChangePageAction = (page: number) => ({
  type: 'CHANGE_PAGE' as const,
  page,
});

export const createChangeOrderAction = (order: string | undefined) => ({
  type: 'CHANGE_ORDER' as const,
  order,
});

// Action
export type Actions =
  | ReturnType<typeof createSetInitializedAction>
  | ReturnType<typeof createChangeSearchWordAction>
  | ReturnType<typeof createChangePerPageAction>
  | ReturnType<typeof createChangePageAction>
  | ReturnType<typeof createChangeOrderAction>;

// Reducer
const generalFileListStateReducer = (prev: GeneralFileListState, action: Actions): GeneralFileListState => {
  switch (action.type) {
    case 'SET_INITIALIZED':
      return {...prev, initialized: action.initialized};
    case 'CHANGE_SEARCH_WORD':
      return {...prev, searchWord: action.searchWord};
    case 'CHANGE_PER_PAGE':
      return {...prev, perPage: action.perPage};
    case 'CHANGE_PAGE':
      return {...prev, page: action.page};
    case 'CHANGE_ORDER':
      return {...prev, order: action.order};
    default:
      console.warn('undefined action is dispatched', action);
      return prev;
  }
};

// helper
export const useGeneralFileListState = () => useReducerAtom(generalFileListStateAtom, generalFileListStateReducer);

const useMemoizedDispatch = () => {
  const [, dispatch] = useGeneralFileListState();
  return useMemo(() => dispatch, [dispatch]);
};

const useSelectState = <T, U = T>(valueAtom: Atom<T>, actionCreator: (value: U) => Actions) => {
  const value = useAtomValue(valueAtom);
  const dispatch = useMemoizedDispatch();
  const update = useCallback((x: U) => dispatch(actionCreator(x)), [actionCreator, dispatch]);
  return [value, update] as const;
};

// hooks
const _initializedAtom = selectAtom(generalFileListStateAtom, (state) => state.initialized);
export const useInitialized = () =>
  useSelectState(_initializedAtom, (initialized: boolean) => createSetInitializedAction(initialized));

const _searchWordAtom = selectAtom(generalFileListStateAtom, (state) => state.searchWord);
export const useSearchWord = () =>
  useSelectState(_searchWordAtom, (searchWord: string) => createChangeSearchWordAction(searchWord));

const _perPageAtom = selectAtom(generalFileListStateAtom, (state) => state.perPage);
export const usePerPage = () => useSelectState(_perPageAtom, (perPage: number) => createChangePerPageAction(perPage));

const _pageAtom = selectAtom(generalFileListStateAtom, (state) => state.page);
export const usePage = () => useSelectState(_pageAtom, (page: number) => createChangePageAction(page));

const _orderAtom = selectAtom(generalFileListStateAtom, (state) => state.order);
export const useOrder = () => useSelectState(_orderAtom, (order: string | undefined) => createChangeOrderAction(order));

export const useQueryVariables = () => {
  const [page, updatePage] = usePage();
  const [perPage, updatePerPage] = usePerPage();
  const [searchWord, updateSearchWord] = useSearchWord();
  const [order, updateOrder] = useOrder();

  return {
    page,
    perPage,
    searchWord,
    order,
    updatePage,
    updatePerPage,
    updateSearchWord,
    updateOrder,
  };
};
