import React, {useMemo} from 'react';
import {Table} from '@molecules/Table';
import {Column} from '@molecules/Table/props';
import {TableLayout, TableLayoutResult} from '@modules/table_layout/hooks/useTableLayout';
import {FaultReception} from '@modules/fault_receptions/types';
import {useNavigate} from 'react-router-dom';
import {FaultReceptionStatusBadge} from '@components/atoms/FaultReceptionStatusBadge';
import {isNullish} from '@front-libs/helpers';
import dayjs from 'dayjs';
import {LocationColumn} from '@Apps/BaseSharedMenu/FaultReceptionHistory/common/LocationColumn';

type Props = {
  data: FaultReception[];
  handleOrderChange: (columnIndex: number, orderDirection: 'asc' | 'desc') => void;
  tableLayout: TableLayoutResult;
};

export const FaultReceptionTable = (props: Props) => {
  const {data, tableLayout, handleOrderChange} = props;
  const navigate = useNavigate();

  const handleClickRow = (_event?: React.MouseEvent, rowData?: FaultReception) => {
    if (isNullish(rowData)) {
      return;
    }
    navigate(`/repairs/fault_receptions/${rowData.hashId}`);
  };

  const serializedTableColumn = useMemo(() => {
    const tableColumn = Object.assign<Column<FaultReception>[], TableLayout[]>([], tableLayout?.currentLayout ?? []);
    return tableColumn.map<Column<FaultReception>>((item) => {
      switch (item.field) {
        case 'productFeature':
          item.render = ({productFeature}: FaultReception) => truncateText(productFeature);
          break;
        case 'faultDetail':
          item.render = ({faultDetail}: FaultReception) => truncateText(faultDetail);
          break;
        case 'rentalRoomName':
          item.render = ({rentalRoomName}: FaultReception) => truncateText(rentalRoomName);
          break;
        case 'reporterName':
          item.render = ({reporterName}: FaultReception) => truncateText(reporterName);
          break;
        case 'status':
          item.render = ({status}: FaultReception) => FaultReceptionStatusBadge({status}) ?? '';
          break;
        case 'createdAt':
          item.render = ({createdAt}: FaultReception) => dayjs(createdAt).format('YYYY-MM-DD HH:mm');
          break;
        case 'isNeedAlternativeDevice':
          item.render = ({isNeedAlternativeDevice}: FaultReception) => (isNeedAlternativeDevice ? '必要' : '不要');
          break;
        case 'isNeedUrgentResponse':
          item.render = ({isNeedUrgentResponse}: FaultReception) => (isNeedUrgentResponse ? '必要' : '不要');
          break;
        case 'location':
          item.render = ({location, locationName}: FaultReception) => LocationColumn({location, locationName});
          break;
        default:
          break;
      }
      item.noBodyWrap = true;
      return item;
    });
  }, [tableLayout?.currentLayout]);

  return (
    <Table<FaultReception>
      stickyHeader={true}
      columns={serializedTableColumn}
      data={data}
      showSelection={false}
      onOrderChange={handleOrderChange}
      onRowClick={handleClickRow}
      tableSize="small"
    />
  );
};

const truncateText = (text: string): string => (text.length > 10 ? `${text.slice(0, 10)}…` : text);
