import {Sidebar} from '@components/organisms/Sidebar';
import {getSettingsContentTemplate as templateClasses} from '@components/templates/ContentLayout/InnerSidebarContentLayoutV5';
import {withSuspense} from '@front-libs/core';
import {Box, Button, Grid, Typography, styled} from '@mui/material';
import React from 'react';

const HospitalInfo = () => {
  return (
    <Box sx={templateClasses.form}>
      <Grid container sx={templateClasses.grid}>
        <Grid item sx={templateClasses.sideBar}>
          <Sidebar />
        </Grid>
        <Grid item sx={templateClasses.content}>
          <PageTitleContainerGrid container>
            <Grid item>
              <Typography variant={'h5'} sx={templateClasses.pageTitle}>
                病院情報
              </Typography>
            </Grid>
          </PageTitleContainerGrid>
          <ItemGrid>病院情報はHITOTSU Hospital で閲覧、変更できます。</ItemGrid>
          <Grid>
            <a href={import.meta.env.VITE_ACCOUNT_SERVICE_URL} target="_blank" rel="noreferrer">
              <Button variant="contained" color="primary">
                HITOTSU Hospitalへ移動
              </Button>
            </a>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const PageTitleContainerGrid = styled(Grid)({
  marginBottom: '32px',
});
const ItemGrid = styled(Grid)({
  marginRight: '32px',
  color: '#172B4D',
  fontSize: '20px',
  marginBottom: '24px',
});

const _SettingsHospitalInfo: React.FC = () => {
  return (
    <Grid container sx={templateClasses.grid}>
      <HospitalInfo />
    </Grid>
  );
};

export const SettingsHospitalInfo = withSuspense(_SettingsHospitalInfo, null);
