import {axios} from '@front-libs/core';
import {useQuery} from 'react-query';
import {getRentalsKey} from '@constants/api';
import {useMemo} from 'react';
import {RentalHistoryIndex} from '@modules/rentals/types';
import {RentalStatusEnum, RequestExportRental} from './type';

export type FetchRentalsParams = {
  rentalUserHashId?: string;
  status?: RentalStatusEnum;
  hospitalWardHashId?: string;
  hospitalRoomHashId?: string;

  checkoutAtFrom?: string;
  checkoutAtTo?: string;
  returnedAtFrom?: string;
  returnedAtTo?: string;

  name?: string;
  managementId?: string;

  page?: number;
  perPage?: number;

  order?: string;
};

export type FetchRentalsResult = {
  totalCount: number;
  page: number;
  data: RentalHistoryIndex[];
};

export type DeviceLender = {
  hashId?: string;
  userId?: string;
  userName?: string;
};

export type CreateRentalData = {
  hospitalProductHashId: string;
  checkoutAt?: Date;
  checkoutUserHashId: string;
  hospitalRoomHashId?: string;
  returnAt?: Date;
  returnUserHashId?: string;
  forceRental?: boolean;
  operatorName?: string;
  checkoutDeviceLender?: DeviceLender;
};

export type UpdateRentalData = {
  rentalHashId: string;
  hospitalProductHashId?: string;
  checkoutAt?: Date;
  checkoutUserHashId?: string;
  hospitalRoomHashId?: string;
  returnAt?: Date;
  returnUserHashId?: string;
  checkoutDeviceLender?: DeviceLender;
  returnDeviceLender?: DeviceLender;
};

export const getRentals = async (hospitalHashId: string, params: FetchRentalsParams) => {
  const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/rentals`;
  const {data} = await axios.get<FetchRentalsResult>(baseUrl, {params});
  return data;
};

export const useFetchRentalsQuery = (hospitalHashId: string, params: FetchRentalsParams) => {
  const query = useQuery([getRentalsKey, hospitalHashId, params], () => getRentals(hospitalHashId, params));
  return useMemo(
    () => ({
      ...query,
      data: query.data?.data ?? [],
      totalCount: query.data?.totalCount ?? 0,
    }),
    [query]
  );
};

export const bulkCreateRentals = async (hospitalHashId: string, data: CreateRentalData[]) => {
  const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/rentals/jobs`;
  await axios.post(baseUrl, {rentalHospitalProducts: data});
};

export const bulkUpdateRentals = async (hospitalHashId: string, data: UpdateRentalData[]) => {
  const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/rentals/jobs`;
  await axios.put(baseUrl, {rentalHospitalProducts: data});
};

export const requestExportRentals = async (hospitalHashId: string, request: RequestExportRental) => {
  const baseURL = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/rentals/tasks/export`;
  return await axios.post(baseURL, request);
};
