import {formSection} from '@Apps/RepairDetail/constants';
import {Sidebar} from '@components/organisms/Sidebar';
import {getSettingsContentTemplate as templateClasses} from '@components/templates/ContentLayout/InnerSidebarContentLayoutV5';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import {useHospitalDepartmentsOptions} from '@modules/hospital_departments/hooks';
import {useHospitalUsers} from '@modules/hospital_users/hooks/useHospitalUsers';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {
  createFaultRepairPropertyRequirement,
  deleteFaultRepairPropertyRequirement,
  resequenceFaultRepairPropertyRequirements,
  updateFaultRepairPropertyRequirement,
} from '@modules/repairs/api/propertyRequirementsApi';
import {useFaultRepairPropertyRequirements, useFetchFaultRepairStatuses} from '@modules/repairs/hooks';
import {FetchFaultRepairPropertyRequrementsParams, PropertyRequirements, RepairIndex} from '@modules/repairs/types';
import Selector from '@molecules/Formik/fields/Selector';
import {InnerLoading} from '@molecules/Loading';
import {openSnackBar} from '@molecules/SnackBar';
import {Box, Button, Divider, FormHelperText, Grid, Switch, Theme, Typography, SxProps, styled} from '@mui/material';

import {Form, Formik, useFormikContext} from 'formik';
import React, {useCallback, useMemo, useState} from 'react';
import {DragDropContext, Draggable, DropResult, Droppable} from 'react-beautiful-dnd';
import {ExtendedPropertyRequirements, GeneratedFieldObjectsType, ItemListType, ItemProps, ItemType} from './types';

export const SettingsHospitalRepair: React.FC = () => {
  return (
    <Grid container sx={templateClasses.grid}>
      <RepairContainer>
        <RepairForm />
      </RepairContainer>
    </Grid>
  );
};

const RepairContainer: React.FC = ({children}) => {
  const {myInfo} = useMyInfo();
  const statusQuery = useFetchFaultRepairStatuses(myInfo.hospitalHashId);
  const startStatusHashId = useMemo(
    () => statusQuery.data.find((status) => status.statusType === 'start')?.hashId,
    [statusQuery]
  );

  const params: FetchFaultRepairPropertyRequrementsParams = {
    faultRepairStatusHashID: startStatusHashId,
    order: 'sequence',
  };
  const {data, isLoading, refetch} = useFaultRepairPropertyRequirements(params);

  const handleSubmit = async (res: ExtendedPropertyRequirements) => {
    try {
      if (res.propertyName && startStatusHashId) {
        await createFaultRepairPropertyRequirement(myInfo.hospitalHashId, {
          faultRepairStatusHashId: startStatusHashId,
          property: res.propertyName,
        });
      }
      if (res.targetProperty) {
        await resequenceFaultRepairPropertyRequirements(
          myInfo.hospitalHashId,
          data?.find((d) => d.property === res.targetProperty?.property)?.hashId ?? '',
          {sequence: res.targetProperty.newSequence}
        );
      }
      if (res.switchedProperty) {
        await updateFaultRepairPropertyRequirement(
          myInfo.hospitalHashId,
          data?.find((d) => d.property === res.switchedProperty?.property)?.hashId ?? '',
          {isRequired: res.switchedProperty.isRequired}
        );
      }
      if (res.deleteProperty) {
        await deleteFaultRepairPropertyRequirement(
          myInfo.hospitalHashId,
          data?.find((d) => d.property === res.deleteProperty?.property)?.hashId ?? ''
        );
      }

      await refetch();
      openSnackBar('修理の設定を更新しました');
    } catch (error) {
      openSnackBar('修理の設定の更新に失敗しました', 'left', 'bottom', 'error');
      throw error;
    }
  };

  if (isLoading) {
    return <InnerLoading />;
  }

  return (
    <Formik initialValues={data as unknown as PropertyRequirements[]} onSubmit={handleSubmit} enableReinitialize={true}>
      {children}
    </Formik>
  );
};

const RepairForm: React.FC = () => {
  const {hospitalUsers} = useHospitalUsers();
  const {submitForm, values, setFieldValue, isValid} = useFormikContext<ExtendedPropertyRequirements>();
  const {hospitalDepartmentOptions} = useHospitalDepartmentsOptions();
  const sections = formSection(hospitalUsers, hospitalDepartmentOptions);
  const generatedFieldObjects = useMemo(() => {
    const result: GeneratedFieldObjectsType = {options: [], items: []};

    for (const section of sections) {
      for (const field of section.fields) {
        if (field.name === 'symptomDetailCategory') {
          continue; // 事象詳細区分（内部故障）は事象区分が内部故障として選択されたときのみ表示するため、オプションから除外する
        }

        const correspondingRequirement = values.find((val) => val.property === field.name);

        if (correspondingRequirement) {
          result.items.push({
            label: field.label,
            value: correspondingRequirement.property,
            isRequired: correspondingRequirement.isRequired,
            sequence: correspondingRequirement.sequence,
          });
        } else {
          result.options.push({
            label: field.label,
            value: field.name,
          });
        }
      }
    }

    // ステータスはformSectionに含まれていないため、ここで追加
    const faultRepairStatus = values.find((val) => val.property === 'statusHashId');
    if (faultRepairStatus) {
      result.items.push({
        label: 'ステータス',
        value: faultRepairStatus.property,
        isRequired: faultRepairStatus.isRequired,
        sequence: faultRepairStatus.sequence,
      });
    } else {
      result.options.push({
        label: 'ステータス',
        value: 'statusHashId',
      });
    }

    result.items.sort((a, b) => a.sequence - b.sequence);

    return result;
  }, [sections, values]);

  const [items, setItems] = useState(generatedFieldObjects.items as ItemType[]);
  const [selectedOption, setSelectedOption] = useState(null);

  const reorder = useCallback((list: ItemType[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }, []);

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) {
        return;
      }
      if (result.destination.index === result.source.index) {
        return;
      }

      const newItems = reorder(items, result.source.index, result.destination.index);
      setItems(newItems);
      setFieldValue('targetProperty', {property: result.draggableId, newSequence: result.destination.index + 1});
      setSelectedOption(null);
      submitForm();
    },
    [items, reorder, setFieldValue, submitForm]
  );

  const handleAddingProperty = useCallback(() => {
    const newItem = {
      value: values.propertyName as keyof RepairIndex,
      label: generatedFieldObjects.options.find((option) => option.value === values.propertyName)?.label ?? '',
      isRequired: false,
    };
    setItems((prevItems) => [...prevItems, newItem]);
    setSelectedOption(null);
    submitForm();
  }, [generatedFieldObjects.options, submitForm, values.propertyName]);

  const handleSwitchIsRequired = useCallback(
    (property: string, isRequired: boolean) => {
      setItems(items.map((item) => (item.value === property ? {...item, isRequired: isRequired} : item)));
      setFieldValue('switchedProperty', {property: property, isRequired: isRequired});
      setSelectedOption(null);
      submitForm();
    },
    [items, setFieldValue, submitForm]
  );

  const handleDelete = useCallback(
    (property: string) => {
      setItems(items.filter((item) => item.value !== property));
      setFieldValue('deleteProperty', {property: property});
      setSelectedOption(null);
      submitForm();
    },
    [items, setFieldValue, submitForm]
  );

  return (
    <Box sx={templateClasses.form}>
      <Form>
        <Grid container sx={templateClasses.grid}>
          {/* 左カラムサイドバー */}
          <Grid item sx={templateClasses.sideBar}>
            <Sidebar />
          </Grid>
          {/* 右カラムコンテンツ */}
          <Grid item sx={contentStyles}>
            <Grid container>
              <Grid item>
                <Typography variant={'h5'} sx={templateClasses.pageTitle}>
                  修理
                </Typography>
                <p>修理に関するユーザー共通設定を管理します。</p>
              </Grid>
              <Box sx={templateClasses.flex} />
            </Grid>
            <Divider variant="middle" sx={templateClasses.divider} />
            <Grid container>
              <Grid item>
                <Typography variant={'h6'} sx={templateClasses.pageSubTitle1}>
                  セットアップ
                </Typography>
                <Box sx={selectorTitleContainerStyles}>
                  <Typography>修理登録の項目管理</Typography>
                  <FormHelperText sx={formHelperTextStyles}>
                    修理登録を行う項目について、登録項目の管理を行います。
                  </FormHelperText>
                </Box>
                <Box sx={selectorContainerStyles}>
                  <StyledSelector
                    name="propertyName"
                    size="large"
                    options={generatedFieldObjects.options}
                    onChange={setSelectedOption}
                  />
                  <Button
                    disabled={!isValid || generatedFieldObjects.options.length === 0 || selectedOption === null}
                    variant={'contained'}
                    color="primary"
                    onClick={handleAddingProperty}
                    sx={addBtnStyles}>
                    追加
                  </Button>
                </Box>
                <Box sx={subtitleStyles} style={items.length === 0 ? {display: 'none'} : undefined}>
                  <Typography>表示する項目</Typography>
                  <Typography>必須</Typography>
                </Box>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="list">
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        <ItemList items={items} onSwitchClick={handleSwitchIsRequired} onDeleteClick={handleDelete} />
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </Box>
  );
};

const ItemList: React.FC<ItemListType> = ({items, onSwitchClick, onDeleteClick}) => (
  <>
    {items.map((item, index) => (
      <DraggableItem
        item={item}
        index={index}
        key={item.value + index}
        onSwitchClick={onSwitchClick}
        onDeleteClick={onDeleteClick}
      />
    ))}
  </>
);

const DraggableItem: React.FC<ItemProps> = ({item, index, onSwitchClick, onDeleteClick}) => {
  const handleOnChange = useCallback(() => {
    onSwitchClick(item.value, !item.isRequired);
  }, [item.isRequired, item.value, onSwitchClick]);

  const handleOnClick = useCallback(() => {
    onDeleteClick(item.value);
  }, [item.value, onDeleteClick]);
  return (
    <Draggable draggableId={item.value} index={index}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <Box sx={itemContainerStyles}>
            <Box sx={labelContainerStyles}>
              <DragIndicatorIcon />
              {item.label}
            </Box>
            <Box>
              <Switch
                checked={item.isRequired}
                onChange={handleOnChange}
                color="primary"
                name={item.value}
                inputProps={{'aria-label': 'primary checkbox'}}
              />
              <Button color="inherit" variant={'contained'} sx={deleteBtnStyles} onClick={handleOnClick}>
                削除
              </Button>
            </Box>
          </Box>
        </div>
      )}
    </Draggable>
  );
};

const StyledSelector = styled(Selector)({
  height: '32px',
  width: '328px',
});

const contentStyles: SxProps<Theme> = {
  ...templateClasses.content,
  height: 'auto',
};

const selectorTitleContainerStyles: SxProps<Theme> = {
  marginTop: '24px',
};

const selectorContainerStyles: SxProps<Theme> = {
  display: 'flex',
};

const addBtnStyles: SxProps<Theme> = {
  marginLeft: '24px',
};

const itemContainerStyles: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingBottom: '8px',
};

const labelContainerStyles: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  maxWidth: '280px',
};

const deleteBtnStyles: SxProps<Theme> = (theme: Theme) => ({
  marginLeft: '12px',
  backgroundColor: theme.palette.grey[50],
});

const subtitleStyles: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'space-between',
  maxWidth: 'calc(328px - 12px)',
  padding: '24px 8px 16px',
};

const formHelperTextStyles: SxProps<Theme> = (theme: Theme) => ({
  fontSize: 14,
  color: theme.palette.grey[600],
  margin: '8px 0px 8px',
});
