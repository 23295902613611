import {useReducer} from 'react';
import {useQuery} from 'react-query';
import {getHospitalProductFaultRepairHistoiesKey, getHospitalProductRentalHistoiesKey} from '@constants/api';
import {
  FetchHospitalProductFaultRepairHistoriesParams,
  FetchHospitalProductFaultRepairHistoriesResult,
  FetchHospitalProductRentalHistoriesParams,
} from '@modules/hospital_products/api';
import {
  getHospitalProductFaultRepairHistories,
  getHospitalProductRentalHistories,
} from '@modules/hospital_products/api/historyApi';
import {repairHistoryReducer, repairHistoryInitialState} from '@modules/hospital_products/reducers/history';
import {RepairHistoryState, RepairHistoryActionType} from '@modules/hospital_products/types';
import {isNullish} from '@front-libs/helpers';

export const useFetchHospitalProductRentalHistoriesQuery = (
  hospitalHashId: string,
  hospitalProductHashId: string,
  params: FetchHospitalProductRentalHistoriesParams = {}
) => {
  return useQuery(
    [getHospitalProductRentalHistoiesKey, hospitalHashId, hospitalProductHashId, params],
    () => getHospitalProductRentalHistories(hospitalHashId, hospitalProductHashId, params),
    {
      enabled: !isNullish(hospitalProductHashId),
    }
  );
};

export const useFetchHospitalProductFaultRepairHistoriesQuery = (
  hospitalHashId: string,
  hospitalProductHashId: string,
  params: FetchHospitalProductFaultRepairHistoriesParams = {}
) => {
  return useQuery(
    [getHospitalProductFaultRepairHistoiesKey, hospitalHashId, hospitalProductHashId, params],
    async () => {
      const {data} = await getHospitalProductFaultRepairHistories(hospitalHashId, hospitalProductHashId, params);
      return data;
    }
  );
};

type RepairHistoryStoreType = {
  state: RepairHistoryState;
  dispatchListRepairHistories: (
    hospitalHashId: string,
    hospitalProductHashId: string,
    params?: FetchHospitalProductFaultRepairHistoriesParams
  ) => Promise<FetchHospitalProductFaultRepairHistoriesResult>;
};

export const useRepairHistoryStore = (): RepairHistoryStoreType => {
  const [state, dispatch] = useReducer(repairHistoryReducer, repairHistoryInitialState);

  const dispatchListRepairHistories = async (
    hospitalHashId: string,
    hospitalProductHashId: string,
    params?: FetchHospitalProductFaultRepairHistoriesParams
  ) => {
    const res = await getHospitalProductFaultRepairHistories(hospitalHashId, hospitalProductHashId, params ?? {});
    dispatch({
      type: RepairHistoryActionType.REPAIR_HISTORY_SET_LIST,
      payload: {repairs: res.data.data},
    });
    return res.data;
  };

  return {
    state,
    dispatchListRepairHistories,
  };
};
