import {useQuery} from 'react-query';
import {getHospitalProductNoteSettingsKey} from '@constants/api';
import {useMemo} from 'react';
import {getHospitalProductNoteSettings} from '../api/hospitalProductNoteSettingsApi';

/** notesは現在20まで定義 */
export const MAX_NOTE_NUM = 20;

/**
 * 機器詳細のログ取得Hook
 * @param hospitalHashId
 * @param {FetchHospitalProductLogsParams} params
 * @returns
 */
export const useHospitalProductNoteSettings = (hospitalHashId: string) => {
  const query = useQuery([getHospitalProductNoteSettingsKey], () => getHospitalProductNoteSettings(hospitalHashId));
  return useMemo(
    () => ({
      ...query,
      data: query.data,
    }),
    [query]
  );
};
