import {zodResolver} from '@hookform/resolvers/zod';
import {useForm} from 'react-hook-form';
import {z} from 'zod';

const childUnitFormValueSchema = z.object({
  childProductManagementId: z.string().min(1, {message: '管理番号を入力してください。'}),
});

export type ChildUnitFormValue = z.infer<typeof childUnitFormValueSchema>;

export const useChildUnitForm = () => {
  const {
    register,
    handleSubmit,
    formState: {errors, isValid},
    reset,
  } = useForm<ChildUnitFormValue>({
    mode: 'onChange',
    resolver: zodResolver(childUnitFormValueSchema),
    defaultValues: {childProductManagementId: ''}, //reset時の初期値を設定
  });

  return {register, handleSubmit, errors, isValid, reset};
};
