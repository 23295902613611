import React, {useMemo, useState, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {createStyles, Grid, makeStyles, Theme} from '@material-ui/core';
import {Today, Remove, Check, SkipNext, GetApp} from '@material-ui/icons';
import {Pagination} from '@material-ui/lab';
import {TableLayoutResult} from '@modules/table_layout/hooks/useTableLayout';
import {StatusSelector} from './StatusSelector';
import {InspectionResultListElement, ViewInspectionResultStatus} from '@Apps/InspectionResultList/pc/types';
import {NoData, Table} from '@molecules/Table';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useMyRole} from '@modules/hospital_users/hooks/useMyRole';
import {
  DeleteInspectionResultDispatcherProvider,
  UpdateScheduledDateDispatcherProvider,
  UpdateTableDispatcherContextProvider,
} from './contexts';
import {ToolBar} from './ToolBar';
import {
  useInspectionResultStatus,
  useInspectionType,
  useIsAdhoc,
  useOrderKey,
  useSearchName,
  useCompletedAtRange,
  useScheduledTimeRange,
  useIsPassed,
  usePage,
  useDefaultDetailResultValues,
  usePerPage,
  useInitialized,
  useHospitalWard,
  useHospitalRoom,
  useRentHospitalWard,
  useRentHospitalRoom,
  useInspectionName,
  useInspectionSettingName,
  useSkippedTimeRange,
} from './states/states';
import {useInspectionResults, useInspectionResultsCounts} from './states/results';
import {
  useChangeScheduledDate,
  useDeleteInspectionResult,
  useApplyPreviousInspectionResults,
  useSkipInspectionResults,
  useTableData,
  useDownloadPDFs,
  useInspectionNameFilter,
} from '../hooks';
import {FilterDrawer} from '@components/molecules/Drawers/FilterDrawer';
import {FilterDrawerOptions} from '../consts';
import {
  DateRangeConditionValue,
  FilterOption,
  ResultType,
  SelectorConditionValue,
  TextConditionValue,
} from '@components/molecules/Drawers/FilterDrawer/types';
import {
  StartInspectionDialog,
  StartInspectionDialogProps,
  StartInspectionDialogResult,
} from '@components/organisms/StartInspectionDialog';
import {
  SkipInspectionDialog,
  SkipInspectionDialogProps,
  SkipInspectionDialogResult,
} from './dialogs/SkipInspectionDialog';
import {
  UpdateScheduledDateDialog,
  UpdateScheduledDateDialogProps,
  UpdateScheduledDateDialogResult,
} from './dialogs/UpdateScheduledDateDialog';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {Column, RowAction} from '@molecules/Table/props';
import {convertDateToSimpleDate} from '@front-libs/helpers';
import {updateInspectionResult, useFetchInspectionResultsQuery} from '@modules/inspection_results/api';
import {openSnackBar} from '@components/molecules/SnackBar';
import {getHospitalProduct} from '@modules/hospital_products/api';
import {onlineManager} from 'react-query';
import {InspectionResultStatus} from '@modules/inspection_results/enum';
import {DisplayNumberSelect} from '@components/molecules/DisplayNumberSelect';
import dayjs from 'dayjs';
import {useFetchHospitalRooms, useFetchHospitalWards} from '@modules/hospital_places/api';
import {HospitalRoomFormatter} from '@modules/hospital_wards/helpers';
import {useFetchHospitalSettingsQuery} from '@modules/hospital_settings/api';
import {inspectionTypeTokAllowApplyPreviousInspectionResultSettings} from '@modules/hospital_settings/types';
import {TableViewLayout} from '@components/layouts/TableViewLayout';

function extractResultFromDetailSetting(values: ResultType[]) {
  let scheduledTimeFrom: Date | null = null;
  let scheduledTimeTo: Date | null = null;
  let completedAtFrom: Date | null = null;
  let completedAtTo: Date | null = null;
  let skippedTimeFrom: Date | null = null;
  let skippedTimeTo: Date | null = null;
  let isAdhoc: boolean | null = null;
  let isPassed: boolean | null = null;
  let hospitalWard: string | null = null;
  let hospitalRoom: string | null = null;
  let rentHospitalWard: string | null = null;
  let rentHospitalRoom: string | null = null;
  let inspectionName: string | null = null;
  let inspectionSettingName: string | null = null;

  for (const value of values) {
    switch (value.key) {
      case 'scheduleDate': {
        const typedValue = value.resultValue as DateRangeConditionValue;
        scheduledTimeFrom = typedValue?.from ?? null;
        scheduledTimeTo = typedValue?.to ?? null;
        break;
      }
      case 'completedAt': {
        const typedValue = value.resultValue as DateRangeConditionValue;
        completedAtFrom = typedValue?.from ?? null;
        completedAtTo = typedValue?.to ?? null;
        break;
      }
      case 'skippedDate': {
        const typedValue = value.resultValue as DateRangeConditionValue;
        skippedTimeFrom = typedValue?.from ?? null;
        skippedTimeTo = typedValue?.to ?? null;
        break;
      }
      case 'isAdhoc': {
        const typedValue = value.resultValue as SelectorConditionValue;
        if (typedValue.length > 0) {
          isAdhoc = typedValue[0].value as boolean;
        }
        break;
      }
      case 'isPassed': {
        const typedValue = value.resultValue as SelectorConditionValue;
        if (typedValue.length > 0) {
          isPassed = typedValue[0].value as boolean;
        }
        break;
      }
      case 'hospitalWard': {
        const typedValue = value.resultValue as SelectorConditionValue;
        if (typedValue.length > 0) {
          hospitalWard = typedValue[0].value as string;
        }
        break;
      }
      case 'hospitalRoom': {
        const typedValue = value.resultValue as SelectorConditionValue;
        if (typedValue.length > 0) {
          hospitalRoom = typedValue[0].value as string;
        }
        break;
      }
      case 'rentHospitalWard': {
        const typedValue = value.resultValue as SelectorConditionValue;
        if (typedValue.length > 0) {
          rentHospitalWard = typedValue[0].value as string;
        }
        break;
      }
      case 'rentHospitalRoom': {
        const typedValue = value.resultValue as SelectorConditionValue;
        if (typedValue.length > 0) {
          rentHospitalRoom = typedValue[0].value as string;
        }
        break;
      }
      case 'inspectionName': {
        const typedValue = value.resultValue as SelectorConditionValue;
        if (typedValue.length > 0) {
          inspectionName = typedValue[0].value as string;
        }
        break;
      }
      case 'inspectionSettingName': {
        const typedValue = value.resultValue as SelectorConditionValue;
        if (typedValue.length > 0) {
          inspectionSettingName = typedValue[0].value as string;
        }
        break;
      }
    }
  }

  return {
    scheduledTimeFrom,
    scheduledTimeTo,
    completedAtFrom,
    completedAtTo,
    skippedTimeFrom,
    skippedTimeTo,
    isAdhoc,
    isPassed,
    hospitalWard,
    hospitalRoom,
    rentHospitalWard,
    rentHospitalRoom,
    inspectionName,
    inspectionSettingName,
  } as const;
}

const SIDEBAR_WIDTH = 170;

const useBodyStyles = makeStyles((theme: Theme) =>
  createStyles({
    body: {
      flexWrap: 'nowrap',
      width: '100%',
      height: '100%',
    },
    sideBar: {
      flex: `0 0 ${SIDEBAR_WIDTH}px`,
      marginRight: '18px',
    },
    main: {
      flex: 1,
      minWidth: '0px',
    },
    pageDescription: {
      margin: 'auto 0px',
      flex: 1,
    },
    paginationContainer: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '0.875rem',
    },
    emptyGrid: {
      flex: 1,
    },
    pageSize: {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
  })
);

export const InspectionResultListBody: React.FC = () => {
  const classes = useBodyStyles();
  const {myInfo} = useMyInfo();
  const navigate = useNavigate();

  const [, setOrderKey] = useOrderKey();
  const [page, setPage] = usePage();
  const [searchName, setSearchName] = useSearchName();
  const [initialized] = useInitialized();
  const [inspectionType] = useInspectionType();
  const [inspectionResultStatus, setInspectionResultStatus] = useInspectionResultStatus();
  const [perPage, setPerPage] = usePerPage();

  const settings = useFetchHospitalSettingsQuery(myInfo.hospitalHashId);
  const query = useInspectionResults(inspectionType);

  const isEnabledApplyPreviousInspectionResults = useMemo(() => {
    switch (inspectionType) {
      case 'maker_periodic':
        // NOTE: メーカー点検はとりあえず無視
        return false;
      default:
        return (
          (settings.data?.data ?? []).find(
            ({key}) => key === inspectionTypeTokAllowApplyPreviousInspectionResultSettings[inspectionType].key
          )?.value === 'true'
        );
    }
  }, [settings.data, inspectionType]);

  const startDisplayPosition = useMemo(() => {
    return query.totalCount === 0 ? 0 : Math.ceil((page - 1) * perPage + 1);
  }, [query.totalCount, page, perPage]);

  const endDisplayPosition = useMemo(() => {
    const endPosition = Math.ceil(page * perPage);
    return query.totalCount === undefined ? 0 : endPosition > query.totalCount ? query.totalCount : endPosition;
  }, [page, perPage, query.totalCount]);

  const {rows, columns, tableLayout, setTableLayout, totalPage} = useTableData(
    inspectionType,
    inspectionResultStatus === 'overdue' ? 'unplanned' : inspectionResultStatus,
    query.data,
    query.totalCount ?? 0
  );

  const countsQuery = useInspectionResultsCounts(inspectionType);
  const downloadPDFs = useDownloadPDFs(myInfo.hospitalHashId);

  const handleChangeViewInspectionResultStatus = useCallback(
    (status: ViewInspectionResultStatus | null) => {
      setInspectionResultStatus(status ?? 'unplanned');
    },
    [setInspectionResultStatus]
  );

  const handleOrderChange = useCallback(
    (columnIndex: number, orderDirection: 'asc' | 'desc') => {
      if (columnIndex === -1) {
        setOrderKey(null);
      } else {
        setOrderKey(`${orderDirection === 'desc' ? '-' : ''}${String(tableLayout?.currentLayout[columnIndex].field)}`);
      }
    },
    [setOrderKey, tableLayout?.currentLayout]
  );

  const handleChangePage = useCallback(
    (event: React.ChangeEvent<unknown>, p: number) => {
      setPage(p);
    },
    [setPage]
  );

  const handleChangeSearchName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchName(e.target.value);
    },
    [setSearchName]
  );

  const [selectedResultHashIds, setSelectedResultHashIds] = useState<string[]>([]);
  const handleSelectionChange = useCallback(
    (selected: InspectionResultListElement[], _newOne: InspectionResultListElement | null, _checked: boolean) => {
      setSelectedResultHashIds(selected.map((p) => p?.hashId));
    },
    []
  );

  const changeScheduledDate = useChangeScheduledDate(myInfo.hospitalHashId, query.data ?? []);
  const handleChangeScheduledDateRequest = useCallback(
    async (hashId: string, date: Date | null) => {
      await changeScheduledDate([hashId], date);
      query.refetch();
    },
    [changeScheduledDate, query]
  );
  const handleClickChangeDates = useCallback(
    async (e: React.MouseEvent) => {
      await changeScheduledDate(selectedResultHashIds, null);
      query.refetch();
    },
    [changeScheduledDate, selectedResultHashIds, query]
  );

  const handleDownloadPdf = useCallback(
    async (e: React.MouseEvent) => {
      await downloadPDFs(selectedResultHashIds);
    },
    [downloadPDFs, selectedResultHashIds]
  );

  const deleteInspectionResult = useDeleteInspectionResult(myInfo.hospitalHashId, query.data ?? []);
  const handleDeleteRequest = useCallback(
    async (hashId: string) => {
      await deleteInspectionResult(
        [hashId],
        inspectionResultStatus !== 'overdue' ? inspectionResultStatus : 'unplanned'
      );
      query.refetch();
    },
    [deleteInspectionResult, inspectionResultStatus, query]
  );

  const handleClickDeleteResults = useCallback(
    async (e: React.MouseEvent) => {
      await deleteInspectionResult(
        selectedResultHashIds,
        inspectionResultStatus !== 'overdue' ? inspectionResultStatus : 'unplanned'
      );
      query.refetch();
      countsQuery.refetch();
    },
    [countsQuery, deleteInspectionResult, inspectionResultStatus, query, selectedResultHashIds]
  );

  const applyPreviousInspectionResults = useApplyPreviousInspectionResults(myInfo.hospitalHashId, inspectionType);
  const handleClickApplyPreviousResults = useCallback(async () => {
    if (isEnabledApplyPreviousInspectionResults) {
      await applyPreviousInspectionResults(selectedResultHashIds);
      query.refetch();
    }
  }, [query, selectedResultHashIds, applyPreviousInspectionResults, isEnabledApplyPreviousInspectionResults]);

  const handleChangeTableLayout = useCallback(
    (layout: TableLayoutResult) => {
      setTableLayout(layout);
    },
    [setTableLayout]
  );

  const handleChangeOrderColumns = useCallback(
    // eslint-disable-next-line no-shadow
    (columns: Column<InspectionResultListElement>[]) => {
      if (!tableLayout) return;
      setTableLayout({
        ...tableLayout,
        currentLayout: columns,
      });
    },
    [setTableLayout, tableLayout]
  );

  const handleUpdateTable = useCallback(() => {
    query.refetch();
  }, [query]);

  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  const handleClickDrawer = useCallback(() => {
    setIsOpenDrawer((x) => !x);
  }, []);

  const handleDrawerClose = useCallback(() => {
    setIsOpenDrawer(false);
  }, []);

  const [, setScheduledTimeRange] = useScheduledTimeRange();
  const [, setCompletedAtRange] = useCompletedAtRange();
  const [, setSkippedTimeRange] = useSkippedTimeRange();
  const [, setIsAdhoc] = useIsAdhoc();
  const [, setIsPassed] = useIsPassed();
  const [, setHospitalWard] = useHospitalWard();
  const [, setHospitalRoom] = useHospitalRoom();
  const [, setRentHospitalWard] = useRentHospitalWard();
  const [, setRentHospitalRoom] = useRentHospitalRoom();
  const [, setInspectionName] = useInspectionName();
  const [, setInspectionSettingName] = useInspectionSettingName();

  const handleClickView = useCallback(
    (e: React.MouseEvent, data: InspectionResultListElement) => {
      e.stopPropagation();

      navigate(`/inspections/${data.inspectionHashId}/result/${data.hashId}`);
    },
    [navigate]
  );

  /**
   * 点検を開始ダイアログ表示
   * @param {InspectionResultListElement} data - InspectionResultListElement
   * @return {*}
   */
  const openStartInspectionDialog = useCallback(
    async (data: InspectionResultListElement, status: InspectionResultStatus) => {
      try {
        const hospitalProduct = await getHospitalProduct(myInfo.hospitalHashId, data.hospitalProductHashId);
        if (!hospitalProduct) {
          throw new Error('hospital product not found');
        }

        return await dialogHandler.open<StartInspectionDialogProps, StartInspectionDialogResult>(
          StartInspectionDialog,
          {
            hospitalHashId: myInfo.hospitalHashId,
            wholeProductHashId: hospitalProduct.wholeProductHashId,
            hospitalProductHashId: hospitalProduct.hashId,
            inspectionResultHashId: data.hashId,
            defaultInspectorHashId: myInfo.hashId,
            inspectionType: inspectionType as never,
            showsSelectInspection: status !== 'uncompleted',
            defaultInspectionHashId: status === 'uncompleted' ? data.inspectionHashId : undefined,
          }
        );
      } catch (_e) {
        return;
      }
    },
    [inspectionType, myInfo.hashId, myInfo.hospitalHashId]
  );
  /**
   * 点検開始押下
   * @param {React.MouseEvent} e - React.MouseEvent
   * @param {InspectionResultListElement} data -InspectionResultListElement
   */
  const handleClickStart = useCallback(
    async (e: React.MouseEvent, data: InspectionResultListElement) => {
      e.stopPropagation();

      let inspectionResult = data.hashId;
      let inspectionHashId = data.inspectionHashId;
      let inspectorHashId = data.inspectorHashId;

      // NOTE:ダイアログを表示
      const res = await openStartInspectionDialog(data, data.status);
      if (!res) return;

      inspectionResult = res.inspectionResult;
      inspectionHashId = res.inspection;
      inspectorHashId = res.inspector;

      try {
        // 詳細画面への状態の受け渡しのため ダイアログで入力された状態
        navigate(`/inspections/${inspectionHashId}/result/${inspectionResult}`, {
          state: {
            status: 'uncompleted',
            inspectionHashId: inspectionHashId,
            inspectorHashId: inspectorHashId,
          },
        });
        // eslint-disable-next-line no-shadow
      } catch (e) {
        console.error(e);
        openSnackBar('点検実績の更新に失敗しました', 'left', 'bottom', 'error');
      }
    },
    [navigate, openStartInspectionDialog]
  );

  const handleClickSkip = useCallback(
    async (e: React.MouseEvent, data: InspectionResultListElement) => {
      let skipDate: Date;
      let operator: string;
      let reason: string | undefined;

      try {
        const res = await dialogHandler.open<SkipInspectionDialogProps, SkipInspectionDialogResult>(
          SkipInspectionDialog,
          {
            defaultOperatorHashId: myInfo.hashId,
            defaultSkipDate: convertDateToSimpleDate(new Date()),
          }
        );

        skipDate = res.skipDate;
        operator = res.operator;
        reason = res.reason;
      } catch (_e) {
        return;
      }

      try {
        await updateInspectionResult(myInfo.hospitalHashId, data.inspectionHashId, data.hashId, {
          status: 'skipped',
          skippedByHashId: operator,
          skippedTime: convertDateToSimpleDate(skipDate),
          skipReason: reason,
        });

        openSnackBar('点検をスキップしました', 'left', 'bottom', 'success');
        query.refetch();
        // eslint-disable-next-line no-shadow
      } catch (e) {
        console.error(e);
        openSnackBar('点検実績の更新に失敗しました', 'left', 'bottom', 'error');
      }
    },
    [myInfo, query]
  );

  const skipInspectionResults = useSkipInspectionResults(myInfo.hospitalHashId, myInfo.hashId);
  const handleClickSkipResults = useCallback(async () => {
    await skipInspectionResults(selectedResultHashIds);
    query.refetch();
  }, [query, selectedResultHashIds, skipInspectionResults]);

  const handleClickChangeDate = useCallback(
    async (e: React.MouseEvent, data: InspectionResultListElement) => {
      let scheduledDate: Date;

      try {
        const res = await dialogHandler.open<UpdateScheduledDateDialogProps, UpdateScheduledDateDialogResult>(
          UpdateScheduledDateDialog,
          {}
        );

        scheduledDate = res.scheduledDate;
      } catch (_e) {
        return;
      }

      try {
        await updateInspectionResult(myInfo.hospitalHashId, data.inspectionHashId, data.hashId, {
          scheduledTime: convertDateToSimpleDate(scheduledDate),
        });

        openSnackBar('点検予定日を更新しました', 'left', 'bottom', 'success');
        query.refetch();
        // eslint-disable-next-line no-shadow
      } catch (e) {
        console.error(e);
        openSnackBar('点検予定日の更新に失敗しました', 'left', 'bottom', 'error');
      }
    },
    [myInfo, query]
  );

  const isOnline = onlineManager.isOnline();
  const {isReadOnly, isAdmin} = useMyRole();

  const rowActions = useMemo(() => {
    const actions: RowAction<InspectionResultListElement>[] = [];

    if (inspectionResultStatus === 'completed') {
      actions.push({
        type: 'button',
        label: '詳細',
        onClick: handleClickView,
      });
    }

    if (
      (inspectionResultStatus === 'unplanned' ||
        inspectionResultStatus === 'overdue' ||
        inspectionResultStatus === 'uncompleted') &&
      !isReadOnly
    ) {
      actions.push({
        type: 'button',
        label: '点検開始',
        onClick: handleClickStart,
      });
    }

    if (
      (inspectionResultStatus === 'unplanned' ||
        inspectionResultStatus === 'overdue' ||
        inspectionResultStatus === 'uncompleted') &&
      isOnline &&
      !isReadOnly
    ) {
      const items = [
        {
          label: '点検をスキップ',
          onClick: handleClickSkip,
        },
      ];

      if (inspectionType === 'periodic') {
        items.push({
          label: '点検予定日を変更',
          onClick: handleClickChangeDate,
        });
      }

      actions.push({
        type: 'menu',
        label: 'アクション',
        items: items,
      });
    }

    return actions;
  }, [
    inspectionResultStatus,
    isReadOnly,
    isOnline,
    inspectionType,
    handleClickView,
    handleClickStart,
    handleClickSkip,
    handleClickChangeDate,
  ]);

  const handleFilterChange = useCallback(
    (values: ResultType[]) => {
      const {
        scheduledTimeFrom,
        scheduledTimeTo,
        completedAtFrom,
        completedAtTo,
        skippedTimeFrom,
        skippedTimeTo,
        isAdhoc,
        isPassed,
        hospitalWard,
        hospitalRoom,
        rentHospitalWard,
        rentHospitalRoom,
        inspectionName,
        inspectionSettingName,
      } = extractResultFromDetailSetting(values);

      setScheduledTimeRange([scheduledTimeFrom, scheduledTimeTo]);
      setCompletedAtRange([completedAtFrom, completedAtTo]);
      setSkippedTimeRange([skippedTimeFrom, skippedTimeTo]);
      setIsAdhoc(isAdhoc);
      setIsPassed(isPassed);
      setHospitalWard(hospitalWard);
      setHospitalRoom(hospitalRoom);
      setRentHospitalWard(rentHospitalWard);
      setRentHospitalRoom(rentHospitalRoom);
      setInspectionName(inspectionName);
      setInspectionSettingName(inspectionSettingName);
    },
    [
      setCompletedAtRange,
      setIsAdhoc,
      setIsPassed,
      setScheduledTimeRange,
      setSkippedTimeRange,
      setHospitalWard,
      setHospitalRoom,
      setRentHospitalWard,
      setRentHospitalRoom,
      setInspectionName,
      setInspectionSettingName,
    ]
  );

  const selectionButtons = useMemo(() => {
    const res = [];
    inspectionResultStatus !== 'completed' &&
      inspectionType == 'periodic' &&
      res.push({
        label: '点検予定日を変更',
        IconComponent: Today,
        onClick: handleClickChangeDates,
      });

    inspectionResultStatus === 'completed' &&
      res.push({
        label: '点検結果をPDFとして出力',
        IconComponent: GetApp,
        onClick: handleDownloadPdf,
      });

    if (isAdmin) {
      res.push({
        label: inspectionResultStatus === 'completed' ? '点検結果を一括で削除' : '点検の予定を一括で削除',
        IconComponent: Remove,
        onClick: handleClickDeleteResults,
      });
    }

    if (
      inspectionResultStatus === 'unplanned' ||
      inspectionResultStatus === 'uncompleted' ||
      inspectionResultStatus === 'overdue'
    ) {
      res.push({
        label: '点検予定をスキップ',
        IconComponent: SkipNext,
        onClick: handleClickSkipResults,
      });
    }

    (inspectionResultStatus === 'unplanned' || inspectionResultStatus === 'overdue') &&
      isEnabledApplyPreviousInspectionResults &&
      res.push({
        label: '点検予定を一括で完了',
        IconComponent: Check,
        onClick: handleClickApplyPreviousResults,
      });

    return res;
  }, [
    inspectionResultStatus,
    handleClickChangeDates,
    handleDownloadPdf,
    isAdmin,
    isEnabledApplyPreviousInspectionResults,
    handleClickApplyPreviousResults,
    handleClickDeleteResults,
    handleClickSkipResults,
  ]);

  const {data: hospitalWards, isLoading: isFetchingHospitalWards} = useFetchHospitalWards(myInfo.hospitalHashId);
  const hospitalWardOptions = useMemo(
    () =>
      hospitalWards.map((room) => ({
        label: room.name,
        value: room.hashId,
      })),
    [hospitalWards]
  );

  const {data: hospitalRooms, isLoading: isFetchingHospitalRooms} = useFetchHospitalRooms(myInfo.hospitalHashId);
  const hospitalRoomOptions = useMemo(
    () =>
      hospitalRooms.map((room) => ({
        label: HospitalRoomFormatter.getFullRoom(room),
        value: room.hashId,
      })),
    [hospitalRooms]
  );

  const {isFetchingInspectionResults, inspectionNameOptions, inspectionSettingNameOptions} = useInspectionNameFilter(
    myInfo.hospitalHashId,
    inspectionType
  );

  const filterDrawerOptions = useMemo((): FilterOption[] => {
    return [
      ...FilterDrawerOptions.map((opt) => {
        if (opt.value !== 'scheduleDate') {
          return opt;
        }
        const dateOption = opt as {
          label: string;
          value: string;
          optionType: 'date';
          min?: Date;
          max?: Date;
        };
        switch (inspectionResultStatus) {
          case 'unplanned':
            return {
              ...dateOption,
              min: dayjs(new Date()).startOf('month').toDate(),
            };
          case 'overdue':
            return {
              ...dateOption,
              max: dayjs(new Date()).subtract(1, 'month').endOf('month').toDate(),
            };
          default:
            return {
              ...dateOption,
            };
        }
      }),
      {
        label: '点検名',
        value: 'inspectionSettingName',
        optionType: 'selector',
        options: inspectionSettingNameOptions,
        isMulti: false,
      },
      {
        label: '点検表名',
        value: 'inspectionName',
        optionType: 'selector',
        options: inspectionNameOptions,
        isMulti: false,
      },
      {
        label: '機器管理場所（大エリア）',
        value: 'hospitalWard',
        optionType: 'selector',
        options: hospitalWardOptions,
      },
      {
        label: '機器管理場所（小エリア）',
        value: 'hospitalRoom',
        optionType: 'selector',
        options: hospitalRoomOptions,
      },
      {
        label: '機器貸出場所（大エリア）',
        value: 'rentHospitalWard',
        optionType: 'selector',
        options: hospitalWardOptions,
      },
      {
        label: '機器貸出場所（小エリア）',
        value: 'rentHospitalRoom',
        optionType: 'selector',
        options: hospitalRoomOptions,
      },
    ];
  }, [
    inspectionResultStatus,
    hospitalWardOptions,
    hospitalRoomOptions,
    inspectionNameOptions,
    inspectionSettingNameOptions,
  ]);

  // クエリパラメータからstate初期化後かつhospitalRoomsとhospitalWards取得後
  const canInitializeFilters =
    initialized && !isFetchingHospitalWards && !isFetchingHospitalRooms && !isFetchingInspectionResults;
  const defaultResultValues = useDefaultDetailResultValues(filterDrawerOptions, canInitializeFilters);

  return (
    <Grid container className={classes.body}>
      <Grid item className={classes.sideBar}>
        <StatusSelector
          inspectionType={inspectionType}
          status={inspectionResultStatus}
          counts={countsQuery.data ?? undefined}
          onSelectStatus={handleChangeViewInspectionResultStatus}
          isOnline={isOnline}
        />
      </Grid>
      <TableViewLayout className={classes.main}>
        <TableViewLayout.Header>
          <ToolBar
            searchName={searchName ?? ''}
            tableLayout={tableLayout}
            onChangeSearchName={handleChangeSearchName}
            onChangeTableLayout={handleChangeTableLayout}
            onClickDrawer={handleClickDrawer}
          />
        </TableViewLayout.Header>
        <TableViewLayout.Body>
          <DeleteInspectionResultDispatcherProvider handler={handleDeleteRequest}>
            <UpdateScheduledDateDispatcherProvider handler={handleChangeScheduledDateRequest}>
              <UpdateTableDispatcherContextProvider handler={handleUpdateTable}>
                <Table<InspectionResultListElement>
                  stickyHeader={true}
                  columns={columns}
                  isLoading={query.isLoading}
                  data={rows}
                  showSelection={!isReadOnly}
                  enablesDragColumn={true}
                  onSelectionChange={handleSelectionChange}
                  onColumnsOrderChange={handleChangeOrderColumns}
                  noDataComponent={
                    <NoData
                      title={searchName ? '現在の検索条件に一致する点検はありません。' : '点検はありません。'}
                      message={
                        searchName
                          ? '検索条件を変えて、再度検索してみてください。'
                          : 'ここで機器の点検予定と結果を管理します。'
                      }
                    />
                  }
                  selectionButtons={isOnline ? selectionButtons : []}
                  rowActions={rowActions}
                  onOrderChange={handleOrderChange}
                  tableSize="small"
                />
              </UpdateTableDispatcherContextProvider>
            </UpdateScheduledDateDispatcherProvider>
          </DeleteInspectionResultDispatcherProvider>
        </TableViewLayout.Body>
        <TableViewLayout.Footer container justifyContent="space-between">
          <Grid item className={classes.pageDescription}>
            {query.totalCount}件のうち{startDisplayPosition}件目-{endDisplayPosition}件目までを表示しています
          </Grid>
          <Grid item className={classes.paginationContainer}>
            <Pagination page={page} count={totalPage} shape="rounded" onChange={handleChangePage} />
            <DisplayNumberSelect
              pageSize={perPage}
              update={(selectNum) => {
                setPerPage(selectNum);
                setPage(1);
              }}
            />
          </Grid>
          <Grid className={classes.emptyGrid} />
        </TableViewLayout.Footer>
      </TableViewLayout>
      {canInitializeFilters && (
        <FilterDrawer
          open={isOpenDrawer}
          filterOptions={filterDrawerOptions}
          defaultFilterResults={defaultResultValues}
          currentCount={query.totalCount || 0}
          onDrawerClose={handleDrawerClose}
          onFilterChange={handleFilterChange}
        />
      )}
    </Grid>
  );
};
