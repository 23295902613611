import {isNullish} from '@front-libs/helpers';
import {ProductDetailFormType} from '.';

export const getNextStatus = (initialValues: ProductDetailFormType, submitValues: ProductDetailFormType) => {
  const willDisposed =
    initialValues.status !== 'disabled' &&
    submitValues.status !== 'disabled' &&
    (!isNullish(submitValues.dateOfDisposal) ||
      (submitValues.reasonOfDisposal !== '' && !isNullish(submitValues.reasonOfDisposal)));

  const wontDisposed =
    initialValues.status === 'disabled' &&
    submitValues.status === 'disabled' &&
    ((!isNullish(initialValues.dateOfDisposal) && isNullish(submitValues.dateOfDisposal)) ||
      (initialValues.reasonOfDisposal !== '' && submitValues.reasonOfDisposal === ''));

  const isNextDisabled = initialValues.status !== 'disabled' && submitValues.status === 'disabled';
  return {willDisposed, wontDisposed, isNextDisabled};
};

/**
 * 親機または子機の情報が変更されたかを判定する関数
 *
 * @param initialValues - デフォルトの機器情報
 * @param submitValues - 更新する機器情報
 * @returns {boolean} - 紐づいている親子関係が存在し親子区分が変更された場合はtrue、それ以外はfalse
 */
export const hasParentOrChildUnitChanged = (
  initialValues: ProductDetailFormType,
  submitValues: ProductDetailFormType
): boolean => {
  // 親機情報の判定：親機情報が存在し、親子区分 (isBaseUnit) が変更された場合
  if (initialValues.parentUnitManagementId && initialValues.isBaseUnit !== submitValues.isBaseUnit) {
    return true;
  }

  // 子機情報の判定：子機のカウントが存在し、親子区分 (isBaseUnit) が変更された場合
  if (
    initialValues.childUnitCount &&
    initialValues.childUnitCount > 0 &&
    initialValues.isBaseUnit !== submitValues.isBaseUnit
  ) {
    return true;
  }

  return false;
};
