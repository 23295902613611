import {InspectionIndex, InspectionListOrIndex} from '../inspections/types';
import {ProductInspectionsOptionsType} from './types';
import {isNullish} from '@front-libs/helpers';

/**
 * @todo 参照がない?
 */
export const InspectionFormatter = {
  getOptions(inspections: InspectionIndex[]) {
    return inspections.map((inspection) => {
      const tmp = {
        label: inspection.name,
        value: inspection.hashId,
        searchAlias: [inspection.name],
      };

      return tmp;
    });
  },
};

const inspectionToOption = (inspection: InspectionListOrIndex) => ({value: inspection, label: inspection.name});

/**
 * 全ての利用可能な点検と機種に紐づく点検表を生成します。
 *
 *
 * @param {InspectionIndex[] | undefined} allInspections - 全ての利用可能な点検表の配列。点検表が利用できない場合は undefined。
 * @param {InspectionIndex[] | undefined} productInspections - 機種に紐づく点検表の配列。点検表が利用できない場合は undefined。
 * @param {string | null} wholeProductHashId - 製品のハッシュID。
 * @param {string | undefined} defaultInspection - 機種に紐づくデフォルトの点検表。点検表が複数紐付く場合、デフォルトの値を採用する
 * @returns {ProductInspectionsOptionsType[]} - カテゴリごとにグループ化された点検オプションの配列を返します。
 */
export const generateInspectionOptions = (
  allInspections: InspectionListOrIndex[] | undefined,
  productInspections: InspectionListOrIndex[] | undefined,
  wholeProductHashId: string | null,
  defaultInspection?: InspectionIndex
): ProductInspectionsOptionsType[] => {
  const allInspectionOptions = {
    label: '全ての点検表',
    options: (allInspections ?? []).map(inspectionToOption),
  };

  if (wholeProductHashId === null || !productInspections) {
    return [allInspectionOptions];
  }

  // 複数の機種の点検タイプに紐づく場合、全て表示されてしまうため、デフォルトで設定された値を紐づける
  const defaultProductInspectionOptions = isNullish(defaultInspection)
    ? productInspections.map(inspectionToOption)
    : [inspectionToOption(defaultInspection)];

  // デフォルトの点検オプションのハッシュID群を取得
  const defaultProductInspectionHashIds = defaultProductInspectionOptions.map((option) => option.value.hashId);

  // 全ての点検表の一覧から機種に紐づく点検を除いた点検表一覧
  const filteredAllInspectionOptions = allInspectionOptions.options.filter(
    (option) => !defaultProductInspectionHashIds.includes(option.value.hashId)
  );
  return [
    {
      label: '機種に紐づく点検表',
      options: defaultProductInspectionOptions,
    },
    {
      label: allInspectionOptions.label,
      options: filteredAllInspectionOptions,
    },
  ];
};
