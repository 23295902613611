import {Card, CardActionArea, CardContent, RadioProps, SxProps, Theme} from '@mui/material';
import React from 'react';

type Props = {
  label: string;
  onChange: (value: string) => void;
} & Omit<RadioProps, 'onChange'>;

export const RentButton = ({label, ...props}: Props) => {
  const handleClick = () => {
    props.onChange(props.value as string);
  };

  return (
    <Card sx={cardStyle}>
      <CardActionArea onClick={handleClick} data-testid={'rent-button'}>
        <CardContent sx={contentStyle}>{label}</CardContent>
      </CardActionArea>
    </Card>
  );
};

const cardStyle: SxProps<Theme> = {
  borderRadius: '45px',
  boxShadow: '0px 8px 0px 0px rgb(209, 216, 245)',
  margin: '16px',
};

const contentStyle: SxProps<Theme> = (theme) => ({
  height: '120px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '36px',
  fontWeight: 'bold',
  color: theme.palette.rent.main,
  [theme.breakpoints.up('laptop')]: {
    height: '160px',
  },
});
