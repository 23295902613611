import React from 'react';
import {
  HospitalProductRequiredProperty,
  HospitalProductRequiredPropertyItem,
} from '@modules/hospital_product_required_properties/types';
import {Box} from '@mui/material';
import {
  StyledSettingsTempContent,
  StyledSettingsTempDivider,
  getSettingsContentTemplate,
} from '@templates/ContentLayout/InnerSidebarContentLayoutV5';
import {ProductRequiredPropertiesSettingFormInput} from './ProductRequiredPropertiesSettingFormInput';
import {ProductRequiredPropertiesSettingFormSubTitle} from './ProductRequiredPropertiesSettingFormSubTitle';
import {ProductRequiredPropertiesSettingFormTitle} from './ProductRequiredPropertiesSettingFormTitle';

type Props = {
  propertyItems: HospitalProductRequiredPropertyItem[];
  requiredProperties: HospitalProductRequiredProperty[];
  onRequiredPropertyAdd: (property: string) => void;
  onRequiredPropertyDelete: (property: string) => void;
};

export const ProductRequiredPropertiesSettingForm = (props: Props) => {
  return (
    <Box sx={getSettingsContentTemplate.form}>
      <form>
        <StyledSettingsTempContent>
          <ProductRequiredPropertiesSettingFormTitle />
          <StyledSettingsTempDivider variant="middle" />
          <ProductRequiredPropertiesSettingFormSubTitle />
          <ProductRequiredPropertiesSettingFormInput
            propertyItems={props.propertyItems}
            requiredProperties={props.requiredProperties}
            onRequiredPropertyAdd={props.onRequiredPropertyAdd}
            onRequiredPropertyDelete={props.onRequiredPropertyDelete}
          />
        </StyledSettingsTempContent>
      </form>
    </Box>
  );
};
