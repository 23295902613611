import {RentalFooterHeight} from '@Apps/BaseSharedMenu/Footer';
import {RentalHeaderHeight} from '@Apps/BaseSharedMenu/Header';
import {SxProps} from '@mui/material';

export const contentFixedStyle: SxProps = {
  display: 'flex',
  overflowY: 'auto',
  alignItems: 'center',
  position: 'fixed',
  width: '100%',
  top: `${RentalHeaderHeight}`,
  bottom: `${RentalFooterHeight}`,
};
