import React, {useMemo} from 'react';
import {Formik, useFormikContext} from 'formik';
import {FormValue} from '../types';
import {Section as SectionHeader} from '@molecules/InspectionTableFormItems';
import {Item} from '@Apps/InspectionResult/pc/EditInspectionResult/Item';
import {Grid, createStyles, makeStyles} from '@material-ui/core';
import {getFormSchema} from '@Apps/InspectionResult/common/validator';
import {InspectionItem, SectionInspectionItem} from '@modules/inspections/api';
import {formValueToInspection} from '../mapper';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      margin: '24px 0px',
    },
    items: {
      maxWidth: '700px',
      '& > div:not(:first-child)': {
        marginTop: '42px',
      },
    },
  })
);

export const Preview = () => {
  const context = useFormikContext<FormValue>();
  const {values} = context;
  const classes = useStyles();
  const inspection = useMemo(
    () => {
      // eslint-disable-next-line no-shadow
      const inspection = formValueToInspection(values, 'draft');
      return {
        ...inspection,
        items: inspection.items
          ? (inspection.items
              .map((section, sectionIndex) => {
                if (section.type !== 'section') {
                  return null;
                }

                return {
                  ...section,
                  id: `section_${sectionIndex}`,
                  items: section.items.map((field, fieldIndex) => {
                    return {
                      ...field,
                      id: `section_${sectionIndex}_field_${fieldIndex}`,
                    };
                  }),
                };
              })
              .filter((res) => res !== null) as SectionInspectionItem[])
          : undefined,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const validationSchema = useMemo(() => {
    if (!inspection || inspection.items === undefined) return null;

    return getFormSchema(inspection.items ?? []);
  }, [inspection]);

  return (
    <Formik
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onSubmit={() => {}}
      initialValues={{}}
      validationSchema={validationSchema}
      validateOnChange={true}
      validateOnBlur={true}>
      <Grid className={classes.root} container justifyContent="center">
        <Grid container direction="column" className={classes.items}>
          {(inspection.items ?? []).map((item: InspectionItem, sectionIndex) => {
            if (item.type !== 'section') {
              return null;
            }

            return (
              <SectionHeader key={`section_${sectionIndex}`} name={item.name || '無題のセクション'}>
                {item.items.map((field, fieldIndex) => {
                  const id = `section_${sectionIndex}_field_${fieldIndex}`;
                  if (field.type === null) {
                    return null;
                  }

                  return <Item key={id} item={{...field, id: id, name: field.name || '無題の項目'}} />;
                })}
              </SectionHeader>
            );
          })}
        </Grid>
      </Grid>
    </Formik>
  );
};
