import {useRepairHistoryStore} from '@modules/hospital_products/hooks/useHistory';
import {RepairHistoryState} from '@modules/hospital_products/types';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useRepairStatusStore} from '@modules/repairs/hooks';
import {RepairStatuses} from '@modules/repairs/types';

type FaultRepairMenuStoreType = {
  repairHistoryState: RepairHistoryState;
  listRepairHistoriesByHospitalProductHashId: (hashId: string) => Promise<void>;
  listRepairStatuses: () => Promise<void>;
  findRepairStatusByStatusHashId: (hashId: string) => RepairStatuses | null;
};

export const useFaultRepairMenuStore = (): FaultRepairMenuStoreType => {
  const {myInfo} = useMyInfo();
  const {state: repairHistoryState, dispatchListRepairHistories} = useRepairHistoryStore();
  const {dispatchListRepairStatuses, get: getRepairStatus} = useRepairStatusStore();

  const listRepairHistoriesByHospitalProductHashId = async (hashId: string) => {
    if (!hashId) {
      return;
    }

    const defaultOrder = '-request_for_repair_at';
    await dispatchListRepairHistories(myInfo.hospitalHashId, hashId, {order: defaultOrder});
  };

  const listRepairStatuses = async () => {
    await dispatchListRepairStatuses(myInfo.hospitalHashId);
  };

  const findRepairStatusByStatusHashId = (hashId: string) => {
    return getRepairStatus(hashId);
  };

  return {
    repairHistoryState,
    listRepairHistoriesByHospitalProductHashId,
    listRepairStatuses,
    findRepairStatusByStatusHashId,
  };
};
