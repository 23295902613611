import {Box, Button, Grid, SxProps} from '@mui/material';
import React, {useMemo} from 'react';
import {Column, RowAction} from '@molecules/Table/props';
import {NoData, Table} from '@molecules/Table';
import {MenuItemType, PopperMenuButtonV5} from '@molecules/Buttons/PopperMenuButton/PopperMenuButtonV5';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const rootStyle: SxProps = {
  padding: '24px 0px 16px',
  width: '100%',
};

const tableWrapperStyle: SxProps = {
  marginTop: '24px',
  height: 'calc(100vh - 227px)', // 画面の高さからヘッダー（203px）と24pxの余白を差し引く
  overflowY: 'auto',
};

const buttonWrapperStyle: SxProps = {
  display: 'flex',
  justifyContent: 'flex-end',
};

// TODO: 仮の型定義
type DummyType = {
  name: string;
  partCode: string;
  partCount: number;
};

// TODO: API繋ぎ込み時に削除する
const DummyData: DummyType[] = Array.from({length: 30}, (_, i) => ({
  name: `部品${i + 1}`,
  partCode: `部品品目${i + 1}`,
  partCount: i + 1,
}));

export const RepairParts = () => {
  // TODO: 型を指定して型安全にする
  // 現在は仮のフィールド構造を使用中。APIの繋ぎ込み後に、
  // 型を定義し、適切なフィールド名と型情報に基づく構造に修正する。
  const tableColumn = [
    {title: '', field: 'iconButton', sorting: false},
    {title: '部品・交換品名', field: 'name', sorting: false},
    {title: '部品・交換品目', field: 'partCode', sorting: false},
    {title: '数量', field: 'partCount', sorting: false},
  ];

  const handleActionMenuClick = (action: MenuItemType, data: DummyType) => {
    switch (action.value) {
      case 'delete':
        // 削除処理
        // TODO: 後続で実装
        console.log(data);
        break;
    }
  };

  const dataAddIconButton = React.useMemo(
    () =>
      DummyData.map((item) => {
        return {
          iconButton: <MenuButton data={item} handleActionMenuClick={handleActionMenuClick} />,
          name: item.name,
          partCode: item.partCode,
          partCount: item.partCount,
        };
      }),
    []
  );

  return (
    <Box sx={rootStyle}>
      <Box sx={buttonWrapperStyle}>
        {/* TODO: onClickは後続で実装 */}
        <Button color="primary" variant="contained" onClick={() => {}}>
          部品・交換品を登録
        </Button>
      </Box>

      <Box sx={tableWrapperStyle}>
        <Table
          columns={tableColumn}
          data={dataAddIconButton}
          tableSize="small"
          showSelection={false}
          noDataComponent={<NoData title={'部品が登録されていません'} message={''} />}
        />
      </Box>
    </Box>
  );
};

const MenuButton = ({
  data,
  handleActionMenuClick,
}: {data: DummyType; handleActionMenuClick: (action: MenuItemType, data: DummyType) => void}) => {
  return (
    <PopperMenuButtonV5<string>
      buttonProps={{variant: 'text', size: 'small', disableElevation: true}}
      menuItemList={[{label: '削除', value: 'delete'}]}
      hiddenArrow
      onMenuClick={(action) => handleActionMenuClick(action, data)}>
      <MoreVertIcon sx={{color: 'rgba(0, 0, 0, 0.87)'}} />
    </PopperMenuButtonV5>
  );
};
