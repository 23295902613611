import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import {TextField} from '@molecules/Formik/fields';
import {Close} from '@material-ui/icons';
import React, {useCallback, Suspense, useMemo, useState} from 'react';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import {Formik, useFormikContext} from 'formik';
import {useDebounceCallback, yup} from '@front-libs/core';
import {
  FetchHospitalProductsParams,
  getHospitalProduct,
  useFetchHospitalProductsQuery,
} from '@modules/hospital_products/api';
import {FetchInspectionsParams} from '@modules/inspections/api';
import {useHospitalUsers} from '@modules/hospital_users/hooks/useHospitalUsers';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {QueryKey, useQuery, UseQueryOptions} from 'react-query';
import {getHospitalProductKey} from '@constants/api';
import {HospitalProductDetail, HospitalProductIndex} from '@modules/hospital_products/types';
import {UserIndex} from '@modules/hospital_users/types';
import {HospitalProductSelector} from './HospitalProductSelector';
import {RequiredLabel} from '@molecules/FormRequiredLabel';

const useFetchHospitalProductOptionalQuery = (
  hospitalHashId: string,
  hospitalProductHashId?: string,
  options?: Omit<
    UseQueryOptions<HospitalProductDetail | null, unknown, HospitalProductDetail, QueryKey>,
    'queryKey' | 'queryFn'
  >
) => {
  return useQuery<HospitalProductDetail | null, unknown, HospitalProductDetail, QueryKey>(
    [getHospitalProductKey, hospitalHashId, hospitalProductHashId],
    async () => (hospitalProductHashId ? await getHospitalProduct(hospitalHashId, hospitalProductHashId) : null),
    options
  );
};

type FormField = {
  hospitalProductHashId: string;
  inspectionHashId: string;
  scheduledDate: string;
  inspector: string;
  isAdhoc: boolean;
};

type CreateMakerInspectionResultFormProps = {
  open: boolean;
  defaultUserHashId: string;
  hospitalProductHashId?: string;
  onClose: React.MouseEventHandler;
};

export type CreateMakerInspectionResultDialogProps = {
  // 与えられた場合は選択できないようにする
  hospitalProductHashId?: string;
  defaultInspectorHashId: string;
  defaultScheduledDate: string;
} & DialogProps;

export type CreateMakerInspectionResultDialogResult = FormField;

export const CreateMakerInspectionResultDialog: React.FC<CreateMakerInspectionResultDialogProps> = (props) => {
  const {hospitalProductHashId, defaultInspectorHashId, defaultScheduledDate} = props;

  const validationSchema = yup.object({
    hospitalProductHashId: yup.string().required(),
    scheduledDate: yup.string().required(),
    inspector: yup.string().required(),
  });

  const handleSubmit = useCallback(
    (res: FormField) => {
      props.actions.resolve(res);
    },
    [props.actions]
  );

  const handleClose = useCallback(
    (_e: React.MouseEvent) => {
      props.actions.resolve();
    },
    [props.actions]
  );

  return (
    <Suspense fallback={null}>
      <Formik
        initialValues={{
          hospitalProductHashId: hospitalProductHashId ?? '',
          inspectionHashId: '',
          scheduledDate: defaultScheduledDate,
          inspector: defaultInspectorHashId,
          isAdhoc: false,
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnMount={true}>
        <CreateMakerInspectionResultFormContainer
          open={props.open}
          hospitalProductHashId={hospitalProductHashId}
          defaultUserHashId={defaultInspectorHashId}
          onClose={handleClose}
        />
      </Formik>
    </Suspense>
  );
};

const initialInspectionVariables = {
  statuses: 'available',
  perPage: 100,
};

const CreateMakerInspectionResultFormContainer: React.FC<CreateMakerInspectionResultFormProps> = (props) => {
  const {open, hospitalProductHashId, defaultUserHashId, onClose} = props;
  const isOpen = typeof open === 'boolean' ? open : false;
  const {myInfo} = useMyInfo();
  const {hospitalUsers} = useHospitalUsers();

  const [hospitalProductsVariables, setHospitalProductsVariables] = useState<FetchHospitalProductsParams>({
    order: 'management_id',
  });

  const [productInspectionVariables, setProductInspectionVariables] =
    useState<FetchInspectionsParams>(initialInspectionVariables);

  const {isSubmitting, submitForm, isValid, setFieldValue} = useFormikContext<FormField>();
  const classNames = useStyles();

  const {data: defaultHospitalProduct} = useFetchHospitalProductOptionalQuery(
    myInfo.hospitalHashId,
    hospitalProductHashId,
    {
      onSuccess: (data: HospitalProductDetail) =>
        setProductInspectionVariables({...productInspectionVariables, wholeProductHashId: data?.wholeProductHashId}),
    }
  );

  const handleSearchHospitalProduct = useDebounceCallback(
    (name: string) => {
      if (name === '') {
        setHospitalProductsVariables({order: 'management_id'});
      } else {
        setHospitalProductsVariables({
          ...hospitalProductsVariables,
          name: name,
        });
      }
    },
    100,
    [hospitalProductsVariables, myInfo.hospitalHashId]
  );
  const hospitalProductsQuery = useFetchHospitalProductsQuery(myInfo.hospitalHashId, hospitalProductsVariables);

  const handleSelectHospitalProduct = useCallback(
    (hospitalProduct?: HospitalProductIndex) => {
      setFieldValue('hospitalProductHashId', hospitalProduct?.hashId);
      setProductInspectionVariables({
        ...productInspectionVariables,
        wholeProductHashId: hospitalProduct?.wholeProductHashId,
      });
    },
    [productInspectionVariables, setFieldValue]
  );

  const handleChangeScheduleDate = useCallback(
    (scheduleDate: string) => {
      setFieldValue('scheduledDate', scheduleDate);
    },
    [setFieldValue]
  );

  const handleClickSubmit = useCallback(() => {
    submitForm();
  }, [submitForm]);

  return (
    <Dialog open={isOpen} onClose={onClose} aria-labelledby="form-dialog-title" fullWidth maxWidth={'md'}>
      <DialogTitle>
        <Grid container justifyContent="space-between">
          <Typography className={classNames.dialogTitle}>メーカー保守点検を登録</Typography>
          <Close className={classNames.close} onClick={onClose} />
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <CreateMakerInspectionResultForm
            defaultHospitalProduct={defaultHospitalProduct ?? undefined}
            defaultHospitalUserHashId={defaultUserHashId}
            hospitalProducts={hospitalProductsQuery.data}
            hospitalUsers={hospitalUsers}
            onSearchHospitalProduct={handleSearchHospitalProduct}
            onSelectHospitalProduct={handleSelectHospitalProduct}
            onChangeScheduleDate={handleChangeScheduleDate}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button disabled={!isSubmitting && !isValid} variant={'contained'} color="primary" onClick={handleClickSubmit}>
          登録開始
        </Button>
        <Button onClick={onClose} color="primary">
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
};

type FormProps = {
  defaultHospitalProduct?: HospitalProductDetail;
  defaultHospitalUserHashId?: string;
  hospitalProducts: HospitalProductIndex[];
  hospitalUsers: UserIndex[];
  scheduleDateValue?: string;
  onSearchHospitalProduct: (name: string) => void;
  onSelectHospitalProduct: (hospitalProduct?: HospitalProductIndex) => void;
  onChangeScheduleDate: (scheduleDate: string) => void;
};

const CreateMakerInspectionResultForm: React.FC<FormProps> = (props) => {
  const classNames = useStyles();
  const {
    defaultHospitalProduct,
    hospitalProducts,
    scheduleDateValue,
    onSearchHospitalProduct,
    onSelectHospitalProduct,
    onChangeScheduleDate,
  } = props;

  const hospitalProductOptions = useMemo(
    () =>
      hospitalProducts.map((item) => ({
        label: item.managementId,
        value: item,
      })),
    [hospitalProducts]
  );

  return (
    <Grid container direction="column" className={classNames.fieldContainer}>
      <Grid item>
        <RequiredLabel>点検を実施する機器</RequiredLabel>
        {defaultHospitalProduct ? (
          <Typography>{defaultHospitalProduct.displayName}</Typography>
        ) : (
          <HospitalProductSelector
            hospitalProductOptions={hospitalProductOptions}
            onSearchHospitalProduct={onSearchHospitalProduct}
            onSelectHospitalProduct={(value?: HospitalProductIndex) => {
              onSelectHospitalProduct(value);
            }}
          />
        )}
      </Grid>
      <Grid item>
        <RequiredLabel>点検予定日</RequiredLabel>
        <TextField
          type="date"
          name="scheduledDate"
          value={scheduleDateValue}
          size="small"
          fullWidth
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeScheduleDate(e.target.value)}
        />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles({
  close: {
    cursor: 'pointer',
  },
  fieldContainer: {
    width: '100%',
    marginBottom: '16px',
    '& > :not(:first-child)': {
      marginTop: '16px',
    },
  },
  scheduledDateInput: {
    '& > input': {
      padding: '12px',
    },
  },
  dialogTitle: {
    fontWeight: 'bold',
    color: '#172B4D',
    fontSize: '20px',
  },
});
