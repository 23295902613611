import {useEffect, useReducer, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {useFetchProductQuery} from '@modules/products/api';
import {v4 as uuidv4} from 'uuid';
import {InspectionType, InspectionTypeOptions, InspectionTypeOptionsValues} from '@modules/inspections/enum';
import {
  CHANGE_INSPECTION_ACTION,
  CHANGE_INSPECTION_PERIOD_ACTION,
  CHANGE_INSPECTION_SETTING_NAME_ACTION,
  CHANGE_INSPECTION_TYPE_ACTION,
  CreateWholeProductPlanAction,
  DELETE_ACTION,
  reducerFunc,
} from './utils/reducer';
import {CreateWholeProductPlan, validateInspectionSettings} from './type';
import {BulkInsertInspectionSetting, bulkInsertInspectionSettings} from '@modules/inspection_setting/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {PeriodUnit} from '@modules/inspection_setting/types';
import {dialogHandler} from '@components/molecules/Dialogs/DialogHandler';
import {InspectionPlanForWholeProductNavigationDialog} from '@organisms/InspectionPlanForWholeProductNavigationDialog';
import {openSnackBar} from '@components/molecules/SnackBar';

export const useWholeProductPlanList = () => {
  const {wholeProductHashId} = useParams();
  const {myInfo} = useMyInfo();
  const {data: product} = useFetchProductQuery(wholeProductHashId ?? '');
  const navigate = useNavigate();

  const handleClickCancel = () => {
    navigate('/inspection_v2/whole_product_plans');
  };

  const [inspectionSettings, dispatch] = useReducer(reducerFunc, [
    {
      uuid: uuidv4(),
      inspectionSettingName: '',
      inspectionName: '',
      inspectionHashId: '',
      inspectionType: null,
      inspectionPeriod: 0,
      inspectionPeriodUnit: 'month',
    },
  ]);

  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    // バリデーションを実行し、状態を更新
    const checkValidation = async () => {
      const valid = await validateInspectionSettings(inspectionSettings);
      const isDuplicatedPeriodicInspectionSelected = hasDuplicatePeriodicInspectionHashId(inspectionSettings);
      setIsValid(valid && !isDuplicatedPeriodicInspectionSelected);
    };
    checkValidation();
  }, [inspectionSettings]);

  const handleSave = async () => {
    const newInspectionSettings: BulkInsertInspectionSetting[] = inspectionSettings.map((row) => ({
      inspectionSettingName: row.inspectionSettingName,
      inspectionHashId: row.inspectionHashId ?? '',
      inspectionPeriod: row.inspectionType === 'periodic' ? row.inspectionPeriod ?? undefined : undefined,
      inspectionPeriodUnitType: row.inspectionType === 'periodic' ? ('month' as PeriodUnit) : undefined,
    }));
    try {
      await bulkInsertInspectionSettings(myInfo.hospitalHashId, wholeProductHashId ?? '', {
        inspectionSettings: newInspectionSettings,
      });
      openSnackBar('機種別点検の設定を作成しました', 'left', 'bottom', 'success');

      // 新規機種別設定が日常点検のみの場合はダイアログを表示せずに編集画面に遷移
      if (inspectionSettings.every((setting) => setting.inspectionType !== 'periodic')) {
        navigate(`/inspection_v2/whole_product_plans/${wholeProductHashId}/edit`);
        return;
      }
      // 新規機種別設定に定期点検が含まれている場合
      try {
        await dialogHandler.open(InspectionPlanForWholeProductNavigationDialog, {});
        navigate(
          `/inspection_v2/hospital_product_plans?wholeProductHashId=${wholeProductHashId}&isDisplayAnnounceBar=true&status=unplanned`
        );
      } catch (_e) {
        navigate(`/inspection_v2/whole_product_plans/${wholeProductHashId}/edit`);
      }
    } catch (_e) {
      console.error(_e);
      openSnackBar('機種別点検の設定の作成に失敗しました', 'left', 'bottom', 'error');
    }
  };

  return {product, inspectionSettings, dispatch, handleClickCancel, isValid, handleSave};
};
type wholeProductCreatePlanProps = {
  dispatch: React.Dispatch<CreateWholeProductPlanAction>;
  inspectionSetting: CreateWholeProductPlan;
  inspectionSettings: CreateWholeProductPlan[];
};

export const useWholeProductPlan = ({dispatch, inspectionSetting, inspectionSettings}: wholeProductCreatePlanProps) => {
  const handleInspectionSettingNameChange = (inspectionSettingName: string) => {
    dispatch({type: CHANGE_INSPECTION_SETTING_NAME_ACTION, uuid: inspectionSetting.uuid, inspectionSettingName});
  };

  const handleInspectionTypeChange = (inspectionType: InspectionType) => {
    dispatch({type: CHANGE_INSPECTION_TYPE_ACTION, uuid: inspectionSetting.uuid, inspectionType});
  };

  const handleInspectionPeriodChange = (inspectionPeriod: number) => {
    dispatch({type: CHANGE_INSPECTION_PERIOD_ACTION, uuid: inspectionSetting.uuid, inspectionPeriod});
  };

  const handleInspectionChange = (inspectionHashId: string, inspectionName: string) => {
    dispatch({type: CHANGE_INSPECTION_ACTION, uuid: inspectionSetting.uuid, inspectionHashId, inspectionName});
  };

  const handleDeleteRow = () => {
    dispatch({type: DELETE_ACTION, uuid: inspectionSetting.uuid});
  };
  // 自身を対象外にし、点検タイプの一覧を表示する
  const inAvailableInspectionTypes = inspectionSettings
    .filter((item) => item.uuid !== inspectionSetting.uuid)
    .map((item) => item.inspectionType);
  // 定期点検は複数計画作成可能。日常点検は1つのみ作成可能。
  const displayInspectionTypeOptions = InspectionTypeOptions.filter((option) => {
    return (
      option.value === 'periodic' || !inAvailableInspectionTypes.includes(option.value as InspectionTypeOptionsValues)
    );
  });

  return {
    handleInspectionSettingNameChange,
    handleInspectionPeriodChange,
    handleInspectionChange,
    handleInspectionTypeChange,
    handleDeleteRow,
    displayInspectionTypeOptions,
  };
};

const hasDuplicatePeriodicInspectionHashId = (inspectionSettings: CreateWholeProductPlan[]): boolean => {
  const hashIdSet = new Set<string>();
  for (const inspectionSetting of inspectionSettings) {
    // 定期点検表のみ重複をチェック
    if (inspectionSetting.inspectionType !== 'periodic') continue;
    if (hashIdSet.has(inspectionSetting.inspectionHashId)) {
      return true; // 同じinspectionHashIdが見つかった場合
    }
    hashIdSet.add(inspectionSetting.inspectionHashId);
  }
  return false; // 重複がなかった場合
};
