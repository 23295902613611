import React from 'react';
import {BuildOutlined, DescriptionOutlined} from '@mui/icons-material';
import {InspectionIcon} from '../DialogContent/MenuContent/MenuIcons/Inspection';
import {MenuItemSetting} from '../types';

export const HospitalProductReadMessages = {
  failed: '機器情報の取得に失敗しました',
} as const;

export const MenuItemValues = ['hospitalProductDetail', 'inspectionStart', 'faultRepairDetail'] as const;

export const MenuItemSettings: readonly MenuItemSetting[] = [
  {
    icon: <DescriptionOutlined />,
    label: '機器詳細',
    value: 'hospitalProductDetail',
  },
  {
    icon: <InspectionIcon viewBox="3 3 18 18" htmlColor="#0052CC" />,
    label: '点検',
    value: 'inspectionStart',
  },
  {
    icon: <BuildOutlined />,
    label: '修理詳細',
    value: 'faultRepairDetail',
  },
] as const;
