import {Tab} from './types';

export const tabs: readonly Tab[] = [
  {
    label: '未完了',
    value: 'incomplete',
  },
  {
    label: '完了',
    value: 'done',
  },
] as const;

export const initTabValue = tabs[0].value;
