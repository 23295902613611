import React, {Suspense, useCallback} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import dayjs from 'dayjs';
import {RequiredLabel} from '../FormRequiredLabel';
import {Formik, useFormikContext} from 'formik';
import {yup} from '@front-libs/core';
import {Close} from '@material-ui/icons';

type FormField = {
  startDate: string;
  endDate: string;
};

export type DatePickerDialogProps = {
  title?: string;
  description?: string;
  startDateLabel?: string;
  endDateLabel?: string;
  initialStartDate: string;
  initialEndDate: string;
  onClose?: (e: React.MouseEvent) => void;
  open: boolean;
} & Omit<DialogProps, 'content'>;

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '24px 32px 40px',
  },
  title: {
    color: '#172B4D',
    fontSize: '20px',
    fontWeight: 'bold',
  },
  content: {
    padding: '0px 32px',
  },
  fields: {
    '& > :not(:first-child)': {
      marginTop: '24px',
    },
  },
  inputDate: {
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  actions: {
    padding: '24px 32px 32px',
  },
}));

export const CreateDatePickerDialog: React.FC<DatePickerDialogProps> = (props) => {
  const {title, description, initialStartDate, initialEndDate, startDateLabel, endDateLabel} = props;
  const validationSchema = yup.object({
    startDate: yup.date().required('開始日を選択してください'),
    endDate: yup.date().required('終了日を選択してください'),
  });

  const handleSubmit = useCallback(
    async (values: FormField) => {
      props.actions.resolve(values);
    },
    [props.actions]
  );

  const handleClose = useCallback(
    (e: React.MouseEvent) => {
      props.actions.resolve(null);
    },
    [props.actions]
  );

  return (
    <Suspense fallback={null}>
      <Formik
        initialValues={{
          startDate: initialStartDate,
          endDate: initialEndDate,
        }}
        onSubmit={handleSubmit}
        onClose={handleClose}
        validationSchema={validationSchema}
        validateOnMount={true}>
        <DateDialogForm
          open={true}
          title={title}
          description={description}
          actions={props.actions}
          initialStartDate={initialStartDate}
          initialEndDate={initialEndDate}
          startDateLabel={startDateLabel ?? '開始日'}
          endDateLabel={endDateLabel ?? '終了日'}
          onClose={handleClose}
        />
      </Formik>
    </Suspense>
  );
};

const DateDialogForm: React.FC<DatePickerDialogProps> = ({
  open,
  title,
  description,
  initialStartDate,
  initialEndDate,
  startDateLabel,
  endDateLabel,
  onClose,
  ...props
}) => {
  const classes = useStyles();
  const {errors, values, setFieldTouched, setFieldValue, submitForm} = useFormikContext<FormField>();

  const handleChangeStartDate = useCallback(
    async (date: string) => {
      await setFieldTouched('startDate', true);
      await setFieldValue('startDate', date);
    },
    [setFieldValue, setFieldTouched]
  );

  const handleChangeEndDate = useCallback(
    async (date: string) => {
      await setFieldTouched('endDate', true);
      await setFieldValue('endDate', date);
    },
    [setFieldValue, setFieldTouched]
  );

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="sm">
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Typography className={classes.title}>{title}</Typography>
        <IconButton aria-label="close" onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Typography>{description}</Typography>
        <br />
        <Grid container className={classes.fields} direction="column">
          <Grid item>
            <RequiredLabel>{startDateLabel}</RequiredLabel>
            <TextField
              type="date"
              name="startDate"
              value={values.startDate}
              size="small"
              variant="outlined"
              fullWidth
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeStartDate(dayjs(e.target.value).format('YYYY-MM-DD'))
              }
            />
          </Grid>
          <Grid item>
            <RequiredLabel>{endDateLabel}</RequiredLabel>
            <TextField
              type="date"
              name="endDate"
              value={values.endDate}
              size="small"
              variant="outlined"
              fullWidth
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeEndDate(dayjs(e.target.value).format('YYYY-MM-DD'))
              }
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          variant="contained"
          color="primary"
          onClick={submitForm}
          disabled={!!errors?.startDate || !!errors?.endDate}>
          エクスポート
        </Button>
        <Button variant="text" onClick={onClose}>
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
};
