import React from 'react';
import {styled, Box, Tabs as MuiTabs, Tab as MuiTab} from '@mui/material';
import {ScheduleStatus} from '@modules/training_schedules/types';
import {tabs} from './constants';
import {Tab} from './types';

type Props = {
  value: ScheduleStatus;
  incompleteCount: number;
  doneCount: number;
  onChange: (newValue: ScheduleStatus) => void;
};

const StyledTabs = styled(MuiTabs)({
  padding: '0 32px',
  borderBottom: '1px solid #F0F2F5',
  '& .MuiTabs-indicator': {
    backgroundColor: '#172B4D',
  },
  '& .Mui-selected': {
    fontWeight: 600,
  },
});

const Badge = styled('span')({
  backgroundColor: '#172B4D',
  color: 'white',
  marginLeft: '8px',
  padding: '2px 7px',
  borderRadius: '16px',
  fontSize: '12px',
});

export const Tabs = ({value, incompleteCount, doneCount, onChange}: Props) => {
  const count = (status: ScheduleStatus) => {
    switch (status) {
      case 'incomplete':
        return incompleteCount;
      case 'done':
        return doneCount;
      default:
        return 0;
    }
  };

  const renderLabel = (tab: Tab) => {
    const c = count(tab.value);
    if (c === 0) {
      return tab.label;
    }

    return (
      <Box>
        <span>{tab.label}</span>
        <Badge>{c}</Badge>
      </Box>
    );
  };

  const handleChange = (_: React.SyntheticEvent, newValue: ScheduleStatus) => {
    onChange(newValue);
  };

  return (
    <StyledTabs textColor="inherit" value={value} onChange={handleChange}>
      {tabs.map((tab) => (
        <MuiTab key={tab.value} label={renderLabel(tab)} value={tab.value} />
      ))}
    </StyledTabs>
  );
};
