import {Typography, styled} from '@material-ui/core';
import React from 'react';
import dayjs from 'dayjs';

/**
 * 通常カードのProps
 */
export type NormalCardProps = {
  title: React.ReactNode;
  icon: React.ReactNode;
  displayDate: Date;
  contentSlot?: {label: string; value: string}[];
  optionalItem?: JSX.Element | null;
};

/**
 * 通常カードの表示
 * @param {NormalCardProps} CommentCardProps
 * @param displayDate - 作成日のDate
 * @param contentSlot - コンテント内容(labelとvalueの配列)
 * @param optionalItem - オプショナルな表示内容
 * @returns
 */
export const NormalCard = ({title, icon, displayDate, contentSlot, optionalItem}: NormalCardProps) => {
  return (
    <RootContainer>
      <IconContainer>{icon}</IconContainer>
      <Container>
        <Header>
          {title}
          <CommentDate>{dayjs(displayDate).format('MM/DD HH:mm')}</CommentDate>
        </Header>
        <div>
          {contentSlot?.map((content, index) => (
            <Row key={`contentSlot${index}`}>
              <Label>{content.label}</Label>
              <Data>{content.value}</Data>
            </Row>
          ))}
          {optionalItem}
        </div>
      </Container>
    </RootContainer>
  );
};

const RootContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  margin: '16px',
  width: '100%',
});

const IconContainer = styled('div')({
  width: '32px',
  height: '32px',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#F0F2F5',
});

const CommentDate = styled(Typography)({
  fontSize: '12px',
  color: '#65676B',
});

const Container = styled('div')({
  paddingLeft: '8px',
  width: '100%',
});

const Header = styled('div')({
  display: 'flex',
  alignItems: 'center',
  height: '32px',
  paddingBottom: '8px',
  justifyContent: 'space-between',
});

const Row = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '4px',
});

const Label = styled('div')({
  fontSize: '14px',
  color: '#172B4D',
});

const Data = styled(Typography)({
  fontSize: '14px',
  color: '#172B4D',
});
