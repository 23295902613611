import React, {useCallback} from 'react';
import {createStyles, makeStyles, Theme, TextField, Grid} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    timeField: {
      width: '200px',
    },
  })
);

type TimeFieldProps = {
  value: string;
  error?: React.ReactNode;
  isEditable: boolean;
  showsError: boolean;
  onChange?: (value: string) => void;
};

export const TimeField: React.FC<TimeFieldProps> = (props) => {
  const {value, error, isEditable, showsError, onChange} = props;
  const classes = useStyles();

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (isEditable === true && onChange) {
        onChange(e.target.value);
      }
    },
    [onChange, isEditable]
  );

  return (
    <Grid container direction="column">
      <TextField
        className={classes.timeField}
        type="time"
        variant="standard"
        value={value}
        error={showsError === true && error !== undefined}
        onChange={handleChange}
        InputProps={{
          readOnly: !isEditable,
        }}
      />
      {error}
    </Grid>
  );
};
