import {QuaggaJSCodeReader, QuaggaJSStyle} from '@ericblade/quagga2';

// コード検出中に表示するボックスのスタイル
const boxesPathStyle: QuaggaJSStyle = {
  color: 'purple',
  lineWidth: 2,
};
// コード検出時に表示するボックスのスタイル
const boxPathStyle: QuaggaJSStyle = {
  color: 'blue',
  lineWidth: 2,
};

export const QrCodeReaderName = 'qrcode_reader';

export const Config = {
  defaultInputStreamType: 'LiveStream',
  defaultFacingMode: 'environment',
  defaultLocatorSettings: {
    patchSize: 'medium',
    halfSample: true,
  },
  defaultDecodeReaders: ['code_128_reader', 'code_39_reader', QrCodeReaderName] as QuaggaJSCodeReader[],
  defaultLocate: true,
  defaultDecodeErrorThreshold: 0.25, // 読み取ったコードの正確度が75％であるなら、そのコードを受け入れる
  boxesPathStyle,
  boxPathStyle,
} as const;
