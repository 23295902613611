import React, {useState} from 'react';
import {
  ClickAwayListener,
  Button,
  ButtonProps,
  Paper,
  Popper,
  MenuList,
  MenuItem,
  Grow,
  styled,
  ListItemText,
} from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {SxProps, Theme} from '@mui/system';
import {CSSObject} from '@emotion/react';

export type NestedMenuListItemType<T> = {
  label: React.ReactNode;
  value: T | NestedMenuListItemType<T>[];
  sx?: CSSObject;
};

type Props<T> = {
  menuItemList: NestedMenuListItemType<T>[];
  children: React.ReactNode;
  onMenuClick: (item: NestedMenuListItemType<T>) => void;
  buttonProps?: ButtonProps;
};

const ContainerDiv = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const popperStyles: SxProps<Theme> = {
  zIndex: 1000,
  maxHeight: 360,
  overflow: 'auto',
};

const paperStyles: SxProps<Theme> = (theme) => ({
  border: `1px solid ${theme.palette.grey[300]}`,
});

const menuListColumnStyles: SxProps<Theme> = {
  display: 'block',
  padding: '8px',
};

const menuItemStyles: SxProps<Theme> = (theme) => ({
  '&:hover': {
    // FIXME:themeから取得出来ていないため、一旦直接指定
    // backgroundColor: theme.palette.secondary.light,
    backgroundColor: '#E2F4FB',
  },
});

type MenuItemListProps<T> = {
  menuItemList: NestedMenuListItemType<T>[];
  handleMenuClick: (item: NestedMenuListItemType<T>, e: React.MouseEvent<HTMLButtonElement | HTMLLIElement>) => void;
};

type MenuElementProps<T> = {
  index: number;
  selectedIndex: number | undefined;
  setSelectedIndex: (index: number | undefined) => void;
  item: NestedMenuListItemType<T>;
} & Omit<MenuItemListProps<T>, 'menuItemList'>;

/**
 * メニューリストの一行
 */
const MenuItemElement = <T,>({item, handleMenuClick, index, selectedIndex, setSelectedIndex}: MenuElementProps<T>) => {
  const HandleClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    if (Array.isArray(item.value)) setSelectedIndex(index);
    handleMenuClick(item, e);
  };
  const handleMouseEnter = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    if (Array.isArray(item.value)) HandleClick(e);
  };

  return (
    <MenuItem
      tabIndex={index}
      sx={{...menuItemStyles, ...item.sx}}
      selected={index === selectedIndex}
      onMouseEnter={handleMouseEnter}
      onClick={HandleClick}>
      <ListItemText> {item.label}</ListItemText>
      {Array.isArray(item.value) && <KeyboardArrowRightIcon />}
    </MenuItem>
  );
};

/**
 * メニューリスト内部
 * @param param0
 * @returns
 */
const MenuItemList = <T,>({menuItemList, handleMenuClick}: MenuItemListProps<T>) => {
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>();
  return (
    <MenuList sx={menuListColumnStyles}>
      {menuItemList.map((item, index) => {
        return (
          <MenuItemElement<T>
            key={`MenuItem${index}`}
            item={item}
            index={index}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            handleMenuClick={handleMenuClick}
          />
        );
      })}
    </MenuList>
  );
};

type PopperItemListProps<T> = {
  open: boolean;
  anchorEl: HTMLButtonElement | HTMLLIElement | null;
  placement: 'bottom' | 'left';
} & MenuItemListProps<T>;

/**
 * トランジションで表示されるメニューリスト
 * @param param0
 * @returns
 */
const PopperItemList = <T,>({open, anchorEl, placement, menuItemList, handleMenuClick}: PopperItemListProps<T>) => {
  return (
    <Popper open={open} transition placement={placement} anchorEl={anchorEl} sx={popperStyles}>
      {({TransitionProps}) => (
        <Grow {...TransitionProps} style={{transformOrigin: placement}}>
          <Paper id="menu-list-grow" elevation={2} sx={paperStyles}>
            <MenuItemList<T> menuItemList={menuItemList} handleMenuClick={handleMenuClick} />
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

/**
 * ネストされたサブメニューを持つメニューリスト
 *
 * @example
 * ```tsx
 * export type RegistrationValueType =
 * | 'search_registration'
 * | 'input_search_registration'
 * | 'select_registration';
 *
 * // valueが配列の時、ネストしたメニューが表示されます。ジェネリクスでValueの型を定義します
 * export const NestedMenuListItems: NestedMenuListItemType<RegistrationValueType>[] = [
 *   {
 *     label: '院内で未登録の機種から登録',
 *     value: [
 *       {
 *         label: '機種を検索して登録',
 *         value: 'search_registration',
 *       },
 *       {
 *         label: '機種情報を手入力して登録',
 *         value: 'input_search_registration',
 *       },
 *     ],
 *   },
 *   {
 *     label: '院内機種一覧から選択して登録',
 *     value: 'select_registration',
 *   },
 * ];
 *
 * // 以下のように呼び出します。onMenuClickのリターンはvalueが配列出ない時のみ返します
 * <NestedMenuList menuItemList={NestedMenuListItems} onMenuClick={nestedMenuListClick}>
 *   機器を新規登録 <ArrowDropDown fontSize="small" />
 * </NestedMenuList>
 *
 * ```
 *
 * @param children
 * @param menuItemList 表示するリスト valueが配列であればサブメニュー表示する valueの型はジェネリクスで指定できる
 * @param onMenuClick メニュー選択時のコールバック関数 NestedMenuListItemType<T = string>が返る
 * @returns
 */
export const NestedMenuList = <T,>({children, buttonProps, menuItemList, onMenuClick}: Props<T>) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  // サブメニュー用State
  const [subOpen, setSubOpen] = useState(false);
  const [subAnchorEl, setSubAnchorEl] = useState<HTMLButtonElement | HTMLLIElement | null>(null);
  const [subMenuItemList, setSubMenuItemList] = useState<NestedMenuListItemType<T>[]>(menuItemList);

  const toggleMenu = () => {
    setOpen(!open);
    if (subOpen) setSubOpen(false);
  };

  const handleClickButton = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
    toggleMenu();
  };

  const handleClose = (): void => {
    if (open) setOpen(false);
    if (subOpen) setSubOpen(false);
  };

  const handleMenuClick = (item: NestedMenuListItemType<T>, e: React.MouseEvent<HTMLButtonElement | HTMLLIElement>) => {
    if (typeof item.value === 'string') {
      onMenuClick(item);
      handleClose();
      return;
    } else if (Array.isArray(item.value)) {
      setSubMenuItemList(item.value as NestedMenuListItemType<T>[]);
      setSubAnchorEl(e.currentTarget);
      setSubOpen(true);
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <ContainerDiv>
        <Button {...buttonProps} onClick={handleClickButton}>
          {children}
        </Button>
        <PopperItemList
          open={open}
          anchorEl={anchorEl}
          placement="bottom"
          menuItemList={menuItemList}
          handleMenuClick={handleMenuClick}
        />
        <PopperItemList
          open={subOpen}
          anchorEl={subAnchorEl}
          placement="left"
          menuItemList={subMenuItemList}
          handleMenuClick={handleMenuClick}
        />
      </ContainerDiv>
    </ClickAwayListener>
  );
};
