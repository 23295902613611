import React, {forwardRef} from 'react';
import {InputBase, SxProps} from '@mui/material';

const InputBaseStyle: SxProps = {
  marginTop: '4px',
  padding: '4px 0px 0px',
  marginBottom: '16px',
  width: '100%',
  '& .MuiInputBase-inputMultiline': {
    borderRadius: '10px',
    backgroundColor: '#F3F4F6',
    fontSize: '20px',
    padding: '16px',
    lineHeight: '1.25em',
  },
};

type Props = {
  name: string;
  rows?: number;
  value?: string;
  onChange: (val: string) => void;
};

/**
 * 返却待ち受付のテキスト入力フォーム
 * ReactHookForm使用のため、forwardRefを使用
 */
export const FormTextField = forwardRef<HTMLInputElement, Props>(({rows = 1, name, value, onChange}, ref) => {
  return (
    <InputBase
      id={name}
      name={name}
      placeholder="入力してください"
      multiline
      rows={rows}
      value={value}
      onChange={(e) => onChange(e.target.value || '')}
      sx={InputBaseStyle}
      inputRef={ref} // refを正しく渡す
    />
  );
});

FormTextField.displayName = 'FormTextField'; // デバッグ用に名前を設定
