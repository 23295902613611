import React, {useCallback, useEffect, useState} from 'react';
import clsx from 'clsx';
import {TextField, makeStyles, FormControlLabel, Checkbox} from '@material-ui/core';
import {Search, Close} from '@material-ui/icons';
import {ControlProps, OptionProps} from 'react-select';
import {SelectOptionProps} from '../PopperSelectBoxButton';
import _ from 'lodash';
import {isMobile} from 'react-device-detect';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';

export const Control: React.FC<ControlProps<SelectOptionProps, true | false>> = ({selectProps}) => {
  const {inputValue, isSearchable, autoFocus, placeholder, onInputChange} = selectProps;

  const classes = useStyles();
  const [value, setValue] = useState<string>('');

  useEffect(() => {
    if (onInputChange && inputValue !== value && isSearchable) {
      onInputChange(value, {action: 'input-change'});
    }
  }, [value, onInputChange, inputValue, isSearchable]);

  const handleSearchText = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  }, []);

  const handleClear = useCallback(() => {
    setValue('');
  }, []);

  return (
    <div className={classes.searchControlWrapper} style={{display: isSearchable ? 'block' : 'none'}}>
      <div className={classes.searchControl}>
        <TextField
          type="text"
          autoFocus={!isMobile && autoFocus}
          variant={'outlined'}
          size={'small'}
          autoComplete="off"
          placeholder={placeholder as string}
          className={classes.searchControlInput}
          InputProps={{
            endAdornment: value === '' ? <Search /> : <Close onClick={handleClear} style={{cursor: 'pointer'}} />,
          }}
          value={value}
          onChange={handleSearchText}
        />
      </div>
    </div>
  );
};

export const Option: React.FC<OptionProps<SelectOptionProps, true | false>> = ({
  isFocused,
  isSelected,
  isMulti,
  data,
  options,
  selectProps,
  getValue,
  setValue,
  innerProps,
  children,
}) => {
  const classes = useStyles();

  const handleSelectOption = useCallback(
    (e: React.MouseEvent<HTMLDivElement> | undefined) => {
      // console.log(props.isSelected);
      /* eslint-disable brace-style */
      if (isSelected) {
        // FIXME: searchAliasがないから突合のロジックがバグってる
        // console.log(props.getValue());
        // console.log(props.data);
      }
      // 複数選択がtrueの場合
      if (isMulti) {
        // チェックを外す時かつそれが「すべて選択」の場合
        if (isSelected === true && data.value === 'all') {
          setValue([], 'deselect-option');
        }
        // 上記以外でチェックを外す場合
        else if (isSelected) {
          setValue(
            (getValue() as Array<SelectOptionProps>).filter((item: SelectOptionProps) => {
              // 「すべて選択」は必ずチェックを外す
              if (item.value === 'all') {
                return false;
              }
              return !_.isEqual(item, data);
            }),
            'deselect-option'
          );
        }
        // チェックをつける時かつ、それが「すべて選択」または「すべて選択」以外全てのオプションにチェックがつく場合
        else if (
          isSelected === false &&
          (data.value === 'all' ||
            (selectProps.allowSelectAll &&
              (getValue() as Array<SelectOptionProps>).concat(data).length === options.length - 1))
        ) {
          setValue(options, 'select-option');
        }
        // 上記以外でチェックをつける場合
        else {
          setValue((getValue() as Array<SelectOptionProps>).concat(data), 'select-option');
        }
      }
      // 複数選択がfalseの場合
      else {
        setValue(data, 'select-option');
      }
      e?.preventDefault();
    },
    [isSelected, isMulti, data, options, getValue, setValue, selectProps]
  );

  return (
    <div
      {...innerProps}
      className={`${isFocused ? clsx(classes.selectOption, classes.optionIsFocused) : classes.selectOption} ${
        data.value === 'all' ? classes.selectAllUnderbar : null
      }`}
      onClick={handleSelectOption}
      role={'option'}
      aria-selected={isSelected}>
      <div className={classes.selectOptionContainer}>
        {isMulti ? (
          data.value === 'all' && getValue().length > 0 && getValue().length !== options.length ? (
            <label className={classes.optionLabelAll}>
              <IndeterminateCheckBoxIcon className={classes.indeterminateCheckBoxIcon} color="primary" />
              {data.label}
            </label>
          ) : (
            <FormControlLabel
              control={<Checkbox disableRipple checked={isSelected} name={data.value} color="primary" />}
              classes={{
                label: classes.optionLabel,
              }}
              label={children}
            />
          )
        ) : (
          <div style={{padding: '8px 0'}}>{children}</div>
        )}
      </div>
      {data.subLabel && (
        <div className={isMulti ? clsx(classes.optionSubLabel, classes.multiOptionSubLabel) : classes.optionSubLabel}>
          <small>{data.subLabel}</small>
        </div>
      )}
    </div>
  );
};

export const NoOptionsMessage: React.FC = () => {
  const classes = useStyles();
  return <div className={classes.noOptionsMessage}>検索結果が見つかりません</div>;
};

export const DropdownIndicator: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.dropdownIndicator}>
      <Search />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  // for Control
  searchControlWrapper: {
    backgroundColor: '#eaf0f6',
    padding: '12px',
    position: 'relative',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  searchControl: {
    display: 'inline-block',
    position: 'relative',
    verticalAlign: 'middle',
    width: '100%',
  },
  searchControlInput: {
    backgroundColor: '#fff',
    '-webkit-appearance': 'textfield',
    width: '100%',
    borderRadius: 4,
  },

  // for Option
  selectOptionContainer: {
    padding: '0 20px',
  },
  selectOption: {
    boxSizing: 'border-box',
    backgroundColor: '#fff',
    cursor: 'pointer',
    display: 'block',
    '-webkit-tap-highlight-color': 'rgba(0,0,0,0)',
  },
  optionIsFocused: {
    background: '#e5f5f8',
  },
  optionLabel: {
    margin: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    pointerEvents: 'none',
  },
  optionLabelAll: {
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    marginLeft: '-11px',
    marginRight: '16px',
    verticalAlign: 'middle',
  },
  optionSubLabel: {
    marginTop: -4,
    padding: '0 20px 8px',
    color: theme.palette.grey[600],
  },
  multiOptionSubLabel: {
    marginLeft: '32px',
  },
  selectAllUnderbar: {
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
  indeterminateCheckBoxIcon: {
    padding: 9,
  },

  // for NoOptionsMessage
  noOptionsMessage: {
    borderColor: '#eaf0f6',
    backgroundColor: '#fff',
    cursor: 'not-allowed',
    padding: '8px 20px',
    fontSize: '14px',
  },

  // for DropdownIndicator
  dropdownIndicator: {
    height: 24,
    width: 32,
  },
}));
