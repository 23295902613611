import React from 'react';
import {FaultReceptionHeader, FaultReceptionHeaderProps} from '@components/molecules/FaultReceptionHeader';
import {Box, Typography, ScopedCssBaseline, SxProps} from '@mui/material';
import {Footer, FooterProps} from '@Apps/FaultReception/Footer';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
const wardFloorContainerStyle: SxProps = {
  display: 'flex',
  alignItems: 'center',
  paddingTop: '24px',
  fontSize: '24px',
  fontWeight: 'bold',
  color: '#0052CC', // theme.palette.rent.main,
};

type WardAssetMenuTemplateProps = {
  children: React.ReactNode;
  reservationDeviceName?: string;
  breadcrumbs?: string[];
  headerProps?: FaultReceptionHeaderProps;
  footerProps?: FooterProps;
  sx?: SxProps;
};
const ArrowRight = () => <KeyboardArrowRightIcon sx={{fontSize: '30px', color: '#172B4D', margin: '0px 8px'}} />;
/**
 * Asset病棟ユーザーメニューテンプレート
 * @returns
 */
export const WardAssetMenuTemplate = ({
  children,
  reservationDeviceName,
  breadcrumbs,
  headerProps,
  footerProps,
  sx,
}: WardAssetMenuTemplateProps) => {
  return (
    <>
      <ScopedCssBaseline />
      <FaultReceptionHeader {...headerProps} />
      {reservationDeviceName && (
        <Box sx={{width: 'calc(100% - 224px)', margin: '56px auto 0'}}>
          <Typography sx={{fontSize: '24px', backgroundColor: 'white', padding: '16px 24px', borderRadius: '20px'}}>
            返却待ち受付する機器:{reservationDeviceName}
          </Typography>
          {breadcrumbs && (
            <Box sx={wardFloorContainerStyle}>
              {breadcrumbs.map((breadcrumb, index) => (
                <React.Fragment key={`breadcrumb${index}`}>
                  {index !== 0 && <ArrowRight />}
                  {breadcrumb}
                </React.Fragment>
              ))}
            </Box>
          )}
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
          position: 'fixed',
          width: 'stretch',
          top: reservationDeviceName ? '224px' : '96px',
          bottom: '128px',
          ...sx,
        }}>
        {children}
      </Box>
      {footerProps && <Footer {...footerProps} />}
    </>
  );
};
