import {InspectionList} from '@modules/inspections/types';
import {InspectionTypeValues, OperationTypeValues} from '../constants';

export type InspectionType = (typeof InspectionTypeValues)[number];

export const isInspectionType = (value: string): value is InspectionType => {
  return (InspectionTypeValues as readonly string[]).includes(value);
};

export type InspectionTypeOption = {
  value: InspectionType;
  label: string;
};

export type OperationType = (typeof OperationTypeValues)[number];

export type InspectionSummaryRow = {
  label: string;
  value: string;
};

export enum InspectionActionType {
  INSPECTION_SET_LIST = 'INSPECTION_SET_LIST',
}

export type InspectionSetListAction = {
  type: InspectionActionType.INSPECTION_SET_LIST;
  payload: {
    allInspections: InspectionList[];
    wholeProductInspections: InspectionList[];
  };
};

export type InspectionAction = InspectionSetListAction;

export type InspectionState = {
  allInspections: InspectionList[]; // 「全ての点検表」として表示する点検表
  wholeProductInspections: InspectionList[]; // 「機種に紐づく点検表」として表示する点検表
};
