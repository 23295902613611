export const HospitalProductPlanStatusOptions = [
  {
    value: 'planned',
    label: '計画済み',
  },
  {
    value: 'unplanned',
    label: '未計画',
  },
] as const;

export type HospitalProductPlanStatus = 'all' | 'planned' | 'unplanned';
