import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {
  GetHospitalWholeProductParam,
  useFetchHospitalWholeProductsQuery,
  useFetchProductQuery,
} from '@modules/products/api';
import {isNullish} from '@front-libs/helpers';
import {useWholeProductHashId} from './states';

const PER_PAGE = 100;

export const useWholeProductPopperButton = () => {
  const {myInfo} = useMyInfo();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState<string | null>(null);
  const [page, setPage] = useState<number>(1);
  const [wholeProductHashId, setWholeProductHashId] = useWholeProductHashId();

  const res = useFetchProductQuery(wholeProductHashId);
  const selected = useMemo(() => {
    if (isNullish(wholeProductHashId) || isNullish(res.data)) {
      return null;
    }
    return res.data.name;
  }, [res.data, wholeProductHashId]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setOpen((prev) => !prev);
  };

  const handleClear = () => {
    setWholeProductHashId('');
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchName = event.target.value;
    setSearchText(searchName);
  };

  const handleItemClick = (hashId: string, name: string) => {
    setWholeProductHashId(hashId);
    setOpen(false);
  };
  const buttonRef = useRef<HTMLButtonElement>(null);

  const displayButtonLabel = selected ? selected : '機種名・型式';
  const isCleared = selected !== null;
  const query: GetHospitalWholeProductParam = {
    name: !isNullish(searchText) ? searchText : undefined,
    hasInspectionSetting: true,
    perPage: PER_PAGE,
    page: page - 1,
  };
  const {data: wholeProduct, totalCount} = useFetchHospitalWholeProductsQuery(myInfo.hospitalHashId, query);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const totalPage = useMemo(() => Math.ceil(totalCount / PER_PAGE), [totalCount]);

  const startDisplayPosition = useMemo(() => {
    return totalCount === 0 ? 0 : Math.ceil((page - 1) * PER_PAGE + 1);
  }, [page, totalCount]);

  const endDisplayPosition = useMemo(() => {
    const endPosition = Math.ceil(page * PER_PAGE);
    return endPosition > totalCount ? totalCount : endPosition;
  }, [page, totalCount]);

  return {
    anchorEl,
    open,
    handleClick,
    searchText,
    handleInputChange,
    handleItemClick,
    buttonRef,
    displayButtonLabel,
    wholeProduct,
    wholeProductHashId,
    handlePageChange,
    totalPage,
    totalCount,
    startDisplayPosition,
    endDisplayPosition,
    page,
    handleClose,
    handleClear,
    isCleared,
  };
};
