import React, {useCallback} from 'react';
import {Button, Checkbox, createStyles, makeStyles, styled, TextField, Theme} from '@material-ui/core';
import {useSteps} from '../hooks';
import {Form, Formik, useFormikContext} from 'formik';
import {yup} from '@front-libs/core';
import {InferType} from 'yup';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {updateHospitalUser} from '@modules/hospital_users/api';
import Select, {ValueType} from 'react-select';
import {occupationOptions} from '../constants';
import {openSnackBar} from '@molecules/SnackBar';

const validationSchema = yup.object({
  lastName: yup.string().required(),
  firstName: yup.string(),
  occupations: yup.array(
    yup.object({
      label: yup.string().required(),
      value: yup.string().required(),
    })
  ),
  isSafetyControlManager: yup.bool(),
  isUserAdmin: yup.bool(),
});

type UserInformationFormType = InferType<typeof validationSchema>;

const UserInformationForm: React.FC = () => {
  const classes = useStyles();
  const {submitForm, values, handleChange, setFieldValue} = useFormikContext<UserInformationFormType>();

  return (
    <Form id="tutorial-step2-name-occupations">
      <div>
        <p className={classes.textFieldTitle}>姓名</p>
        <TextField
          name="lastName"
          type="text"
          variant="outlined"
          size={'small'}
          value={values.lastName}
          onChange={handleChange}
          style={{marginRight: 30}}
        />
        <TextField
          name="firstName"
          type="text"
          variant="outlined"
          size={'small'}
          value={values.firstName}
          onChange={handleChange}
        />
      </div>

      <div style={{marginTop: 40}}>
        <p className={classes.textFieldTitle}>資格・役割</p>
        <Select
          isMulti={true}
          name="occupations"
          value={values.occupations}
          options={occupationOptions}
          closeMenuOnSelect={false}
          onChange={(val: ValueType<{label: string; value: string}, true>) => {
            setFieldValue('occupations', val);
          }}
        />
      </div>
      <div style={{marginTop: 40}}>
        <label onClick={() => setFieldValue('isSafetyControlManager', !values.isSafetyControlManager)}>
          <Checkbox value="true" checked={values.isSafetyControlManager} />{' '}
          <span>医療機器安全管理責任者として設定</span>
        </label>
      </div>
      {values.isUserAdmin && (
        <div>
          <Checkbox disabled checked={values.isUserAdmin} />{' '}
          <CheckboxLabel>ユーザ管理者として設定されています。</CheckboxLabel>
        </div>
      )}

      <div style={{marginTop: 8}}>
        <Button fullWidth color="primary" variant="contained" onClick={submitForm}>
          変更する
        </Button>
      </div>
    </Form>
  );
};

export const UserInformationSetting: React.FC = () => {
  const {myInfo, refetch} = useMyInfo();
  const {next, currentStep} = useSteps();
  const submitForm = useCallback(
    async (values: UserInformationFormType) => {
      await updateHospitalUser(myInfo.hospitalHashId, myInfo.hashId, {
        lastName: values.lastName,
        firstName: values.firstName !== '' ? values.firstName : undefined,
        occupations: values.occupations?.map((item) => item.value) ?? [],
        initialized: true,
        nextInitializeStep: currentStep + 1,
      });
      next(true);
      refetch();
      openSnackBar('ユーザー情報を更新しました。', 'left', 'bottom');
      window.location.reload();
    },
    [currentStep, myInfo.hashId, myInfo.hospitalHashId, next, refetch]
  );

  return (
    <Formik<UserInformationFormType>
      initialValues={{
        lastName: myInfo.lastName,
        firstName: myInfo.firstName,
        occupations: myInfo.occupations?.map((item) => ({
          value: item,
          label: occupationOptions.find((occ) => occ.value === item)?.label ?? '',
        })),
        isSafetyControlManager: myInfo.isSafetyControlManager,
        isUserAdmin: myInfo.isUserAdmin,
      }}
      onSubmit={submitForm}>
      <UserInformationForm />
    </Formik>
  );
};

const CheckboxLabel = styled('span')(({theme}) => ({
  color: theme.palette.grey[400],
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      width: '100%',
    },
    dialogActions: {
      display: 'flex',
      justifyContent: 'end',
      padding: '16px 24px 24px',
    },
    textFieldTitle: {
      marginTop: 0,
      marginBottom: 8,
      fontSize: 14,
    },
    textSubTitle: {
      color: theme.palette.grey[600],
    },
    checkCircleIconSuccess: {
      color: theme.palette.success.main,
    },
    checkCircleIconFailure: {
      color: theme.palette.grey[400],
    },
  })
);
