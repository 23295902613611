import {styled, TextField} from '@mui/material';
import React, {useState} from 'react';
import {AlertDialog} from '@molecules/Dialogs/AlertDialog';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';

const AlertTextField = styled(TextField)({
  width: '100%',
  marginTop: '6px',
});

const UserNameSpan = styled('span')({
  fontWeight: 'bold',
});

export type InspectionDeleteDialogProps = {
  userName: string;
} & DialogProps;

export const InspectionDeleteDialog = ({userName, open, actions}: InspectionDeleteDialogProps) => {
  const [fieldValue, setFieldValue] = useState('');
  const handleDisabled = () => {
    return userName !== fieldValue;
  };
  return (
    <AlertDialog
      title={'機器を削除しますか？'}
      positiveButtonLabel={'機器を削除'}
      disabled={handleDisabled()}
      content={
        <>
          <div>1件の機器を削除しようとしています。削除したデータは削除すると復元できなくなります。</div>
          <br />
          <div>
            ユーザ名(<UserNameSpan>{userName}</UserNameSpan>)を下に入力してください <br />
            <AlertTextField label={userName} variant="outlined" onChange={(e) => setFieldValue(e.target.value)} />
          </div>
        </>
      }
      open={!!open}
      actions={actions}
    />
  );
};
