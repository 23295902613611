export const InspectionStatusOptions = [
  {
    value: 'available',
    label: '利用可能',
  },
  {
    value: 'draft',
    label: '下書き',
  },
] as const;

export const InspectionStatuses = [...InspectionStatusOptions.map((o) => o.value), 'disabled'];

export type InspectionStatus = (typeof InspectionStatuses)[number];

export const InspectionTypeOptions = [
  {
    value: 'pre_use',
    label: '使用前点検',
  },
  {
    value: 'in_use',
    label: '使用中点検',
  },
  {
    value: 'post_use',
    label: '使用後点検',
  },
  {
    value: 'periodic',
    label: '定期点検',
  },
] as const;

type InspectionTypeOption = (typeof InspectionTypeOptions)[number];

/**  スマホ版で型指定に使用*/
export type InspectionTypeOptionsValues = (typeof InspectionTypeOptions)[number]['value'];

export const InspectionTypes = InspectionTypeOptions.map((o) => o.value);

// 点検タイプ: 抽出条件・表示用 (日常点検 なども含有)
// NOTE:InspectionTypes以外の文字列も使用するようになっているので責務を分けるために別のType追加を検討したい
export type InspectionType = (typeof InspectionTypes)[number] | 'daily' | 'maker_periodic' | 'pre_use,in_use,post_use';

export const InspectionTypeMap = InspectionTypeOptions.reduce(
  (a, o) => ({
    ...a,
    [o.value]: o,
  }),
  {} as Record<InspectionType, InspectionTypeOption>
);

export const InspectionCategoryOptions = [
  {
    value: 'in_hospital',
    label: '院内点検',
  },
  {
    value: 'maker',
    label: 'メーカー点検',
  },
];

export const InspectionCategories = InspectionCategoryOptions.map((o) => o.value);

export type InspectionCategory = (typeof InspectionCategories)[number];

// 点検タイプ型 から 表示名への変換用オブジェクト
export const InspectionTypesToLabel: {[key in InspectionType]: string} = {
  pre_use: '使用前点検',
  in_use: '使用中点検',
  post_use: '使用後点検',
  periodic: '定期点検',
  maker_periodic: '',
  // 点検結果データとしては存在しないが、CSVエクスポートのダイアログ表示で使用する
  daily: '日常点検',
  'pre_use,in_use,post_use': '',
};
