import {styled} from '@material-ui/core';
import {Button, Divider, Paper} from '@mui/material';
import {CreateWholeProductList} from './CreateWholeProductList';
import {withSuspense} from '@front-libs/core';
import {useWholeProductPlanList} from './hooks';
import React from 'react';

const BasePaper = styled(Paper)({
  height: '100vh',
  position: 'absolute', // 位置を絶対位置指定
  top: 0, // 上端
  left: 0, // 左端
  width: '100%', // 横幅
});

const Header = styled('div')({
  margin: '16px 24px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const ProductInfo = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const ActButtons = styled('div')({
  display: 'flex',
  justifyContent: 'column',
});

const BodyContainer = styled('div')({
  margin: '16px 24px',
  display: 'flex',
  flexDirection: 'column',
});

const _CreateWholeProductPlanList = () => {
  const {product, inspectionSettings, dispatch, handleClickCancel, isValid, handleSave} = useWholeProductPlanList();

  return (
    <BasePaper>
      <Header>
        <ProductInfo>
          <div>機種名　：{product?.displayName}</div>
          <div>型式　　：{product?.name}</div>
          <div>メーカー：{product?.maker.name}</div>
        </ProductInfo>
        <ActButtons>
          <Button
            variant={'outlined'}
            color={'primary'}
            sx={{background: '#FFFFFF', marginRight: '16px'}}
            onClick={() => {
              handleClickCancel();
            }}>
            保存せず終了
          </Button>
          <Button type="submit" variant={'contained'} color={'primary'} disabled={!isValid} onClick={handleSave}>
            保存
          </Button>
        </ActButtons>
      </Header>
      <Divider />
      <BodyContainer>
        <CreateWholeProductList inspectionSettings={inspectionSettings} dispatch={dispatch} />
      </BodyContainer>
    </BasePaper>
  );
};

export const CreateWholeProductPlanList = withSuspense(_CreateWholeProductPlanList, null);
