import {FetchInspectionResultsParam, getInspectionResults} from '@modules/inspection_results/api';
import {upsertInspectionResults} from './repository';
import dayjs from 'dayjs';

const inspectionHashId = 'dummyHashId';
const NUM_PER_PAGE = 100;

// 再帰的にページを取得する関数
export const upsertAllPagesForInspectionResult = async (
  hospitalHashId: string,
  inspectionType: string,
  status: string,
  page: number,
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  conditions: Record<string, any> = {},
  updatedAt?: string
) => {
  if (updatedAt) {
    conditions.updatedAtFrom = updatedAt;
  }
  const {data, isNextPage} = await getOfflineInspectionResult(hospitalHashId, inspectionType, status, page, conditions);
  await upsertInspectionResults(data); // データを保存

  if (isNextPage) {
    // 次のページがある場合は再帰的に次のページを取得
    await upsertAllPagesForInspectionResult(hospitalHashId, inspectionType, status, page + 1, conditions, updatedAt);
  }
};

// 条件に沿った対象のデータを取得
export const getOfflineInspectionResult = async (
  hospitalHashId: string,
  inspectionType: string,
  status: string,
  page: number,
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  conditions: Record<string, any> = {}
) => {
  const params: FetchInspectionResultsParam = {
    perPage: NUM_PER_PAGE,
    page: page,
    types: inspectionType,
    statuses: status,
    ...conditions, // conditionsを展開してマージ
  };

  const result = await getInspectionResults(hospitalHashId, inspectionHashId, params);
  const isNextPage = page < result.page; // 次のページがあるか確認
  return {data: result.data, isNextPage};
};

// 日時の計算結果を取得する関数
export const getDateCalculations = () => {
  return {
    twoDaysAgo: dayjs().subtract(3, 'day').endOf('day').format(),
    oneMonthLater: dayjs().add(1, 'month').endOf('month').format(),
    twoMonthsAgo: dayjs().subtract(2, 'month').subtract(1, 'day').endOf('day').format(),
  };
};
