import {useQuery} from 'react-query';
import {getUnitRelationsKey} from '@constants/api';
import {getUnitRelations} from './api';

export const useFetchUnitRelationsQuery = (hospitalHashId: string, parentProductHashId: string) => {
  const query = useQuery(
    [getUnitRelationsKey, hospitalHashId, parentProductHashId],
    () => getUnitRelations(hospitalHashId, parentProductHashId),
    {
      enabled: !!hospitalHashId && !!parentProductHashId,
      refetchOnWindowFocus: false,
    }
  );

  return {data: query.data, isLoading: query.isLoading, refetch: query.refetch, error: query.error};
};
