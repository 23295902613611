import {Product} from '../state';

/**
 * Productの配列を一括登録用のFormatに変換する
 * 変換内容：isBaseUnitがnotBaseUnitの場合はnullに変換する
 * @param products Product[]
 * @returns Product[]
 */
export const convertToBulkCreate = (products: Product[]) => {
  return products.map((product) => {
    return {
      ...product,
      isBaseUnit: product.isBaseUnit === 'notBaseUnit' ? null : product.isBaseUnit,
    };
  });
};
