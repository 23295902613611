import React from 'react';
import {Box, SxProps, Theme} from '@mui/material';
import Logo from '../../../assets/asset_logo.png';

type Props = {
  onClick?: () => void;
};

const logoStyle: SxProps<Theme> = {
  width: 132,
  objectFit: 'contain',
  WebkitTouchCallout: 'none',
  msTouchSelect: 'none',
  WebkitUserSelect: 'none',
  MozUserSelect: 'none',
  userSelect: 'none',
  pointerEvents: 'none',
};

/**
 * HITOTSULogo コンポーネント
 *
 */
export const HITOTSULogo = ({onClick}: Props) => {
  return (
    <Box
      component="img"
      sx={logoStyle}
      src={Logo}
      alt="HITOTSU logo"
      onClick={onClick}
      onContextMenu={(e) => {
        e.preventDefault();
        e.stopPropagation();
        return false;
      }}
    />
  );
};
