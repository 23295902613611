import {Stack} from '@mui/material';
import React from 'react';

const styles = {
  noUploadFile: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    color: '#5D6B82',
  },
};

type NoUploadedFileProps = {
  canUpload: boolean;
};

export const NoUploadedFile = (props: NoUploadedFileProps) => {
  const {canUpload} = props;
  return (
    <Stack sx={styles.noUploadFile}>
      {canUpload ? (
        <>
          点検マニュアルが登録されていません。
          <br />
          右のボタンから点検マニュアルをアップロードできます。
          <br />
          <br />
          アップロード可能なファイルは1件です。
          <br />
          PDFファイルのみ対応しています。
        </>
      ) : (
        '点検マニュアルが登録されていません。'
      )}
    </Stack>
  );
};
