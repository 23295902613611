import {PatchWholeProductParams, UpdateWholeProductColumns} from '@modules/products/api';
import {WholeProductFormValue} from './hooks';
import {isNullish} from '@front-libs/helpers';
import _ from 'lodash';

/**
 * 型ガード関数: UpdateWholeProductColumns 型かどうかをチェックする
 * @param value チェックする値
 * @returns UpdateWholeProductColumns 型である場合に true を返す
 */
const isUpdateWholeProductColumn = (value: string): value is UpdateWholeProductColumns => {
  return [
    'narrow_category_hash_id',
    'display_name',
    'name',
    'approval_number',
    'jmdn_code',
    'jan_code',
    'is_specific_maintain',
    'class_name',
    'catalog_price',
    'maker_hash_id',
    'new_maker_name',
  ].includes(value);
};

/**
 * 機種のForm情報からAPIのリクエストパラメータを生成する
 * @param formValues 機種のForm情報
 * @param updateFieldKeys 更新されたキーの一覧
 * @returns
 */
export const convertFormFieldToPatchWholeProductAPI = (
  formValues: WholeProductFormValue,
  updateFieldKeys: (keyof WholeProductFormValue)[]
): PatchWholeProductParams => {
  const res: PatchWholeProductParams = {
    updateColumns: [],
  };

  const handlers: Record<string, () => void> = {
    displayName: () => {
      res.displayName = formValues.displayName;
      const columnName = _.snakeCase('displayName');
      if (isUpdateWholeProductColumn(columnName)) {
        res.updateColumns.push(columnName);
      }
    },
    name: () => {
      res.name = formValues.name;
      const columnName = _.snakeCase('name');
      if (isUpdateWholeProductColumn(columnName)) {
        res.updateColumns.push(columnName);
      }
    },
    approvalNumber: () => {
      res.approvalNumber = formValues.approvalNumber;
      const columnName = _.snakeCase('approvalNumber');
      if (isUpdateWholeProductColumn(columnName)) {
        res.updateColumns.push(columnName);
      }
    },
    janCode: () => {
      res.janCode = formValues.janCode;
      const columnName = _.snakeCase('janCode');
      if (isUpdateWholeProductColumn(columnName)) {
        res.updateColumns.push(columnName);
      }
    },
    jmdnCode: () => {
      res.jmdnCode = !isNullish(formValues.jmdnCode) ? Number(formValues.jmdnCode) : undefined;
      const columnName = _.snakeCase('jmdnCode');
      if (isUpdateWholeProductColumn(columnName)) {
        res.updateColumns.push(columnName);
      }
    },
    catalogPrice: () => {
      res.catalogPrice = formValues.catalogPrice ? Number(formValues.catalogPrice) : undefined;
      const columnName = _.snakeCase('catalogPrice');
      if (isUpdateWholeProductColumn(columnName)) {
        res.updateColumns.push(columnName);
      }
    },
    className: () => {
      res.className = formValues.className;
      const columnName = _.snakeCase('className');
      if (isUpdateWholeProductColumn(columnName)) {
        res.updateColumns.push(columnName);
      }
    },
    specificMaintain: () => {
      if (formValues.specificMaintain === 'apply') {
        res.isSpecificMaintain = true;
      } else if (formValues.specificMaintain === 'not_apply') {
        res.isSpecificMaintain = false;
      } else {
        res.isSpecificMaintain = undefined;
      }
      if (isUpdateWholeProductColumn('is_specific_maintain')) {
        res.updateColumns.push('is_specific_maintain');
      }
    },
    narrowCategory: () => {
      res.narrowCategoryHashId = formValues.narrowCategory?.hashId;
      if (isUpdateWholeProductColumn('narrow_category_hash_id')) {
        res.updateColumns.push('narrow_category_hash_id');
      }
    },
    maker: () => {
      if (formValues.maker?.hashId === undefined && formValues.maker?.name) {
        res.newMakerName = formValues.maker.name;
        if (isUpdateWholeProductColumn('new_maker_name')) {
          res.updateColumns.push('new_maker_name');
        }
      } else {
        if (isUpdateWholeProductColumn('maker_hash_id')) {
          res.updateColumns.push('maker_hash_id');
        }
        res.makerHashId = formValues.maker?.hashId;
      }
    },
  };

  for (const key of updateFieldKeys) {
    const handler = handlers[key];
    if (handler) {
      handler();
    }
  }

  return res;
};
