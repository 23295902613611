import React from 'react';
import {ChevronLeft} from '@mui/icons-material';
import {Button, CSSObject} from '@mui/material';

const prevButtonStyles: CSSObject = {
  color: '#050505',
  '&:hover': {
    backgroundColor: 'inherit',
  },
};

type HeaderBackwardButtonProps = {
  label: string;
  onClick: () => void;
};

/**
 * ヘッダーの共通の戻るボタン
 * @TODO Mui Linkコンポーネントを使って遷移するように変更する
 */
export const HeaderBackwardButton = ({label, onClick}: HeaderBackwardButtonProps) => {
  return (
    <Button color="inherit" sx={prevButtonStyles} onClick={onClick} startIcon={<ChevronLeft />}>
      {label}
    </Button>
  );
};
