import {axios} from '@front-libs/core';
import {InspectionStatus, InspectionType} from '@modules/inspections/enum';
import {InspectionIndex, InspectionList} from '@modules/inspections/types';
import {useQuery} from 'react-query';
import {getInspectionKey, getInspectionsKey, getInspectionCountsKey, getInspectionListKey} from '@constants/api';
import {useMemo} from 'react';
import {isNullish} from '@front-libs/helpers';

export type FetchInspectionsParams = {
  // query
  type?: InspectionType;
  name?: string;
  order?: string;
  statuses?: string;
  wholeProductHashId?: string;
  isShared?: boolean;
  categoryHashIds?: string;
  updatedAtFrom?: string;
  // pagination
  page?: number;
  perPage?: number;
};

export type FetchInspectionsResult = {
  totalCount: number;
  page: number;
  data: InspectionIndex[];
};

export type FetchInspectionListParams = {
  inspectionType?: InspectionType;
  name?: string;
  statuses?: string;
  wholeProductHashId?: string;
  page?: number;
  perPage?: number;
  order?: string;
};

export type FetchInspectionListResult = {
  totalCount: number;
  page: number;
  data: InspectionList[];
};

export type NumericValidator =
  | {
      op: 'and' | 'or';
      exprs: NumericValidator[];
    }
  | {
      op: 'not';
      expr: NumericValidator;
    }
  | {
      op: 'eq' | 'neq' | 'gt' | 'gte' | 'lt' | 'lte';
      value: number;
      errorText?: string;
    }
  | {
      op: 'between' | 'not-between';
      lower: number;
      upper: number;
      errorText?: string;
    };

type BaseInspectionItem = {
  id?: string;
  name: string;
  description: string;
  inspectionPoint: string;
};

export type SectionInspectionItem = BaseInspectionItem & {
  type: 'section';
  items: InspectionItem[];
};

export type BoolInspectionItem = BaseInspectionItem & {
  type: 'bool';
  required: boolean;
};

export type SelectValidator = {
  values: string[];
};

export type SelectInspectionItem = BaseInspectionItem & {
  type: 'select';
  view: 'radio' | 'pull-down';
  required: boolean;
  options: Array<{label: string; value: string}>;
  validator: SelectValidator | null;
};

export type MultiSelectInspectionItem = BaseInspectionItem & {
  type: 'multi-select';
  required: boolean;
  options: Array<{label: string; value: string}>;
  validator: SelectValidator | null;
};

export type NumericInspectionItem = BaseInspectionItem & {
  type: 'numeric';
  required: boolean;
  unit: string;
  validator: NumericValidator | null;
};

export type TextInspectionItem = BaseInspectionItem & {
  type: 'text';
  required: boolean;
};

export type MultilineTextInspectionItem = BaseInspectionItem & {
  type: 'multiline-text';
  required: boolean;
};

export type DateInspectionItem = BaseInspectionItem & {
  type: 'date';
  required: boolean;
};

export type TimeInspectionItem = BaseInspectionItem & {
  type: 'time';
  required: boolean;
};

export type NullInspectionItem = BaseInspectionItem & {
  type: null;
};

export type InspectionItem =
  | SectionInspectionItem
  | BoolInspectionItem
  | SelectInspectionItem
  | MultiSelectInspectionItem
  | NumericInspectionItem
  | TextInspectionItem
  | MultilineTextInspectionItem
  | DateInspectionItem
  | TimeInspectionItem
  | NullInspectionItem;

export type CreateInspectionParam = {
  name: string;
  type: InspectionType;
  items?: InspectionItem[];
  status?: InspectionStatus;
};

export type UpdateInspectionParam = {
  name?: string;
  items?: InspectionItem[];
  status?: InspectionStatus;
};

export type CopyInspectionParam = {
  type: InspectionType;
  name: string;
  migrateProducts: boolean;
};

type QueryOptions = {
  enabled?: boolean;
  cacheTime?: number;
};

export const getInspections = async (hospitalHashId: string, params: FetchInspectionsParams) => {
  // maker_periodicは非対応なのでエラーを返す
  if (params.type === 'maker_periodic') {
    throw new Error('maker_periodic is not supported');
  }
  const baseURL = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/inspections`;
  const {data} = await axios.get<FetchInspectionsResult>(baseURL, {
    params: {
      ...params,
      // dailyについては、API側の型に合わせてパラメータを修正
      type: isNullish(params.type) ? undefined : params.type === 'daily' ? 'pre_use,in_use,post_use' : params.type,
    },
  });
  return data;
};

export const getInspectionList = async (hospitalHashId: string, params: FetchInspectionListParams) => {
  const baseURL = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/inspections/list`;
  const {data} = await axios.get<FetchInspectionListResult>(baseURL, {
    params: {
      ...params,
      type: isNullish(params.inspectionType) ? undefined : params.inspectionType,
    },
  });
  return data;
};

export const createInspection = async (hospitalHashId: string, data: CreateInspectionParam) => {
  const baseURL = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/inspections`;
  return await axios.post<InspectionIndex>(baseURL, data);
};

export const updateInspection = async (
  hospitalHashId: string,
  inspectionHashId: string,
  data: UpdateInspectionParam
) => {
  const baseURL = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/inspections/${inspectionHashId}`;
  return await axios.put<InspectionIndex>(baseURL, data);
};

export const copyInspection = async (hospitalHashId: string, inspectionHashId: string, data: CopyInspectionParam) => {
  const baseURL = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/inspections/${inspectionHashId}/copy`;
  return await axios.post<InspectionIndex>(baseURL, data);
};

export const getInspection = async (hospitalHashId: string, inspectionHashId: string, showDeleted?: boolean) => {
  const baseURL = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/inspections/${inspectionHashId}`;
  const {data} = await axios.get<InspectionIndex>(baseURL, {
    params: {
      showDeleted: showDeleted ?? false,
    },
  });
  return data;
};

export const useFetchInspectionsQuery = (
  hospitalHashId: string,
  params: FetchInspectionsParams,
  queryOptions?: QueryOptions
) => {
  const query = useQuery([getInspectionsKey, hospitalHashId, params], () => getInspections(hospitalHashId, params), {
    ...queryOptions,
    cacheTime: 0,
  });
  return useMemo(
    () => ({
      ...query,
      data: query.data?.data ?? [],
      totalCount: query.data?.totalCount ?? 0,
      page: query.data?.page ?? 0,
    }),
    [query]
  );
};

export const useFetchInspectionListQuery = (
  hospitalHshId: string,
  params: FetchInspectionListParams,
  queryOptions?: QueryOptions
) => {
  const query = useQuery(
    [getInspectionListKey, hospitalHshId, params],
    () => getInspectionList(hospitalHshId, params),
    {
      ...queryOptions,
      cacheTime: 0,
    }
  );
  return useMemo(
    () => ({
      ...query,
      data: query.data?.data ?? [],
      totalCount: query.data?.totalCount ?? 0,
      page: query.data?.page ?? 0,
    }),
    [query]
  );
};

export const useFetchInspectionCountsQuery = (hospitalHashId: string, params: FetchInspectionsParams) => {
  const query = useQuery([hospitalHashId, params, getInspectionCountsKey], () =>
    Promise.all([
      getInspections(hospitalHashId, {...params, statuses: 'available'}),
      getInspections(hospitalHashId, {...params, statuses: 'draft'}),
    ])
  );

  return useMemo(
    () => ({
      ...query,
      available: query.data ? query.data[0].totalCount : null,
      draft: query.data ? query.data[1].totalCount : null,
    }),
    [query]
  );
};

export const useFetchInspectionQuery = (hospitalHashId: string, inspectionHashId: string) => {
  return useQuery([getInspectionKey, hospitalHashId, inspectionHashId], () =>
    getInspection(hospitalHashId, inspectionHashId)
  );
};

export const useFetchOptionalInspectionQuery = (hospitalHashId: string, inspectionHashId?: string) => {
  return useQuery([getInspectionKey, hospitalHashId, inspectionHashId], () =>
    inspectionHashId ? getInspection(hospitalHashId, inspectionHashId) : undefined
  );
};

export const deleteInspection = async (hospitalHashId: string, inspectionHashId: string) => {
  const baseURL = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/inspections/${inspectionHashId}`;
  return await axios.delete(baseURL);
};

export type UploadInspectionManualParam = {
  hospitalHashId: string;
  inspectionHashId: string;
  file: File;
  fileName: string;
};

export const uploadInspectionManual = async (data: UploadInspectionManualParam) => {
  const baseURL = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${data.hospitalHashId}/inspections/${
    data.inspectionHashId
  }/inspection_manual`;
  const params = new FormData();
  params.append('file', data.file);
  params.append('file_name', data.fileName);
  return await axios.post(baseURL, params, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
