import {Button, Paper, Table, TableContainer} from '@mui/material';
import React from 'react';
import {EditWholeProductHeader} from './ui/EditWholeProductHeader';
import {EditWholeProductListBody} from './EditWholeProductListBody';
import {ADD_ACTION, EditWholeProductPlan, EditWholeProductPlanAction} from './utils/reducer';
import {ADD_MODE} from './type';

type EditWholeProductListProps = {
  inspectionSettings: EditWholeProductPlan[];
  dispatch: React.Dispatch<EditWholeProductPlanAction>;
};

export const EditWholeProductList = ({inspectionSettings, dispatch}: EditWholeProductListProps) => {
  const isAddMode = inspectionSettings.some((item) => item.mode === ADD_MODE);
  return (
    <TableContainer component={Paper} style={{boxShadow: 'none'}}>
      <Table sx={{'& .MuiTableCell-root': {paddingBottom: '16px', height: '40px'}}}>
        <EditWholeProductHeader />
        <EditWholeProductListBody inspectionSettings={inspectionSettings} dispatch={dispatch} />
      </Table>

      <Button
        disabled={isAddMode}
        onClick={() => {
          dispatch({type: ADD_ACTION});
        }}>
        + 新規点検を追加
      </Button>
    </TableContainer>
  );
};
