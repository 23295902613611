import React from 'react';
import {Box, styled} from '@mui/material';
import {useCurrentDateTime} from '@front-libs/core';

type Props = {
  title?: string;
};

const HeaderDate = () => {
  const formattedDate = useCurrentDateTime();
  return <StyledDateBox>{formattedDate}</StyledDateBox>;
};

export const Header = ({title}: Props) => {
  return (
    <StyledRootBox>
      <HeaderDate />
      <StyledTitleBox>{title}</StyledTitleBox>
    </StyledRootBox>
  );
};

const StyledRootBox = styled(Box)({
  boxSizing: 'border-box',
  width: '100%',
  padding: '0px 32px',
});

const StyledDateBox = styled(Box)({
  top: '32px',
  right: '32px',
  position: 'absolute',
});

const StyledTitleBox = styled(Box)({
  color: '#374659',
  fontSize: '24px',
  fontWeight: 'bold',
  paddingTop: '30px',
});
