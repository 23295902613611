import {useMemo} from 'react';
import {useQuery} from 'react-query';
import {axios} from '@front-libs/core';
import {
  PeriodUnit,
  InspectionPeriodProduct,
  InspectionPeriodProductWithCount,
  FetchInspectionSettingsParam,
  FetchInspectionSettingsResponse,
} from './types';
import {getInspectionPeriodsKey, getInspectionPeriodProductsByAssignedKey} from '@constants/api';
import {InspectionType} from '../inspections/enum';
import {Inspection} from '../inspection/types';
import {WholeProductIndex} from '../products/types';

export type InspectionProduct = {
  hashId: string;
  hospitalHashId: string;
  wholeProductHashId: string;
  inspectionHashId: string;
  wholeProduct: WholeProductIndex;
  name: string;
  periodicInspectionPeriod: number;
  periodicInspectionPeriodUnit: PeriodUnit | null;
};

export type InspectionProductWithWholeProduct = InspectionProduct & {
  wholeProduct: InspectionPeriodProductWithCount;
};

export type FetchInspectionPeriodProductsParam = {
  perPage?: number;
  page?: number;
  inspectionHashId?: string;
  hasInspectionId?: boolean;
  hasInspectionPeriod?: boolean;
  inspectionType: InspectionType;
  name?: string;
  categoryHashIds?: string;
};

export type FetchInspectionPeriodProductsByAssignedParam = {
  perPage?: number;
  page?: number;
  inspectionHashId: string;
  inspectionType: InspectionType;
  name?: string;
  categoryHashIds?: string;
};

export type FetchInspectionProductParam = {
  inspectionType: InspectionType;
};

export type FetchInspectionPeriodProductsResponse = {
  totalCount: number;
  page: number;
  data: InspectionPeriodProduct[];
};

export type FetchInspectionPeriodProductsByAssignedResponse = {
  totalCount: number;
  page: number;
  data: InspectionPeriodProductWithCount[];
};

export type FetchInspectionProductResponse = InspectionProduct & {
  inspection: Inspection;
};

export type InspectionPeriodParam = {
  wholeProductHashId: string;
  inspectionHashId?: string;
  periodicInspectionPeriod?: number;
  periodicInspectionPeriodUnit?: PeriodUnit;
  makerInspectionPeriod?: number;
  makerInspectionPeriodUnit?: PeriodUnit;
};

export type UpdateInspectionPeriodParam = {
  wholeProductHashId?: string;
  periodicInspectionPeriod?: number;
  periodicInspectionPeriodUnit?: PeriodUnit;
  makerInspectionPeriod?: number;
  makerInspectionPeriodUnit?: PeriodUnit;
};

export type UpdateInspectionPeriodsParam = {
  inspectionHashId: string;
  addWholeProductHashIds: string[];
  deleteWholeProductHashIds: string[];
};

export type UpdateInspectionPeriodsResponse = {
  createdItemCount: number;
  deletedItemCount: number;
};

export const getInspectionPeriodProducts = async (
  hospitalHashId: string,
  params: FetchInspectionPeriodProductsParam
) => {
  const baseURL = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/inspections/settings/products`;
  const {data} = await axios.get<FetchInspectionPeriodProductsResponse>(baseURL, {params});

  return data;
};

export const getInspectionPeriodProductsByAssigned = async (
  hospitalHashId: string,
  params: FetchInspectionPeriodProductsByAssignedParam
) => {
  const baseURL = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/inspections/settings/products/assigned_non_assigined`;
  const {data} = await axios.get<FetchInspectionPeriodProductsByAssignedResponse>(baseURL, {params});

  return data;
};

export const getInspectionProduct = async (
  hospitalHashId: string,
  wholeProductHashId: string,
  params: FetchInspectionProductParam
) => {
  const baseURL = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/inspections/settings/products/${wholeProductHashId}`;
  const {data} = await axios.get<FetchInspectionProductResponse>(baseURL, {params});

  return data;
};

export const updateInspectionPeriods = async (hospitalHashId: string, params: UpdateInspectionPeriodsParam) => {
  const baseURL = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/inspections/settings/products/jobs`;
  const {data} = await axios.post<UpdateInspectionPeriodsResponse>(baseURL, params);
  return data;
};

export const updateInspectionPeriod = async (hospitalHashId: string, params: UpdateInspectionPeriodParam) => {
  const baseURL = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/inspections/settings/periods`;
  const {data} = await axios.put<unknown>(baseURL, params);
  return data;
};

export const useFetchInspectionPeriodProductsQuery = (
  hospitalHashId: string,
  params: FetchInspectionPeriodProductsParam
) => {
  const query = useQuery([getInspectionPeriodsKey, hospitalHashId, params], () =>
    getInspectionPeriodProducts(hospitalHashId, params)
  );
  return useMemo(
    () => ({
      ...query,
      data: query.data?.data ?? [],
      totalCount: query.data?.totalCount ?? 0,
    }),
    [query]
  );
};

export const useFetchInspectionPeriodProductsByAssignedQuery = (
  hospitalHashId: string,
  params: FetchInspectionPeriodProductsByAssignedParam
) => {
  const query = useQuery([getInspectionPeriodProductsByAssignedKey, hospitalHashId, params], () =>
    getInspectionPeriodProductsByAssigned(hospitalHashId, params)
  );
  return useMemo(
    () => ({
      ...query,
      data: query.data?.data ?? [],
      totalCount: query.data?.totalCount ?? 0,
    }),
    [query]
  );
};

export const getInspectionProductSettings = async (hospitalHashId: string, params: FetchInspectionSettingsParam) => {
  const baseURL = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/inspection-settings`;
  const {data} = await axios.get<FetchInspectionSettingsResponse>(baseURL, {params});

  return data;
};

export type UpdateInspectionProductSettingParam = {
  inspectionSettingName: string;
  inspectionHashId: string;
};

export const updateInspectionProductSetting = async (
  hospitalHashId: string,
  wholeProductHashId: string,
  inspectionSettingHashId: string,
  inspectionProductSetting: UpdateInspectionProductSettingParam
) => {
  const baseURL = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/whole_products/${wholeProductHashId}/inspection-settings/${inspectionSettingHashId}`;
  const {data} = await axios.post<UpdateInspectionPeriodsResponse>(baseURL, inspectionProductSetting);
  return data;
};

export type BulkInsertInspectionSetting = {
  inspectionSettingName: string; // 点検名
  inspectionHashId: string; // 点検表のHashId
  inspectionPeriod?: number; // 点検間隔
  inspectionPeriodUnitType?: string; // 点検間隔の単位
};

export type BulkInsertInspectionSettingResponse = BulkInsertInspectionSetting & {
  inspectionSettingHashId: string;
};

export type BulkInsertInspectionSettings = {
  inspectionSettings: BulkInsertInspectionSetting[];
};

export type BulkInsertInspectionSettingsResponse = {
  inspectionSettings: BulkInsertInspectionSettingResponse[];
};

export const bulkInsertInspectionSettings = async (
  hospitalHashId: string,
  wholeProductHashId: string,
  params: BulkInsertInspectionSettings
) => {
  const baseURL = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/whole_products/${wholeProductHashId}/inspection-settings/bulk_insert`;
  return await axios.post<BulkInsertInspectionSettingsResponse>(baseURL, params);
};

export const deleteInspectionSetting = async (
  hospitalHashId: string,
  wholeProductHashId: string,
  inspectionSettingHashId: string
) => {
  const baseURL = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/whole_products/${wholeProductHashId}/inspection-settings/${inspectionSettingHashId}`;
  return await axios.delete<void>(baseURL);
};
