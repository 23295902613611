import React, {ReactNode, useMemo} from 'react';
import {FastField, useFormikContext} from 'formik';
import {HospitalProductDetail} from '@modules/hospital_products/types';
import {MakerInspectionSetting} from '@modules/maker_inspection_settings/types';
import _ from 'lodash';
import {SectionFormField, SectionContainer, SectionLabel, SectionDetail} from '@Apps/ProductDetail/styled';
export type FormField = {
  type: string;
  label: string | ReactNode;
  name:
    | keyof HospitalProductDetail
    | keyof MakerInspectionSetting
    | 'rootCategory'
    | 'narrowCategory'
    | 'makerInspectionSetting.dueDateOfMakerInspection'
    | 'makerInspectionSetting.inspectionPeriod'
    | 'makerInspectionSetting.nextInspectionDate';
  getValue?: (product: HospitalProductDetail) => string | number | boolean | undefined;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  InputComponent: React.ComponentType<any>;
  readOnly?: boolean | ((values: HospitalProductDetail, initialValues: HospitalProductDetail) => boolean);
  isVisible?: boolean | ((values: HospitalProductDetail) => boolean);
  options?: {
    label: string;
    value: string | number | boolean | (string | number | boolean)[];
  }[];
  multiline?: boolean;
  clearable?: boolean;
};

export type FormSection = {
  sectionName: string;
  fields: FormField[];
};

type Props = FormSection;

/**
 * 病院製品の詳細やメーカー検査設定に関連するフォームフィールドを表示するためのセクション
 * 各フィールドは `FormField` 型で定義され、条件に基づいて表示されるかどうが分かれる
 *
 * @param {Props} props - このコンポーネントのプロパティ
 * @param {string} props.sectionName - セクションの名前
 * @param {FormField[]} props.fields - フォームフィールドの配列
 * @returns {React.ReactElement} フォームフィールドを含むセクションのReact要素
 *
 * @example
 * <FormFieldsSection
 *   sectionName="基本情報"
 *   fields={[
 *     { type: 'text', label: '名称', name: 'name', InputComponent: TextInput },
 *     // 他のフィールド...
 *   ]}
 * />
 */
export const FormFieldsSection = ({sectionName, fields}: Props) => {
  const context = useFormikContext<HospitalProductDetail>();

  const filteredFields = useMemo(
    () =>
      fields.filter((field) => {
        if (field.isVisible === undefined || field.isVisible === true) {
          return true;
        }

        if (_.isFunction(field.isVisible) && field.isVisible(context.values)) {
          return true;
        }

        return false;
      }),
    [fields, context.values]
  );

  const renderedSections = filteredFields.map((field, index) => {
    // prefer to use value by getValue
    const value = field.getValue
      ? field.getValue(context.values)
      : field.name in context.values
        ? // biome-ignore lint/suspicious/noExplicitAny: <explanation>
          (context.values as any)[field.name]
        : '';
    const readOnly = _.isFunction(field.readOnly)
      ? field.readOnly(context.values, context.initialValues)
      : field.readOnly;

    const fieldProps = {
      type: field.type,
      label: field.label,
      fullWidth: true,
      name: field.name,
      value: value !== null ? value : '',
      component: field.InputComponent,
      disabled: readOnly,
      options: field.options,
      multiline: field.multiline,
      clearable: field.clearable,
    };

    return (
      <SectionFormField key={sectionName + index}>
        <FastField {...fieldProps} />
      </SectionFormField>
    );
  });

  return (
    <SectionContainer>
      <SectionLabel>{sectionName}</SectionLabel>
      <SectionDetail>{renderedSections}</SectionDetail>
    </SectionContainer>
  );
};
