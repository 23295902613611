import React from 'react';
import {NavLink} from 'react-router-dom';
import {theme} from '@atoms/theme';
import {styled} from '@mui/material';

const ManagementIdLink = styled(NavLink)({
  padding: '0px',
  fontSize: '14px',
  display: 'inline',
  color: theme.palette.info.dark,
  fontWeight: 'bold',
  cursor: 'pointer',
  textDecoration: 'none',
});

export const InspectionResultTableManagementIdColumn: React.FC<{
  managementId: string;
  hospitalProductHashId: string;
}> = (props) => {
  const {managementId, hospitalProductHashId} = props;

  return <ManagementIdLink to={`/products/${hospitalProductHashId}`}>{managementId}</ManagementIdLink>;
};
