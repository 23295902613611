import {dialogHandler} from '@components/molecules/Dialogs/DialogHandler';
import {SearchBox} from '@components/molecules/SearchBox/sp';
import {CircularProgress, Grid, styled} from '@material-ui/core';
import {useAtom, useAtomValue} from 'jotai';
import React, {useCallback, useRef, useState, useEffect, useMemo} from 'react';
import {ToolBarFilterButton} from './ToolBarFilterButton';
import {onFocusAtom} from '../jotai';
import {ProductFilter} from './ProductFilter';
import {CategoryFilter} from './CategoryFilter';
import {ToolBarApplyFilterButton} from './ToolBarApplyFilterButton';
import {searchRootCategoriesAtom, searchStatusesAtom, searchSubCategoriesAtom} from '../../pc/jotai';
import {productStatusOptionsWODisabled} from '@constants/constants';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {SelectOptionProps} from '@components/molecules/Buttons/PopperSelectBoxButton';
import {useCategoryQuery} from '../../pc/hooks';
import {CategoryFormatter} from '@modules/categories/helpers';
import {CategoryIndex} from '@modules/categories/types';

const Container = styled('div')({
  tabIndex: 0,
});

const StyledGrid = styled(Grid)({
  margin: '8px 4px',
});

// 引数のfilterValuesにあるKeyでフィルタリングするための関数
const filterObjectByValues = (obj: {[value: string]: boolean}, filterValues: string[]): {[value: string]: boolean} => {
  return Object.fromEntries(
    Object.entries(obj).filter(([key, value]) => filterValues.some((filterValue) => key.includes(filterValue)))
  ) as {[value: string]: boolean};
};

/**
 * ValueをKeyとして持っているオブジェクト型を{[key: string]: boolean}に変換する
 * @param options
 * @returns
 */
const createValueBooleanObject = <T extends {value: string}>(options: T[]): {[key: string]: boolean} => {
  return options.reduce(
    (acc, option) => {
      acc[option.value] = true;
      return acc;
    },
    {} as {[key: string]: boolean}
  );
};

/**
 * `ProductFilterButtonList` コンポーネントは、稼働状況、大分類、小分類、詳細検索のボタンと選択されたフィルタの一覧を表示します
 *
 * 稼働状況ボタンが押された場合、ダイアログが開き、ユーザーがフィルタを選択できるようになります
 * 選択されたフィルタは一覧で表示され、ユーザーはそれをキャンセルすることもできます
 *
 * このコンポーネントは、ユーザーが検索ボックスにフォーカスしているか、
 * ダイアログが開いている場合、または選択されたフィルタがある場合にのみ表示されます
 */
export const ProductFilterButtonList = () => {
  const isSearchTextFocus = useAtomValue(onFocusAtom);
  const [isBlur, setIsBlur] = useState(true);
  const [isCloseDialog, setIsCloseDialog] = useState(true);
  const [filterButton, setFilterButton] = useState<SelectOptionProps[]>([]);
  const [searchStatuses, setSearchStatuses] = useAtom(searchStatusesAtom);
  const [searchRootCategories, setSearchRootCategories] = useAtom(searchRootCategoriesAtom);
  const [searchSubCategories, setSearchSubCategories] = useAtom(searchSubCategoriesAtom);
  const checkList = useRef<{[value: string]: boolean}>({});
  const {myInfo} = useMyInfo();
  const {rootCategoryQuery, narrowCategoryQuery, selectRootCategory, descendantCategoriesQueries} = useCategoryQuery(
    myInfo.hospitalHashId
  );

  useEffect(() => {
    // フィルターボタンの表示切り替え
    checkList.current = {
      ...checkList.current,
      ...createValueBooleanObject(searchStatuses),
      ...createValueBooleanObject(searchRootCategories),
      ...createValueBooleanObject(searchSubCategories),
    };
  }, [searchRootCategories, searchStatuses, searchSubCategories]);

  /** 表示するフィルターのボタン更新 */
  useEffect(() => {
    setFilterButton([...searchStatuses, ...searchRootCategories, ...searchSubCategories]);
  }, [searchRootCategories, searchStatuses, searchSubCategories]);

  /** 大分類の選択状態に合わせて変更された小分類の項目を返す */
  const descendantCategoryOptions = useMemo(() => {
    if (descendantCategoriesQueries.length > 0) {
      let catQuery: CategoryIndex[] = [];
      for (const descendantCategoriesQuery of descendantCategoriesQueries) {
        const filteredCategories = (descendantCategoriesQuery.data?.data ?? []).filter((item) => item.depth === 1);
        catQuery = [...catQuery, ...filteredCategories];
      }

      return CategoryFormatter.getOptions(catQuery);
    } else {
      return CategoryFormatter.getOptions(narrowCategoryQuery.data);
    }
  }, [descendantCategoriesQueries, narrowCategoryQuery.data]);

  /** 大分類ボタン押下時 */
  const onClickRootCategories = useCallback(async () => {
    const dataKeys = rootCategoryQuery.data.map((v) => v.hashId);
    setIsCloseDialog(false);
    const categoryCheckList = await dialogHandler.open(SearchBox, {
      content: <CategoryFilter categories={rootCategoryQuery.data} />,
      checkList: filterObjectByValues(checkList.current, dataKeys),
    });
    checkList.current = {...checkList.current, ...categoryCheckList};
    // 大分類の選択状態をselectRootCategoryに渡して小分類の切り替えを行う
    selectRootCategory(
      Object.keys(checkList.current).filter((v) =>
        rootCategoryQuery.data.find((categoryIndex) => categoryIndex.hashId === v)
      )
    );
    setSearchRootCategories(
      rootCategoryQuery?.data
        .map((v) => ({label: v.name, value: v.hashId}))
        .filter((v) => checkList.current[v.value] === true)
    );

    setIsCloseDialog(true);
  }, [rootCategoryQuery.data, selectRootCategory, setSearchRootCategories]);

  /** 小分類ボタン押下時 */
  const onClickNarrowCategories = useCallback(async () => {
    const dataKeys = descendantCategoryOptions.map((v) => v.value);
    setIsCloseDialog(false);
    const categoryCheckList = await dialogHandler.open(SearchBox, {
      content: (
        <CategoryFilter
          categories={descendantCategoryOptions.map((v) => ({
            hashId: v.value,
            name: v.label,
            depth: 1,
          }))}
        />
      ),
      checkList: filterObjectByValues(checkList.current, dataKeys),
    });
    checkList.current = {...checkList.current, ...categoryCheckList};
    setSearchSubCategories(descendantCategoryOptions?.filter((v) => checkList.current[v.value] === true));
    setIsCloseDialog(true);
  }, [descendantCategoryOptions, setSearchSubCategories]);

  /** 稼働状況押下時の挙動 */
  const onClickOperatingStatus = useCallback(
    async (e?: React.MouseEvent) => {
      setIsCloseDialog(false);
      const operatingStatusCheckList = await dialogHandler.open(SearchBox, {
        content: <ProductFilter />,
        checkList: filterObjectByValues(
          checkList.current,
          productStatusOptionsWODisabled.map((v) => v.value)
        ),
      });
      checkList.current = {...checkList.current, ...operatingStatusCheckList};
      setSearchStatuses(productStatusOptionsWODisabled.filter((v) => checkList.current[v.value]));

      setIsCloseDialog(true);
    },
    [setSearchStatuses]
  );

  /** フィルター取り消すボタン押下時 */
  const onClickApplyFilter = (value: string) => {
    checkList.current[value] = false;
    if (productStatusOptionsWODisabled.find((v) => v.value === value)) {
      setSearchStatuses(productStatusOptionsWODisabled.filter((v) => checkList.current[v.value]));
    } else if (rootCategoryQuery?.data.find((v) => v.hashId === value)) {
      setSearchRootCategories(
        rootCategoryQuery.data
          .map((v) => ({label: v.name, value: v.hashId}))
          .filter((v) => checkList.current[v.value] === true)
      );
    } else if (narrowCategoryQuery?.data.find((v) => v.hashId === value)) {
      setSearchSubCategories(
        narrowCategoryQuery.data
          .map((v) => ({label: v.name, value: v.hashId}))
          .filter((v) => checkList.current[v.value] === true)
      );
    }
  };

  const onFocus = (e: React.FocusEvent) => {
    setIsBlur(e.type === 'blur');
  };

  // すべてのフォーカスが無いときもしくはフィルターが無いときは非表示
  if (isSearchTextFocus === false && isBlur && isCloseDialog && filterButton.length === 0) return null;

  return (
    <Container onBlur={onFocus}>
      <StyledGrid container justifyContent="flex-start" spacing={1}>
        <Grid item>
          <ToolBarFilterButton label={'稼働状況'} onClick={onClickOperatingStatus} tabindex="1" />
        </Grid>
        {rootCategoryQuery.isLoading ? (
          <Grid item>
            <CircularProgress color="secondary" />
          </Grid>
        ) : (
          <Grid item>
            <ToolBarFilterButton label={'大分類'} onClick={onClickRootCategories} tabindex="2" />
          </Grid>
        )}
        {narrowCategoryQuery.isLoading ? (
          <Grid item>
            <CircularProgress color="secondary" />
          </Grid>
        ) : (
          <Grid item>
            <ToolBarFilterButton label={'小分類'} onClick={onClickNarrowCategories} tabindex="3" />
          </Grid>
        )}
        {/* NOTE:後日実装 */}
        {/* <Grid item>
              <ToolBarFilterButton label={'詳細検索'} onClick={onClick} tabindex="4" />
            </Grid> */}
      </StyledGrid>
      {filterButton.length > 0 && (
        <StyledGrid container justifyContent="flex-start" spacing={1}>
          {filterButton.map((v) => (
            <Grid item key={v.value}>
              <ToolBarApplyFilterButton
                label={`${v.label}`}
                onClick={() => {
                  onClickApplyFilter(v.value);
                }}
              />
            </Grid>
          ))}
        </StyledGrid>
      )}
    </Container>
  );
};
