import {ProductList} from '@Apps/ProductsList/sp';
import {InspectionResultList} from '@Apps/InspectionResultList/sp';
import React, {useEffect} from 'react';
import {InspectionResult} from '@Apps/InspectionResult/sp';
import {MobileProductDetail} from './Apps/ProductDetail';
import {Outlet} from 'react-router-dom';
import {useMyRole} from '@modules/hospital_users/hooks/useMyRole';
import {useLogout} from '@modules/auth/hooks';

const SpRoutesAuthManager = () => {
  const {canAccessAsset} = useMyRole();
  const logout = useLogout();

  useEffect(() => {
    if (!canAccessAsset) {
      alert('このユーザーではAssetを利用できません。Asset利用設定済のユーザーでログインしてください。');
      logout();
    }
  }, [canAccessAsset, logout]);

  return <Outlet />;
};

// NOTE:スマートフォン用のルーティング
export const spRoutes = {
  path: '/sp',
  element: <SpRoutesAuthManager />,
  children: [
    {
      key: 'spProducts',
      path: 'products',
      element: <ProductList />,
    },
    {
      key: 'spInspectionResults',
      path: 'inspection/results',
      element: <InspectionResultList />,
    },
    {
      key: 'spInspectionResult',
      path: 'inspections/:inspectionHashId/result/:inspectionResultHashId',
      element: <InspectionResult />,
    },
    {
      key: 'spProductDetail',
      path: 'products/:hashId',
      element: <MobileProductDetail />,
    },
  ],
};
