import React from 'react';
import Selector from 'react-select';
import {FormControl} from '@mui/material';
import {UserIndex} from '@modules/hospital_users/types';
import {UserFormatter, UserTemp} from '@modules/hospital_users/helpers';
import {theme} from '@atoms/theme';

type Props = {
  placeholder?: string;
  users: UserIndex[];
  selectedHashId?: string;
  onSelect: (hashId?: string) => void;
};

export const UserSelector: React.FC<Props> = ({placeholder, users, selectedHashId, onSelect}) => {
  const options = UserFormatter.getOptions(users, {withAlias: true, withSubLabel: true});
  const selectedOption = options.find((o) => o.value === selectedHashId);

  const handleSelect = (option: UserTemp | null) => {
    onSelect(option?.value);
  };

  return (
    <FormControl variant="outlined" size={'small'} fullWidth>
      <Selector<UserTemp, false>
        menuPortalTarget={document.body}
        size="small"
        placeholder={placeholder}
        styles={{
          control: (provided) => ({...provided, minHeight: '40px'}),
          menuPortal: (provided) => ({...provided, zIndex: theme.zIndex.modal * 10}),
        }}
        value={selectedOption}
        options={options}
        onChange={handleSelect}
        components={{IndicatorSeparator: null}}
      />
    </FormControl>
  );
};
