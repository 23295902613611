import React, {RefObject, useCallback, useLayoutEffect} from 'react';
import Quagga, {InputStreamType, QuaggaJSCodeReader, QuaggaJSReaderConfig} from '@ericblade/quagga2';
import QrCodeReader from './QrCodeReader';
import {getMedianOfCodeErrors} from '@front-libs/helpers';

const DEFAULT_DECODE_ERROR_THRESHOLD = 0.25;
const VIDEO_WIDTH_ADJUSTMENT_FACTOR = 0.6;
const VIDEO_HEIGHT_ADJUSTMENT_FACTOR = 0.5;

const defaultFacingMode = 'environment';

const defaultLocatorSettings = {
  patchSize: 'medium',
  halfSample: true,
};

const defaultDecoders = ['code_128_reader', 'code_39_reader', 'qrcode'];

type ScannerProps = {
  onDetected: (result: string) => void;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  scannerRef: RefObject<any>;
  onScannerReady?: () => void;
  cameraId?: string;
  facingMode?: string;
  constraints?: MediaTrackConstraints;
  locator?: typeof defaultLocatorSettings;
  numOfWorkers?: number;
  decoders?: (QuaggaJSCodeReader | QuaggaJSReaderConfig)[];
  locate?: boolean;
};

Quagga.registerReader('qrcode', QrCodeReader);

export const Scanner: React.FC<ScannerProps> = ({
  onDetected,
  scannerRef,
  onScannerReady,
  cameraId,
  facingMode = defaultFacingMode,
  constraints,
  locator = defaultLocatorSettings,
  numOfWorkers = navigator.hardwareConcurrency || 0,
  decoders = defaultDecoders,
  locate = true,
}) => {
  const errorCheck = useCallback(
    (result) => {
      if (!onDetected || !result.codeResult.decodedCodes) {
        return;
      }
      const err = getMedianOfCodeErrors(result.codeResult.decodedCodes);
      // Quaggaが読み取ったコードの正確さが少なくとも75％であるなら、そのコードを受け入れる
      if (err < DEFAULT_DECODE_ERROR_THRESHOLD) {
        onDetected(result.codeResult.code);
      }
    },
    [onDetected]
  );

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const handleDrawing = useCallback((result: any) => {
    const drawingCtx = Quagga.canvas.ctx.overlay;
    const drawingCanvas = Quagga.canvas.dom.overlay;

    if (result) {
      if (result.boxes) {
        drawingCtx.clearRect(
          0,
          0,
          parseInt(drawingCanvas.getAttribute('width') || '0'),
          parseInt(drawingCanvas.getAttribute('height') || '0')
        );
        result.boxes
          .filter((box: Array<[number, number]>) => box !== result.box)
          .forEach((box: Array<[number, number]>) => {
            Quagga.ImageDebug.drawPath(box, {x: 0, y: 1}, drawingCtx, {color: 'purple', lineWidth: 2});
          });
      }
      if (result.box) {
        Quagga.ImageDebug.drawPath(result.box, {x: 0, y: 1}, drawingCtx, {color: 'blue', lineWidth: 2});
      }
    }
  }, []);

  const handleProcessing = useCallback(
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    (result: any) => {
      if (result?.codeResult?.code) {
        onDetected(result.codeResult.code);
      }
    },
    [onDetected]
  );

  useLayoutEffect(() => {
    const quaggaConfig = {
      inputStream: {
        type: 'LiveStream' as InputStreamType,
        constraints: {
          width: window.innerWidth * VIDEO_WIDTH_ADJUSTMENT_FACTOR,
          height: window.innerHeight * VIDEO_HEIGHT_ADJUSTMENT_FACTOR,
          ...constraints,
          ...(cameraId && {deviceId: cameraId}),
          ...(!cameraId && {facingMode}),
        },
        target: scannerRef.current,
      },
      locator,
      numOfWorkers,
      decoder: {readers: decoders as unknown as QuaggaJSCodeReader[]},
      locate: true,
    };

    // 初期から縦向きの場合
    if (window.innerWidth < window.innerHeight) {
      quaggaConfig.inputStream.constraints.width = window.innerHeight * VIDEO_WIDTH_ADJUSTMENT_FACTOR;
      quaggaConfig.inputStream.constraints.height = window.innerWidth * VIDEO_HEIGHT_ADJUSTMENT_FACTOR;
    }

    Quagga.init(quaggaConfig, (err) => {
      Quagga.onProcessed(handleDrawing);
      Quagga.onProcessed(handleProcessing);
      if (err) {
        console.error('Error starting Quagga:', err);
        return;
      }
      if (scannerRef && scannerRef.current) {
        Quagga.start();
        if (onScannerReady) {
          onScannerReady();
        }
      }
    });

    // orientationが変わる時、Quaggaのvideoサイズを変更
    const handleResize = () => {
      Quagga.stop();

      if (window.innerWidth > window.innerHeight) {
        quaggaConfig.inputStream.constraints.width = window.innerWidth * VIDEO_WIDTH_ADJUSTMENT_FACTOR;
        quaggaConfig.inputStream.constraints.height = window.innerHeight * VIDEO_HEIGHT_ADJUSTMENT_FACTOR;
      } else {
        quaggaConfig.inputStream.constraints.width = window.innerHeight * VIDEO_WIDTH_ADJUSTMENT_FACTOR;
        quaggaConfig.inputStream.constraints.height = window.innerWidth * VIDEO_HEIGHT_ADJUSTMENT_FACTOR;
      }

      Quagga.init(quaggaConfig, (err) => {
        if (err) {
          console.error('Error starting Quagga:', err);
          return;
        }
        if (scannerRef && scannerRef.current) {
          Quagga.start();
        }
      });
    };

    window.addEventListener('resize', handleResize);

    Quagga.onDetected(errorCheck);
    return () => {
      Quagga.offDetected(errorCheck);
      Quagga.offProcessed(handleDrawing);
      Quagga.offProcessed(handleProcessing);
      window.removeEventListener('resize', handleResize);
      Quagga.stop();
    };
  }, [
    cameraId,
    onDetected,
    onScannerReady,
    scannerRef,
    errorCheck,
    constraints,
    locator,
    decoders,
    locate,
    facingMode,
    numOfWorkers,
    handleProcessing,
    handleDrawing,
  ]);
  return null;
};
