import React from 'react';
import {styled, Box} from '@mui/material';
import {
  HospitalProductRequiredProperty,
  HospitalProductRequiredPropertyItem,
} from '@modules/hospital_product_required_properties/types';
import {InnerLoading} from '@molecules/Loading';
import {Sidebar} from './Sidebar';
import {ProductRequiredPropertiesSettingForm} from './ProductRequiredPropertiesSettingForm';

const ProductRegistrationContainer = styled(Box)({
  display: 'flex',
  width: '100%',
});

const ProductRegistrationContent = styled(Box)({
  width: '100%',
});

type Props = {
  isLoading: boolean;
  propertyItems: HospitalProductRequiredPropertyItem[];
  requiredProperties: HospitalProductRequiredProperty[];
  onRequiredPropertyAdd: (property: string) => void;
  onRequiredPropertyDelete: (property: string) => void;
};

export const ProductRegistration = (props: Props) => {
  if (props.isLoading || props.propertyItems.length === 0) {
    return <InnerLoading />;
  }

  return (
    <ProductRegistrationContainer>
      <Box>
        <Sidebar />
      </Box>
      <ProductRegistrationContent>
        <ProductRequiredPropertiesSettingForm
          propertyItems={props.propertyItems}
          requiredProperties={props.requiredProperties}
          onRequiredPropertyAdd={props.onRequiredPropertyAdd}
          onRequiredPropertyDelete={props.onRequiredPropertyDelete}
        />
      </ProductRegistrationContent>
    </ProductRegistrationContainer>
  );
};
