import {Grid, styled} from '@mui/material';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {TableViewLayout} from '@components/layouts/TableViewLayout';
import {HospitalProductPlanListTable} from './HospitalProductPlanTable';
import {StatusSelector} from './StatusSelector';
import {PopperSelectBoxProps, SelectOptionProps} from '@components/molecules/Buttons/PopperSelectBoxButton';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useGetHospitalNarrowCategoriesOptions, useGetHospitalRootCategoriesOptions} from '@modules/categories/api';
import {
  useGetHospitalProductInspectionPlansCountsQuery,
  useGetHospitalProductInspectionPlansQuery,
} from '@modules/hospital_product_plan/hooks';
import {useQueryVariables} from '../states';
import {HospitalProductPlanListElement} from '../types';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {ConfirmBulkDeleteInspectionPlansDialog} from '@organisms/ConfirmBulkDeleteInspectionPlansDialog';
import {
  FetchHospitalProductInspectionPlansParam,
  bulkDeleteHospitalProductInspectionPlans,
  bulkUpdateHospitalProductInspectionPlans,
} from '@modules/hospital_product_plan/api';
import {openSnackBar} from '@molecules/SnackBar';
import {useMyRole} from '@modules/hospital_users/hooks/useMyRole';
import {InspectionScheduleConfigurationDialog} from '@organisms/InspectionScheduleConfigurationDialog';
import dayjs from 'dayjs';
import {SearchAndFilter} from '@organisms/SearchAndFilter';
import {WholeProductPopperButton} from './WholeProductPopperButton';
import {PaginationAndPerPage} from '@organisms/PaginationAndPerPage';
import {useDebounceCallback, useDebounceMemo} from '@front-libs/core';
import {HospitalRoom} from '@modules/hospital_product_plan/type';
import {TableLayoutResult, useTableLayout} from '@modules/table_layout/hooks/useTableLayout';
import {InspectionProductPlanned} from '@modules/products/constants';
import {isNullish} from '@front-libs/helpers';
import {HospitalProductPlanStatus} from 'src/modules/hospital_product_plan/enum';
import {InspectionScheduleBulkCreateAlertDialog} from '@organisms/InspectionScheduleBulkCreateAlertDialog';
import {Order} from '@molecules/Table/props';

const SIDEBAR_WIDTH = 200;

const StyledBody = styled(Grid)({
  flexWrap: 'nowrap',
  width: '100%',
  height: '100%',
});

const StyledSideBar = styled(Grid)({
  flex: `0 0 ${SIDEBAR_WIDTH}px`,
  marginRight: '18px',
});

const StyledTableViewLayout = styled(TableViewLayout)({
  flex: 1,
  minWidth: '0px',
});

/** APIのパラメータ生成 */
const convertApiParameter = ({
  page,
  perPage,
  status,
  name,
  rootCategoryHashId,
  narrowCategoryHashId,
  wholeProductHashId,
  order,
}: {
  page: number;
  perPage: number;
  name: string | undefined;
  wholeProductHashId: string | undefined;
  order: string | undefined;
  rootCategoryHashId: string | undefined;
  narrowCategoryHashId: string | undefined;
  status: HospitalProductPlanStatus;
}): FetchHospitalProductInspectionPlansParam => {
  const params: FetchHospitalProductInspectionPlansParam = {
    page: page - 1,
    perPage: perPage,
    status: status === 'all' ? null : status,
  };

  if (name) {
    params.name = name;
  }

  if (!isNullish(narrowCategoryHashId)) {
    // 小分類がある場合は小分類を優先
    params.categoryHashIds = narrowCategoryHashId;
  } else if (!isNullish(rootCategoryHashId)) {
    params.categoryHashIds = rootCategoryHashId;
  }

  if (wholeProductHashId) {
    params.wholeProductHashId = wholeProductHashId;
  }

  if (order) {
    params.order = order;
  }
  return params;
};

/** 機器管理場所カラムの整形 */
const getFullRoom = (room: HospitalRoom) => {
  if (room.hospitalRoomHashId === '') return '';
  const wardStr =
    room.hospitalWard.hospitalWardHashId !== ''
      ? `（${room.hospitalWard.hospitalWardName} ${room.isGroundFloor ? '' : '地下'}${room.floorNumber}階）`
      : '';

  return `${room.hospitalRoomName}${wardStr}`;
};

export const HospitalProductPlanListBody = () => {
  const {myInfo} = useMyInfo();
  const {isAdmin, isGeneral} = useMyRole();
  const [tableLayout, setTableLayout] = useTableLayout(
    'hospitalProductPlanList',
    useMemo(() => ({managementId: true, inspectionPeriod: true, startMonth: true, startDate: true}), [])
  );

  const {
    updateName,
    updateRootCategoryHashId,
    updateNarrowCategoryHashId,
    updateWholeProductHashId,
    updateStatus,
    updatePerPage,
    updatePage,
    updateOrder,
    ...variables
  } = useQueryVariables();

  const {name, status, page, perPage, rootCategoryHashId, narrowCategoryHashId, order} = variables;

  const apiParameter = useDebounceMemo(() => convertApiParameter(variables), [variables], 300);

  const [resetRootCategoryValue, setResetRootCategoryValue] = useState<boolean>(false);
  const [resetNarrowCategoryValue, setResetNarrowCategoryValue] = useState<boolean>(false);

  const {data, refetch, isLoading} = useGetHospitalProductInspectionPlansQuery(myInfo.hospitalHashId, apiParameter);
  const {statusCountsQuery, refetch: countsRefetch} = useGetHospitalProductInspectionPlansCountsQuery(
    myInfo.hospitalHashId,
    apiParameter
  );
  const {rootCategoryOptions} = useGetHospitalRootCategoriesOptions(myInfo.hospitalHashId);
  const {narrowCategoryOptions} = useGetHospitalNarrowCategoriesOptions(myInfo.hospitalHashId, rootCategoryHashId);

  /** テーブル表示用データ */
  const tableData = useMemo<HospitalProductPlanListElement[]>(
    () =>
      data?.data?.map((item) => ({
        hashId: item.inspectionPlan.inspectionPlanHashId,
        hospitalProductHashId: item.hospitalProductHashId,
        inspectionSettingHashId: item.inspectionPlan.inspectionSettingHashId,
        status: item.inspectionPlan.inspectionPlanStatus,
        planName: item.inspectionPlan.inspectionSettingName,
        managementId: item.managementId,
        displayName: item.displayName,
        name: item.name,
        makerName: item.makerName,
        inspectionPeriod: item.inspectionPlan.inspectionPeriod,
        startMonth: item.inspectionPlan.inspectionStartMonth,
        startDate: item.inspectionPlan.inspectionDay,
        inspectionName: item.inspectionName,
        hospitalRoom: getFullRoom(item.hospitalRoom),
        dateOfPurchase: item.dateOfPurchase,
      })) ?? [],
    [data?.data, status]
  );

  /** 計画済み・未計画カウント */
  const statusCounts = useMemo(() => {
    return {
      all: (statusCountsQuery?.planned ?? 0) + (statusCountsQuery?.unplanned ?? 0), // いらないけど型定義上追加してる。計算量は軽微なため問題ない
      planned: statusCountsQuery.planned,
      unplanned: statusCountsQuery.unplanned,
    };
  }, [statusCountsQuery.planned, statusCountsQuery.unplanned]);

  /** 大分類切り替え */
  const handleChangeRootCategory = useCallback(
    (selectValue?: SelectOptionProps) => {
      updateRootCategoryHashId(selectValue?.value ?? '');
      updateNarrowCategoryHashId('');
    },
    [updateRootCategoryHashId]
  );

  /** 小分類切り替え共通処理 */
  const handleChangeNarrowCategory = useCallback(
    (selectValue?: SelectOptionProps) => {
      updateNarrowCategoryHashId(selectValue?.value ?? '');
    },
    [updateNarrowCategoryHashId]
  );

  useEffect(() => {
    setResetRootCategoryValue(isNullish(rootCategoryHashId));
    setResetNarrowCategoryValue(isNullish(narrowCategoryHashId));
  }, [rootCategoryHashId, narrowCategoryHashId]);

  /** 検索バー横のセレクトボックス設定 */
  const popperSelectBoxButtonProps: PopperSelectBoxProps[] = useMemo(
    () => [
      {
        buttonLabel: '大分類',
        options: rootCategoryOptions,
        isMulti: false,
        searchable: true,
        onChange: handleChangeRootCategory,
        initialOption: rootCategoryOptions.find((option) => option.value === rootCategoryHashId),
        resetValue: resetRootCategoryValue,
      },
      {
        buttonLabel: '小分類',
        options: narrowCategoryOptions,
        isMulti: false,
        searchable: true,
        onChange: handleChangeNarrowCategory,
        initialOption: narrowCategoryOptions.find((option) => option.value === narrowCategoryHashId),
        resetValue: resetNarrowCategoryValue,
      },
    ],
    [
      rootCategoryHashId,
      narrowCategoryHashId,
      rootCategoryOptions,
      narrowCategoryOptions,
      resetRootCategoryValue,
      resetNarrowCategoryValue,
      handleChangeRootCategory,
      handleChangeNarrowCategory,
    ]
  );

  /** 点検計画削除 */
  const handleDeletePlans = useCallback(async (selectedData: HospitalProductPlanListElement[]) => {
    const moveProductPlans = selectedData.filter((item) => item.hashId !== '');
    if (moveProductPlans.length === 0) {
      openSnackBar('選択された点検計画の中で、削除可能な点検計画がありませんでした。', 'left', 'bottom', 'error');
      return;
    }
    await dialogHandler.open(ConfirmBulkDeleteInspectionPlansDialog, {});

    try {
      await bulkDeleteHospitalProductInspectionPlans(
        myInfo.hospitalHashId,
        selectedData.map((item) => item.hashId)
      );
      refetch();
      countsRefetch();
      openSnackBar('点検計画を一括で削除しました。');
    } catch (__e) {
      openSnackBar('点検計画削除に失敗しました。', 'left', 'bottom', 'error');
    }
  }, []);

  /** 点検計画更新 */
  const handleUpdatePlans = useCallback(async (selectedData: HospitalProductPlanListElement[]) => {
    const {year, month, day} = await dialogHandler.open(InspectionScheduleConfigurationDialog, {});

    const isPlannedData = selectedData.some((v) => v.status === InspectionProductPlanned);
    // 計画済みが含まれていたらアラートダイアログを表示
    if (isPlannedData) {
      try {
        await dialogHandler.open(InspectionScheduleBulkCreateAlertDialog, {});
      } catch (_error) {
        return;
      }
    }

    const startDate = dayjs().set('year', year).set('month', month);

    try {
      await bulkUpdateHospitalProductInspectionPlans(
        myInfo.hospitalHashId,
        selectedData.map((item) => ({
          inspectionPlanHashId: item.hashId,
          hospitalProductHashId: item.hospitalProductHashId,
          inspectionSettingHashId: item.inspectionSettingHashId,
          periodicInspectionStartMonth: startDate.format('YYYYMM'),
          periodicInspectionDay: day,
        }))
      );
      refetch();
      countsRefetch();
      openSnackBar('点検計画を一括で更新しました。');
    } catch (__e) {
      openSnackBar('点検計画更新に失敗しました。', 'left', 'bottom', 'error');
    }
  }, []);

  /** 検索バー入力文字ハンドラ */
  const handleChangeSearchName = useCallback((newSearchName: string) => {
    updateName(newSearchName);
  }, []);

  // stateからテーブルの初期状態を作成
  const defaultOrder = useMemo<Order | undefined>(() => {
    // stateにorderが指定されていない場合は、点検名の昇順で表示
    if (isNullish(order)) return {fieldId: 'planName', direction: 'asc'};

    // orderはカンマ区切りで複数指定される場合があるので、表示上は先頭のみを使用する
    const displayOrder = order.includes(',') ? order.split(',')[0] : order;

    return displayOrder.startsWith('-')
      ? {fieldId: displayOrder.slice(1), direction: 'desc'}
      : {fieldId: displayOrder, direction: 'asc'};
  }, [order]);

  /** 並び順変更ハンドラ */
  const handleOrderChange = useCallback(
    (columnIndex: number, orderDirection: 'asc' | 'desc') => {
      if (columnIndex === -1) {
        // HIT-4104 デフォルトのソートは 点検名の昇順+管理番号
        // orderが指定されていない場合はデフォルトソートに戻す
        updateOrder('planName,managementId');
        return;
      } else {
        const baseOrder = `${orderDirection === 'desc' ? '-' : ''}${String(
          tableLayout?.currentLayout[columnIndex].field
        )}`;

        if (tableLayout?.currentLayout[columnIndex].field !== 'managementId') {
          // HIT-4104 管理番号以外でソート順を指定した場合は、第2ソート項目は管理番号の昇順
          updateOrder(`${baseOrder},managementId`);
        } else {
          // HIT-4104 管理番号でソート順を指定した場合は、そのまま指定
          updateOrder(baseOrder);
        }
      }
    },
    [tableLayout?.currentLayout]
  );

  /** テーブルレイアウト切り替え */
  const handleChangeTableLayout = useCallback(
    (newTableLayout: TableLayoutResult) => {
      setTableLayout(newTableLayout);
    },
    [setTableLayout]
  );

  return (
    <StyledBody container>
      <StyledSideBar>
        <StatusSelector counts={statusCounts} selectedStatus={status} onChange={updateStatus} />
      </StyledSideBar>
      <StyledTableViewLayout>
        <TableViewLayout.Header>
          <SearchAndFilter
            searchNameValue={name ?? ''}
            tableLayout={tableLayout}
            onChangeSearchName={handleChangeSearchName}
            onChangeTableLayout={handleChangeTableLayout}
            searchLabel="機種名・型式・管理番号で検索"
            firstReactNode={<WholeProductPopperButton />}
            popperSelectBoxButtonProps={popperSelectBoxButtonProps}
          />
        </TableViewLayout.Header>
        <TableViewLayout.Body>
          <HospitalProductPlanListTable
            tableData={tableData}
            isLoading={isLoading}
            defaultOrder={defaultOrder}
            canEditPlan={isAdmin || isGeneral} // 管理者権限か一般権限の場合は編集可能
            canDeletePlan={isAdmin && status !== 'unplanned'} // 削除は現状管理者のみ
            onClickChangeInspectionDate={handleUpdatePlans}
            onClickDeletePlan={handleDeletePlans}
            tableLayout={tableLayout}
            handleOrderChange={handleOrderChange}
          />
        </TableViewLayout.Body>
        <TableViewLayout.Footer container justifyContent="space-between">
          <PaginationAndPerPage
            totalCount={data?.totalCount ?? 0}
            currentPage={page}
            pageSize={perPage}
            onPageChange={updatePage}
            onPageSizeChange={updatePerPage}
          />
        </TableViewLayout.Footer>
      </StyledTableViewLayout>
    </StyledBody>
  );
};
