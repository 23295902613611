import React from 'react';
import {makeStyles, Grid, Theme} from '@material-ui/core';
import {UserNotification} from '@modules/notifications/types';
import {NotificationListItem} from './NotificationListItem';

export type NotificationListProps = {
  listTitle: string;
  listItem: UserNotification[];
  onClickNotificationItem: (notification: UserNotification) => void;
};

export const NotificationList: React.FC<NotificationListProps> = (props) => {
  const classes = useStyles();

  return (
    <Grid container direction={'column'} className={classes.contentContainer} alignItems={'center'}>
      <Grid item className={classes.contentHeader}>
        {props.listTitle}
      </Grid>
      {props.listItem.map((notification, key) => (
        <Grid item className={classes.listItemContainer} key={`listItem_${key}`}>
          <NotificationListItem
            notification={notification}
            onClick={() => props.onClickNotificationItem(notification)}
          />
        </Grid>
      ))}
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  contentContainer: {
    fontSize: '14px',
  },
  contentHeader: {
    padding: '16px 20px',
    backgroundColor: '#fafafa',
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    width: '100%',
    fontWeight: 'bold',
    color: '#586F8E',
  },
  listItemContainer: {
    width: '100%',
  },
}));
