import React from 'react';
import {Grid, styled} from '@material-ui/core';
import {Section} from '@components/molecules/InspectionTableFormItems/Section';
import {InnerItem} from './InnerItem';
import {InspectionTableIndex} from '@modules/inspections/types';

const Container = styled(Grid)({
  flexDirection: 'column',
  '& > div': {
    marginBottom: '28px',
  },
  '& > div:last-child': {
    marginBottom: '0px',
  },
});

export type InspectionPreviewProps = {
  inspectionTable: InspectionTableIndex;
};

export const InspectionPreview = (props: InspectionPreviewProps) => {
  const {inspectionTable} = props;
  return (
    <Container container>
      {inspectionTable.items.map((item, i) => {
        if (item.type === 'section') {
          return (
            <Section key={i} name={item.name}>
              {item.items.map((sectionItem, j) => (
                <InnerItem key={j} item={sectionItem} />
              ))}
            </Section>
          );
        } else {
          return <InnerItem key={i} item={item} />;
        }
      })}
    </Container>
  );
};
