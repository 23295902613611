import {atom} from 'jotai';
import {atomWithReset, atomWithStorage} from 'jotai/utils';
import {FetchRentalsParams} from '@modules/rentals/api';
import {RentalHistoryMenuItemType} from './types';
import {Order} from '@molecules/Table/props';
import {
  convertDateToSimpleDate,
  getTermOfYesterday,
  getTermOfToday,
  getTermOfThisWeek,
  getTermOfLastWeek,
  getTermOfLastMonth,
  getTermOfThisMonth,
  getTermOfThisYear,
} from '@front-libs/helpers';
import dayjs from 'dayjs';
import {ResultType, SelectorConditionValue} from '@components/molecules/Drawers/FilterDrawer/types';
import {TermOptions} from './consts';
import {
  TermAll,
  TermCustom,
  TermLastMonth,
  TermLastWeek,
  TermThisMonth,
  TermThisWeek,
  TermThisYear,
  TermToday,
  TermYesterday,
} from '@components/molecules/TermPicker';

type DetailFilter = {
  hospitalRoomHashId: string;
};

const LOCAL_STORAGE_KEY_RENTAL_HISTORY_MENU_STATUS = 'hitotsu/rental_history_menu_status';
const LOCAL_STORAGE_KEY_RENTAL_HISTORY_SELECTED_WARD_STATUS = 'hitotsu/rental_history_selected_ward';
const LOCAL_STORAGE_KEY_RENTAL_LIST = 'hitotsu/rental_page_size_status';
export const rentalHistoryMenuStatus = atomWithStorage<RentalHistoryMenuItemType>(
  LOCAL_STORAGE_KEY_RENTAL_HISTORY_MENU_STATUS,
  {
    label: '貸出中機器一覧',
    value: 'renting',
  }
);

export const rentalHistorySelectedWardStatus = atomWithStorage<{label: string; value: string}>(
  LOCAL_STORAGE_KEY_RENTAL_HISTORY_SELECTED_WARD_STATUS,
  {
    label: 'すべての大エリア',
    value: '',
  }
);

type DateRange = {
  value: (typeof TermOptions)[number]['value'];
  from?: Date;
  to?: Date;
};

export const searchNameAtom = atom<string | null>(null);
export const orderKeyAtom = atom<Order | null>(null);
export const pageAtom = atomWithReset<number>(1);
export const pageSizeAtom = atomWithStorage<number>(LOCAL_STORAGE_KEY_RENTAL_LIST, 20);
export const checkoutAtAtom = atom<DateRange>({value: TermAll});
export const returnedAtAtom = atom<DateRange>({value: TermAll});
export const searchFilterResultsAtom = atom<ResultType[]>([]);

const getTermRange = (r: DateRange): [Date | null, Date | null] => {
  switch (r.value) {
    case TermAll:
      return [null, null];
    case TermYesterday:
      return getTermOfYesterday();
    case TermToday:
      return getTermOfToday();
    case TermThisWeek:
      return getTermOfThisWeek();
    case TermLastWeek:
      return getTermOfLastWeek();
    case TermLastMonth:
      return getTermOfLastMonth();
    case TermThisMonth:
      return getTermOfThisMonth();
    case TermThisYear:
      return getTermOfThisYear();
    case TermCustom:
      return [r.from ?? null, r.to ? dayjs(r.to).endOf('day').toDate() : null];
    default: {
      const undefinedType: never = r.value;
      console.warn(`undefined type in getTermRange: ${undefinedType}`);
    }
  }

  return [null, null];
};

export const rentalVariablesAtom = atom<FetchRentalsParams>((get) => {
  const historyStatus = get(rentalHistoryMenuStatus);
  const selectedWard = get(rentalHistorySelectedWardStatus);
  const order = get(orderKeyAtom);
  const checkoutAt = get(checkoutAtAtom);
  const returnedAt = get(returnedAtAtom);
  const searchName = get(searchNameAtom);
  const detailFilter = convertFilterResults(get(searchFilterResultsAtom));

  const [checkoutAtFrom, checkoutAtTo] = getTermRange(checkoutAt);
  const [returnedAtFrom, returnedAtTo] = getTermRange(returnedAt);

  return {
    page: get(pageAtom) - 1 || 0,
    perPage: get(pageSizeAtom),
    name: searchName !== '' && searchName !== null ? searchName : undefined,
    order: order ? `${order.direction === 'desc' ? '-' : ''}${order.fieldId}` : '-checkout_at',
    status: historyStatus.value !== '' ? historyStatus.value : undefined,
    hospitalWardHashId: selectedWard.value !== '' ? selectedWard.value : undefined,
    checkoutAtFrom: checkoutAtFrom !== null ? convertDateToSimpleDate(checkoutAtFrom) : undefined,
    checkoutAtTo:
      checkoutAtTo !== null ? convertDateToSimpleDate(dayjs(checkoutAtTo).add(1, 'day').toDate()) : undefined,
    returnedAtFrom: returnedAtFrom !== null ? convertDateToSimpleDate(returnedAtFrom) : undefined,
    returnedAtTo:
      returnedAtTo !== null ? convertDateToSimpleDate(dayjs(returnedAtTo).add(1, 'day').toDate()) : undefined,
    ...detailFilter,
  };
});

const convertFilterResults = (filterResults: ResultType[]): Partial<DetailFilter> => {
  const detailFilter: Partial<DetailFilter> = {};
  filterResults.forEach((item) => {
    switch (item.key) {
      case 'hospitalRoomHashId':
        detailFilter.hospitalRoomHashId = (item.resultValue as SelectorConditionValue)[0].value as string;
        break;
      default:
        break;
    }
  });
  return detailFilter;
};
