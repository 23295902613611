import React, {ComponentProps, useCallback} from 'react';
import {TableCell as MTableCell, Checkbox, makeStyles} from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  smallCell: {
    paddingTop: '4px',
    paddingBottom: '4px',
  },
  smallCheckbox: {
    paddingTop: '0px',
    paddingBottom: '0px',
    '& > span > .MuiSvgIcon-root': {
      width: '20px',
      height: '20px',
    },
  },
}));

type CheckboxCellProps = ComponentProps<typeof Checkbox> & {size: 'small' | 'medium'};

export const CheckboxCell = (props: CheckboxCellProps) => {
  const {size, ...rest} = props;
  const classes = useStyles();

  const handleMouseEvent = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();

    return false;
  }, []);

  return (
    <MTableCell
      className={clsx(size === 'small' && classes.smallCell)}
      onClick={handleMouseEvent}
      onMouseDown={handleMouseEvent}
      onMouseUp={handleMouseEvent}>
      <Checkbox className={clsx(size === 'small' && classes.smallCheckbox)} color="primary" {...rest} />
    </MTableCell>
  );
};
