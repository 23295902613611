import React from 'react';
import {styled, Typography, Grid} from '@material-ui/core';
import {getInspectionStatusMenus} from '@Apps/InspectionResultList/pc/consts';
import {InspectionResultStatus} from '@modules/inspection_results/enum';

type InspectionResultStatusBadgeProps = {
  status: InspectionResultStatus;
  size?: 'small';
};

export const InspectionResultStatusBadge: React.FC<InspectionResultStatusBadgeProps> = (props) => {
  const {status, size} = props;

  return (
    <StyledGrid>
      <CustomTypography status={status} size={size} variant="inherit">
        {getInspectionStatusMenus().find((i) => i.value === status)?.label ?? ''}
      </CustomTypography>
    </StyledGrid>
  );
};

export const SmallInspectionResultStatusBadge: React.FC<InspectionResultStatusBadgeProps> = (props) => {
  return <InspectionResultStatusBadge {...props} size="small" />;
};

const StyledGrid = styled(Grid)({
  minWidth: '100px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
});

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
const withStatusStyles = (Component: any) => {
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  return (props: any) => {
    const {status, size, ...otherProps} = props;

    const statusStyle = {
      flex: '0 0 65px',
      borderRadius: '100px',
      padding: '2px 0px',
      fontSize: size === 'small' ? '10px' : '12px',
      fontWeight: 'bold',
      textAlign: 'center',
      borderWidth: '2px',
      borderStyle: 'solid',
    };
    const unplanned = {
      background: '#feeaed',
      color: '#C7243A',
      borderColor: '#C7243A',
    };
    const skipped = {
      background: '#E4E6EC',
      color: '#65676B',
      borderColor: '#65676B',
    };
    const uncompleted = {
      background: '#DEEBFF',
      color: '#2A96E8',
      borderColor: '#2A96E8',
    };
    const completed = {
      background: '#EAF5EA',
      color: '#3C9E58',
      borderColor: '#3C9E58',
    };

    if (status === 'unplanned') {
      return <Component style={{...statusStyle, ...unplanned}} {...otherProps} />;
    } else if (status === 'skipped') {
      return <Component style={{...statusStyle, ...skipped}} {...otherProps} />;
    } else if (status === 'uncompleted') {
      return <Component style={{...statusStyle, ...uncompleted}} {...otherProps} />;
    } else if (status === 'completed') {
      return <Component style={{...statusStyle, ...completed}} {...otherProps} />;
    } else {
      return <Component {...otherProps} />;
    }
  };
};

const CustomTypography = withStatusStyles(Typography);
