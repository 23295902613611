import {useMemo} from 'react';
import {axios} from '@front-libs/core';
import {useQuery} from 'react-query';
import {RoleUsers, UserIndex, UserResource} from './types';
import {AxiosResponse} from 'axios';
import {applicationHashIdMap} from './hooks/useMyInfo';

interface StandardListResponse<T> {
  totalCount: number;
  page: number;
  data: T[];
}

export type CreateHospitalUserParams = {
  hospitalHashId: string;
  email: string;
  username: string;
  password: string;
  lastName: string;
  firstName?: string;
  lastNameKana?: string;
  firstNameKana?: string;
  isShared?: boolean;
  initialized?: boolean;
  isUserAdmin?: boolean;
  permissions: string[];
  sessionExpirationTimeSec?: number;
};

export type UpdateHospitalUserParams = {
  hospitalUserHashId?: string;
  hospitalHashId?: string;
  username?: string;
  lastName?: string;
  firstName?: string;
  email?: string;
  isShared?: boolean;
  thumbnailFileHashId?: string;
  nextInitializeStep?: number;
  initialized?: boolean;
  occupations?: string[];
  isUserAdmin?: boolean;
  isSafetyControlManager?: boolean;
  permissions?: string[];
  sessionExpirationTimeSec?: number | null;
  rentalLayoutPreference?: 'default_order' | 'customize';
};

let myInfoData: AxiosResponse<UserIndex, unknown> | undefined = undefined;

/** 取得済みのUserIndexを削除 */
export const ResetMyInfo = () => {
  myInfoData = undefined;
};

/** 取得済みのUserIndexがあれば返す。なければAPIから取得 */
export const fetchMyInfoForCache = async () => {
  if (myInfoData !== undefined && myInfoData.data) {
    return myInfoData;
  }
  const query = await fetchMyInfo();
  if ('data' in query) myInfoData = query;
  return query;
};

const fetchMyInfo = () => {
  const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/users/me`;
  return axios.get<UserIndex>(baseUrl);
};

const fetchUser = async () => {
  const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/users/me`;
  const res = await axios.get<UserIndex>(baseUrl);
  return res.data;
};

export const useFetchMyInfo = (userHashID: string) => {
  return useQuery(userHashID, fetchUser);
};

export type HospitalUserParams = {
  page: number;
  perPage: number;
  order?: string;
  name?: string;
  updatedAtFrom?: string;
  includeDeleted?: boolean;
  roleApplicationHashID?: string;
};

export type FetchHospitalUsersResult = StandardListResponse<UserIndex>;

export const fetchHospitalUsers = async (hospitalHashId: string, params: HospitalUserParams) => {
  params.roleApplicationHashID = applicationHashIdMap.asset;
  const hospitalUserUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/users`;
  const res = await axios.get<StandardListResponse<UserIndex>>(hospitalUserUrl, {params});
  return res.data;
};

export const useFetchHospitalUsers = (hospitalHashId: string, params: HospitalUserParams = {page: 0, perPage: 100}) => {
  const query = useQuery([hospitalHashId, params], () => fetchHospitalUsers(hospitalHashId, params));
  return useMemo(
    () => ({
      ...query,
      data: query.data?.data ?? [],
      totalCount: query.data?.totalCount ?? 0,
    }),
    [query]
  );
};

export const useFetchAllHospitalUsers = (
  hospitalHashId: string,
  params: HospitalUserParams = {page: 0, perPage: 100}
) => {
  params.roleApplicationHashID = applicationHashIdMap.asset;
  params.includeDeleted = true;
  const query = useQuery([hospitalHashId, params], () => fetchHospitalUsers(hospitalHashId, params));
  return useMemo(
    () => ({
      ...query,
      data: query.data?.data ?? [],
      totalCount: query.data?.totalCount ?? 0,
    }),
    [query]
  );
};

export const createHospitalUser = async (data: CreateHospitalUserParams) => {
  const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/auth/signup`;
  return axios.post<UserIndex>(baseUrl, data);
};

export const updateHospitalUser = async (
  hospitalHashId: string,
  hospitalUserHashId: string,
  data: UpdateHospitalUserParams
) => {
  const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/users/${hospitalUserHashId}`;
  return axios.put<UserIndex>(baseUrl, data);
};

//FixMe: Api未実装
export const deleteHospitalUser = async (hospitalHashId: string, hospitalUserHashId: string) => {
  const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/users/${hospitalUserHashId}`;
  return axios.delete<UserIndex>(baseUrl);
};

export const sendInitialUserAccounts = async (value: {
  hospitalHashId: string;
  hospitalName: string;
  hitotsuHospitalCode: string;
  data: {fullName: string; fullNameKana: string; authority: string; username: string; password: string}[];
}) => {
  const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/auth/signup/send_email`;
  return axios.post(baseUrl, {
    hospitalHashId: value.hospitalHashId,
    hospitalName: value.hospitalName,
    hitotsuHospitalCode: value.hitotsuHospitalCode,
    data: value.data,
  });
};

export const getMyResources = (organizationHashID: string, userHashID: string) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/organizations/${organizationHashID}/users/${userHashID}/resources`;
  return axios.get<UserResource[]>(baseUrl);
};

export const getOrganizationRoleUsers = async (organizationId: string) => {
  const baseURL = `${import.meta.env.VITE_SERVER_URL}/api/v1/organizations/${organizationId}/role-users`;
  const {data} = await axios.get<StandardListResponse<RoleUsers>>(baseURL);
  return data.data;
};
