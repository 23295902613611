import {
  InspectionTypes as BaseInspectionTypeValues,
  InspectionTypeOptions as BaseInspectionTypeOptions,
} from '@modules/inspections/enum';
import {InspectionType, InspectionTypeOption} from '../types';

export const InspectionTypeValues = [...BaseInspectionTypeValues, 'adhoc'] as const;

export const InspectionTypeOptions: readonly InspectionTypeOption[] = [
  ...BaseInspectionTypeOptions,
  {
    value: 'adhoc',
    label: '臨時点検',
  },
] as const;

export const InspectionTypeLabelMap: Record<InspectionType, string> = {
  pre_use: '使用前点検',
  in_use: '使用中点検',
  post_use: '使用後点検',
  periodic: '定期点検',
  adhoc: '臨時点検',
} as const;

export const OperationTypeValues = ['inspect', 'adhoc_inspect', 'skip', 'update_scheduled_time'] as const;
