import {useMemo, useCallback} from 'react';
import {atom} from 'jotai';
import {useAtomValue, useSetAtom} from 'jotai';
import {focusedItemAtom} from '@Apps/InspectionSettings/InspectionEditor/states';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {UploadDocumentDialog} from '@organisms/Files/FileList/UploadDocumentDialog';
import {openSnackBar} from '@molecules/SnackBar';
import {uploadInspectionManual} from '@modules/inspections/api';

export const useUploadInspectionManual = (props: {
  hospitalHashId: string;
  inspectionHashId: string;
  onUploadInspectionManual: () => void;
}) => {
  const {hospitalHashId, inspectionHashId, onUploadInspectionManual} = props;

  const handleClickUploadInspectionManualButton = useCallback(async () => {
    // PDFのみ
    const fileData = await dialogHandler.open(UploadDocumentDialog, {
      acceptFile: ['application/pdf'],
    });

    try {
      await uploadInspectionManual({
        hospitalHashId,
        inspectionHashId,
        file: fileData.file,
        fileName: fileData.fileName,
      });
      onUploadInspectionManual();
      openSnackBar('ファイルをアップロードしました');
    } catch (_e) {
      openSnackBar('ファイルのアップロードに失敗しました', 'left', 'bottom', 'error');
    }
  }, []);

  return {handleClickUploadInspectionManualButton};
};

export const useFocused = (sectionIndex: number, fieldIndex: number | null) => {
  const updateFocusedItem = useSetAtom(focusedItemAtom);
  const isFocused = useAtomValue(
    useMemo(
      () =>
        atom((get) => {
          const focusedItem = get(focusedItemAtom);
          return focusedItem.sectionIndex === sectionIndex && focusedItem.fieldIndex === fieldIndex;
        }),
      [sectionIndex, fieldIndex]
    )
  );
  const onFocused = useCallback(
    (el: HTMLDivElement) => {
      updateFocusedItem({
        el,
        sectionIndex,
        fieldIndex,
      });
    },
    [updateFocusedItem, sectionIndex, fieldIndex]
  );
  return [isFocused, onFocused] as const;
};
