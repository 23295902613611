import {axios} from '@front-libs/core';
import {isNullish} from '@front-libs/helpers';
import {FileCategory, FileIndex, FileListResponse} from '@modules/files/types';

export type UploadFileParam = {
  file: File;
  fileName: string;
  category: FileCategory;
};

export type UpdateFileParam = {
  category?: FileCategory;
  fileName?: string;
  isFileSharingAcrossProducts?: boolean;
};
export type UpdateHospitalProductFileParam = {
  /** Trueの場合は院内製品間でファイル共有がされている */
  isFileSharingAcrossProducts: boolean;
};

export const uploadFile = async (data: UploadFileParam) => {
  const baseURL = `${import.meta.env.VITE_SERVER_URL}/api/v1/files`;
  const params = new FormData();
  params.append('file', data.file);
  params.append('file_name', data.fileName);
  params.append('category', data.category);
  return await axios.post<FileIndex>(baseURL, params, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

/**
 * 病院内製品に添付されたファイルを1件更新する。
 * @param hospitalHashId 病院のハッシュID
 * @param hospitalProductHashId 院内製品のハッシュID
 * @param fileHashId ファイルのハッシュID
 * @param params isFileSharingAcrossProducts: boolean
 * @returns
 */
export const updateHospitalProductFile = async (
  hospitalHashId: string,
  hospitalProductHashId: string,
  fileHashId: string,
  params: UpdateHospitalProductFileParam
) => {
  const baseURL = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/products/${hospitalProductHashId}/files/${fileHashId}`;
  return await axios.put<FileIndex>(baseURL, params);
};
export const updateFile = async (fileHashId: string, params: UpdateFileParam) => {
  const baseURL = `${import.meta.env.VITE_SERVER_URL}/api/v1/files/${fileHashId}`;
  return await axios.put<FileIndex>(baseURL, params);
};

export const deleteFile = async (fileHashId: string) => {
  const baseURL = `${import.meta.env.VITE_SERVER_URL}/api/v1/files/${fileHashId}`;
  return await axios.delete<FileIndex>(baseURL);
};

export type GetHospitalFileParam = {
  name?: string;
  categories?: FileCategory[];
  page?: number;
  perPage?: number;
  order?: string;
};

export const getHospitalFileByHashId = async (hospitalHashId: string, fileHashId: string) => {
  const baseURL = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/files/${fileHashId}`;
  return await axios.get<FileIndex>(baseURL);
};

export const getHospitalFiles = async (hospitalHashId: string, params?: GetHospitalFileParam) => {
  const baseURL = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/files`;
  return await axios.get<FileListResponse>(baseURL, {params: serializeQueryParameters(params)});
};

const serializeQueryParameters = (params?: GetHospitalFileParam) => {
  if (isNullish(params)) return undefined;

  return {...params, categories: params.categories?.join(',')};
};

const fetchFile = async (initialUrl: string) => {
  try {
    const response = await axios.get<string>(initialUrl);
    if (response.status === 200) {
      const redirectUrl = response.data;
      return {redirectUrl, error: null};
    } else {
      return {redirectUrl: null, error: '画像のURLを取得中にエラーが発生しました。'};
    }
  } catch (_error) {
    return {redirectUrl: null, error: '画像処理中にエラーが発生しました。'};
  }
};

export const fetchFileUrlByHashId = async (fileHashId: string) => {
  const initialUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/files/${fileHashId}`;
  return fetchFile(initialUrl);
};

export const fetchFileUrlByPath = async (path: string) => {
  const slashedPath = path.startsWith('/') ? path : `/${path}`;
  const initialUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/files/s3${slashedPath}`;
  return fetchFile(initialUrl);
};
