import React from 'react';
import {styled} from '@material-ui/styles';
import {Box, Button} from '@material-ui/core';
import {Close} from '@material-ui/icons';
import {RegistrationRepair, RegistrationRepairFormProps} from './RegistrationRepair';

const ContainerBox = styled(Box)(() => ({
  backgroundColor: 'white',
  position: 'absolute', // 親ElementがPosition relative
  inset: '-40px -32px 0px 0px',
  zIndex: 1,
  boxShadow: `#00000099 0px 0px 4px 0px`,
  display: 'grid',
  alignContent: 'space-between',
  padding: '24px 32px',
}));

const TitleBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontWeight: 'bold',
  fontSize: '20px',
  '& .MuiButton-label': {
    marginRight: '-16px',
  },
});

type Props = {
  onSubmit: (res: Partial<RegistrationRepairFormProps>) => void;
  onClose: VoidFunction;
};

/**
 * 新規登録フォーム
 * @param onSubmit
 * @returns
 */
export const NewRegistrationFormContainer = ({onSubmit, onClose}: Props) => {
  return (
    <ContainerBox display="flex" flexDirection="column" alignItems="left" p={1}>
      <TitleBox>
        <span>修理を新規登録</span>
        <Button onClick={onClose}>
          <Close />
        </Button>
      </TitleBox>
      <RegistrationRepair onSubmit={onSubmit} onClose={onClose} />
    </ContainerBox>
  );
};
