import {dialogHandler} from '@components/molecules/Dialogs/DialogHandler';
import {InnerLoading} from '@components/molecules/Loading';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import CloseIcon from '@mui/icons-material/Close';
import {Box, Button, Dialog, DialogActions, DialogTitle, IconButton, styled, Typography} from '@mui/material';
import React, {Suspense} from 'react';
import {getHospitalProducts} from '@modules/hospital_products/api/hospitalProductApi';

type ConfirmRelinkDialogProps = {
  isMulch: boolean;
} & DialogProps;

const ProductContentBox = styled(Box)({
  margin: '32px',
  color: ' #172B4D',
  '& .MuiTypography-root': {
    fontSize: '14px',
  },
});

const singleProductContent = () => {
  return (
    <ProductContentBox>
      <Typography>
        こちらの機器を別機種に再紐づけすると、元の機種に紐づく機器数が０件となり、元の機種情報が削除されます。
      </Typography>
      <Typography>※元の機種に紐づく機種共通ファイルは「ファイル一覧」より閲覧できます。</Typography>
      <Typography sx={{marginTop: '32px'}}>また、機種を再紐付けすると元に戻せません。</Typography>
      <Typography>再紐付け後、対象機器の定期点検計画の再設定をお願いします。</Typography>
    </ProductContentBox>
  );
};

const multiProductContent = () => {
  return (
    <ProductContentBox>
      <Typography>機種を再紐付けすると元に戻せません。</Typography>
      <Typography>再紐付け後、対象機器の定期点検計画の再設定をお願いします。</Typography>
    </ProductContentBox>
  );
};

/**
 * 再紐付け確認ダイアログ
 * @param param0
 * @returns
 */
const ConfirmRelinkDialog: React.FC<ConfirmRelinkDialogProps> = ({
  open,
  actions,
  isMulch,
}: ConfirmRelinkDialogProps) => {
  return (
    <Dialog maxWidth="lg" open={!!open} PaperProps={{sx: {minWidth: isMulch ? '776px' : '512px'}}}>
      <Suspense fallback={<InnerLoading />}>
        <DialogTitle sx={{fontWeight: 700, padding: '32px 32px 0'}}>機種を再紐付けしますか？</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => actions.reject()}
          sx={{
            position: 'absolute',
            fontSize: 24,
            right: '16px',
            top: '24px',
          }}>
          <CloseIcon sx={{color: '#172B4D'}} />
        </IconButton>
        {isMulch ? multiProductContent() : singleProductContent()}
        <DialogActions sx={{margin: '8px 32px 32px'}}>
          <Button
            variant="contained"
            color="primary"
            sx={{backgroundColor: '#0052CC'}}
            onClick={() => actions.resolve(true)}>
            機種を再紐付けする
          </Button>
          <Button onClick={() => actions.reject()} sx={{color: '#0052CC !important', backgroundColor: '#fffff'}}>
            キャンセル
          </Button>
        </DialogActions>
      </Suspense>
    </Dialog>
  );
};

/**
 * 再紐付け確認ダイアログを開き、再紐付けするを選択した時はtrue キャンセル時はfalseを返す
 */
export const openConfirmRelinkDialog = async (hospitalHashId: string, wholeProductHashId: string) => {
  // 紐づいた機種数を取得
  const hospitalProducts = await getHospitalProducts(hospitalHashId, {
    page: 0,
    perPage: 20,
    wholeProductHashIds: wholeProductHashId,
  });

  try {
    await dialogHandler.open<ConfirmRelinkDialogProps, boolean>(ConfirmRelinkDialog, {
      isMulch: hospitalProducts?.totalCount >= 2,
    });
    return true;
  } catch (_e) {
    return false;
  }
};
