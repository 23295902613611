import React from 'react';
import {styled, Tabs as MuiTabs, Tab as MuiTab} from '@mui/material';
import {tabs} from './constants';
import {TabValue} from './types';

type Props = {
  value: TabValue;
  onChange: (newValue: TabValue) => void;
};

const StyledTabs = styled(MuiTabs)({
  fontSize: '14px',
  '& .MuiTabs-indicator': {
    display: 'none',
  },
  '& button': {
    minHeight: 'unset',
    height: '40px',
    borderRadius: '4px',
    color: '#172B4D',
    '&.Mui-selected': {
      backgroundColor: '#0052CC14',
      fontWeight: 600,
      color: '#0052CC',
    },
    '& > svg': {
      fontSize: '20px',
    },
  },
  '& button:nth-of-type(n+2)': {
    marginLeft: '8px',
  },
});

export const Tabs = ({value, onChange}: Props) => {
  const handleChange = (_: React.SyntheticEvent, newValue: TabValue) => {
    onChange(newValue);
  };

  return (
    <StyledTabs value={value} onChange={handleChange}>
      {tabs.map(({icon, label, value}) => (
        <MuiTab key={value} icon={icon} iconPosition="start" label={label} value={value} />
      ))}
    </StyledTabs>
  );
};
