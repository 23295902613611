import React from 'react';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import {Typography} from '@mui/material';
import {styled} from '@material-ui/core/styles';
import {ArrowDropDown} from '@material-ui/icons';
import {SearchBar} from '@molecules/SearchBar/SearchBar';
import {Pagination} from '@material-ui/lab';
import {InspectionIndex} from '@modules/inspections/types';
import {isNullish} from '@front-libs/helpers';
import {InspectionType} from '@modules/inspections/enum';
import {Button, ClickAwayListener} from '@material-ui/core';
import {useInspectionTable} from './hooks';

interface SearchResultListProps {
  data: InspectionIndex[];
  onItemClick: (newValue: InspectionIndex) => void;
}

const SearchResultList: React.FC<SearchResultListProps> = ({data, onItemClick}) => {
  return (
    <SearchResultListContainer>
      {data.map((item, index) => (
        <Typography
          sx={{
            padding: '8px 16px',
            cursor: 'pointer',
            '&&:hover': {
              backgroundColor: '#e5f5f8',
            },
          }}
          key={index}
          onClick={() => {
            onItemClick(item);
          }}>
          {item.name}
        </Typography>
      ))}
    </SearchResultListContainer>
  );
};

const SearchResultListContainer = styled('div')({
  overflowY: 'auto',
  maxHeight: '300px',
  margin: '16px 0px',
});

const SearchBarRoot = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export type PopperBtnInspectionTableProps = {
  inspectionName: string;
  onChangeInspection: (newValue: InspectionIndex) => void;
  type: InspectionType | null;
};

export const PopperBtnInspectionTable = (props: PopperBtnInspectionTableProps) => {
  const {
    open,
    setOpen,
    searchText,
    page,
    data,
    totalCount,
    handleClick,
    handleInputChange,
    handleItemClick,
    handlePageChange,
    totalPage,
    startDisplayPosition,
    endDisplayPosition,
    buttonRef,
    type,
  } = useInspectionTable(props);

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div>
        <PullDownBtn
          style={{backgroundColor: isNullish(type) ? '#F3F4F6' : 'white', borderColor: '#b4b5b6'}}
          ref={buttonRef}
          onClick={handleClick}
          disabled={isNullish(type)}>
          {props.inspectionName ? (
            <Typography>{props.inspectionName}</Typography>
          ) : (
            <Typography sx={{color: '#a6a6a6'}}>使用する点検表を選択</Typography>
          )}
          <ArrowDropDown />
        </PullDownBtn>
        <Popper open={open} anchorEl={buttonRef.current} placement="bottom" transition>
          {() => (
            <BasePaper>
              <SearchBar
                placeholder="点検表名で検索"
                searchName={searchText}
                backgroundColor="white"
                onChangeSearchName={handleInputChange}
              />
              <SearchResultList data={data} onItemClick={handleItemClick} />
              <SearchBarRoot>
                {totalCount}件のうち{startDisplayPosition}件目-{endDisplayPosition}件目までを表示しています。
                <Pagination
                  page={page}
                  count={totalPage}
                  onChange={(_, newPage) => handlePageChange(newPage)}
                  shape="rounded"
                />
              </SearchBarRoot>
            </BasePaper>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

const PullDownBtn = styled(Button)({
  display: 'flex',
  justifyContent: 'space-between',
  border: '1px solid black',
  padding: '7px 16px',
  borderRadius: '4px',
  width: '360px',
});

const BasePaper = styled(Paper)({
  border: '1px solid',
  display: 'flex',
  flexDirection: 'column',
  padding: '16px',
  backgroundColor: 'white',
});
