import React from 'react';
import {Box, Typography, SxProps, Theme} from '@mui/material';

type NoDataProps = {
  title?: string;
  message?: string;
};

const boxStyle: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  '& > p': {
    fontSize: '0.875rem',
    padding: '16px',
  },
};

/**
 * テーブル等でデータが無い時に表示するコンポーネント
 * タイトルは太字 メッセージは通常フォント
 * @param param.title タイトル
 * @param param.message メッセージ
 * @returns
 */
export const NoContentMessage = ({title, message}: NoDataProps) => (
  <Box sx={boxStyle}>
    {title && (
      <Typography variant="h6" component="p" sx={{fontWeight: 'bold'}}>
        {title}
      </Typography>
    )}
    {message && (
      <Typography variant="body2" component="p">
        {message}
      </Typography>
    )}
  </Box>
);
