import {
  TableContainer,
  styled,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Radio,
  Typography,
  CSSObject,
} from '@mui/material';
import React, {useCallback} from 'react';
import {Rows} from './type';

const StyledTypography = styled(Typography)(() => ({
  color: '#172B4D',
}));

const StyledTableContainer = styled(TableContainer)(() => ({
  overflowY: 'scroll',
  maxWidth: '100%',
  height: '100%',
}));

const StyledTableHead = styled(TableHead)(() => ({
  position: 'sticky',
  top: 0,
  zIndex: 1000,
  backgroundColor: 'white',
}));

const cellStyles: CSSObject = {
  borderBottom: 'unset',
  lineHeight: 1,
  padding: '0 8px 8px 8px',
  '.MuiRadio-root': {
    width: '20px',
    marginRight: '4px',
  },
};

const StyaledTableHeadCell = styled(TableCell)(() => ({
  borderBottom: 'unset',
  width: '20px',
  marginRight: '4px',
}));

// TODO: API側が変更になるので動作確認用に一旦残しておく
// const rows = [
//   {
//     hashID: 'aaaaaa',
//     name: 'NKV-330',
//     displayName: 'NKV-330 人工呼吸器',
//     makerName: 'nikkiso',
//   },
// ];
// for (let i = 0; i < 15; i++) {
//   rows.push({
//     hashID: 'aaaaaa' + i,
//     name: 'NKV-330' + i,
//     displayName: 'NKV-330 人工呼吸器',
//     makerName: 'nikkiso',
//   });
// }

type ProductTableProps = {
  onSelectHashId: (hashId: string) => void;
  SelectHashId: string | undefined;
  rows: Rows[];
};

export const ProductTable = (props: ProductTableProps) => {
  const {onSelectHashId, SelectHashId, rows} = props;

  const handleRadioChange = useCallback(
    (hashId: string) => {
      onSelectHashId(hashId);
    },
    [onSelectHashId]
  );

  return (
    <StyledTableContainer>
      <Table sx={{tableLayout: 'fixed', borderCollapse: 'separate'}}>
        <StyledTableHead>
          <TableRow>
            <StyaledTableHeadCell />
            <TableCell sx={cellStyles}>
              <StyledTypography>型式</StyledTypography>
            </TableCell>
            <TableCell sx={cellStyles}>
              <StyledTypography>機種名</StyledTypography>
            </TableCell>
            <TableCell sx={cellStyles}>
              <StyledTypography>メーカー</StyledTypography>
            </TableCell>
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {rows.map((row) => (
            <ProductTableRow
              key={row.hashID}
              row={row}
              selectedHashId={SelectHashId}
              handleRadioChange={handleRadioChange}
            />
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

type TableRowProps = {
  row: {
    hashID: string;
    name: string;
    displayName: string;
    makerName: string;
  };
  selectedHashId?: string; // 選択された hashID を受け取る
  handleRadioChange: (hashId: string) => void; // ラジオボタンの変更イベントハンドラ
};

const ProductTableRow = ({row, selectedHashId, handleRadioChange}: TableRowProps) => {
  return (
    <TableRow key={row.hashID}>
      <TableCell sx={cellStyles}>
        <Radio
          checked={selectedHashId === row.hashID}
          onChange={() => handleRadioChange(row.hashID)}
          value={row.hashID}
          color="primary"
          name="WholeProductHashId"
        />
      </TableCell>
      <TableCell sx={cellStyles}>{row.name}</TableCell>
      <TableCell sx={cellStyles}>{row.displayName}</TableCell>
      <TableCell sx={cellStyles}>{row.makerName}</TableCell>
    </TableRow>
  );
};
