import {InspectionType} from '@modules/inspections/enum';
import {v4 as uuidv4} from 'uuid';
import {initialState} from './const';
import {CreateWholeProductPlan} from '../type';

export const ADD_ACTION = 'ADD';
export const DELETE_ACTION = 'DELETE';
export const SAVE_ACTION = 'SAVE';
export const SET_INITIAL_DATA_ACTION = 'SET_INITIAL_DATA';
export const CHANGE_INSPECTION_ACTION = 'CHANGE_INSPECTION';
export const CHANGE_INSPECTION_SETTING_NAME_ACTION = 'CHANGE_INSPECTION_SETTING_NAME';
export const CHANGE_INSPECTION_TYPE_ACTION = 'CHANGE_INSPECTION_TYPE';
export const CHANGE_INSPECTION_PERIOD_ACTION = 'CHANGE_INSPECTION_PERIOD';

export type CreateWholeProductPlanAction =
  | {type: typeof ADD_ACTION}
  | {type: typeof DELETE_ACTION; uuid: string}
  | {type: typeof SAVE_ACTION; uuid: string}
  | {type: typeof SET_INITIAL_DATA_ACTION; payload: CreateWholeProductPlan[]}
  | {type: typeof CHANGE_INSPECTION_ACTION; uuid: string; inspectionHashId: string; inspectionName: string}
  | {type: typeof CHANGE_INSPECTION_SETTING_NAME_ACTION; uuid: string; inspectionSettingName: string}
  | {type: typeof CHANGE_INSPECTION_TYPE_ACTION; uuid: string; inspectionType: InspectionType}
  | {type: typeof CHANGE_INSPECTION_PERIOD_ACTION; uuid: string; inspectionPeriod: number};

// reducer関数を定義
export const reducerFunc = (
  state: CreateWholeProductPlan[],
  action: CreateWholeProductPlanAction
): CreateWholeProductPlan[] => {
  switch (action.type) {
    case ADD_ACTION:
      return addProductPlan(state);
    case DELETE_ACTION:
      return deleteProductPlan(state, action.uuid);
    case SAVE_ACTION:
      return saveProductPlan(state);
    case SET_INITIAL_DATA_ACTION:
      return setAllProductPlans(action.payload);
    case CHANGE_INSPECTION_ACTION:
      return changeInspection(state, action.uuid, action.inspectionHashId, action.inspectionName);
    case CHANGE_INSPECTION_SETTING_NAME_ACTION:
      return changeInspectionSettingName(state, action.uuid, action.inspectionSettingName);
    case CHANGE_INSPECTION_TYPE_ACTION:
      return changeInspectionType(state, action.uuid, action.inspectionType);
    case CHANGE_INSPECTION_PERIOD_ACTION:
      return changeInspectionPeriod(state, action.uuid, action.inspectionPeriod);
    default:
      return state;
  }
};

const addProductPlan = (plans: CreateWholeProductPlan[]): CreateWholeProductPlan[] => {
  const uuid = uuidv4();
  const plan: CreateWholeProductPlan = {...initialState, uuid};
  const newPlans = [...plans, {...plan}];
  return newPlans;
};

const deleteProductPlan = (state: CreateWholeProductPlan[], uuid: string): CreateWholeProductPlan[] => {
  return state.filter((item) => item.uuid !== uuid);
};

const saveProductPlan = (state: CreateWholeProductPlan[]): CreateWholeProductPlan[] => {
  return state;
};

const setAllProductPlans = (payload: CreateWholeProductPlan[]): CreateWholeProductPlan[] => {
  return payload;
};

const changeInspection = (
  state: CreateWholeProductPlan[],
  uuid: string,
  inspectionHashId: string,
  inspectionName: string
): CreateWholeProductPlan[] => {
  return state.map((item) => (item.uuid === uuid ? {...item, inspectionHashId, inspectionName} : item));
};

const changeInspectionSettingName = (
  state: CreateWholeProductPlan[],
  uuid: string,
  inspectionSettingName: string
): CreateWholeProductPlan[] => {
  return state.map((item) => (item.uuid === uuid ? {...item, inspectionSettingName} : item));
};

const changeInspectionType = (
  state: CreateWholeProductPlan[],
  uuid: string,
  inspectionType: InspectionType
): CreateWholeProductPlan[] => {
  return state.map((item) =>
    item.uuid === uuid ? {...item, inspectionType, inspectionPeriod: 0, inspectionHashId: '', inspectionName: ''} : item
  );
};

const changeInspectionPeriod = (
  state: CreateWholeProductPlan[],
  uuid: string,
  inspectionPeriod: number
): CreateWholeProductPlan[] => {
  return state.map((item) => (item.uuid === uuid ? {...item, inspectionPeriod} : item));
};
