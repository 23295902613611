import {SxProps, Theme} from '@mui/material/styles';
import {Divider, Grid, styled, Typography} from '@mui/material';
import {Form} from 'formik';

// 定数の定義
export const sideNavHeight = '100%';
export const sideNavWidth = 356;
export const sideBarWidth = 256;

type get2ColumnsLayoutTemplateKey = 'root' | 'sideNav' | 'content';
/** 2カラムレイアウトのスタイル */
export const get2ColumnsLayoutTemplate: Record<get2ColumnsLayoutTemplateKey, SxProps<Theme>> = {
  root: {
    height: '100%',
    overflowY: 'hidden',
  },
  sideNav: {
    width: sideNavWidth,
    height: `calc(100vh - 56px)`,
    overflowY: 'auto',
  },
  content: {
    width: `calc(100% - ${sideNavWidth}px)`,
    height: `calc(100vh - 56px)`,
    overflowY: 'auto',
  },
};
type get3ColumnsLayoutTemplateKey = 'root' | 'leftSideNav' | 'rightSideNav' | 'content';
/** 3カラムレイアウトのスタイル */
export const get3ColumnsLayoutTemplate: Record<get3ColumnsLayoutTemplateKey, SxProps<Theme>> = {
  root: {
    height: '100%',
    overflowY: 'hidden',
  },
  leftSideNav: {
    width: sideNavWidth,
    height: `calc(100vh - 56px)`,
    overflowY: 'auto',
  },
  rightSideNav: {
    width: 0,
    height: `calc(100vh - 56px)`,
    overflowY: 'auto',
    '@media (min-width: 1280px)': {
      width: sideNavWidth,
    },
  },
  content: {
    width: `calc(100% - ${sideNavWidth}px)`,
    height: `calc(100vh - 56px)`,
    overflowY: 'auto',
    '@media (min-width: 1280px)': {
      width: `calc(100% - ${sideNavWidth}px - ${sideNavWidth}px)`,
    },
  },
};

type SettingsContentTemplateKeys =
  | 'pageTitle'
  | 'grid'
  | 'sideBar'
  | 'content'
  | 'flex'
  | 'form'
  | 'pageSubTitle1'
  | 'pageSubTitle2'
  | 'divider'
  | 'link';

/** 設定コンテンツのテンプレートスタイル */
export const getSettingsContentTemplate: Record<SettingsContentTemplateKeys, SxProps<Theme>> = {
  pageTitle: {
    fontWeight: 'bold',
  },
  grid: {
    height: '100%',
    overflowY: 'visible',
  },
  sideBar: {
    width: sideBarWidth,
    height: `calc(100vh - 56px)`,
    overflowY: 'auto',
  },
  content: {
    width: `calc(100% - ${sideBarWidth}px)`,
    height: `calc(100vh - 56px)`,
    overflowY: 'visible',
    padding: '48px 40px',
    '& > :last-child': {
      paddingBottom: '75px',
    },
  },
  flex: {
    flexGrow: 1,
  },
  form: {
    backgroundColor: (theme: Theme) => theme.palette.common.white,
    width: '100%',
    overflowY: 'visible',
  },
  pageSubTitle1: {
    fontWeight: 'bold',
    marginTop: (theme: Theme) => theme.spacing(2),
  },
  pageSubTitle2: {
    fontWeight: 'bold',
    color: (theme: Theme) => theme.palette.secondary.dark,
  },
  divider: {
    margin: '16px 0',
    width: '100%',
  },
  link: {
    color: (theme: Theme) => theme.palette.secondary.dark,
  },
};

/**
 * 以下はuseSettingsContentTemplateをStyledに変換したもの
 */

export const StyledSettingsTempForm = styled(Form)(({theme}: {theme: Theme}) => ({
  backgroundColor: theme.palette.common.white,
  overflowY: 'visible',
  width: `calc(100% - ${sideBarWidth}px)`,
  height: `calc(100% - 0px)`,
}));

export const StyledSettingsTempPageTitle = styled(Typography)({
  fontWeight: 'bold',
});

export const StyledSettingsTempSideBar = styled(Grid)({
  width: `${sideBarWidth}px`,
  height: `calc(100vh - 56px)`,
  overflowY: 'auto',
});

export const StyledSettingsTempContent = styled(Grid)({
  padding: '48px 40px',
  '& > :last-child': {
    paddingBottom: '75px',
  },
});

export const StyledSettingsTempFlex = styled('div')({
  flexGrow: 1,
});

export const StyledSettingsTempPageSubTitle1 = styled(Typography)(({theme}: {theme: Theme}) => ({
  fontWeight: 'bold',
  marginTop: theme.spacing(2),
}));

export const StyledSettingsTempPageSubTitle2 = styled('div')(({theme}: {theme: Theme}) => ({
  fontWeight: 'bold',
  color: theme.palette.secondary.dark,
}));

export const StyledSettingsTempDivider = styled(Divider)({
  margin: '16px 0',
  width: '100%',
});

export const StyledSettingsTempLink = styled('a')(({theme}: {theme: Theme}) => ({
  color: theme.palette.secondary.dark,
}));
