import React, {memo, useCallback, useMemo} from 'react';
import {createStyles, Paper, Grid, Tabs, Tab, Button, makeStyles, Theme, Box, Typography} from '@material-ui/core';
import {useAtom} from 'jotai';
import {FastField, FieldInputProps, FieldMetaProps, FormikProps} from 'formik';
import {tabAtom} from '@Apps/InspectionResult/pc/EditInspectionResult/states';
import {tabs, TabKey} from '@Apps/InspectionResult/common/types';
import {InspectionIndex, InspectionTableIndex} from '@modules/inspections/types';
import {HospitalProductIndex} from '@modules/hospital_products/types';
import {FormValue} from '../../../common/types';
import {getEmptyAnswers} from '../../../common/validator';
import {SubmitButton} from './SubmitButton';
import {useSubmitDispatcher} from '../contexts';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: '8px 0px 0px',
      zIndex: theme.zIndex.drawer + 2,
      backgroundColor: theme.palette.common.white,
      position: 'sticky',
      top: 0,
    },
    topBar: {
      padding: '8px 24px 0px 72px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    name: {
      fontSize: '16px',
      color: theme.palette.grey[700],
    },
    productName: {
      color: theme.palette.grey[600],
    },
    managementNumber: {
      color: theme.palette.grey[600],
    },
    saveButtonContainer: {
      marginLeft: '8px',
      display: 'flex',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
  })
);

type HeaderFieldProps = {
  field: FieldInputProps<FormValue>;
  meta: FieldMetaProps<FormValue>;
  form: FormikProps<FormValue>;
};

type HeaderInnerProps = {
  inspection: InspectionIndex;
  inspectionTable: InspectionTableIndex;
  hospitalProduct: HospitalProductIndex | null;
  onSaveAsDraft: (e: React.MouseEvent, values: FormValue) => Promise<void>;
  onDiscardChanges: () => Promise<void>;
};

export const HeaderInner: React.FC<HeaderFieldProps & HeaderInnerProps> = memo((props) => {
  const {inspection, inspectionTable, hospitalProduct, form, onSaveAsDraft, onDiscardChanges} = props;
  const onSubmit = useSubmitDispatcher();

  const classes = useStyles();
  const [tab, setTab] = useAtom(tabAtom);

  const handleChangeTab = useCallback(
    (_, key: string) => {
      setTab(key as TabKey);
    },
    [setTab]
  );

  const handleClickSave = useCallback(
    (e: React.MouseEvent) => {
      onSaveAsDraft(e, form.values);
    },
    [form.values, onSaveAsDraft]
  );

  const handleClickDiscard = useCallback(() => {
    onDiscardChanges();
  }, [onDiscardChanges]);

  const handleClickComplete = useCallback(
    (e: React.MouseEvent) => {
      onSubmit(e, form.values);
    },
    [form.values, onSubmit]
  );

  const emptyAnswers = useMemo(() => {
    form.validateForm();
    return getEmptyAnswers(form.errors, inspectionTable.items);
  }, [form, inspectionTable.items]);

  const saveButtonDisabled = form.isSubmitting;
  const submitButtonDisabled = useMemo(
    () => emptyAnswers.length > 0 || form.isSubmitting,
    [emptyAnswers, form.isSubmitting]
  );

  return (
    <Paper elevation={2} square className={classes.root}>
      <Grid container direction="column">
        <Grid container className={classes.topBar} direction="row">
          <Grid item xs={3} sm={4}>
            <Typography variant={'body2'}>管理番号: {hospitalProduct?.managementId ?? ''}</Typography>
            <Typography variant={'body2'}>型式: {hospitalProduct?.name ?? ''}</Typography>
          </Grid>
          <Grid item direction="column">
            <Box className={classes.name}>{inspection.name}</Box>
          </Grid>
          <Grid item xs={3} sm={4} className={classes.saveButtonContainer}>
            <Button variant="outlined" color="primary" onClick={handleClickDiscard} style={{marginRight: '16px'}}>
              保存せずに終了
            </Button>
            <Button
              variant="outlined"
              color="primary"
              disabled={saveButtonDisabled}
              onClick={handleClickSave}
              style={{marginRight: '16px'}}>
              途中保存して終了
            </Button>
            <Grid>
              <SubmitButton
                disabled={submitButtonDisabled}
                showsBadge={emptyAnswers.length > 0}
                emptyAnswers={emptyAnswers}
                onClick={handleClickComplete}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Tabs value={tab} onChange={handleChangeTab} indicatorColor="primary" textColor="primary">
            {tabs.map((item) => (
              <Tab key={item.value} label={item.label} value={item.value} />
            ))}
          </Tabs>
        </Grid>
      </Grid>
    </Paper>
  );
});

export const Header: React.FC<HeaderInnerProps> = memo((props) => {
  return (
    <FastField name="">
      {({field, form, meta}: HeaderFieldProps) => <HeaderInner {...props} field={field} meta={meta} form={form} />}
    </FastField>
  );
});
