import React from 'react';
import {List} from '@mui/material';
import {SelectTypeOptionCreateListItem} from './SelectTypeOptionCreateListItem';

type Props = {
  options: string[];
  onChange: (index: number, newOption: string) => void;
  onRemove: (index: number) => void;
};

export const SelectTypeOptionCreateList = ({options, onChange, onRemove}: Props) => {
  const removeFieldState = options.length > 1 ? 'visible' : 'disabled';
  const handleChange = (index: number, newOption: string) => {
    onChange(index, newOption);
  };

  const handleRemove = (index: number) => {
    onRemove(index);
  };

  return (
    <List sx={{'& > li:nth-of-type(n+2)': {marginTop: '16px'}}}>
      {options.map((option, i) => (
        <SelectTypeOptionCreateListItem
          key={`select-type-option-${i}`}
          index={i}
          option={option}
          removeFieldState={removeFieldState}
          onChange={handleChange}
          onRemove={handleRemove}
        />
      ))}
    </List>
  );
};
