import React, {useMemo} from 'react';
import {Grid, Button, Typography, InputAdornment, OutlinedInput, styled} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import {InspectionType, InspectionTypeMap} from '@modules/inspections/enum';
import {SubmitButton} from './SubmitButton';
import {theme} from '@atoms/theme';
import {InspectionStatus} from '@molecules/InspectionStatus';
import {FormError, FormValue} from '../types';
import {FormikErrors} from 'formik';

const Container = styled(Grid)({
  width: '100%',
  backgroundColor: theme.palette.common.white,
  position: 'sticky',
  top: 0,
  zIndex: theme.zIndex.appBar,
  padding: '24px',
  borderRadius: 0,
});

const Text = styled(Typography)({
  fontSize: '14px',
});

const NameInputContainer = styled(Grid)({
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const NameInput = styled(OutlinedInput)({
  minWidth: '320px',
  align: 'center',
  textAlign: 'center',
  '& input': {
    textAlign: 'center',
    padding: '12px 14px',
  },
  [theme.breakpoints.up('tabletH')]: {
    minWidth: '400px',
  },
});

const RightContainer = styled(Grid)({
  minWidth: '200px',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  flexWrap: 'nowrap',
  gap: '16px',
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
  },
});

export type HeaderInnerProps = {
  inspectionType: InspectionType;
  status: 'available' | 'draft';
  inspectionName: string;
  errors: FormikErrors<FormValue>;
  errorSummaries: FormError[];
  isValid: boolean;
  isSubmitting: boolean;
  onChangeName: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocusName: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlurName: (e: React.FocusEvent) => void;
  onSaveDraft: () => void;
  onClickPublish: () => void;
  onFinishWithoutToSave: (e: React.MouseEvent) => void;
  onItemClick: (sectionIndex: number, fieldIndex: number | null) => void;
};

export const HeaderInner = (props: HeaderInnerProps) => {
  const {
    inspectionType,
    status,
    inspectionName,
    errors,
    errorSummaries,
    isValid,
    isSubmitting,
    onChangeName,
    onFocusName,
    onBlurName,
    onSaveDraft,
    onClickPublish,
    onFinishWithoutToSave,
    onItemClick,
  } = props;

  const isDisable: boolean = useMemo(() => {
    return status === 'draft' ? !isValid || isSubmitting : !isValid;
  }, [status, isValid, isSubmitting]);

  return (
    <Container container>
      <Grid item xs={3}>
        <Text display="block">点検タイプ：{InspectionTypeMap[inspectionType]?.label ?? ''}</Text>
        <Text display="block">
          ステータス：
          <InspectionStatus status={status} />
        </Text>
      </Grid>
      <NameInputContainer item xs container>
        <NameInput
          error={!!errors.name}
          placeholder="点検表名"
          value={inspectionName}
          onFocus={onFocusName}
          onChange={onChangeName}
          onBlur={onBlurName}
          endAdornment={
            <InputAdornment position="end">
              <EditIcon style={{fontSize: '18px'}} />
            </InputAdornment>
          }
        />
      </NameInputContainer>
      <RightContainer item xs={3} container>
        <Grid item>
          <Button variant="outlined" color="primary" onClick={onFinishWithoutToSave}>
            {status === 'draft' ? '終了' : '保存せず終了'}
          </Button>
        </Grid>
        {status === 'draft' && (
          <Grid item>
            <Button variant="outlined" color="primary" onClick={onSaveDraft}>
              下書きを保存
            </Button>
          </Grid>
        )}
        <Grid item>
          <SubmitButton
            showsBadge={errorSummaries.length > 0}
            badgeCount={errorSummaries.length}
            status={status}
            disabled={isDisable}
            errors={errorSummaries}
            onClick={onClickPublish}
            onItemClick={onItemClick}
          />
        </Grid>
      </RightContainer>
    </Container>
  );
};
