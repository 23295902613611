import React, {useState} from 'react';
import {styled, Box, Button, DialogActions, DialogTitle, TextField} from '@mui/material';
import {UserIndex} from '@modules/hospital_users/types';
import {InspectionResultIndex} from '@modules/inspection_results/types';
import {RequiredLabel} from '@molecules/FormRequiredLabel';
import {convertDateToSimpleDate} from '@front-libs/helpers';
import {openSnackBar} from '@components/molecules/SnackBar';
import {InspectionSummary, buildInspectionSummaryRows} from '../InspectionSummary';
import {UserSelector} from './UserSelector';

type Props = {
  inspectionResult: InspectionResultIndex;
  hospitalUsers: UserIndex[];
  defaultSkippedByHashId: string;
  onBack: () => void;
  onSubmit: (skippedTime: string, selectedSkippedByHashId: string, skipReason: string) => Promise<void>;
};

const Description = styled(Box)({
  marginTop: '32px',
});

const InputForm = styled(Box)({
  marginTop: '24px',
  '& > div:nth-of-type(n+2)': {
    marginTop: '24px',
  },
});

const Actions = styled(DialogActions)({
  marginTop: '40px',
  '& > button:nth-of-type(n+2)': {
    marginLeft: '16px',
  },
});

export const InspectionSkip = ({inspectionResult, hospitalUsers, defaultSkippedByHashId, onBack, onSubmit}: Props) => {
  const todayText = convertDateToSimpleDate(new Date());
  const [skippedTime, setSkippedTime] = useState<string>(todayText);
  const [selectedSkippedByHashId, setSelectedSkippedByHashId] = useState<string>(defaultSkippedByHashId);
  const [skipReason, setSkipReason] = useState<string>('');
  const [skipped, setSkipped] = useState<boolean>(false);
  const canSubmit = !!skippedTime && !!selectedSkippedByHashId;

  const resetInputValues = () => {
    setSkippedTime(todayText);
    setSelectedSkippedByHashId(defaultSkippedByHashId);
    setSkipReason('');
    setSkipped(false);
  };

  const handleChangeSkippedTime = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSkippedTime(event.target.value);
  };
  const handleSelectSkippedByUser = (hashId?: string) => {
    if (!hashId) return;

    setSelectedSkippedByHashId(hashId);
  };
  const handleChangeSkipReason = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSkipReason(event.target.value);
  };

  const handleBack = () => {
    resetInputValues();
    onBack();
  };

  const handleSubmit = async () => {
    if (!canSubmit) return;

    try {
      await onSubmit(skippedTime, selectedSkippedByHashId, skipReason);
    } catch (error) {
      console.error(error);
      openSnackBar('点検のスキップに失敗しました', 'left', 'bottom', 'error');
      return;
    }

    setSkipped(true);
  };

  if (skipped) {
    return <DialogTitle sx={{padding: 0, minWidth: '586px', fontWeight: 700}}>点検をスキップしました</DialogTitle>;
  }

  return (
    <>
      <DialogTitle sx={{padding: 0, minWidth: '586px', fontWeight: 700}}>点検をスキップ</DialogTitle>
      <Description>
        <InspectionSummary data={buildInspectionSummaryRows(inspectionResult)} />
      </Description>
      <InputForm>
        <Box>
          <RequiredLabel>スキップをした日</RequiredLabel>
          <TextField
            type="date"
            variant="outlined"
            size="small"
            fullWidth
            value={skippedTime}
            onChange={handleChangeSkippedTime}
          />
        </Box>
        <Box>
          <RequiredLabel>スキップ実施者</RequiredLabel>
          <UserSelector
            placeholder="スキップ実施者"
            users={hospitalUsers}
            selectedHashId={selectedSkippedByHashId}
            onSelect={handleSelectSkippedByUser}
          />
        </Box>
        <Box>
          <label>スキップ理由</label>
          <TextField
            type="text"
            variant="outlined"
            size="small"
            fullWidth
            value={skipReason}
            onChange={handleChangeSkipReason}
          />
        </Box>
      </InputForm>
      <Actions>
        <Button variant="text" sx={{color: '#0052CC'}} onClick={handleBack}>
          前に戻る
        </Button>
        <Button
          variant="contained"
          sx={{backgroundColor: '#0052CC', '&.Mui-disabled': {color: '#FFFFFF'}}}
          disabled={!canSubmit}
          onClick={handleSubmit}>
          スキップ
        </Button>
      </Actions>
    </>
  );
};
