import {DependencyList, useEffect, useState} from 'react';

export const useAsyncMemo = <T>(factory: () => Promise<T>, initialValue: T, deps: DependencyList): T => {
  const [value, setValue] = useState<T>(initialValue);
  useEffect(() => {
    factory().then((res) => setValue(res));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
  return value;
};
