import {Selector} from '@molecules/Selector';
import {TextField} from '@mui/material';
import {classNameOpts} from '../../ProductRegistration/constants';
import {Column} from './EditableColumnModal';
import React from 'react';

const DefaultColumnProps = {
  variant: 'outlined',
  style: {width: '100%'},
};
const TextColumnProps = {
  ...DefaultColumnProps,
  type: 'string',
};
const NumberColumnProps = {
  ...DefaultColumnProps,
  type: 'number',
};

/** 複数選択可能な項目名リスト */
export const MultipleEditableColumnNames = [
  'narrowCategoryHashId', //大分類・小分類
  'makerHashId', //メーカー名
  'jmdnCode', //JMDNコード
  'isSpecificMaintain', // 特定保守製品
  'className', //クラス分類
  'catalogPrice', //定価
];

export const EditableColumnList: Column[] = [
  {
    type: 'text',
    label: '大分類・小分類',
    name: 'narrowCategoryHashId',
    InputComponent: TextField,
    props: {},
  },
  {
    type: 'text',
    label: 'メーカー名',
    name: 'makerHashId',
    InputComponent: TextField,
  },
  {
    type: 'text',
    label: '機種名',
    name: 'displayName',
    InputComponent: TextField,
    props: TextColumnProps,
  },
  {
    type: 'text',
    label: '型式',
    name: 'name',
    InputComponent: TextField,
    props: TextColumnProps,
  },
  {
    type: 'text',
    label: '承認番号',
    name: 'approvalNumber',
    InputComponent: TextField,
    props: TextColumnProps,
  },
  {
    type: 'number',
    label: 'JMDNコード',
    name: 'jmdnCode',
    InputComponent: TextField,
    props: NumberColumnProps,
  },
  {
    // TODO numberで送るように変更 (api側のdtoの変更対応も合わせて必要)
    type: 'text',
    label: 'JANコード',
    name: 'janCode',
    InputComponent: TextField,
    props: NumberColumnProps,
  },
  {
    type: 'select',
    label: '特定保守製品',
    name: 'isSpecificMaintain',
    InputComponent: Selector,
    props: {
      options: [
        {label: '該当', value: true},
        {label: '非該当', value: false},
      ],
    },
  },
  {
    type: 'select',
    label: 'クラス分類',
    name: 'className',
    InputComponent: Selector,
    props: {
      options: classNameOpts,
    },
  },
  {
    // TODO numberで送るように変更 (api側のdto変更対応も合わせて必要)
    type: 'text',
    label: '定価',
    name: 'catalogPrice',
    InputComponent: TextField,
    props: NumberColumnProps,
  },
];
