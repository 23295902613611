import React, {useState} from 'react';
import {Box, Typography} from '@mui/material';
import {ProductTabs, ProductTabType} from './ui/ProductTabs';
import {useParams} from 'react-router-dom';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {PmdaContents} from './ui/PmdaContents';
import TrainingSchedules from './ui/TrainingSchedules';

/**
 * 機種詳細ページの右カラム
 */
export const WholeProductInfo = () => {
  const [selectedTab, setSelectedTab] = useState<ProductTabType>('pmdaFile');
  const {hashId} = useParams();
  const {myInfo} = useMyInfo();

  const TabContents: Record<ProductTabType, JSX.Element> = {
    pmdaFile: <PmdaContents hospitalHashId={myInfo.hospitalHashId} wholeProductHashId={hashId ?? ''} />,
    file: <Typography>ファイル</Typography>, // FIXME: ファイルは未実装
    trainingSchedule: <TrainingSchedules wholeProductHashId={hashId ?? ''} />,
  };

  return (
    <Box>
      <ProductTabs
        onChangeTab={(tabId) => {
          setSelectedTab(tabId);
        }}
        defaultTab={selectedTab}
      />
      {TabContents[selectedTab]}
    </Box>
  );
};
