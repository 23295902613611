import React from 'react';
import {NormalCard} from './Cards/NormalCard';
import {CommentCard} from './Cards/CommentCard';
import {UserIndex} from '@modules/hospital_users/types';

interface InnerItem {
  logHashId: string;
  user?: UserIndex;
  cardType: string;
  title?: string | JSX.Element;
  icon?: JSX.Element;
  displayDate: Date;
  contentSlot?: {label: string; value: string}[];
  pinned?: boolean;
  optionalItem?: JSX.Element | null;
  description?: string;
  createdAt?: string;
  updatedAt?: string;
  isArchived?: boolean;
  archivedBy?: UserIndex;
}

interface CardRendererProps {
  innerItem: InnerItem;
  isReadOnly: boolean;
  isAdmin: boolean;
  /**
   * コメントが更新された時に呼び出される関数
   * @param comment - 更新されたコメント。
   */
  onUpdateComment: (comment: string) => void;
  /**
   * ピンどめが更新された時に呼び出される関数
   */
  onUpdatePinned: () => void;

  /**
   * コメントが削除された時に呼び出される関数
   */
  onDeleteComment: () => void;
}

export const InnerCardItem: React.FC<CardRendererProps> = ({
  innerItem,
  isReadOnly,
  isAdmin,
  onDeleteComment,
  onUpdateComment,
  onUpdatePinned,
}) => {
  switch (innerItem.cardType) {
    case 'status_change':
    case 'fault_reception':
    case 'fault_reception_comment':
    case 'registration':
      return (
        <NormalCard
          title={innerItem.title}
          icon={innerItem.icon}
          displayDate={innerItem.displayDate}
          contentSlot={innerItem.contentSlot}
          optionalItem={innerItem.optionalItem}
        />
      );
    case 'comment':
      return (
        <CommentCard
          pinned={innerItem.pinned}
          displayDate={innerItem.displayDate}
          description={innerItem.description}
          isReadOnly={isReadOnly}
          user={innerItem.user}
          isArchived={innerItem.isArchived}
          archivedBy={innerItem.archivedBy}
          updatedAt={innerItem.updatedAt}
          isAdmin={isAdmin}
          onUpdateComment={onUpdateComment}
          onUpdatePinned={onUpdatePinned}
          onDeleteComment={onDeleteComment}
        />
      );
    default:
      return null;
  }
};
