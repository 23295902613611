import React, {useCallback, useEffect, useMemo} from 'react';
import {FetchChannelsParams} from '@modules/channels/types';
import {useFetchChannelsInfo} from '@modules/channels/api';
import {BandTable, ChannelType} from '@Apps/Channel/BandTable';
import {createStyles, Grid, makeStyles, Theme, Typography} from '@material-ui/core';
import {maxBandNumber, minBandNumber, ZoneIdType} from '@Apps/Channel/consts';
import {isNullish} from '@front-libs/helpers';
import {UpdateChannelType, useUpdateChannelSubject} from '@Apps/Channel/hooks';

export const ZoneTable: React.FC<{zoneId: string; hospitalHashId: string; hospitalWardHashIds: string[]}> = ({
  zoneId,
  hospitalHashId,
  hospitalWardHashIds,
}) => {
  const classes = useStyles();
  const {subscribe, unsubscribe} = useUpdateChannelSubject();
  const params: FetchChannelsParams = {
    page: 0,
    perPage: 60,
    zoneId: zoneId,
  };
  const channelsQuery = useFetchChannelsInfo(hospitalHashId, params);

  const onUpdateChannelSubject = useCallback(
    async (t: UpdateChannelType) => {
      if (t !== 'all' && !t.includes(zoneId as ZoneIdType)) return;
      await channelsQuery.refetch();
      return;
    },
    [channelsQuery, zoneId]
  );

  useEffect(() => {
    subscribe(onUpdateChannelSubject);
    return () => unsubscribe(onUpdateChannelSubject);
  }, [onUpdateChannelSubject, subscribe, unsubscribe]);

  const zoneRows = useMemo(() => {
    const rows: ChannelType[][] = [];
    for (let i = maxBandNumber; i >= minBandNumber; i--) {
      const bandRows: ChannelType[] = [];
      channelsQuery.channels.forEach((ch) => {
        if (Math.floor(ch.channelNumber / 1000) === i) {
          const place = isNullish(ch.isGroundFloor)
            ? ''
            : `${ch.hospitalWardName}${ch.isGroundFloor ? ' ' : ' 地下'}${ch.floorNumber}階`;
          const bandRow: ChannelType = {
            channelNumber: ch.channelNumber,
            channelHashId: ch.channelHashId,
            productName: ch?.productName ?? '',
            placeName: place,
            placeHashId: ch.hospitalWardHashId,
            hospitalProduct: ch.hospitalProduct,
            wholeProduct: ch.wholeProduct,
          };
          bandRows.push(bandRow);
        }
      });
      rows[i] = bandRows;
    }
    return rows;
  }, [channelsQuery]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const band = (bandNumber: number) => {
    return (
      <Grid key={bandNumber} item className={classes.bandItem}>
        <Grid container style={{flexGrow: 1}}>
          <Grid item>
            <Typography className={classes.bandTitle}>バンド{bandNumber}</Typography>
          </Grid>
          <div className={classes.flex} />
          <Grid item>
            <Typography className={classes.bandTitle}>
              {zoneRows[bandNumber].filter((c) => c.productName).length}/{zoneRows[bandNumber].length}
            </Typography>
          </Grid>
        </Grid>
        <BandTable rows={zoneRows[bandNumber]} hospitalWardHashIds={hospitalWardHashIds} zoneId={zoneId} />
      </Grid>
    );
  };

  const bands = useMemo(() => {
    return zoneRows
      .map((row, index) => {
        if (row.length !== 0) return band(index);
        return null;
      })
      .reverse();
  }, [zoneRows, band]);

  const isActive =
    !channelsQuery.isLoading &&
    (hospitalWardHashIds.length === 0 || channelsQuery.channels.filter((c) => c.productName).length !== 0);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isActive && (
        <Grid container>
          <Grid container>
            <Typography className={classes.zoneTitle}>
              <span style={{marginRight: '32px'}}>ゾーン{zoneId === '11' ? '配置外' : zoneId}</span>
              <span style={{fontWeight: 'bold'}}>
                {channelsQuery.channels.filter((c) => c.productName).length}/{channelsQuery.channels.length}
              </span>
            </Typography>
          </Grid>
          <Grid className={classes.cardContainer}>{bands}</Grid>
        </Grid>
      )}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bandItem: {
      display: 'inline-block',
      width: '400px',
      marginRight: '16px',
      height: '270px',
    },
    bandTitle: {
      paddingBottom: '8px',
      fontSize: '14px',
    },
    zoneTitle: {
      fontSize: '14px',
      marginTop: '0px',
      marginBottom: '8px',
      marginLeft: '16px',
    },
    flex: {
      flexGrow: 1,
    },
    cardContainer: {
      paddingLeft: '18px',
      paddingBottom: '18px',
      paddingRight: '2px',
      paddingTop: '12px',
      backgroundColor: '#F6F8FA',
      overflowX: 'scroll',
      display: 'inline-block',
      whiteSpace: 'nowrap',
      marginBottom: '36px',
    },
  })
);
