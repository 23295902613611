import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {
  CostCategoryType,
  useFetchCostTrendsByCostCategory,
  useFetchCostTrendsByNarrowCategory,
  useFetchCostTrendsByRootCategory,
} from '@modules/hospital_product_costs';
import {useDisplayTimePeriod, usePaymentDateRange, usePaymentDateTo, useSelectedRootCategoryHashId} from './states';
import dayjs, {Dayjs} from 'dayjs';
import {costCategory} from './constants';
import {useHospitalFiscalYear} from '@modules/hospital/hooks';
import {useGetHospitalCategories, useGetHospitalDescendantCategories} from '@modules/categories/api';
import {colorList} from './ChartByRootCategory/constants';
import {useOnlyOnce} from '@front-libs/core';
import {useRootCategories} from '../components/hooks';

export const useCostTrendsByCostCategoryQuery = () => {
  const [paymentDateTo] = usePaymentDateRange();
  const [displayTimePeriod] = useDisplayTimePeriod();
  const {myInfo} = useMyInfo();
  const {data: costTrendsByCostCategoryData, ...res} = useFetchCostTrendsByCostCategory(myInfo.hospitalHashId, {
    displayTimePeriod: displayTimePeriod,
    paymentDateTo: paymentDateTo,
  });
  return {...costTrendsByCostCategoryData, ...res};
};

export const useCostTrendsByRootCategoryQuery = () => {
  const [paymentDateTo] = usePaymentDateRange();
  const [displayTimePeriod] = useDisplayTimePeriod();
  const {myInfo} = useMyInfo();
  const {data: costTrendsByRootCategoryData, ...res} = useFetchCostTrendsByRootCategory(myInfo.hospitalHashId, {
    displayTimePeriod: displayTimePeriod,
    paymentDateTo: paymentDateTo,
  });
  return {...costTrendsByRootCategoryData, ...res};
};

export const useCostTrendsByNarrowCategoryQuery = () => {
  const {query} = useRootCategories();

  const [paymentDateTo] = usePaymentDateRange();
  const [displayTimePeriod] = useDisplayTimePeriod();
  const [rootCategoryHashId, setRootCategoryHashId] = useSelectedRootCategoryHashId();
  const {myInfo} = useMyInfo();
  const {data: costTrendsByNarrowCategoryData, ...res} = useFetchCostTrendsByNarrowCategory(myInfo.hospitalHashId, {
    displayTimePeriod: displayTimePeriod,
    paymentDateTo: paymentDateTo,
    rootCategoryHashId: rootCategoryHashId ?? undefined,
  });

  useOnlyOnce(
    () => {
      setRootCategoryHashId(query.data[0].hashId);
    },
    query.isSuccess && query.data.length > 0
  );

  return {...costTrendsByNarrowCategoryData, ...res};
};

export const useXAxisCategories = () => {
  const {termStartMonth} = useHospitalFiscalYear();
  const [displayTimePeriod] = useDisplayTimePeriod();
  const [paymentDateTo] = usePaymentDateTo();

  if (displayTimePeriod === 'month') {
    // 期首(termStartMonth)から12ヶ月分の月を「YYYY-MM」の形式でstring配列にする
    const lastDate = dayjs(paymentDateTo).set('date', 1);
    return [...Array(12)]
      .map((_, i) => {
        const date = lastDate.add(-i, 'month');
        return date.format('YYYY-MM');
      })
      .reverse();
  } else if (displayTimePeriod === 'quarter') {
    // 期首(termStartMonth)から4クォーター分の月を「YYYY-Q1」の形式でstring配列にする
    const lastDate = dayjs(paymentDateTo).set('date', 1);
    return [...Array(4)]
      .map((_, i) => {
        const date = lastDate.add(-(i * 3), 'month');

        return calculateQuarter(date, termStartMonth);
      })
      .reverse();
  } else if (displayTimePeriod === 'year') {
    // 期首(termStartMonth)から4年分の月を「YYYY」の形式でstring配列にする
    const lastDate = dayjs(paymentDateTo)
      .set('month', termStartMonth - 1)
      .set('date', 1);
    return [...Array(4)]
      .map((_, i) => {
        const date = lastDate.add(-i, 'year');
        return date.format('YYYY');
      })
      .reverse();
  } else {
    throw new Error('unreachable');
  }
};

const calculateQuarter = (date: Dayjs, termStartMonth: number) => {
  let month = date.month() + 1;
  if (month < termStartMonth) {
    month = month + 12;
  }
  month = month - termStartMonth;
  const quarter = Math.ceil(month / 3);
  const year = date.year();
  return `${year} Q${quarter}`;
};

export const useCostTrendsByCostCategorySeries = () => {
  const {data} = useCostTrendsByCostCategoryQuery();
  const xAxisCategories = useXAxisCategories();
  const series: {name: string; color: string; data: number[]}[] = [];

  (Object.keys(costCategory) as CostCategoryType[]).forEach((key) => {
    const costCategoryData = data?.filter((item) => item.costCategory === key) ?? [];
    const seriesData = xAxisCategories.map((xAxis) => {
      const cost = costCategoryData.find((item) => item.timePeriod === xAxis)?.totalTransactionCost ?? 0;
      return cost !== 0 ? Math.round((cost / (1 * 1000 * 1000)) * 10) / 10 : 0;
    });

    series.push({
      ...costCategory[key],
      data: seriesData,
    });
  });

  return series;
};

export const useCostTrendsByRootCategorySeries = () => {
  const {data} = useCostTrendsByRootCategoryQuery();
  const xAxisCategories = useXAxisCategories();
  const {myInfo} = useMyInfo();
  const {data: rootCategories} = useGetHospitalCategories(myInfo.hospitalHashId, {depth: 0});
  const series: {name: string; color: string; data: number[]}[] = [];

  rootCategories.forEach((key, idx) => {
    const costCategoryData = data?.filter((item) => item.rootCategoryHashId === key.hashId) ?? [];
    const seriesData = xAxisCategories.map((xAxis) => {
      const cost = costCategoryData.find((item) => item.timePeriod === xAxis)?.totalTransactionCost ?? 0;
      return cost !== 0 ? Math.round((cost / (1 * 1000 * 1000)) * 10) / 10 : 0;
    });

    series.push({
      name: key.name,
      color: colorList[idx % colorList.length],
      data: seriesData,
    });
  });

  return series;
};

export const useCostTrendsByNarrowCategorySeries = () => {
  const {data} = useCostTrendsByNarrowCategoryQuery();
  const xAxisCategories = useXAxisCategories();
  const {myInfo} = useMyInfo();
  const [rootCategoryHashId] = useSelectedRootCategoryHashId();
  const {data: narrowCategories} = useGetHospitalDescendantCategories(
    myInfo.hospitalHashId,
    rootCategoryHashId ?? undefined
  );
  const series: {name: string; color: string; data: number[]}[] = [];

  narrowCategories.forEach((key, idx) => {
    const costCategoryData = data?.filter((item) => item.narrowCategoryHashId === key.hashId) ?? [];
    const seriesData = xAxisCategories.map((xAxis) => {
      const cost = costCategoryData.find((item) => item.timePeriod === xAxis)?.totalTransactionCost ?? 0;
      return cost !== 0 ? Math.round((cost / (1 * 1000 * 1000)) * 10) / 10 : 0;
    });

    series.push({
      name: key.name,
      color: colorList[idx % colorList.length],
      data: seriesData,
    });
  });

  return series;
};
