import {useMemo} from 'react';
import {useQueries, useQuery} from 'react-query';
import {FaultReceptionLogParams, FaultReceptionLogsResponse} from '../types';
import {getFaultReceptionLogsKey} from '@constants/api';
import {getFaultReceptionLogs} from '../api/faultReceptionLog';

export const useFetchFaultReceptionLogsQuery = (
  hospitalHashId: string,
  faultReceptionHashId: string,
  params?: FaultReceptionLogParams
) => {
  const query = useQuery(
    [getFaultReceptionLogsKey, hospitalHashId, faultReceptionHashId, params],
    () => getFaultReceptionLogs(hospitalHashId, faultReceptionHashId, params),
    {enabled: !!faultReceptionHashId, refetchOnWindowFocus: false}
  );
  return useMemo(
    () => ({
      ...query,
      data: query.data?.data ?? [],
      totalCount: query.data?.totalCount ?? 0,
    }),
    [query]
  );
};

export const useFetchFaultReceptionLogsListQuery = (
  hospitalHashId: string,
  faultReceptionHashIds: string[],
  params?: FaultReceptionLogParams
) => {
  const queries = useQueries(
    faultReceptionHashIds.map((faultReceptionHashId) => ({
      queryKey: [getFaultReceptionLogsKey, hospitalHashId, faultReceptionHashId, params],
      queryFn: () => getFaultReceptionLogs(hospitalHashId, faultReceptionHashId, params),
      enabled: !!hospitalHashId && !!faultReceptionHashId,
    }))
  );
  const data = queries
    .map((query) => query.data)
    .filter((item): item is FaultReceptionLogsResponse => item !== undefined);
  return {
    data,
    isLoading: queries.some((query) => query.isLoading),
  };
};
