import {Button, SxProps, Theme} from '@mui/material';
import React from 'react';

const cancelButtonStyle: SxProps<Theme> = (theme) => ({
  backgroundColor: theme.palette.common.white,
  border: `1px solid ${theme.palette.rent.main}`,
  borderRadius: '10px',
  color: theme.palette.rent.main,
  boxSizing: 'border-box',
  padding: '10px 8px',
  width: '98px',
  height: '36px',
});

type CancelButtonProps = {onClickCancel: VoidFunction};

/**
 * BaseSharedMenu内で使用するキャンセルボタンコンポーネント
 */
export const CancelButton = ({onClickCancel}: CancelButtonProps) => {
  return (
    <Button variant="outlined" sx={cancelButtonStyle} onClick={onClickCancel}>
      キャンセル
    </Button>
  );
};
