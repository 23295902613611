import {CreateWholeProductPlan} from '../type';

export const initialState: CreateWholeProductPlan = {
  uuid: '',
  inspectionSettingName: '',
  inspectionName: '',
  inspectionHashId: '',
  inspectionType: null,
  inspectionPeriod: 0,
  inspectionPeriodUnit: 'month',
};
