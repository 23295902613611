import {isNullish} from '@front-libs/helpers';
import {useMemo} from 'react';
import {FaultReceptionParams, FaultReceptionStatus} from '@modules/fault_receptions/types';
import {BEFORE_CONFIRMATION_STATUS} from './constants';

/**
 * APIのクエリパラメータを更新する。
 **/

export const useUpdateAPIQueryParams = (
  page: number,
  perPage: number,
  searchName: string | null,
  status: string | null,
  order: string | null
) => {
  const params = useMemo(() => {
    const adjustedPage = page > 0 ? page - 1 : 0;

    const _p: FaultReceptionParams = {
      page: adjustedPage,
      perPage,
      name: searchName ?? undefined,
      statuses: status !== 'all' && !isNullish(status) ? (status as FaultReceptionStatus) : undefined,
      order: isNullish(order) ? undefined : order,
    };

    return _p;
  }, [order, page, perPage, searchName, status]);

  const beforeConfParam: FaultReceptionParams = {
    page: 0,
    perPage: 1,
    name: searchName ?? undefined,
    statuses: BEFORE_CONFIRMATION_STATUS,
  };

  return {params, beforeConfParam};
};
