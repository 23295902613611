import {useReducer} from 'react';
import {fetchHospitalUsers, HospitalUserParams, FetchHospitalUsersResult} from '@modules/hospital_users/api';
import {hospitalUserInitialState, hospitalUserReducer} from '@modules/hospital_users/reducers';
import {HospitalUserState, HospitalUserActionType} from '@modules/hospital_users/types';

type HospitalUserStoreType = {
  state: HospitalUserState;
  dispatchListHospitalUsers: (hospitalHashId: string, params: HospitalUserParams) => Promise<FetchHospitalUsersResult>;
};

export const useHospitalUserStore = (): HospitalUserStoreType => {
  const [state, dispatch] = useReducer(hospitalUserReducer, hospitalUserInitialState);

  const dispatchListHospitalUsers = async (hospitalHashId: string, params: HospitalUserParams) => {
    const res = await fetchHospitalUsers(hospitalHashId, params);
    dispatch({
      type: HospitalUserActionType.HOSPITAL_USER_SET_LIST,
      payload: {users: res.data},
    });
    return res;
  };

  return {
    state,
    dispatchListHospitalUsers,
  };
};
