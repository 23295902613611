import React from 'react';
import {HeaderBackwardButton} from '@components/molecules/Buttons/HeaderBackwardButton';
import {Box, Divider, SxProps, Typography} from '@mui/material';

const headerLabelStyle: SxProps = {
  fontSize: '18px',
  fontWeight: 'bold',
};

const HeaderComponentStyle: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px 24px 16px 32px',
  width: '100%',
};

type HeaderProps = {
  prevLabel: string;
  headerLabel: string;
  onClickPrevButton: VoidFunction;
  rightNode?: React.ReactNode;
};

/**
 * ページ内のヘッダーコンポーネント
 *
 * 戻るボタン、ヘッダーラベル、オプションの右側ノードを含むレイアウト
 *
 * @param {string} props.prevLabel - ヘッダー左側に表示されるテキストラベル（通常は戻るボタンのラベル）
 * @param {string} props.headerLabel - ヘッダーラベル（通常はタイトル）
 * @param {VoidFunction} props.onClickPrevButton - 戻るボタンがクリックされたときに実行されるコールバック関数
 * @param {React.ReactNode} [props.rightNode] - ヘッダーの右側に配置されるオプションのノード（ボタンやリンクなど）
 *
 *
 * @example
 * ```tsx
 * <InnerPageHeader
 *   prevLabel="戻る"
 *   headerLabel="左上のページタイトル"
 *   onClickPrevButton={handleBackClick}
 *   rightNode={
 *   <Button
 *     color="primary"
 *     sx={ButtonStyle}
 *     onClick={() => {
 *       console.log('click');
 *     }}>
 *     子機を登録
 *   </Button>
 * );}
 * />
 * ```
 */
export const InnerPageHeader = ({prevLabel, headerLabel, onClickPrevButton, rightNode}: HeaderProps) => {
  return (
    <>
      <Box sx={HeaderComponentStyle}>
        <Box>
          <HeaderBackwardButton label={prevLabel} onClick={onClickPrevButton} />
          <Typography variant="h4" sx={headerLabelStyle}>
            {headerLabel}
          </Typography>
        </Box>
        <Box>{rightNode}</Box>
      </Box>
      <Divider sx={{width: '100%'}} />
    </>
  );
};
