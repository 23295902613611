import React from 'react';
import {Table, TableBody} from '@mui/material';
import {InspectionResultIndex} from '@modules/inspection_results/types';
import {InspectionType} from '@components/organisms/Header/pc/GeneralMenu/HospitalProductMenu/types';
import {InspectionResultSelectTableHead} from './InspectionResultSelectTableHead';
import {InspectionResultSelectTableRow} from './InspectionResultSelectTableRow';

type Props = {
  inspectionType: InspectionType;
  inspectionResults: InspectionResultIndex[];
  selectedInspectionResultHashId: string | null;
  onSelect: (inspectionResult: InspectionResultIndex) => void;
};

export const InspectionResultSelector = ({
  inspectionType,
  inspectionResults,
  selectedInspectionResultHashId,
  onSelect,
}: Props) => {
  const handleSelect = (inspectionResult: InspectionResultIndex) => {
    onSelect(inspectionResult);
  };

  return (
    <Table>
      <InspectionResultSelectTableHead inspectionType={inspectionType} />
      <TableBody>
        {inspectionResults.map((result) => (
          <InspectionResultSelectTableRow
            key={result.hashId}
            inspectionType={inspectionType}
            inspectionResult={result}
            checked={result.hashId === selectedInspectionResultHashId}
            onSelect={handleSelect}
          />
        ))}
      </TableBody>
    </Table>
  );
};
