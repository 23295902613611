import {TableFormRowId, PotentiallyRequiredTableFormRowId} from './types';
import {HospitalProductRequiredPropertyItemValue} from '@modules/hospital_product_required_properties/types';
import {productStatusOptions} from '@modules/hospital_products/constants';

/** TableFormRowId と 機器登録に設定可能な必須項目の値 とのマッピング */
export const HospitalProductRequiredPropertyItemValueMapByTableFormRowId: Record<
  PotentiallyRequiredTableFormRowId,
  string
> = {
  [TableFormRowId.SerialNumber]: HospitalProductRequiredPropertyItemValue.SerialNumber,
  [TableFormRowId.LotNumber]: HospitalProductRequiredPropertyItemValue.LotNumber,
  [TableFormRowId.HospitalDepartmentHashId]: HospitalProductRequiredPropertyItemValue.HospitalDepartment,
  [TableFormRowId.Status]: HospitalProductRequiredPropertyItemValue.Status,
  [TableFormRowId.HospitalRoomHashId]: HospitalProductRequiredPropertyItemValue.HospitalRoom,
  [TableFormRowId.DateOfPurchase]: HospitalProductRequiredPropertyItemValue.DateOfPurchase,
  [TableFormRowId.WaysOfPurchase]: HospitalProductRequiredPropertyItemValue.WaysOfPurchase,
  [TableFormRowId.LegalDurableYear]: HospitalProductRequiredPropertyItemValue.LegalDurableYear,
  [TableFormRowId.HospitalDealerHashId]: HospitalProductRequiredPropertyItemValue.HospitalDealer,
  [TableFormRowId.AssetRegisterNumber]: HospitalProductRequiredPropertyItemValue.AssetRegisterNumber,
  [TableFormRowId.DateOfDisposal]: HospitalProductRequiredPropertyItemValue.DateOfDisposal,
  [TableFormRowId.ReasonOfDisposal]: HospitalProductRequiredPropertyItemValue.ReasonOfDisposal,
  [TableFormRowId.OptionalBarcode]: HospitalProductRequiredPropertyItemValue.OptionalBarcode,
  [TableFormRowId.RawBarcode]: HospitalProductRequiredPropertyItemValue.Gs1Barcode,
} as const;

/** 機器登録用の稼働状況プロパティ。廃棄と待機のみに絞る */
export const newProductStatusOptions = productStatusOptions.filter(
  (item) => item.value === 'ready' || item.value === 'disabled'
);

export const isOutsideOfHospitalOptions = [
  {value: false, label: '院内'},
  {value: true, label: '院外'},
];
