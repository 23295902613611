import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {SxProps, Theme} from '@mui/material';

export const tableTitleStyle = {
  textAlign: 'center',
  marginBottom: '16px',
  fontSize: '24px',
};

export type BaseSharedListTableColumn<T> = {
  id: Extract<keyof T, string>; // keyof T を string に限定
  label: string;
  minWidth?: string;
  sx?: SxProps;
};

export type BaseSharedListTableProps<T> = {
  tableRows: T[];
  columns: BaseSharedListTableColumn<T>[];
};

/**
 * BaseSharedMenu内で使用するテーブルコンポーネント
 * 型Tのデータを受け取り、columnsで指定されたカラムでテーブルを表示する
 */
export const BaseSharedListTable = <T,>({tableRows, columns}: BaseSharedListTableProps<T>) => {
  // 4行に満たない場合は空の行を追加
  const rows = [...tableRows, ...(tableRows.length < 4 ? Array(4 - tableRows.length).fill({}) : [])];

  return (
    <>
      <TableContainer sx={tableContainerStyle}>
        <Table stickyHeader aria-label="sticky table">
          {/* キャプションの合計数は空の行を含んでいない元データのLength */}
          <caption>{`合計${tableRows.length}点`}</caption>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align="left"
                  sx={{
                    minWidth: column.minWidth,
                    ...column.sx,
                  }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => {
              return (
                <TableRow tabIndex={-1} key={`row_${index}`}>
                  {columns.map((column, colIndex) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={`${column.id}-${colIndex}`} align="left">
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const tableContainerStyle: SxProps<Theme> = (theme) => ({
  // Main
  borderRadius: '10px',
  maxHeight: '376px',
  backgroundColor: 'white',
  // Tableタグ内のスタイル
  '& tr': {
    height: '48px',
    [theme.breakpoints.up('laptop')]: {
      height: '56px',
    },
  },

  '& th': {padding: '8px'},
  '& td': {padding: '2px 8px'},

  '& th, & td': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '200px',
  },
  // thの最初
  '& th:first-of-type': {
    fontSize: '24px',
    padding: '0px 8px 0px 8px',
    [theme.breakpoints.up('laptop')]: {
      padding: '8px 16px 8px 32px',
    },
  },
  // tdの最初
  '& td:first-of-type': {
    color: '#2a96e8',
    fontWeight: 700,
    fontSize: '32px',
    [theme.breakpoints.up('laptop')]: {
      padding: '8px 16px 8px 32px',
    },
  },
  // th, tdの最後に配置のキャンセルボタンは幅を固定
  '& th:last-of-type, td:last-of-type': {
    width: '100px',
    minWidth: '100px',
    maxWidth: '100px',
  },
  // Captionタグ
  '& .MuiTable-root caption': {
    textAlign: 'right',
    color: '#374659',
    fontSize: '24px',
    position: 'sticky',
    bottom: 0,
    left: 0,
    zIndex: 2,
    backgroundColor: 'white',
  },
});
