import React from 'react';
import {Box, Button, SxProps, Theme, Tooltip, Typography} from '@mui/material';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';

const rootStyle: SxProps = {display: 'flex', width: '100%'};
const defaultFont: SxProps = {fontSize: '14px'};

const buttonStyle: SxProps<Theme> = (theme) => ({
  color: theme.palette.common.black,
  background: '#F3F4F6',
  border: '1px solid #8B95A6',
  padding: '0 8px',
  marginLeft: '8px',
  top: '-3px',
});

const boxStyle: SxProps<Theme> = {
  padding: '8px',
};

const textStyle: SxProps<Theme> = {
  minWidth: '56px',
};

const rowStyle: SxProps<Theme> = {
  display: 'flex',
  '& > div': {
    ...defaultFont,
  },
};

const titleStyle: SxProps<Theme> = {
  ...textStyle,
  mb: '8px',
};

const ParentDeviceButton = () => {
  const navigate = useNavigate();
  const {hashId} = useParams();
  const handleClick = () => {
    navigate(`/products/${hashId}/parent/edit_children`);
  };
  return (
    <Button color="inherit" variant="outlined" sx={buttonStyle} onClick={handleClick}>
      関連する子機
    </Button>
  );
};
const ChildDeviceButton = ({params}: {params: URLSearchParams}) => {
  const managementId = params.get('managementId') ?? '';
  const displayName = params.get('displayName') ?? '';
  const name = params.get('name') ?? '';
  return (
    <Tooltip
      title={
        <Box sx={boxStyle}>
          <Typography sx={titleStyle}>関連する親機</Typography>
          <LabelValueRow label="管理番号" value={managementId} />
          <LabelValueRow label="機種名" value={displayName} />
          <LabelValueRow label="型式" value={name} />
        </Box>
      }
      slotProps={{tooltip: {sx: {backgroundColor: 'white', color: 'black', border: '1px solid #ddd'}}}}>
      <Button color="inherit" variant="outlined" sx={buttonStyle}>
        関連する親機
      </Button>
    </Tooltip>
  );
};

const LabelValueRow = ({label, value}: {label: string; value: string}) => {
  return (
    <Box sx={rowStyle}>
      <Box sx={textStyle}>{label}</Box>
      <Box>：{value}</Box>
    </Box>
  );
};

/**
 * 機器詳細の親機・子機のラベル表示コンポーネント
 */
export const IsBaseUnitLabel = () => {
  const [params] = useSearchParams();
  const isBaseUnit = params.get('isBaseUnit');

  return (
    <Box sx={rootStyle}>
      <Typography sx={defaultFont}>親機・子機</Typography>
      {isBaseUnit === 'true' && <ParentDeviceButton />}
      {isBaseUnit === 'false' && <ChildDeviceButton params={params} />}
    </Box>
  );
};
