import React, {useCallback, useState} from 'react';
import {withSuspense} from '@front-libs/core';
import {useNavigate, useParams} from 'react-router-dom';
import {InspectionPreview as InspectionPreviewContent} from '@organisms/InspectionPreview';
import {useInspectionEditorQuery} from './hooks';
import {InspectionTypeMap} from '@modules/inspections/enum';
import {InspectionStatus} from '@molecules/InspectionStatus';
import {isNullish} from '@front-libs/helpers';
import {Divider, Tab, Tabs, Box, Paper, Grid, Button, Typography} from '@mui/material';
import {InspectionManualFileTab} from '@organisms/InspectionManualFileTab';

const styles = {
  root: {
    width: '100vw',
  },
  headerContainer: {
    padding: '20px 24px',
    backgroundColor: '#fff',
    borderBottom: '1px solid #F5F8FA',
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
  previewContainer: {
    padding: '24px 0px',
    width: '768px',
    margin: '0 auto',
  },
  text: {
    fontSize: '14px',
  },
  inspectionManualFileContainer: {
    padding: '24px 0',
    height: '100vh',
  },
};

const tabs = [
  {
    label: '点検項目',
    value: 'items',
  },
  {
    label: '点検マニュアル',
    value: 'manual',
  },
];

export const InspectionPreview = withSuspense(() => {
  const navigate = useNavigate();
  const {hashId} = useParams();
  const [tab, setTab] = useState('items');

  const {inspection} = useInspectionEditorQuery({
    inspectionHashId: hashId,
  });

  const handleClickEditInspection = useCallback(() => {
    // hashIdがない場合は遷移しない
    if (isNullish(hashId)) return;

    navigate(`/inspections/${hashId}`);
  }, [hashId, navigate]);

  const handleClose = useCallback(() => {
    navigate('/inspection_v2/tables');
  }, [navigate]);

  if (!inspection || !inspection.table) return null;

  return (
    <Box sx={styles.root}>
      <Paper elevation={2}>
        <Grid container justifyContent="space-between" alignItems="center" sx={styles.headerContainer}>
          <Grid item xs={2}>
            <Typography sx={styles.text} display="block">
              点検タイプ：{InspectionTypeMap[inspection.type]?.label ?? ''}
            </Typography>
            <Typography sx={styles.text} display="block">
              ステータス：
              <InspectionStatus status={inspection.status as 'available' | 'draft'} />
            </Typography>
          </Grid>
          <Grid item>
            <Typography sx={styles.text}>{inspection.name}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Grid container justifyContent={'flex-end'} style={{gap: '16px'}}>
              <Grid item>
                <Button variant="outlined" color="primary" onClick={handleClose}>
                  閉じる
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" onClick={handleClickEditInspection}>
                  編集
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider
          variant="middle"
          sx={{
            margin: '0px 0',
            width: '100%',
          }}
        />
        {/* Lower Header */}
        <Grid
          item
          container
          justifyContent="center"
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}>
          <Tabs value={tab} onChange={(_e, newTab) => setTab(newTab)} indicatorColor="primary" textColor="primary">
            {tabs.map((item) => (
              <Tab key={item.value} label={item.label} value={item.value} />
            ))}
          </Tabs>
        </Grid>
      </Paper>

      {tab === 'items' && (
        <Box sx={styles.previewContainer}>
          <InspectionPreviewContent inspectionTable={inspection?.table} />
        </Box>
      )}
      {tab === 'manual' && (
        <Box sx={styles.inspectionManualFileContainer}>
          <InspectionManualFileTab
            canUpload={false}
            fileHashId={inspection.inspectionManual?.hashId}
            fileType={inspection.inspectionManual?.fileType}
            fileName={inspection.inspectionManual?.fileName}
            uploadedUser={inspection.inspectionManual?.createdBy}
            uploadedAt={
              isNullish(inspection.inspectionManual?.createdAt)
                ? undefined
                : new Date(inspection.inspectionManual?.createdAt ?? '')
            }
          />
        </Box>
      )}
    </Box>
  );
});
