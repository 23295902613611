import {
  Box,
  Button,
  CSSObject,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TableCell,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import {PopperBtnInspectionTable} from '@organisms/PopperBtnInspectionTable';
import {InspectionType} from '@modules/inspections/enum';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ModeIcon from '@mui/icons-material/Mode';
import {InspectionIndex} from '@modules/inspections/types';
import {viewInspectionType} from '../utils/util';
import {EditWholeProductPlan} from '../utils/reducer';

const TableCellStyle: CSSObject = {
  borderBottom: 'none',
  padding: '0px 16px',
};

type EditSettingNameCellProps = {
  settingName: string;
  onInspectionSettingNameChange: (inspectionSettingName: string) => void;
};

/**
 * 点検名のセル
 */
export const EditSettingNameCell = ({settingName, onInspectionSettingNameChange}: EditSettingNameCellProps) => {
  return (
    <TableCell sx={TableCellStyle}>
      <TextField
        fullWidth
        value={settingName}
        onChange={(e) => onInspectionSettingNameChange(e.target.value)}
        variant="outlined"
        size="small"
        placeholder={'点検名を入力'}
      />
    </TableCell>
  );
};

type ViewSettingNameCellProps = {
  settingName: string;
};

/**
 * 点検名のセル(Viewモード)
 */
export const ViewSettingNameCell = ({settingName}: ViewSettingNameCellProps) => {
  return <TableCell sx={TableCellStyle}>{settingName}</TableCell>;
};

type EditInspectionTypeCellProps = {
  inspectionSetting: EditWholeProductPlan;
  onInspectionTypeChange: (inspectionSettingName: string) => void;
  displayInspectionTypeOptions: {value: string; label: string}[];
};

/**
 * 点検タイプのセル(Addモード)
 */
export const EditInspectionTypeCell = ({
  inspectionSetting,
  onInspectionTypeChange,
  displayInspectionTypeOptions,
}: EditInspectionTypeCellProps) => {
  return (
    <TableCell sx={TableCellStyle}>
      <FormControl fullWidth size="small">
        <InputLabel id="demo-simple-select-label" />
        <Select
          labelId="demo-simple-select-label"
          value={inspectionSetting.inspectionType ?? ''}
          displayEmpty
          sx={inspectionSetting.inspectionType ? null : {color: '#a6a6a6'}}
          onChange={(e) => {
            onInspectionTypeChange(e.target.value);
          }}>
          <MenuItem value="" disabled sx={{display: 'none'}}>
            点検タイプを選択
          </MenuItem>
          {displayInspectionTypeOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </TableCell>
  );
};

type InspectionTypeCellProps = {
  inspectionTypeName: string | null;
};

/**
 * 点検タイプのセル
 */
export const InspectionTypeCell = ({inspectionTypeName}: InspectionTypeCellProps) => {
  return <TableCell sx={TableCellStyle}>{viewInspectionType(inspectionTypeName)}</TableCell>;
};

type EditInspectionPeriodicCellProps = {
  isPeriodic: boolean; // 定期点検かどうか
  inspectionPeriod?: number;
  onInspectionPeriodChange: (inspectionPeriod: number) => void;
};

/**
 * 点検間隔のセル(Addモード)
 * 定期点検ならば期間表示、日常点検ならば対象外
 */
export const EditInspectionPeriodCell = ({
  isPeriodic,
  inspectionPeriod,
  onInspectionPeriodChange,
}: EditInspectionPeriodicCellProps) => {
  return (
    <TableCell sx={TableCellStyle}>
      {isPeriodic ? (
        <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <TextField
            type="number"
            inputProps={{min: 1, max: 120}}
            value={inspectionPeriod || ''}
            onChange={(e) => onInspectionPeriodChange(Number(e.target.value))}
            fullWidth
            margin="normal"
            size="small"
            placeholder={'数値を入力'}
            sx={{margin: 0}}
          />
          <Typography style={{width: '100px', paddingLeft: '8px'}}>ヶ月</Typography>
        </Box>
      ) : (
        <TextField
          type="number"
          fullWidth
          margin="normal"
          size="small"
          disabled
          placeholder="対象外"
          style={{backgroundColor: '#F3F4F6', margin: 0}}
        />
      )}
    </TableCell>
  );
};

type InspectionPeriodicCellProps = {
  isPeriodic: boolean; // 定期点検かどうか
  inspectionPeriod?: number;
};

/**
 * 点検間隔のセル
 * 定期点検ならば期間表示、日常点検ならば対象外
 */
export const InspectionPeriodCell = ({isPeriodic, inspectionPeriod}: InspectionPeriodicCellProps) => {
  return (
    <TableCell sx={TableCellStyle}>
      {isPeriodic ? (
        <>{inspectionPeriod}ヶ月</>
      ) : (
        <TextField
          type="number"
          fullWidth
          margin="normal"
          size="small"
          disabled
          placeholder="対象外"
          style={{backgroundColor: '#F3F4F6', margin: 0}}
        />
      )}
    </TableCell>
  );
};

type InspectionProps = {
  inspectionName: string;
  inspectionType: InspectionType | null;
  onChangeInspection: (inspectionHashId: string, inspectionName: string) => void;
};

/**
 * 点検する点検表のセル
 */
export const EditInspectionCell = ({inspectionName, inspectionType, onChangeInspection}: InspectionProps) => {
  return (
    <TableCell sx={TableCellStyle}>
      {/* // TODO 点検表のデータはAPIと疎通後実施する */}
      <PopperBtnInspectionTable
        inspectionName={inspectionName}
        onChangeInspection={(inspection: InspectionIndex) => {
          onChangeInspection(inspection.hashId, inspection.name);
        }}
        type={inspectionType}
      />
    </TableCell>
  );
};

type ViewInspectionCellProps = {
  inspectionName: string;
};

/**
 * 点検表のセル(Viewモード)
 */
export const ViewInspectionCell = ({inspectionName}: ViewInspectionCellProps) => {
  return <TableCell sx={TableCellStyle}>{inspectionName}</TableCell>;
};

type EditActionButtonCellProps = {
  onSave: () => void;
  onCancel: () => void;
  canSave: boolean;
};

/**
 * Editモードでのアクションボタンセル
 * 保存、キャンセルボタンを表示
 */
export const EditActionButtonCell = ({onSave, onCancel, canSave}: EditActionButtonCellProps) => {
  return (
    <TableCell sx={TableCellStyle}>
      <Box sx={{display: 'flex', alignContent: 'center'}}>
        <Button onClick={onSave} disabled={!canSave}>
          保存
        </Button>
        <Button onClick={onCancel}>キャンセル</Button>
      </Box>
    </TableCell>
  );
};

type ViewActionButtonCellProps = {
  onEdit: () => void;
  onDelete: () => void;
  canDelete: boolean;
};

/**
 * Viewモードでのアクションボタンセル
 * 編集、削除ボタンを表示
 */
export const ViewActionButtonCell = ({onEdit, onDelete, canDelete}: ViewActionButtonCellProps) => {
  return (
    <TableCell sx={TableCellStyle}>
      <Box sx={{display: 'flex', alignContent: 'center'}}>
        <IconButton onClick={onEdit} size="small" sx={{marginRight: '32px'}}>
          <ModeIcon sx={{color: '#0052CC', paddingRight: '12px'}} />
          <Typography sx={{color: '#0052CC'}}>編集</Typography>
        </IconButton>
        {canDelete && (
          <IconButton onClick={onDelete} size="small">
            <DeleteOutlineIcon sx={{color: '#0052CC', paddingRight: '12px'}} />
            <Typography sx={{color: '#0052CC'}}>削除</Typography>
          </IconButton>
        )}
      </Box>
    </TableCell>
  );
};

type WaitActionButtonCellProps = {
  canDelete: boolean;
};

/**
 * Waitモードでのアクションボタンセル
 * 編集、削除ボタンを非活性化する
 */
export const WaitActionButtonCell = ({canDelete}: WaitActionButtonCellProps) => {
  return (
    <TableCell sx={TableCellStyle}>
      <Box sx={{display: 'flex', alignContent: 'center'}}>
        <IconButton size="small" sx={{marginRight: '32px'}}>
          <ModeIcon sx={{color: '#D1D5DB', paddingRight: '12px'}} />
          <Typography sx={{color: '#D1D5DB'}}>編集</Typography>
        </IconButton>
        {canDelete && (
          <IconButton size="small">
            <DeleteOutlineIcon sx={{color: '#D1D5DB', paddingRight: '12px'}} />
            <Typography sx={{color: '#D1D5DB'}}>削除</Typography>
          </IconButton>
        )}
      </Box>
    </TableCell>
  );
};
