import {TrainingTypeValue} from '@modules/training_plans/types';

export const trainingTypeLabelMap: Record<TrainingTypeValue, string> = {
  effectiveness_and_safety: '有効性・安全性',
  how_to_use: '使用方法',
  maintenance_and_inspection: '保守点検',
  incident: '不具合等への対応',
  compliance: '法令上の遵守事項',
};

export const trainingTypeOptions = [
  {
    value: 'effectiveness_and_safety',
    label: trainingTypeLabelMap.effectiveness_and_safety,
  },
  {
    value: 'how_to_use',
    label: trainingTypeLabelMap.how_to_use,
  },
  {
    value: 'maintenance_and_inspection',
    label: trainingTypeLabelMap.maintenance_and_inspection,
  },
  {
    value: 'incident',
    label: trainingTypeLabelMap.incident,
  },
  {
    value: 'compliance',
    label: trainingTypeLabelMap.compliance,
  },
];

export const planTypeOptions = [
  {label: '年間', value: 'yearly_plan'},
  {label: '随時', value: 'as_needed'},
];

export const statusOptions = [
  {label: '完了', value: 'complete'},
  {label: '未完了', value: 'incomplete'},
];
