import {InspectionState, InspectionAction, InspectionActionType} from '../types';

export const inspectionInitialState: InspectionState = {
  allInspections: [],
  wholeProductInspections: [],
};

export const inspectionReducer = (state: InspectionState, action: InspectionAction): InspectionState => {
  switch (action.type) {
    case InspectionActionType.INSPECTION_SET_LIST: {
      return {
        ...state,
        allInspections: action.payload.allInspections,
        wholeProductInspections: action.payload.wholeProductInspections,
      };
    }
    default:
      return state;
  }
};
