import React, {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {MenuItemType, PopperMenuButton} from '@molecules/Buttons/PopperMenuButton';

export const PageRoutingMenu: React.FC = ({children}) => {
  const navigate = useNavigate();

  const pageRoutingMenuItems = [
    {
      label: 'ダッシュボード',
      value: '/',
    },
    {
      label: '機器',
      value: '/products',
    },
    {
      label: '点検',
      value: '/inspection_v2/results',
    },
    {
      label: '修理',
      value: '/repairs',
    },
    {
      label: '貸出・返却',
      value: '/rentals',
    },
  ];

  const handlePageRoutingMenuClick = useCallback(
    (item: MenuItemType) => {
      if (item.value) {
        navigate(item.value);
      } else {
        navigate('/');
      }
    },
    [navigate]
  );

  return (
    <PopperMenuButton
      placement="bottom-start"
      hiddenArrow={true}
      buttonProps={{variant: 'text', style: {width: '100%'}}}
      menuItemList={pageRoutingMenuItems}
      itemProps={{style: {minWidth: '350px'}}}
      onMenuClick={handlePageRoutingMenuClick}>
      {children}
    </PopperMenuButton>
  );
};
