import React from 'react';
import {Divider, SxProps, Box} from '@mui/material';
import {HeaderTop} from './HeaderTop';
import {HeaderBottom} from './HeaderBottom';
import {WithFormikProps, withFormik} from '@front-libs/core';
import {FormValue} from '@modules/hospital_ward_layout_setting/types';

type HeaderProps = {
  handleClickCancel: () => void;
};

const _Header = (props: WithFormikProps<FormValue, FormValue, HeaderProps>): JSX.Element => {
  const {handleClickCancel} = props;
  return (
    <Box sx={boxStyle}>
      <HeaderTop handleClickCancel={handleClickCancel} />
      <Divider sx={dividerStyle} />
      <HeaderBottom />
    </Box>
  );
};

export const Header = withFormik<FormValue, FormValue, HeaderProps>({
  displayName: 'Header',
  getNames: () => ['HashId', 'Setting'],
})(_Header);

const boxStyle: SxProps = {
  backgroundColor: '#ffffff',
  width: '100%',
};
const dividerStyle: SxProps = {
  backgroundColor: '#C6CBD4',
  width: '100%',
};
