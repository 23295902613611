import {yup} from '@front-libs/core';
import {EIGHT_DIGIT_NUMBER_ERROR_MESSAGE} from '@Apps/ProductImport/hooks';
import {isNullish} from '@front-libs/helpers';
export type WholeProductEditDialogResult = {
  rootCategoryHashId: string;
  narrowCategoryHashId: string;
  name: string;
  displayName: string;
  makerHashId: string | null;
  newMakerName?: string | null;
  approvalNumber: string | null;
  jmdnCode: number | null;
  janCode: string | null;
  isSpecificMaintain: boolean | null;
  className: string | null;
  catalogPrice: number | null;
};

/**
 * WholeProductEditDialogResult 型ガード
 *
 * @param obj - チェックするオブジェクト
 * @returns オブジェクトが WholeProductEditDialogResult 型であるかどうか
 */
export const isWholeProductEditDialogResult = (obj: unknown): obj is WholeProductEditDialogResult => {
  if (typeof obj !== 'object' || obj === null) {
    return false;
  }

  const result = obj as WholeProductEditDialogResult;

  return (
    typeof result.rootCategoryHashId === 'string' &&
    typeof result.narrowCategoryHashId === 'string' &&
    typeof result.name === 'string' &&
    typeof result.displayName === 'string' &&
    (typeof result.makerHashId === 'string' || result.makerHashId === null) &&
    (typeof result.newMakerName === 'string' ||
      result.newMakerName === null ||
      typeof result.newMakerName === 'undefined') &&
    (typeof result.approvalNumber === 'string' || result.approvalNumber === null) &&
    (typeof result.jmdnCode === 'number' || result.jmdnCode === null) &&
    (typeof result.janCode === 'string' || result.janCode === null) &&
    (typeof result.isSpecificMaintain === 'boolean' || result.isSpecificMaintain === null) &&
    (typeof result.className === 'string' || result.className === null) &&
    (typeof result.catalogPrice === 'number' || result.catalogPrice === null)
  );
};

export const validationSchema = yup.object({
  rootCategoryHashId: yup.string().required(),
  narrowCategoryHashId: yup.string().required(),
  displayName: yup.string().required(),
  name: yup.string().required(),
  makerHashId: yup.string().nullable(),
  jmdnCode: yup
    .number()
    .nullable()
    .notRequired()
    .test('jmdn-code', EIGHT_DIGIT_NUMBER_ERROR_MESSAGE, (value) => {
      if (isNullish(value)) return true;
      return /^(?:[1-9][0-9]{7})$/.test(value.toString());
    }),
  janCode: yup
    .string()
    .nullable()
    .notRequired()
    .matches(/^[a-zA-Z0-9]+$/, 'JANコードには、半角英数字を入力してください。'),
  approvalNumber: yup
    .string()
    .nullable()
    .notRequired()
    .matches(/^[A-Z0-9]+$/, '承認番号には、半角英大文字・数字 を入力してください。'),
  isSpecificMaintain: yup.boolean().nullable(),
  className: yup.string().nullable(),
  catalogPrice: yup.number().min(1, 'catalog_price must be at least 1').nullable(),
});
