import React, {useCallback, useMemo, useState} from 'react';
import clsx from 'clsx';
import {Button, createStyles, IconButton, InputAdornment, makeStyles, TextField, Theme} from '@material-ui/core';
import {CheckCircle, Visibility, VisibilityOff} from '@material-ui/icons';
import {useSteps} from '../hooks';
import {Form, Formik, useFormikContext} from 'formik';
import {yup} from '@front-libs/core';
import {InferType, TestContext} from 'yup';
import {updateHospitalUser} from '@modules/hospital_users/api';
import {updateUserPassword} from '@modules/auth/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {openSnackBar} from '@molecules/SnackBar';

const remoteErrorMessage4SameEmail = 'The specified new email already exists';

const INVALID_EMAIL = 'INVALID_EMAIL';
const INVALID_LENGTH = 'INVALID_LENGTH';
const INVALID_NOT_SAME_PASSWORD = 'INVALID_NOT_SAME_PASSWORD';

const validationSchema = yup.object({
  email: yup
    .string()
    .required()
    .test(INVALID_EMAIL, INVALID_EMAIL, (tester: string | undefined) => {
      return !!(tester?.includes('@') && tester?.includes('.'));
    }),
  password: yup.string().min(8, INVALID_LENGTH).required(),
  passwordConfirm: yup
    .string()
    .required()
    .test(
      INVALID_NOT_SAME_PASSWORD,
      INVALID_NOT_SAME_PASSWORD,
      (tester: string | undefined, validator: TestContext) => {
        return tester === validator.parent.password;
      }
    ),
});

type MailAndPasswordFormType = InferType<typeof validationSchema>;
type PageState = {
  showPassword: boolean;
  showPasswordConfirm: boolean;
};

const MailAndPasswordSettingForm: React.FC = () => {
  const classes = useStyles();
  const {submitForm, errors, isValid, isSubmitting, values, handleChange} = useFormikContext<MailAndPasswordFormType>();
  const isInvalidPasswordLen = useMemo(() => errors.password, [errors.password]);
  const isInvalidSamePasswords = useMemo(() => errors.passwordConfirm, [errors.passwordConfirm]);
  const [pageState, setPageState] = useState<PageState>({
    showPassword: false,
    showPasswordConfirm: false,
  });
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Form id="tutorial-step1-email-password">
      <div>
        <p className={classes.textFieldTitle} style={{fontWeight: 700}}>
          （１）パスワード再設定用メールアドレスの設定
        </p>
        <p className={clsx(classes.textFieldTitle, classes.textSubTitle)}>
          パスワードを忘れてしまった際など、パスワードを再設定するために使うメールアドレスを設定してください。
          <br />
          info@hitotsu.co.jp からのメールを受け取ることができるメールアドレスであれば、個人用でも問題ありません。
          <br />
          （他のユーザーが既に使用しているメールアドレスは設定できません。）
        </p>
        <TextField
          fullWidth
          name="email"
          type="text"
          variant="outlined"
          size={'small'}
          value={values.email}
          onChange={handleChange}
        />
      </div>

      <div>
        <div style={{marginTop: 40}}>
          <p className={classes.textFieldTitle} style={{fontWeight: 700}}>
            （２）新しいパスワードの設定
          </p>
          <p className={clsx(classes.textFieldTitle, classes.textSubTitle)}>
            今後のログインに使う新しいパスワードを設定してください。
            <br />
            半角英数字、半角記号を組み合わせて8文字以上で設定してください。パスワードは忘れないようにご自身での管理をお願いします。
            <br />
            （今回のログインに使用した初期パスワードは今後使えません。）
          </p>
        </div>
        <div style={{marginTop: 20}}>
          <p className={classes.textFieldTitle}>新しいパスワード</p>
          <TextField
            fullWidth
            name="password"
            type={pageState.showPassword ? 'text' : 'password'}
            variant="outlined"
            size={'small'}
            value={values.password}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    tabIndex="-1"
                    onClick={() => {
                      setPageState({...pageState, showPassword: !pageState.showPassword});
                    }}
                    onMouseDown={handleMouseDownPassword}
                    edge="end">
                    {pageState.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div style={{marginTop: 40}}>
          <p className={classes.textFieldTitle}>新しいパスワード（確認用）</p>
          <TextField
            fullWidth
            name="passwordConfirm"
            type={pageState.showPasswordConfirm ? 'text' : 'password'}
            variant="outlined"
            size={'small'}
            value={values.passwordConfirm}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password confirmation visibility"
                    tabIndex="-1"
                    onClick={() => {
                      setPageState({...pageState, showPasswordConfirm: !pageState.showPasswordConfirm});
                    }}
                    onMouseDown={handleMouseDownPassword}
                    edge="end">
                    {pageState.showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div style={{marginTop: 8}}>
          <CheckCircle
            fontSize="small"
            className={clsx(
              classes.checkCircleIcon,
              isInvalidPasswordLen ? classes.checkCircleIconFailure : classes.checkCircleIconSuccess
            )}
          />
          半角英数字、半角記号で8文字以上
        </div>
        <div style={{marginTop: 8}}>
          <CheckCircle
            fontSize="small"
            className={clsx(
              classes.checkCircleIcon,
              isInvalidSamePasswords ? classes.checkCircleIconFailure : classes.checkCircleIconSuccess
            )}
          />
          パスワードの確認
        </div>
      </div>
      <div style={{marginTop: 16}}>
        <Button disabled={!isValid || isSubmitting} fullWidth color="primary" variant="contained" onClick={submitForm}>
          変更する
        </Button>
      </div>
    </Form>
  );
};

export const MailAndPasswordSetting: React.FC = () => {
  const {myInfo, refetch} = useMyInfo();
  const {next, currentStep} = useSteps();

  const submitForm = useCallback(
    async (values: MailAndPasswordFormType) => {
      try {
        await updateHospitalUser(myInfo.hospitalHashId, myInfo.hashId, {email: values.email});
        await updateUserPassword({hashId: myInfo.hashId, password: values.password});
        await updateHospitalUser(myInfo.hospitalHashId, myInfo.hashId, {nextInitializeStep: currentStep + 1});
        await refetch();
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      } catch (e: any) {
        const errMsg =
          e.response.data.message === remoteErrorMessage4SameEmail
            ? 'メールアドレスが他のユーザーと重複しています'
            : 'ユーザー情報の変更に失敗しました。';
        openSnackBar(errMsg, 'left', 'bottom', 'error');
        return;
      }

      next(true);
      openSnackBar('ユーザー情報を更新しました。', 'left', 'bottom');
    },
    [currentStep, myInfo.hashId, myInfo.hospitalHashId, next, refetch]
  );
  return (
    <Formik<MailAndPasswordFormType>
      initialValues={{
        email: '',
        password: '',
        passwordConfirm: '',
      }}
      validationSchema={validationSchema}
      initialErrors={{
        password: INVALID_LENGTH,
        passwordConfirm: INVALID_NOT_SAME_PASSWORD,
      }}
      onSubmit={submitForm}>
      <MailAndPasswordSettingForm />
    </Formik>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      width: '100%',
    },
    dialogActions: {
      display: 'flex',
      justifyContent: 'end',
      padding: '16px 24px 24px',
    },
    textFieldTitle: {
      marginTop: 0,
      marginBottom: 8,
      fontSize: 14,
    },
    textSubTitle: {
      color: theme.palette.grey[600],
    },
    checkCircleIcon: {
      marginRight: 8,
    },
    checkCircleIconSuccess: {
      color: theme.palette.success.main,
    },
    checkCircleIconFailure: {
      color: theme.palette.grey[400],
    },
  })
);
