import {useSetAtom} from 'jotai';
import {useMemo, useCallback, useEffect} from 'react';
import {isTablet} from 'react-device-detect';
import {useFetchHospitalSettingsQuery} from '@modules/hospital_settings/api';
import {rentalSettings} from '@modules/hospital_settings/types';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {rentalSettingAtom, canUseDeviceCameraAtom} from '../states';
import {HospitalRentalSetting} from '../types';
import {isNullish} from '@front-libs/helpers';

export const useDetectCameraEffect = () => {
  const setRentalSetting = useSetAtom(rentalSettingAtom);
  const setCanUseDeviceCamera = useSetAtom(canUseDeviceCameraAtom);

  const {myInfo} = useMyInfo();
  const {data: hospitalSettings} = useFetchHospitalSettingsQuery(myInfo.hospitalHashId);

  const hospitalRentalSetting = useMemo(
    () => hospitalSettings?.data.find((setting) => setting.key === rentalSettings.use_device_lender.key),
    [hospitalSettings?.data]
  );

  const detectCamera = useCallback(() => {
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        const hasCamera = devices.some((device) => device.kind === 'videoinput');
        setCanUseDeviceCamera(isTablet && hasCamera);
      })
      .catch((err) => {
        console.error(`${err.name}: ${err.message}`);
      });
  }, [setCanUseDeviceCamera]);

  useEffect(() => {
    !isNullish(hospitalRentalSetting) && setRentalSetting(hospitalRentalSetting.value as HospitalRentalSetting);
    detectCamera();
  }, [detectCamera, hospitalRentalSetting, setRentalSetting]);
};
