import {Grid, SxProps, Theme, TextField, Box} from '@mui/material';
import React, {useMemo, useState} from 'react';
import {useFetchHospitalDeviceLendersQuery} from '@modules/hospital_device_lenders/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {FetchHospitalDeviceLendersParams} from '@modules/hospital_device_lenders/types';

import {DirectInputContentProps} from './types';
import {LenderNameCard} from './LenderNameCard';

const ITEMS_PER_PAGE = 12;
const PAGE = 0;

export const DirectInputContent: React.FC<DirectInputContentProps> = ({onSubmitInput}) => {
  const {myInfo} = useMyInfo();
  const [lenderName, setLenderName] = useState('');

  const params: FetchHospitalDeviceLendersParams = useMemo(
    () => ({
      userName: lenderName,
      page: PAGE,
      perPage: ITEMS_PER_PAGE,
    }),
    [lenderName]
  );
  const {data} = useFetchHospitalDeviceLendersQuery(myInfo.hospitalHashId, params);

  return (
    <Box sx={rootStyle}>
      <Box sx={fieldContainerStyle}>
        <TextField
          data-testid={'lender-name-textbox'}
          id="outlined-full-width"
          placeholder="ここをタップして名前を入力"
          InputProps={{
            sx: {
              fontSize: 32,
              fontWeight: 700,
            },
          }}
          fullWidth
          autoFocus
          margin="normal"
          variant="outlined"
          sx={textFieldStyle}
          tabIndex={0}
          value={lenderName}
          onChange={(e) => setLenderName(e.target.value)}
        />
      </Box>
      <Box sx={nameContainerStyle}>
        <Box sx={candidateSubTitleStyle}>名前の候補</Box>
        <Box sx={{width: '70%'}}>
          {lenderName !== '' && (
            <Grid container spacing={3} sx={{overflow: 'auto', paddingBottom: '8px'}}>
              <Grid item xs={3}>
                <LenderNameCard userName={lenderName} onSubmitInput={onSubmitInput} />
              </Grid>
              {data.map(
                (d, index) =>
                  d.userName !== lenderName && (
                    <Grid key={`lenderName${index}`} item xs={3}>
                      <LenderNameCard userName={d.userName} onSubmitInput={onSubmitInput} />
                    </Grid>
                  )
              )}
            </Grid>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const rootStyle: SxProps<Theme> = {
  paddingTop: '40px',
  marginBottom: 2,
  width: '100%',
  marginLeft: 'auto',
  marginRight: 'auto',
};

const fieldContainerStyle: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'center',
};

const textFieldStyle: SxProps<Theme> = {
  width: '70%',
  paddingTop: 5,
  margin: 1,
};

const candidateSubTitleStyle: SxProps<Theme> = {
  width: '70%',
  marginTop: 2,
  marginBottom: 1,
};

const nameContainerStyle: SxProps<Theme> = {
  flexDirection: 'column',
  alignItems: 'center',
  ...fieldContainerStyle,
};
