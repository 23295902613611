import React from 'react';
import {Column} from '@molecules/Table/props';
import {ProductIndex} from '@modules/products/types';
import {productClassTypeToLabel} from '@modules/products/constants';
import {Table} from '@molecules/Table';
import {isNullish} from '@front-libs/helpers';

const tableColumn: Column<ProductIndex>[] = [
  {
    title: '大分類',
    field: 'broadCategory',
    render: ({categories}) => {
      return categories && categories.find(({depth}) => depth === 0)?.name;
    },
  },
  {
    title: '小分類',
    field: 'narrowCategory',
    render: ({categories}) => {
      return categories && categories.find(({depth}) => depth === 1)?.name;
    },
  },
  {
    title: '機種名',
    field: 'displayName',
  },

  {
    title: '型式',
    field: 'name',
  },
  {
    title: 'メーカー名',
    field: 'maker_name',
    render: ({maker}) => {
      return maker?.name;
    },
  },
  {
    title: '承認番号',
    field: 'approvalNumber',
  },
  {
    title: 'JMDNコード',
    field: 'jmdnCode',
  },
  {
    title: 'JANコード',
    field: 'janCode',
  },
  {
    title: '特定保守製品',
    field: 'isSpecificMaintain',
    render: ({isSpecificMaintain}) => {
      return isSpecificMaintain == null ? '' : isSpecificMaintain ? '該当' : '非該当';
    },
  },
  {
    title: 'クラス分類',
    field: 'className',
    render: ({className}) => {
      return (className && productClassTypeToLabel[className]) || '';
    },
  },
  {
    title: '定価',
    field: 'catalogPrice',
    render: ({catalogPrice}) => {
      return !isNullish(catalogPrice) ? `${catalogPrice.toLocaleString()}円` : '';
    },
  },
];

type Props = {
  wholeProducts?: ProductIndex;
};

export const CategoryTable: React.FC<Props> = ({wholeProducts}) => {
  return wholeProducts ? <Table showSelection={false} columns={tableColumn} data={[wholeProducts]} /> : null;
};
