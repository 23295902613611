import React, {Suspense} from 'react';
import {Dialog, IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import {DialogContent} from './DialogContent/DialogContent';

export const HospitalProductMenuDialog = ({open, actions}: DialogProps) => {
  const handleClose = () => {
    actions.reject();
  };

  return (
    <Dialog
      maxWidth="lg"
      open={!!open}
      PaperProps={{
        sx: {position: 'relative', padding: '32px', fontSize: '14px', color: '#172B4D'},
      }}>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          fontSize: '24px',
          right: '16px',
          top: '24px',
          color: 'inherit',
        }}>
        <CloseIcon />
      </IconButton>
      <Suspense fallback={null}>
        <DialogContent />
      </Suspense>
    </Dialog>
  );
};
