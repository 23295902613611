import React, {useState} from 'react';
import {styled, Box, Button, DialogActions, DialogTitle, Typography, Divider} from '@mui/material';
import {Brightness1} from '@mui/icons-material';
import no_image from '@assets/no_image.svg';
import {HospitalProductIndex} from '@modules/hospital_products/types';
import {getProductStatusByValue} from '@modules/hospital_products/constants';
import {MenuButton} from './MenuButton';
import {MenuItemSettings} from '../../constants';
import {MenuItemValue} from '../../types';

type Props = {
  hospitalProduct: HospitalProductIndex;
  onBack: () => void;
  onSubmit: (value: MenuItemValue) => void;
};

const Summary = styled(Box)({
  marginTop: '32px',
});
const SummaryHeader = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});
const SummaryBody = styled(Box)({
  marginTop: '8px',
  display: 'flex',
});
const SummaryLeft = styled(Box)({
  width: '76px',
});
const SummaryRight = styled(Box)({
  marginLeft: '16px',
});

const MenuContent = styled(Box)({
  marginTop: '24px',
});
const MenuItemList = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '24px',
});

const Actions = styled(DialogActions)({
  marginTop: '40px',
  '& > button:nth-of-type(n+2)': {
    marginLeft: '16px',
  },
});

export const MainMenu = ({hospitalProduct, onBack, onSubmit}: Props) => {
  const [selectedMenuItem, setSelectedMenuItem] = useState<MenuItemValue | null>(null);

  const status = getProductStatusByValue(hospitalProduct.status);

  const handleSelectMenuItem = (value: MenuItemValue) => {
    setSelectedMenuItem(value);
  };

  const resetSelectedMenuItem = () => {
    setSelectedMenuItem(null);
  };

  const handleBack = () => {
    resetSelectedMenuItem();
    onBack();
  };

  const handleSubmit = () => {
    if (!selectedMenuItem) return;

    onSubmit(selectedMenuItem);
  };

  return (
    <>
      <DialogTitle sx={{padding: 0, minWidth: '456px', fontWeight: 700}}>機器メニューを選択</DialogTitle>
      <Summary>
        <SummaryHeader>
          <SummaryLeft sx={{display: 'flex', alignItems: 'center', color: status.color}}>
            <Brightness1 sx={{fontSize: '14px'}} />
            <Typography sx={{marginLeft: '4px', fontSize: '14px'}}>{status.label}</Typography>
          </SummaryLeft>
          <SummaryRight>
            <Typography sx={{fontWeight: '600', fontSize: '16px'}}>{hospitalProduct.managementId}</Typography>
          </SummaryRight>
        </SummaryHeader>
        <SummaryBody>
          <SummaryLeft sx={{'& > img': {borderRadius: '10px', border: '1px solid #D1D5DB'}}}>
            <img
              src={hospitalProduct.thumbnailUrl === '' ? no_image : hospitalProduct.thumbnailUrl}
              alt="サムネイル"
              height="76px"
              width="76px"
              decoding="async"
              loading="lazy"
              onError={({currentTarget}) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = no_image;
              }}
            />
          </SummaryLeft>
          <SummaryRight sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
            <Typography noWrap sx={{fontSize: '16px'}}>
              {hospitalProduct.displayName}
            </Typography>
            <Typography noWrap sx={{marginTop: '4px', fontSize: '14px'}}>
              {hospitalProduct.name}
            </Typography>
            <Typography noWrap sx={{marginTop: '4px', fontSize: '14px'}}>
              {hospitalProduct.maker.name}
            </Typography>
          </SummaryRight>
        </SummaryBody>
      </Summary>
      <Divider sx={{marginTop: '24px', backgroundColor: 'inherit', borderColor: '#D1D5DB'}} />
      <MenuContent>
        <Typography noWrap sx={{fontSize: '14px'}}>
          機器メニュー
        </Typography>
        <MenuItemList sx={{marginTop: '16px', maxWidth: '456px'}}>
          {MenuItemSettings.map((setting, index) => (
            <MenuButton
              key={`menu-item-${index}`}
              setting={setting}
              selected={setting.value === selectedMenuItem}
              onClick={handleSelectMenuItem}
            />
          ))}
        </MenuItemList>
      </MenuContent>
      <Actions>
        <Button variant="text" sx={{color: '#0052CC'}} onClick={handleBack}>
          読み取り画面に戻る
        </Button>
        <Button
          variant="contained"
          sx={{backgroundColor: '#0052CC', '&.Mui-disabled': {color: '#FFFFFF'}}}
          disabled={selectedMenuItem === null}
          onClick={handleSubmit}>
          確定
        </Button>
      </Actions>
    </>
  );
};
