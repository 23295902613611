import React from 'react';
import {Button, Grid, Typography, SxProps, Box} from '@mui/material';
import {Sidebar} from '@components/organisms/Sidebar';
import {withSuspense} from '@front-libs/core';
import {getSettingsContentTemplate as templateClasses} from '@components/templates/ContentLayout/InnerSidebarContentLayoutV5';

const textStyles: SxProps = {
  marginRight: '32px',
  color: '#172B4D',
  fontSize: '20px',
  marginBottom: '24px',
};

/**
 * ユーザー管理ページ
 * @param props
 * @returns
 */
const _SettingsHospitalUser: React.FC = () => {
  return (
    <Box sx={templateClasses.form}>
      <Grid container sx={templateClasses.grid}>
        <Grid item sx={templateClasses.sideBar}>
          <Sidebar />
        </Grid>
        <Grid item sx={templateClasses.content}>
          <Grid container style={{marginBottom: '32px'}}>
            <Grid item>
              <Typography variant={'h5'} sx={templateClasses.pageTitle}>
                ユーザー管理
              </Typography>
            </Grid>
          </Grid>
          <Grid sx={textStyles}>ユーザーの追加、削除、権限の変更はHITOTSU Hospitalで設定できます。</Grid>
          <Grid>
            <a href={import.meta.env.VITE_ACCOUNT_SERVICE_URL} target="_blank" rel="noreferrer">
              <Button variant="contained" color="primary">
                HITOTSU Hospitalへ移動
              </Button>
            </a>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export const SettingsHospitalUser = withSuspense(_SettingsHospitalUser, null);
