import {FormControl, FormControlLabel, FormGroup, FormHelperText, SxProps, Theme} from '@mui/material';
import React from 'react';
import {Checkbox} from '@atoms/Checkbox';

export type LabeledCheckboxProps = {
  label: string;
  subLabel?: string;
  disabled?: boolean;
  checked: boolean;
  onChange: (e: React.SyntheticEvent<Element, Event>, checked: boolean) => void;
};

export const LabeledCheckbox = (props: LabeledCheckboxProps) => {
  const {label, subLabel, disabled, checked, onChange} = props;
  return (
    <FormControl component="fieldset">
      <FormGroup>
        <FormControlLabel
          data-testid={'rental-settings-skip-floor-selection'}
          control={<Checkbox color={'secondary'} disableRipple={false} />}
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          label={label}
        />
        {subLabel && <FormHelperText sx={formHelperTextStyles}>{subLabel}</FormHelperText>}
      </FormGroup>
    </FormControl>
  );
};

const formHelperTextStyles: SxProps<Theme> = {
  mt: -1,
  ml: 4,
};
