import {Button, Grid, Stack, SxProps} from '@mui/material';
import React, {useMemo} from 'react';
import {NoUploadedFile} from './NoUploadedFile';
import {UserIndex} from '@modules/hospital_users/types';
import {isNullish} from '@front-libs/helpers';
import {InspectionManual} from './InspectionManual';

const styles: Record<string, SxProps> = {
  noUploadMessage: {
    marginTop: '48px',
  },
};

type InspectionManualFileTabProps = {
  canUpload: boolean;
  fileHashId?: string;
  fileType?: string;
  fileName?: string;
  uploadedUser?: UserIndex;
  uploadedAt?: Date;
  onClickUploadButton?: () => void;
};

export const InspectionManualFileTab = (props: InspectionManualFileTabProps) => {
  const {canUpload, fileHashId, fileType, fileName, uploadedUser, uploadedAt, onClickUploadButton} = props;

  const hasUploadedFile = useMemo(() => !isNullish(fileHashId), [fileHashId]);

  return (
    <Grid container sx={{height: '100%'}}>
      <Grid item xs />
      <Grid item xs={6} sx={{height: '100%'}}>
        {hasUploadedFile ? (
          <InspectionManual
            fileHashId={fileHashId}
            fileType={fileType}
            fileName={fileName}
            uploadedUser={uploadedUser}
            uploadedAt={uploadedAt}
          />
        ) : (
          <Stack sx={styles.noUploadMessage}>
            <NoUploadedFile canUpload={canUpload} />
          </Stack>
        )}
      </Grid>
      <Grid item xs>
        {canUpload && (
          <Button disableElevation variant={'contained'} color={'primary'} onClick={onClickUploadButton}>
            点検マニュアルをアップロード
          </Button>
        )}
      </Grid>
    </Grid>
  );
};
