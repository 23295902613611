import {atom} from 'jotai';
import {DateFieldValue} from '@molecules/DateField';

export type Product = {
  managementId: string | null;
  permanentlyAssigned: boolean;
  status: string;
  isOutsideOfHospital: boolean;
  hospitalRoomHashId: string | null;
  dateOfPurchase: string | null;
  // NOTE 非該当追加に伴い、notBaseUnitを追加。機器登録時のSelectorがnull値は許容されていないため、notBaseUnitを追加した
  isBaseUnit: boolean | 'notBaseUnit';
  waysOfPurchase: string;
  legalDurableYear?: number;
  isMaintenanceContract: boolean;
  // depreciationAmount: number | null;
  // bookValue: number | null;
  serialNumber: string | null;
  assetRegisterNumber: string | null;
  hospitalDealerHashId: string | null;
  hospitalDepartmentHashId: string | null;
  dateOfDisposal: DateFieldValue | null;
  reasonOfDisposal: string | null;
  notes: string | null;
  notes2: string | null;
  notes3: string | null;
  notes4: string | null;
  notes5: string | null;
  notes6: string | null;
  notes7: string | null;
  notes8: string | null;
  notes9: string | null;
  notes10: string | null;
  notes11: string | null;
  notes12: string | null;
  notes13: string | null;
  notes14: string | null;
  notes15: string | null;
  notes16: string | null;
  notes17: string | null;
  notes18: string | null;
  notes19: string | null;
  notes20: string | null;
  uuid?: string;
  lotNumber: string | null;
  optionalBarcode: string | null;
  rawBarcode: string | null;
  wholeProductHashID?: string;
  periodicInspectionStartDate: DateFieldValue | null;
};

export const InitialValue: Product = {
  managementId: null,
  permanentlyAssigned: false,
  status: 'ready',
  isOutsideOfHospital: false,
  hospitalRoomHashId: null,
  dateOfPurchase: null,
  isBaseUnit: 'notBaseUnit',
  waysOfPurchase: 'purchase',
  legalDurableYear: undefined,
  isMaintenanceContract: true,
  // depreciationAmount: null,
  // bookValue: null,
  serialNumber: null,
  assetRegisterNumber: null,
  hospitalDealerHashId: null,
  hospitalDepartmentHashId: null,
  dateOfDisposal: null,
  reasonOfDisposal: null,
  periodicInspectionStartDate: null,
  notes: null,
  notes2: null,
  notes3: null,
  notes4: null,
  notes5: null,
  notes6: null,
  notes7: null,
  notes8: null,
  notes9: null,
  notes10: null,
  notes11: null,
  notes12: null,
  notes13: null,
  notes14: null,
  notes15: null,
  notes16: null,
  notes17: null,
  notes18: null,
  notes19: null,
  notes20: null,
  lotNumber: null,
  optionalBarcode: null,
  rawBarcode: null,
};

export const productsAtom = atom<Product[]>([]);
export const productCountAtom = atom<number>(1);
