import React, {useMemo, useState} from 'react';
import {SimpleDialog} from '@front-libs/ui';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import {Dropzone} from '@molecules/Dropzone';
import {Box, TextField} from '@mui/material';

type UploadFormProps = {
  file: File;
  fileName: string;
};

export type UploadDocumentDialogProps = {
  acceptFile?: string[];
} & Omit<DialogProps<UploadFormProps>, 'content'>;

export const UploadDocumentDialog = ({acceptFile, ...props}: UploadDocumentDialogProps) => {
  const [file, setFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState<string>('');

  const disabledButton = useMemo(() => !file || !fileName, [file, fileName]);

  const dropzoneContent = useMemo(() => {
    return file ? <img src={URL.createObjectURL(file)} alt={file.name} /> : null;
  }, [file]);

  // NOTE: Fileが取得できない場合null.txtを返す
  const nullFile = new File(['null'], 'null.txt', {type: 'text/plain'});

  return (
    <SimpleDialog
      {...props}
      disabledButton={disabledButton}
      maxWidth="sm"
      actions={{
        resolve: async (res) => {
          props.actions.resolve({file: file ?? nullFile, fileName});
          return res;
        },
        reject: async (res) => {
          props.actions.reject(res);
          return res;
        },
      }}
      title="ファイルをアップロード">
      <Box sx={{display: 'flex', flexDirection: 'column', gap: '32px'}}>
        <Dropzone
          accept={acceptFile}
          onDrop={(acceptedFiles) => {
            const newFile = acceptedFiles[0];
            setFile(newFile);
            setFileName(newFile.name);
          }}>
          {dropzoneContent}
        </Dropzone>

        <TextField
          fullWidth
          variant="outlined"
          label="ファイル名"
          size="small"
          value={fileName}
          onChange={(e) => setFileName(e.target.value)}
        />
      </Box>
    </SimpleDialog>
  );
};
