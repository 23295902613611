import {axios} from '@front-libs/core';
import {ParentChildHospitalProducts} from './types';
import {ChildUnitFormValue} from '@Apps/ProductChildDeviceEditor/useChildUnitForm';

export const postUnitRelations = async (
  hospitalHashId: string,
  parentProductHashId: string,
  param: ChildUnitFormValue
) => {
  const baseURL = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/products/${parentProductHashId}/unit_relations`;
  return await axios.post<unknown>(baseURL, param);
};
export const getUnitRelations = async (hospitalHashId: string, parentProductHashId: string) => {
  const baseURL = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/products/${parentProductHashId}/unit_relations`;
  const {data} = await axios.get<ParentChildHospitalProducts>(baseURL);
  return data;
};

export const deleteUnitRelations = async (
  hospitalHashId: string,
  parentProductHashId: string,
  childProductHashId: string
) => {
  const baseURL = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/products/${parentProductHashId}/unit_relations/${childProductHashId}`;
  const {data} = await axios.delete<ParentChildHospitalProducts>(baseURL);

  return data;
};
