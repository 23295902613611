import React from 'react';
import {Grid, Box, SxProps, Theme} from '@mui/material';
import barcode from '@assets/barcode.png';
import barcode2 from './assets/barcode2.png';

export const NoRowsContent = () => {
  return (
    <Grid container alignItems="center" justifyContent="space-around" sx={rootStyle}>
      <Grid item sx={img1ContainerStyle}>
        <Box component="img" src={barcode} alt="バーコードの使い方" sx={barcodeImgStyle} />
      </Grid>
      <Grid item sx={img2ContainerStyle}>
        <Box component="img" src={barcode2} alt="バーコードの場所" sx={barcodeImg2Style} />
      </Grid>
    </Grid>
  );
};

const rootStyle: SxProps<Theme> = {
  flexWrap: 'nowrap',
  gap: '40px',
};

const img1ContainerStyle: SxProps<Theme> = {
  background: '#FFFFFF',
  borderRadius: '10px',
  padding: '40px 26px',
  display: 'flex',
  justifyContent: 'center',
};

const barcodeImgStyle: SxProps<Theme> = (theme) => ({
  maxWidth: '80%',
  [theme.breakpoints.up('laptop')]: {
    maxWidth: '70%',
  },
  [theme.breakpoints.up('desktop')]: {
    maxWidth: '60%',
  },
});

const img2ContainerStyle: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'center',
};

const barcodeImg2Style: SxProps<Theme> = (theme) => ({
  maxWidth: '80%',
  paddingTop: '56px',
  [theme.breakpoints.up('laptop')]: {
    maxWidth: '70%',
    paddingTop: '64px',
  },
  [theme.breakpoints.up('desktop')]: {
    paddingTop: '80px',
    maxWidth: '60%',
  },
});
