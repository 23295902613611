import React, {useState} from 'react';
import {styled, Box, Button, DialogActions, DialogTitle, Typography} from '@mui/material';
import {RepairIndex} from '@modules/repairs/types';
import {ErrorMessage} from '../ErrorMessage';
import {FaultRepairSelector} from './FaultRepairMenuContent/FaultRepairSelector';

type Props = {
  repairs: RepairIndex[];
  onBack: () => void;
};

const Description = styled(Box)({
  marginTop: '32px',
  '& .MuiTypography-root': {
    fontSize: 'inherit',
  },
});

const InputForm = styled(Box)({
  marginTop: '24px',
  maxHeight: '264px',
  overflowY: 'scroll',
});

const Actions = styled(DialogActions)({
  marginTop: '40px',
  '& > button:nth-of-type(n+2)': {
    marginLeft: '16px',
  },
});

export const FaultRepairMenu = ({repairs, onBack}: Props) => {
  const [selectedRepairHashId, setSelectedRepairHashId] = useState<string | null>(null);

  const handleSelectRepair = (hashId: string) => {
    setSelectedRepairHashId(hashId);
  };

  const handleBack = () => {
    onBack();
  };

  const handleSubmit = () => {
    if (!selectedRepairHashId) return;

    window.open(`/repairs/${selectedRepairHashId}`, '_blank');
  };

  if (repairs.length === 0) {
    return (
      <ErrorMessage
        title="修理が登録されていません"
        content={null}
        buttonText="機器メニュー画面に戻る"
        onBack={handleBack}
      />
    );
  }

  return (
    <>
      <DialogTitle sx={{padding: 0, minWidth: '600px', fontWeight: 700}}>詳細確認する修理の選択</DialogTitle>
      <Description>
        <Typography>詳細確認する修理を選択してください。</Typography>
      </Description>
      <InputForm>
        <FaultRepairSelector
          repairs={repairs}
          selectedRepairHashId={selectedRepairHashId}
          onSelect={handleSelectRepair}
        />
      </InputForm>
      <Actions>
        <Button variant="text" sx={{color: '#0052CC'}} onClick={handleBack}>
          機器メニュー画面に戻る
        </Button>
        <Button
          variant="contained"
          sx={{backgroundColor: '#0052CC', '&.Mui-disabled': {color: '#FFFFFF'}}}
          disabled={!selectedRepairHashId}
          onClick={handleSubmit}>
          確定
        </Button>
      </Actions>
    </>
  );
};
