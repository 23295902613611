import React, {MouseEventHandler} from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import {IconButton, Box} from '@mui/material';
import {SxProps} from '@mui/material/styles';

type DialogTitleProps = {
  children: React.ReactNode | string;
  onClose?: MouseEventHandler;
};

const rootStyle: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: 'black',
  padding: '32px 32px 0 32px',
  margin: 0,
};

const closeButtonStyle: SxProps = {
  '& .MuiButton-startIcon:nth-of-type(1)': {
    fontSize: '24px',
  },
};

export const DialogTitle = ({onClose, children}: DialogTitleProps) => {
  return (
    <Box sx={rootStyle}>
      <Box>{children}</Box>
      <Box>
        {onClose && (
          <IconButton color="inherit" onClick={onClose} sx={closeButtonStyle}>
            <ClearIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};
