import React, {useMemo, useState} from 'react';
import {Autocomplete, createFilterOptions} from '@material-ui/lab';
import {TextField, Theme, makeStyles} from '@material-ui/core';
import {useFormikContext} from 'formik';
import {useFetchCompaniesQuery} from '@modules/companies/api';
import {ProductDetailFormType} from '../../';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';

type Option = {
  label: string;
  value: string;
  isFreeSolo: boolean;
};

const filter = createFilterOptions<Option>();

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export const MakerSelect: React.FC = (props: any) => {
  const {myInfo} = useMyInfo();

  const [companyName, setCompanyName] = useState<string>();
  const {setFieldValue, values} = useFormikContext<ProductDetailFormType>();
  const {label, disabled} = props;

  const classes = useStyles();

  const {data, isLoading} = useFetchCompaniesQuery(myInfo.hospitalHashId, {page: 0, perPage: 100, name: companyName});

  const companies = useMemo(() => {
    return data?.data.filter((item) => data?.data.some((i) => i.name === item.name)) ?? [];
  }, [data?.data]);

  const filedValue = useMemo(
    () =>
      values.maker.isNew
        ? {
            label: values.maker.name ?? '',
            value: values.maker.name ?? '',
            isFreeSolo: true,
          }
        : {label: values.maker?.name, value: values.maker?.hashId, isFreeSolo: false},
    [values.maker?.hashId, values.maker.isNew, values.maker.name]
  );

  const options = useMemo(() => {
    return companies.map((company) => ({label: company.name, value: company.hashId, isFreeSolo: false})) ?? [];
  }, [companies]);

  return (
    <Autocomplete
      freeSolo
      disabled={disabled}
      disableClearable
      fullWidth
      options={options}
      getOptionSelected={(option, val) => option.value === val?.value}
      getOptionLabel={(option) => option.label ?? ''}
      loading={isLoading}
      value={filedValue}
      classes={{
        option: classes.option,
      }}
      renderInput={(inputProps) => {
        return (
          <TextField
            variant={'standard'}
            {...inputProps}
            label={label}
            InputLabelProps={{
              ...inputProps.InputLabelProps,
              classes: {
                root: classes.inputText,
                shrink: classes.shrink,
              },
            }}
            InputProps={{
              ...inputProps.InputProps,
              classes: {
                root: classes.inputRoot,
                input: classes.inputText,
                underline: classes.underline,
              },
              readOnly: props.readOnly,
            }}
          />
        );
      }}
      filterOptions={(opts, params) => {
        const filtered = filter(opts as Option[], params);

        const isExisting = options.some((option) => params.inputValue === option.label);
        if (params.inputValue !== '' && !isExisting) {
          filtered.push({
            value: params.inputValue,
            label: `"${params.inputValue}"を追加`,
            isFreeSolo: true,
          });
        }

        return filtered;
      }}
      onInputChange={(_e, value) => {
        if (value === '' || values.maker.name === value) {
          setCompanyName(undefined);
        } else {
          setCompanyName(value);
        }
      }}
      onChange={(e, value) => {
        // 複数フィールドでセットすると、複数件アップデートとメッセージが表示されるため、オブジェクトで保存
        if (typeof value === 'string') {
          setFieldValue('maker', {name: value, isNew: true});
        } else if (value.isFreeSolo) {
          setFieldValue('maker', {name: value.value, isNew: true});
        } else {
          const maker = companies.find((item) => item.hashId === value.value);
          setFieldValue('maker', {hashId: maker?.hashId, name: maker?.name, isNew: false});
        }
      }}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  inputRoot: {
    '&:hover': {
      borderBottom: `1px solid ${theme.palette.primary.dark}`,
    },
  },
  inputText: {
    fontSize: theme.typography.fontSize,
  },
  shrink: {
    transform: 'translate(0, 1.5px) scale(0.9)',
  },
  underline: {
    '&&&:before': {
      borderBottom: '0px solid',
    },
    '&&:after': {
      borderBottom: `1px solid ${theme.palette.primary.dark}`,
    },
  },
  option: {
    fontSize: theme.typography.fontSize,
  },
}));
