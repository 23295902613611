import {Chip} from '@mui/material';
import React from 'react';

export const FormLabelBadgeNarrow = (props: {required: boolean}) => {
  const {required, ...rest} = props;
  return (
    <Chip
      label={required ? '必須' : '任意'}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px 8px',
        width: '40px',
        height: '20px',
        background: required ? '#0052CC' : '#8B95A6',
        borderRadius: '5px',
        '& .MuiChip-label': {
          color: 'white',
          fontSize: '12px',
          padding: '0px',
        },
      }}
      {...rest}
    />
  );
};
